import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import AccessDenied from '../common/AccessDenied';

import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const UploadLeads = (props) => {
    const { leadId, id } = useParams();
    const location = useLocation();
    const { actions, usersData, fetchLeadIdItr, allLeadItrByLeadData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allUsers, setAllUsers] = useState([]);
    const [selectedItrFiles, setSelectedItrFiles] = useState([]);
    const formRef = useRef(null);

    const [allCampaigns, setAllCampaigns] = useState([]);
    const [allUploadLeads, setUploadLeads] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Upload Lead');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
 
                if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type')  && result.data.user_info[0].role_type !==''){
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
                
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            setAllCampaigns(sortedResultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.uploadLeadData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  uploadLeadData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            setUploadLeads(resultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  uploadLeadData :", error); 
        });
    }, [setUploadLeads]);

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedItrFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const onSubmit = (data) => {

        if (data.campaign.trim() === "") {
            toast.error("Please select campaign.", {
                position: "top-right",
            });
        } else if (!selectedItrFiles || selectedItrFiles.length === 0) {
            toast.error("No file has been selected.", {
                position: "top-right",
            });
        } else {

            const params = {"campaign": data.campaign, "upload_lead_file": selectedItrFiles };

            const requestData = encryptData(params);
            const response = actions.uploadLeads(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - uploadLeads form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    
                    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
                    const requestData = encryptData(obj);
                    const response = actions.uploadLeadData(requestData, false);
                    response.then((result) => {
                    //   console.log("Promise resolved -  uploadLeadData:", result);
                    if (result.data.success === 1) {
                        const results = result.data.data.data;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        setUploadLeads(resultArr);
                    }
                    }).catch((error) => {
                    //  console.error("Promise rejected -  uploadLeadData :", error); 
                    });
                    
                    reset();
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };
    
      const filteredItems = (allUploadLeads ?? []).filter(item =>
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    
    
      const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
      const endIndex = startIndex + config.ITEMS_PER_PAGE;
      const paginatedItems = filteredItems.slice(startIndex, endIndex);
      const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);
    
      const docViewUrl = `${config.API_BASE_URL}uploads/lead_gst_document/`;
    
    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }


    return (
        <>
            <Helmet>
                <title> Upload Leads | Ckers </title>
            </Helmet>

            <Container>
                <div id='main-wrap' className='mt-2'>

                    <Typography variant="h4" sx={{ mb: 5 }}>
                        Upload Leads
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)} className="row" encType="multipart/form-data" ref={formRef}>
                        <div className="row guarantor" id="bank_statement-1">
                            
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label htmlFor="campaign">
                                        <span>Campaign</span>
                                        <select
                                            id='campaign'
                                            name='campaign'
                                            className="form-select"
                                            {...register('campaign')}
                                        >
                                            <option value=''>Select Campaign</option>
                                            {allCampaigns.map((option) => (
                                                <option value={option.id}>
                                                    {option.campaign_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="itr_file">
                                        <span>File Upload <a style={{ color: "red" }}>*</a></span>
                                        <input type='file' id='itr_file' name='itr_file' className='form-control' onChange={handleItrFileChange} />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-0">
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>


                <div id="main-wrap" className='mt-4'>
                    <h5 className="fw-bold">Upload Lead History</h5>
                    <div className="table-wrap">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Upload File ID</th>
                                    <th>Created Date</th>
                                    <th>Total Upload Leads</th>
                                    <th>Save Leads</th>
                                    <th>Incorrect Leads</th>
                                    {/* <th>View Upload File</th>
                                    <th>View Corrupt File</th> */}
                                </tr>
                            </thead>

                            {paginatedItems.length === 0 ? (
                                <tbody>
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: 'center' }}>
                                            <h5>No Upload Lead History</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {paginatedItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.formatetdID}</td>
                                            <td>{item.created_date}</td>
                                            <td>{item.toatal_records}</td>
                                            <td>{item.correct_records}</td>
                                            <td>{item.corrupt_records}</td>
                                            {/* <td>
                                                <span className="badge text-bg-success text-white">
                                                    <a target="new" href={item.uploaded_file} className='text-success text-decoration-none'>
                                                        <i className="bi bi-eye">&nbsp;</i>
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <span className="badge text-bg-success text-white">
                                                    <a target="new" href={item.corrupt_file} className='text-success text-decoration-none'>
                                                        <i className="bi bi-eye">&nbsp;</i>
                                                    </a>
                                                </span>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>

                    <div>
                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        getUserModuleData: state.getUserModuleData,
        uploadLeadData : state.uploadLeadData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadLeads);
