import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

export function incidentReporteDataSuccess(incidentReporteData) {
    return { type: types.LOAD_INCIDENT_REPORTE_DATA_SUCCESS, incidentReporteData };
}

export function incidentReportStatusDataSuccess(incidentReportStatusData) {
  return { type: types.LOAD_INCIDENT_REPORT_STATUS_DATA_SUCCESS, incidentReportStatusData };
}

export function incidentCategoryDataSuccess(incidentCategoryData) {
  return { type: types.LOAD_INCIDENT_CATEGORY_DATA_SUCCESS, incidentCategoryData };
}

export function incidentPriorityDataSuccess(incidentPriorityData) {
  return { type: types.LOAD_INCIDENT_PRIORITY_DATA_SUCCESS, incidentPriorityData };
}

export function allTicketDataSuccess(allTicketData) {
  return { type: types.LOAD_ALL_TICKET_DATA_SUCCESS, allTicketData };
}

export function viewTicketDataSuccess(viewTicketData) {
  return { type: types.LOAD_VIEW_TICKET_DATA_SUCCESS, viewTicketData };
}

const addEditIncidentReporte = (params) => {
    return async function (dispatch) {
        dispatch(toggleNetworkRequestStatus(true));
        try {
        const response = await agent.post(API.CREATE_INCIDENT_REPORTE, params);
        dispatch(toggleNetworkRequestStatus(false));
        return response;
        } catch (error) {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
        } 
    };
}

const incidentReporteData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_REPORTE, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_REPORTE, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(incidentReporteDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const incidentReportStatusData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_REPORTE_STATUS, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_REPORTE_STATUS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addEditIncidentReporteStatus = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_INCIDENT_REPORTE_STATUS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const incidentReportFileDownload = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.INCIDENT_REPORTE_FILE_DOWNLOAD, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const incidentCategoryData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_CATEGORY, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_CATEGORY, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(incidentCategoryDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addEditIncidentCategory = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_INCIDENT_CATEGORY, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const incidentPriorityData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_PRIORITY, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_PRIORITY, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(incidentPriorityDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getIncidentReports = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_REPORT_BY_USER, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_REPORT_BY_USER, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const viewTicketData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_VIEW_TICKET, params);
      } else {
        response = await agent.post(API.GET_VIEW_TICKET, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(viewTicketDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addIncidentReportComment = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_INCIDENT_REPORTE_COMMENT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const checkLeadByMobile = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.CHECK_LEAD_BY_MOBILE, params);
      } else {
        response = await agent.post(API.CHECK_LEAD_BY_MOBILE, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const updateIncidentChecklistQuestionAnswer = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_INCIDENT_CHECKLIST_ANSWER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const createIncidentTask = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_INCIDENT_TASK, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const incidentTaskDelete = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.INCIDENT_TASK_DELETE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const getTaskForCalender = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_TASK_FOR_CALENDER, params);
      } else {
        response = await agent.post(API.GET_TASK_FOR_CALENDER, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(viewTicketDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const incidentTaskActivity = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.INCIDENT_TASK_ACTIVITY, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}



  export default {
    addEditIncidentReporte,
    incidentReporteData,
    incidentReporteDataSuccess,
    incidentReportStatusDataSuccess,
    incidentReportStatusData,
    addEditIncidentReporteStatus,
    incidentReportFileDownload,
    incidentCategoryData,
    incidentCategoryDataSuccess,
    addEditIncidentCategory,
    incidentPriorityData,
    incidentPriorityDataSuccess,
    getIncidentReports,
    allTicketDataSuccess,
    viewTicketData,
    viewTicketDataSuccess,
    addIncidentReportComment,
    checkLeadByMobile,
    updateIncidentChecklistQuestionAnswer,
    createIncidentTask,
    incidentTaskDelete,
    getTaskForCalender,
    incidentTaskActivity,
  }