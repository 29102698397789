import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const UserRoleCreatePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, roleInfo } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [showRoleDetails, setShowRoleDetails] = useState(false);

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [allRoleType, setAllRoleType] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState(null);


  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  getUserModuleData:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'User Role');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    const objRoleType = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestDataRoleType = encryptData(objRoleType);
    const responseRoleType = actions.getRoleTypeData(requestDataRoleType, false);
    responseRoleType.then((result) => {
        // console.log("Promise resolved -  master profile data:", result);         
      if (result.data.success === 1) {
        const results = result.data.data.data;
        // const resultArr = Object.keys(results).map((key) => results[key]);
        const resultArr = [...results].sort((a, b) => a.role_type.localeCompare(b.role_type));

        setAllRoleType(resultArr);
      }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllRoleType]);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setShowRoleDetails(false);

    if (id !== undefined && !showRoleDetails) {
      const decodedId = atob(id);
      setRoleId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'role_id': decodedId };
      const requestData = encryptData(obj);
      const response = actions.rolesData(requestData);
      response.then((result) => {
          // console.log("Promise resolved -  rolesData:", result);
          if (result.data.success === 1) {
              setShowRoleDetails(true);
              const results = result.data.data;
              setValue("role_name", results.data[0].role_name);
              setValue("role_type", results.data[0].role_type);
              setValue("role_status", results.data[0].status);

              handleRoleType({ target: { value: results.data[0].role_type } });
              setSelectedRoleType(results.data[0].role_type);
          }
      }).catch((error) => {
          // console.error("Promise rejected -  rolesData:", error);
      });
    }
  }, [id, setValue, setShowRoleDetails]);

  const handleRoleType = (event) => {
    const selectedRoleType = event.target.value;
    setSelectedRoleType(selectedRoleType);
  }

  const onSubmit = (data) => {
    let params = { 'role_name': data.role_name, 'role_status': data.role_status, "role_type": selectedRoleType };

    if (roleId !== '') {
      params = { 'role_name': data.role_name, 'role_status': data.role_status, "role_type": selectedRoleType, 'role_id': roleId };
    }
    const requestData = encryptData(params);
    const response = actions.createRole(requestData);
    response.then((result) => {
        // console.log("Promise resolved - add/edit lead form:", result); 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            setTimeout(() => {
              navigate('/dashboard/user-role');
            }, 1000);
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
    }).catch((error) => {
        // console.error("Promise rejected - add/edit lead form:", error);
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }

  return (
    <>
      <Helmet>
        <title> Create User Role | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showRoleDetails ? "Edit User Role" : "Create User Role"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Role Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='role_name' className='form-control'
                      {...register('role_name', { required: true })} />
                  </label>
                  {errors.role_name && <span className="required">This field is required.</span>}
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="role-type">
                    <span>Role Type <a style={{ color: "red" }}>*</a></span>
                    <select 
                      name='role_type'
                      className="form-select"
                      {...register('role_type', { required: true })}
                      value={selectedRoleType}
                      onChange={handleRoleType}
                    >
                      <option value="">Select Role Type</option>
                      {allRoleType && allRoleType.map((option) => (
                        <option value={option.role_type}>
                          {option.role_type}
                        </option>
                      ))}
                    </select>
                  </label>
                  {errors.role_type && <span className="required">This field is required.</span>}
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Role Status <a style={{ color: "red" }}>*</a></span>
                    <select name='role_status' className="form-select" {...register('role_status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.role_status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    roleInfo: state.roleInfo,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRoleCreatePage);