import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as reportActions from "../../actions/reportActions";
import AccessDenied from '../common/AccessDenied';
import Loader from "../../components/Loader";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const UserReportPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, sourceData, leadCountData, leadStatusData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allLeadAssignedUserData, setAllLeadAssignedUserData] = useState([]);
    const [allLeadSource, setAllLeadSource] = useState([]);
    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [dynamicSourceColumns, setDynamicSourceColumns] = useState([]);
    const [dynamicStatusColumns, setDynamicStatusColumns] = useState([]);
    const [allLeadSourceCount, setAllLeadSourceCount] = useState(0);
    const [allLeadStatusCount, setAllLeadStatusCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'User Report')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }

            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {

        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS};
        const requestData = encryptData(params);
        const response = actions.getUserReportData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved - getUserReportData", result); 
            if (result.data.success === 1) {
                const results = result.data.data;
                setAllLeadAssignedUserData(results);
                setPreloaderStatus(false);
            }
        }).catch((error) => {
            setAllLeadAssignedUserData([]);
            setPreloaderStatus(false);
            // console.error("Promise rejected - getUserReportData", error);
            toast.error(error, {
                position: "top-right",
            });
        });

    }, [setValue, setAllLeadAssignedUserData]);

    const onSubmit = (data) => {
        setAllLeadAssignedUserData([]);
        setPreloaderStatus(true);
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': data.start_date, 'end_date': data.end_date };
        const requestData = encryptData(params);
        const response = actions.getUserReportData(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                const results = result.data.data;
                setAllLeadAssignedUserData(results);
                setPreloaderStatus(false);
            }
        }).catch((error) => {
            setAllLeadAssignedUserData([]);
            toast.error(error, {
                position: "top-right",
            });
            setPreloaderStatus(false);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // console.log("allLeadAssignedUserData : ", allLeadAssignedUserData);

    const allLeadAssignedUserDataArray = Object.values(allLeadAssignedUserData);

    // Filter the array
    const filteredItems = allLeadAssignedUserDataArray.filter(item =>
        item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    const exportTatLeadsInGroupReport = async () => {
        const table = document.getElementById('exportTatReportExcelFile');

        TableToExcel.convert(table, {
            name: 'Leads_Tracker.xlsx',
            sheet: {
                name: 'Leads_Tracker',
            },
        });
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Leads Tracker</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Leads Tracker
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-3 filter'>
                        <div className='row mx-0'>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Start Date</span>
                                        <input type='date' name='start_date' className='form-control' {...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>End Date</span>
                                        <input type='date' name='end_date' className='form-control' {...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row'>
                    <div className='col-md-12'>
                        <div id='main-wrap' className='mb-4 search-filter'>

                            <div className="row justify-content-between">
                                <div className='col-md-12 text-end ps-lg-5'>
                                    <div className="form-group d-flex justify-content-end align-items-center">
                                        <button className="btn btn-info text-white ms-3" onClick={() => exportTatLeadsInGroupReport()}>
                                            Export
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive table-wrap">
                                <table className="table leads-assigned table-hover" id="exportTatReportExcelFile">
                                    <thead>
                                        <tr className='border-top border-bottom'>
                                            <th className='text-nowrap'>User Name</th>
                                            <th className='text-nowrap'># of Leads Created</th>
                                            <th className='text-nowrap'># of Sales Rejection</th>
                                            <th className='text-nowrap'># of Credit Rejection</th>
                                            {/* <th className='text-nowrap'># of Need Document</th>
                                            <th className='text-nowrap'># of Under Process</th> */}
                                            <th className='text-nowrap'># of Bureau</th>
                                            <th className='text-nowrap'># of FI Sheets Uploaded</th>
                                            <th className='text-nowrap'># of Sent to Credit</th>
                                            <th className='text-nowrap'># of Sent to Habile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedItems.map((user, index) => (
                                            <tr key={index}>
                                                <td className='border-right'>{user.name}</td>
                                                <td className='border-right'>{user.total_entries}</td>
                                                <td className='border-right'>{user.sales_rejection}</td>
                                                <td className='border-right'>{user.credit_rejection}</td>
                                                {/* <td className='border-right'>{user.need_document}</td>
                                                <td className='border-right'>{user.under_process}</td> */}
                                                <td className='border-right'>{user.bureau}</td>
                                                <td className='border-right'>{user.fi_visit}</td>
                                                <td className='border-right'>{user.sent_to_credit}</td>
                                                <td className='border-right'>{user.sent_to_habile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <nav className='mb-1'>
                                <ul className="pagination pagination-md justify-content-end">
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                        <li className="page-item">
                                            <button
                                                key={page}
                                                onClick={() => handlePageChange(page)}
                                                disabled={currentPage === page}
                                                className="page-link"
                                            >
                                                {page}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </Container>
            {preloaderStatus && <Loader />}
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        leadProfileInfo: state.leadProfileInfo,
        sourceData: state.sourceData,
        leadCountData: state.leadCountData,
        leadStatusData: state.leadStatusData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, leadActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserReportPage);