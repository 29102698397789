import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import $ from 'jquery';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import { format } from 'date-fns';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";
import * as userActions from "../../actions/userActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AddEditLeadPage = (props) => {
    const { userId, incidentId, sectionName, incidentTaskId } = useParams();

    const location = useLocation();
    const { actions, userInfo } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId || userId === null || userId === "") {
            navigate('/login');
        } else {
            const decodedUserId = atob(userId);
            const obj = { 'userId': decodedUserId, 'brief': 'module_access' };
            const requestData = encryptData(obj);
            const response = actions.moduleAccess(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const resultData = result.data;

                    if (Object.prototype.hasOwnProperty.call(resultData, 'login_token') && resultData.login_token) {
                        const loginToken = resultData.login_token;

                        localStorage.setItem("authToken", loginToken);
                        const authToken = localStorage.getItem('authToken');

                        if (authToken && authToken !== '') {

                            const decodedIncidentId = atob(incidentId);

                            if(incidentId !== undefined && incidentId !== "" && incidentTaskId !== undefined && incidentTaskId !== ""){
                                const decodedIncidentTaskId = atob(incidentTaskId);
                                navigate(`/dashboard/module-view-ticket-page/${btoa(String(decodedIncidentId))}/${sectionName}/${btoa(String(decodedIncidentTaskId))}`);
                            } else {
                                navigate(`/dashboard/module-view-ticket-page/${btoa(String(decodedIncidentId))}/${sectionName}`);
                            }
                        }
                    } else {
                        navigate('/login');
                    }
                }
            }).catch((error) => {
                 console.error("Promise rejected - moduleAccess:", error);
            });
        }
    }, [userId, actions, navigate]);
    return (
        <></>
    );
}
function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        usersData: state.usersData,
        leadInfo: state.leadInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default, groupsUsersActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLeadPage);