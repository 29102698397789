import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ArchiveModuleAllListingPage = ({ props, allIncidentReports, actions, fetchModules }) => {

    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [preloaderStatus, setPreloaderStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("allIncidentReports = ", allIncidentReports);
    }, [allIncidentReports]);

    const handleClose = () => {
        setOpen(false);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allIncidentReports.filter(item =>
        item.type_topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);


    const handleRestoreClick = (id) => {

        if (id.trim() === "") {
            toast.error(`Request is not selected.`, {
                position: "top-right",
            });
        } else {
            
            const params = {"incident_id": id };

            const requestData = encryptData(params);
            const response = actions.moduleRestore(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    setPreloaderStatus(false);

                    toast.success(result.data.message, {
                        position: "top-right",
                    });

                    // navigate('/dashboard/module-listing-page');
                    fetchModules();
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    return (
        <>
            <div id='main-wrap-'>
                <div className='table-wrap'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className='text-nowrap'>Deleted By</th>
                                <th className='text-nowrap'>Deleted Date</th>
                                <th>Assigned to</th>
                                <th>Status</th>
                                <th>Restore</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{(item.leadName && item.leadName.trim() ? item.leadName : item.name || '')}</td>
                                    <td className='text-nowrap'>{item.modifiedByName}</td>
                                    <td className='text-nowrap'>{item.modified_date}</td>
                                    <th >{item.assignedTo}</th>
                                    <th >{item.incidentReportStatus}</th>
                                    <td>
                                        <div className="btn-group dropstart" title="Edit">
                                            <NavLink onClick={() => handleRestoreClick(item.id)}><i className="bi bi-arrow-counterclockwise text-success" /></NavLink>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div>
                    <div className="mb-2">
                        Records - {paginatedItems.length}
                    </div>

                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveModuleAllListingPage);