import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink , useNavigate,useParams ,useLocation} from 'react-router-dom'; 
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadCreatePage = (props) => {

    const { id } = useParams();
    const location = useLocation();
    const { actions,usersData, basicLeadInfo, sourceData, masterProfileData, leadChecklistData, interactionTypeData, leadStatusData, createLead} = props;
    const { register, handleSubmit, formState: { errors },setValue  } = useForm();
    const navigate = useNavigate();  
    const [allUsers, setAllUsers] = useState([]);
    const [selectDcreateby, setSelectDcreateby] = useState('');
    const [selectDstatus, setSelectDstatus] = useState('');
    const [leadId, setLeadId] = useState(''); 
    const [setLeadChecklistData, setAllLeadChecklistData] = useState([]);
    const [allSourceData, setAllSource] = useState([]);
    const [allMasterProfiles, setAllMasterProfiles] = useState([]);
    const [allInteractionTypeData, setAllInteractionTypeData] = useState([]);
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allCreateLead, setAllCreateLead] = useState([]);
    const [isChecklistStatus, setIsChecklistStatus] = useState(false);
    const [selectedChecklist, setSelectedChecklist] = useState('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if(authToken === null){
            navigate('/login');
        }
        
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj); 
        
        actions.usersData(requestData);
        if(usersData.success){
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        // setAllMasterProfiles(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj); 
        actions.masterProfileData(requestData);
        if(masterProfileData.success){
            // setAllMasterProfiles(true);
            setAllMasterProfiles(masterProfileData.data);
        }
    }, []);
    
    useEffect(() => {
        // setAllSource(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.sourceData(requestData);
        if(sourceData.success){
            // setAllSource(true);
            setAllSource(sourceData.data);
        }
    }, []);

    useEffect(() => {
        // setAllInteractionTypeData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.interactionTypeData(requestData);
        if(interactionTypeData.success){
            // setAllInteractionTypeData(true);
            setAllInteractionTypeData(interactionTypeData.data);
        }
    }, []);

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.leadStatusData(requestData);
        if(leadStatusData.success){
            // setAllLeadStatusData(true);
            setAllLeadStatusData(leadStatusData.data);
        }
    }, []);

    const handleSubmitBasicInfo = (data) => {

        const leadCheklistMaster = [data.lead_profile];
        const leadCheklist = JSON.stringify(leadCheklistMaster);

        let params = {'name': data.name, 'email': data.email_id, 'mobile': data.phone, 'full_address': data.address, 'adhar_number': data.aadhar, 'pancard_number': data.pan, 'lead_query' : data.query, 'source_of_lead': data.source, 'lead_assigned': data.user, 'action_user_id': data.created_by, 'lead_created_by': data.created_by, 'lead_checklist_master' : leadCheklist};

        if (leadId !== '') {
            params = {'name': data.name, 'email': data.email_id, 'mobile': data.phone, 'full_address': data.address, 'adhar_number': data.aadhar, 'pancard_number': data.pan, 'lead_query' : data.query, 'source_of_lead': data.source, 'lead_assigned': data.user, 'action_user_id': data.created_by, 'lead_created_by': data.created_by, 'lead_checklist_master' : leadCheklist, 'lead_id': leadId};
        }

        const requestData = encryptData(params); 
        actions.createLead(requestData,navigate);        
        if(createLead.success){
            // setAllCreateLead(createLead.data);
            console.log(createLead.data);
            fetchChecklist(createLead.data);
            setIsChecklistStatus(true);
        }
        else {
            setIsChecklistStatus(false);
        }
    };

    const fetchChecklist = (id) => {
        
        console.log("id:", id);

        const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': id };
        const requestData = encryptData(obj);
        const response = actions.leadChecklistData(requestData);

        console.log("response:", response);

        response.then((result) => {
            console.log("Promise resolved:", result);
            setAllLeadChecklistData(result.data.data);
        }).catch((error) => {
            setAllLeadChecklistData([]);
            console.error("Promise rejected:", error);
        });
    };

    const handleSubmitLeadChecklist = (data) => {
        // Navigate to the next tab or section
        // Update any navigation logic here
    };

    const handleSubmitCommunication = (data) => {
        const params ={'lead_id' : leadId, 'type_of_interaction' : data.interaction_type, 'date_of_interaction' : data.date_of_interaction, 'interaction_note' : data.interaction_note, 'next_interaction_type' : data.nit, 'next_interaction_date' : data.nid, 'lead_communication_status' : data.lead_status};

        const requestData = encryptData(params); 
        actions.createLeadCommunication(requestData,navigate);
    };
     
    const handleCreatedByChange = (event) => {
        alert(event.target.value);
        setSelectDcreateby(event.target.value);
    };
    
    const handleStatusChange = (event) => {
        setSelectDstatus(event.target.value);
    };

    const handlePrevQuestion = () => {
        setCurrentQuestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleNextQuestion = () => {
        setCurrentQuestionIndex(prevIndex => Math.min(prevIndex + 1, leadChecklistData.data.length - 1));
    };


  return (
    <>
      <Helmet>
        <title> Create Lead | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Create Lead
        </Typography>

            <div id='main-wrap'>
                <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-basic-info-tab" data-bs-toggle="pill" data-bs-target="#pills-basic-info" type="button" role="tab" aria-controls="pills-basic-info" aria-selected="true">Basic Information</button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-lead-check-tab" data-bs-toggle="pill" data-bs-target="#pills-lead-check" type="button" role="tab" aria-controls="pills-lead-check" aria-selected="false">Lead Checklist</button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-communication-tab" data-bs-toggle="pill" data-bs-target="#pills-communication" type="button" role="tab" aria-controls="pills-communication" aria-selected="false">Communication</button>
                    </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-basic-info" role="tabpanel" aria-labelledby="pills-basic-info-tab" tabIndex="0">
                        <h5 className='fw-bold mb-3'>Basic Information</h5>

                        <form onSubmit={handleSubmit(handleSubmitBasicInfo)}>
                            <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Name</span>
                                        <input type='text' id='name' name='name' className='form-control' placeholder='' {...register('name')}/>
                                    </label>
                                    {errors.name && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="email_id">
                                        <span>Email ID</span>
                                        <input type='email' id='email_id' name='email_id' className='form-control' placeholder='' {...register('email_id')}/>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="phone">
                                        <span>Phone Number</span>
                                        <input type='tel' id='phone' name='phone' className='form-control' placeholder='' {...register('phone')}/>
                                    </label>
                                    {errors.phone && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="address">
                                        <span>Address</span>
                                        <input type='text' id='address' className='form-control' placeholder='' {...register('address')}/>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="aadhar">
                                        <span>Aadhar Card Number</span>
                                        <input type='tel' id='aadhar' name='aadhar' className='form-control' placeholder='' {...register('aadhar')}/>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="pan">
                                        <span>PAN Card Number</span>
                                        <input type='text' id='pan' name='pan' className='form-control' placeholder='' {...register('pan')}/>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="query">
                                        <span>Lead Query</span>
                                        <textarea id='query' name='query' className='form-control' placeholder='' {...register('query')}/>
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="lead-profile">
                                        <span>Lead Profile</span>
                                        <select id='lead_profile' name='lead_profile' className="form-select" {...register('lead_profile')}>
                                            <option selected>Select Master Profile</option>
                                            {/* {allMasterProfiles.map((option) => (
                                                <option value={option.id}>
                                                {option.lead_profile_name}
                                                </option>
                                            ))} */}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="source">
                                        <span>Source of Lead</span>
                                        <select id='source' name='source' className="form-select" {...register('source')}>
                                            <option selected>Select Source</option>
                                            {/* {allSourceData.map((option) => (
                                                <option value={option.id}>
                                                {option.source_of_lead}
                                                </option>
                                            ))} */}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="user">
                                        <span>Lead Assigned to</span>
                                        <select id='user' name='user' className="form-select" {...register('user')}>
                                            <option selected>Select User</option>
                                            {allUsers.map((option) => (
                                                <option value={option.user_id}>
                                                {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="created_by">
                                        <span>Lead Created by</span>
                                        <select id='created_by' name='created_by' className="form-select" {...register('created_by')}>
                                            <option selected>Select User</option>
                                            {allUsers.map((option) => (
                                                <option value={option.user_id}>
                                                {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                <button className="btn btn-primary"> Next </button>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>

                    
                    <div className="tab-pane fade" id="pills-lead-check" role="tabpanel" aria-labelledby="pills-lead-check-tab" tabIndex="0">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h5 className='fw-bold mb-3'>Lead Checklist</h5>
                            <h6 className='fw-bold mb-3 bg-info text-white py-1 px-2 rounded'>1/2</h6>
                        </div>

                        <form onSubmit={handleSubmit(handleSubmitLeadChecklist)}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    {leadChecklistData && 'data' in leadChecklistData ? (
                                        <div className='form-group'>
                                            <label htmlFor="check_list_select">
                                                <select id='check_list_select' name='check_list_select' className="form-select" {...register('check_list_select')} onChange={(e) => setSelectedChecklist(e.target.value)}>
                                                    {leadChecklistData.data.map((questions, questionIndex) => (
                                                        <option key={questionIndex} value={questions.ckeck_list_id}>
                                                            {questions.ckeck_list_id}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        </div>
                                    ) : (
                                        <div>No lead checklist data available.</div>
                                    )}
                                </div>
                                                
                                <div className='col-md-12'>
                                    {leadChecklistData && 'data' in leadChecklistData ? (
                                        leadChecklistData.data.map((questions, questionIndex) => {

                                            // Parse the JSON string into an object
                                            const questionData = JSON.parse(questions.question_answer);
                                            // console.log(questionData);
                                            return (
                                                // <div key={questionIndex} className= {`check-list-show-${questions.ckeck_list_id}`}>
                                                <div key={questionIndex} className= {`check-list-show-${questions.ckeck_list_id}`} style={{ display: selectedChecklist === questions.ckeck_list_id ? 'block' : 'none' }}>
                                                    <div className='form-group'>
                                                        <label htmlFor={`question-${questionIndex}`}>
                                                            <span>Question Text</span>
                                                            <input
                                                                type='text'
                                                                id={`question-${questionIndex}`}
                                                                className='form-control'
                                                                value={questionData.question}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </label>
                                                    </div>
                                                    
                                                    <div className='col-md-12 questions'>
                                                        <span className='mb-2'>Answer Option</span>
                                                        {questionData.options.map((option, optionIndex) => (
                                                            <div className='form-group' key={optionIndex}>
                                                                <div className='row'>
                                                                    <div className='col-1'>
                                                                        <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='form-check-input mt-0 ms-0'
                                                                            checked={option.check === '1'}
                                                                            readOnly
                                                                        />
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-11 ps-0'>
                                                                        <label htmlFor={`ans-op-${questionIndex}-${optionIndex}`}>
                                                                            <input
                                                                                type='text'
                                                                                className='form-control'
                                                                                readOnly
                                                                                value={option.option}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className='col-12 text-end question-btns'>
                                                        <button
                                                            className='btn prev-btn'
                                                            disabled={currentQuestionIndex === 0}
                                                            onClick={handlePrevQuestion}
                                                        >
                                                            Prev Question
                                                        </button>
                                                        <button
                                                            className='btn next-btn'
                                                            disabled={currentQuestionIndex === leadChecklistData.data.length - 1}
                                                            onClick={handleNextQuestion}
                                                        >
                                                            Next Question
                                                        </button>
                                                    </div>

                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>No lead checklist data available.</div>
                                    )}
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group mt-5 mb-2'>
                                        <button className='btn btn-primary'>Next</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="tab-pane fade" id="pills-communication" role="tabpanel" aria-labelledby="pills-communication-tab" tabIndex="0">
                        <h5 className='fw-bold mb-3'>Communication</h5>

                        <form onSubmit={handleSubmit(handleSubmitCommunication)}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="interaction-type">
                                            <span>Type of Interaction</span>
                                            <select id='interaction_type' name='interaction_type' className="form-select" {...register('interaction_type')}>
                                                <option selected>Select Interaction Type</option>
                                                {/* {allInteractionTypeData.map((option) => (
                                                    <option value={option.id}>
                                                    {option.interaction_type_name}
                                                    </option>
                                                ))} */}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="date-interaction">
                                            <span>Date of Interaction</span>
                                            <input type='date' className='form-control' id='date_of_interaction' name='date_of_interaction' {...register('date_of_interaction')}/>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor="inteaction-notes">
                                            <span>Interaction Notes</span>
                                            <textarea id='interaction_note' name='interaction_note' className='form-control' {...register('interaction_note')}>&nbsp;</textarea>
                                        </label>
                                    </div>
                                </div>                      
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="nit">
                                            <span>Next Interaction Type</span>
                                            <select id='nit' className="form-select" name='nit' {...register('nit')}>
                                                <option selected>Select Next Interaction Type</option>
                                                {/* {allInteractionTypeData.map((option) => (
                                                    <option value={option.id}>
                                                    {option.interaction_type_name}
                                                    </option>
                                                ))} */}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="nid">
                                            <span>Next Interaction Date</span>
                                            <input type='date' id='nid' name='nid' className='form-control' {...register('nid')}/>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="lead-status">
                                            <span>Lead Status</span>
                                            <select id='lead_status' name='lead_status' className="form-select" {...register('lead_status')}>
                                                <option selected>Select Lead Status</option>
                                                {allLeadStatusData.map((option) => (
                                                    <option value={option.id}>
                                                    {option.lead_status}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group mb-2'>
                                        <button className="btn btn-primary"> Submit </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
      </Container>
    </>
  );
}

function mapStateToProps(state) {
    return {
       usersData: state.usersData,
       userInfo: state.userInfo,
       sourceData: state.sourceData,
       masterProfileData : state.masterProfileData,
       leadChecklistData : state.leadChecklistData,
       interactionTypeData : state.interactionTypeData,
       leadStatusData : state.leadStatusData,
       createLead : state.createLead,
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(LeadCreatePage);