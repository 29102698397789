
import react, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useParams, useLocation, Link, Link as RouterLink} from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Typography, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";


const NoteHistoryPage = ({ leadId, setShowHistory, props }) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, addEditNoteHistoryPage, NoteHistoryPageData, relationShipData, identifiersData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [getAllNoteHistoryPageData, setAllNoteHistoryPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewModalInfo, setViewModalInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [isMappingStatus, setIsMappingStatus] = useState(false);

  useEffect(() => {

        if(leadId !== undefined){
            console.log("leadId = ", leadId);
            
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": leadId };
      const requestData = encryptData(obj);
      const response = actions.getNoteHistory(requestData);

      response.then((result) => {
        // console.log("Promise resolved -  getNoteHistory :", result); 
        if (result.data.success === 1) {
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          setAllNoteHistoryPageData(resultArr);
        }
      }).catch((error) => {
        //  console.error("Promise rejected -  getNoteHistory :", error); 
      });
    }
  }, [setAllNoteHistoryPageData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };


  const filteredItems = getAllNoteHistoryPageData.filter(item => {
    const formattedID = item.formatetdID?.toLowerCase() || '';

    return (
      formattedID.includes(searchQuery.toLowerCase())
    );
  }
  );


  const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
  const endIndex = startIndex + config.ITEMS_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

  const backToLeadHandler = () => {
      setShowHistory(false);
  }

  const onRowClickAction = (itemObj, btnAction) => {
    setViewModalInfo(itemObj);
    setOpen(true);
    setIsMappingStatus(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div id="main-wrap" className='mt-4'>
        <div className='row justify-content-between'>
          <div className='col-md-10'>
            <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
              Notes History
            </Typography>
          </div>

          <div className='col-md-2'>
            <div className='d-flex flex-md-row flex-column justify-content-between'>
                <button onClick={backToLeadHandler} onKeyDown={backToLeadHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded order-md-2 order-1'>Back to Lead</button>
            </div>
          </div>

          <div className='col-md-4 pe-0 ps-lg-5 hide'>
            <div className="form-group">
              <label htmlFor="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  id="search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="table-wrap">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Created Date</th>
                <th>Created By</th>
                <th>Notes For</th>
                <th>View</th>               
              </tr>
            </thead>
            <tbody>
              {paginatedItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.formatetdID}</td>
                  <td>{item.created_date}</td>
                  <td>{item.createdByName}</td>

                  <td>
                    {item.section_name === "lead_info" ? "Lead" :
                    item.section_name === "co_applicants" ? "Co-Applicant" : 
                    item.section_name === "guarantors" ? "Guarantors" : 
                    item.section_name === "bank_statement" ? "Bank Statement" : 
                    item.section_name === "itr" ? "ITR" :
                    item.section_name === "gst" ? "GST" : item.section_name}
                  </td>
                  
                  <td>
                    <RouterLink onClick={() => onRowClickAction(item, 'view_note')} ><i className="bi bi-eye"/></RouterLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className='mb-1'>
          <ul className="pagination pagination-md justify-content-end">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
              <li className="page-item">
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  disabled={currentPage === page}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          >
          <div className='modal-custom rounded'>
              <div className='modal-head'>
              <h4 id="modal-title">Note Info</h4>
              </div>

              <div className='modal-body'>
              <div className='row mb-2 pb-1'>
                  <div className='col-4'><b>Note :</b></div>
                  <div className='col-7 ps-0'>{viewModalInfo.comment}</div>
              </div>
              </div>
          </div>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    addEditNoteHistoryPage: state.addEditNoteHistoryPage,
    NoteHistoryPageData: state.NoteHistoryPageData,
    relationShipData: state.relationShipData,
    identifiersData: state.identifiersData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NoteHistoryPage);
