import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function campaignInfoReducer(state = initialState.campaignInfo, action) {
  switch (action.type) {
    case types.LOAD_CAMPAIGN_SUCCESS_INFO:
      return action.campaignInfo;
    default:
      return state;
  }
}
