import React, { useState, useEffect, memo } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
// @mui  
import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";

const LeadQuestionnaire = ({ questions, checklistInfo, props, setOpen, fetchLeads, updateParentState, userRoleType, leadStatusFlag }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { leadInfo, leadData, actions } = props;
    const [currentChecklistId, setCurrentChecklistId] = useState(checklistInfo.id);
    const [leadId, setLeadId] = useState(checklistInfo.lead_id);
    const [questionnaireData, setQuestionnaireData] = useState(questions);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(questionnaireData.length).fill(''));
    const [fieldInvestigationFalg, setFieldInvestigationFalg] = useState(false);
    const [showButtonOrNot, setShowButtonOrNot] = useState(false);

    useEffect(() => {
        // set answers of each questions in array

        console.log("questions, ", questions);

        const userAnswersArray = questions.map((item) => (item.userAnswers ? item.userAnswers[0] : ''));
        setAnswers(userAnswersArray);
    }, [actions, leadInfo]);

    useEffect(() => {
        if (checklistInfo && checklistInfo.check_list_name === "Field Investigation") {
            setFieldInvestigationFalg(true);
        } else {
            setFieldInvestigationFalg(false);
        }

        if (checklistInfo && checklistInfo.fieldInvestigationFalg === "1") {
            setShowButtonOrNot(true);
        } else {
            setShowButtonOrNot(false);
        }
    }, [checklistInfo, setFieldInvestigationFalg, setShowButtonOrNot]);

    console.log("showButtonOrNot : ", showButtonOrNot);

    const [checklistData, setChecklistData] = useState([]);
    useEffect(() => {
        if (props.checklistInfo) {
            setCurrentChecklistId(props.checklistInfo.id)
        }
        handleChecklistID();
    }, [props.checklistInfo]);

    useEffect(() => {
        handleChecklistID();
    }, []);

    const handleDelete = (id) => {
        const requestData = encryptData({ id });
        const response = actions.checklistDeleteData(requestData, false);

        response.then((result) => {
            if (result.data.status === 200) {
                console.log("Deletion Result:", result.data);
                toast.success('Item deleted successfully', {
                    position: 'top-right',
                });
                handleChecklistID();
            }
        })
            .catch((error) => {
                console.error("Error deleting item:", error);
                toast.error('Failed to delete item. Please try again later.', {
                    position: 'top-right',
                });
            });
    };


    // const handleChecklistID = () => {
    //     const params = {
    //         checklist_id: currentChecklistId
    //     };
    //     const requestData = encryptData(params);
    //     const response = actions.checklistFetchData(requestData, false);
    //     response.then((result) => {
    //         response.then((result) => {
    //             if (result.data) {
    //                 console.log("Testing", result.data);
    //                 setChecklistData(result.data)
    //             }
    //         }).catch((error) => {
    //         });
    //     })
    // }

    const handleChecklistID = () => {
        const params = {
            checklist_id: currentChecklistId, // Assuming currentChecklistId is defined somewhere
        };
        const requestData = encryptData(params); // Encrypt the request if needed

        // Call your API to fetch checklist data
        const response = actions.checklistFetchData(requestData, false);

        response.then((result) => {
            if (result.data) {
                console.log("Checklist Data:", result.data);
                setChecklistData(result.data); // Set the fetched data to state
            }
        }).catch((error) => {
            console.error('Error fetching checklist data:', error);
        });
    };

    const handleNext = () => {
        handleSave('nextBtnClickHandler');
        if (currentIndex < questionnaireData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleAnswer = (questionIndex, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = value;
        // console.log("updatedAnswers :", updatedAnswers);
        setAnswers(updatedAnswers);
    };

    const handleSave = (actionHandler) => {
        //    console.log("Selected Answers:", answers);

        // Save answers to the database (convert to JSON if needed)
        const answersJSON = JSON.stringify(answers);
        if (currentChecklistId === '') {
            toast.error("Checklist id in null   ", {
                position: "top-right",
            });
            return;
        }

        const params = { 'id': currentChecklistId, 'answers': answersJSON, 'userRoleType':userRoleType, "lead_id":leadId };
        console.log("leadId :", leadId);

        const requestData = encryptData(params);
        const response = actions.updateLeadChecklistQuestionAnswer(requestData);

        response.then((result) => {
            // console.log("Promise resolved - add/edit question answer form:", result); 
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                // setShowLeadDetails(false);
                setTimeout(() => {
                    if (actionHandler !== 'nextBtnClickHandler') {
                        updateParentState(false);
                        fetchLeads();
                        setOpen(false);
                        backToChecklistsHandler();
                    }
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            //  console.error("Promise rejected - add/edit question answer form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    };


    const handleSaveDraftAndFinal = (actionHandler) => {
        // console.log("actionHandler : ", actionHandler);
        handleSubmit(onSubmit)();
        const answersJSON = JSON.stringify(answers);
        if (currentChecklistId === '') {
            toast.error("Checklist id is null", {
                position: "top-right",
            });
            return;
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                const params = { 'id': currentChecklistId, 'answers': answersJSON, 'userRoleType': userRoleType, "lead_id": leadId, "action": actionHandler, "fieldInvestigationFalg": fieldInvestigationFalg, "latitude": latitude, "longitude": longitude };
                console.log("params :", params);

                const requestData = encryptData(params);
                const response = actions.updateFiChecklistQuestionAnswer(requestData);

                response.then((result) => {
                    // console.log("Promise resolved - add/edit question answer form:", result); 
                    if (result.data.success === 1) {
                        toast.success(result.data.message, {
                            position: "top-right",
                        });
                        // setShowLeadDetails(false);
                        setTimeout(() => {
                            if (actionHandler !== 'nextBtnClickHandler') {
                                updateParentState(false);
                                fetchLeads();
                                setOpen(false);
                                backToChecklistsHandler();
                            }
                        }, 1000);
                    } else {
                        toast.error(result.data.message, {
                            position: "top-right",
                        });
                    }
                }).catch((error) => {
                    //  console.error("Promise rejected - add/edit question answer form:", error);
                    toast.error(error, {
                        position: "top-right",
                    });
                });

            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Google location is not supported by this browser.", {
                position: "top-right",
            });
        }

    };

    const currentQuestion = questionnaireData[currentIndex];
    const backToChecklistsHandler = () => {
        setOpen(false);
        updateParentState(false);
    }

    const { register, handleSubmit } = useForm();
    const [fileSets, setFileSets] = useState([{
        uploadFile: [],
        fileComment: ''
    }]);

    useEffect(() => {
        if (props.checklistInfo) {
            setLeadId(props.checklistInfo.lead_id);
            setCurrentChecklistId(props.checklistInfo.id)
        }
    }, [props.checklistInfo]);

    const onSubmit = (data) => {
        const params = fileSets.map(set => ({
            upload_file: set.uploadFile,
            file_comment: set.fileComment,
            lead_id: leadId,
            checklist_id: currentChecklistId
        }));
        console.log('Form Data:', params);
        const requestData = encryptData(params);
        const response = actions.addEditUploadFile(requestData);
        response.then((result) => {
            setLeadId();
            // console.log('Result ->', result);
            // toast.success('Add Record successfully', {
            //     position: 'top-right',
            // });
        });
    };

    const handleUploadFileChange = (e, index) => {
        const files = Array.from(e.target.files);
        const base64Files = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    const newFileSets = [...fileSets];
                    newFileSets[index].uploadFile = base64Files;
                    setFileSets(newFileSets);
                    console.log('Updated uploadFile:', base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handleCommentChange = (e, index) => {
        const newFileSets = [...fileSets];
        newFileSets[index].fileComment = e.target.value;
        setFileSets(newFileSets);
    };

    const addMoreFields = () => {
        setFileSets([...fileSets, { uploadFile: [], fileComment: '' }]);
    };

    const deleteFileSet = (index) => {
        const newFileSets = fileSets.filter((_, i) => i !== index);
        setFileSets(newFileSets);
    };

    const handleDownload = (fileUrl, fileName) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <div className='d-flex flex-md-row flex-column align-md-items-center align-items-start justify-content-between mt-3'>
                <h5 className='fw-bold mb-3 order-md-1 mb-0 order-2'>Checklist : {checklistInfo.check_list_name}</h5>
                <button onClick={backToChecklistsHandler} onKeyDown={backToChecklistsHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded order-md-2 order-1'> Back to List</button>
            </div>

            <div className='row mb-2'>
                <div className="col-6 text-start">
                    <button
                        disabled={leadStatusFlag === true} className={fieldInvestigationFalg && showButtonOrNot ? "btn btn-success d-none" : "btn btn-success"} onClick={() => handleSaveDraftAndFinal("draft")}>
                        Save As Draft
                    </button>
                </div>
                <div className={fieldInvestigationFalg ? "d-none col-6 text-end" : "col-6 text-end"}>
                    <button
                        disabled={leadStatusFlag === true}
                        className="btn btn-success"
                        onClick={() => handleSaveDraftAndFinal("complete")}
                    >
                        Complete
                    </button>
                </div>

                <div className="col-6 text-end">
                    <button
                        disabled={leadStatusFlag === true}
                        className={fieldInvestigationFalg ? "btn btn-primary" : "d-none btn btn-primary"}
                        onClick={() => handleSaveDraftAndFinal("final")}
                    >
                        Final Save
                    </button>
                </div>
            </div>

            <div>
                {questionnaireData.map((currentQuestion, questionIndex) => (
                    <div key={questionIndex} className='question-wrap'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <h6 className='d-flex lh-base mb-2'><span className='h6 lh-base fw-bold opacity-100 mb-0'>Q{questionIndex + 1}.&nbsp;</span> {currentQuestion.questionText}</h6>
                                </div>
                            </div>
                            <div className='col-md-12 questions'>
                                <span className='mb-1'>Answer Option</span>
                                <div className='row q-row'>
                                    {currentQuestion.answerType === "checkbox" ? (
                                        currentQuestion.answerOptions.map((option, optionIndex) => (
                                            <div className="col-md-3" key={optionIndex}>
                                                <div className="question-options mb-md-0 mb-2">
                                                    <label htmlFor="check" className='d-flex align-items-center'>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value={option}
                                                            checked={(answers[questionIndex] || []).includes(option)}
                                                            onChange={(e) => {
                                                                const selectedOptions = answers[questionIndex] || [];
                                                                if (e.target.checked) {
                                                                    selectedOptions.push(option);
                                                                } else {
                                                                    const indexToRemove = selectedOptions.indexOf(option);
                                                                    if (indexToRemove !== -1) {
                                                                        selectedOptions.splice(indexToRemove, 1);
                                                                    }
                                                                }
                                                                handleAnswer(questionIndex, selectedOptions);
                                                            }}
                                                        />
                                                        <span className='ps-2 mb-0'>{option}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    ) : currentQuestion.answerType === "input" ? (
                                        <div className='px-2'>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={answers[questionIndex]}
                                                onChange={(e) => handleAnswer(questionIndex, e.target.value)}
                                            />
                                        </div>
                                    ) : currentQuestion.answerType === "radio" ? (
                                        currentQuestion.answerOptions.map((option, optionIndex) => (
                                            <div className='col-md-3' key={optionIndex}>
                                                <div className="question-options mb-md-0 mb-2">
                                                    <label htmlFor="radio" className='d-flex align-items-center'>
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={option}
                                                            checked={answers[questionIndex] === option}
                                                            onChange={() => handleAnswer(questionIndex, option)}
                                                        />
                                                        <span className='ps-2 mb-0'>{option}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='px-2'>
                                            <textarea
                                                value={answers[questionIndex]}
                                                onChange={(e) => handleAnswer(questionIndex, e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {/* ************************************************************* */}
                {/* Start the Portion */}
                <div style={{ marginBottom: '1rem' }}>
                    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        {fileSets.map((fileSet, index) => (
                            <div className="row" key={index}>
                                <div style={{ display: 'flex' }}>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor={`upload_file_${index}`}>
                                                <span>File Upload</span>
                                                <input
                                                    type='file'
                                                    id={`upload_file_${index}`}
                                                    name={`upload_file_${index}`}
                                                    className='form-control'
                                                    multiple
                                                    onChange={(e) => handleUploadFileChange(e, index)}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-2" style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        <label htmlFor="dsd" />
                                        <button style={{ height: '2rem' }} type="button" className="btn btn-danger" onClick={() => deleteFileSet(index)}>X</button>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor={`file_comment_${index}`}>
                                            <span>Type of file</span>
                                            <textarea
                                                id={`file_comment_${index}`}
                                                className='form-control'
                                                name={`file_comment_${index}`}
                                                placeholder="Comment Something"
                                                value={fileSet.fileComment}
                                                onChange={(e) => handleCommentChange(e, index)}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '2rem', marginTop: '0.2rem' }} >
                            <div className="form-group">
                                <button type="button" className="btn btn-success" onClick={addMoreFields}>Add More</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* table Start  */}
                <div className='table-wrap'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>File Upload</th>
                                <th>File Comment</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {checklistData.length > 0 ? (
                                checklistData.map((item, index) => (
                                    <tr key={index}>
                                        {/* <td><a >{item.upload_file}</a></td> */}
                                        {/* <td><a href={`${window.location.origin}/uploads/lead_bank_statement_document/${item.upload_file}`} >
                                            {item.upload_file}
                                        </a></td> */}
                                        <td>
                                            <a href={item.file_url} target="_blank" rel="noopener noreferrer"> {item.upload_file} </a>
                                        </td>
                                        <td>{item.file_comment}</td>
                                        <td>
                                            <button style={{ height: '2rem' }} type="button" className="btn btn-danger" onClick={() => handleDelete(item.id)}>X</button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* tale end */}

                {/* End the Portion */}
                {/* ************************************************************* */}

                <div className='row'>

                    <div className="col-6 text-start">
                        <button disabled={leadStatusFlag === true} className={fieldInvestigationFalg && showButtonOrNot ? "btn btn-success d-none" : "btn btn-success"} onClick={() => handleSaveDraftAndFinal("draft")}>Save As Draft</button>
                    </div>

                    <div className={fieldInvestigationFalg ? "d-none col-6 text-end" : "col-6 text-end"}>
                        <button disabled={leadStatusFlag === true} className="btn btn-success" onClick={handleSave}>Complete</button>
                    </div>

                    <div className="col-6 text-end">
                        <button disabled={leadStatusFlag === true} className={fieldInvestigationFalg ? "btn btn-primary" : "d-none btn btn-primary"} onClick={() => handleSaveDraftAndFinal("final")}>Final Save</button>
                    </div>
                </div>

            </div>



        </div>
    );
}

function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        leadInfo: state.leadInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadQuestionnaire);