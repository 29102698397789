import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui  
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as reportActions from "../../actions/reportActions";

import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AllLeadInteractionsPage = (props) => {
    const { actions, leadsData, usersData, sourceData, getUserModuleData, masterProfileData, leadStatusData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allLeads, setAllLeads] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showLeadDetails, setShowLeadDetails] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [userRoleType, setUserRoleType] = useState('');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Leads')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);


    const onSubmit = (data) => {
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'assign_to': selectedUsers,
        };

        console.log("params = ", params);
        const requestData = encryptData(params);
        const response = actions.getAllLeadInteractions(requestData, true, true);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setShowLeadDetails(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllLeads(resultArr);
            } else {
                setAllLeads([]);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });

    };


    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        setShowLeadDetails(false);
        console.log("Render AllLeadInteractionsPage component ", showLeadDetails);
        if (!showLeadDetails) {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "user_role_type": userRoleType };
            const requestData = encryptData(obj);
            const response = actions.getAllLeadInteractions(requestData);
            response.then((result) => {
                console.log("Promise resolved -  lead list:", result);
                if (result.data.success === 1) {
                    setShowLeadDetails(true);
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllLeads(resultArr);
                }
            }).catch((error) => {
                console.error("Promise rejected -  lead list:", error);
            });
        }


        if (Array.isArray(usersData.data)) {
            const extractedData = usersData.data.map(item => {
                return { id: item.user_id, name: item.name };
            });
            const resultArr = Object.values(extractedData);
            setAllUsers(resultArr);
        }

    }, [leadsData, setAllUsers]);

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.id));
        if (btnAction === 'edit') {
            const url = `/dashboard/edit-lead/${encodedId}`;
        } else {
            setViewModalInfo(itemObj);
            setOpen(true);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allLeads.filter(item => {
        const name = item.name?.toLowerCase() || '';
        const formattedID = item.formatetdID?.toLowerCase() || '';
        const statusName = item.statusName?.toLowerCase() || '';
        const mobile = item.mobile?.toLowerCase() || '';

        return (
            name.includes(searchQuery.toLowerCase()) ||
            formattedID.includes(searchQuery.toLowerCase()) ||
            statusName.includes(searchQuery.toLowerCase()) ||
            mobile.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    //   if (!userHasPermission) {
    //     return <AccessDenied />;
    //   }

    return (
        <>
            <Helmet>
                <title> Leads | Ckers </title>
            </Helmet>

            <Container>
                <div id='main-wrap' className='mb-3 mmt-7 filter'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row mx-0'>
                            <div className="col">
                                <div className='form-group'>
                                    <span>Assign To</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="assign_to"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Search</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div id='main-wrap' className='mb-4'>
                    <div className='row justify-content-between'>
                        <div className='col-md-8'>
                            <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                All Leads Interactions
                            </Typography>
                        </div>
                        <div className='col-md-4 pe-0 ps-lg-5'>
                            <div className="form-group">

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Status, Name . . . . "
                                    id="search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                            </div>
                        </div>
                    </div>
                    <div className='table-wrap  table-responsive'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Lead Name</th>
                                    <th className="text-nowrap">Lead Creation</th>
                                    <th className="text-nowrap">Bureau</th>
                                    <th className="text-nowrap">FI Visit</th>
                                    <th className="text-nowrap">Sales Score card completion</th>
                                    <th className="text-nowrap">Sales Rejection</th>
                                    <th className="text-nowrap">Sent to Credit</th>
                                    <th className="text-nowrap">Credit Score card completion</th>
                                    <th className="text-nowrap">Credit Reject</th>
                                    <th className="text-nowrap">Sanction</th>
                                    <th className="text-nowrap">Disbursement to dealer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">
                                            <NavLink to={`/dashboard/edit-lead/${btoa(String(item.id))}`} style={{color: "black"}} target="_blank" rel="noopener noreferrer">{item.name}</NavLink>
                                        </td>
                                        <td className="text-nowrap">
                                            {item.lead_creation_user}
                                            <br/>
                                            {item.lead_creation}
                                            <br/>
                                            {item.lead_creation_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.bureau_user}
                                            <br/>
                                            {item.bureau}
                                            <br/>
                                            {item.bureau_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.fi_visit_user}
                                            <br/>
                                            {item.fi_visit}
                                            <br/>
                                            {item.fi_visit_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.sales_score_card_completion_user}
                                            <br/>
                                            {item.sales_score_card_completion}
                                            <br/>
                                            {item.sales_score_card_completion_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.sales_rejection_user}
                                            <br/>
                                            {item.sales_rejection}
                                            <br/>
                                            {item.sales_rejection_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.sent_to_credit_user}
                                            <br/>
                                            {item.sent_to_credit}
                                            <br/>
                                            {item.sent_to_credit_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.credit_score_card_completion_user}
                                            <br/>
                                            {item.credit_score_card_completion}
                                            <br/>
                                            {item.credit_score_card_completion_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.credit_rejection_user}
                                            <br/>
                                            {item.credit_rejection}
                                            <br/>
                                            {item.credit_rejection_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.sanction_user}
                                            <br/>
                                            {item.sanction}
                                            <br/>
                                            {item.sanction_sub_status}
                                        </td>
                                        <td className="text-nowrap">
                                            {item.disbursement_to_dealer_user}
                                            <br/>
                                            {item.disbursement_to_dealer}
                                            <br/>
                                            {item.disbursement_to_dealer_sub_status}
                                            <br/>
                                            {item.dateOfDisbursement}
                                        </td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={10} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Lead  : {viewModalInfo.name}</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Lead ID :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.id}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Created By :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.lead_created_by}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Lead Status :</b></div>
                            <div className='col-7 ps-0'><span className={(viewModalInfo.status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}

function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        usersData: state.usersData,
        sourceData: state.sourceData,
        getUserModuleData: state.getUserModuleData,
        masterProfileData: state.masterProfileData,
        leadStatusData: state.leadStatusData,
        getLoggedinUserChild: state.getLoggedinUserChild,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default, reportActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeadInteractionsPage);