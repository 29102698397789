import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

export function campaignsDataSuccess(campaignData) {
    return { type: types.LOAD_CAMPAIGN_DATA_SUCCESS, campaignData };
}

export function campaignsInfoSuccess(campaignInfo) {
    return { type: types.LOAD_CAMPAIGN_SUCCESS_INFO, campaignInfo };
}


const campaignData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_CAMPAIGN, params);
      } else {
        response = await agent.post(API.GET_CAMPAIGN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const campaignInfo = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_CAMPAIGN, params);
      } else {
        response = await agent.post(API.GET_CAMPAIGN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const createCampaign = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_CAMPAIGN, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

  export default {
    createCampaign,
    campaignData,
    campaignInfo,
    campaignsDataSuccess,
    campaignsInfoSuccess,
  }