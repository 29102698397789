import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function createLeadReducer(state = initialState.createLead, action) {
  switch (action.type) {
    case types.LEAD_CREATE_STATUS:
      return action.createLead;
    default:
      return state;
  }
}