import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import * as masterAction from "../../actions/masterAction";

import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const CreateApprovalMasterPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, departmentInfo } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [approvalMasterId, setApprovalMasterId] = useState('');
    const [showDepartmentDetails, setShowDepartmentDetails] = useState(false);

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [allRoles, setAllRoles] = useState([]);
    const [getAllUsersListData, setAllUsersListData] = useState([]);
    const [selectedAssignTo, setSelectedAssignTo] = useState([]);
    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;

    const [selectedRole, setSelectedRole] = useState('');

    const [formEntries, setFormEntries] = useState([{ role: '', users: [], selectedUsers: [], sequence: '' }]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Approval Master');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.rolesData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  rolesData:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const extractedData = results.map(item => {
                    return { id: item.role_id, name: item.role_name };
                });
                const resultArr = Object.values(extractedData);
                setAllRoles(resultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  rolesData:", error);
        });

    }, [setAllRoles]);

    const handleChangeRole = (index, event) => {
        const selectedValue = event.target.value;
        setSelectedRole(selectedValue);

        const updatedFormEntries = [...formEntries];
        updatedFormEntries[index].role = selectedValue;

        if (selectedValue !== "") {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": selectedValue };
            const requestData = encryptData(obj);

            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => {
                        return { id: item.user_id, name: item.name };
                    });
                    const resultArr = Object.values(extractedData);
                    updatedFormEntries[index].users = resultArr;
                    setFormEntries(updatedFormEntries);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        } else {
            updatedFormEntries[index].users = [];
            setFormEntries(updatedFormEntries);
        }
    };

    const handleChangeSequence = (index, event) => {
        const { value } = event.target;
        const updatedFormEntries = [...formEntries];
        const isDuplicate = updatedFormEntries.some((entry, i) => entry.sequence === value && i !== index);
        if (isDuplicate) {
            toast.error("Sequence value must be unique.", {
                position: "top-right",
            });
        } else {
            updatedFormEntries[index].sequence = value;
            setFormEntries(updatedFormEntries);
        }
    };

    const handleAddMore = () => {
        setFormEntries([...formEntries, { role: '', users: [], selectedUsers: [], sequence: '' }]);
    };

    useEffect(() => {
        const fetchApprovalMasterData = async () => {
            try {

                if (id !== undefined) {
                    const decodedApprovalMasterId = atob(id);
                    setApprovalMasterId(id);
                    const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'approval_master_id': decodedApprovalMasterId };
                    const requestData = encryptData(obj);
                    const response = actions.getApprovalMaster(requestData);

                    response.then((result) => {

                        if (result.data.success === 1) {
                            const approvalMasterData = result.data.data.data[0];

                            // Ensure approvalMasterData is valid
                            if (approvalMasterData) {
                                console.log("approvalMasterData : ", approvalMasterData);

                                // Set the static fields
                                setValue('task_name', approvalMasterData.task_name);
                                setValue('status', approvalMasterData.status);
                                setValue('approval_workflow_for', approvalMasterData.approval_workflow_for);

                                // Ensure approvalData is valid
                                if (approvalMasterData.approvalData && Array.isArray(approvalMasterData.approvalData)) {
                                    // Prepopulate the dynamic form entries
                                    const populatedFormEntries = approvalMasterData.approvalData.map(entry => ({
                                        role: entry.role,
                                        users: [], // We'll populate users separately
                                        selectedUsers: entry.assign_to.map(user => ({
                                            key: user.key,
                                            value: user.value
                                        })),
                                        sequence: entry.sequence
                                    }));

                                    setFormEntries(populatedFormEntries);

                                    // console.log("populatedFormEntries : ", populatedFormEntries);

                                    // Fetch users for each role
                                    populatedFormEntries.forEach((entry, index) => {
                                        const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": entry.role };
                                        const requestData = encryptData(obj);
                                        const response = actions.getUserByRole(requestData);
                                        response.then((result) => {
                                            if (result.data.success === 1) {
                                                const results = result.data.data.data;
                                                const extractedData = results.map(item => ({
                                                    id: item.user_id,
                                                    name: item.name
                                                }));
                                                const resultArr = Object.values(extractedData);

                                                setFormEntries(prevFormEntries => {
                                                    const updatedFormEntries = [...prevFormEntries];
                                                    updatedFormEntries[index].users = resultArr;
                                                    return updatedFormEntries;
                                                });
                                            }
                                        });
                                    });
                                }
                            }
                        }
                    }).catch((error) => {
                        console.error("Promise rejected -  getApprovalMaster:", error);
                    });
                }
            } catch (error) {
                console.error('Error fetching approval master data:', error);
            }
        };

        fetchApprovalMasterData();
    }, [setValue]);

    const onSubmit = (data) => {

        // Validate status
        if (data.approval_workflow_for.trim() === "") {
            toast.error("Please select approval workflow for option.", {
                position: "top-right",
            });
            return; // Exit function if validation fails
        }

        // Validate task_name
        if (data.task_name.trim() === "") {
            toast.error("Task name is blank.", {
                position: "top-right",
            });
            return; // Exit function if validation fails
        }
    
        // Validate status
        if (data.status.trim() === "") {
            toast.error("Please select status.", {
                position: "top-right",
            });
            return; // Exit function if validation fails
        }
    
        // Validate formEntries
        const hasInvalidEntry = formEntries.some(entry => {
            return entry.selectedUsers.length === 0 || entry.sequence.trim() === "";
        });
    
        if (hasInvalidEntry) {
            toast.error("Please fill all fields in approval entries.", {
                position: "top-right",
            });
            return; // Exit function if validation fails
        }
    
        // If all validations pass, proceed with form submission
        let submissionData = {
            task_name: data.task_name,
            status: data.status,
            approval_workflow_for: data.approval_workflow_for,
            approval: formEntries.map(entry => ({
                role: entry.role,
                assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                sequence: entry.sequence,
            })),
        };
    
        if (id !== undefined) {
            const decodedApprovalMasterId = atob(id);
            setApprovalMasterId(id);
    
            submissionData = {
                approval_master_id: decodedApprovalMasterId,
                task_name: data.task_name,
                status: data.status,
                approval_workflow_for: data.approval_workflow_for,
                approval: formEntries.map(entry => ({
                    role: entry.role,
                    assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                    sequence: entry.sequence,
                })),
            };
        }
    
        // Encrypt data before sending
        const requestData = encryptData(submissionData);
        const response = actions.createApprovalMaster(requestData);
    
        // Handle response
        response.then((result) => {
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                setTimeout(() => {
                    navigate('/dashboard/approval-master');
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            toast.error(error.message, {
                position: "top-right",
            });
        });
    };    

    const handleRemove = (index) => {
        const updatedFormEntries = formEntries.filter((_, i) => i !== index);
        setFormEntries(updatedFormEntries);
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>{id ? "Edit" : "Create"} Approval Workflow</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    {id ? "Edit" : "Create"} Approval Workflow
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="reporting-user">
                                        <span>Approval Workflow For <a style={{ color: "red" }}>*</a></span>
                                        <select name='approval_workflow_for' className="form-select" {...register('approval_workflow_for', { required: true })}>
                                            <option value="">Please select option</option>
                                            <option value="1">Incident</option>
                                            <option value="2">Request</option>
                                        </select>
                                    </label>
                                    {errors.approval_workflow_for && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Task Name <a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='task_name' className='form-control'
                                            {...register('task_name', { required: true })} />
                                    </label>
                                    {errors.task_name && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="reporting-user">
                                        <span>Status <a style={{ color: "red" }}>*</a></span>
                                        <select name='status' className="form-select" {...register('status', { required: true })}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </label>
                                    {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                        </div>

                        {formEntries.map((entry, index) => (
                            <div className='row' key={index}>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor={`role-${index}`}>
                                            <span>Role</span>
                                            <select
                                                name={`role-${index}`}
                                                className="form-select"
                                                value={entry.role}
                                                onChange={(e) => handleChangeRole(index, e)}
                                            >
                                                <option value="">Select Role</option>
                                                {allRoles
                                                    .filter((option) => !formEntries.some((entry) => entry.role === option.id) || option.id === entry.role)
                                                    .map((option) => (
                                                        <option value={option.id} key={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <span>Assign to <a style={{ color: "red" }}>*</a></span>
                                        <div className="text-dark">
                                            <Multiselect
                                                id={`assign_to-${index}`}
                                                options={entry.users?.map((option) => ({
                                                    key: option.id,
                                                    value: option.name,
                                                })) || []}
                                                selectedValues={entry.selectedUsers || []}
                                                displayValue="value"
                                                onSelect={(selectedItems) => {
                                                    const updatedFormEntries = [...formEntries];
                                                    updatedFormEntries[index].selectedUsers = selectedItems;
                                                    setFormEntries(updatedFormEntries);
                                                }}
                                                onRemove={(selectedItems) => {
                                                    const updatedFormEntries = [...formEntries];
                                                    updatedFormEntries[index].selectedUsers = selectedItems;
                                                    setFormEntries(updatedFormEntries);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='form-group'>
                                        <label htmlFor={`sequence-${index}`}>
                                            <span>Sequence</span>
                                            <input
                                                type='number'
                                                name={`sequence-${index}`}
                                                className='form-control'
                                                value={entry.sequence}
                                                onChange={(e) => handleChangeSequence(index, e)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                {index !== 0 && (
                                    <div className='col-md-1 d-flex align-items-center'>
                                        <button type='button' onClick={() => handleRemove(index)} className='btn float-right btn-danger dismiss-btn'>
                                            <i className="bi bi-x-lg">&nbsp;</i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group text-end'>
                                    <button type='button' onClick={handleAddMore} className='btn btn-info'>
                                        Add More
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        departmentInfo: state.departmentInfo,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(departmentActions.default, userActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateApprovalMasterPage);