import react, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { Button, Typography, Modal, Container, Box, } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as leadActions from "../../actions/leadActions";
import * as moduleAction from "../../actions/moduleAction";
import Loader from "../../components/Loader";
import * as masterAction from "../../actions/masterAction";

const TaskForm = ({
    formValues,
    handleSubmit,
    onSubmit,
    formRef,
    handleChange,
    handleChangeApprovalMaster,
    handleChangeAssignedGroup,
    handleChangeAssignTo,
    handleFileChange,
    handleChangeIsCompleted,
    handleChangeSequence,
    selectedApprovalMaster,
    allApprovalMaster,
    selectedAssignedGroup,
    allGroupData,
    getAllUsersListData,
    selectedApprovalMasterUser,
    fieldsShow,
    showDiv,
    isStart,
    userRoleType,
    isLastSequence,
    overrideRequestFlow,
    incidentTaskId,
    presentSequence,
    toggleDiv,
    onChangeApprovalWorkflow,
    isCompleted,
    assignTo,
    isEditableStatus,
    loggedInUserId,
    taskCreatorUser,
    shouldReset
}) => {
    const { register, reset, formState: { errors } } = useForm({
        defaultValues: {
            report_file: ''
        }
    });

    const fileInputRef = useRef(null);

    const [buttonStatus, setButtonStatus] = useState(false);

    useEffect(() => {
        // Reset the file input when the component mounts
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, []);

    useEffect(() => {
        // Reset the form with default values whenever formValues change
        reset({
            ...formValues,
            report_file: ''
        });
        // Reset the file input to clear any previously selected files
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [formValues, reset]);    

    const [note, setNote] = useState('');
    const handleNoteChange = (e) => {
        setNote(e.target.value);
        if (handleChange) {
            handleChange(e);
        }
    };

    useEffect(() => {
        if (shouldReset) {
            setNote('');
        }
    }, [shouldReset, setNote]);

    useEffect(() => {

        if (assignTo) {
            const assignToArray = assignTo.split(",").map(item => item.trim());

            const isAssignedToLoggedInUser = assignToArray.includes(loggedInUserId);

            if (isAssignedToLoggedInUser || overrideRequestFlow || userRoleType === "Superadmin") {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }
        }
    }, [assignTo, setButtonStatus, overrideRequestFlow]);

    return (
        <div className='accordion-body'>
            <div className='mt-4'>
                <div className='row justify-content-between'>

                    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={formRef}>
                        <div className="row task" id="task">

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="approval-master">
                                        <span>Task List <a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id='approval_master'
                                            name='approval_master'
                                            className="form-select"
                                            {...register('approval_master')}
                                            disabled={isStart || selectedAssignedGroup !== '' === true}
                                            value={selectedApprovalMaster}
                                            onChange={handleChangeApprovalMaster}
                                        >
                                            <option value=''>Select Task</option>
                                            {allApprovalMaster.map((option) => (
                                                <option value={option.id} key={option.id}>
                                                    {option.task_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="task_name">
                                        <span>{config.MODULE_SUB_TYPE_NAME} Name</span>
                                        <input type="text" id="task_name" name="task_name" className="form-control"
                                            {...register('task_name')}
                                            value={formValues.task_name}
                                            onChange={handleChange}
                                            disabled={isStart}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="due_date">
                                        <span>Due Date <a style={{ color: "red" }}>*</a></span>
                                        <input type="date" id="due_date" name="due_date" className="form-control"
                                            {...register('due_date')}
                                            value={formValues.due_date}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={`col-md-6 px-3 py-3 mb-md-0 mb-4 ${!fieldsShow ? 'd-none' : ''}`}>
                                <div className="row justify-content-between">
                                    <div className='col-md-10'>
                                        <RouterLink onClick={() => onChangeApprovalWorkflow(selectedApprovalMasterUser, 'view')} className='custom-button-task'>View Sequence</RouterLink>
                                    </div>
                                    <div className={taskCreatorUser === loggedInUserId || !taskCreatorUser ? 'col-md-2' : 'col-md-2 d-none'}>
                                        <RouterLink 
                                            onClick={() => onChangeApprovalWorkflow(selectedApprovalMasterUser, 'edit')} 
                                            className='custom-button'
                                        >
                                            <i className="bi bi-pen">&nbsp;</i>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className='form-group'>
                                    <label htmlFor="note">
                                        <span>Comment <a style={{ color: "red" }}>*</a></span>
                                        <textarea
                                            id="note"
                                            name='note'
                                            className="form-control"
                                            placeholder="Type Something"
                                            value={note}
                                            {...register('note', { required: true })}
                                            onChange={handleNoteChange}
                                        />
                                    </label> 
                                    {errors.note && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label htmlFor="report_file">
                                        <span>File Upload</span>
                                        <input
                                            type='file'
                                            multiple
                                            id='report_file'
                                            name='report_file'
                                            className='form-control'
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={(userRoleType === "Superadmin" && incidentTaskId !== '') || (isLastSequence && incidentTaskId !== '') || (selectedAssignedGroup !== "" && incidentTaskId !== '') ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <span>&nbsp;</span>
                                        <label className="form-check-label" htmlFor="is_completedd">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="is_completedd"
                                                name="is_completedd"
                                                checked={isCompleted}
                                                onChange={handleChangeIsCompleted}
                                            />
                                            <span>Mark Task as Completed</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={showDiv ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className="form-group">
                                    <label htmlFor="sequence">
                                        <span>Send Back To <a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id="sequence"
                                            name="sequence"
                                            className="form-select"
                                            {...register('sequence')}
                                            onChange={handleChangeSequence}
                                        >
                                            <option value="">Please Select</option>
                                            {selectedApprovalMasterUser
                                                .filter((option) => option.sequence === presentSequence || option.sequence < presentSequence)
                                                .map((option) => (
                                                    <option key={option.sequence} value={option.sequence}>
                                                        {option.roleName}
                                                    </option>
                                                ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='row my-3'>
                            <div className={incidentTaskId === '' ? 'col-md-12 d-flex justify-content-end' : "col-md-12 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>

                            <div className={incidentTaskId !== '' && selectedAssignedGroup !== '' ? 'col-md-12 d-flex justify-content-end' : "col-md-12 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                        <div className={selectedAssignedGroup === '' ? 'row my-3' : "row my-3 d-none"}>
                            <div className={incidentTaskId !== '' ? 'col-md-6 d-flex justify-content-start' : "col-md-6 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="proceed_next" className="btn btn-info" disabled={!buttonStatus}> {isLastSequence ? 'Approve' : 'Proceed To Next Step'}</button>
                                </div>
                            </div>

                            <div className={incidentTaskId === '' || !isStart ? 'col-md-4 d-none' : "col-md-4 d-flex justify-content-end"}>
                                <div className="form-group mb-0">
                                    <button className={!showDiv ? 'btn btn-primary' : "btn btn-primary d-none"} onClick={toggleDiv} disabled={!buttonStatus}>
                                        Send Back
                                    </button>

                                    <button type="submit" name="action" value="proceed_back" className={showDiv ? 'btn btn-danger d-flex justify-content-end' : "btn btn-danger d-none"} disabled={!buttonStatus}>Send Back</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default TaskForm;
