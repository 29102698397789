import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import { format } from 'date-fns';
import * as userActions from "../../actions/userActions";
import * as departmentActions from "../../actions/departmentActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
import * as groupsUsersActions from "../../actions/groupsUsersActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const Payment = (props) => {

    const { actions, leadsData, leadInfo, usersData, basicLeadInfo, userTypesData, masterProfileData, leadChecklistData, interactionTypeData, leadStatusData, departmentsData, rolesData } = props;
    const [userId, setUserId] = useState('');  
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();    
    const navigate = useNavigate(); 
    const [userHasPermission, setUserHasPermission] = useState(true);
   // const[intentUrl, setIntentUrl] = useState('upi://pay?pa=ps1.sdpay@fin&tr=FIDa1Mau9ODBJW28TSHDX&tn=Pay For 1 Rupees&am=22&cu=INR');
    const[intentUrl, setIntentUrl] = useState('');

    const [formValues, setFormValues] = useState({
        amount: ''
    });
 
    useEffect(() => {
       // run script
    }, [setIntentUrl]);    
    
    const handleSubmitForm = (data) => {
        const params = { 'amount': formValues.amount };
        if (userId !== '') {
            params.user_id = userId;
        }
        setIntentUrl('');
        console.log("form data = ", params);
        const requestData = encryptData(params);
        const response = actions.generateQRcode(requestData);
        response.then((result) => {
             if (result.data.success === 1) {
                if (result.data.intent_url !== '') {  
                    setIntentUrl(result.data.intent_url);  
                    toast.success("QR code generated sucessfully.", {
                        position: "top-right",
                    });
                } else {
                    toast.error("You can't generate QR code, Please try again!", {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
             toast.error(error, {
                position: "top-right",
            });
        });
    };   
    const handleChange = (e) => { 
       // setIntentUrl('');
        const { name, value } = e.target;      
        setFormValues({
            ...formValues,
            [name]: value,
        });         
    };
      
    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title> Payment </title>
            </Helmet>
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>Generate QR
                </Typography>
                <div id='main-wrap'>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Amount <a style={{ color: "red" }}>*</a></span>
                                        <input
                                            type='number'
                                            id='amount'
                                            name='amount'
                                            value={formValues.amount}
                                            className='form-control'
                                            placeholder=''
                                            {...register('amount', { required: formValues.amount === '' })}
                                            onChange={handleChange}
                                        />
                                        {errors.amount && <span className="required">This field is required.</span>}

                                    </label>
                                </div>

                                 <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Generate QR</button>
                                </div>
                            </div>
                            </div>    
                           
                            <div className='col-md-6'>
                                <div className='form-group'>
                                     <div className="float-right">
                                       { (intentUrl!=='') && (<QRCode  value={intentUrl} size={150} /> )  }
                                    </div>
                            </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(
            userActions.default,
            departmentActions.default,
            groupsUsersActions.default,
        ), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);