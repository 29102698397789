export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_LOGIN_SUCCESS = "LOADED_USER_LOGIN_SUCCESS";
export const LOADED_USER_OTP_SUCCESS = "LOADED_USER_OTP_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";

export const LOAD_USERS_DATA_SUCCESS = "LOAD_USERS_DATA_SUCCESS";
export const LOAD_DEPARTMENTS_DATA_SUCCESS = "LOAD_DEPARTMENTS_DATA_SUCCESS";
export const LOAD_DEPARTMENT_INFO_SUCCESS = "LOAD_DEPARTMENT_INFO_SUCCESS";

export const LOAD_ROLES_DATA_SUCCESS = "LOAD_ROLES_DATA_SUCCESS";
export const LOAD_ROLE_INFO_SUCCESS = "LOAD_ROLE_INFO_SUCCESS";
export const LOAD_ROLE_PERMISSION_DATA_SUCCESS = "LOAD_ROLE_PERMISSION_DATA_SUCCESS";
export const LOAD_ROLE_PERMISSION_INFO_SUCCESS = "LOAD_ROLE_PERMISSION_INFO_SUCCESS";

export const GET_SOURCE_OF_LEAD_DATA_SUCCESS = "GET_SOURCE_OF_LEAD_DATA_SUCCESS";
export const GET_LEAD_MASTER_PROFILE = "GET_LEAD_MASTER_PROFILE";
export const LOAD_LEAD_CHECKLIST_DATA_SUCCESS = "LOAD_LEAD_CHECKLIST_DATA_SUCCESS";
export const LOAD_LEADS_DATA_SUCCESS = "LOAD_LEADS_DATA_SUCCESS";
export const LOAD_LEAD_INFO_SUCCESS = "LOAD_LEAD_INFO_SUCCESS";
export const LOAD_COMMUNICATON_INFO_SUCCESS = "LOAD_COMMUNICATON_INFO_SUCCESS";
export const LOAD_INTERACTION_TYPE = "LOAD_INTERACTION_TYPE";
export const LOAD_LEAD_STATUS = "LOAD_LEAD_STATUS";
export const LEAD_CREATE_STATUS = "LEAD_CREATE_STATUS";
export const LOAD_CHECKLISTS_DATA_SUCCESS = "LOAD_CHECKLISTS_DATA_SUCCESS";
export const LOAD_LEAD_PROFILE_DATA_SUCCESS = "LOAD_LEAD_PROFILE_DATA_SUCCESS";
export const LOAD_SOURCE_LEAD_INFO_SUCCESS = "LOAD_SOURCE_LEAD_INFO_SUCCESS";
export const GET_USER_TYPES_DATA_SUCCESS = "GET_USER_TYPES_DATA_SUCCESS";
export const LOAD_LEADCOUNT_DATA_SUCCESS = "LOAD_LEADCOUNT_DATA_SUCCESS";
export const LOAD_LEAD_STATUS_INFO_SUCCESS = "LOAD_LEAD_STATUS_INFO_SUCCESS";
export const LOAD_CAMPAIGN_DATA_SUCCESS = "LOAD_CAMPAIGN_DATA_SUCCESS";
export const LOAD_CAMPAIGN_SUCCESS_INFO = "LOAD_CAMPAIGN_SUCCESS_INFO";
export const LOAD_LEADBREAKUP_DATA_SUCCESS = "LOAD_LEADBREAKUP_DATA_SUCCESS";
export const LOAD_LEADBREAKUP_DETAILS_DATA_SUCCESS = "LOAD_LEADBREAKUP_DETAILS_DATA_SUCCESS";
export const LOAD_LOGGEDIN_USER_ACTICITY_DATA_SUCCESS = "LOAD_LOGGEDIN_USER_ACTICITY_DATA_SUCCESS";
export const LOAD_LOGGEDIN_USER_FOLLOWUP_LEADS_DATA_SUCCESS = "LOAD_LOGGEDIN_USER_FOLLOWUP_LEADS_DATA_SUCCESS";
export const LOAD_LOGGEDIN_USER_NEW_LEADS_DATA_SUCCESS = "LOAD_LOGGEDIN_USER_NEW_LEADS_DATA_SUCCESS";
export const LOAD_INCIDENT_REPORTE_DATA_SUCCESS = "LOAD_INCIDENT_REPORTE_DATA_SUCCESS";
export const LOAD_INCIDENT_REPORT_STATUS_DATA_SUCCESS = "LOAD_INCIDENT_REPORT_STATUS_DATA_SUCCESS";
export const LOAD_INCIDENT_CATEGORY_DATA_SUCCESS = "LOAD_INCIDENT_CATEGORY_DATA_SUCCESS";
export const LOAD_INCIDENT_PRIORITY_DATA_SUCCESS = "LOAD_INCIDENT_PRIORITY_DATA_SUCCESS";
export const LOAD_ALL_TICKET_DATA_SUCCESS = "LOAD_ALL_TICKET_DATA_SUCCESS";
export const LOAD_VIEW_TICKET_DATA_SUCCESS = "LOAD_VIEW_TICKET_DATA_SUCCESS";
export const LOAD_GROUPS_USERS_DATA_SUCCESS = "LOAD_GROUPS_USERS_DATA_SUCCESS";
export const LOAD_INCIDENT_GROUP_DATA_SUCCESS = "LOAD_INCIDENT_GROUP_DATA_SUCCESS";
export const LOAD_INCIDENT_GROUP_SUCCESS_INFO = "LOAD_INCIDENT_GROUP_SUCCESS_INFO";
export const LOAD_LEAD_GST_DATA_SUCCESS = "LOAD_LEAD_GST_DATA_SUCCESS";
export const LOAD_LEAD_GST_BY_LEAD_INFO_SUCCESS = "LOAD_LEAD_GST_BY_LEAD_INFO_SUCCESS";
export const LOAD_CO_APPLICANT_DATA_SUCCESS = "LOAD_CO_APPLICANT_DATA_SUCCESS";
export const LOAD_GUARANTOR_DATA_SUCCESS = "LOAD_GUARANTOR_DATA_SUCCESS";
export const LOAD_LEAD_ITR_DATA_SUCCESS = "LOAD_LEAD_ITR_DATA_SUCCESS";
export const LOAD_LEAD_ITR_BY_LEAD_DATA_SUCCESS = "LOAD_LEAD_ITR_BY_LEAD_DATA_SUCCESS";
export const LOAD_LEAD_BANK_STATEMENT_DATA_SUCCESS = "LOAD_LEAD_BANK_STATEMENT_DATA_SUCCESS";
export const LOAD_LEAD_BANK_STATEMENT_BY_LEAD_DATA_SUCCESS = "LOAD_LEAD_BANK_STATEMENT_BY_LEAD_DATA_SUCCESS";


 
