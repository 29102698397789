import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as beatPlanActions from "../../actions/beatPlanActions";
import Loader from "../../components/Loader";
import AccessDenied from '../common/AccessDenied';
import * as reportActions from "../../actions/reportActions";
import * as campaignsActions from "../../actions/campaignsActions";
import * as leadActions from "../../actions/leadActions";

// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const TatReportPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allTatLeadsInGroupData, setAllTatLeadsInGroupData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [open, setOpen] = useState(false);
    const [tatReportClickOnCountData, setTatReportClickOnCountData] = useState([]);

    const [selectedLeadSource, setSelectedLeadSource] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [allLeadSource, setAllLeadSource] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    
    const [userTatReport, setUserTatReport] = useState('');

    const [allTatLeadsData, setAllTatLeadsData] = useState([]);
    const [currentTatLeadsPage, setCurrentTatLeadsPage] = useState(1);
    const [searchTatLeadsQuery, setSearchTatLeadsQuery] = useState('');
    const [openAllTatLead, setOpenAllTatLead] = useState(false);

    const [allTatLeadsDataForUseAgain, setAllTatLeadsDataForUseAgain] = useState([]);
    
    const multiselectRef = useRef(null);
    const multiselectRefLeadProfile = useRef(null);
    const multiselectRefCampaign = useRef(null);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'TAT Report')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);
    
        response.then((result) => {
            // console.log("Promise resolved - getLoggedinUserChild data:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
    
                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const sortedData = extractedData.sort((a, b) => a.name.localeCompare(b.name));
    
                setAllLoggedinUserChild(sortedData);
            }
        }).catch((error) => {
            //  console.error("Promise rejected - getLoggedinUserChild data :", error); 
        });
    }, [setAllLoggedinUserChild]);
    

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const objSource = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestSourceData = encryptData(objSource);
        const responseSource = actions.sourceData(requestSourceData, false);
        responseSource.then((result) => {
          // console.log("Promise resolved -  source data:", result); 
          if (result.data.success === 1) {
            const results = result.data.data.data
            const filteredResultArr = results.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
              a.source_of_lead.localeCompare(b.source_of_lead)
            );
            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.source_of_lead };
            });
            const resultArr = Object.values(extractedData);
            setAllLeadSource(resultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  source data :", error); 
        });
    
      }, [setAllLeadSource]);

      useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.campaign_name };
            });
            const resultArrObj = Object.values(extractedData);
            setAllCampaigns(resultArrObj);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);

    useEffect(() => {

        const params = {};
        setPreloaderStatus(true);

        const requestData = encryptData(params);
        const response = actions.getTatReportData(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                const results = result.data.data.allLeadsInGroup;
                const allLeads = result.data.data.allLeads;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter(item => item.UserName.trim() !== '');
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.UserName.localeCompare(b.UserName)
                );
                const resultArrObj = Object.values(sortedResultArr);
                setAllTatLeadsInGroupData(resultArrObj);
                setAllTatLeadsData(allLeads);
                setAllTatLeadsDataForUseAgain(allLeads);

                setPreloaderStatus(false);
            } else {
                setPreloaderStatus(false);
                setAllTatLeadsInGroupData([]);
                setAllTatLeadsData([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            // console.error("Promise rejected -  lead list:", error);
        });

    }, [setAllTatLeadsInGroupData, setAllTatLeadsData, setAllTatLeadsDataForUseAgain]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const exportTatLeadsInGroupReport = async () => {
        const table = document.getElementById('exportTatReportExcelFile');

        TableToExcel.convert(table, {
            name: 'TAT-Report.xlsx',
            sheet: {
                name: 'TAT-Report',
            },
        });
    };

    const handleClickOnCount = (UserId) => {
        const clickOnCountparams = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'UserId': UserId,
        };

        setUserTatReport(UserId);

        const requestData = encryptData(clickOnCountparams);
        const response = actions.getTatReportByUserData(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setTatReportClickOnCountData(resultArr);
                setOpen(true);
            } else {
                setPreloaderStatus(false);
                setTatReportClickOnCountData([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            // console.error("Promise rejected -  lead list:", error);
        });
    };

    const onSubmit = (data, event) => {
        const action = event.nativeEvent.submitter.value;

        if (action === "user_tat_report_submit") {
            handleFormUserTatReportSubmit(data);
        } else if (action === "all_user_tat_report_submit") {
            handleFormAllUserTatReportSubmit(data);
        }
    };

    const handleFormUserTatReportSubmit = (data) => {

        const clickOnCountparams = { 'UserId': userTatReport, 'start_date': data.start_date, 'end_date': data.end_date, "lead_source": selectedLeadSource, "campaign":selectedCampaign };

        const requestData = encryptData(clickOnCountparams);
        const response = actions.getTatReportByUserData(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setTatReportClickOnCountData(resultArr);
                setOpen(true);
            } else {
                setPreloaderStatus(false);
                setTatReportClickOnCountData([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            // console.error("Promise rejected -  lead list:", error);
        }); 
    };

    const handleFormAllUserTatReportSubmit = (data) => {

        const clickOnCountparams = { 'lead_assigned_user': selectedUsers, 'start_date': data.all_tat_start_date, 'end_date': data.all_tat_end_date};

        const requestData = encryptData(clickOnCountparams);
        const response = actions.getTatReportFiltered(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                const allLeads = result.data.data.allLeads;
                setAllTatLeadsData(allLeads);
                setPreloaderStatus(false);
            } else {
                setPreloaderStatus(false);
                setAllTatLeadsData([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            // console.error("Promise rejected -  lgetTatReportFiltered:", error);
        }); 
    };

    const filteredItems = tatReportClickOnCountData.filter(item => {
        const leadName = item.LeadName?.toLowerCase() || '';

        return (
            leadName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const backToTatReportHandler = () => {
        setOpen(false);
        setOpenAllTatLead(false);
        setTatReportClickOnCountData([]);
        setAllTatLeadsData([]);
        
        setValue("all_tat_start_date", "");
        setValue("all_tat_end_date", "");
        setValue("start_date", "");
        setValue("end_date", "");

        setSelectedLeadSource([]);
        setSelectedCampaign([]);
        setSelectedUsers([]);
        reset();

        if (multiselectRef.current) {
            multiselectRef.current.resetSelectedValues();
        }

        if (multiselectRefCampaign.current) {
            multiselectRefCampaign.current.resetSelectedValues();
        }

        if (multiselectRefLeadProfile.current) {
            multiselectRefLeadProfile.current.resetSelectedValues();
        }
    }

    const extendTatLeadsReport = () => {
        setPreloaderStatus(true);
        setAllTatLeadsData(allTatLeadsDataForUseAgain);
        setOpenAllTatLead(true);
        setPreloaderStatus(false);
    };

    const handleAllLeadsPageChange = (newPage) => {
        setCurrentTatLeadsPage(newPage);
    };

    const filteredAllTatLeadsItems = allTatLeadsData.filter(item => {
        const leadName = item.LeadName?.toLowerCase() || '';
        return (
            leadName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndexAllTatLeads = (currentTatLeadsPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndexAllTatLeads = startIndexAllTatLeads + config.HUNDREAD_PER_PAGE;
    const paginatedItemsAllTatLeads = filteredAllTatLeadsItems.slice(startIndexAllTatLeads, endIndexAllTatLeads);
    const totalPagesAllTatLeads = Math.ceil(filteredAllTatLeadsItems.length / config.HUNDREAD_PER_PAGE);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>TAT Report</title>
            </Helmet>

            <Container>

                <div id='main-wrap' className={open || openAllTatLead ? "mb-4 d-none" : "mb-4"}>

                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                TAT Report
                            </Typography>
                        </div>

                        <div className='col-md-3 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <button className="btn btn-success text-white ms-3" onClick={extendTatLeadsReport}>
                                    Extend Report
                                </button>
                            </div>
                        </div>

                        <div className='col-md-2 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <button className="btn btn-info text-white ms-3" onClick={() => exportTatLeadsInGroupReport()}>
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='table-wrap table-responsive'>
                        <table className="table table-hover" id="exportTatReportExcelFile">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>User Name</th>
                                    <th className='text-nowrap'>Add Lead - Count</th>
                                    <th className='text-nowrap'>Scorecard Creation - Count</th>
                                    <th className='text-nowrap'>Scorecard Creation - TAT</th>
                                    <th className='text-nowrap'>Bureau Pull - Count</th>
                                    <th className='text-nowrap'>Bureau Pull - TAT</th>
                                    <th className='text-nowrap'>FI - Count</th>
                                    <th className='text-nowrap'>FI - TAT</th>
                                    <th className='text-nowrap'>Sent to Habile - Count</th>
                                    <th className='text-nowrap'>Sent to Habile - TAT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTatLeadsInGroupData.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <button onClick={() => handleClickOnCount(item.UserId)} className="btn btn-link p-0 custom-button text-nowrap">{item.UserName}</button>
                                        </td>
                                        <td className='text-nowrap'>{item.AddLeadCount}</td>
                                        <td className='text-nowrap'>{item.ScorecardCreationCount}</td>
                                        <td className='text-nowrap'>{item.AvgScorecardCreationTAT}</td>
                                        <td className='text-nowrap'>{item.BureauPullCount}</td>
                                        <td className='text-nowrap'>{item.AvgBureauPullTAT}</td>
                                        <td className='text-nowrap'>{item.FICount}</td>
                                        <td className='text-nowrap'>
                                            {item.FITat || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.FITatLeadCreated || '\u00A0'}
                                        </td>
                                        <td className='text-nowrap'>{item.SentToHabileCount}</td>
                                        <td className='text-nowrap'>
                                            {item.SentToHabileTAT || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.SentToHabileTATLeadCreated || '\u00A0'}
                                        </td>
                                    </tr>
                                ))}

                                {allTatLeadsInGroupData.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={10} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id='main-wrap' className={openAllTatLead ? "mb-4" : " mb-4 d-none"}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div id='main-wrap' className='mb-3 filter'>
                            <div className='row mx-0'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <span>Users</span>
                                        <div className="text-dark">
                                            <Multiselect
                                                id="assign_to"
                                                ref={multiselectRef} 
                                                options={allLoggedinUserChild.map((option) => ({
                                                    key: option.id,
                                                    value: option.name,
                                                }))}
                                                displayValue="value"
                                                onSelect={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedUsers(selectedValues);
                                                }}
                                                onRemove={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedUsers(selectedValues);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label htmlFor="start-date">
                                            <span>Start Date</span>
                                            <input type='date' name='all_tat_start_date' className='form-control'{...register('all_tat_start_date')} />
                                        </label>
                                        {errors.all_tat_start_date && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group'>
                                        <label htmlFor="end-date">
                                            <span>End Date</span>
                                            <input type='date' name='all_tat_end_date' className='form-control'{...register('all_tat_end_date')} />
                                        </label>
                                        {errors.all_tat_end_date && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group mb-2'>
                                        <span className='d-block'>&nbsp;</span>
                                        <button type="submit" name="action" value="all_user_tat_report_submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="row justify-content-between">
                        <div className="col-md-10">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                All User TAT Report
                            </Typography>
                        </div>

                        <div className='col-md-2 text-end ps-lg-2'>
                            <div className='d-flex flex-md-row flex-column align-md-items-center align-items-start justify-content-between'>
                                <button onClick={backToTatReportHandler} onKeyDown={backToTatReportHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded order-md-2 order-1'> Back to Report</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-wrap table-responsive">
                        <table className="table table-hover" id="exportUserTatReportExcelFile">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>User Name</th>
                                    <th className='text-nowrap'>Lead Name</th>
                                    <th className='text-nowrap'>Mobile</th>
                                    <th className='text-nowrap'>Add Lead</th>
                                    <th className='text-nowrap'>Scorecard Creation</th>
                                    <th className='text-nowrap'>Scorecard Creation - TAT</th>
                                    <th className='text-nowrap'>Bureau Pull</th>
                                    <th className='text-nowrap'>Bureau Pull - TAT</th>
                                    <th className='text-nowrap'>FI Creation</th>
                                    <th className='text-nowrap'>FI Creation - TAT</th>
                                    <th className='text-nowrap'>Sent to Habile</th>
                                    <th className='text-nowrap'>Sent to Habile - TAT</th>
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItemsAllTatLeads.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.UserName}</td>
                                        <td className="text-nowrap">{item.LeadName}</td>
                                        <td className="text-nowrap">{item.LeadMobile}</td>
                                        <td className="text-nowrap">{item.LeadCreatedAt}</td>
                                        <td className="text-nowrap">{item.ScCreatedAt}</td>
                                        <td className="text-nowrap">{item.AvgScorecardCreationTAT}</td>
                                        <td className="text-nowrap">{item.BureauPullOn}</td>
                                        <td className="text-nowrap">{item.AvgBureauPullTAT}</td>
                                        <td className="text-nowrap">{item.DocumentCreatedAt}</td>
                                        <td className="text-nowrap">
                                            {item.FITAT || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.FITatDiffLeadCreated || '\u00A0'}
                                        </td>
                                        <td className="text-nowrap">{item.SentToHabileAt}</td>
                                        <td className="text-nowrap">
                                            {item.SentToHabileTAT || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.SentToHabileTATDiffLeadCreated || '\u00A0'}
                                        </td>
                                    </tr>
                                ))}

                                {paginatedItemsAllTatLeads.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={9} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div className="mb-2">
                            Record - {paginatedItemsAllTatLeads.length}
                        </div>

                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPagesAllTatLeads }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handleAllLeadsPageChange(page)}
                                            disabled={currentTatLeadsPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div id='main-wrap' className={open ? "mb-4" : " mb-4 d-none"}>

                    <div className="row justify-content-between">
                        <div className="col-md-10">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                User TAT Report
                            </Typography>
                        </div>

                        <div className='col-md-2 text-end ps-lg-2'>
                            <div className='d-flex flex-md-row flex-column align-md-items-center align-items-start justify-content-between'>
                                <button onClick={backToTatReportHandler} onKeyDown={backToTatReportHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded order-md-2 order-1'> Back to Report</button>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div id='main-wrap' className='mb-3 filter'>
                            <div className='row mx-0'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label htmlFor="start-date">
                                            <span>Start Date</span>
                                            <input type='date' name='start_date' className='form-control'{...register('start_date')} />
                                        </label>
                                        {errors.start_date && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group'>
                                        <label htmlFor="end-date">
                                            <span>End Date</span>
                                            <input type='date' name='end_date' className='form-control'{...register('end_date')} />
                                        </label>
                                        {errors.end_date && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group'>
                                        <span>Lead Source</span>
                                        <div className="text-dark">
                                            <Multiselect
                                                id="lead_profile"
                                                ref={multiselectRefLeadProfile}
                                                options={allLeadSource.map((option) => ({
                                                    key: option.id,
                                                    value: option.name,
                                                }))}
                                                displayValue="value"
                                                onSelect={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedLeadSource(selectedValues);
                                                }}
                                                onRemove={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedLeadSource(selectedValues);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group'>
                                        <span>Campaigns</span>
                                        <div className="text-dark">
                                            <Multiselect
                                                id="campaign"
                                                ref={multiselectRefCampaign}
                                                options={allCampaigns.map((option) => ({
                                                    key: option.id,
                                                    value: option.name,
                                                }))}
                                                displayValue="value"
                                                onSelect={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedCampaign(selectedValues);
                                                }}
                                                onRemove={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedCampaign(selectedValues);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group mb-2'>
                                        <span className='d-block'>&nbsp;</span>
                                        <button type="submit" name="action" value="user_tat_report_submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="table-wrap table-responsive">
                        <table className="table table-hover" id="exportUserTatReportExcelFile">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>Lead Name</th>
                                    <th className='text-nowrap'>Mobile</th>
                                    <th className='text-nowrap'>Add Lead</th>
                                    <th className='text-nowrap'>Scorecard Creation</th>
                                    <th className='text-nowrap'>Scorecard Creation - TAT</th>
                                    <th className='text-nowrap'>Bureau Pull</th>
                                    <th className='text-nowrap'>Bureau Pull - TAT</th>
                                    <th className='text-nowrap'>FI Creation</th>
                                    <th className='text-nowrap'>FI Creation - TAT</th>
                                    <th className='text-nowrap'>Sent to Habile</th>
                                    <th className='text-nowrap'>Sent to Habile - TAT</th>
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.LeadName}</td>
                                        <td className="text-nowrap">{item.LeadMobile}</td>
                                        <td className="text-nowrap">{item.LeadCreatedAt}</td>
                                        <td className="text-nowrap">{item.ScCreatedAt}</td>
                                        <td className="text-nowrap">{item.AvgScorecardCreationTAT}</td>
                                        <td className="text-nowrap">{item.BureauPullOn}</td>
                                        <td className="text-nowrap">{item.AvgBureauPullTAT}</td>
                                        <td className="text-nowrap">{item.DocumentCreatedAt}</td>
                                        <td className="text-nowrap">
                                            {item.FITAT || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.FITatDiffLeadCreated || '\u00A0'}
                                        </td>
                                        <td className="text-nowrap">{item.SentToHabileAt}</td>
                                        <td className="text-nowrap">
                                            {item.SentToHabileTAT || '\u00A0'}
                                            <hr style={{ margin: '0.5em 0' }} />
                                            {item.SentToHabileTATDiffLeadCreated || '\u00A0'}
                                        </td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={9} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div className="mb-2">
                            Record - {paginatedItems.length}
                        </div>

                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBeaPlanReportData: state.getBeaPlanReportData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(beatPlanActions.default, userActions.default, reportActions.default, campaignsActions.default, leadActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TatReportPage);