import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal, TextField, Stack, InputAdornment, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import * as userActions from "../../actions/userActions";
import * as departmentActions from "../../actions/departmentActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// import '../common.scss';

import * as groupsUsersActions from "../../actions/groupsUsersActions";

import useResponsive from '../../hooks/useResponsive';
 
 
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '../../sections/auth/login';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ChangePassowordPage = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCon, setShowPasswordCon] = useState(false);
    const { id, identifier } = useParams();
    const location = useLocation();
    const { actions} = props;

    const [allRoles, setAllRoles] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const password = watch('password');

    const navigate = useNavigate();

    const [passwordRequired, setPasswordRequired] = useState(false);
    const [formValues, setFormValues] = useState({});
    
    const [identifierToken, setIdentifierTokenred] = useState("");
    const mdUp = useResponsive('up', 'md');

    useEffect(() => {
        if (identifier !== undefined) {

            setIdentifierTokenred(identifier);

            // console.log("identifierToken : ", identifierToken);
        } else {
            navigate('/login');
        }
    },[setIdentifierTokenred]);

    const handleSubmitForm = (data) => {

        const params = { 'password': data.password, 'identifier': identifierToken};
        
        const requestData = encryptData(params);
        const response = actions.updatePassword(requestData);

        response.then((result) => {
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            // console.error("Promise rejected - add/edit lead form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    }


    const handleChange = (e) => {
        
        const { name, value } = e.target;
        
        if (name === 'password') {
            setPasswordRequired(!!value);
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Enable password validation if a value is entered in the password field
        if (name === 'password') {
            setPasswordRequired(!!value);
        }
    };

    const validateConfirmPassword = (value) => {
        // Check if the value matches the password
        return value === password || 'Passwords do not match';
    };
  
  return (
    <>
      <Helmet>
        <title> Login | Ckers </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection className='bg-white'>
          <img src="/assets/ckern-logo.png" className='login-logo' alt='' />
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" className='px-5' alt="login" />
          </StyledSection>
        )}

        <Container className='login-bg'>
          <StyledContent className='px-3'>
            <div className='d-md-none d-block mb-3'>
              <img src="/assets/ckern-logo.png" width={150} alt='' />
            </div>
            <Typography variant="h3" gutterBottom>
                Change Password
            </Typography>
            <span className='d-md-block d-none'>&nbsp;</span>

            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Stack spacing={3}>
                    <TextField
                        type={showPasswordCon ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPasswordCon(!showPasswordCon)} edge="end">
                                <Iconify icon={showPasswordCon ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        id='password'
                        name='password'
                        autoComplete="new-password"
                        label="Password"
                        onChange={handleChange}
                        {...register('password', {
                            validate: (value) => {
                                if (value !== '') {
                                    // Apply your password validation logic here
                                    // Example: Check for a minimum password length
                                    if (value.length < 6) {
                                        return 'Password must be at least 6 characters long';
                                    }
                                }
                                // Return true to indicate validation success when password is empty
                                return true;
                            },
                        })}
                    />
                    {errors.password && <span className="required mt-1">Password must be at least 6 characters long</span>}

                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        id='confirm_password'
                        name='confirm_password'
                        label="Confirm Password"
                        placeholder='' {...register('confirm_password', { validate: validateConfirmPassword })}
                        required={passwordRequired}
                        onChange={handleChange}
                    />
                    {errors.confirm_password && <span className="required mt-1">{errors.confirm_password.message}</span>}
                </Stack>
                <LoadingButton className='mt-4' fullWidth size="large" type="submit" variant="contained">
                Continue
                </LoadingButton>
                <Stack direction="row" alignItems="center" justifyContent="space-end" sx={{ my: 2 }}>
                    <span className='text-end w-100'>Want to go back?
                    <RouterLink to="/login" className='fw-bold text-decoration-none'>
                        &nbsp;Login
                    </RouterLink></span>
                </Stack>
            </form>

          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        userTypesData: state.userTypesData,
        departmentsData: state.departmentsData,
        rolesData: state.rolesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(
            userActions.default,
            departmentActions.default,
            groupsUsersActions.default,
        ), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassowordPage);