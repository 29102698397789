import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const DepartmentCreatePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, departmentInfo } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false);

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Departments');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowDepartmentDetails(false);
    if (id !== undefined && !showDepartmentDetails) {
      const decodedId = atob(id);
      setDepartmentId(decodedId);

      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'department_id': decodedId };
      const requestData = encryptData(obj);

      const response = actions.departmentInfo(requestData, false);
      response.then((result) => {
        // console.log("Promise resolved -  departmentInfo data:", result);
        if (result.data.success === 1) {
          setShowDepartmentDetails(true);
          const departmentInfoResult = result.data.data;

          setValue("department_name", departmentInfoResult.data[0].department_name);
          setValue("department_status", departmentInfoResult.data[0].status);
        }
      }).catch((error) => {
        //  console.error("Promise rejected -  departmentInfo data :", error);
      });

    }
  }, [id, setValue, setShowDepartmentDetails]);

  const onSubmit = (data) => {
    let params = { 'department_name': data.department_name, 'department_status': data.department_status };

    if (departmentId !== '') {
      params = { 'department_name': data.department_name, 'department_status': data.department_status, 'department_id': departmentId };
    }

    console.log("params : ", params);
    
    const requestData = encryptData(params);
    const response = actions.createDepartment(requestData);

    response.then((result) => {
      // console.log("Promise resolved - add/edit lead form:", result); 
      if (result.data.success === 1) {
        toast.success(result.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate('/dashboard/department');
        }, 1000);
      } else {
        toast.error(result.data.message, {
          position: "top-right",
        });
      }
    }).catch((error) => {
      // console.error("Promise rejected - add/edit lead form:", error);
      toast.error(error, {
        position: "top-right",
      });
    });
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{showDepartmentDetails ? "Edit Department" : "Create Department"}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showDepartmentDetails ? "Edit Department" : "Create Department"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Department Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='department_name' className='form-control'
                      {...register('department_name', { required: true })} />
                  </label>
                  {errors.department_name && <span className="required">This field is required.</span>}
                </div>
              </div>
              { /* <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="created-by">
                    <span>Department Created by</span>
                    <select
                      name="action_user_id"
                      className="form-select"
                      {...register('action_user_id', { required: true })}
                    >
                      <option value="">Select User</option>
                      {allUsers.map((option) => (
                        <option value={option.user_id}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                  </label>
                  {errors.action_user_id && <span className="required">This field is required.</span>}
                </div>
              </div> */ }
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Department Status <a style={{ color: "red" }}>*</a></span>
                    <select name='department_status' className="form-select" {...register('department_status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.department_status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    departmentInfo: state.departmentInfo,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(departmentActions.default, userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentCreatePage);