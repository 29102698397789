import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink , useNavigate,useParams ,useLocation} from 'react-router-dom'; 
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadQualificationChecklistCreatePage = (props) => {

    const { id } = useParams();
    const location = useLocation();
    const { actions,usersData, basicLeadInfo, sourceData, masterProfileData} = props;
    const { register, handleSubmit, formState: { errors },setValue  } = useForm();
    const navigate = useNavigate();  
    const [allUsers, setAllUsers] = useState([]);
    const [selectDcreateby, setSelectDcreateby] = useState('');
    const [selectDstatus, setSelectDstatus] = useState('');
    const [leadId, setLeadId] = useState('');
    const [selectedQuestionTye, setSelectedQuestionTye] = useState('');
    const [inputFields, setInputFields] = useState([{ option: '' }]);
    const [checkBoxcheckboxInputFields, setCheckboxInputFields] = useState([{ option: '' }]);
    const [questionSections, setQuestionSections] = useState([]);

    const [selectedQuestionType, setSelectedQuestionType] = useState();
    const handleQuestionTypeChange = (sectionIndex, questionType) => {};

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if(authToken === null){
            navigate('/login');
        }
        
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj); 
        
        actions.usersData(requestData);
        if(usersData.success){
            setAllUsers(usersData.data);
        }
    }, []);

    const onSubmit = (data) => {

        let params = { };
        const questionType = data.question_type; 
        if(questionSections.length < 1){
            toast.error("Please add question", {
                position: "top-right",
            }); 
        }else if (questionType === "1") {
            params = {'check_list_name': data.checklist_name, 'question': data.question_text, 'question_type': questionType, question_input : data.question_input};
        } else if(questionType === "3") {
            params = {'check_list_name': data.checklist_name, 'question': data.question_text, 'question_type': questionType, question_textarea : data.question_textarea};
        } else {
            const rows = document.querySelectorAll('.form-group.row');
            const optionsArray = [];

            rows.forEach(row => {
                const checkbox = row.querySelector('.form-check-input');
                const optionInput = row.querySelector('.form-control#optionInputs');
                const scoreInput = row.querySelector('.form-control#scoreInputs');

                if (checkbox && optionInput && scoreInput) {
                    optionsArray.push({ option: optionInput.value, score: scoreInput.value, check: checkbox.checked });
                }
            });

            const allOptions = JSON.stringify(optionsArray);
            params = {'check_list_name': data.checklist_name, 'question': data.question_text, 'question_type': data.question_type, optionsArray : allOptions};
        }

        console.log(params);
        
        // const requestData = encryptData(params); 
        // actions.createLeadQualificationChecklist(requestData,navigate);
    };
    
    const handleCreatedByChange = (event) => {
        alert(event.target.value);
        setSelectDcreateby(event.target.value);
    };
    const handleStatusChange = (event) => {
        setSelectDstatus(event.target.value);
    };

    const handleAddFieldsCheckBox = () => {
        const newFields = [...checkBoxcheckboxInputFields, { option: '', score: '' }];
        setCheckboxInputFields(newFields);
      };
    
      const handleRemoveFieldsCheckBox = (index) => {
        const newFields = [...checkBoxcheckboxInputFields];
        newFields.splice(index, 1);
        setCheckboxInputFields(newFields);
      };
    
      const handleChangeCheckBox = (index, field, value) => {
        const newFields = [...checkBoxcheckboxInputFields];
        newFields[index][field] = value;
        setCheckboxInputFields(newFields);
      };

      const handleAddFields = () => {
        const newFields = [...inputFields, { option: '', score: '' }];
        setInputFields(newFields);
      };
    
      const handleRemoveFields = (index) => {
        const newFields = [...inputFields];
        newFields.splice(index, 1);
        setInputFields(newFields);
      };
    
      const handleChange = (index, field, value) => {
        const newFields = [...inputFields];
        newFields[index][field] = value;
        setInputFields(newFields);
      };

      const handleAddQuestionSection = () => {  
        const newSection = {
          questionText: '',
          questionType: '1',
        };
      
        const sections = [...questionSections, newSection];
        setQuestionSections(sections);
      };      
    
  return (
    <>
      <Helmet>
        <title>Lead Qualification Checklist | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Lead Qualification Checklist
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div id='main-wrap'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor="checklist-name">
                                <span>Checklist Name</span>
                                <input type='text' id='checklist_name' name='checklist_name' className='form-control' placeholder='' {...register('checklist_name', { required: true })}/>
                                {errors.checklist_name && <span className="required">This field is required.</span>}
                            </label>
                        </div>
                    </div>

                    {questionSections.map((section, sectionIndex) => (
                        <div key={sectionIndex} className={`row question-div section-${sectionIndex}`}>
                            <div className={`col-md-12 section-${sectionIndex}`}>
                                <div className='form-group'>
                                    <label htmlFor="question-text">
                                        <span>Question Text</span>
                                        <input type='text' id='question-text' name='question_text' className='form-control' placeholder='' {...register('question_text', { required: true })}/>
                                    </label>
                                </div>
                            </div>

                            <div className={`col-md-6 section-${sectionIndex}`}>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="question-required">
                                            <input className="form-check-input" type="checkbox" role="switch" id="question_required" {...register('question_text')} />
                                            <span>Question Required</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={`col-md-6 section-${sectionIndex}`}>
                                <div className='form-group'>
                                    <label htmlFor="question_input">
                                        <span>Question Sequence</span>
                                        <input type='text' id='question_sequence' name='question_sequence' className='form-control' placeholder='' {...register('question_sequence')}/>
                                    </label>
                                </div>
                            </div>

                            {/* Question Type Selection */}
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor={`question_type_${sectionIndex}`}>
                                        <span>Question Type</span>
                                        <select
                                            id={`question_type_${sectionIndex}`}
                                            name={`question_type_${sectionIndex}`}
                                            className="form-select"
                                            value={section.questionType}
                                            onChange={(e) => handleQuestionTypeChange(sectionIndex, e.target.value)}
                                        >
                                            <option selected>Select Question Type</option>
                                            <option value="1">Input</option>
                                            <option value="2">Checkbox</option>
                                            <option value="3">Textarea</option>
                                            <option value="4">Radio Button</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            
                            {/* <div className={`col-md-6 section-${sectionIndex}`}>
                                
                                <div className='form-group'>
                                    <label htmlFor={`question_type_${sectionIndex}`}>
                                        <span>Question Type</span>
                                        <select id={`question_type_${sectionIndex}`} name={`question_type_${sectionIndex}`} className="form-select" value={section.questionType} onChange={(e) => handleQuestionTypeChange(sectionIndex, e.target.value)}>
                                            <option selected>Select Question Type</option>
                                            <option value="1">Input</option>
                                            <option value="2">Checkbox</option>
                                            <option value="3">Textarea</option>
                                            <option value="4">Radio Button</option>
                                        </select>
                                    </label>
                                </div>
                            </div> */}
                            <div className='col-md-6'>
                                &nbsp;
                            </div>

                            {section.questionType}
                            {sectionIndex}
                            {/* Input Field */}
                            {/* eslint-disable-next-line eqeqeq */}
                            <div style={{ display: selectedQuestionType == 1 && section.questionType == sectionIndex ? 'block' : 'none' }}>
                                <div className={`col-md-9 section-${sectionIndex}`}>
                                    <div className='form-group'>
                                        {/* ... input field code ... */}
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ display: selectedQuestionTye == '1' && section.questionType == sectionIndex ? 'block' : 'none' }}>
                                <div className={`col-md-9 section-${sectionIndex}`}>
                                    <div className='form-group'>
                                        <label htmlFor={`question_input_${sectionIndex}`}>
                                            <span>Question Input</span>
                                            <input
                                                type='text'
                                                id={`question_input_${sectionIndex}`}
                                                name={`question_input_${sectionIndex}`}
                                                className='form-control'
                                                placeholder=''
                                                {...register(`question_input_${sectionIndex}`)}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div> */}

                            {/* textarea */}
                            {/* eslint-disable-next-line eqeqeq */}
                            <div style={{ display: selectedQuestionType == 3 && section.questionType == sectionIndex ? 'block' : 'none' }}>
                            {/* <div style={{ display: selectedQuestionTye == 3 ? 'block' : 'none' }}> */}
                                <div className='col-md-9'>
                                    <div className='form-group mb-0'>
                                        <label htmlFor="question_textarea">
                                            <span>Question Text</span>
                                            <textarea id='question_textarea' name='question_textarea' className='form-control' placeholder='' {...register('question_textarea')}/>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* checkbox */}
                            {/* eslint-disable-next-line eqeqeq */}
                            <div style={{ display: selectedQuestionType == 2 && section.questionType == sectionIndex ? 'block' : 'none' }}>
                            {/* <div style={{ display: selectedQuestionTye == 2 ? 'block' : 'none' }}> */}
                                <div className='col-md-9'>
                                    <div className='form-group mb-0'>
                                        <label htmlFor="user-role">
                                            <span>Answer Options</span>
                                            <input type='hidden' className='d-none' />
                                        </label>
                                    </div>
                                </div>

                                {checkBoxcheckboxInputFields.map((inputField, index) => (
                                    <div key={index} className='col-12 checkbox-div'>
                                        <div className='form-group row'>
                                            <div className='col-10 ps-0'>
                                                <label htmlFor='ans-op'>
                                                    <input type='text' name='optionInputs' id='optionInputs' value={inputField.option} onChange={(event) => handleChangeCheckBox(index, 'optionInputs', event.target.value) } className='form-control'/>
                                                </label>
                                            </div>
                                            <div className='col-2'>
                                                <button type='button' onClick={() => handleRemoveFieldsCheckBox(index)} className='btn btn-danger' >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className='col-md-12'>
                                    <div className='form-group text-end'>
                                        <button type='button' onClick={handleAddFieldsCheckBox} className='btn btn-info'>
                                            Add More
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            {/* radio button */}
                            {/* eslint-disable-next-line eqeqeq */}
                            <div style={{ display: selectedQuestionTye == 4 ? 'block' : 'none' }}>
                                <div className='col-md-9'>
                                    <div className='form-group mb-0'>
                                        <label htmlFor="user-role">
                                            <span>Answer Options</span>
                                            <input type='hidden' className='d-none' />
                                        </label>
                                    </div>
                                </div>

                                {inputFields.map((inputField, index) => (
                                    <div key={index} className='col-12 checkbox-div'>
                                        <div className='form-group row'>
                                            <div className='col-10 ps-0'>
                                                <label htmlFor='ans-op'>
                                                    <input type='text' name='optionInputs' id='optionInputs' value={inputField.option} onChange={(event) => handleChange(index, 'optionInputs', event.target.value) } className='form-control'/>
                                                </label>
                                            </div>
                                            <div className='col-2'>
                                                <button type='button' onClick={() => handleRemoveFields(index)} className='btn btn-danger' >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className='col-md-12'>
                                    <div className='form-group text-end'>
                                        <button type='button' onClick={handleAddFields} className='btn btn-info'>
                                            Add More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Button to add more sections */}
                    <div className='col-md-12'>
                        <div className='form-group float-right'>
                            <button type='button' onClick={handleAddQuestionSection} className='btn btn-info'>
                            Add Question
                            </button>
                        </div>
                    </div>
                    
                    <div className='col-md-12'>
                        <div className='form-group mb-2'>
                            <button className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
    return {
       usersData: state.usersData,
       userInfo: state.userInfo,
       sourceData: state.sourceData,
       masterProfileData : state.masterProfileData
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(LeadQualificationChecklistCreatePage);