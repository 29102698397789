import { combineReducers } from "redux";
import userInfo from "./userInfoReducer";
import userParams from "./userParamsReducer";
import userLogin from "./userloginReducer";
import networkRequest from "./networkRequest";
import usersData from "./usersDataReducer";
import departmentsData from "./departmentReducer";
import departmentInfo from "./departmentInfoReducer";
import rolesData from "./rolesReducer";
import roleInfo from "./roleInfoReducer";
import rolePermissionData from "./rolePermissionReducer";
import rolePermissionInfo from "./rolePermissionInfoReducer";
import initialState from "./initialState";
import leadsData from "./leadsReducer";
import leadInfo from "./leadInfoReducer";
import leadCommunicationInfo from "./communicationInfoReducer";
import sourceData from "./sourceDataReducer";
import masterProfileData from "./masterProfileDataReducer";
import interactionTypeData from "./interactionTypeDataReducer";
import leadStatusData from "./leadStatusReducer";
import leadChecklistData from "./leadChecklistReducer";
import createLead from "./createLeadReducer";
import checklistsData from './checklistsReducer';
import leadProfileInfo from "./leadProfileInfoReducer";
import sourceLeadInfo from "./sourceLeadInfoReducer";
import leadCountData from "./leadCountReducer";
import leadStatusInfo from "./leadStatusInfoReducer";
import campaignData from "./campaignDataReducer";
import campaignInfo from "./campaignInfoReducer";
import leadBreakupCountData from "./leadBreakupCountDataReducer";
import loggedinUserActivityData from "./loggedinUserActivityDataReducer";
import leadBreakupCountDetailsData from "./leadBreakupCountDetailsDataReducer";
import loggedinUserFollowupLeadsData from "./loggedinUserFollowupLeadsDataReducer";
import loggedinUserNewLeadsData from "./loggedinUserNewLeadsDataReducer";
import incidentReporteData from "./incidentReporteDataReducer";
import incidentReportStatusData from "./incidentReportStatusDataReducer";
import incidentCategoryData from "./incidentCategoryDataReducer";
import incidentPriorityData from "./incidentPriorityDataReducer";
import allTicketData from "./allTicketDataReducer";
import viewTicketData from "./viewTicketDataReducer";
import groupsUsersData from "./groupsUsersDataReducer";
import groupData from "./incidentGroupDataReducer";
import groupInfo from "./incidentGroupInfoReducer";
import leadGstData from "./leadGstDataReducer";
import leadGstByLeadData from "./leadGstByLeadDataReducer";
import coApplicantData from "./coApplicantDataReducer";
import guarantorData from "./guarantorDataReducer";
import leadItrData from "./leadItrDataReducer";
import leadItrByLeadData from "./leadItrByLeadDataReducer";
import leadBankStatementData from "./leadBankStatementDataReducer";
import leadBankStatementByLeadData from "./leadBankStatementByLeadDataReducer";


const rootReducer = combineReducers({
  userInfo,
  userLogin,
  userParams,
  usersData,
  isFetching: networkRequest,
  departmentsData,
  departmentInfo,
  rolesData,
  roleInfo,
  rolePermissionData,
  rolePermissionInfo,
  leadsData,
  leadInfo,
  leadCommunicationInfo,
  sourceData,
  masterProfileData,
  interactionTypeData,
  leadStatusData,
  leadChecklistData,
  createLead,
  checklistsData,
  leadProfileInfo,
  sourceLeadInfo,
  leadCountData,
  leadStatusInfo,
  campaignData,
  campaignInfo,
  leadBreakupCountData,
  loggedinUserActivityData,
  leadBreakupCountDetailsData,
  loggedinUserFollowupLeadsData,
  loggedinUserNewLeadsData,
  incidentReporteData,
  incidentReportStatusData,
  incidentCategoryData,
  incidentPriorityData,
  allTicketData,
  viewTicketData,
  groupsUsersData,
  groupData,
  groupInfo,
  leadGstData,
  leadGstByLeadData,
  coApplicantData,
  guarantorData,
  leadItrData,
  leadItrByLeadData,
  leadBankStatementData,
  leadBankStatementByLeadData, 
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
