import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const PrefillTemplateNameAddEdit = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, masterPrefillTemplateNameData, checklistsData, masterPrefillTemplateTypeData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [prefillTemplateNameId, setprefillTemplateNameId] = useState('');
    const [showCampaignDetails, setShowCampaignDetails] = useState(false);
    const [allPrefillTemplateType, setAllPrefillTemplateType] = useState([]);

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Prefill Template');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);


    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);

        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        setAllPrefillTemplateType(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.masterPrefillTemplateTypeData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                setAllPrefillTemplateType(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllPrefillTemplateType(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

    }, [setAllPrefillTemplateType]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setShowCampaignDetails(false);
        if (id !== undefined && !showCampaignDetails) {
            const decodedId = atob(id);
            setprefillTemplateNameId(decodedId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'prefill_template_name_id': decodedId };
            const requestData = encryptData(obj);

            const response = actions.masterPrefillTemplateNameData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  source data:", result); 
                if (result.data.success === 1) {
                    setShowCampaignDetails(true);
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    setValue("prefill_template_name", resultArr[0].prefill_template_name);
                    setValue("prefill_template_type", resultArr[0].prefill_template_type);
                    setValue("status", resultArr[0].status);

                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });
        }
    }, [id, setValue, setShowCampaignDetails]);

    const onSubmit = (data) => {

        if (data.prefill_template_name.trim() === "") {
            toast.error("Prefill Template Name is blank.", {
                position: "top-right",
            });
        } else if (data.prefill_template_type.trim() === "") {
            toast.error("Prefill Template Type has been selected.", {
                position: "top-right",
            });
        } else {
            const params = { 'prefill_template_name': data.prefill_template_name, 'prefill_template_type': data.prefill_template_type, "status": data.status };

            if (prefillTemplateNameId !== '') {
                params.prefill_template_name_id = prefillTemplateNameId;
            }

            const requestData = encryptData(params);
            const response = actions.addEditPrefillTemplateName(requestData);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setTimeout(() => {
                        navigate('/dashboard/prefill-template-name');
                    }, 1000);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title>{showCampaignDetails ? "Edit Prefill Template Name" : "Create Prefill Template Name"}</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    {showCampaignDetails ? "Edit Prefill Template Name" : "Create Prefill Template Name"}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="campaign-name">
                                        <span>Prefill Template Name <a style={{ color: "red" }}>*</a></span>
                                        <input type='text' id='prefill_template_name' className='form-control' {...register('prefill_template_name', { required: true })} />
                                    </label>
                                    {errors.prefill_template_name && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="prefill-template-type">
                                        <span>Prefill Template Type <a style={{ color: "red" }}>*</a></span>
                                        <select id='prefill_template_type' className="form-select" name='prefill_template_type' {...register('prefill_template_type')}>
                                            <option value="">Select Prefill Template Type</option>
                                            {allPrefillTemplateType.length > 0 && allPrefillTemplateType.map((option) => (
                                                <option value={option.id}>
                                                    {option.prefill_template_type}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="reporting-user">
                                        <span>Status <a style={{ color: "red" }}>*</a></span>
                                        <select name='status' className="form-select" {...register('status', { required: true })}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </label>
                                    {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        masterPrefillTemplateNameData: state.masterPrefillTemplateNameData,
        masterPrefillTemplateTypeData: state.masterPrefillTemplateTypeData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrefillTemplateNameAddEdit);