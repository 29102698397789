import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const shipmentOrders = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_SHIPMENTS, params);
      } else {
        response = await agent.post(API.GET_SHIPMENTS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const createShipmentOrder = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_SHIPMENT_ORDER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const shipmentTrackingAwbNumber = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.SHIPMENT_TRACKING_AWB_NUMBER, params);
        } else {
          response = await agent.post(API.SHIPMENT_TRACKING_AWB_NUMBER, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
};

const cancelOrder = (params) => {
    return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
        const response = await agent.post(API.CANCEL_ORDER, params);
        dispatch(toggleNetworkRequestStatus(false));
        return response;
      } catch (error) {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      } 
    };
  }

export default {
    createShipmentOrder,
    shipmentOrders,
    shipmentTrackingAwbNumber,
    cancelOrder,
}