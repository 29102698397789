import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import { setToaster, showToaster } from "../../utils/helpers";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ModulePermissionsList = ({ modulePermissions, handlePermissionChange }) => {
    return (
        <div>
            {modulePermissions.map(permission => (
                <ModulePermissionItem
                    key={permission.permission_id}
                    permission={permission}
                    handlePermissionChange={handlePermissionChange}
                />
            ))}
        </div>
    );
};

const ModulePermissionItem = ({ permission, handlePermissionChange }) => {
    return (
        <div>
            <div className='d-flex mb-3 pe-0'>
                <h6 className='col-md-2 fw-bold'>{permission.module_name}</h6>
                <span className=''>
                    <input
                        type="checkbox" className='form-check-input'
                        checked={permission.view_permission}
                        onChange={() => handlePermissionChange(permission.permission_id, 'view')}
                    />
                    &nbsp;
                    View
                </span>
                <span className='pl-10'>
                    <input
                        type="checkbox" className='form-check-input'
                        checked={permission.add_permission}
                        onChange={() => handlePermissionChange(permission.permission_id, 'add')}
                    />&nbsp;
                    Add
                </span>
                <span className='pl-10'>
                    <input
                        type="checkbox" className='form-check-input'
                        checked={permission.edit_permission}
                        onChange={() => handlePermissionChange(permission.permission_id, 'edit')}
                    />&nbsp;
                    Edit
                </span>
            </div>
        </div>
    );
};

const UserRolePage = (props) => {
    const { actions, rolesData } = props;

    const navigate = useNavigate();
    const [allRoles, setAllRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showRolesDetails, setShowRolesDetails] = useState(false);
    const [isMappingStatus, setIsMappingStatus] = useState(false);
    const [modulePermissions, setModulePermissions] = useState([]);
    const [roleId, setRoleId] = useState('');

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'User Role')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);


    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        setShowRolesDetails(false);
        if (!showRolesDetails) {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS };
            const requestData = encryptData(obj);

            const response = actions.rolesData(requestData);
            response.then((result) => {
                console.log("Promise resolved -  rolesData:", result);
                if (result.data.success === 1) {
                    setShowRolesDetails(true);
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllRoles(resultArr);
                }
            }).catch((error) => {
                console.error("Promise rejected -  rolesData:", error);
            });
        }

    }, [setAllRoles]);

    const fetchModulePermissions = (id) => {

        const obj = { 'role_id': id, 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.fetchModulePermissionsData(requestData);

        response.then((result) => {
            console.log("Promise resolved:", result);
            setModulePermissions(result.data.data);
        }).catch((error) => {
            setModulePermissions([]);
            console.error("Promise rejected:", error);
        });

    };

    const handlePermissionChange = (permissionId, action) => {
        const updatedPermissions = modulePermissions.map(permission => {
            if (permission.permission_id === permissionId) {

                return {
                    ...permission,
                    [`${action}_permission`]: !permission[`${action}_permission`]
                };
            }
            return permission;
        });
        setModulePermissions(updatedPermissions);
    };

    const savePermissions = () => {
        try {
            const obj = { 'formData': modulePermissions };
            const requestData = encryptData(obj);
            actions.rolesData(requestData);
            console.log(modulePermissions);
            const response = actions.saveModulePermissions(requestData);

            response.then((result) => {
                console.log("Save Promise resolved:", result);
                setToaster(result.data.message);
                setOpen(false);
            }).catch((error) => {
                setToaster(error);
                console.error("save Promise rejected:", error);
            });

        } catch (error) {
            console.error('Error saving permissions:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.role_id));
        setRoleId(itemObj.role_id);
        if (btnAction === 'edit') {
            const url = `/dashboard/edit-role/${encodedId}`;
            // navigate(url);
            window.open(url, '_self');
        } else if (btnAction === 'permission_mapping') {

            setViewModalInfo(itemObj);
            fetchModulePermissions(itemObj.role_id);
            setIsMappingStatus(true);
            setOpen(true);

        } else {
            setViewModalInfo(itemObj);
            setOpen(true);
            setIsMappingStatus(false);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allRoles.filter(item =>
        item.role_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.createdByName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    return (
        <>
            <Helmet>
                <title> User Roles | Ckers </title>
            </Helmet>

            <Container>
                <div className="d-flex align-items-center justify-content-between mb-0">
                    <span>&nbsp;</span>
                    <RouterLink to='/dashboard/create-user-role' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add User Role</RouterLink>
                </div>

                {!userHasPermission ? (
                    <>
                        <AccessDeniedWithoutLeftMenu />
                    </>
                ) : (
                    <>
                        <div id='main-wrap' className='mmt-0'>
                            <div className='row justify-content-between'>
                                <div className='col-md-8'> 
                                    <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                        User Roles
                                    </Typography>
                                </div>
                                <div className='col-md-4 pe-0 ps-lg-5'>
                                    <div className="form-group">
                                        <label htmlFor="search">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                id="search"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='table-wrap'>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>RoleID</th>
                                            <th>Role Name</th>
                                            <th>Role Status</th>
                                            <th>Role Created By</th>
                                            <th>Role Created On</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.formatetdID}</td>
                                                <td>{item.role_name}</td>
                                                <td>
                                                    <span
                                                        className={(item.status === '1') ? "badge text-bg-success text-white" : (item.status === '2') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                                    >{item.statusName}</span>
                                                </td>
                                                <th> {item.createdByName}</th>
                                                <td>{item.created_date}</td>
                                                <td>
                                                    <div className="btn-group dropstart">
                                                        <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>{/*
                                                                <RouterLink onClick={() => onRowClickAction(item, 'view')} ><i className="bi bi-eye">&nbsp;</i> View</RouterLink>
                                                               */} </li> 
                                                            <li>
                                                                { /* <RouterLink onClick={() => onRowClickAction(item, 'edit')} ><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink> */}

                                                                <NavLink to={`/dashboard/edit-role/${btoa(String(item.role_id))}`}><i className="bi bi-pen">&nbsp;</i> Edit</NavLink>

                                                            </li>

                                                            <li>
                                                                <RouterLink onClick={() => onRowClickAction(item, 'permission_mapping')} ><i className="bi bi-eye">&nbsp;</i> Permission Mapping</RouterLink>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                
                                            </tr>                              ))}

                                    </tbody>
                                </table>
                            </div>
                            <div>

                                <nav className='mb-1'>
                                    <ul className="pagination pagination-md justify-content-end">
                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                            <li className="page-item">
                                                <button
                                                    key={page}
                                                    onClick={() => handlePageChange(page)}
                                                    disabled={currentPage === page}
                                                    className="page-link"
                                                >
                                                    {page}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </>
                )}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">User Roles  : {viewModalInfo.role_name}</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        {!isMappingStatus && (
                            <div>
                                <div className='row mb-2 pb-1'>
                                    <div className='col-4'><b>Role ID :</b></div>
                                    <div className='col-7 ps-0'>{viewModalInfo.formatetdID}</div>
                                </div>

                                <div className='row mb-2 pb-1'>
                                    <div className='col-4'><b>Created By :</b></div>
                                    <div className='col-7 ps-0'>{viewModalInfo.createdByName}</div>
                                </div>

                                <div className='row mb-2 pb-1'>
                                    <div className='col-4'><b>Role Status :</b></div>
                                    <div className='col-7 ps-0'><span className={(viewModalInfo.status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
                                </div>
                            </div>
                        )}


                        {isMappingStatus && (
                            <div>
                                <div>
                                    <h5 className='mb-4 pb-2'>Module Permissions</h5>
                                    <ModulePermissionsList
                                        modulePermissions={modulePermissions}
                                        handlePermissionChange={handlePermissionChange}
                                    /><br />
                                    <button className="btn btn-primary" onClick={savePermissions}>Save Permissions</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>


        </>
    );
}

function mapStateToProps(state) {
    return {
        rolesData: state.rolesData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRolePage);