import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadGstByLeadDataReducer(state = initialState.leadGstByLeadData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_GST_BY_LEAD_INFO_SUCCESS:
      return action.leadGstByLeadData;
    default:
      return state;
  }
}
