import axios from "axios";
import CryptoJS from 'crypto-js';
import config from "../config";
import { showToaster } from "./helpers";

const base64url = (source) => {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, "");

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  return encodedSource;
}

const generateToken = (loginToken) => {
  const jwtSecret = config.ENCKEY;
  const header = {
    typ: "JWT",
    alg: "HS256",
  };
  // const currentTimestamp = Math.floor(Date.now() / 1000);
  // console.log(currentTimestamp);
  const data = {
    // iat:  (Math.floor(Date.now() / 1000)-10),
    // exp: currentTimestamp + 60, // expiry time is 30 seconds from time of creation
    token: loginToken,
  };

  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  // encode data
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const encodedData = base64url(stringifiedData);

  // build token
  const token = `${encodedHeader}.${encodedData}`;

  // sign token
  const signature = CryptoJS.HmacSHA256(token, jwtSecret);
  const signature2 = base64url(signature);
  const signedToken = `${token}.${signature2}`;
  // console.log(signedToken); 
  return signedToken;
}

const agent = axios.create({

  baseURL: config.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "App_device_id": localStorage.getItem("APP_DEVICE_ID"),
    "Firebase_Token": localStorage.getItem("FCMToken"),
    "Push_alert_subs_id": localStorage.getItem("PUSH_ALERT_TOKEN"),
  },
});

agent.interceptors.request.use((config) => {
  let token = localStorage.getItem("authToken");
  if (!token) {
    return config;
  }

  token = generateToken(token);
  // if (process.browser) {

  config = {
    ...config,
    headers: { ...config.headers, 
        Authorization: `Bearer ${token}`
      ,"App_device_id": localStorage.getItem("APP_DEVICE_ID") 
    },
  };
  // }

  return config;
});

agent.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      showToaster(error.response.statusText);
    }
    return error;
  }
);

export { agent };
