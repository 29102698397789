import { useState, useEffect ,useCallback} from 'react';
import DatePicker from 'react-datepicker';   
import 'react-datepicker/dist/react-datepicker.css';   
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';

import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import DataTable from 'react-data-table-component'; 
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import LogsVersioning from './LogsVersioning';

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as masterAction from "../../actions/masterAction";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AllCloudLoanRepaymentDev = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const { urlAgreementNo } = useParams();
    const { brief } = useParams();
     const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [businessDoneLoans, setBusinessDoneLoans] = useState([]);
    const [loanDetailsEMIS, setLoanDetailsEMIS] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);
    const [loanID, setLoanID] = useState('');
    const [loanDetailStatus, setLoanDetailStatus] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [versioningLoans, setVersioningLoans] = useState([]);
	const [businessDoneLoan, setBusinessDoneLoan] = useState([]);
    const [reportName, setReportName] = useState('');
    const [cronRunDateArr, setCronRunDateArr] = useState([]);
    const [cronRunTabsReloadStatus, setCronRunTabsReloadStatus] = useState(true);
    const [activeLoanTab, setActiveLoanTab] = useState(0);
    const [auditAllLoansButtonStatus, setAuditAllLoansButtonStatus] = useState(false);

    const [keyword, setKeyword] = useState('');
  
    const [userId, setUserId] = useState(''); 
    const[intentUrl, setIntentUrl] = useState('');
    const [totalOutstandingAmt, setTotalOutstandingAmt] = useState(0);
    const [loadDefaultQR, setLoadDefaultQR] = useState(false);
    const [formValues, setFormValues] = useState({
        amount: ''
    });
    const [runApis, setRunApis] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    
     const [partialAmount, setPartialAmount] = useState('');
    const [partialDebitDate, setPartialDebitDate] = useState('');
	
    useEffect(() => {

        if (brief !== undefined ) {
            setReportName(brief); 
        }
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        if (id !== undefined ) {
            const idVal = atob(id);            
            setLoanID(idVal); 
             setLoanDetailStatus(true);
        } 
    }, [setLoanID,id,setReportName]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Audit Report')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

     
    const reInitOpenEnachForm = () => {
        setOpenEnach(false); 
        setPartialAmount('');
        setPartialDebitDate(''); 
    }
    
    // Handle Checkbox Change for each row
    const handleCheckboxChange = (id) => {
        if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter(rowId => rowId !== id));
        } else {
        setSelectedRows([...selectedRows, id]);
        }
    };
    // Handle Select All
    const handleSelectAll = () => {
        const allIds = businessDoneLoans.map(row => row.ImportRowID);
        setSelectedRows(allIds);
    };

    // Handle Unselect All
    const handleUnselectAll = () => {
        setSelectedRows([]);
    };


    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value); 
    };
     
 
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleTabClick = (index) => {
        setActiveLoanTab(index); // Update active tab index
        const clickedDate = cronRunDateArr[index].formatted_cron_run_date;
        
        if(clickedDate !== ''){
            setPreloaderStatus(true);
            
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'clicked_date': clickedDate, 'keyword' : keyword , 'partialAmount' : partialAmount, 'partialDebitDate' : partialDebitDate, 'server_data' : 'AllCloude_Dev'};
            const requestData = encryptData(params);
            const response = actions.getLoansClearanceData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getLoansClearanceData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    reInitOpenEnachForm();  
                    const results = result.data.data.all_loans;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    // const cronRunDateResults = result.data.data.cron_run_date;
                    // const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                  //  setCronRunDateArr(cronRunDateResultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getLoansClearanceData:", error);
            });
        }
    };

    const auditAllLoansButton = async () => {
        setPreloaderStatus(true);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'keyword' : keyword    , 'partialAmount' : partialAmount , 'partialDebitDate' : partialDebitDate  , 'server_data' : 'AllCloude_Dev'};
        const requestData = encryptData(params);
        const response = actions.getLoansClearanceData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getLoansClearanceData:", result);
            if (result.data.success === 1) {
                setAuditAllLoansButtonStatus(true);
                setPreloaderStatus(false);
                reInitOpenEnachForm();   
                const results = result.data.data.all_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);

                const cronRunDateResults = result.data.data.cron_run_date;
                const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                 setCronRunDateArr(cronRunDateResultArr);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
                setAuditAllLoansButtonStatus(false);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getLoansClearanceData:", error);
        });
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const today = new Date();
        const todayFormatted = formatDate(today);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

        // console.log("111111 - id = ",id);

        if (id !== undefined ) {
            setBusinessDoneLoans([]);
            setLoanDetailsEMIS([]);
            setVersioningLoans([]);
            const idVal = atob(id); 
            const agreementNo = atob(urlAgreementNo);  
            
            let reportName = '';
            if (brief !== undefined ) {
                reportName = brief; 
            }
            // alert(reportName);
            // alert(idVal);      
            setLoanID(idVal); 
             setLoanDetailStatus(true);
             setAuditAllLoansButtonStatus(true);
 
             const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted ,'loan_id' : idVal ,'loanAgreementNo' : agreementNo,'reportName' : reportName, 'keyword' : keyword    , 'partialAmount' : partialAmount, 'partialDebitDate' : partialDebitDate , 'server_data' : 'AllCloude_Dev'};
                const requestData = encryptData(params);
                const response = actions.getLoansClearanceData(requestData, false);
                response.then((result) => {
                    // console.log("Promise resolved -  getLoansClearanceData:", result);
                    if (result.data.success === 1) {
                        setPreloaderStatus(false);
                        reInitOpenEnachForm();  
                        const results = result.data.data.all_loans;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        setVersioningLoans(resultArr);

                        const defaultTabIndex = (resultArr.length >0) ? (resultArr.length - 1) : 0;
                        setActiveTab(defaultTabIndex);
                             
                        if (
                            results &&
                            results[0] &&
                            Object.prototype.hasOwnProperty.call(results[0], 'RepaymentSchedules')
                        ) {
                            // alert(results[0].AgreementNo);
                             setLoanDetailsEMIS(results[0].RepaymentSchedules);
                             setBusinessDoneLoan(results[0]);

                             setTotalOutstandingAmt(100);
                        }
                    } else {
                        setPreloaderStatus(false);
                        setBusinessDoneLoans([]);
                        setLoanDetailsEMIS([]);
                        setBusinessDoneLoan([]);
                    }
                }).catch((error) => {
                    setPreloaderStatus(false);
                    console.error("Promise rejected -  getLoansClearanceData:", error);
                });
        }else{
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted, 'keyword' : keyword    , 'partialAmount' : partialAmount , 'partialDebitDate' : partialDebitDate , 'server_data' : 'AllCloude_Dev'};
            const requestData = encryptData(params);
            const response = actions.getLoansClearanceData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getLoansClearanceData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    reInitOpenEnachForm();  
                    const results = result.data.data.all_loans;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getLoansClearanceData:", error);
            });
        }

        
    }, [setBusinessDoneLoans,setLoanID,setLoanDetailsEMIS,setLoanDetailStatus,id, setBusinessDoneLoan,setVersioningLoans, setCronRunDateArr, setTotalOutstandingAmt]);

    const onSubmit = (data) => { 
         setPreloaderStatus(true);
        const params = {
            'keyword' : keyword,
            'partialAmount' : partialAmount, 
            'partialDebitDate' : partialDebitDate,
            'runApis' : runApis,  
            'start': '0',
            'length': config.MAX_RECORDS,
            'selectedRows' : selectedRows, 'server_data' : 'AllCloude_Dev'
        };

        const requestData = encryptData(params);
        const response = actions.getLoansClearanceData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
             reInitOpenEnachForm();  
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.all_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setBusinessDoneLoans(resultArr);
                setSelectedRows([]);
                
                goBackBtnFromAuditAllLoans();
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                if (result.data && Object.prototype.hasOwnProperty.call(result.data, 'message')) {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }else{
                    toast.error("There is some error,please try again!", {
                        position: "top-right",
                    });
                }
                    
            }
         }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const btnVerifySubmit = () => {
       // console.log("Selected rows = ",selectedRows);
        if(selectedRows.length < 1){
            toast.error("Please select at least one loan record.", {
                position: "top-right",
            });
        // }else if(selectedRows.length === 1){
          //  setOpenEnach(true);
        }else{
            setRunApis(true); 
        }
    };

    useEffect(() => {
        if (runApis) {
             onSubmit();
             setRunApis(false);
        }
    }, [runApis]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = businessDoneLoans.filter(item => {
        const AgreementNo = item.AgreementNo?.toLowerCase() || '';
        const CustomerName = item.CustomerName?.toLowerCase() || '';
        // const CustomerNo = item.CustomerNo?.toLowerCase() || '';
        return (
            AgreementNo.includes(searchQuery.toLowerCase()) ||
            CustomerName.includes(searchQuery.toLowerCase())
            // ||
            // CustomerNo.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice();
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);
     
    const exportLoansReport = async () => {
        const table = document.getElementById('exportLoansReportExcelFile');
        
        // Debugging log: Check if table is correctly selected
        console.log('Table:', table);
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');
        
        // Debugging logs: Check headersRow and actionColumnIndex
        console.log('Headers Row:', headersRow);
        console.log('Action Column Index:', actionColumnIndex);
        
        if (actionColumnIndex !== -1) {
            // Debugging log: Check the header element to remove
            console.log('Header to Remove:', headers[actionColumnIndex]);
            
            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }
    
        TableToExcel.convert(table, {
            name: 'Audit-Report.xlsx',
            sheet: {
                name: 'Audit-Report',
            },
        });
    
        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };

    const getLocation = (beatPlanId, btnAction, id) => {

        setPreloaderStatus(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                if(latitude !== "" && longitude !== ""){
            
                    const params = {
                        'id': id,
                        'beat_plan_id': beatPlanId,
                        'btnAction': btnAction,
                        'latitude': latitude,
                        'longitude': longitude
                    };
                    
                    const requestData = encryptData(params);
                    const response = actions.setBeatPlanStatus(requestData, false);
                    response.then((result) => {
                        if (result.data.success === 1) {
                            
                            toast.success(result.data.message, {
                                position: "top-right",
                            });
                            // setPreloaderStatus(false);
                            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': startDate, 'end_date': endDate, 'keyword' : keyword   , 'partialAmount' : partialAmount, 'partialDebitDate' : partialDebitDate , 'server_data' : 'AllCloude_Dev' };
                            const requestData = encryptData(params);
                            const response = actions.getLoansClearanceData(requestData, false);
                            response.then((result) => {
                                // console.log("Promise resolved -  getLoansClearanceData:", result);
                                if (result.data.success === 1) {
                                    setPreloaderStatus(false);
                                    reInitOpenEnachForm();  
                                    const results = result.data.data.all_loans;
                                    const resultArr = Object.keys(results).map((key) => results[key]);
                                    setBusinessDoneLoans(resultArr);
                                } else {
                                    setPreloaderStatus(false);
                                    setBusinessDoneLoans([]);
                                }
                            }).catch((error) => {
                                setPreloaderStatus(false);
                                console.error("Promise rejected -  getLoansClearanceData:", error);
                            });
                        } else {
                            setPreloaderStatus(false);
                            toast.error(result.data.message, {
                                position: "top-right",
                            });
                        }
                    }).catch((error) => {
                        setPreloaderStatus(false);
                        console.error("Promise rejected -  setBeatPlanStatus: ", error);
                    });
                }

                setBeatPlanLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Geolocation is not supported by this browser.", {
                position: "top-right",
            });
        }
    }

    useEffect(() => {
        if (cronRunDateArr.length > 0) {
            setActiveLoanTab(cronRunDateArr.length - 1);
        }
    }, [cronRunDateArr, setActiveLoanTab]);

    const goBackBtn = (event) => {
        
        setLoanDetailStatus(false);
        setAuditAllLoansButtonStatus(false);
        const url = `/dashboard/allcloud-loan-repayment`;
       // window.open(url, '_self');
        navigate(url); 
      };

      const goBackBtnFromAuditAllLoans = (event) => {

        setPreloaderStatus(true);
        setAuditAllLoansButtonStatus(false);
        setCronRunDateArr([]);
        setBusinessDoneLoans([]);
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'keyword' : keyword   , 'partialAmount' : partialAmount, 'partialDebitDate' : partialDebitDate , 'server_data' : 'AllCloude_Dev' };
        const requestData = encryptData(params);
        const response = actions.getLoansClearanceData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getLoansClearanceData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                reInitOpenEnachForm();   
                const results = result.data.data.all_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getLoansClearanceData:", error);
        });
    };

      const viewDetails = (record) => {
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]); 
        setLoanID(record.id); 
        setLoanDetailStatus(true);
        console.log("RepaymentSchedules = ",record.RepaymentSchedules);
        if (record && Object.prototype.hasOwnProperty.call(record, 'RepaymentSchedules')) {
            setLoanDetailsEMIS(record.RepaymentSchedules);
        }
        setBusinessDoneLoan(record);
      };

      const moneyFormatIndia = (number) => {
        if (number !== undefined && number !== null && !Number.isNaN(Number(number))) {
            number = parseFloat(number).toFixed(2);  // Ensure two decimal places
    
            const [integerPart, decimalPart] = number.split('.');
    
            const lastThreeDigits = integerPart.slice(-3);
            const otherDigits = integerPart.slice(0, -3);
    
            let formattedIntegerPart;
            if (otherDigits !== '') {
                formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
            } else {
                formattedIntegerPart = lastThreeDigits;
            }
    
            return `${formattedIntegerPart}.${decimalPart}`;
        }
    
        return '0.00';  // Default return value for invalid inputs
    };
    
    const formatDateForValue = (date) => {
        if (!date || Number.isNaN(new Date(date).getTime())) {
            return '';  // Return an empty string for invalid or empty dates
        }
    
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
    
        return `${day}-${month}-${year}`;
    };

   
    const columns = [
       

        {
            name: '',
           
            cell: row => (row?.ImportRowStatus !== '1' ) ? (
                <input
                type="checkbox"
                checked={selectedRows.includes(row.ImportRowID)}
                onChange={() => handleCheckboxChange(row.ImportRowID)}
              />
            ) : null,  // Don't display checkbox if condition is not met

            width: '60px', // Adjust width of checkbox column if needed
          },
        {
            name: 'Agreement No',
            selector: row => row.AgreementNo,
            sortable: true,
        },
        {
            name: 'Date Of Payment',
            selector: row => formatDateForValue(row.ImportRowDateOfPayment),
            sortable: true,
        },
        
        {
            name: 'Amount',
            selector: row => row.ImportRowAmount,
            sortable: true,
        },
        {
            name: 'Mode Of Payment',
            selector: row => row.ImportRowModeOfPayment,
            sortable: true,
        },

        {
            name: 'Status',
            selector: row => row.allCloudPaymentStatus,
            sortable: true,
        },


       /* {
            name: 'Logs',
            cell: row => (
              <div className="btn-group dropstart" title="View Details">
                <NavLink to={`/dashboard/allcloud-loan-repayment/${btoa(String(row.ImportRowID))}/${btoa(String(row.AgreementNo))}`}>
                  <i className="bi bi-eye" style={{ color: "black" }} />
                </NavLink> 
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          }, */
         
    ];

    // Conditional row styles to change text color
        const conditionalRowStyles = [
            {
            when: row => row.LatestEnachRegistrationStatus === 'Success-',   
            style: {
                color: 'green',
            },
            },
            {
            when: row => row.LatestEnachRegistrationStatus === 'Aborted-',  
            style: {
                color: 'red',
            },
            },
            {
            when: row => row.LatestEnachRegistrationStatus === 'Failed-',  
            style: {
                color: 'red',
            },
            },
        ];

    const ExpandedComponent = ({ data }) => (
        <div className='row px-2 py-2'>

        {data && Object.prototype.hasOwnProperty.call(data, 'enach_registration_sms_info') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Amount : </strong> 
                <span className="text-success">{data.enach_registration_sms_info.proposed_amount}
                </span></p>
            </div>
            )}

           {data && Object.prototype.hasOwnProperty.call(data, 'enach_registration_sms_info') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Debit Date : </strong> 
                <span className="text-success">{formatDateForValue(data.enach_registration_sms_info.proposed_debit_date)}</span>
                </p>
            </div>
            )} 

            <div className="col-md-6"><p><strong>Sanction Date : </strong> {formatDateForValue(data.dateOfSanction)}</p></div>
            <div className="col-md-6"><p><strong>Disbusment Date : </strong> {formatDateForValue(data.dateOfDisbursement)}</p></div>
            <div className="col-md-6"><p><strong>Finance Id : </strong> {data.FinanceId}</p></div>
            <div className="col-md-6"><p><strong>Company Role Id : </strong> {data.CompanyRoleId} </p> </div>
            <div className="col-md-6"><p><strong>Loan Type : </strong> {data.LoanType} </p> </div>
            <div className="col-md-6"><p><strong>Asset Id : </strong> {data.AssetId} </p> </div>
            <div className="col-md-6"><p><strong>Total Amount : </strong> {moneyFormatIndia(data.TotalAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Duration : </strong> {data.Duration} </p> </div>
            <div className="col-md-6"><p><strong>Interest (%) : </strong> {data.Interest} </p> </div>
            <div className="col-md-6"><p><strong>EMI : </strong> {moneyFormatIndia(data.EMI)} </p> </div>
            <div className="col-md-6"><p><strong>Yearly Interest (%) : </strong> {data.YearlyInterest} </p> </div>
            <div className="col-md-6"><p><strong>Start Date : </strong> {formatDateForValue(data.StartDate)} </p> </div>
            <div className="col-md-6"><p><strong>Loan Closure Date : </strong> {formatDateForValue(data.LoanClosureDate)} </p> </div>
            <div className="col-md-6"><p><strong>DPD Days : </strong> {data.DPDDays} </p> </div>
            <div className="col-md-6"><p><strong>Future Value : </strong> {data.FutureValue} </p> </div>
            <div className="col-md-6"><p><strong>Insurance Amount : </strong> {moneyFormatIndia(data.InsuranceAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Insurance Duration : </strong> {data.InsuranceDuration} </p> </div>
            <div className="col-md-6"><p><strong>Indicative ROI : </strong> {data.IndicativeROI} </p> </div>
            <div className="col-md-6"><p><strong>Send SMS : </strong> {data.SendSMS} </p> </div>
            <div className="col-md-6"><p><strong>Scheme Id : </strong> {data.SchemeId} </p> </div>
            <div className="col-md-6"><p><strong>EMIType Id : </strong> {data.EMITypeId} </p> </div>
            <div className="col-md-6"><p><strong>LPC Amount : </strong> {moneyFormatIndia(data.LPCAmount)} </p> </div>
            <div className="col-md-6"><p><strong>LPC Type : </strong> {data.LPCType} </p> </div>
            <div className="col-md-6"><p><strong>LPC Interest : </strong> {data.LPCInterest} </p> </div>
            <div className="col-md-6"><p><strong>Installment Type Id : </strong> {data.InstallmentTypeId} </p> </div>
            <div className="col-md-6"><p><strong>ROI Duration : </strong> {data.ROIDuration} </p> </div>
            <div className="col-md-6"><p><strong>Sub Loan Type For PL : </strong> {data.SubLoanTypeForPL} </p> </div>
            <div className="col-md-6"><p><strong>Loan Category : </strong> {data.LoanCategory} </p> </div>
            <div className="col-md-6"><p><strong>Down Payment : </strong> {moneyFormatIndia(data.DownPayment)} </p> </div>
            <div className="col-md-6"><p><strong>Advance EMI : </strong> {data.AdvanceEMI} </p> </div>
            <div className="col-md-6"><p><strong>Loan Segment Id : </strong> {data.LoanSegmentId} </p> </div>
            <div className="col-md-6"><p><strong>UTR No : </strong> {data.UTRNo} </p> </div>
            <div className="col-md-6"><p><strong>Disbursement Status : </strong> {data.DisbursementStatus} </p> </div>
            <div className="col-md-6"><p><strong>Nach StatusId : </strong> {data.NachStatusId} </p> </div>
            <div className="col-md-6"><p><strong>UMRN Number : </strong> {data.UMRNNumber} </p> </div>
            <div className="col-md-6"><p><strong>Status Id : </strong> {data.StatusId} </p> </div>
            <div className="col-md-6"><p><strong>EMI Start Date : </strong> {formatDateForValue(data.EMIStartDate)} </p> </div>
            <div className="col-md-6"><p><strong>EMI End Date : </strong> {formatDateForValue(data.EMIEndDate)} </p> </div>
            <div className="col-md-6"><p><strong>Next Payment Date : </strong> {formatDateForValue(data.NextPaymentDate)} </p> </div>
            <div className="col-md-6"><p><strong>Next EMI Due Date : </strong> {formatDateForValue(data.NextEMIDueDate)} </p> </div>
            <div className="col-md-6"><p><strong>Mode Of RePayment Id : </strong> {data.ModeOfRePaymentId} </p> </div>
            <div className="col-md-6"><p><strong>No Of Paid EMI : </strong> {data.NoOfPaidEMI} </p> </div>
            <div className="col-md-6"><p><strong>Total Received Amt : </strong> {moneyFormatIndia(data.TotalReceivedAmt)} </p> </div>
            <div className="col-md-6"><p><strong>Total Principle Recvd : </strong> {moneyFormatIndia(data.TotalPrincipleRecvd)} </p> </div>
            <div className="col-md-6"><p><strong>Total Interest Paid : </strong> {moneyFormatIndia(data.TotalInterestPaid)} </p> </div>
            <div className="col-md-6"><p><strong>EMI DueCount : </strong> {data.EMIDueCount} </p> </div>
            <div className="col-md-6"><p><strong>Total Principal Due : </strong> {moneyFormatIndia(data.TotalPrincipalDue)} </p> </div>
            <div className="col-md-6"><p><strong>Total Interest Due : </strong> {moneyFormatIndia(data.TotalInterestDue)} </p> </div>
            <div className="col-md-6"><p><strong>Total VAS Dues : </strong> {moneyFormatIndia(data.TotalVASDues)} </p> </div>
            <div className="col-md-6"><p><strong>Total Due Amount : </strong> {moneyFormatIndia(data.TotalDueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>LPI Dues : </strong> {moneyFormatIndia(data.LPIDues)} </p> </div>
            <div className="col-md-6"><p><strong>Default Chrages : </strong> {moneyFormatIndia(data.DefaultChrages)} </p> </div>
            <div className="col-md-6"><p><strong>Total EMI Overdue Amount : </strong> {moneyFormatIndia(data.TotalEMIOverdueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Total Loan Overdue Amount : </strong> {moneyFormatIndia(data.TotalLoanOverdueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Loan Total Due : </strong> {moneyFormatIndia(data.LoanTotalDue)} </p> </div>
            <div className="col-md-6"><p><strong>Last updated : </strong> {data.cronRunDate} </p> </div>
        </div>
    );

    const [open, setOpen] = React.useState(false);
    const btnOpenUploadMissingData = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedItrFiles([]); // Reset selected ITR files
    };

    const [openEnach, setOpenEnach] = React.useState(false);
    const btnOpenEnachData = () => setOpenEnach(true);
    const handleCloseEnach = () => {
        setOpenEnach(false); 
        setPartialAmount('');
        setPartialDebitDate('');
     };

    const [selectedItrFiles, setSelectedItrFiles] = useState([]);

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedItrFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    

    const handleFormSubmit = (data) => {

        if (!selectedItrFiles || selectedItrFiles.length === 0) {
            toast.error("No file has been selected.", {
                position: "top-right",
            });
        } else {

            const params = {"upload_file": selectedItrFiles };

            // setPreloaderStatus(true);
            const requestData = encryptData(params);
            const response = actions.uploadMissingData(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - uploadLeads form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                    reset();
                    setOpen(false);
                    setSelectedItrFiles([]);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const defaultQRCall = useCallback(async () => {

        if( !loadDefaultQR && totalOutstandingAmt > 0){
            const params = { 'amount':totalOutstandingAmt, "AgreementNo" : businessDoneLoan.AgreementNo };
            if (userId !== '') {
                params.user_id = userId;
            }
        
            setIntentUrl('');
            // console.log("form data = ", params);
            const requestData = encryptData(params);            
            try {
                const result = await actions.generateQRcode(requestData);
                setLoadDefaultQR(true);                
                if (result.data.success === 1) {
                    if (result.data.intent_url !== '') {
                        setIntentUrl(result.data.intent_url);
                        toast.success("QR code generated successfully.", {
                            position: "top-right",
                        });
                    } else {
                        toast.error("You can't generate QR code, Please try again!", {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            } catch (error) {
                toast.error(error.message || "An error occurred", {
                    position: "top-right",
                });
            }
        }
    }, [userId, formValues, loadDefaultQR, totalOutstandingAmt]);
    
    useEffect(() => {
        if (!loadDefaultQR) {
         //  defaultQRCall();
        }
    }, [loadDefaultQR, defaultQRCall, totalOutstandingAmt]);

    
    const handleSubmitForm = (data) => {
        const params = { 'amount':  formValues.amount, "AgreementNo" : businessDoneLoan.AgreementNo };
        if (userId !== '') {
            params.user_id = userId;
        }
        setIntentUrl('');
        // console.log("form data = ", params);
        const requestData = encryptData(params);
        const response = actions.generateQRcode(requestData);
        response.then((result) => {
             if (result.data.success === 1) {
                if (result.data.intent_url !== '') {  
                    setIntentUrl(result.data.intent_url);  
                    toast.success("QR code generated sucessfully.", {
                        position: "top-right",
                    });
                } else {
                    toast.error("You can't generate QR code, Please try again!", {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
             toast.error(error, {
                position: "top-right",
            });
        });
    };   
    const handleChange = (e) => { 
       // setIntentUrl('');
        const { name, value } = e.target;      
        setFormValues({
            ...formValues,
            [name]: value,
        });         
    };

 
    const handleDateChange = (e) => {
        const fullDate = e.target.value; // YYYY-MM-DD
        alert(fullDate);
       // const [year, month, day] = fullDate.split('-');
       // setFilterdate(`${month}-${day}`); // Display only MM-DD
       
    };

    // ready to run e-nach api for single record
    const handleFormSubmitEnach = (data) => {
        console.log("data = ", data)
        if (data.partial_enach_proposed_amount === '' || data.partial_debit_date === '') {
            toast.error("Please enter details.", {
                position: "top-right",
            });
        } else { 
            setPartialAmount(data.partial_enach_proposed_amount);
            setPartialDebitDate(data.partial_debit_date);
            setRunApis(true); 
        }
    };

    const btnSkipPartialPayment = (data) => {
        setPartialAmount('');
        setPartialDebitDate('');
        setRunApis(true); 
    };
    
    const { importName } = useParams();
    const [pageHeading , setPageHeading]  = useState('');
    const [iframeSrc , setIframeSrc]  = useState('');
    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {
        let appUrl = config.APP_URL;
        appUrl = appUrl.replace('/public/', '/'); 
            setPageHeading('Import Report');
            setIframeSrc(`${appUrl}excel_report/excel_import_clearance_data_report_dev.php?referrer=${btoa('lms_page')}`);
        
        setIframeLoaded(true);

    }, [importName, setIframeLoaded]);

    // console.log("importName : ", importName);
    // console.log("iframeSrc : ", iframeSrc);

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

   
    return (
        <>  
            <Helmet>
                <title>View Transaction Scheduling | Ckers</title>
            </Helmet>

            <Container>

            <div className='table-wrap'>
                            {!iframeLoaded && <div><div className='p-spinner'><div className="spinner">&nbsp;</div></div></div>}
                                <iframe
                                    title="Report"
                                    width="100%"
                                    height="130"
                                    src= {iframeSrc}
                                    frameBorder="0"
                                    allowFullScreen
                                    onLoad={handleIframeLoad}
                                    style={{ display: iframeLoaded ? 'block' : 'none' }}
                                 />
                            </div>
                <hr/>
                <div id='main-wrap'  className={loanDetailStatus ? "d-none mb-3 mmt-7 filter" : "mb-3 mmt-7 filter"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row mx-0'>
                         
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="keyword">
                                        <span>Keyword</span>
                                        <input type='text' name='keyword' id='keyword' className='form-control'{...register('keyword')} placeholder="Enter your value here..." onChange={handleKeywordChange}/>
                                    </label>
                                </div>
                            </div>

                            
                            
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Search</button>
                                </div>

                                
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2 '>
                                    <span className='d-block'>&nbsp;</span>
                                    <button onClick={btnVerifySubmit}
                                        type="button"
                                        className="btn btn-success text-white"
                                    >
                                        Verify & Submit
                                    </button> 
                                </div>

                                <div className='form-group mb-2 d-none'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button onClick={btnOpenUploadMissingData}
                                        type="button"
                                        className="btn btn-info text-white"
                                    >
                                        Upload Missing Data
                                    </button> 
                                </div>

                                <div className='form-group mb-2 d-none'> 
                                     <span className='d-block'>&nbsp;</span>
                                    <NavLink to={`/dashboard/allcloud-loan-repayment`}>
                                    <button
                                        type="button"
                                        className="btn btn-primary text-white"
                                    >
                                        View Transaction Scheduling
                                    </button>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <div className={auditAllLoansButtonStatus ? "col" : "col d-none"}>
                                    <button onClick={goBackBtnFromAuditAllLoans}
                                        type="button"
                                        className="badge text-bg-success text-white"
                                    >
                                        Go Back 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                         

                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                            Records {businessDoneLoan.AgreementNo !== undefined && (
                                    <>
                                        ({businessDoneLoan.AgreementNo})
                                    </>
                                )}
                            </Typography> 
                            
                            <div className={auditAllLoansButtonStatus ? "d-none" : "m-1 d-none"}>
                                <button className="btn btn-info text-white" onClick={() => auditAllLoansButton()}>
                                    AUDIT ALL LOANS
                                </button>
                            </div>
                        </div>


                        <div className='col-md-5 text-end ps-lg-5'>                            
                        {(!loanDetailStatus) && (
                            <>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <label htmlFor="search" className='w-100 d-none'>
                                    {/* Search : */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                                <button className="btn btn-info text-white ms-3 d-none" onClick={() => exportLoansReport()}>
                                    Export
                                </button>
                                
                            </div>
                            <div className="float-left mb-2"><b>Total Records : {businessDoneLoans.length}</b></div>
                            </>
                        )}

                        {(loanDetailStatus) && ( 
                            <div>                      
                                <button onClick={goBackBtn} 
                                    type="button"
                                    className= "badge text-bg-success text-white float-right"
                                    > 
                                Go Back
                                </button> 
                                </div>                                
                            )}
                           
                        </div>
                        
                        {(!loanDetailStatus) && (
                            <>
                                <div className='row'>
                                    <div className="col-md-12 mt-1">
                                        <div className="tabs">
                                            {cronRunDateArr.map((tab, index) => (
                                                <button
                                                    key={index}
                                                    className={activeLoanTab === index ? 'btn active activTab btn-primary' : 'btn'}
                                                    onClick={() => handleTabClick(index)}
                                                >
                                                    {tab.formatted_cron_run_date}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        )}
                           
                    </div>
                    
                     

                    {(!loanDetailStatus) ? (
                        <>
                       { (businessDoneLoans.length > 0) && (<div>
                            <button className="btn btn-info text-white" onClick={handleSelectAll}>Select All</button>&nbsp;
                            <button className="btn btn-danger text-white" onClick={handleUnselectAll}>Unselect All</button>
                        </div>) }
                        <DataTable
                            // title="My DataTable with Expandable Rows"
                            columns={columns}
                            data={businessDoneLoans}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            conditionalRowStyles={conditionalRowStyles}
                            // pagination
                        />
                        </>
                    ) : (
                        <>
                        <div className="mt-4">

                        <div className="row">

                        <div className="col-md-12 mt-4">  
                            <div className="tabs">
                                {reportName !=='' && versioningLoans.map((tab, index) => (
                                 
                                <button                                   
                                    className={activeTab === index ? 'btn active activTab btn-primary' : 'btn'}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.formated_cron_run_date}
                                </button>
                                 
                                ))}
                            </div>
                            </div>
                           <div className="col-md-12">   
                            <div className="tab-content">

                            {versioningLoans.map((vitem, vindex) => { 
                                return (
                                    <> 
                                        <div  key={vindex} className={activeTab === vindex ? 'width100' : 'width100 d-none'}> 
                                            <LogsVersioning vitem={vitem} />
                                        </div>
                                     </>
                                );
                                })}
                            </div> 
                            </div> 
                            </div>
                        </div>
                    </>)}

                     
                </div>

                {preloaderStatus && <Loader />}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Upload Additional Data</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} encType="multipart/form-data">
                                <div className="row mx-0">
                                    <div className="col-md-6 px-0">
                                        <div className="form-group">
                                            <label htmlFor="itr_file">
                                                <span>File Upload <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='file'
                                                    id='itr_file'
                                                    name='itr_file'
                                                    className='form-control'
                                                    {...register('itr_file')}
                                                    onChange={handleItrFileChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                open={openEnach}
                onClose={handleCloseEnach}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Partial Payment</h4>
                        <Button onClick={handleCloseEnach} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmitEnach)} encType="multipart/form-data">
                                <div className="row mx-0">
                                    <div className="col-md-6 px-0">
                                        <div className="form-group">
                                            <label htmlFor="partial_enach_proposed_amount">
                                                <span>Amount</span>
                                                <input
                                                    type='number'
                                                    id='partial_enach_proposed_amount'
                                                    name='partial_enach_proposed_amount'
                                                    className='form-control'
                                                    {...register('partial_enach_proposed_amount')}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="partial_debit_date">
                                                <span>Debit Date </span>
                                                <input
                                                    type='date'
                                                    id='partial_debit_date'
                                                    name='partial_debit_date'
                                                    className='form-control'
                                                    {...register('partial_debit_date')}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2"
                                            >
                                                Submit
                                            </button>

                                            <button className="btn btn-warning mt-2 float-right"
                                             type="button"
                                            onClick={btnSkipPartialPayment} >
                                                Skip Partial Payment
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getLoansClearanceData: state.getLoansClearanceData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllCloudLoanRepaymentDev);