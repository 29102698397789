import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import $ from 'jquery';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import { format } from 'date-fns';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import LeadCommunication from './LeadCommunication';
import LeadChecklists from './LeadChecklists';
import Questionnaire from './Questionnaire';
import LeadQuestionnaire from './LeadQuestionnaire';
import SummaryInfo from './SummaryInfo';
import AccessDenied from '../common/AccessDenied';
import IncidentReporteCreate from '../incidentReporte/IncidentReporteCreate';
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import CoApplicant from './CoApplicant';
import Guarantor from './Guarantor';
import UploadBankStatement from './UploadBankStatement';
import UploadITR from './UploadITR';
import UploadGST from './UploadGST';
import NoteHistoryPage from './NoteHistoryPage';
import * as groupsUsersActions from "../../actions/groupsUsersActions";
import * as leadGstActions from "../../actions/leadGstActions";
import * as leadItrActions from "../../actions/leadItrActions";
import * as leadBankStatementActions from "../../actions/leadBankStatementActions";
import * as campaignsActions from "../../actions/campaignsActions";
import UploadDocument from './UploadDocument';
import LeadUploadAcivity from './LeadUploadAcivity';
import LeadChecklistScore from './LeadChecklistScore';
import LeadChecklistDynamicScore from './LeadChecklistDynamicScore';
import ManageShipmentByLeadListingPage from '../manage_shipment/ManageShipmentByLeadListingPage';
import * as masterAction from "../../actions/masterAction";
import FiDetils from './FiDetils';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AddEditLeadPage = (props) => {
    const { id, actionName } = useParams();
    const location = useLocation();
    const { actions, leadsData, leadInfo, usersData, basicLeadInfo, sourceData, masterProfileData, leadChecklistData, interactionTypeData, leadStatusData, getUserModuleData, incidentReporteData, leadGstByLeadData, leadItrByLeadData, leadBankStatementByLeadData } = props;
    const [allLeads, setAllLeads] = useState([]);
    const [open, setOpen] = useState(false);
    const [openQue, setOpenQue] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showLeadDetails, setShowLeadDetails] = useState(false);
    const [leadId, setLeadId] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [showLeadCommunicationDetails, setShowLeadCommunicationDetails] = useState(false);
    const [allSourceData, setAllSource] = useState([]);
    const [allMasterProfiles, setAllMasterProfiles] = useState([]);
    const [allInteractionTypeData, setAllInteractionTypeData] = useState([]);
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allInteractions, setAllInteractions] = useState([]);
    const [allLeadCheckLists, setAllLeadCheckLists] = useState([]);
    const [pageHeading, setPageHeading] = useState('');
    const [questions, setQuestions] = useState([]);


    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [panDetailsInfoName, setPanDetailsInfoName] = useState('');
    const [phoneDetailsInfoName, setPhoneDetailsInfoName] = useState('');
    const [panDetailsInfoDoB, setPanDetailsInfoDoB] = useState('');
    const [panDetailsInfoGender, setPanDetailsInfoGender] = useState('');
    const [panDetailsInfoAddress, setPanDetailsInfoAddress] = useState('');
    const [leadPanDetails, setLeadPanDetails] = useState('');
    const [showTicketListDetails, setShowTicketListDetails] = useState(false);
    const [allTicketData, setAllTicketData] = useState([]);

    const [showListDetails, setShowListDetails] = useState(false);
    const [allGroupData, setAllGroupData] = useState([]);
    const [getAllUsersListData, setAllUsersListData] = useState([]);

    const [allLeadGstByLeadData, setAllLeadGstByLeadData] = useState([]);
    const [allLeadItrByLeadData, setAllLeadItrByLeadData] = useState([]);
    const [allLeadBankStatementByLeadData, setAllLeadBankStatementByLeadData] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');
    const [isCrifUser, setIsCrifUser] = useState(true);
    const [isExternalUser, setIsExternalUser] = useState(false);

    const [allStates, setAllStates] = useState([]);
    const [allAddressTypes, setAllAddressTypes] = useState([]);
    const [showNote, setShowNote] = useState('');
    const [showNoteNoteByCredit, setShowNoteNoteByCredit] = useState('');
    const [showHistory, setShowHistory] = useState(false);
    const [allPrefillTemplateNameData, setAllPrefillTemplateNameData] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [showActionLeadInfo, setShowActionLeadInfo] = useState(false);

    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [assignedUser, setAssignedUser] = useState("");

    const [isSentToCredit, setIsSentToCredit] = useState(true);

    const [allLeadActivity, setAllLeadActivity] = useState([]);
    const [preloaderStatus, setPreloaderStatus] = useState(false);
    const [preloaderStatusPhone, setPreloaderStatusPhone] = useState(false);
    const [actionType, setActionType] = useState('');
    const [activeTabName, setActiveTabName] = useState('basic');

    const [allLeadCheckListScore, setAllLeadCheckListScore] = useState([]);
    const [allLeadCheckListDynamicScore, setAllLeadCheckListDynamicScore] = useState([]);

    const [creditAllLeadCheckLists, setCreditAllLeadCheckLists] = useState([]);
    const [scoreCard, setScoreCard] = useState("");
    const [allShipment, setAllShipment] = useState([]);
    const [leadStatusFlag, setLeadStatusFlag] = useState(false);
    const [outputBefiscHtml, setOutputBefiscHtml] = useState('');
    const [isExternalUserContent, setIsExternalUserContent] = useState('');
    const [mobileReadOnly, setMobileReadOnly] = useState(false);

    const [allOem, setAllOem] = useState([]);
    const [allOemUsage, setAllOemUsage] = useState([]);

    const [allFiSaveHistory, setAllFiSaveHistory] = useState([]);

    // lead Type start    
    const [getAllChildsData1, setAllChildsData1] = useState([]);
    const [selectedOutcome, setSelectedOutcome] = useState('');
    const handleOutCome = () => {
        const params = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(params);
        const response = actions.leadStatusDataAllDropdown(requestData, false);
        response.then((result) => {
            response.then((result) => {
                console.log("result123", result);
                if (result.data) {
                    const results = result.data;
                    setAllChildsData1(results);
                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });
        })
    }

    useEffect(() => {
        handleOutCome();
    }, []);



    // lead Type end



    const [formValues, setFormValues] = useState({
        name: '',
        created_by: '',
        status: '',
        phone: '',
        email_id: '',
        dob:'',
        full_address: '',
        city: '',
        state: '',
        pin: '',
        aadhar: '',
        pan: '',
        query: '',
        campaign: '',
        user: '',
        lead_profile: '',
        lead_assigned_group: '',
        prefill_template_name: '',
        oem_usage: '',
        oem: '',
        loan_amount: '',
        dealer_name: '',
        lead_type_id: '',
        sub_dealer_name: ''
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Leads');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
 
                if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type')  && result.data.user_info[0].role_type !==''){
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);

                    setIsCrifUser(result.data.user_info[0].is_crif_user);
                }

                if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'is_external_user')  && result.data.user_info[0].is_external_user !== '' && result.data.user_info[0].is_external_user === "1"){
                    setIsExternalUser(true);
                    setActiveTabName('basic');
                }

            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });

        setSelectedCampaign("");
        setAssignedUser("");
        setSelectedAssignedGroup("");

    }, [setPermissions, setUserHasPermission, setSelectedCampaign, setAssignedUser, setSelectedAssignedGroup, setIsExternalUser]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData, false);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.masterProfileData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  master profile data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.lead_profile_name.localeCompare(b.lead_profile_name)
                );
                setAllMasterProfiles(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

        setAllStates([]);
        const objStates = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestDataStates = encryptData(objStates);
        const responseStates = actions.masterStatesData(requestDataStates, false);
        responseStates.then((result) => {
            // console.log("Promise resolved -  master profile data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllStates(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllMasterProfiles,setAllStates]);

    useEffect(() => {
        setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj); 
        const response = actions.leadStatusData(requestData);

        response.then((result) => {
            // console.log("Promise resolved -  lead status data :", result); 
            if (result.data.success === 1) {
                setAllLeadStatusData(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.lead_status.localeCompare(b.lead_status)
                );
                setAllLeadStatusData(sortedResultArr);
            }
        }).catch((error) => {
           //  console.error("Promise rejected -  lead status data :", error); 
        }); 

    }, [setAllLeadStatusData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.sourceData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).filter((key) => results[key].status === "1").map((key) => results[key]);
                setAllSource(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

    }, [setAllSource]);

    useEffect(() => {
        setAllPrefillTemplateNameData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.masterPrefillTemplateNameData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                setAllPrefillTemplateNameData(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);

                // Filter the data with status 1 and set it in the state
                const filteredData = resultArr.filter(item => item.status === "1");
                setAllPrefillTemplateNameData(filteredData);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

    }, [setAllPrefillTemplateNameData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  groupData list:", result);
            if (result.data.data.success === 1) {
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    const filteredResultArr = resultArr.filter((item) => item.status === "1");
                    const sortedResultArr = filteredResultArr.sort((a, b) =>
                        a.group_name.localeCompare(b.group_name)
                    );
                    setAllGroupData(sortedResultArr);
                }
            }).catch((error) => {
                console.error("Promise rejected -  lead list:", error);
            });   
        
    }, [setAllGroupData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            setAllCampaigns(sortedResultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getOem(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.oem_name.localeCompare(b.oem_name)
            );
            setAllOem(sortedResultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllOem]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getOemUsage(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.oem_usage_name.localeCompare(b.oem_usage_name)
            );
            setAllOemUsage(sortedResultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllOemUsage]);
    
    useEffect(() => {
        // setAllInteractionTypeData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.interactionTypeData(requestData,false);
        if (interactionTypeData.success) {
            // setAllInteractionTypeData(true);
            setAllInteractionTypeData(interactionTypeData.data);
        }
    }, []);

    const updateParentState = (newValue) => {
        // console.log("updateParentState = ", newValue);
        setShowLeadDetails(newValue);

        if (id !== undefined && !newValue) {
            const decodedId = atob(id);
            setLeadId(decodedId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': decodedId };
            const requestDataUpdateParentState = encryptData(obj);

            const responseParentState = actions.leadInfo(requestDataUpdateParentState, false);
            responseParentState.then((result) => {
                // console.log("Promise resolved -  master profile data:", result); 
                if (result.data.success === 1) {
                    const leadInfoResult = result.data.data;
 
                    if (leadInfoResult.data.length > 0) {
                        setShowLeadDetails(true);
                        newValue = true;

                        if (Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'statusName')) {
                            const statusName =  leadInfoResult.data[0].statusName;

                            if(statusName === "CLOSED"){
                                setLeadStatusFlag(true);
                            } else {
                                setLeadStatusFlag(false); 
                            }
                        }
                        
                        let panNumber = '';
                        if(Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'pancard_number')  && leadInfoResult.data[0].pan_number !=='' &&  leadInfoResult.data[0].pancard_number !== null){
                            panNumber =  leadInfoResult.data[0].pancard_number.replace(/"/g, '');
                        } 
                        
                        let adharNumber = '';
                        if(Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'adhar_number')  && leadInfoResult.data[0].adhar_number !=='' &&  leadInfoResult.data[0].adhar_number !== null){
                            adharNumber =  leadInfoResult.data[0].adhar_number.replace(/"/g, '');
                        }
                         
                        
                        const initialFormValues = {
                            name: leadInfoResult.data[0].name,
                            created_by: leadInfoResult.data[0].created_by,
                            status: leadInfoResult.data[0].status,
                            phone: leadInfoResult.data[0].mobile,
                            email_id: leadInfoResult.data[0].email,
                            dob:leadInfoResult.data[0].dob,
                            full_address: leadInfoResult.data[0].full_address,
                            city: leadInfoResult.data[0].city,
                            state: leadInfoResult.data[0].state,
                            pin: leadInfoResult.data[0].pin,
                            aadhar: adharNumber,
                            pan: panNumber,
                            query: leadInfoResult.data[0].lead_query,
                            campaign: leadInfoResult.data[0].campaign,
                            // user: leadInfoResult.data[0].lead_assigned,
                            lead_profile: leadInfoResult.data[0].lead_profile,
                            lead_assigned_group: leadInfoResult.data[0].lead_assigned_group,
                            prefill_template_name: leadInfoResult.data[0].prefill_template_name,
                            oem_usage: leadInfoResult.data[0].oem_usage,
                            oem: leadInfoResult.data[0].oem,
                            loan_amount: leadInfoResult.data[0].loan_amount,
                            dealer_name: leadInfoResult.data[0].dealer_name,
                            lead_type_id: leadInfoResult.data[0].lead_type_id,
                            sub_dealer_name: leadInfoResult.data[0].sub_dealer_name
                        };

                        setFormValues(initialFormValues);

                        // get lead assigned checklists
                        const checkListsResults = leadInfoResult.data[0].all_checklists;
                        const checkListsResultsArr = Object.keys(checkListsResults).map((key) => checkListsResults[key]);
                        setAllLeadCheckLists(checkListsResultsArr);

                        const creditCheckListsResults = leadInfoResult.data[0].credit_all_checklists;
                        const creditCheckListsResultsArr = Object.keys(creditCheckListsResults).map((key) => creditCheckListsResults[key]);
                        setCreditAllLeadCheckLists(creditCheckListsResultsArr);

                        const leadScoreResults = leadInfoResult.data[0].lead_score;
                        setAllLeadCheckListScore(leadScoreResults);

                        const leadDynamicScoreResults = leadInfoResult.data[0].lead_dynamic_score;
                        setAllLeadCheckListDynamicScore(leadDynamicScoreResults);

                        const fiSaveHistory = leadInfoResult.data[0].fiSaveHistory;
                        setAllFiSaveHistory(fiSaveHistory);

                        const leadActivityResults = leadInfoResult.data[0].lead_activity;
                        setAllLeadActivity(leadActivityResults);

                        const shipmentResults = leadInfoResult.data[0].shipment;
                        setAllShipment(shipmentResults);

                        if (Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'credit_user_answered')) {
                            setScoreCard(leadInfoResult.data[0].credit_user_answered);
                        }

                        setPageHeading(`${leadInfoResult.data[0].name} - ${leadInfoResult.data[0].mobile}`);

                        handleCampaign({ target: { value: leadInfoResult.data[0].campaign } });
                        setSelectedCampaign(leadInfoResult.data[0].campaign);

                        handleChangeAssignedGroup({ target: { value: leadInfoResult.data[0].lead_assigned_group } });
                        setSelectedAssignedGroup(leadInfoResult.data[0].lead_assigned_group);

                        handleAssignedUser({ target: { value: leadInfoResult.data[0].lead_assigned } });
                        setAssignedUser(leadInfoResult.data[0].lead_assigned);

                        //  alert('karza = 2');   
                        if (Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'pan_details_decrypt') && Object.prototype.hasOwnProperty.call(leadInfoResult.data[0].pan_details_decrypt, 'address')) {
                            const panDetailsAddress = `${leadInfoResult.data[0].pan_details_decrypt.address.buildingName} ${leadInfoResult.data[0].pan_details_decrypt.address.city} ${leadInfoResult.data[0].pan_details_decrypt.address.country} ${leadInfoResult.data[0].pan_details_decrypt.address.streetName} ${leadInfoResult.data[0].pan_details_decrypt.address.state} ${leadInfoResult.data[0].pan_details_decrypt.address.pinCode}`;


                            setPanDetailsInfoName(leadInfoResult.data[0].pan_details_decrypt.name);
                            setPanDetailsInfoDoB(leadInfoResult.data[0].pan_details_decrypt.dob);
                            setPanDetailsInfoGender(leadInfoResult.data[0].pan_details_decrypt.gender);
                            setPanDetailsInfoAddress(panDetailsAddress);

                            setLeadPanDetails(leadInfoResult.data[0].pan_details_decrypt);
                        }

                        setMobileReadOnly(false);

                        if ((Object.prototype.hasOwnProperty.call(leadInfoResult.data[0], 'crif_info') && leadInfoResult.data[0].crif_info !== '')) {
                            const crifInfoRemark = leadInfoResult.data[0].crif_info.crif_remarks;
                            if (crifInfoRemark) {
                                setMobileReadOnly(true);
                            } else {
                                setMobileReadOnly(false);
                            }
                        }
                    }
                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });
        }
    };

    const fetchLeads = useCallback(() => {
        setShowLeadDetails(false);

        setShowNoteNoteByCredit("");
        setShowNote("");

        // console.log("fetchLeads calling ", id, showLeadDetails);
        if (id !== undefined && !showLeadDetails) {
            const decodedId = atob(id);
            setLeadId(decodedId);

            if (decodedId !== '') {
                setActiveTabName('communication');
                if (actionName !== undefined) {
                    setActiveTabName('basic');
                }
            }

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': decodedId };
            const requestData = encryptData(obj);
            const response = actions.leadInfo(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  leadInfo:", result); 
                if (result.data.success === 1) {
    
                    const leadInfoData = result.data.data;
                    setMobileReadOnly(false);
                    if ('data' in leadInfoData && leadInfoData.data.length > 0) {
                        setShowLeadDetails(true);

                        let BefiscHtml = '';
                        let BefiscStatus = '';

                        if ((Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'crif_info') && leadInfoData.data[0].crif_info !== '')) {
                            const crifInfoRemark = leadInfoData.data[0].crif_info.crif_remarks;
                            if (crifInfoRemark) {
                                setMobileReadOnly(true);
                            } else {
                                setMobileReadOnly(false);
                            }
                        }

                         if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'befiscApiRunStatus') && leadInfoData.data[0].befiscApiRunStatus) { 

                                if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'mobileage_response')) { 
                                
                                    const mobileageObj = leadInfoData.data[0].mobileage_response;

                                    if(mobileageObj.length  ===0){
                                        BefiscHtml += `<span class="text-danger">Phone Number Age not found.</span>`;
                                    }else if (Object.prototype.hasOwnProperty.call(mobileageObj, 'result') && Object.prototype.hasOwnProperty.call(mobileageObj.result, 'mobile_age')) {
                                            BefiscHtml += `<div>Mobile Age : ${mobileageObj.result.mobile_age}</div>`;
                                            BefiscStatus = 'true';
                                    }
                                    
                                } 
        
                                if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'multiple_upi_response')) { 
                                const upiObj = leadInfoData.data[0].multiple_upi_response;

                                if(upiObj.length ===0){
                                    BefiscHtml += `<span class="text-danger">UPI Handle not found.</span>`;
                                }else{
                                    if (Object.prototype.hasOwnProperty.call(upiObj, 'result') && Object.prototype.hasOwnProperty.call(upiObj.result, 'name')) {
                                        BefiscHtml += `<div>Name : ${upiObj.result.name}</div>`;
                                        BefiscStatus = 'true';
                                    }
            
                                    if (Object.prototype.hasOwnProperty.call(upiObj, 'result') && Object.prototype.hasOwnProperty.call(upiObj.result, 'upi') && Array.isArray(upiObj.result.upi)) {
                                            BefiscHtml += `<div>UPIs : ${upiObj.result.upi.join(', ')}</div>`;
                                            BefiscStatus = 'true';
                                    } 
                                }
                            } 


                            }else{
                                 BefiscHtml += `<span class="text-danger">UPI Handle and Phone Number API not run.</span><span>Please click the <b>'Get Phone Number Age + UPI Handle'</b> button.</span> `;
                            }
                        setOutputBefiscHtml(BefiscHtml);
                        setPhoneDetailsInfoName(BefiscStatus);

                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'statusName')) {
                            const statusName =  leadInfoData.data[0].statusName;

                            if(statusName === "CLOSED"){
                                setLeadStatusFlag(true);
                            } else {
                                setLeadStatusFlag(false); 
                            }
                        }
    
                        let panNumber = '';
                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'pancard_number') && leadInfoData.data[0].pan_number !== '' && leadInfoData.data[0].pancard_number !== null) {
                            panNumber = leadInfoData.data[0].pancard_number.replace(/"/g, '');
                        }
    
                        let adharNumber = '';
                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'adhar_number') && leadInfoData.data[0].adhar_number !== '' && leadInfoData.data[0].adhar_number !== null) {
                            adharNumber = leadInfoData.data[0].adhar_number.replace(/"/g, '');
                        }
    
                        const initialFormValues = {
                            name: leadInfoData.data[0].name,
                            created_by: leadInfoData.data[0].created_by,
                            status: leadInfoData.data[0].status,
                            phone: leadInfoData.data[0].mobile,
                            email_id: leadInfoData.data[0].email,
                            dob: leadInfoData.data[0].dob,
                            full_address: leadInfoData.data[0].full_address,
                            city: leadInfoData.data[0].city,
                            state: leadInfoData.data[0].state,
                            pin: leadInfoData.data[0].pin,
                            aadhar: adharNumber,
                            pan: panNumber,
                            query: leadInfoData.data[0].lead_query,
                            // campaign: leadInfoData.data[0].campaign,
                            // user: leadInfoData.data[0].lead_assigned,
                            lead_profile: leadInfoData.data[0].lead_profile,
                            // lead_assigned_group: leadInfoData.data[0].lead_assigned_group,
                            prefill_template_name: leadInfoData.data[0].prefill_template_name,
                            oem_usage: leadInfoData.data[0].oem_usage,
                            oem: leadInfoData.data[0].oem,
                            loan_amount: leadInfoData.data[0].loan_amount,
                            dealer_name: leadInfoData.data[0].dealer_name,
                            lead_type_id: leadInfoData.data[0].lead_type_id,
                            sub_dealer_name: leadInfoData.data[0].sub_dealer_name
                        };
                       
                        setFormValues(initialFormValues);

                         // alert('karza = 3');
                         if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'pan_details_decrypt') && Object.prototype.hasOwnProperty.call(leadInfoData.data[0].pan_details_decrypt, 'address')) {
                            const panDetailsAddress = `${leadInfoData.data[0].pan_details_decrypt.address.buildingName} ${leadInfoData.data[0].pan_details_decrypt.address.city} ${leadInfoData.data[0].pan_details_decrypt.address.country} ${leadInfoData.data[0].pan_details_decrypt.address.streetName} ${leadInfoData.data[0].pan_details_decrypt.address.state} ${leadInfoData.data[0].pan_details_decrypt.address.pinCode}`;
                             
    
                            setPanDetailsInfoName(leadInfoData.data[0].pan_details_decrypt.name);
                            setPanDetailsInfoDoB(leadInfoData.data[0].pan_details_decrypt.dob);
                            setPanDetailsInfoGender(leadInfoData.data[0].pan_details_decrypt.gender);
                            setPanDetailsInfoAddress(panDetailsAddress);
    
                            setLeadPanDetails(leadInfoData.data[0].pan_details_decrypt);
                        }

                        // get lead assigned checklists
                        const results = leadInfoData.data[0].all_checklists;
                        const resultChecklistArr = Object.keys(results).map((key) => results[key]);
                        setAllLeadCheckLists(resultChecklistArr);

                        const creditCheckListsResults = leadInfoData.data[0].credit_all_checklists;
                        const creditCheckListsResultsArr = Object.keys(creditCheckListsResults).map((key) => creditCheckListsResults[key]);
                        setCreditAllLeadCheckLists(creditCheckListsResultsArr);

                        const leadScoreResults = leadInfoData.data[0].lead_score;
                        setAllLeadCheckListScore(leadScoreResults);

                        const leadDynamicScoreResults = leadInfoData.data[0].lead_dynamic_score;
                        setAllLeadCheckListDynamicScore(leadDynamicScoreResults);

                        const fiSaveHistory = leadInfoData.data[0].fiSaveHistory;
                        setAllFiSaveHistory(fiSaveHistory);

                        const leadActivityResults = leadInfoData.data[0].lead_activity;
                        setAllLeadActivity(leadActivityResults);

                        const shipmentResults = leadInfoData.data[0].shipment;
                        setAllShipment(shipmentResults);

                        setPageHeading(`${leadInfoData.data[0].name} - ${leadInfoData.data[0].mobile}`);

                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], '_isSentToCredit')) {
                            setIsSentToCredit(leadInfoData.data[0]._isSentToCredit === '1');
                        }

                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'credit_user_answered')) {
                            setScoreCard(leadInfoData.data[0].credit_user_answered);
                        }

                        handleCampaign({ target: { value: leadInfoData.data[0].campaign } });
                        setSelectedCampaign(leadInfoData.data[0].campaign);
                        
                        handleChangeAssignedGroup({ target: { value: leadInfoData.data[0].lead_assigned_group } });
                        setSelectedAssignedGroup(leadInfoData.data[0].lead_assigned_group);
                        
                        handleAssignedUser({ target: { value: leadInfoData.data[0].lead_assigned } });
                        setAssignedUser(leadInfoData.data[0].lead_assigned);

                        if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'lead_credit')) {
                            
                            if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0].lead_credit, 'created_date') && leadInfoData.data[0].lead_credit.created_date) {
                                const note = `Sent to Credit On ${leadInfoData.data[0].lead_credit.created_date} `;
                                setShowNote(note);
                                setShowNoteNoteByCredit("");
                                setShowActionLeadInfo(true);
                            }

                            if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0].lead_credit, 'noteAddDate') && leadInfoData.data[0].lead_credit.noteAddDate) {
                                const noteByCredit = `Note added by Credit On ${leadInfoData.data[0].lead_credit.noteAddDate} `;
                                setShowNoteNoteByCredit(noteByCredit);
                                setShowNote("");
                                setShowActionLeadInfo(true);
                            }
                        }
                    }
                }
            }).catch((error) => {
                // console.error("Promise rejected -  leadInfo :", error);
            });
        }
    
        if (id === undefined) {
            const initialFormValues = {
                name: '',
                created_by: '',
                status: '',
                phone: '',
                email_id: '',
                dob: '',
                full_address: '',
                city: '',
                state: '',
                pin: '',
                aadhar: '',
                pan: '',
                query: '',
                campaign: '',
                user: '',
                lead_profile: '',
                lead_assigned_group: '',
                prefill_template_name: '',
                oem_usage:'',
                oem: '',
                loan_amount: '',
                dealer_name: '',
                lead_type_id: '',
                sub_dealer_name: ''
            };
            
            setFormValues(initialFormValues);
    
            setShowLeadDetails(false);
            setAllLeadCheckLists([]);
            setCreditAllLeadCheckLists([]);
            setAllLeadCheckListScore([]);
            setAllLeadCheckListDynamicScore([]);
            setPageHeading('Add Lead');
            setLeadId('');
            setAllInteractions([]);
            $('#pills-basic-info-tab').click();
            setPanDetailsInfoName('');
            setPanDetailsInfoDoB('');
            setPanDetailsInfoGender('');
            setPanDetailsInfoAddress('');
            setLeadPanDetails('');
            setShowNote('');
            setSelectedCampaign('');
            setSelectedAssignedGroup('');
            setAssignedUser('');
            setActiveTabName('basic');
            setLeadStatusFlag(false);
            setOutputBefiscHtml('');
            setMobileReadOnly(false);
            setAllFiSaveHistory([]);
        }

    }, [id, setValue, setShowLeadDetails, setAllLeadCheckLists, setPageHeading, setSelectedCampaign, setSelectedAssignedGroup, setAssignedUser, setAllLeadActivity, setAllLeadCheckListScore, setAllLeadCheckListDynamicScore, setActiveTabName, setCreditAllLeadCheckLists, setAllShipment, setLeadStatusFlag, setOutputBefiscHtml, setMobileReadOnly, setAllFiSaveHistory]);

    const fetchInteractions = useCallback(() => {

        setShowLeadCommunicationDetails(false);
        if (id !== undefined && !showTicketListDetails) {
            const decodedId = atob(id);
            setLeadId(decodedId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': decodedId };
            const requestData = encryptData(obj);
            const response = actions.leadCommunicationInfo(requestData, false);

            response.then((result) => {
                // console.log("Promise resolved:", result);
                if ('data' in result.data) {
                    setAllInteractions(result.data.data);
                }
            }).catch((error) => {
                setAllInteractions([]);
                // console.error("Promise rejected:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }

    }, [id, setAllInteractions]);

    const fetchIncidentReporte = useCallback(() => {

        setShowTicketListDetails(false);
        if (id !== undefined && !showLeadDetails) {
    
            const decodedId = atob(id);
            setLeadId(decodedId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id" :  decodedId};
            const requestData = encryptData(obj);
    
            const response = actions.incidentReporteData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data.data;
                    // console.log("incidentReporte = ", incidentReporte);
                    setAllTicketData(incidentReporte);
                    setShowTicketListDetails(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        }

    }, [id, setShowTicketListDetails, setAllTicketData]);

    const fetchLeadIdGst = useCallback(() => {
        if (id !== undefined) {
    
            const decodedId = atob(id);
            setLeadId(decodedId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id" :  decodedId};
            const requestData = encryptData(obj);
    
            const response = actions.leadGstByLeadData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  leadGstByLeadData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data.data;
                    // console.log("incidentReporte = ", incidentReporte);
                    setAllLeadGstByLeadData(incidentReporte);
                }
            }).catch((error) => {
                console.error("Promise rejected -  leadGstByLeadData list:", error);
            });
        }
    }, [id, setAllLeadGstByLeadData]);

    const fetchLeadIdItr = useCallback(() => {
        if (id !== undefined) {
    
            const decodedId = atob(id);
            setLeadId(decodedId);



            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id" :  decodedId};
            const requestData = encryptData(obj);
    
            const response = actions.leadItrByLeadData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  leadItrByLeadData list:", result);
                if (result.data.success === 1) {
                    const leadItrByLeadRes = result.data.data.data;
                    setAllLeadItrByLeadData(leadItrByLeadRes);
                }
            }).catch((error) => {
                console.error("Promise rejected -  leadItrByLeadData list:", error);
            });
        }
    }, [id, setAllLeadItrByLeadData]);

    const fetchLeadIdBankStatement = useCallback(() => {
        if (id !== undefined) {
    
            const decodedId = atob(id);
            setLeadId(decodedId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id" :  decodedId};
            const requestData = encryptData(obj);
    
            const response = actions.leadBankStatementByLeadData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  leadBankStatementByLeadData list:", result);
                if (result.data.success === 1) {
                    const leadBankStatementByLeadRes = result.data.data.data;
                    setAllLeadBankStatementByLeadData(leadBankStatementByLeadRes);
                }
            }).catch((error) => {
                console.error("Promise rejected -  leadBankStatementByLeadData list:", error);
            });
        }

    }, [id, setAllLeadBankStatementByLeadData]);

    useEffect(() => {
        fetchInteractions();
        fetchLeads();
        fetchIncidentReporte();
        fetchLeadIdGst();
        fetchLeadIdItr();
        fetchLeadIdBankStatement();
    }, [fetchInteractions, fetchLeads, questions, fetchIncidentReporte, fetchLeadIdGst, fetchLeadIdItr, fetchLeadIdBankStatement]);

    const handleSubmitBasicInfo = (data) => {

        const pinCode = formValues.pin;
        const fullAddress = formValues.full_address;
        
        // if (!/^\d{10}$/.test(formValues.phone)) {
        //     toast.error('Invalid mobile number.', {
        //         position: "top-right",
        //     });
        //     return;
        // }

        if (!pinCode) {
            toast.error('Pincode cannot be blank.', {
                position: "top-right",
            });
            return;
        }
        
        if (!/^\d{6}$/.test(pinCode)) {
            toast.error('Pincode must be a 6-digit number.', {
                position: "top-right",
            });
            return;
        }
        
        if (!fullAddress) {
            toast.error('Address can not be blank.', {
                position: "top-right",
            });
            return;
        }
        
        if (fullAddress.length < 10) {
            toast.error('Address is incomplete, please specify fully.', {
                position: "top-right",
            });
            return;
        }

        if (!formValues.oem_usage) {
            toast.error('Usage cannot be blank.', {
                position: "top-right",
            });
            return;
        }

        if (!formValues.oem) {
            toast.error('OEM cannot be blank.', {
                position: "top-right",
            });
            return;
        }

        if (!formValues.loan_amount) {
            toast.error('Loan Amount cannot be blank.', {
                position: "top-right",
            });
            return;
        }

        if (formValues.loan_amount === "0") {
            toast.error('Please enter a valid Loan Amount.', {
                position: "top-right",
            });
            return;
        }

        if (!formValues.dealer_name) {
            toast.error('Please enter dealer name.', {
                position: "top-right",
            });
            return;
        }

        const userRoleTypeVal = userRoleType;

        // set logged-in user id if assigned to user id is blank
        if(formValues.user === ''){
            formValues.user = loggeInUserID;
        }

        if(formValues.prefill_template_name === '' || formValues.prefill_template_name === '0'){
            formValues.prefill_template_name = 1;
        }

        let params = { 'name': formValues.name, 'email': formValues.email_id, 'mobile': formValues.phone, 'full_address': formValues.full_address, 'adhar_number': formValues.aadhar, 'pancard_number': formValues.pan, 'lead_query': formValues.query, 'campaign': selectedCampaign, 'lead_assigned': assignedUser, 'action_user_id': formValues.created_by, 'lead_created_by': formValues.created_by, 'lead_profile': formValues.lead_profile,'pan_details' :leadPanDetails, 'lead_assigned_group': selectedAssignedGroup,'user_role_type' : userRoleTypeVal,'city' : formValues.city ,'state' : formValues.state,'pin' : formValues.pin,'dob' : formValues.dob, 'prefill_template_name' : formValues.prefill_template_name, "oem_usage": formValues.oem_usage,  "oem": formValues.oem, 'loan_amount':formValues.loan_amount, 'dealer_name' : formValues.dealer_name, 'lead_type_id': data.lead_type_id , 'sub_dealer_name': formValues.sub_dealer_name};

        if (leadId !== '') {
            params = { 'name': formValues.name, 'email': formValues.email_id, 'mobile': formValues.phone, 'full_address': formValues.full_address, 'adhar_number': formValues.aadhar, 'pancard_number': formValues.pan, 'lead_query': formValues.query, 'campaign': selectedCampaign, 'lead_assigned': assignedUser, 'action_user_id': formValues.created_by, 'lead_created_by': formValues.created_by, 'lead_profile': formValues.lead_profile, 'lead_id': leadId, 'lead_assigned_group': selectedAssignedGroup,'user_role_type' : userRoleTypeVal,'city' : formValues.city ,'state' : formValues.state,'pin' : formValues.pin,'dob' : formValues.dob, 'prefill_template_name' : formValues.prefill_template_name, "oem_usage": formValues.oem_usage, "oem": formValues.oem, 'loan_amount':formValues.loan_amount, 'dealer_name' : formValues.dealer_name, 'lead_type_id': data.lead_type_id , 'sub_dealer_name': formValues.sub_dealer_name};
        }

        console.log("params : ", params);

        const requestData = encryptData(params);
        const response = actions.addEditLead(requestData);

        response.then((result) => {
            // console.log("Promise resolved - add/edit lead form:", result); 
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                setTimeout(() => {
                    if (leadId === '') {
                        const encodedId = btoa(String(result.data.data));
                        navigate(`/dashboard/edit-lead/${encodedId}`);
                    } else {
                        updateParentState(false);
                        navigate('/dashboard/leads');
                    }
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            // console.error("Promise rejected - add/edit lead form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });

    }; 

    const handlePanDetails = () => {
      // alert(`leadId = ${leadId}, formValues.pan = ${formValues.pan}`);
      setPreloaderStatus(true); 
       const params = { 'lead_id': leadId, 'pan_number': formValues.pan};
       const requestData = encryptData(params);
       const response = actions.updateLeadKarzaPanDetails(requestData);

       response.then((result) => {
           // console.log("Promise resolved - add/edit lead form:", result);
           setLeadPanDetails(''); 
           setPanDetailsInfoName('');  
           setPanDetailsInfoDoB('');  
           setPanDetailsInfoGender('');  
           setPanDetailsInfoAddress('');
           if (result.data.success === 1) { 
               console.log("karza pan response = ", result.data.data); 
               if(result.data.data.data.statusCode === 101){ 
                setPreloaderStatus(false);
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
 
                   // alert('karza = 1');
                      if (Object.prototype.hasOwnProperty.call(result.data.data.data.result, 'address')) { 
                        const panDetailsAddress = `${result.data.data.data.result.address.buildingName} ${result.data.data.data.result.address.city} ${result.data.data.data.result.address.country} ${result.data.data.data.result.address.streetName} ${result.data.data.data.result.address.state} ${result.data.data.data.result.address.pinCode}`;
      
                        setPanDetailsInfoName(result.data.data.data.result.name);  
                        setPanDetailsInfoDoB(result.data.data.data.result.dob);  
                        setPanDetailsInfoGender(result.data.data.data.result.gender);  
                        setPanDetailsInfoAddress(panDetailsAddress); 
                        
                        setLeadPanDetails(result.data.data.data);
                    } 
                     
               }else if (Object.prototype.hasOwnProperty.call(result.data.data.data, 'statusMessage')) { 
                    setPreloaderStatus(false);  
                    toast.error(result.data.data.data.statusMessage, {
                        position: "top-right",
                    });
              }else{
                    setPreloaderStatus(false);
                    toast.error("No response from api", {
                        position: "top-right",
                    });
             }
        
               
                
           } else {
             setPreloaderStatus(false);
               toast.error(result.data.message, {
                   position: "top-right",
               });
           }
       }).catch((error) => {
        setPreloaderStatus(false);
           // console.error("Promise rejected - add/edit lead form:", error);
           toast.error(error, {
               position: "top-right",
           });
       });

    };

    const handlePhoneDetails = () => {
         setPreloaderStatusPhone(true); 
         const params = { 'lead_id': leadId, 'phone_number': formValues.phone};
         const requestData = encryptData(params);
         const response = actions.validateBefiscPhoneDetails(requestData);
  
         response.then((result) => {
             // console.log("Promise resolved - add/edit lead form:", result);
             setLeadPanDetails('');  
             
             if (result.data.success === 1) { 
                 console.log("Befisc phone response = ", result.data.data); 
                 setPreloaderStatusPhone(false);
                      toast.success(result.data.message, {
                          position: "top-right",
                      });
    

                      let BefiscHtml = '';
                      let BefiscStatus = '';

                      if (Object.prototype.hasOwnProperty.call(result.data.data, 'befiscApiRunStatus') && result.data.data.befiscApiRunStatus) {
                         if (Object.prototype.hasOwnProperty.call(result.data.data, 'mobileage_response')) { 
                           
                            const mobileageObj = result.data.data.mobileage_response;

                            if(mobileageObj.length  ===0){
                                BefiscHtml += `<span class="text-danger">Phone Number Age not found.</span>`;
                            }else if (Object.prototype.hasOwnProperty.call(mobileageObj, 'result') && Object.prototype.hasOwnProperty.call(mobileageObj.result, 'mobile_age')) {
                                BefiscHtml += `<div>Mobile Age : ${mobileageObj.result.mobile_age}</div>`;
                                BefiscStatus = 'true';
                            }
                        } 
  
                        if (Object.prototype.hasOwnProperty.call(result.data.data, 'multiple_upi_response')) { 
                          const upiObj = result.data.data.multiple_upi_response;

                        if(upiObj.length ===0){
                            BefiscHtml += `<span class="text-danger">UPI Handle not found.</span>`;
                         }else{
                            if (Object.prototype.hasOwnProperty.call(upiObj, 'result') && Object.prototype.hasOwnProperty.call(upiObj.result, 'name')) {
                                BefiscHtml += `<div>Name : ${upiObj.result.name}</div>`;
                                BefiscStatus = 'true';
                            }
    
                            if (Object.prototype.hasOwnProperty.call(upiObj, 'result') && Object.prototype.hasOwnProperty.call(upiObj.result, 'upi') && Array.isArray(upiObj.result.upi)) {
                                    BefiscHtml += `<div>UPIs : ${upiObj.result.upi.join(', ')}</div>`;
                                    BefiscStatus = 'true';
                            } 
                        }
                      } 

                    }else{
                        BefiscHtml += `<span class="text-danger">UPI Handle and Phone Number API not run.</span><span>Please click the <b>'Get Phone Number Age + UPI Handle'</b> button.</span> `;
                   }
                        
                        setOutputBefiscHtml(BefiscHtml);
                        setPhoneDetailsInfoName(BefiscStatus);
                 
             } else {
                setPreloaderStatusPhone(false);
                 toast.error(result.data.message, {
                     position: "top-right",
                 });
             }
         }).catch((error) => {
            setPreloaderStatusPhone(false);
             // console.error("Promise rejected - add/edit lead form:", error);
             toast.error(error, {
                 position: "top-right",
             });
         });
  
      };

    const handleChangeAssignedGroup = (event, actionName ='') => {
        const selectedValue = event.target.value;
        setSelectedAssignedGroup(selectedValue);

        if(selectedValue !== ""){
            const userRoleTypeVal = userRoleType;
            

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id" :  selectedValue,'user_role_type' : userRoleTypeVal};
            const requestData = encryptData(obj);
        
            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);
                       // setAllUsersListData(usersListData[0].users_list);
                    }
                    setSelectedAssignedGroup(selectedValue);

                   if( actionName !== 'selectedUser' ){
                     const userSetReset = { user: '' };
                     setFormValues((prevValues) => ({ ...prevValues, ...userSetReset }));
                   }
                }
            }).catch((error) => {
                // console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
        }
    };


    const handleCampaign = (event, actionName ='') => {
        const selectedCampaignId = event.target.value;
        setSelectedCampaign(selectedCampaignId);
        const selectedCampaignInfo = allCampaigns.find((campaign) => campaign.id === selectedCampaignId);

        if (selectedCampaignInfo) {
            setSelectedAssignedGroup(selectedCampaignInfo.assigned_group);
            setAssignedUser(selectedCampaignInfo.assigned_user);

            handleAssignedUser({ target: { value: selectedCampaignInfo.assigned_user } });
            handleChangeAssignedGroup({ target: { value: selectedCampaignInfo.assigned_group } });
        }
    }

    const handleAssignedUser = (event) => {
        const user = event.target.value;
        setAssignedUser(user);
    }
    
    

    const handleCrifAPI = (actionName, sectionId,formRemarkData) => { 


        if(formRemarkData.purpose_deployment_vehicle.trim() === ''){
            toast.error("Purpose and deployment of vehicle is blank.", {
                position: "top-right",
            }); 
          
        }else if(formRemarkData.investor_profile.trim() === ''){
            toast.error("Investor profile is blank.", {
                position: "top-right",
            }); 
          
        }else if(formRemarkData.purpose_deployment_vehicle.trim() === 'ATTACHED' && formRemarkData.remarks.trim() === ''){
            toast.error("Remarks is required.", {
                position: "top-right",
            }); 
             
        } else if(!formRemarkData.consentDetailsStatus){
            toast.error("Please check the consent details checkbox if you want to proceed for bureau.", {
                position: "top-right",
            }); 
           
        }else if(formRemarkData.consentDetails.trim() === ''){
            toast.error("Consent Details is required.", {
                position: "top-right",
            });
            
        }else if(formRemarkData.dob.trim() === ''  || formRemarkData.dob ==='0000-00-00'){
            toast.error("DoB is required.", {
                position: "top-right",
            });     
        }else if(formRemarkData.full_address.trim() === ''){
            toast.error("Address is required.", {
                position: "top-right",
            }); 
        }else if(formRemarkData.city.trim() === ''){
            toast.error("City is required.", {
                position: "top-right",
            });         
        }else if(formRemarkData.state.trim() === ''){
            toast.error("State is required.", {
                position: "top-right",
            }); 
            
        }else if(formRemarkData.pin.trim() === ''){
            toast.error("Pin is required.", {
                position: "top-right",
            }); 
        }else if(formRemarkData.pan_number.trim() === ''){
            toast.error("Pan is required.", {
                position: "top-right",
            });     
           
        }else{

            // alert(`${actionName} - ${sectionId}`);
                const params = { 'action': actionName, id:sectionId,lead_id : leadId,'formRemarkData' : formRemarkData};
                const requestData = encryptData(params);
                const response = actions.callCrifAPI(requestData);

            response.then((result) => {
                    console.log("Promise resolved - CRIF form:", result); 
                if (result.data.success === 1) { 
                    console.log("crif response = ", result.data.data);  
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                        updateParentState(false);           
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - CRIF form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
            }
      };

      const handleCrifOTPAPI = (actionName, sectionId,formOTPData) => { 
        if(formOTPData.otp.trim() === ''){
            toast.error("OTP is blank.", {
                position: "top-right",
            });          
        }else{
            // alert(`${actionName} - ${sectionId}`);
                const params = { 'action': actionName, id:sectionId,lead_id : leadId,'formOTPData' : formOTPData,'brief' :'otp_verification',"verifed_side" : 'ViaLms'};
                const requestData = encryptData(params);
                const response = actions.callCrifAPI(requestData);

            response.then((result) => {
                    console.log("Promise resolved - CRIF form:", result); 
                if (result.data.success === 1) { 
                    console.log("crif response = ", result.data.data);  
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                        updateParentState(false);           
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - CRIF form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
            }
      }; 
      
      const handleCrifResedOTPAPI = (actionName, sectionId,formOTPData) => { 
        if(actionName.trim() === ''){
            toast.error("Please try again.", {
                position: "top-right",
            });          
        }else{
            // alert(`${actionName} - ${sectionId}`);
                const params = { 'action': actionName, id:sectionId,lead_id : leadId,'formOTPData' : formOTPData,'brief' :'resend_otp',"verifed_side" : 'ViaLms'};
                const requestData = encryptData(params);
                const response = actions.callCrifAPI(requestData);

            response.then((result) => {
                    console.log("Promise resolved - CRIF form:", result); 
                if (result.data.success === 1) { 
                    console.log("crif response = ", result.data.data);  
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                        updateParentState(false);           
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - CRIF form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
            }
      };

    function handleCredit() {
        const params = { 'lead_id': leadId };
        // console.log("handleCredit = ", params);
        const requestData = encryptData(params);
        const response = actions.addCredit(requestData);
        response.then((result) => {
            // console.log("Promise resolved - add/edit lead form:", result);
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                const addCreditInfo = result.data.data;
                if(addCreditInfo !== ""){
                    if (Object.prototype.hasOwnProperty.call(addCreditInfo, 'created_date')) {
                        const showResNote = `Sent to Credit On ${addCreditInfo.created_date} `;
                        setShowNote(showResNote);
                    }
                    fetchLeads();
                }
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            // console.error("Promise rejected - add/edit lead form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });

    }

    const onShowHistoryClickAction = () => {
        setShowHistory(true);
    }

    const backToLeadHandler = () => {
        setShowHistory(false);
    }

    useEffect(() => {
        if(isExternalUser){
            if (allInteractions && allInteractions.length > 0) {
                if(Object.prototype.hasOwnProperty.call(allInteractions[0], 'lead_status')  && allInteractions[0].lead_status !== ''){
                    if(allInteractions[0].lead_status === "Need Documents"){
                        const noteIsExternalUserContent = `${allInteractions[0].lead_status} - ${allInteractions[0].subLeadStatus} Updated On ${allInteractions[0].DateOfInteraction} `;
                        setIsExternalUserContent(noteIsExternalUserContent);
                    } else {
                        setIsExternalUserContent('');
                    }
                }
            }
        }
    }, [allInteractions, setIsExternalUserContent]);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    
    return (
        <>
            <Helmet>
                <title> {leadId === '' ? 'Add' : 'Edit'} Lead | Ckers </title>
            </Helmet>
            <Container>
                <div className='row align-items-center'>

                    <div className="col-md-6">&nbsp;</div>
                    <div className="col-md-6 flex-container">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.open('https://www.loom.com/share/7e08b5c9445740e49a6deb1db65e338f?sid=2cd51dae-4278-4167-8309-c397108f35eb', '_blank')}
                            >
                                Tutorial  Video 1
                            </Button>
                            
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.open('https://www.loom.com/share/57212dd4839b40f690fc7f18f8ace8c2?sid=62d15608-140a-4a2a-98ad-b0bd170c9a4d', '_blank')}
                            >
                                Tutorial  Video 2
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.open('https://www.loom.com/share/6c8b84a8440c4e9e88dbd02f2057ae90?sid=c09d945b-ad2e-4680-919d-25ad937868a7', '_blank')}
                            >
                                Tutorial  Video 3
                            </Button>
                            
                    </div>

                    <div className='col-md-6'>
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            {leadId === '' ? 'Add Lead' : (
                                <>
                                {pageHeading}
                                <br />
                                <Typography variant="body3" sx={{ color: 'red' }}>
                                    {isExternalUserContent}
                                </Typography>
                                </>
                            )}
                        </Typography>
                    </div>

                    <div className= {!showLeadDetails ? "col-md-6 hide ":"col-md-6 show"}>
                        {userRoleType !== 'Credit' ? (
                            showNote !== "" ? (
                                <div className="text-end">{showNote}</div>
                            ) : (
                                showNoteNoteByCredit !== "" ? (
                                    <>
                                    <div className="text-end" role="button"
                                        tabIndex={0}
                                        onClick={() => onShowHistoryClickAction()}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' || event.key === 'Space') {
                                            onShowHistoryClickAction();
                                            }
                                        }}
                                        disabled={leadId === ''}
                                        >
                                        {showNoteNoteByCredit} 
                                        <i className="bi bi-eye">&nbsp;</i>
                                    </div>
                                    { isExternalUser || !isSentToCredit && ( 
                                    <div> 
                                        <div className='col-md-12 py-2'>
                                            <div className='form-group text-end mb-2'>
                                                <button className="btn btn-primary" onClick={() => handleCredit()} disabled={leadId === '' || leadStatusFlag === true}> Send To Credit </button>
                                            </div>
                                        </div>
                                    </div>)}
                                    </>
                                ) : (
                                <div>
                                    {!isExternalUser && ( <> 
                                    <div className='col-md-12 py-2'>
                                        <div className='form-group text-end mb-2'>
                                            <button className="btn btn-primary" onClick={() => handleCredit()} disabled={leadId === '' || leadStatusFlag === true}> Send To Credit </button>
                                        </div>
                                    </div>
                                    </>)}
                                </div>)
                            )
                        ) : (
                            <></>
                        )}
                    </div>

                </div>
                
                <div id='main-wrap' className='mb-5'>
                    <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={activeTabName === 'basic' ? "nav-link active":"nav-link"} id="pills-lead-info-tab" data-bs-toggle="pill" data-bs-target="#pills-lead-info" type="button" role="tab" aria-controls="pills-lead-info" aria-selected={activeTabName === 'basic' ? "true":"false"}  onClick={backToLeadHandler}>Lead Information</button>
                        </li>
                        
                        {!isExternalUser && ( <>
                        <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-lead-check-tab" data-bs-toggle="pill" data-bs-target="#pills-lead-check" type="button" role="tab" aria-controls="pills-lead-check" aria-selected="false"
                                disabled={leadId === ''}
                                onClick={backToLeadHandler} >Lead Checklist</button>
                        </li>
                        </>)}
                       {/* <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link active" id="pills-communication-tab" data-bs-toggle="pill" data-bs-target="#pills-communication" type="button" role="tab" aria-controls="pills-communication" aria-selected="true"                       
                            >Communication</button>
                        </li> */}

                        {isExternalUser && ( <>
                        <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className={activeTabName === 'documemt' ? "nav-link active":"nav-link"} id="pills-document-tab" data-bs-toggle="pill" data-bs-target="#pills-document" type="button" role="tab" aria-controls="pills-document" aria-selected={activeTabName === 'document' ? "true":"false"}                       
                             disabled={leadId === ''} onClick={backToLeadHandler}>Document</button>
                        </li>
                        </>)}
                        
                        <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className={activeTabName === 'communication' ? "nav-link active":"nav-link"} id="pills-communication-tab" data-bs-toggle="pill" data-bs-target="#pills-communication" type="button" role="tab" aria-controls="pills-communication" aria-selected={activeTabName === 'communication' ? "true":"false"}                       
                             disabled={leadId === ''} onClick={backToLeadHandler}>Activity</button>
                        </li>

                        {!isExternalUser && ( <>
                        <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-dynamic-lead-score-tab" data-bs-toggle="pill" data-bs-target="#pills-dynamic-lead-score" type="button" role="tab" aria-controls="pills-score" aria-selected={activeTabName === 'score' ? "true":"false"}                       
                            disabled={leadId === ''} onClick={backToLeadHandler}>Scorecard</button>
                        </li>
                        
                        <li className="nav-item" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                                <button className="nav-link" id="pills-dynamic-fi-details-tab" data-bs-toggle="pill" data-bs-target="#pills-dynamic-fi-details" type="button" role="tab" aria-controls="pills-score" aria-selected={activeTabName === 'fi' ? "true" : "false"}
                                    disabled={leadId === ''} onClick={backToLeadHandler}>FI Details</button>
                            </li>

                        <li className="nav-item d-none" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-activity-tab" data-bs-toggle="pill" data-bs-target="#pills-activity" type="button" role="tab" aria-controls="pills-activity" aria-selected={activeTabName === 'activity' ? "true":"false"}                       
                             disabled={leadId === ''} onClick={backToLeadHandler}>Activity</button>
                            
                        </li>

                        <li className="nav-item d-none" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-shipment-tab" data-bs-toggle="pill" data-bs-target="#pills-shipment" type="button" role="tab" aria-controls="pills-shipment" aria-selected={activeTabName === 'shipment' ? "true":"false"}                       
                             disabled={leadId === ''} onClick={backToLeadHandler}>Shipment</button>
                        </li>

                      <li className="nav-item d-none" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-reference-tab" data-bs-toggle="pill" data-bs-target="#pills-reference-check" type="button" role="tab" aria-controls="pills-upload-reference-check" aria-selected={activeTabName === 'reference' ? "true":"false"}
                                disabled={leadId === ''}
                                onClick={backToLeadHandler}>Reference</button>
                        </li>
                        {/* <li className="nav-item d-none" role="presentation" title={leadId === '' ? "Please enter the basic lead informations." : ""}>
                            <button className="nav-link" id="pills-incident-tab" data-bs-toggle="pill" data-bs-target="#pills-incident-check" type="button" role="tab" aria-controls="pills-upload-incident-check" aria-selected="false"
                                disabled={leadId === ''}
                                onClick={backToLeadHandler}>Incident Reporting</button>
                        </li> */}
                        </>)}
                    </ul>


                    {showHistory ? (
                        <NoteHistoryPage
                            props={props}
                            leadId={leadId}
                            setShowHistory={setShowHistory}
                        />
                    ) : (<></>)}

                    <div className= {!showHistory ? "tab-content show ":"tab-content hide"} id="pills-tabContent">
                        <div className={activeTabName === 'basic' ? "tab-pane fade show active":"tab-pane fade"} id="pills-lead-info" role="tabpanel" aria-labelledby="pills-lead-info-tab" tabIndex="0">
                        <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-basic-info-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-basic-info"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-basic-info"
                                    aria-selected="true"
                                >
                                    Basic Information
                                </button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-summary-tab" data-bs-toggle="pill" data-bs-target="#pills-summary" type="button" role="tab" aria-controls="pills-summary" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>Summary</button>
                                </li>

                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-basic-info" role="tabpanel" aria-labelledby="pills-basic-info-tab" tabIndex="0">
                                    <div className="accordion first-level" id="accordionLI">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#LI-1" aria-expanded="true" aria-controls="LI-1" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                    Basic Information
                                                </button>
                                            </h2>
                                            <div id="LI-1" className="accordion-collapse collapse show" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                 <h5 className='fw-bold mb-3' > Basic Information </h5>
    < form onSubmit = { handleSubmit(handleSubmitBasicInfo) } >
        <div className='row' >
            <div className='col-md-6' >
                <div className='form-group' >
                    <label htmlFor="name" >
                        <span>Name < a style = {{ color: "red" }}>* </a></span >
                            <input
                    type='text'
id = 'name'
name = 'name'
className = 'form-control'
{...register('name') }
value = { formValues.name }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
{ errors.name && <span className="required" > This field is required.</span> }
</div>
    </div>
    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="email" >
                <span>Email ID </span>
                    < input
type = 'email'
id = 'email_id'
name = 'email_id'
className = 'form-control'
{...register('email_id') }
value = { formValues.email_id }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>
    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="phone" >
                <span>Phone Number < a style = {{ color: "red" }}>* </a></span >
                    <input
                    type='tel'
id = 'phone'
name = 'phone'
className = 'form-control '
{...register('phone') }
value = { formValues.phone }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true || mobileReadOnly === true}
                />

{
    !isExternalUser && (<>
        { phoneDetailsInfoName === '' ? (
            <>
            <button
                                type= "button"
                                className = "btn btn-success float-right validate-phone"
    onClick = {() => handlePhoneDetails()
}
                            >
    { preloaderStatusPhone && (<>
        <div className="spinner-border small-preloader" role = "status" >
            <span className="visually-hidden" > Loading...</span>
                < /div></ >)}
                                Get Phone Number Age + UPI Handle
    </button>
    < />) : (<></ >)}
</>)}
</label>
{ errors.phone && <span className="required" > This field is required.</span> }
</div>

{
    !isExternalUser && (<>
        <div dangerouslySetInnerHTML={ { __html: outputBefiscHtml.replace(/\n/g, '<br />') } } />
            </>)
}
</div>

    < div className = "col-md-6" >
        <div className="form-group" >
            <label htmlFor="dob" >
                <span>Date Of Birth </span>
                    < input type = "date" id = "dob" name = "dob" className = "form-control"
{...register('dob') }
value = { formValues.dob }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
            />
    </label>
    </div>
    </div>

    < div className = "col-md-6 d-none" >
        <div className='form-group' >
            <label htmlFor="lead-profile" >
                <span>Address Type </span>
                    < select
id = 'addressTypeId'
name = 'addressTypeId'
className = "form-select"
{...register('addressTypeId') }
value = { formValues.addressTypeId }
onChange = { handleChange }

    >
    <option value='' > Select Address Type </option>
{
    allAddressTypes.length > 0 && allAddressTypes.map((option) => (
        <option value= { option.id } >
        { option.type_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="full_address" >
                <span>Address < a style = {{ color: "red" }}>* </a></span >
                    <input
                    type='text'
id = 'full_address'
name = 'full_address'
className = 'form-control'
{...register('full_address') }
value = { formValues.full_address }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>

    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="city" >
                <span>City </span>
                < input
type = 'text'
id = 'city'
name = 'city'
className = 'form-control'
{...register('city') }
value = { formValues.city }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>

    < div className = "col-md-6" >
        <div className='form-group' >
            <label htmlFor="lead-profile" >
                <span>State </span>
                < select
id = 'state'
name = 'state'
className = "form-select"
{...register('state') }
value = { formValues.state }
onChange = { handleChange }
disabled = { userRoleType === 'Credit' || leadStatusFlag === true}
                >
    <option value='' > Select State </option>
{
    allStates.length > 0 && allStates.map((option) => (
        <option value= { option.state_code } >
        { option.state_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>


    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="pin" >
                <span>Pincode < a style = {{ color: "red" }}>* </a></span >
                    <input
                    type='number'
id = 'pin'
name = 'pin'
className = 'form-control'
{...register('pin') }
value = { formValues.pin }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>


    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="aadhar" >
                <span>Aadhar Card Number </span>
                    < input
type = 'tel'
id = 'aadhar'
name = 'aadhar'
className = 'form-control'
{...register('aadhar') }
value = { formValues.aadhar }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>
    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="pan" >
                <span>PAN Card Number </span>
                    < input
type = 'text'
id = 'pan'
name = 'pan'
className = 'form-control'
{...register('pan') }
value = { formValues.pan }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>

{
    panDetailsInfoName === '' || !isExternalUser ? (
        <>
        <button
                    type= "button"
                    className = "btn btn-success float-right pan_fetch"
    onClick = {() => handlePanDetails()
}
                >
    { preloaderStatus && (<>
        <div className="spinner-border small-preloader" role = "status" >
            <span className="visually-hidden" > Loading...</span>
                < /div></ >)}

                    PAN Profile Details
    </button>
    < />) : (<></ >)}
<div className='f-14px rounded overflow-hidden' >
    { panDetailsInfoName !== '' ? (<div className= "py-1 px-2 bg-light" > <b>Name : </b>  {panDetailsInfoName}</div >) : (<></>)}
{ panDetailsInfoDoB !== '' ? (<div className= "pb-1 px-2 bg-light" > <b>DoB : </b> {panDetailsInfoDoB}</div >) : (<></>) }
{ panDetailsInfoGender !== '' ? (<div className= "pb-1 px-2 bg-light" > <b>Gender : </b> {panDetailsInfoGender}</div >) : (<></>) }
{ panDetailsInfoAddress !== '' ? (<div className= "pb-1 px-2 bg-light" > <b>Address : </b> {panDetailsInfoAddress}</div >) : (<></>) }
</div>

    </div>
    < div className = 'col-md-12' >
        <div className='form-group' >
            <label htmlFor="query" >
                <span>Lead Query </span>
                    < textarea
id = 'query'
name = 'query'
className = 'form-control'
{...register('query') }
value = { formValues.query }
onChange = { handleChange }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                />
    </label>
    </div>
    </div>

{
    !isExternalUser && (<>
        <div className="col-md-6" id = "next_action_div" >
            <div className='form-group' >
                <label htmlFor="lead-profile" >
                    <span>Lead Profile </span>
                        < select
    id = 'lead_profile'
    name = 'lead_profile'
    className = "form-select"
    {...register('lead_profile') }
    value = { formValues.lead_profile }
    onChange = { handleChange } disabled = { userRoleType === 'Credit' || leadStatusFlag === true
} 
                >
    <option value='' > Select Lead Profile </option>
{
    allMasterProfiles.length > 0 && allMasterProfiles.map((option) => (
        <option value= { option.id } >
        { option.lead_profile_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

{/* <div className={leadId === '' ? "col-md-6" : " col-md-6 hide"}> */ }
<div className="col-md-6" >
    <div className='form-group' >
        <label htmlFor="campaign" >
            <span>Campaign </span>
            < select
id = 'campaign'
name = 'campaign'
className = "form-select"
{...register('campaign') }
value = { selectedCampaign }
onChange = { handleCampaign }
readOnly = { userRoleType === 'Credit' || leadStatusFlag === true}
                >
    <option value='' > Select Campaign </option>
{
    allCampaigns.map((option) => (
        <option value= { option.id } >
        { option.campaign_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>
    </>)}

<div className={ userRoleType !== 'User' ? "col-md-6" : " col-md-6 hide" }>
    <div className='form-group' >
        <label htmlFor="assigned-group" >
            <span>Assigned group </span>
                < select
id = 'lead_assigned_group'
name = 'lead_assigned_group'
className = "form-select"
{...register('lead_assigned_group') }
value = { selectedAssignedGroup }
onChange = { handleChangeAssignedGroup }
disabled = { userRoleType === 'Credit' || leadStatusFlag === true}
                >
    <option value='' > Select assigned group </option>
{
    allGroupData.map((option) => (
        <option value= { option.id } >
        { option.group_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

    < div className = { userRoleType !== 'User' ? "col-md-6" : " col-md-6 hide"}>
        <div className='form-group' >
            <label htmlFor="assigned-user" >
                <span>Assigned to </span>
                    < select
id = 'user'
name = 'user'
className = "form-select"
{...register('user') }
//    value={assignedUser || formValues.user}
value = { assignedUser }
onChange = { handleAssignedUser }
disabled = { userRoleType === 'Credit' || leadStatusFlag === true}
                >
    <option value='' > Select User </option>
{
    getAllUsersListData.map((option) => (
        <option value= { option.user_id } >
        { option.name }
        </option>
    ))
}
{
    getAllUsersListData.length > 0 && (
        <option value='any_user' > Any User </option>
                    )
}
</select>
    </label>
    </div>
    </div>

    < div className = "col-md-6 d-none" id = "next_action_div" >
        <div className='form-group' >
            <label htmlFor="prefill-template-name" >
                <span>Prefill Template Name </span>
                    < select
id = 'prefill_template_name'
name = 'prefill_template_name'
className = "form-select"
{...register('prefill_template_name') }
value = { formValues.prefill_template_name }
onChange = { handleChange }
    >
    {/* <option value="1">EV Vehicle Loan</option> */ }
    < option value = '' > Select Prefill Template Name </option>
{
    allPrefillTemplateNameData.length > 0 && allPrefillTemplateNameData.map((option) => (
        <option key= { option.id } value = { option.id } >
        { option.prefill_template_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

    < div className = "col-md-6" id = "next_action_div" >
        <div className='form-group' >
            <label htmlFor="prefill-template-name" >
                <span>Type Of Vehicle < a style = {{ color: "red" }}>* </a></span >
                    <select
                    id='oem_usage'
name = 'oem_usage'
className = "form-select"
{...register('oem_usage') }
value = { formValues.oem_usage }
onChange = { handleChange }
    >
    {/* <option value="1">EV Vehicle Loan</option> */ }
    < option value = '' > Select Usage </option>
{
    allOemUsage.length > 0 && allOemUsage.map((option) => (
        <option key= { option.id } value = { option.id } >
        { option.oem_usage_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

    < div className = "col-md-6" id = "next_action_div" >
        <div className='form-group' >
            <label htmlFor="prefill-template-name" >
                <span>OEM < a style = {{ color: "red" }}>* </a></span >
                    <select
                    id='oem'
name = 'oem'
className = "form-select"
{...register('oem') }
value = { formValues.oem }
onChange = { handleChange }
    >
    {/* <option value="1">EV Vehicle Loan</option> */ }
    < option value = '' > Select OEM </option>
{
    allOem.length > 0 && allOem.map((option) => (
        <option key= { option.id } value = { option.id } >
        { option.oem_name }
        </option>
    ))
}
</select>
    </label>
    </div>
    </div>

    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="dealer_name" >
                <span>Dealer Name < a style = {{ color: "red" }}>* </a></span >
                    <input
                    type='text'
id = 'dealer_name'
name = 'dealer_name'
className = 'form-control'
{...register('dealer_name') }
value = { formValues.dealer_name }
onChange = { handleChange }
readOnly = { leadStatusFlag === true}
                    />
    </label>
    </div>
    </div>

    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="sub_dealer_name" >
                <span>Sub Dealer Name </span>
                    < input
type = 'text'
id = 'sub_dealer_name'
name = 'sub_dealer_name'
className = 'form-control'
{...register('sub_dealer_name') }
value = { formValues.sub_dealer_name }
onChange = { handleChange }
readOnly = { leadStatusFlag === true}
                    />
    </label>
    </div>
    </div>

    < div className = 'col-md-6' >
        <div className='form-group' >
            <label htmlFor="loan_amount" >
                <span>Loan Amount < a style = {{ color: "red" }}>* </a></span >
                    <input
                        type='text'
id = 'loan_amount'
name = 'loan_amount'
className = 'form-control'
{...register('loan_amount') }
value = { formValues.loan_amount }
onChange = { handleChange }
readOnly = { leadStatusFlag === true}
                    />
    </label>
    </div>
    </div>

{/* Type of Vehicle Start */ }
<div className="col-md-6" >
    <div className="form-group" >
        <label htmlFor="final_outcome" >
            <span>Type of Lead </span>
                < select id = "lead_type" className = "form-select" {...register('lead_type_id') }
value = { formValues.lead_type_id }
onChange = { handleChange } >
    <option value='' > Select lead Type </option>
{
    getAllChildsData1.length > 0 && getAllChildsData1.map((option) => (
        <option value= { option.id } >
        { option.lead_status }
        </option>
    ))
}
</select>
    </label>
{/* {errors.final_outcome && <span className="required">This field is required.</span>} */ }
</div>
    </div>

{/* Type of Vehicle End */ }
<div className='col-md-12' >
    <div className='form-group mb-2' >
        <button disabled={ leadStatusFlag === true } className = "btn btn-primary" > Save </button>
            </div>
            </div>
            </div>
            </form>
                                                </div>
                                            </div>
                                        </div>

                                        {!isExternalUser && ( <>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-2" aria-expanded="true" aria-controls="LI-2" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                    Co-Applicant
                                                </button>
                                            </h2>
                                            <div id="LI-2" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <CoApplicant
                                                    leadId={leadId}
                                                    componentLeadId={leadId} 
                                                    updateParentState={updateParentState}
                                                    showActionLeadInfo={showActionLeadInfo}
                                                    allStates={allStates}
                                                    leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-3" aria-expanded="true" aria-controls="LI-3" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                Guarantor
                                                </button>
                                            </h2>
                                            <div id="LI-3" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <Guarantor
                                                        leadId={leadId}
                                                        componentLeadId={leadId}
                                                        updateParentState={updateParentState}
                                                        showActionLeadInfo={showActionLeadInfo}
                                                        allStates={allStates}
                                                        leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-4" aria-expanded="true" aria-controls="LI-4" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                Bank Statement
                                                </button>
                                            </h2>
                                            <div id="LI-4" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <UploadBankStatement
                                                        allLeadBankStatementByLeadData={allLeadBankStatementByLeadData}
                                                        leadId={leadId}
                                                        fetchLeadIdBankStatement={fetchLeadIdBankStatement}
                                                        updateParentState={updateParentState}
                                                        showActionLeadInfo={showActionLeadInfo}
                                                        leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-5" aria-expanded="true" aria-controls="LI-5" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                ITR
                                                </button>
                                            </h2>
                                            <div id="LI-5" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <UploadITR
                                                        allLeadItrByLeadData={allLeadItrByLeadData}
                                                        leadId={leadId}
                                                        fetchLeadIdItr={fetchLeadIdItr}
                                                        updateParentState={updateParentState}
                                                        showActionLeadInfo={showActionLeadInfo}
                                                        leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-6" aria-expanded="true" aria-controls="LI-6" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                GST
                                                </button>
                                            </h2>
                                            <div id="LI-6" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <UploadGST
                                                        allLeadGstByLeadData={allLeadGstByLeadData}
                                                        leadId={leadId}
                                                        fetchLeadIdGst={fetchLeadIdGst}
                                                        updateParentState={updateParentState}
                                                        showActionLeadInfo={showActionLeadInfo}
                                                        leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#LI-7" aria-expanded="true" aria-controls="LI-7" role="tab" aria-selected={leadId === '' ? "true":"false"} disabled={leadId === ''}>
                                                Document
                                                </button>
                                            </h2>
                                            <div id="LI-7" className="accordion-collapse collapse" data-bs-parent="#accordionLI">
                                                <div className="accordion-body p-md-3 px-2 pt-2 pb-4">
                                                    <UploadDocument
                                                        leadId={leadId}
                                                        componentLeadId={leadId}
                                                        updateParentState={updateParentState}
                                                        showActionLeadInfo={showActionLeadInfo}
                                                        allStates={allStates}
                                                        leadStatusFlag={leadStatusFlag}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        </>)}
                                    </div>
                                </div>
                                <div className="tab-pane fade first-level" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab" tabIndex="0">
                                    <SummaryInfo 
                                        props={props}
                                        leadId={leadId}
                                        leadInfo={leadInfo}
                                        updateParentState={updateParentState}
                                        handleCrifAPI={handleCrifAPI}
                                        handleCrifOTPAPI={handleCrifOTPAPI}
                                        handleCrifResedOTPAPI={handleCrifResedOTPAPI}
                                        userRoleType = {userRoleType}
                                        isCrifUser = {isCrifUser}
                                        allStates = {allStates}
                                        scoreCard = {scoreCard}
                                        leadStatusFlag={leadStatusFlag}
                                        isExternalUser={isExternalUser}
                                    /> 
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-lead-check" role="tabpanel" aria-labelledby="pills-lead-check-tab" tabIndex="0">

                            {!open ? (<LeadChecklists
                                props={props}
                                allLeadCheckLists={allLeadCheckLists}
                                setOpen={setOpen}
                                setViewModalInfo={setViewModalInfo}
                                setQuestions={setQuestions}
                                setOpenQue={setOpenQue}
                                userRoleType={userRoleType}
                                creditAllLeadCheckLists={creditAllLeadCheckLists}
                                leadStatusFlag={leadStatusFlag}
                            />
                            ) : (
                                <>
                                    { /* <Questionnaire
                                    questions={questions}
                                    checklistInfo={viewModalInfo}
                                    props={props}
                                    setOpen={setOpen}
                                    fetchLeads={fetchLeads}
                                    updateParentState={updateParentState}
                            /> */}

                                    <LeadQuestionnaire
                                        questions={questions}
                                        checklistInfo={viewModalInfo}
                                        props={props}
                                        setOpen={setOpen}
                                        fetchLeads={fetchLeads}
                                        updateParentState={updateParentState}
                                        userRoleType={userRoleType}
                                        scoreCard={scoreCard}
                                        leadStatusFlag={leadStatusFlag}
                                    />
                                </>
                            )}
                        </div>

                        <div className={activeTabName === 'communication' ? "tab-pane fade show active":"tab-pane fade"} id="pills-communication" role="tabpanel" aria-labelledby="pills-communication-tab" tabIndex="0">

                            <LeadCommunication
                                allInteractions={allInteractions}
                                leadId={leadId}
                                allInteractionTypeData={allInteractionTypeData}
                                fetchInteractions={fetchInteractions}
                                leadStatusFlag={leadStatusFlag}
                                updateParentState={updateParentState}
                                isExternalUser={isExternalUser}
                            />

                        </div>

                        <div className={activeTabName === 'activity' ? "tab-pane fade show active":"tab-pane fade"} id="pills-activity" role="tabpanel" aria-labelledby="pills-activity-tab" tabIndex="0">
                            <LeadUploadAcivity
                                props={props}
                                allLeadActivity={allLeadActivity}
                                setOpen={setOpen}
                            />

                        </div>

                        <div className={activeTabName === 'shipment' ? "tab-pane fade show active":"tab-pane fade"} id="pills-shipment" role="tabpanel" aria-labelledby="pills-shipment-tab" tabIndex="0">
                            <ManageShipmentByLeadListingPage
                                props={props}
                                allShipment={allShipment}
                                leadId={leadId}
                                // setOpen={setOpen}
                            />

                        </div>

                        <div className={activeTabName === 'score' ? "tab-pane fade show active":"tab-pane fade"} id="pills-dynamic-lead-score" role="tabpanel" aria-labelledby="pills-dynamic-lead-score-tab" tabIndex="0">
                            <LeadChecklistDynamicScore
                                props={props}
                                allLeadCheckListDynamicScore={allLeadCheckListDynamicScore}
                                setOpen={setOpen}
                                leadId={leadId}
                                allLeadCheckLists={allLeadCheckLists}
                                userRoleType={userRoleType}
                                creditAllLeadCheckLists={creditAllLeadCheckLists}
                                scoreCard={scoreCard}
                                allLeadCheckListScore={allLeadCheckListScore}
                            />
                        </div>

                        <div className={activeTabName === 'fi' ? "tab-pane fade show active" : "tab-pane fade"} id="pills-dynamic-fi-details" role="tabpanel" aria-labelledby="pills-dynamic-fi-details-tab" tabIndex="0">
                            <FiDetils
                                props={props}
                                allFiSaveHistory={allFiSaveHistory}
                                leadId={leadId}
                            />
                        </div>

                        <div className={activeTabName === 'document' ? "tab-pane fade show active" : "tab-pane fade"} id="pills-document" role="tabpanel" aria-labelledby="pills-communication-tab" tabIndex="0">
                            <UploadDocument
                                leadId={leadId}
                                componentLeadId={leadId}
                                updateParentState={updateParentState}
                                showActionLeadInfo={showActionLeadInfo}
                                allStates={allStates}
                                leadStatusFlag={leadStatusFlag}
                            />
                        </div>

                        {/* <div className="tab-pane fade" id="pills-reference-check" role="tabpanel" aria-labelledby="pills-reference-tab" tabIndex="0">
                            <h5 className='fw-bold mb-3'>Reference</h5>
                            <form>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="r_name">
                                            <span>Name</span>
                                            <input
                                            type='text'
                                            id='r_name'
                                            name='r_name'
                                            className='form-control'
                                            />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="r_email">
                                            <span>Email ID</span>
                                            <input
                                            type='email'
                                            id='r_email'
                                            name='r_email'
                                            className='form-control'
                                            />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="r_phone">
                                            <span>Phone Number</span>
                                            <input
                                            type='tel'
                                            id='r_phone'
                                            name='r_phone'
                                            className='form-control'
                                            />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="r_file">
                                            <span>File Upload</span>
                                            <input
                                            type='file'
                                            multiple
                                            id='r_file'
                                            name='r_file'
                                            className='form-control'
                                            />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group mt-2'>
                                    <button className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div> */}
                        
                        {/* <div className="tab-pane fade" id="pills-incident-check" role="tabpanel" aria-labelledby="pills-incident-tab" tabIndex="0">

                            <IncidentReporteCreate
                                allTicketData={allTicketData}
                                leadId={leadId}
                                fetchIncidentReporte={fetchIncidentReporte}
                                allGroupData={allGroupData}
                            /> */}

                            {/* <div className='d-flex justify-content-between mb-3'>
                                <h5 className='fw-bold mb-3'>Incident Reporting</h5>
                                <RouterLink to={`/dashboard/create-incident-reporting/${btoa(String(leadId))}`} className='add-btn btn-primary no-effect btn'><i className="bi bi-plus">&nbsp;</i> Create Report</RouterLink>
                            </div> 
                            <div className='form-group mt-2'>
                                <RouterLink to={`/dashboard/incident-reporting/${btoa(String(leadId))}`} className='add-btn btn-primary no-effect btn'><i className="bi bi-plus">&nbsp;</i>View Reports</RouterLink>
                            </div> */}
                        {/* </div> */}

                    </div>
                </div>
            </Container>

        </>
    );
}
function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        usersData: state.usersData,
        leadInfo: state.leadInfo,
        sourceData: state.sourceData,
        masterProfileData: state.masterProfileData,
        leadChecklistData: state.leadChecklistData,
        interactionTypeData: state.interactionTypeData,
        leadStatusData: state.leadStatusData,
        getUserModuleData: state.getUserModuleData,
        incidentReporteData: state.incidentReporteData,
        leadGstByLeadData: state.leadGstByLeadData,
        leadItrByLeadData:state.leadItrByLeadData,
        leadBankStatementByLeadData:state.leadBankStatementByLeadData,
        campaignData: state.campaignData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default, groupsUsersActions.default, leadGstActions.default, leadItrActions.default, leadBankStatementActions.default, campaignsActions.default, masterAction.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLeadPage);