import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles'; 
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadChecklists = ({ props, allLeadCheckLists, setOpen,setViewModalInfo,setQuestions, userRoleType, creditAllLeadCheckLists}) => {
    
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
     
    useEffect(() => {
        // console.log("allLeadCheckLists = ", allLeadCheckLists);
        // console.log("creditAllLeadCheckLists = ", creditAllLeadCheckLists);
        console.log("userRoleType = ", userRoleType);
    }, [allLeadCheckLists]);
    
    const onRowClickAction = (itemObj, btnAction) => {

        console.log("itemObj = ", itemObj);

        if(userRoleType === "Credit"){
            setQuestions(itemObj.credit_questions_data_decoded);
        } else {
            setQuestions(itemObj.questions_data_decoded);
        }

        // setQuestions(itemObj.questions_data_decoded);
        setViewModalInfo(itemObj);
        setOpen(true);
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    let filteredItems = "";
    
    if(userRoleType === "Credit"){
        filteredItems = creditAllLeadCheckLists.filter(item =>
            item.check_list_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    } else {
        filteredItems = allLeadCheckLists.filter(item =>
            item.check_list_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
    const endIndex = startIndex + config.ITEMS_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);
    return ( 
        <>
            <div id='main-wrap-'>
                <div className='row justify-content-end'>
                    <div className="col-md-8">
                        <h5 className='fw-bold mb-3'>Lead Checklist</h5>
                    </div>
                    <div className='col-md-4 pe-0 ps-lg-5'>
                        <div className="form-group">
                            <label htmlFor="search">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    id="search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className='table-wrap'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Checklist Name</th>
                                <th>Total Questions</th>
                                <th>Total Answers</th>
                                <th>Qualification Checklist</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.check_list_name}</td>
                                    <td>{item.total_questions}</td>
                                    <td>{item.total_answered_questions}</td>
                                    {/* <td>{item.total_answered_questions > 0 && item.total_required_questions > 0 && item.total_required_answered_questions === item.total_required_questions ? (<><i className="bi bi-check text-green sx-20">&nbsp;</i></>) : (<><i className="bi bi-x text-red sx-20">&nbsp;</i></>)}</td> */}
                                    <td>{item.total_answered_questions > 0 && item.total_required_questions >= 0 && item.total_required_answered_questions === item.total_required_questions ? (<><i className="bi bi-check text-green sx-20">&nbsp;</i></>) : (<><i className="bi bi-x text-red sx-20">&nbsp;</i></>)}</td>

                                    <td>
                                        <div className="btn-group dropstart">
                                            <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <RouterLink onClick={() => onRowClickAction(item, 'view')} ><i className="bi bi-eye">&nbsp;</i> Questions</RouterLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>

                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadChecklists);