import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadsReducer(state = initialState.leadsData, action) {
  switch (action.type) {
    case types.LOAD_LEADS_DATA_SUCCESS:
      return action.leadsData;
    default:
      return state;
  }
}
