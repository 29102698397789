import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui  
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";

import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadsPage = (props) => {
  const { actions, leadsData, usersData, sourceData, getUserModuleData, masterProfileData, leadStatusData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allLeads, setAllLeads] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewModalInfo, setViewModalInfo] = useState([]);
  const [showLeadDetails, setShowLeadDetails] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState([]);
  const [getSelectedLeadStatus, setSelectedLeadStatus] = useState([]);
  const [getSelectedLeadProfile, setSelectedLeadProfile] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allLeadSource, setAllLeadSource] = useState([]);


  const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [allLeadStatusData, setAllLeadStatusData] = useState([]);
  const [allMasterProfiles, setAllMasterProfiles] = useState([]);
  const [legalFormId, setLegalFormId] = useState('person');

  const [userRoleType, setUserRoleType] = useState('');
  const [loggeInUserID, setLoggeInUserID] = useState('');

  const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
  const [isExternalUser, setIsExternalUser] = useState(false);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Leads')?.view_permission;
        setPermissions(permissionsData);
        setViewModulePermissionStatus(hasViewPermissions);
        setUserHasPermission(hasViewPermissions);

        if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type')  && result.data.user_info[0].role_type !==''){
            setUserRoleType(result.data.user_info[0].role_type);
            // alert(result.data.user_info[0].role_type);
            setLoggeInUserID(result.data.user_info[0].user_id);
        }

        if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'is_external_user')  && result.data.user_info[0].is_external_user !== '' && result.data.user_info[0].is_external_user === "1"){
          setIsExternalUser(true);
        }
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID,setIsExternalUser]);


  const onSubmit = (data) => {
    const params = {
      'start': '0',
      'length': config.MAX_RECORDS,
      'assign_to': selectedUsers,
      'start_date': data.start_date,
      'end_date': data.end_date,
      'keyword': data.keyword,
      'lead_source': selectedLeadSource,
      'lead_status': getSelectedLeadStatus,
      'lead_profile': getSelectedLeadProfile,
      "user_role_type": userRoleType,
    };

    console.log("params = ", params);
    const requestData = encryptData(params);
    const response = actions.leadsData(requestData, true, true);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        setShowLeadDetails(true);
        const results = result.data.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        setAllLeads(resultArr);
      } else {
        setAllLeads([]);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });

  };


  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    setShowLeadDetails(false);
    console.log("Render LeadsPage component ", showLeadDetails);
    if (!showLeadDetails) {
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, "user_role_type": userRoleType };
      const requestData = encryptData(obj);
      const response = actions.leadsData(requestData);
      response.then((result) => {
        console.log("Promise resolved -  lead list:", result);
        if (result.data.success === 1) {
          setShowLeadDetails(true);
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          setAllLeads(resultArr);
        }
      }).catch((error) => {
        console.error("Promise rejected -  lead list:", error);
      });
    }


    if (Array.isArray(usersData.data)) {
      const extractedData = usersData.data.map(item => {
        return { id: item.user_id, name: item.name };
      });
      const resultArr = Object.values(extractedData);
      setAllUsers(resultArr);
    }

    const objSource = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestSourceData = encryptData(objSource);
    const responseSource = actions.sourceData(requestSourceData,false);
    responseSource.then((result) => {
        // console.log("Promise resolved -  source data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data
            const filteredResultArr = results.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.source_of_lead.localeCompare(b.source_of_lead)
            );
            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.source_of_lead };
            });
            const resultArr = Object.values(extractedData);
            setAllLeadSource(resultArr);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });

    const objMasterProfile = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestDataMasterProfile = encryptData(objMasterProfile);
    const responseMasterProfile = actions.masterProfileData(requestDataMasterProfile, false);
    responseMasterProfile.then((result) => {
        // console.log("Promise resolved -  master profile data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.lead_profile_name.localeCompare(b.lead_profile_name)
            );

            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.lead_profile_name };
            });
            const extractedObject = Object.values(extractedData);
            setAllMasterProfiles(extractedObject);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });

    const objLeadStatus = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestLeadStatusData = encryptData(objLeadStatus); 
    const leadStatusResponse = actions.leadStatusData(requestLeadStatusData);
    leadStatusResponse.then((result) => {
        // console.log("Promise resolved -  lead status data :", result); 
        if (result.data.success === 1) {
            setAllLeadStatusData(true);
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.lead_status.localeCompare(b.lead_status)
            );

            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.lead_status };
            });
            const extractedObject = Object.values(extractedData);
            setAllLeadStatusData(extractedObject);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  lead status data :", error); 
    });

  }, [leadsData, setAllUsers, setAllLeadSource, setAllMasterProfiles, setAllLeadStatusData]);

  useEffect(() => {
    // setAllLeadStatusData(false);
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getLoggedinUserChild(requestData, false);

    response.then((result) => {
        // console.log("Promise resolved -  lead Status data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data;
            
            const extractedData = results.map(item => {
              return { id: item.user_id, name: item.name };
            });
            const resultArr = Object.values(extractedData);
            setAllLoggedinUserChild(resultArr);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });
}, [setAllLoggedinUserChild]);

  const handleClose = () => {
    setOpen(false);
  };

  const onRowClickAction = (itemObj, btnAction) => {
    const encodedId = btoa(String(itemObj.id));
    if (btnAction === 'edit') {
      const url = `/dashboard/edit-lead/${encodedId}`;
    } else {
      setViewModalInfo(itemObj);
      setOpen(true);
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const filteredItems = allLeads.filter(item => {
    const name = item.name?.toLowerCase() || '';
    const formattedID = item.formatetdID?.toLowerCase() || '';
    const createdByName = item.createdByName?.toLowerCase() || '';
    const statusName = item.statusName?.toLowerCase() || '';
    const mobile = item.mobile?.toLowerCase() || '';
  
    return (
      name.includes(searchQuery.toLowerCase()) ||
      formattedID.includes(searchQuery.toLowerCase()) ||
      createdByName.includes(searchQuery.toLowerCase()) ||
      statusName.includes(searchQuery.toLowerCase()) ||
      mobile.includes(searchQuery.toLowerCase())
    );
  });

  const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
  const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

  const handleHabileAddClient = (item) => {
     // alert(`In progress , need to implement ${leadId}`);
     const leadId = item.id;
     const pan = item.pancard_number;

     const prefillTemplateNameId = item.prefill_template_name;
     console.log("item ",item);
     if (Object.prototype.hasOwnProperty.call(item, 'pan_details_decrypt')) { 
        setLegalFormId('person');
        const isSoleProp = item.pan_details_decrypt.isSoleProp;
        const isSalaried = item.pan_details_decrypt.isSalaried;
        const isDirector = item.pan_details_decrypt.isDirector;

        if (isDirector === true) {
           setLegalFormId('business');
        } 
        
     }else{
        toast.error("Lead pan details are missing.", {
            position: "top-right",
        });
        return;
     }
     
     if(!pan || pan.trim() ==='' || pan === null){
        toast.error("Pan Number is required.", {
            position: "top-right",
        });
      } else if(!prefillTemplateNameId || prefillTemplateNameId.trim() ==='' || prefillTemplateNameId === null) { 
        toast.error("Prefill Template is required.", {
            position: "top-right",
        });
      } else {
        const params = { 'lead_id': leadId, 'legalFormId':legalFormId, "prefillTemplateNameId":prefillTemplateNameId};
        const requestData = encryptData(params);
        const response = actions.addClientHabileInfo(requestData);
        response.then((result) => {
          // console.log("Promise resolved - add/edit lead form:", result);
          console.log("habile message = ", result.data.message);
          if (result.data.success === 1) {
            console.log("habile response = ", result.data);

            if (Object.prototype.hasOwnProperty.call(result.data, 'habil_final_message') && result.data.habil_final_message !== '' && result.data.habil_final_message !== null) {
              toast.success(result.data.habil_final_message, {
                position: "top-right",
              });
            } else {
              toast.success(result.data.message, {
                position: "top-right",
              });
            }

          } else if (result.data.success === 0) {
            if (Object.prototype.hasOwnProperty.call(result.data, 'habil_final_message') && result.data.habil_final_message !== '' && result.data.habil_final_message !== null) {
              toast.error(result.data.habil_final_message, {
                position: "top-right",
              });
            } else {
              toast.error(result.data.message, {
                position: "top-right",
              });
            }
          } else {
            toast.error(result.data.habil_final_message, {
              position: "top-right",
            });
          }
        }).catch((error) => {
          // console.error("Promise rejected - add/edit lead form:", error);
          toast.error(error, {
            position: "top-right",
          });
        });
      }
      
      
  }

  const handleHabileAddIdentifier = (leadId,pan) => {
    // alert(`In progress , need to implement ${leadId}`);
    if(pan ===''){ 
     toast.error("Lead pan number is null.", {
         position: "top-right",
     });
     return;
   }
     const params = { 'lead_id': leadId};
      const requestData = encryptData(params);
      const response = actions.addIdentifierHabileInfo(requestData);
      response.then((result) => {
          // console.log("Promise resolved - add/edit lead form:", result);
           if (result.data.success === 1) { 
              console.log("habile response = ", result.data.data); 
                   toast.success(result.data.message, {
                       position: "top-right",
                   }); 
          } else {
              toast.error(result.data.message, {
                  position: "top-right",
              });
          }
      }).catch((error) => {
          // console.error("Promise rejected - add/edit lead form:", error);
          toast.error(error, {
              position: "top-right",
          });
      });
 }

  const checkLogout = () => {
    const authToken = localStorage.getItem('authToken');
    const logInTimestamp = localStorage.getItem('logInTimestamp');

    if (logInTimestamp !== null) {
      const loginTimestamp = parseInt(logInTimestamp, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - loginTimestamp;
      const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

      if (timeElapsed >= autoLogoutTimeInSecod) {
        const params = { 'login_token': authToken };
        console.log("params : ", params);
        const requestData = encryptData(params);
        const response = actions.userLogout(requestData);
        response.then((result) => {
          if (result.data.success === 1) {
            const data = {};
            actions.loadUserAuth(false);
            actions.loginDataSuccess(data);
            localStorage.setItem("authToken", '');
            localStorage.setItem("logInTimestamp", '');
            navigate("/login");
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }

  useEffect(() => {
    checkLogout();
  });

  const clearfix = {
    clear: 'both'
  };
  const textAlignRight = {
    textAlign: 'right'
  };

  const [enachItem, setEnachItem] = useState([]);
  const [proposedAmt, setProposedAmt] = useState('');
  const [proposedDebitDate, setProposedDebitDate] = useState('');
  const [openAmt, setOpenAmt] = useState(false);
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const btnOpenUploadMissingData = (item) => {
     setOpenAmt(true);
    setEnachItem(item);
  }

  const handleCloseAmt = () => {
    setOpenAmt(false); 
    // setProposedAmt('');
   // setProposedDebitDate('');
    setEnachItem([]); 
  };


  const onSendEnachRegistrationAction = (item) => {  
    setPreloaderStatus(true); 
    
    // alert(item.emi_amount);
    const params = { 'lead_id': item.id, 'application_id': item.id,'brief' :'send_sms_to_user' ,'action' :'send_enach_link_to_user' ,'call_api_from' :'Lead','proposed_amount' : item.emi_amount,'proposed_debit_date' : item.debit_date};
    const requestData = encryptData(params);
    const response = actions.sendEnachRegistrationDetails(requestData);

    response.then((result) => {
        // console.log("Promise resolved - add/edit lead form:", result);
         
        if (result.data.success === 1) { 
            console.log("response = ", result.data.data); 
            setPreloaderStatus(false);
                 toast.success(result.data.message, {
                     position: "top-right",
                 });

                // fetchHabileLoanApplicationsData(); 
                const obj = { 'start': '0', 'length': config.MAX_RECORDS, "user_role_type": userRoleType };
                const requestData = encryptData(obj);
                const response = actions.leadsData(requestData);
                response.then((result) => {
                  console.log("Promise resolved -  lead list:", result);
                  if (result.data.success === 1) {
                    setShowLeadDetails(true);
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllLeads(resultArr);
                  }
                }).catch((error) => {
                  console.error("Promise rejected -  lead list:", error);
                });   
            
        } else {
            setPreloaderStatus(false);
            toast.error(result.data.message, {
                position: "top-right",
            });
        }

        setOpenAmt(false); 
        setProposedAmt('');
        setProposedDebitDate('');
        setEnachItem([]); 
    }).catch((error) => {
        setPreloaderStatus(false);
        // console.error("Promise rejected - add/edit lead form:", error);
        toast.error(error, {
            position: "top-right",
        });
    });

 };

 const handleFormSubmit = (data) => { 
    console.log("data , ",data);

    if(data.emi_amount ===''){
      toast.error("Amount is required.", {
        position: "top-right",
      }); 
   }else if(data.debit_date ===''){
      toast.error("Debit date is required.", {
        position: "top-right",
      });  
    }else{

      // setProposedAmt(data.emi_amount);
     // setProposedDebitDate(data.debit_date);
      enachItem.emi_amount =  data.emi_amount;
      enachItem.debit_date =  data.debit_date;
      onSendEnachRegistrationAction(enachItem);
    }
   
 }

 const handleAmtChange = (e) => {
  const amt = e.target.value;
   setProposedAmt(amt);
};

const handleDebitDateChange = (e) => {
  const date = e.target.value;
   setProposedDebitDate(date);
};

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title> Leads | Ckers </title>
      </Helmet>

      <Container>
        <div className="d-flex align-items-center justify-content-between mb-0">
          <span>&nbsp;</span>
          <RouterLink to='/dashboard/create-lead' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add Lead</RouterLink>

          <div className='form-group mb-2'> 
              <NavLink to={`/dashboard/enach-mandates`}>
              <button
                  type="button"
                  className="btn btn-primary text-white"
              >
                  E-NACH Mandates
              </button>
              </NavLink>
              <span>&nbsp;</span>
          </div>

        </div>
        <div id='main-wrap' className='mb-3 mmt-7 filter'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row mx-0'>
              <div className={isExternalUser ? "col d-none" : " col"}>
                <div className='form-group'>
                  <span>Assign To</span>
                  <div className="text-dark">
                    <Multiselect
                      id="assign_to"
                      options={allLoggedinUserChild.map((option) => ({
                        key: option.id,
                        value: option.name,
                      }))}
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedUsers(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedUsers(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor="start-date">
                    <span>Start Date</span>
                    <input type='date' name='start_date' className='form-control'{...register('start_date')} />
                  </label>

                </div>
              </div>

              <div className='col'>
                <div className='form-group'>
                  <label htmlFor="end-date">
                    <span>End Date</span>
                    <input type='date' name='end_date' className='form-control'{...register('end_date')} />
                  </label>

                </div>
              </div>
              <div className={isExternalUser ? "col d-none" : " col"}>
                <div className='form-group'>
                  <span>Lead Source</span>
                  <div className="text-dark">
                    <Multiselect
                      id="lead_profile"
                      options={allLeadSource.map((option) => ({
                        key: option.id,
                        value: option.name,
                      }))}
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadSource(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadSource(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='form-group'>
                  <span>Lead Status</span>
                  <div className="text-dark">
                    <Multiselect
                      id="lead_profile"
                      options={allLeadStatusData.map((option) => ({
                        key: option.id,
                        value: option.name,
                      }))}
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadStatus(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadStatus(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={isExternalUser ? "col d-none" : " col"}>
                <div className='form-group'>
                  <span>Lead Profile</span>
                  <div className="text-dark">
                    <Multiselect
                      id="lead_profile"
                      options={allMasterProfiles.map((option) => ({
                        key: option.id,
                        value: option.name,
                      }))}
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadProfile(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedLeadProfile(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className='form-group'>
                  <label htmlFor="keyword">
                    <span>Keyword</span>
                    <input type='text' name='keyword' id='keyword' className='form-control'{...register('keyword')} placeholder="Lead name, email or mobile" />
                  </label>
                </div>
              </div>
              <div className='col'>
                <div className='form-group mb-2'>
                  <span className='d-block'>&nbsp;</span>
                  <button className='btn btn-primary'>Search</button>
                </div>
              </div>

            </div>
          </form>
        </div>

        <div id='main-wrap' className='mb-4'>
          <div className='row justify-content-between'>
            <div className='col-md-8'> 
              <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                All Leads
              </Typography>
            </div>
            <div className='col-md-4 pe-0 ps-lg-5'>
              <div className="form-group">
                 
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Status, Name, Created by.. "
                    id="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                 
              </div>
            </div>
          </div>
          <div className='table-wrap'>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className='text-nowrap'>S. No.</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  {!isExternalUser && ( <> <th>Created by</th> </>)}
                  <th>Created Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedItems.map((item, index) => {

                let ENachSentStatus =  false;
                let ENachUserStatus =  false;
                let ENachUserHtml = '';  
                if ( item.EnachDetails &&   Object.prototype.hasOwnProperty.call(item.EnachDetails, 'id')
                ) {
                    ENachSentStatus =  true;

                    if ( Object.prototype.hasOwnProperty.call(item.EnachDetails, 'userEnachResponse') && Object.prototype.hasOwnProperty.call(item.EnachDetails.userEnachResponse, 'response_status')
                    ) {
                        

                        if (item.EnachDetails.userEnachResponse.response_status === 'Success') {
                          ENachUserHtml = `<div>E-NACH process successfully done.</div>`;
                          ENachUserStatus = true;
                        }else{ 
                          ENachUserHtml = `<div>E-NACH process ${item.EnachDetails.userEnachResponse.response_status}.</div>`;

                        }
                    }
                    
                }

                const safeCurrentPage = Number.isInteger(currentPage) ? currentPage : 1;
                const safeItemsPerPage = Number.isInteger(config.HUNDREAD_PER_PAGE) ? config.HUNDREAD_PER_PAGE : paginatedItems.length;
                const rowIndex = (safeCurrentPage - 1) * safeItemsPerPage + index + 1;
              return (   
                <> 
                  <tr key={index}>
                    <td>{rowIndex}</td>
                    <td>{item.name}</td>
                    <td>{item.mobile}</td>
                    {!isExternalUser && ( <> <td>{item.createdByName}</td> </>)}
                    <td>{item.created_date}</td>
                    <td><span
                      className={(item.status === '1') ? "badge text-bg-success text-white" : (item.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}
                    >{item.statusName === "CALL" ? 'No Interaction' : item.statusName}</span></td>
                    <td>
                        <div className="btn-group dropstart" title="Edit">
                            <NavLink to={`/dashboard/edit-lead/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink>
 
                        </div> 
					{!isExternalUser && ( <>                        

                       {(item.statusName === 'Ok to Proceed' || item.is_ok_to_proceed === "1") && ( 
                        <>  

                          <button 
                            type="button"
                            className={(item.habile_lms === 1) ? "d-none badge text-bg-success text-white float-right" : "badge text-bg-success text-white float-right"}
                            onClick={() => handleHabileAddClient(item)}
                            > 
                           Habile - LMS
                        </button>

                        <button 
                            type="button"
                            className={(ENachUserStatus) ? "d-none badge btn btn-primary text-white float-right" : "badge btn btn-primary text-white float-right"}
                             onClick={(e) => {
                              e.preventDefault();
                             /* onSendEnachRegistrationAction(item) */
                              btnOpenUploadMissingData(item)}
                              }
                            > 
                            {preloaderStatus && (<>
                              <div className="spinner-border small-preloader d-none" role="status" >
                                  <span className="visually-hidden">Loading...</span>
                              </div></>)}
                           E-NACH Registration
                        </button>
                        </>
                       )}

                       

                        <div className={(item.habile_lms === 0) ? "d-none float-right" : "float-right"}>
                          Sent to Habile <span className={(item.sentToHabileLmsDate === '') ? "d-none" : ""}>on {item.sentToHabileLmsDate}</span>
                          <span className={(item.sentToHabileLmsByUser === '') ? "d-none" : ""}>&nbsp;By {item.sentToHabileLmsByUser}</span>
                        </div>

                        {(ENachSentStatus) && (
                          <>
                          
                          <div style={clearfix} className="text-success align-right">E-NACH link sent to #{item.mobile}</div>
                          <div style={clearfix} className={(ENachUserStatus) ? "text-success align-right" : "text-danger align-right"} dangerouslySetInnerHTML={{ __html: ENachUserHtml.replace(/\n/g, '<br />') }} />

                          </> )}
                      </>)}

                      {/*  <NavLink className={(item.habile_lms === 0) ? "d-none float-right" : "float-right"} to={`/dashboard/send-to-allcloud/${btoa(String(item.id))}`}>                          
                        <button 
                            type="button"
                            className= "badge text-bg-warning text-white float-right"
                            > 
                           Send To AllCloud
                        </button></NavLink> */}
                    </td>                    
                  </tr>

                  </>);
                
              })}

                { paginatedItems.length < 1 && (
                    <>
                      <tr>
                        <td colSpan={5} className='text-center py-3 fw-bold'>No record found</td>
                      </tr>
                    </>
                )}
              </tbody>
            </table>
          </div>
          <div>
          <nav className='mb-1'>
            <ul className="pagination pagination-md justify-content-end">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <li className="page-item">
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    disabled={currentPage === page}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          </div>
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className='modal-custom rounded'>
          <div className='modal-head'>
            <h4 id="modal-title">Lead  : {viewModalInfo.name}</h4>
            <Button onClick={handleClose} color="primary" className=' btn'>
              <i className="bi bi-x">&nbsp;</i>
            </Button>
          </div>
          <div className='modal-body'>
            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Lead ID :</b></div>
              <div className='col-7 ps-0'>{viewModalInfo.id}</div>
            </div>

            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Created By :</b></div>
              <div className='col-7 ps-0'>{viewModalInfo.lead_created_by}</div>
            </div>

            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Lead Status :</b></div>
              <div className='col-7 ps-0'><span className={(viewModalInfo.status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal
                open={openAmt}
                onClose={handleCloseAmt}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">What is the proposed EMI amount?</h4>
                        <Button onClick={handleCloseAmt} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} encType="multipart/form-data">
                                <div className="row mx-0">
                                    <div className="col-md-6 px-0">
                                        <div className="form-group">
                                            <label htmlFor="itr_file">
                                                <span>EMI Amount <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='number'
                                                    id='emi_amount'
                                                    name='emi_amount'
                                                    className='form-control'
                                                    {...register('emi_amount')}
                                                    onChange={handleAmtChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="itr_file">
                                                <span>Debit Date <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='date'
                                                    id='debit_date'
                                                    name='debit_date'
                                                    className='form-control'
                                                    {...register('debit_date')}
                                                    onChange={handleDebitDateChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

    </>
  );
}

function mapStateToProps(state) {
  return {
    leadsData: state.leadsData,
    usersData: state.usersData,
    sourceData: state.sourceData,
    getUserModuleData: state.getUserModuleData,
    masterProfileData: state.masterProfileData,
    leadStatusData: state.leadStatusData,
    getLoggedinUserChild: state.getLoggedinUserChild,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default), dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsPage);