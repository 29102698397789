import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import $ from 'jquery';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import { format } from 'date-fns';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";

import * as incidentReporteActions from "../../actions/incidentReporteActions";

import * as groupsUsersActions from "../../actions/groupsUsersActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AddEditLeadPage = (props) => {
    // const { id, actionName,sectionId,sectionName } = useParams();
    const { actionName } = useParams();
    const location = useLocation();

    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('i');
    const sectionId = searchParams.get('s');
    let sectionName = searchParams.get('n');

    if(sectionName === 'l'){
        sectionName = 'lead';
    }else if(sectionName === 'c'){
        sectionName = 'coApplicant';
    }else if(sectionName === 'g'){
        sectionName = 'guarantor';    
    }

    // alert(sectionName);
 
    const { actions, leadsData, leadInfo, usersData, basicLeadInfo, sourceData, masterProfileData, leadChecklistData, interactionTypeData, leadStatusData, getUserModuleData, incidentReporteData, leadGstByLeadData, leadItrByLeadData, leadBankStatementByLeadData } = props;
    const [allLeads, setAllLeads] = useState([]);
    const [open, setOpen] = useState(false);
    const [openQue, setOpenQue] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showLeadDetails, setShowLeadDetails] = useState(false);
    const [leadId, setLeadId] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [showLeadCommunicationDetails, setShowLeadCommunicationDetails] = useState(false);
    const [allSourceData, setAllSource] = useState([]);
    const [allMasterProfiles, setAllMasterProfiles] = useState([]);
    const [allInteractionTypeData, setAllInteractionTypeData] = useState([]);
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allInteractions, setAllInteractions] = useState([]);
    const [allLeadCheckLists, setAllLeadCheckLists] = useState([]);
    const [pageHeading, setPageHeading] = useState('');
    const [questions, setQuestions] = useState([]);


    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [panDetailsInfoName, setPanDetailsInfoName] = useState('');
    const [phoneDetailsInfoName, setPhoneDetailsInfoName] = useState('');
    const [panDetailsInfoDoB, setPanDetailsInfoDoB] = useState('');
    const [panDetailsInfoGender, setPanDetailsInfoGender] = useState('');
    const [panDetailsInfoAddress, setPanDetailsInfoAddress] = useState('');
    const [leadPanDetails, setLeadPanDetails] = useState('');
    const [showTicketListDetails, setShowTicketListDetails] = useState(false);
    const [allTicketData, setAllTicketData] = useState([]);

    const [showListDetails, setShowListDetails] = useState(false);
    const [allGroupData, setAllGroupData] = useState([]);
    const [getAllUsersListData, setAllUsersListData] = useState([]);

    const [allLeadGstByLeadData, setAllLeadGstByLeadData] = useState([]);
    const [allLeadItrByLeadData, setAllLeadItrByLeadData] = useState([]);
    const [allLeadBankStatementByLeadData, setAllLeadBankStatementByLeadData] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');
    const [isCrifUser, setIsCrifUser] = useState(true);

    const [allStates, setAllStates] = useState([]); 
    const [allAddressTypes, setAllAddressTypes] = useState([]); 
    const [showNote, setShowNote] = useState('');
    const [showNoteNoteByCredit, setShowNoteNoteByCredit] = useState('');
    const [showHistory, setShowHistory] = useState(false);
    const [allPrefillTemplateNameData, setAllPrefillTemplateNameData] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [showActionLeadInfo, setShowActionLeadInfo] = useState(false);

    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [assignedUser, setAssignedUser] = useState("");

    const [isSentToCredit, setIsSentToCredit] = useState(true);

    const [allLeadActivity, setAllLeadActivity] = useState([]);
    const [preloaderStatus, setPreloaderStatus] = useState(false);
    const [preloaderStatusPhone, setPreloaderStatusPhone] = useState(false);
    const [actionType, setActionType] = useState('');
    const [activeTabName, setActiveTabName] = useState('basic');

    const [allLeadCheckListScore, setAllLeadCheckListScore] = useState([]);
    const [allLeadCheckListDynamicScore, setAllLeadCheckListDynamicScore] = useState([]);

    const [creditAllLeadCheckLists, setCreditAllLeadCheckLists] = useState([]);
    const [scoreCard, setScoreCard] = useState("");
    const [allShipment, setAllShipment] = useState([]);
    const [leadStatusFlag, setLeadStatusFlag] = useState(false);
    const [outputBefiscHtml, setOutputBefiscHtml] = useState('');
    const [outputOTPHtml, setOutputOTPHtml] = useState('');
    const [finalOTPVerifyStatus, setFinalOTPVerifyStatus] = useState(false);

    const [isConsentDetails, setIsConsentDetails] = useState(false);
    const [crifSectionId, setCrifSectionId] = useState('');
    const [crifActionName, setCrifActionName] = useState('');
 

    const fetchLeads = useCallback(() => {
        setShowLeadDetails(false);
    
        setShowNoteNoteByCredit("");
        setShowNote("");
        
        // console.log("fetchLeads calling ", id, showLeadDetails);
        if (id !== undefined && !showLeadDetails) {
            const decodedId = atob(id);
            setLeadId(decodedId);
            const decodedSectionId = atob(sectionId);
 
            setCrifSectionId(decodedSectionId);
            setCrifActionName(sectionName); 

           // alert(sectionName);

           setPreloaderStatusPhone(true); 
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': decodedId ,'brief' :'otp_verification' , 'section_id' : decodedSectionId,'section_name' :sectionName};
            const requestData = encryptData(obj);
            const response = actions.leadInfo(requestData, false);
            response.then((result) => {
                setPreloaderStatusPhone(false); 
                // console.log("Promise resolved -  leadInfo:", result); 
                if (result.data.success === 1) {
    
                    const leadInfoData = result.data.data;

                    if ('data' in leadInfoData && leadInfoData.data.length > 0) {
                        
                        let VerifyHtml = '';
                        let VerifyStatus = false;

                        if(sectionName ==='coApplicant'){
                                // we implement
                                VerifyHtml = ``;
                                if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'lead_co_applicants') &&
                              leadInfoData.data[0].lead_co_applicants !== '' &&
                              leadInfoData.data[0].lead_co_applicants !== null &&
                              '0' in leadInfoData.data[0].lead_co_applicants &&
                              Object.prototype.hasOwnProperty.call(leadInfoData.data[0].lead_co_applicants[0], 'crif_info') &&
                              leadInfoData.data[0].lead_co_applicants[0].crif_info.crif_status !== '' &&
                              leadInfoData.data[0].lead_co_applicants[0].crif_info.crif_status !== null) {
                          

                                if(leadInfoData.data[0].lead_co_applicants[0].crif_info.crif_status ==='otp_expired'){
                                    VerifyHtml = `<span className="text-red">OTP has been expired for  ${leadInfoData.data[0].lead_co_applicants[0].mobile}</span>`;
                                } else if(leadInfoData.data[0].lead_co_applicants[0].crif_info.crif_status==='not_verified_yet'){
                                    VerifyHtml = `<span className="text-red">OTP has been sent to  ${leadInfoData.data[0].lead_co_applicants[0].mobile}, Please verify.</span>`;

                                    VerifyStatus = true;
                               
                                } else if(leadInfoData.data[0].lead_co_applicants[0].crif_info.crif_status){
                                    VerifyHtml = `<span className="text-red">OTP has been verified for  ${leadInfoData.data[0].lead_co_applicants[0].mobile}</span>`;
                                }else{
                                     VerifyStatus = false;
                                }

                            }
                                
                        }else if(sectionName ==='guarantor'){
                             // we implement
                              VerifyHtml = ``;

                              if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'lead_guarantors') &&
                              leadInfoData.data[0].lead_guarantors !== '' &&
                              leadInfoData.data[0].lead_guarantors !== null &&
                              '0' in leadInfoData.data[0].lead_guarantors &&
                              Object.prototype.hasOwnProperty.call(leadInfoData.data[0].lead_guarantors[0], 'crif_info') &&
                              leadInfoData.data[0].lead_guarantors[0].crif_info.crif_status !== '' &&
                              leadInfoData.data[0].lead_guarantors[0].crif_info.crif_status !== null) {
                          

                                if(leadInfoData.data[0].lead_guarantors[0].crif_info.crif_status ==='otp_expired'){
                                    VerifyHtml = `<span className="text-red">OTP has been expired for  ${leadInfoData.data[0].lead_guarantors[0].mobile}</span>`;
                                } else if(leadInfoData.data[0].lead_guarantors[0].crif_info.crif_status==='not_verified_yet'){
                                    VerifyHtml = `<span className="text-red">OTP has been sent to  ${leadInfoData.data[0].lead_guarantors[0].mobile}, Please verify.</span>`;

                                    VerifyStatus = true;
                               
                                } else if(leadInfoData.data[0].lead_guarantors[0].crif_info.crif_status){
                                    VerifyHtml = `<span className="text-red">OTP has been verified for  ${leadInfoData.data[0].lead_guarantors[0].mobile}</span>`;
                                }else{
                                     VerifyStatus = false;
                                }

                            }

                        }else{
                             VerifyHtml = '';

                            if (Object.prototype.hasOwnProperty.call(leadInfoData.data[0], 'crif_info') && leadInfoData.data[0].crif_info !== '' && leadInfoData.data[0].crif_info !== null && Object.prototype.hasOwnProperty.call(leadInfoData.data[0].crif_info, 'crif_status') && leadInfoData.data[0].crif_info.crif_status !== '' && leadInfoData.data[0].crif_info.crif_status !== null) {

                                if(leadInfoData.data[0].crif_info.crif_status ==='otp_expired'){
                                    VerifyHtml = `<span className="text-red">OTP has been expired for  ${leadInfoData.data[0].mobile}</span>`;
                                } else if(leadInfoData.data[0].crif_info.crif_status==='not_verified_yet'){
                                    VerifyHtml = `<span className="text-red">OTP has been sent to  ${leadInfoData.data[0].mobile}, Please verify.</span>`;

                                    VerifyStatus = true;
                               
                                } else if(leadInfoData.data[0].crif_info.crif_status){
                                    VerifyHtml = `<span className="text-red">OTP has been verified for  ${leadInfoData.data[0].mobile}</span>`;
                                }else{
                                     VerifyStatus = false;
                                }

                            }

                            
                             
                        }

                        setOutputOTPHtml(VerifyHtml);
                        setFinalOTPVerifyStatus(VerifyStatus);
                        
                        
                    }
                }
            }).catch((error) => {
                // console.error("Promise rejected -  leadInfo :", error);
            });
        }
    
        if (id === undefined) {           
            navigate('/404');
        }
    
    }, [id, setValue, setShowLeadDetails, setAllLeadCheckLists, setPageHeading, setSelectedCampaign, setSelectedAssignedGroup, setAssignedUser, setAllLeadActivity, setAllLeadCheckListScore, setAllLeadCheckListDynamicScore, setActiveTabName, setCreditAllLeadCheckLists, setAllShipment, setLeadStatusFlag,setOutputBefiscHtml]);

   
    
    useEffect(() => {
         fetchLeads();
     }, [ fetchLeads, questions]);
 
  
      const handleCrifOTPAPI = (actionName, sectionId,formOTPData) => { 

       // alert(actionName  );
       // alert(sectionId  );

        if(formOTPData.otp.trim() === ''){
            toast.error("OTP is blank.", {
                position: "top-right",
            });          
        }else{
            // alert(`${actionName} - ${sectionId}`);
                const params = { 'action': actionName, id:sectionId,lead_id : leadId,'formOTPData' : formOTPData,'brief' :'otp_verification',"verifed_side" : 'ViaLink'};
                const requestData = encryptData(params);
                const response = actions.callCrifAPI(requestData);

            response.then((result) => {
                    console.log("Promise resolved - CRIF form:", result); 
                if (result.data.success === 1) { 
                    console.log("crif response = ", result.data.data);  
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                        fetchLeads();
                 } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - CRIF form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
            }
      }; 
      
      const handleCrifResedOTPAPI = (actionName, sectionId,formOTPData) => { 
        if(actionName.trim() === ''){
            toast.error("Please try again.", {
                position: "top-right",
            });          
        }else{
            // alert(`${actionName} - ${sectionId}`);
                const params = { 'action': actionName, id:sectionId,lead_id : leadId,'formOTPData' : formOTPData,'brief' :'resend_otp',"verifed_side" : 'ViaLms'};
                const requestData = encryptData(params);
                const response = actions.callCrifAPI(requestData);

            response.then((result) => {
                    console.log("Promise resolved - CRIF form:", result); 
                if (result.data.success === 1) { 
                    console.log("crif response = ", result.data.data);  
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                 } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - CRIF form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
            }
      };


    const [formOTPData, setFormOTPData] = useState({ otp: ''}); 

    
       // Form OTP submission handler
    const handleSubmitOTPForm = (event) => {
        event.preventDefault();
        // Handle form submission logic for Form 1
        console.log('Form OTP submitted:', formOTPData);

        if(formOTPData.otp.trim() === ''){
            toast.error("OTP is blank.", {
                position: "top-right",
            }); 
          
        }else{
            setFormOTPData({ otp: ''});
            handleCrifOTPAPI(crifActionName, crifSectionId,formOTPData);
           // setCrifOpenOTPModal(false);
        }
    }

    // Form OTP change handler
    const handleChangeBureauOTPForm = (event) => {
        const { name, value } = event.target;
        setFormOTPData((prevData) => ({ ...prevData, [name]: value }));
    };
 
  
    // console.log("leadStatusFlag : ", leadStatusFlag);
    
    return (
        <>
            <Helmet>
                <title> OTP Verify </title>
            </Helmet>
            <Container>          
                                 
                <div id='main-wrap' className='mb-5 mt-5'>
                    

                
                <form onSubmit={handleSubmitOTPForm} className="" encType="multipart/form-data">
                        <div className='modal-body'>
                       

                            <div className='scroll-content pe-3'>
                                <div className="row">
                                <div className='col-md-12 text-center'><img src="/assets/ckern-logo.png" width={150} alt='' className='text-center' /></div> 

                                
                                    <div className="col-md-6  mt-4"> 
                                    {preloaderStatusPhone && (<>
                                    <div className="spinner-border small-preloader" role="status" >
                                        <span className="visually-hidden">Loading...</span>
                                    </div></>)}
                                        {finalOTPVerifyStatus && (<>  
                                        <div className="form-group">
                                        <label htmlFor="otp">
                                            <span>Verify OTP <a style={{ color: "red" }}>*</a></span>
                                            <input type="number" 
                                            id="otp" 
                                            name="otp" 
                                            className="form-control" 
                                            value={formOTPData.otp}
                                            onChange={handleChangeBureauOTPForm} 
                                            />
                                        </label>
                                        </div> 
                                        </>)} 
                                    </div>
                                   
                                </div>    
                            </div>
                            <div className="row">
                               
                            {finalOTPVerifyStatus && (<>
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <button className="btn btn-primary mt-2 mb-2">Submit</button>
                                    </div>
                                </div>
                                </>)}

                                <div className="col-md-12">
                                     <div dangerouslySetInnerHTML={{ __html: outputOTPHtml.replace(/\n/g, '<br />') }} />

                                     {finalOTPVerifyStatus ? (<></>) : (<></>)}
                                </div>

                            </div>
                            
                        </div>
                    </form>
                       
  
                    
                </div>
            </Container>

        </>
    );
}
function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        usersData: state.usersData,
        leadInfo: state.leadInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default, groupsUsersActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLeadPage);