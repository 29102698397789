import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";

import MapContainer from './BeatPlanMultipleGpsPage';



// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const UserLocationsPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [showLeadDetails, setShowLeadDetails] = useState(false);
    const [usedColors, setUsedColors] = useState(new Set());
    const [predefinedColors, setPredefinedColors] = useState([]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);
    
    const generateRandomColor = (usedColors) => {
        let color;
        let brightness;
        do {
            color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
            // Convert color to RGB
            const r = parseInt(color.substr(1, 2), 16);
            const g = parseInt(color.substr(3, 2), 16);
            const b = parseInt(color.substr(5, 2), 16);
            // Calculate brightness (YIQ formula)
            brightness = (r * 299 + g * 587 + b * 114) / 1000;
            // Ensure the brightness is not too close to white or black
        } while (usedColors.has(color) || brightness > 200 || brightness < 55);
        return color;
    }    

    useEffect(() => {
        // Generate predefined colors using generateRandomColor
        const colors = Array.from({ length: allBeatPlans.length }, () => generateRandomColor(usedColors));
        setPredefinedColors(colors);
    }, [allBeatPlans.length]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    const onSubmit = (data) => {

        setPreloaderStatus(true);

        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'assign_to': selectedUsers,
            'start_date': data.start_date,
            'end_date': data.end_date,
        };

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        const requestData = encryptData(params);
        const response = actions.getUserLocationsData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved - User Locations:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                setShowLeadDetails(false);
                const results = result.data.data.beat_plan_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
            } else {
                setPreloaderStatus(false);
                setShowLeadDetails(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected - User Locations:", error);
        });
    };

    const fetchUserLocations = (selectedUserId) => {
        if (selectedUserId !== undefined && selectedUserId !== "") {
            const params = {
                'start': '0',
                'length': config.MAX_RECORDS,
                'start_date': startDate,
                'end_date': endDate,
                'selectedUserId': selectedUserId
            };

            setPreloaderStatus(true);

            const requestData = encryptData(params);
            const response = actions.getUserLocationsData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved - User Locations:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    setShowLeadDetails(false);
                    const results = result.data.data.beat_plan_data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllBeatPlans(resultArr);
                } else {
                    setPreloaderStatus(false);
                    setShowLeadDetails(false);
                    setAllBeatPlans([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected - User Locations:", error);
            });
        }
    };

    let markers = [];

    // Load userIdColorMap from local storage if available, or initialize as an empty object
    let userIdColorMap;
    const storedData = localStorage.getItem('userIdColorMap');
    if (storedData) {
        try {
            userIdColorMap = JSON.parse(storedData);
        } catch (error) {
            console.error('Error parsing stored data:', error);
            userIdColorMap = {}; // Initialize as empty object if parsing fails
        }
    } else {
        userIdColorMap = {};
    }

    // Function to get color for a userId
    function getColorForUserId(userId) {
        // If userId already has a color assigned, return it
        if (userIdColorMap[userId]) {
            return userIdColorMap[userId];
        }
        // If userId does not have a color assigned, assign one
        const colorIndex = Object.keys(userIdColorMap).length % predefinedColors.length;
        const color = predefinedColors[colorIndex];
        userIdColorMap[userId] = color;
        // Store updated userIdColorMap in local storage
        localStorage.setItem('userIdColorMap', JSON.stringify(userIdColorMap));
        return color;
    }

    if (Object.keys(userIdColorMap).length === 0) {
        localStorage.setItem("userIdColorMap", '');
    }

    // Assuming allBeatPlans is an array containing beat plans
    if (allBeatPlans && allBeatPlans.length > 0) {
        markers = allBeatPlans.map(beatPlan => ({
            lat: parseFloat(beatPlan.lat),
            lng: parseFloat(beatPlan.lng),
            address: beatPlan.gpsAddress,
            gpsLocation: beatPlan.gpsLocation,
            createdAt: beatPlan.created_date,
            createdByName: beatPlan.createdByName,
            userId: beatPlan.user_id,
            color: getColorForUserId(beatPlan.user_id) // Get color for userId using the getColorForUserId function
        }));
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = markers.filter(item => {
        const companyName = item.companyName?.toLowerCase() || '';
        const assignedUser = item.assignedUser?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        return (
            companyName.includes(searchQuery.toLowerCase()) ||
            assignedUser.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const svgIcon = `
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="%COLOR%"
        width="50px"
        height="50px"
        >
        <path d="M12 2C8.14 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.14-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        <path fill="none" d="M0 0h24v24H0z" />
        </svg>`;

    // Function to create SVG icon with dynamic color
    const createSvgIcon = (color) => {
        return svgIcon.replace('%COLOR%', color);
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>User Visit Location | Ckers</title>
            </Helmet>

            <Container>
                {/* <div className="d-flex align-items-center justify-content-between mb-0">
                    <span>&nbsp;</span>
                    <RouterLink to="/dashboard/create-beat-plan" className="add-btn btn-primary btn">
                        <i className="bi bi-plus">&nbsp;</i> Create Beat Plan
                    </RouterLink>
                </div> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <span>User</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="created_by"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date <a style={{ color: "red" }}>*</a></span>
                                        <input type='datetime-local' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date <a style={{ color: "red" }}>*</a></span>
                                        <input type='datetime-local' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                        <div className="col-md-12">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 2 }}>
                                User Visit Location
                            </Typography>
                        </div>
                    </div>

                    <div className={markers.length === 0 || markers === null ? "d-none col-md-12" : "col-md-12"}>
                        <div className="row justify-content-between">
                            <div className="col-md-12 mb-4">
                                <MapContainer
                                    markers={markers}
                                    fetchUserLocations={fetchUserLocations}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="table-wrap table-responsive">
                        <table className="table table-hover" id="exportBeatPlanExcelFile">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">GPS Location</th>
                                    <th className="text-nowrap">GPS Address</th>
                                    <th className="text-nowrap">Added On</th>
                                    <th className="text-nowrap">Added By</th>
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <p className='beat-plan-remark'>{item.gpsLocation}</p>
                                        </td>
                                        <td>
                                            <p className='beat-plan-remark'>{item.address}</p>
                                        </td>
                                        <td>{item.createdAt}</td>
                                        <td className='text-nowrap'>
                                            <div className='d-flex align-items-center'>
                                                {item.color && (
                                                    <img src={`data:image/svg+xml;base64,${btoa(createSvgIcon(item.color))}`} width={25} className='me-1' alt="" />
                                                )}
                                                {item.createdByName}
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={11} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBeatPlanData: state.getBeatPlanData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLocationsPage);