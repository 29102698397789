// PermissionContext.js
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import config from "../config";
import { encryptData } from '../utils/cryptoUtils';

const PermissionContext = createContext();

export function PermissionProvider({ children }) {
  const [permissions, setPermissions] = useState([]);

  /* const [permissions, setPermissions] = useState([
     { 'module_name': 'Leads', 'view_permission': true, 'add_permission': false,'edit_permission': false },
     { 'module_name': 'Users', 'view_permission': false, 'add_permission': false ,'edit_permission': false}
   ]); // Initialize with your permission data */

  useEffect(() => {
    // Fetch module permission data from your API and set it in state
    // Example fetch code:
    const fetchModulePermissions = async () => {
      try {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = await agent.post(API.GET_USER_MODULE_PERMISSION, requestData);
        if (response.status === 200) {
          setPermissions(response.data.data); // Update permissions based on the response
        }
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error('API Request Error:', error);
      }
    };
    fetchModulePermissions();
  }, [setPermissions]);

  return (
    <PermissionContext.Provider value={{ permissions, setPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
}

export function usePermission() {
  return useContext(PermissionContext);
}