import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function sourceLeadInfoReducer(state = initialState.sourceLeadInfo, action) {
  switch (action.type) {
    case types.LOAD_SOURCE_LEAD_INFO_SUCCESS:
      return action.sourceLeadInfo;
    default:
      return state;
  }
}
