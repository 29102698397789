import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

export function leadChecklistDataSuccess(leadChecklistData) {
    return { type: types.LOAD_LEAD_CHECKLIST_DATA_SUCCESS, leadChecklistData };
}

export function sourceOfLeadDataSuccess(sourceData) {
  return { type: types.GET_SOURCE_OF_LEAD_DATA_SUCCESS, sourceData };
}

export function masterProfileDataSuccess(masterProfileData) {
  return { type: types.GET_LEAD_MASTER_PROFILE, masterProfileData };
}

export function leadsDataSuccess(leadsData) {
  return { type: types.LOAD_LEADS_DATA_SUCCESS, leadsData };
}

export function leadInfoSuccess(leadInfo) {
  return { type: types.LOAD_LEAD_INFO_SUCCESS, leadInfo };
}

export function leadProfileInfoSuccess(leadProfileInfo) {
  return { type: types.LOAD_LEAD_PROFILE_DATA_SUCCESS, leadProfileInfo };
}

// export function communicationInfoSuccess(leadCommunicationInfo) {
 // return { type: types.LOAD_COMMUNICATON_INFO_SUCCESS, leadCommunicationInfo };
// }

export function interactionTypeDataSuccess(interactionTypeData) {
  return { type: types.LOAD_INTERACTION_TYPE, interactionTypeData };
}

export function leadStatusDataSuccess(leadStatusData) {
  return { type: types.LOAD_LEAD_STATUS, leadStatusData };
}
export function leadCreateSuccess(createLead) {
  return { type: types.LEAD_CREATE_STATUS, createLead };
}

export function checklistsDataSuccess(checklistsData) {
  return { type: types.LOAD_CHECKLISTS_DATA_SUCCESS, checklistsData };
}

export function sourceLeadInfoSuccess(sourceLeadInfo) {
  return { type: types.LOAD_SOURCE_LEAD_INFO_SUCCESS, sourceLeadInfo };
}

export function leadStatusInfoSuccess(leadStatusInfo) {
  return { type: types.LOAD_LEAD_STATUS_INFO_SUCCESS, leadStatusInfo };
}

const createLead = (params,push) => {
  return async function (dispatch) {
    await agent
      .post(API.CREATE_LEAD, params)
      .then((response) => {  

        console.log("========createLead : start=========");
        console.log(response);
        console.log("========createLead : end=========");
        
        if (response.status === 200 || response.status === 201) {
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            setToaster(response.data.message);
            push("/dashboard/create-lead"); 
            dispatch(leadCreateSuccess(response.data));
            // setToaster(response.data.message);
            // push("/dashboard/create-lead");
          }
        } else{ 
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

// my code start

const leadStatusDataAllDropdown = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;
      if (returnVal) { 
        response = await agent.post(API.GET_LEAD_STATUS_ALL, params);
      } else {
        response = await agent.post(API.GET_LEAD_STATUS_ALL, params);
      }
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }
      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
}




// my code end

const addEditLead = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_LEAD, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const editLead = (params,push) => {
  return async function (dispatch) {
    await agent
      .post(API.CREATE_LEAD, params)
      .then((response) => {  
 
        if (response.status === 200 || response.status === 201) {
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            setToaster(response.data.message);
            const encodedId = btoa(String(response.data.lead_id));
            push(`/dashboard/edit-lead/${encodedId}`); 
          }
        } else{ 
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

/* const sourceData  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_SOURCE_OF_LREADS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(sourceOfLeadDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
} */

const sourceData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_SOURCE_OF_LREADS, params);
      } else {
        response = await agent.post(API.GET_SOURCE_OF_LREADS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(sourceOfLeadDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

/* const masterProfileData  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_LEAD_PROFILE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(masterProfileDataSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
} */

const masterProfileData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LEAD_PROFILE, params);
      } else {
        response = await agent.post(API.GET_LEAD_PROFILE, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const createLeadCommunication = (params,push) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_LEAD_COMMUNICATION, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const createLeadQualificationChecklist = (params,push) => {
  return async function (dispatch) {
    await agent
      .post(API.CREATE_LEAD_QUALIFICATION_CHECKLIST, params)
      .then((response) => {  

        console.log("========createLeadQualificationChecklist : start=========");
        console.log(response);
        console.log("========createLeadQualificationChecklist : end=========");
        
        if (response.status === 200 || response.status === 201) {
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            setToaster(response.data.message); 
            push("/dashboard"); 
          }
        } else{ 
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

const createQualificationChecklist = (params,push) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CU_QUALIFICATION_CHECKLIST, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const leadChecklistData  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_LEAD_CHECKLIST, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(leadChecklistDataSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
}

const leadsData  = (params, preloader = true, returnVal = false) => {
  
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    }

    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LEADS, params);
      } else {
        response = await agent.post(API.GET_LEADS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(leadsDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
}

/*
const leadInfo  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_LEADS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
             dispatch(leadInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
} */

const leadInfo = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LEADS, params);
      } else {
        response = await agent.post(API.GET_LEADS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(leadInfoSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const leadCommunicationInfo  = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) { 
    const loader = preloader ? dispatch(toggleNetworkRequestStatus(true)) : '';
    try {
      const response = await agent.post(API.GET_COMMUNICATION, params);
      const loader = preloader ? dispatch(toggleNetworkRequestStatus(false)) : '';
      return response;
    } catch (error) {
      const loader = preloader ? dispatch(toggleNetworkRequestStatus(false)) : '';
      throw error;
    } 
  };
}

const interactionTypeData  = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) { 
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    await agent
      .post(API.GET_INTERACTION_TYPE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (preloader) {
            dispatch(toggleNetworkRequestStatus(false));
          } 
          if (response.data.status === 200) {
             dispatch(interactionTypeDataSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      });
  };
}

// const leadStatusData  = (params) => {
//   return async function (dispatch) { 
//     dispatch(toggleNetworkRequestStatus(true));
//     await agent
//       .post(API.GET_LEAD_STATUS, params)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           dispatch(toggleNetworkRequestStatus(false));
//           if (response.data.status === 200) {
//              dispatch(leadStatusDataSuccess(response.data.data)); 
//           }
//         }
//       })
//       .catch((error) => { 
//         dispatch(toggleNetworkRequestStatus(false));
//         throw error;
//       });
//   };
// }

const leadStatusData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LEAD_STATUS, params);
      } else {
        response = await agent.post(API.GET_LEAD_STATUS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(leadStatusDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

// leadType
// const leadStatusDataAll = (params, preloader = true, returnVal = false) => {
//   return async function (dispatch) {
//     if (preloader) {
//       dispatch(toggleNetworkRequestStatus(true));
//     } 
//     try {
//       let response;

//       if (returnVal) {
//         response = await agent.post(API.GET_LEAD_STATUS_ALL, params);
//       } else {
//         response = await agent.post(API.GET_LEAD_STATUS_ALL, params);
        
//         if (response.status === 200 || response.status === 201) {
//           if (response.data.status === 200) {
//             dispatch(leadStatusDataSuccess(response.data.data));
//           }
//         }
//       }

//       if (preloader) {
//         dispatch(toggleNetworkRequestStatus(false));
//       }

//       return response;
//     } catch (error) {
//       // Handle errors, e.g., log or dispatch an error action
//       console.error("API Request Error:", error);

//       if (preloader) {
//         dispatch(toggleNetworkRequestStatus(false));
//       } 
//       throw error;
//     }
//   };
// };

/* const checklistsData  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_CHECKLISTS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(checklistsDataSuccess(response.data.data));
           }
        }
      })
      .catch((error) => { 
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
} */

const checklistsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_CHECKLISTS, params);
      } else {
        response = await agent.post(API.GET_CHECKLISTS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(checklistsDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const checklistInfo  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.GET_CHECKLISTS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const createLeadProfile = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_LEAD_PROFILE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const leadProfileInfo  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_LEAD_PROFILE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(leadProfileInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
}

const createSourceLead = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_SOURCE_LEAD, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const sourceLeadInfo  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_SOURCE_OF_LREADS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(sourceLeadInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
}

const updateLeadChecklistQuestionAnswer = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_LEAD_CHECKLIST_QUESTION_ANSWER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}


const createLeadStatus = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_LEAD_STATUS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const leadStatusInfo  = (params) => {
  return async function (dispatch) { 
    await agent
      .post(API.GET_LEAD_STATUS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            // console.log(response.data.data);
            dispatch(leadStatusInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
}

const updateLeadKarzaPanDetails = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_LEAD_PAN_DETAILS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addClientHabileInfo = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ADD_CLIENT_HABILE_INFO, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addIdentifierHabileInfo = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ADD_IDENTIFIER_HABILE_INFO, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addSummaryInfo = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ADD_SUMMARY_INFO, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const callCrifAPI = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CALL_CRIF_API, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const masterStatesData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_STATES, params);
      } else {
        response = await agent.post(API.GET_STATES, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addCredit = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ADD_CREDIT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getNoteHistory = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_NOTE_HISTORY, params);
      } else {
        response = await agent.post(API.GET_NOTE_HISTORY, params);       
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const masterPrefillTemplateTypeData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_PREFILL_TEMPLATE_TYPE, params);
      } else {
        response = await agent.post(API.GET_PREFILL_TEMPLATE_TYPE, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const masterPrefillTemplateNameData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_PREFILL_TEMPLATE_NAME, params);
      } else {
        response = await agent.post(API.GET_PREFILL_TEMPLATE_NAME, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addEditPrefillTemplateName = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_PREFILL_TEMPLATE_NAME, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const deleteLeadBasicInfo = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.DELETE_LEAD_BASIC_INFO, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const uploadLeads = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPLOAD_LEADS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const uploadLeadData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_UPLOAD_LEAD, params);
      } else {
        response = await agent.post(API.GET_UPLOAD_LEAD, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const masterDocumentTypeData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_DOCUMENT_TYPE, params);
      } else {
        response = await agent.post(API.GET_DOCUMENT_TYPE, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addEditDocumentTypeData = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_DOCUMENT_TYPE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getUploadLeadDocument = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_UPLOAD_LEAD_DOCUMENT, params);
      } else {
        response = await agent.post(API.GET_UPLOAD_LEAD_DOCUMENT, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const addEditLeadDocument = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPLOAD_LEAD_DOCUMENT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getCrifReport = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.VIEW_CRIF_REPORT, params);
      } else {
        response = await agent.post(API.VIEW_CRIF_REPORT, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const exportLeadScoreCard = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.EXPORT_LEAD_SCORE_CARD, params);
      } else {
        response = await agent.post(API.EXPORT_LEAD_SCORE_CARD, params);
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API States Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const storeScoreData = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.STORE_SCORE_DATA, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addEditLeadAllCloudCustomer = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.MANAGE_LEAD_ALLCLOUD_CUSTOMER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addEditAllCloudCustomerLead = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.MANAGE_ALLCLOUD_CUSTOMER_LEAD, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const addEditAllCloudCustomerLoan = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.MANAGE_ALLCLOUD_CUSTOMER_LOAN, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const validateBefiscPhoneDetails = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.VALIDATE_BEFISC_PHONE_DETAILS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const sendEnachUPIRegistrationDetails = (params) => {
  console.log("paramsLeadActions", params)
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.SEND_ENACH_UPI_REGISTRATION_DETAILS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}


const sendEnachRegistrationDetails = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.SEND_ENACH_REGISTRATION_DETAILS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const enachActionsDetails = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ENACH_ACTIONS_DETAILS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const updateFiChecklistQuestionAnswer = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_FI_CHECKLIST_QUESTION_ANSWER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

export default {
  createLead,
  editLead,
  addEditLead,
  createLeadCommunication,
  sourceOfLeadDataSuccess,
  sourceData,
  masterProfileDataSuccess,
  masterProfileData,
  createLeadQualificationChecklist,
  leadsData,
  leadsDataSuccess,
  leadInfo,
  leadInfoSuccess,
  leadChecklistDataSuccess,
  leadChecklistData,
  leadCommunicationInfo,
  interactionTypeData,
  leadStatusDataSuccess,
  leadStatusData,
  // leadStatusDataAll,
  leadStatusDataAllDropdown,
  createQualificationChecklist,
  checklistsData,
  checklistsDataSuccess,
  checklistInfo,
  createLeadProfile,
  leadProfileInfo,
  createSourceLead,
  sourceLeadInfo,
  updateLeadChecklistQuestionAnswer,
  createLeadStatus,
  leadStatusInfo,
  leadStatusInfoSuccess,
  updateLeadKarzaPanDetails,
  addClientHabileInfo,
  addIdentifierHabileInfo,
  addSummaryInfo,
  callCrifAPI,
  masterStatesData,
  addCredit,
  getNoteHistory,
  masterPrefillTemplateTypeData,
  masterPrefillTemplateNameData,
  addEditPrefillTemplateName,
  deleteLeadBasicInfo,
  uploadLeads,
  uploadLeadData,
  masterDocumentTypeData,
  addEditDocumentTypeData,
  getUploadLeadDocument,
  addEditLeadDocument,
  getCrifReport,
  exportLeadScoreCard,
  storeScoreData,
  addEditLeadAllCloudCustomer,
  addEditAllCloudCustomerLead,
  addEditAllCloudCustomerLoan,
  validateBefiscPhoneDetails,
  sendEnachUPIRegistrationDetails,
  sendEnachRegistrationDetails,
  // leadInfoSuccess,
  enachActionsDetails,
  updateFiChecklistQuestionAnswer,
}