import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus"; 

export function groupsUsersDataSuccess(groupsUsersData) {
  return { type: types.LOAD_GROUPS_USERS_DATA_SUCCESS, groupsUsersData };
}

export function groupDataSuccess(groupData) {
  return { type: types.LOAD_INCIDENT_GROUP_DATA_SUCCESS, groupData };
}

export function groupInfoSuccess(groupInfo) {
  return { type: types.LOAD_INCIDENT_GROUP_SUCCESS_INFO, groupInfo };
}

const groupsUsersData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_GROUPS_USERS, params);
        } else {
          response = await agent.post(API.GET_GROUPS_USERS, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
};

const groupData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_INCIDENT_GROUP, params);
      } else {
        response = await agent.post(API.GET_INCIDENT_GROUP, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const groupInfo  = (params) => { 
  return async function (dispatch) { 
    await agent
      .post(API.GET_INCIDENT_GROUP, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(groupInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        throw error;
      });
  };
}

const createIncidentGroup = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_INCIDENT_GROUP, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

export default {
  groupsUsersData,
  groupsUsersDataSuccess,
  groupData,
  groupDataSuccess,
  groupInfo,
  groupInfoSuccess,
  createIncidentGroup,
}