import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function loggedinUserFollowupLeadsDataReducer(state = initialState.loggedinUserFollowupLeadsData, action) {
  switch (action.type) {
    case types.LOAD_LOGGEDIN_USER_FOLLOWUP_LEADS_DATA_SUCCESS:
      return action.loggedinUserFollowupLeadsData;
    default:
      return state;
  }
}
