import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function guarantorDataReducer(state = initialState.guarantorData, action) {
  switch (action.type) {
    case types.LOAD_GUARANTOR_DATA_SUCCESS:
      return action.guarantorData;
    default:
      return state;
  }
}
