import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import * as userActions from "../../../actions/userActions";
import { checkConform } from "../../../utils/helpers";
import { encryptData } from '../../../utils/cryptoUtils';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  }, 
];

// ----------------------------------------------------------------------

const AccountPopover = (props) => { 
  const { actions, userLogin, userParams, deviceToken ,userLoginData,userInfo} = props;
  const [open, setOpen] = useState(null); 
  const navigate = useNavigate();
  
  const handleLogoutClick = () => {
    const afterLogoutCallback = () => {
      const params = {};
      const isToken = deviceToken;
      params.device_type = "web";
      params.device_token = isToken;
      params.login_token = localStorage.getItem('authToken');
      // console.log("params : ", params);
      const requestData = encryptData(params);
      const response = actions.userLogout(requestData);
      response.then((result) => {
        if (result.data.success === 1) {

          const data = {};
          actions.loadUserAuth(false);
          actions.loginDataSuccess(data);
          localStorage.setItem("authToken", '');

          navigate("/login");

        } else {
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    };

    checkConform(afterLogoutCallback, "Are you sure you want to logout account?");
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap> 
           {userInfo.id ? (userInfo.name)
          : ("")}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap> 
            {userInfo.id ? (userInfo.email)
            : ("")}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {userInfo.id ? (<MenuItem onClick={handleLogoutClick} sx={{ m: 1 }}>
          Logout
        </MenuItem>)
        : ("")}
      </Popover>
    </>
  );
}

function mapStateToProps(state) {
  return {
    deviceToken: state.deviceToken,
    userInfo: state.userInfo,
  }
}
 
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountPopover);

