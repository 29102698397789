import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function incidentGroupInfoReducer(state = initialState.groupInfo, action) {
  switch (action.type) {
    case types.LOAD_INCIDENT_GROUP_SUCCESS_INFO:
      return action.groupInfo;
    default:
      return state;
  }
}
