import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import $ from 'jquery';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const AnswerOptions = ({ options, setOptions, scores, setScores, questionTypeVal }) => {

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleScoreChange = (index, value) => {
    const updatedScores = [...scores];
    updatedScores[index] = value;
    setScores(updatedScores);
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const removeAnswer = (index) => {
    const updatedAnswers = [...options];
    updatedAnswers.splice(index, 1);
    setOptions(updatedAnswers);
  };

  return (
    <div className="">
      {options.map((option, index) => (
        <div key={index} className='form-group row'>

          { (questionTypeVal === 'checkbox' || questionTypeVal === 'radio') && index >= 0 ? (
            <>
              <div className="col-md-6 col-7 pe-md-3 pe-0">
                <span>Answer text</span>
                <input
                  type="text"
                  className="form-control"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                />
              </div>

              <div className="col-lg-2 col-md-3 col-3">
                <span>Score</span>
                <input
                  type="number"
                  className="form-control"
                  value={scores[index]}
                  onChange={(e) => handleScoreChange(index, e.target.value)}
                />
              </div>
            </>
          ) : null }

          {questionTypeVal === 'textarea' && index === 0 ?(
            <>
              <div className="col-md-6 col-7 pe-md-3 pe-0">
                <span>Answer text</span>
                <textarea
                  className="form-control"
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                >{option}</textarea>
              </div>

              <div className="col-lg-2 col-md-3 col-3">
                <span>Score</span>
                <input
                  type="number"
                  className="form-control"
                  value={scores[index]}
                  onChange={(e) => handleScoreChange(index, e.target.value)}
                />
              </div>
            </>
          ) : null}

          {questionTypeVal === 'input' && index === 0 ?(
            <>
              <div className="col-md-6 col-7 pe-md-3 pe-0">
                <span>Answer text</span>
                <input
                  type="text"
                  className="form-control"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                />
              </div>
              <div className="col-lg-2 col-md-3 col-3">
                <span>Score</span>
                <input
                  type="number"
                  className="form-control"
                  value={scores[index]}
                  onChange={(e) => handleScoreChange(index, e.target.value)}
                />
              </div>
            </>
          ) : null}

          {(questionTypeVal === 'input' || questionTypeVal === 'textarea') && index !== 0?(
            <div className="col-2 pt-1 ps-0">
              {index !== 0 && removeAnswer(index)}
          </div>) : null}

          {(questionTypeVal === 'checkbox' || questionTypeVal === 'radio') ?(
          <div className="col-2 pt-1 ps-0">
            <span className='d-block'>&nbsp;</span>
            {index > 0 ? (<button type="button" onClick={() => removeAnswer(index)} className='btn btn-danger dismiss-btn'><i className="bi bi-x-lg">&nbsp;</i></button>) : null}
          </div>) : null}
          
        </div>

      ))}

      {(questionTypeVal === 'checkbox' || questionTypeVal === 'radio') ?
        (<div className='form-group'>
          <button type="button" onClick={handleAddOption} className="btn btn-info op-btn text-white">Add Option</button>
        </div>) : null}
    </div>
  );
};

const LeadQualificationChecklistCreate = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, checklistInfo, basicLeadInfo, sourceData, masterProfileData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const [checklistId, setChecklistId] = useState('');
  const [showChecklistDetails, setShowChecklistDetails] = useState(false);


  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const [isIncidentChecklist, setIsIncidentChecklist] = useState(false);
  const [allIncidentCategoryData, setAllIncidentCategoryData] = useState([]);
  const [incidentCategory, setIncidentCategory] = useState("");

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Checklists');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.incidentCategoryData(requestData);
    response.then((result) => {
        // console.log("Promise resolved -  source data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data
            const filteredResultArr = results.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.incident_category.localeCompare(b.incident_category)
            );
            setAllIncidentCategoryData(sortedResultArr);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllIncidentCategoryData]);

  const fetchChecklist = useCallback(() => {
    const params = new URLSearchParams(location.search);
    setShowChecklistDetails(false);
    if (id !== undefined && !showChecklistDetails) {
      const decodedId = atob(id);
      setChecklistId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'checklist_id': decodedId };
      const requestData = encryptData(obj);
      const response = actions.checklistInfo(requestData);
      response.then((result) => {
        // console.log("Promise resolved:", result);
        if (result.data.success === 1) {
          setValue("checklist_name", result.data.data[0].check_list_name);
          setValue("status", result.data.data[0].status);
          setValue("stage", result.data.data[0].stage);
          setQuestions(result.data.data[0].form_data_decoded);

          setValue("incident_category", result.data.data[0].incident_category);
          setIncidentCategory(result.data.data[0].incident_category);
          handleChangeIncidentCategory({ target: { value: result.data.data[0].incident_category } });

          setIsIncidentChecklist(result.data.data[0].is_incident_checklist);

          if (Object.prototype.hasOwnProperty.call(result.data.data[0], 'is_incident_checklist') && result.data.data[0].is_incident_checklist) {
            const isIncidentChecklistVal = result.data.data[0].is_incident_checklist;

            if (isIncidentChecklistVal === 1 || isIncidentChecklistVal === "1") {
              setIsIncidentChecklist(true);
            } else {
              setIsIncidentChecklist(false);
            }
          }
        }

      }).catch((error) => {
        console.error("Promise rejected:", error);
      });
    }

  }, [id, setValue, setQuestions, setIsIncidentChecklist, setIncidentCategory]);

  useEffect(() => {
    fetchChecklist();
  }, [fetchChecklist]);

  const addQuestion = () => {
    const newQuestion = {
      questionText: '',
      answerType: 'input',
      answerOptions: ['Option 1'],
    };
    setQuestions([...questions, newQuestion]);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };


  const onSubmit = (data) => {
    let params = {};
    if (questions.length < 1) {
      toast.error("Please add question", {
        position: "top-right",
      });
      return;
    }
    const hasBlankQuestion = questions.some((question) => !question.questionText.trim());
    if (hasBlankQuestion) {
      toast.error("Please enter the question text", {
        position: "top-right",
      });
      return;
    }

    if(isIncidentChecklist && (incidentCategory === null || incidentCategory.trim() === "")){
      toast.error("Please select incident category", {
        position: "top-right",
      });
      return;
    }

    params = { 'checklist_id': checklistId, 'check_list_name': data.checklist_name, 'status': data.status, 'form_data': questions, "stage": data.stage, "is_incident_checklist": (isIncidentChecklist ? '1' : '0'), "incident_category": incidentCategory};
    
    // console.log("Form Data:", params);

    const requestData = encryptData(params);
    const response = actions.createQualificationChecklist(requestData, navigate);

    response.then((result) => {
      // alert(result.data.success);
      // console.log("Promise resolved create intercation : ", result);
      if (result.data.success === 1) {
        // Handle success case

        toast.success(result.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate('/dashboard/checklists');
        }, 1000);
      } else {
        // Handle error case  
        toast.error(result.data.message, {
          position: "top-right",
        });
      }

    }).catch((error) => {
      toast.error(error, {
        position: "top-right",
      });
    });
  };

  const handleChangeIncidentChecklist = (event) => {
    const { name, value } = event.target;
    if (name === 'is_incident_checklist') {
        setIsIncidentChecklist(event.target.checked);
        
        if(!event.target.checked){
          // setValue("incident_category", "");
          setIncidentCategory("");
        }
    }
  };

  const handleChangeIncidentCategory = (event) => {
    const selectedValue = event.target.value;
    setIncidentCategory(selectedValue);
  }

  
  
  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>Lead Qualification Checklist | Ckers </title>
      </Helmet>

      <Container>
        <div className="flex-container">
            <Typography variant="h4">
              Lead Qualification Checklist
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://www.loom.com/share/59cc05f2823344218a0d0c33a4f2e3ff?sid=d954f109-e9cc-4a1e-b0ad-0ea94d385cec', '_blank')}
            >
                Tutorial  Video
            </Button>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div id='main-wrap'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor="checklist-name">
                      <span>Checklist Name <a style={{ color: "red" }}>*</a></span>
                      <input type='text' id='checklist_name' name='checklist_name' className='form-control' placeholder='' {...register('checklist_name', { required: true })} />
                      {errors.checklist_name && <span className="required">This field is required.</span>}
                    </label>
                  </div>
                </div>
                <div className='col-12'>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="reporting-user">
                          <span>Status <a style={{ color: "red" }}>*</a></span>
                          <select name='status' id='status' className="form-select" {...register('status', { required: true })}>
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="2">Inactive</option>   
                          </select>
                        </label>
                        {errors.status && <span className="required">This field is required.</span>}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="reporting-user">
                          <span>Stage</span>
                          <select name='stage' id='stage' className="form-select" {...register('stage')}>
                            <option value="">Select Stage</option>
                            <option value="1">Pre-Sales</option>
                            <option value="2">Sales</option>
                            <option value="3">Credit</option>
                          </select>
                        </label>
                        {errors.stage && <span className="required">This field is required.</span>}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                          <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="is_incident_checklist">

                                  <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="is_incident_checklist"
                                      name="is_incident_checklist"
                                      checked={isIncidentChecklist} 
                                      onChange={handleChangeIncidentChecklist}
                                  />

                                  <span>Is Incident Category</span>

                              </label>
                          </div>
                      </div>
                    </div>

                    <div className={isIncidentChecklist ? "col-md-6" : " col-md-6 hide"}>
                      <div className='form-group'>
                        <label htmlFor="reporting-user">
                          <span>Incident Category <a style={{ color: "red" }}>*</a></span>
                          <select id='incident_category' name='incident_category' className="form-select" {...register('incident_category')}
                            value={incidentCategory}
                            onChange={handleChangeIncidentCategory}
                            >
                            <option value="">Select Incident Category</option>
                            {allIncidentCategoryData.length > 0 && allIncidentCategoryData.map((option) => (
                                <option value={option.id}>
                                    {option.incident_category}
                                </option>
                            ))}
                          </select>
                        </label>
                        {errors.incident_category && <span className="required">This field is required.</span>}
                      </div>
                    </div>


                    

                    <div className='col-md-12'>
                      {questions.map((question, index) => (
                        <div key={index} className='question-row row mb-3 mx-0 px-1'>
                          <div className='col-md-11 col-10 pe-md-0 pe-1'>
                            <div className="form-group">
                              <span>Question Text</span>
                              <input
                                type="text"
                                className='form-control'
                                value={question.questionText}
                                onChange={(e) => updateQuestion(index, { ...question, questionText: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className='col-md-1 col-2 pt-1'>
                            <span className='d-block'>&nbsp;</span>
                            <button type="button" onClick={() => removeQuestion(index)} className='btn float-right btn-danger dismiss-btn'>
                              <i className="bi bi-x-lg">&nbsp;</i>
                            </button>
                          </div>

                          <div className='col-md-6'>
                            <div className="form-group">
                              <span>Sequence</span>
                              <input
                                type="number"
                                className='form-control'
                                value={question.questionSequence}
                                onChange={(e) => updateQuestion(index, { ...question, questionSequence: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className='col-md-6 ps-md-0'>
                            <div className="form-group">
                              <span className='d-md-block d-none'>&nbsp;</span>
                              <div className="form-check form-switch mt-md-2 pt-1 d-flex">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="question_required"
                                  checked={question.questionRequired === 'on'}
                                  onChange={(e) => updateQuestion(index, { ...question, questionRequired: e.target.checked ? 'on' : 'off' })}
                                />
                                <span>&nbsp;Question Required</span>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='form-group'>
                              <span>Question Type</span>
                              <select
                                className="form-select"
                                value={question.answerType}
                                onChange={(e) => updateQuestion(index, { ...question, answerType: e.target.value })}
                              >
                                <option value="input">Input</option>
                                <option value="textarea">Textarea</option>
                                <option value="checkbox">Checkbox Options</option>
                                <option value="radio">Radio Options</option>
                              </select>
                            </div>
                          </div>
                          {/* <AnswerOptions
                            options={question.answerOptions}
                            setOptions={(options) => updateQuestion(index, { ...question, answerOptions: options })}
                            questionTypeVal={question.answerType}
                          /> */}

                          <AnswerOptions
                            options={question.answerOptions}
                            setOptions={(options) => updateQuestion(index, { ...question, answerOptions: options })}
                            scores={question.answerScores || []}
                            setScores={(scores) => updateQuestion(index, { ...question, answerScores: scores })}
                            questionTypeVal={question.answerType}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  
                  <div className='row justify-content-between'>
                    <div className='col-6 pe-0'>
                      <div className='form-group mb-2'>
                        <button className='btn btn-primary'>Submit</button>
                      </div>
                    </div>

                    <div className='col-6 text-end ps-0'>
                      <div className='form-group'>
                        <button type='button' className='btn btn-info text-white' onClick={addQuestion}>Add Question</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>

      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    userInfo: state.userInfo,
    checklistInfo: state.checklistInfo,
    sourceData: state.sourceData,
    masterProfileData: state.masterProfileData,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, incidentReporteActions.default,), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadQualificationChecklistCreate);