import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function groupsUsersDataReducer(state = initialState.groupsUsersData, action) {
  switch (action.type) {
    case types.LOAD_GROUPS_USERS_DATA_SUCCESS:
      return action.groupsUsersData;
    default:
      return state;
  }
}
