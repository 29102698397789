import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink,useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const RiskMatrixReportPage = (props) => {
    const { importName } = useParams();
    const { actions, campaignData } = props; 
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pageHeading , setPageHeading]  = useState('');
    const [iframeSrc , setIframeSrc]  = useState('');
    const [iframeLoaded, setIframeLoaded] = useState(false);

    // console.log("importName : ", importName);
    // console.log("iframeSrc : ", iframeSrc);

    let appUrl = config.APP_URL;
    appUrl = appUrl.replace('/public/', '/');

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    return (
        <>

            <Helmet>
                <title> Risk Metrics | Ckers </title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Risk Metrics
                </Typography>
                <div id='main-wrap'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group mb-2'>
                            <a href={`${appUrl}allcloud_apis/risk_matrix_report_by_file.php`} target="_blank" rel="noreferrer">
                                <button className='btn btn-primary'>View Risk Metrics</button>
                            </a>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
 
        </>
    );
}

function mapStateToProps(state) {
    return {
        campaignData: state.campaignData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RiskMatrixReportPage);