import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
import * as manageShipmentActions from "../../actions/manageShipmentActions";
import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ManageShipmentListingPage = (props) => {
    const { actions, departmentsData } = props;
    const navigate = useNavigate();
    const [allShipments, setAllShipments] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showDepartmentDetails, setShowDepartmentDetails] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [walletBalanceAmount, setWalletBalanceAmount] = useState('');

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Manage Shipment')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

    }, [departmentsData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.shipmentOrders(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  shipmentOrders:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const balanceAmount = result.data.data.balanceAmount;

                setWalletBalanceAmount(balanceAmount);
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllShipments(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  shipmentOrders:", error); 
        });

    }, [setAllShipments, setWalletBalanceAmount]);



    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.id));
        if (btnAction === 'cancel') {

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'shipment_order_id': itemObj.id };
            const requestData = encryptData(obj);

            const response = actions.cancelOrder(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });

        } else {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'shipment_order_id': itemObj.id };
            const requestData = encryptData(obj);

            const response = actions.shipmentTrackingAwbNumber(requestData, false);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    const arrResult = result.data.data;
                    setViewModalInfo(arrResult);
                    setOpen(true);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allShipments.filter(item =>
        item.createdByName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title> All Shipments | Ckers </title>
            </Helmet>

            <Container>
                <div id='main-wrap' className='mt-3'>
                    <div className='row justify-content-between'>
                        <div className='col-md-8'>
                            <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                All Shipments
                            </Typography>
                        </div>
                        <div className='col-md-4'>
                            Wallet Amt : {walletBalanceAmount} ₹
                        </div>
                    </div>

                    <div className='table-wrap mt-2'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Lead</th>
                                    <th>Created By</th>
                                    <th>Created On</th>
                                    <th>Shipment Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.leadName}</td>
                                        <td>{item.createdByName}</td>
                                        <td>{item.created_date}</td>
                                        <td>{item.shipment_status}</td>
                                        <td>
                                            <div className="btn-group dropstart">
                                                <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <RouterLink onClick={() => onRowClickAction(item, 'track')} >Track Shipment</RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink onClick={() => onRowClickAction(item, 'cancel')} >Cancel Shipment</RouterLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>

                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div className="history-tl-container beatplan-history">
                            <ul className="tl">
                                <div className="history-tl-container beatplan-history">
                                    <ul className="tl">
                                        {viewModalInfo && viewModalInfo.tracking_data && viewModalInfo.tracking_data.shipment_track_activities && viewModalInfo.tracking_data.shipment_track_activities.length > 0 ? (
                                            viewModalInfo.tracking_data.shipment_track_activities.map((activity, index) => (
                                                <li key={index} className="tl-item">
                                                    {activity && (
                                                        <>
                                                            <div className="tl-item">
                                                                <div className="tl-line">
                                                                    <span className="d-block text-nowrap">Status : {activity.activity}</span>
                                                                    <span className="d-block text-nowrap">Location : {activity.location}</span>
                                                                    <span className="d-block text-nowrap">Date : {activity.date}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </li>
                                            ))
                                        ) : (
                                            <li className="tl-item">
                                                <span className="d-block text-nowrap">No shipment activities found</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </ul>
                        </div>



                    </div>
                </div>
            </Modal>

        </>
    );
}

function mapStateToProps(state) {
    return {
        departmentsData: state.departmentsData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(manageShipmentActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageShipmentListingPage);