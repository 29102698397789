import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import config from "../../config";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadUploadAcivity = ({ props, allLeadActivity, setOpen }) => {

    useEffect(() => {
        console.log("allLeadActivity = ", allLeadActivity);
    }, [allLeadActivity]);

    return (
        <>
            <div id='main-wrap'>
                <div className='row'>
                    <div className='col-md-12'>

                        {allLeadActivity.map((leadActivity) => (
                            <div key={leadActivity.id} className='lead-activity'>
                                {leadActivity.activity && (
                                    <>
                                        {leadActivity.activity === 'send_credit' ? (
                                            <div key={leadActivity.id} className="lead-activity-item">
                                                <p className='mb-2 fw-bold'>Sent to credit</p>
                                                <p className='mb-2'><b>Sent date :</b> {leadActivity.created_date || ''}</p>
                                                <p className='mb-0'><b>Sent by user :</b> {leadActivity.createdBy || ''}</p>
                                            </div>
                                        ) : ("")}

                                        {leadActivity.activity === 'summary_activity' ? (
                                            <div key={leadActivity.id} className="lead-activity-item">

                                                <p className='mb-2 fw-bold'>Credit user has sent back {leadActivity.section_name === "lead_info" ? "Lead" :
                                                    leadActivity.section_name === "co_applicants" ? "Co-Applicant" :
                                                        leadActivity.section_name === "guarantors" ? "Guarantors" :
                                                            leadActivity.section_name === "bank_statement" ? "Bank Statement" :
                                                                leadActivity.section_name === "itr" ? "ITR" :
                                                                    leadActivity.section_name === "gst" ? "GST" : ""} - {leadActivity.name}
                                                </p>

                                                <p className='mb-2'><b>Action date :</b> {leadActivity.created_date || ''}</p>
                                                <p className='mb-2'><b>Action :</b>  <span className={(leadActivity.action === '1') ? "badge text-bg-success text-white" : (leadActivity.action === '2') ? "badge text-bg-danger text-white" : "badge text-bg-danger text-white"}
                                                >{leadActivity.actionName}</span></p>
                                                <p className='mb-0'><b>Credit user :</b> {leadActivity.createdBy || ''}</p>
                                            </div>
                                        ) : ("")}

                                        {leadActivity.activity === 'upload_lead' ? (
                                            <div key={leadActivity.id} className="lead-activity-item">
                                                <p className='fw-bold mb-2'>Lead added in the system</p>
                                                <p className='mb-2'><b>Lead Entry:</b> {leadActivity.created_date || ''}</p>
                                                <p className='mb-2'><b>Campaign Name:</b> {leadActivity.campaignName || ''}</p>
                                                <p className='mb-0'><b>Entered By:</b> {leadActivity.createdBy || ''}</p>
                                            </div>
                                        ) : ("")}
                                    </>
                                )}
                            </div>
                        ))}
                        {allLeadActivity.length === 0 && <p>No activity found.</p>}
                    </div>
                </div>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadUploadAcivity);