import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";


export function coApplicantDataSuccess(coApplicantData) {
    return { type: types.LOAD_CO_APPLICANT_DATA_SUCCESS, coApplicantData };
}

const addEditCoApplicant = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {  
      const response = await agent.post(API.CREATE_CO_APPLICANT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const coApplicantData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {  
        response = await agent.post(API.GET_CO_APPLICANT, params);
      } else {
        response = await agent.post(API.GET_CO_APPLICANT, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(coApplicantDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const relationShipData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_RELATIONSHIP, params);
      } else {
        response = await agent.post(API.GET_RELATIONSHIP, params);       
        
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const identifiersData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_IDENTIFIERS, params);
      } else {
        response = await agent.post(API.GET_IDENTIFIERS, params);       
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

  export default {    
    addEditCoApplicant,
    coApplicantDataSuccess,
    coApplicantData,
    relationShipData,  
    identifiersData,    
  }