import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function loggedinUserNewLeadsDataReducer(state = initialState.loggedinUserNewLeadsData, action) {
  switch (action.type) {
    case types.LOAD_LOGGEDIN_USER_NEW_LEADS_DATA_SUCCESS:
      return action.loggedinUserNewLeadsData;
    default:
      return state;
  }
}
