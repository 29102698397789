import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import { format } from 'date-fns';
import * as userActions from "../../actions/userActions";
import * as departmentActions from "../../actions/departmentActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
import * as groupsUsersActions from "../../actions/groupsUsersActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const UserCreatePage = (props) => {

    const { id } = useParams();
    const location = useLocation();
    const { actions, leadsData, leadInfo, usersData, basicLeadInfo, userTypesData, masterProfileData, leadChecklistData, interactionTypeData, leadStatusData, departmentsData, rolesData } = props;

    const [userId, setUserId] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [showDepartmentDetails, setShowDepartmentDetails] = useState(false);
    const [allDepartments, setAllDepartments] = useState([]);
    const [showRolesDetails, setShowRolesDetails] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const password = watch('password');
    const [isExternalUser, setIsExternalUser] = useState(false);
    const [isCrifUser, setIsCrifUser] = useState(false);
    const [overrideRequestFlow, setOverrideRequestFlow] = useState(false);
   

    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [passwordRequired, setPasswordRequired] = useState(false);
    
    const [allGroupData, setAllGroupData] = useState([]);
    const [getAllUsersListData, setAllUsersListData] = useState([]);
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [assignedUser, setAssignedUser] = useState("");
    const [status, setStatus] = useState("");

    const [selectedUser, setSelectedUser] = useState([]);
    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;

    const [selectedRole, setSelectedRole] = useState([]);
    const [roleNumbersArray, setRoleNumbersArray] = useState([]);
    const isRoleSelected = true;

    const [bureuAccess, setBureuAccess] = useState("");

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        department: '',
        role: '',
        reporting_user_id: '',
        daily_crif_limit :'',
        override_request_flow :'',
        bureau_access : '',
        daily_count : '',
        monthly_count : '',
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Users');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.departmentsData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  departmentInfo data:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");

                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.department_name.localeCompare(b.department_name)
                );

                setAllDepartments(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  departmentInfo data :", error); 
        });

    }, [setAllDepartments]);

    const fetchUserRoles = useCallback(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.rolesData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  rolesData:", result);
            if (result.data.success === 1) {

                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");

                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.role_name.localeCompare(b.role_name)
                );
                setAllRoles(sortedResultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  rolesData:", error);
        });
    }, [setAllRoles]);

    const fetchUserData = useCallback(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            const sortedUsers = [...usersData.data].sort((a, b) => a.name.localeCompare(b.name));
            const result = Object.keys(sortedUsers).filter((key) => sortedUsers[key].status === "1").map((key) => sortedUsers[key]);
            setAllUsers(result);
        }
    }, [setAllUsers]);

    const fetchUserInfo = useCallback(() => {
        if (id !== undefined) {
            const decodedId = atob(id);
            setUserId(decodedId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': decodedId };
            const requestData = encryptData(obj);
            const response = actions.usersData(requestData);

            response.then((result) => {
                console.log("Promise resolved - add/edit lead form:", result);
                if (result.data.success === 1) {                     

                    if (result.data.data.data[0].is_external_user === '1') {
                        setIsExternalUser(true);
                        setBureuAccess(result.data.data.data[0].bureau_access);
                    } else {
                        setIsExternalUser(false);
                        setBureuAccess("");
                    }

                    if (result.data.data.data[0].is_crif_user === '1') {
                        setIsCrifUser(true);
                    } else {
                        setIsCrifUser(false);
                    }

                    if (result.data.data.data[0].override_request_flow === '1') {
                        setOverrideRequestFlow(true);
                    } else {
                        setOverrideRequestFlow(false);
                    }

                    const initialFormValues = {
                        name: result.data.data.data[0].name,
                        email: result.data.data.data[0].email,
                        department: result.data.data.data[0].department,
                        role: result.data.data.data[0].role,
                        reporting_user_id: result.data.data.data[0].reporting_user_id,
                        daily_crif_limit: result.data.data.data[0].daily_crif_limit,
                        bureau_access: result.data.data.data[0].bureau_access,
                        daily_count: result.data.data.data[0].daily_count,
                        monthly_count: result.data.data.data[0].monthly_count,
                    };

                    const userListString = result.data.data.data[0].reporting_user_id;
                    const userlistArray = userListString.split(',');
                    setSelectedUser(userlistArray);
                    setNumbersArray(result.data.data.data[0].selected_user);

                    setFormValues(initialFormValues);
                    setStatus(result.data.data.data[0].status);

                    const roleListString = result.data.data.data[0].role;
                    const roleListArray = roleListString.split(',');
                    setSelectedRole(roleListArray);
                    setRoleNumbersArray(result.data.data.data[0].selected_role);
                    
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                console.error("Promise rejected - add/edit lead form:", error);

            });
        } else { 
            const initialFormValues = {
                name: '',
                email: '',
                department: '',
                role: '',
                reporting_user_id: '',
                daily_crif_limit: '',
                bureau_access : '',
                daily_count : '',
                monthly_count : '',
            };

            setFormValues(initialFormValues);
            setIsExternalUser(false);
            setBureuAccess("");
            setIsCrifUser(false);
            setUserId('');
            setOverrideRequestFlow(false);
        }
    }, [id, setValue, setSelectedUser, setNumbersArray, setFormValues, setIsExternalUser, setIsCrifUser, setUserId, setOverrideRequestFlow]);

    useEffect(() => {
        // fetchDepartments();
        fetchUserRoles();
        fetchUserData();
        fetchUserInfo();
    }, [fetchUserRoles, fetchUserInfo]);

    const handleSubmitForm = (data) => {

        const selectedUserList = JSON.stringify(selectedUser);
        const selectedRoleList = JSON.stringify(selectedRole);

        if(status === ""){
            toast.error("Please selected status.", {
                position: "top-right",
            });
        } else if (userId !== '' && status !== '1' && selectedAssignedGroup === '') {
            toast.error("Please selected Assigned Group.", {
                position: "top-right",
            });
        } else if (userId !== '' && status !== '1' && assignedUser === '') {
            toast.error("Please selected Assigned To.", {
                position: "top-right",
            });
        } else {

            const params = { 'name': formValues.name, 'email': formValues.email, 'password': data.password, 'department': formValues.department, 'role': selectedRoleList, 'reporting_user_id': selectedUserList, 'is_external_user': (isExternalUser ? '1' : '0'), 'status': status, 'lead_assigned': assignedUser, 'lead_assigned_group': selectedAssignedGroup, 'is_crif_user': (isCrifUser ? '1' : '0'),'daily_crif_limit': (isCrifUser ? formValues.daily_crif_limit : '0'), 'override_request_flow': (overrideRequestFlow ? '1' : '0'), 'bureau_access' : bureuAccess, 'daily_count': formValues.daily_count, 'monthly_count': formValues.monthly_count };

            if (userId !== '') {
                params.user_id = userId;
            }

            console.log("form data = ", params);
            const requestData = encryptData(params);
            const response = actions.addEditUser(requestData);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setTimeout(() => {
                        if (userId === '') {
                            const encodedId = btoa(String(result.data.data));
                            // navigate(`/dashboard/edit-user/${encodedId}`);
                            navigate('/dashboard/user');
                        } else {
                            navigate('/dashboard/user');
                        }
                    }, 1000);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }



    };
     
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'is_external_user') {
            setIsExternalUser(e.target.checked);
        } else if (name === 'password') {
            setPasswordRequired(!!value);
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleChangeCrif = (e) => {
        const { name, value } = e.target;
        if (name === 'is_crif_user') {
             setIsCrifUser(e.target.checked); 
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Enable password validation if a value is entered in the password field
        if (name === 'password') {
            setPasswordRequired(!!value);
        }
    };

    const handleChangeOverrideRequestFlow = (e) => {
        const { name, value } = e.target;
        if (name === 'override_request_flow') {
            setOverrideRequestFlow(e.target.checked); 
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleSwitchChange = (e) => {
        setIsExternalUser(e.target.checked);
    };

    useEffect(() => {
        if(!isExternalUser){
            setBureuAccess("");
        }

        if(bureuAccess === undefined){
            setBureuAccess("");
        }
    }, [isExternalUser, bureuAccess, setBureuAccess]);

    const validateConfirmPassword = (value) => {
        // Check if the value matches the password
        return value === password || 'Passwords do not match';
    };

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  groupData list:", result);
            if (result.data.data.success === 1) {

                const results = result.data.data.data;
                // const resultArr = Object.keys(results).map((key) => results[key]);
                const resultArr = Object.keys(results).filter((key) => results[key].status === "1").map((key) => results[key]);
                setAllGroupData(resultArr);

                }
            }).catch((error) => {
                console.error("Promise rejected -  lead list:", error);
            });   
        
    }, [setAllGroupData]);

    const handleChangeStatus = (event) => {
        const status = event.target.value;
        setStatus(status);
        setSelectedAssignedGroup("");
        setAssignedUser("");
    }

    const handleChangeAssignedGroup = (event, actionName ='') => {
        const selectedValue = event.target.value;
        setSelectedAssignedGroup(selectedValue);

        if(selectedValue !== ""){

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id" :  selectedValue};
            const requestData = encryptData(obj);
        
            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);
                       // setAllUsersListData(usersListData[0].users_list);
                    }
                    setSelectedAssignedGroup(selectedValue);

                   if( actionName !== 'selectedUser' ){
                     const userSetReset = { user: '' };
                     setFormValues((prevValues) => ({ ...prevValues, ...userSetReset }));
                   }
                }
            }).catch((error) => {
                // console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
        }
    };

    const handleChangeBureuAccess = (event) => {
        setBureuAccess(event.target.value);
    }

    const handleAssignedUser = (event) => {
        const user = event.target.value;
        setAssignedUser(user);
    }

    // console.log("bureuAccess : ", bureuAccess);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title> {userId === '' ? ('Create User') : ('Edit User')} | Ckers </title>
            </Helmet>

            <Container>
                <div className="flex-container">
                    <Typography variant="h4">
                        {userId === '' ? 'Create User' : 'Edit User'}
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.open('https://www.loom.com/share/3563c0c9f1f44a8088301faf9f9e7955?sid=ab3f9c4f-d4b7-4199-b674-5c1ceb54c98e', '_blank')}
                    >
                        Tutorial  Video
                    </Button>
                </div>

                <div id='main-wrap'>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Name <a style={{ color: "red" }}>*</a></span>
                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            value={formValues.name}
                                            className='form-control'
                                            placeholder=''
                                            {...register('name', { required: formValues.name === '' })}
                                            onChange={handleChange}
                                        />
                                        {errors.name && <span className="required">This field is required.</span>}

                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="email-id">
                                        <span>Email ID <a style={{ color: "red" }}>*</a></span>

                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            value={formValues.email}
                                            className='form-control'
                                            placeholder=''
                                            {...register('email', { required: formValues.email === '' })}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <span className="required">This field is required.</span>}
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Password <a style={{ color: "red" }}>*</a></span>

                                        <input
                                            type='password'
                                            id='password'
                                            name='password'
                                            autoComplete="new-password"
                                            className='form-control'
                                            onChange={handleChange}
                                            {...register('password', {
                                                validate: (value) => {
                                                    if (value !== '') {
                                                        // Apply your password validation logic here
                                                        // Example: Check for a minimum password length
                                                        if (value.length < 6) {
                                                            return 'Password must be at least 6 characters long';
                                                        }
                                                    }
                                                    // Return true to indicate validation success when password is empty
                                                    return true;
                                                },
                                            })}
                                        />
                                        {errors.password && <span className="required">This field is required.</span>}
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Confirm Password <a style={{ color: "red" }}>*</a></span>

                                        <input
                                            type='password'
                                            id='confirm_password'
                                            name='confirm_password'
                                            className='form-control'
                                            placeholder='' {...register('confirm_password', { validate: validateConfirmPassword })}
                                            required={passwordRequired}
                                            onChange={handleChange}
                                        />
                                        {errors.confirm_password && <span className="required">{errors.confirm_password.message}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="department">
                                        <span>Department <a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id='department'
                                            name='department'
                                            value={formValues.department}
                                            className="form-select"
                                            {...register('department', { required: formValues.department === '' })}
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select Department</option>
                                            {allDepartments.map((option) => (
                                                <option value={option.department_id}>
                                                    {option.department_name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.department && <span className="required">This field is required.</span>}
                                    </label>
                                </div>
                            </div>
                            
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <span>User Role <a style={{ color: "red" }}>*</a></span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="role"
                                            options={allRoles.map((option) => ({
                                                key: option.role_id,
                                                value: option.role_name,
                                            }))}
                                            selectedValues={isRoleSelected ? roleNumbersArray : []} // Use isSelected to conditionally set selected values
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedRole(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedRole(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <span>Reporting User Name</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="reporting_user_id"
                                            options={allUsers.map((option) => ({
                                                key: option.user_id,
                                                value: option.name,
                                            }))}
                                            selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUser(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUser(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                <label htmlFor="status">
                                    <span>Status <a style={{ color: "red" }}>*</a></span>
                                    <select
                                        name='status'
                                        className="form-select"
                                        value={status}
                                        onChange={handleChangeStatus}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </select>
                                </label>
                                {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="is_external_user">

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="is_external_user"
                                                name="is_external_user"
                                                checked={isExternalUser}
                                                // onChange={handleSwitchChange}
                                                onChange={handleChange}
                                            />

                                            <span>Is External User</span>

                                        </label>
                                    </div>
                                </div>
                            </div>



                            <div className={status !=="1" && userId !== '' ? "col-md-6" : " col-md-6 hide"}> 
                                <div className='form-group'>
                                    <label htmlFor="assigned-group">
                                        <span>Assigned Group</span>
                                        <select
                                            id='lead_assigned_group'
                                            name='lead_assigned_group'
                                            className="form-select"
                                            {...register('lead_assigned_group')}
                                            value={selectedAssignedGroup}
                                            onChange={handleChangeAssignedGroup}
                                            
                                        >
                                            <option value=''>Select assigned group</option>
                                            {allGroupData.map((option) => (
                                                <option value={option.id}>
                                                    {option.group_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className={status !=="1" && userId !== '' ? "col-md-6" : " col-md-6 hide"}> 
                                <div className='form-group'>
                                    <label htmlFor="assigned-user">
                                        <span>Assigned to</span>
                                        <select
                                            id='user'
                                            name='user'
                                            className="form-select"
                                            {...register('user')}
                                        //    value={assignedUser || formValues.user}
                                            value={assignedUser}
                                            onChange={handleAssignedUser}
                                            
                                        >
                                            <option value=''>Select User</option>
                                            {getAllUsersListData.map((option) => (
                                                <option value={option.user_id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                            {getAllUsersListData.length > 0 && (
                                                <option value='any_user'>Any User</option>
                                            )}
                                        </select>
                                    </label>
                                </div>
                            </div>


                            <div className={isExternalUser ? "col-md-6 d-none" : " col-md-6"}>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="is_crif_user">

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="is_crif_user"
                                                name="is_crif_user"
                                                checked={isCrifUser} 
                                                onChange={handleChangeCrif}
                                            />

                                            <span>Is Crif User</span>

                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={!isExternalUser && isCrifUser ? "col-md-6" : " col-md-6 hide"}> 
                                <div className='form-group'>
                                    <label htmlFor="assigned-group">
                                        <span>Daily Crif Limit</span>
                                        <input
                                            type='text'
                                            id='daily_crif_limit'
                                            name='daily_crif_limit'
                                            value={formValues.daily_crif_limit}
                                            className='form-control'
                                            placeholder=''
                                            {...register('daily_crif_limit')}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={isExternalUser ? "col-md-6 d-none" : " col-md-6"}>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="override_request_flow">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="override_request_flow"
                                                name="override_request_flow"
                                                checked={overrideRequestFlow}
                                                onChange={handleChangeOverrideRequestFlow}
                                            />
                                            <span>Override Request Flow</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={isExternalUser ? "col-md-6" : "col-md-6 d-none"}>
                                <div className='form-group'>
                                    <label htmlFor="status">
                                        <span>Bureau Access</span>
                                        <select
                                            name='bureau_access'
                                            id = 'bureau_access'
                                            className="form-select"
                                            value={bureuAccess}
                                            onChange={handleChangeBureuAccess}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="NO ACCESS">NO ACCESS</option>
                                            <option value="LIMITED ACCESS">LIMITED ACCESS</option>
                                            <option value="FULL ACCESS">FULL ACCESS</option>
                                        </select>
                                    </label>
                                    {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className={bureuAccess === '' || bureuAccess ==='NO ACCESS' ? "col-md-6 d-none" : " col-md-6"}> 
                                <div className='form-group'>
                                    <label htmlFor="daily-count">
                                        <span>Daily Count</span>
                                        <input
                                            type='number'
                                            id='daily_count'
                                            name='daily_count'
                                            value={formValues.daily_count}
                                            className='form-control'
                                            placeholder=''
                                            {...register('daily_count')}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={bureuAccess === '' || bureuAccess ==='NO ACCESS' ? "col-md-6 d-none" : " col-md-6"}> 
                                <div className='form-group'>
                                    <label htmlFor="monthly-count">
                                        <span>Monthly Count</span>
                                        <input
                                            type='number'
                                            id='monthly_count'
                                            name='monthly_count'
                                            value={formValues.monthly_count}
                                            className='form-control'
                                            placeholder=''
                                            {...register('monthly_count')}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        userTypesData: state.userTypesData,
        departmentsData: state.departmentsData,
        rolesData: state.rolesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(
            userActions.default,
            departmentActions.default,
            groupsUsersActions.default,
        ), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreatePage);