import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const UserPage = (props) => {
    const { actions, usersData } = props;
    const navigate = useNavigate();
    const [allRecords, setAllRecords] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showListDetails, setShowListDetails] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Users')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {

        setShowListDetails(false);
        if (!showListDetails) {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS };
            const requestData = encryptData(obj);
            const response = actions.usersData(requestData, false);
    
            response.then((result) => {
                // console.log("Promise resolved -  usersData data:", result); 
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    setAllRecords(results);
                }
            }).catch((error) => {
                //  console.error("Promise rejected -  usersData data :", error); 
            });
        }

    }, [setAllRecords]);


    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.user_id));
        if (btnAction === 'edit') {
            const url = `/dashboard/edit-user/${encodedId}`;
            // navigate(url);
            window.open(url, '_self');
        } else {
            setViewModalInfo(itemObj);
            setOpen(true);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);        
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allRecords.filter(item => {
        const name = item.name?.toLowerCase() || '';
        const formattedID = item.formatetdID?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        const statusName = item.statusName?.toLowerCase() || '';

        return (
            name.includes(searchQuery.toLowerCase()) ||
            formattedID.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase()) ||
            statusName.includes(searchQuery.toLowerCase())
        );
    }
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                  if (result.data.success === 1) {
                    const data = {};
                    actions.loadUserAuth(false);
                    actions.loginDataSuccess(data);
                    localStorage.setItem("authToken", '');
                    localStorage.setItem("logInTimestamp", '');
                    navigate("/login");
                  }
                }).catch((error) => {
                  console.log(error);
                });
              }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title> Users | Ckers </title>
            </Helmet>

            <Container>
                <div className="d-flex align-items-center justify-content-between mb-0">
                    <span>&nbsp;</span>
                    <RouterLink to='/dashboard/create-user' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add User</RouterLink>
                </div>
                <div id='main-wrap' className='mmt-7'>
                    <div className='row justify-content-between'>
                        <div className='col-md-8'>
                            <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                All Users
                            </Typography>
                        </div>
                        <div className='col-md-4 pe-0 ps-lg-5'>
                            <div className="form-group">
                                <label htmlFor="search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='table-wrap'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>UserID</th>
                                    <th>User Name</th>
                                    <th>Created Date</th>
                                    <th>External User</th>
                                    <th>Department</th>
                                    <th>Role</th>
                                    <th>Crif status</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.formatetdID}</td>
                                        <td>{item.name}</td>
                                        <td>{item.created_date}</td>
                                        <td>
                                        <span className={(item.is_external_user === '1') ? "text-green" : ""}
                                            >{(item.is_external_user === '1') ? "Yes" : "No"}</span>
                                        </td>
                                        <td> {item.departmentName} </td>
                                        <td> {item.roleName}</td>

                                        <td>
                                        <span className={(item.is_crif_user === '1') ? "text-green" : ""}
                                            >{(item.is_crif_user === '1') ? "Yes" : "No"}</span>
                                        </td>


                                        <td>
                                            <span
                                                className={(item.status === '1') ? "badge text-bg-success text-white" : (item.status === '2') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                            >{item.statusName}</span>
                                        </td>
                                        <td>
                                            <div className="btn-group dropstart" title="Edit">
                                                <NavLink to={`/dashboard/edit-user/${btoa(String(item.user_id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    <div>

                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">User  : {viewModalInfo.name}</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>User ID :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.formatetdID}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Created By :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.createdByName}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Status :</b></div>
                            <div className='col-7 ps-0'><span className={(viewModalInfo.status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPage);