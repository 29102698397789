import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation , NavLink} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import {
  PieChart,
  Pie,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Bar,
  ResponsiveContainer,
  Cell,
  Label,
  LabelList,
} from "recharts";

import { scaleOrdinal } from 'd3-scale'; // Import 'scaleOrdinal' from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import AccessDenied from '../common/AccessDenied';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadBreakupPage = (props) => {
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { actions, leadBreakupCountData, usersData} = props;
  const [allUsers, setAllUsers] = useState([]);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [getSourceLeadData, setSourceLeadData] = useState([]);
  const [getStatusLeadData, setStatusLeadData] = useState([]);
  const [getAllTeamLeadData, setAllTeamLeadData] = useState([]);
  const [getAllFollowUpsLeadData, setAllFollowUpsLeadData] = useState([]);
  const [getAllSourceWithLeadStatusData, setAllSourceWithLeadStatusData] = useState([]);
  
  const [getDate, setDate] = useState();

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [userRoleType, setUserRoleType] = useState('User');
  const [loggeInUserID, setLoggeInUserID] = useState('');

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Breakup')?.view_permission;
        setPermissions(permissionsData);
        setUserHasPermission(hasViewPermissions);

        if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
          setUserRoleType(result.data.user_info[0].role_type);
          // alert(result.data.user_info[0].role_type);
          setLoggeInUserID(result.data.user_info[0].user_id);
        }
      }
    }).catch((error) => {
      // console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

  const onSubmit = (data) => {
    const params = { 'start_date': data.start_date, 'end_date': data.end_date};
    const requestData = encryptData(params);    
    const response = actions.leadBreakupCountData(requestData, false);
    response.then((result) => {

       // setDate(data.date);
        // console.log("Promise resolved - leadBreakupCountData", result); 
        if (result.data.success === 1) {
          const results = result.data.data.data;
          if('per_source_res' in results){
              setSourceLeadData(results.per_source_res);
              // console.log("setSourceLeadData", results.per_source_res);
            }
            if('per_status_res' in results){
              setStatusLeadData(results.per_status_res);
              // console.log("setStatusLeadData", results.per_status_res);
            }

            if('all_team_lead' in results){
              setAllTeamLeadData(results.all_team_lead);
              // console.log("setAllTeamLeadData", results.all_team_lead);
            }

            if('all_follow_ups_lead' in results){
              setAllFollowUpsLeadData(results.all_follow_ups_lead);
              // console.log("setAllFollowUpsLeadData", results.all_follow_ups_lead);
            }

            if('source_with_lead_status' in results){
              setAllSourceWithLeadStatusData(results.source_with_lead_status);
              // console.log("setAllSourceWithLeadStatusData", results.source_with_lead_status);
            }
        }
    }).catch((error) => {
        console.error("Promise rejected - leadBreakupCountData", error);
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  const sourceChartDataFormatted = getSourceLeadData.map((entry, index) => ({
    name: entry.sourceName,
    sourceId: entry.sourceId,
    leadCount: parseInt(entry.leadCount, 10), // Convert lead_count to an integer
    color: theme.palette[index % theme.palette.length]?.main || '#000', // Use a fallback color if 'main' is not available
  }));

  const statusChartDataFormatted = getStatusLeadData.map((entry, index) => ({
    name: entry.leadStatus,
    leadStatusId: entry.leadStatusId,
    leadCount: parseInt(entry.leadCount, 10), // Convert leadCount to a number if it's stored as a string
    color: theme.palette[index % theme.palette.length]?.main || '#000', // Use a fallback color if 'main' is not available
  }));

  const teamLeaderChartDataFormatted = getAllTeamLeadData.map((entry, index) => ({
    name: entry.team_leader_name,
    userId: entry.user_id,
    leadCount: parseInt(entry.leadCount, 10), // Convert leadCount to a number if it's stored as a string
    color: theme.palette[index % theme.palette.length]?.main || '#000', // Use a fallback color if 'main' is not available
  }));

  const colorScale = scaleOrdinal(schemeCategory10);

  const handlePieSegmentClick = (leadTypeId, leadBreakupName, leadBreakupType) => {
    // console.log(leadTypeId);
    // console.log(leadBreakupName);
    // console.log(leadBreakupType);
    // console.log(getDate);

    navigate(`/dashboard/lead-breakup-details/${btoa(String(leadTypeId))}/${btoa(String(leadBreakupType))}/${btoa(String(getDate))}`);
  };

  const followUpsLeadColumns = [
    {
      name: 'TEAM LEADER',
      // selector: (row) => row.team_leader_name,
      cell: (row) => <div>{row.team_leader_name}</div>,
      sortable: true,
    },
    {
      name: '# OF FOLLOW UPS FOR TODAY',
      // selector: (row) => row.leadCount,
      cell: (row) => <div>{row.leadCount}</div>,
      sortable: true,
    }
  ];

  const sourceWithLeadStatusColumns = [
    {
      name: 'Lead Source',
      cell: (row) => <div>{row.sourceName}</div>,
      sortable: true,
    },
    {
      name: 'Lead Status',
      cell: (row) => <div>{row.leadStatus}</div>,
      sortable: true,
    },
    {
      name: 'Count',
      cell: (row) => <div>{row.leadCount}</div>,
      sortable: true,
    },
    {
      name: 'Assigned to',
      cell: (row) => <div>{row.assignedUserName}</div>,
      sortable: true,
    },
  ];

  // Function to convert an array of objects to CSV
  function convertArrayOfObjectsToCSV(columns, array) {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    result = '';
    result += columns.map(column => column.name).join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      const row = columns.map(column => {
        if (column.selector) {
          return column.selector(item); // Use the selector function if provided
        }
        return item[column.name] || '';
      }).join(columnDelimiter);
      result += row;
      result += lineDelimiter;
    });

    return result;
  }

  // Function to trigger the CSV download
  function downloadCSV(columns, array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(columns, array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const checkLogout = () => {
    const authToken = localStorage.getItem('authToken');
    const logInTimestamp = localStorage.getItem('logInTimestamp');

    if (logInTimestamp !== null) {
      const loginTimestamp = parseInt(logInTimestamp, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - loginTimestamp;
      const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

      if (timeElapsed >= autoLogoutTimeInSecod) {
        const params = { 'login_token': authToken };
        console.log("params : ", params);
        const requestData = encryptData(params);
        const response = actions.userLogout(requestData);
        response.then((result) => {
          if (result.data.success === 1) {
            const data = {};
            actions.loadUserAuth(false);
            actions.loginDataSuccess(data);
            localStorage.setItem("authToken", '');
            localStorage.setItem("logInTimestamp", '');
            navigate("/login");
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }

  useEffect(() => {
    checkLogout();
  });

  if (!userHasPermission) {
      // Redirect or render an access denied message
      return <AccessDenied />;
  }
  
  return (
    <>
      <Helmet>
        <title>Lead Breakup</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Lead Breakup
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap' className='mb-4 filter'>
            <div className='row mx-0'>
                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="start-date">
                            <span>Select Start Date</span>
                            <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                        </label>
                        {errors.start_date && <span className="required">This field is required.</span>}
                    </div>
                </div>
                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="end-date">
                            <span>Select End Date</span>
                            <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                        </label>
                        {errors.end_date && <span className="required">This field is required.</span>}
                    </div>
                </div>
                <div className='col'>
                  <div className='form-group mb-2'>
                    <span className='d-block'>&nbsp;</span>
                    <button className='btn btn-primary'>Submit</button>
                  </div>
                </div>
            </div>
          </div>


          <div id='main-wrap' className='mb-4'>
            <div className='row'>
              <div className='col-md-6 mb-4'>
                  <h5>Breakup as Per Source</h5>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      {/* Add your Legend and other configurations here */}
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        wrapperStyle={{ marginRight: '20px' }}
                      />
                        <Pie dataKey="leadCount" data={sourceChartDataFormatted} cx="50%" cy="50%" outerRadius={80} labelLine={false}>
                          {sourceChartDataFormatted.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorScale(index)}
                              onClick={() => handlePieSegmentClick(entry.sourceId, entry.name, "source")}
                            />
                          ))}
                          <LabelList dataKey="leadCount" position="inside" />
                        </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
              </div>
              <div className='col-md-6 mb-4'>
                  <h5>Breakup as Per Status</h5>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        wrapperStyle={{ marginRight: '20px' }}
                      />

                        <Pie dataKey="leadCount" data={statusChartDataFormatted} cx="50%" cy="50%" outerRadius={80} labelLine={false}>
                          {statusChartDataFormatted.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorScale(index)}
                              onClick={() => handlePieSegmentClick(entry.leadStatusId, entry.name, "status")}
                            />
                          ))}
                          <LabelList dataKey="leadCount" position="inside" />
                        </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
              </div>
            </div>
          </div>
          
          <div id="main-wrap" className= {userRoleType === "User" ? "mb-4 hide ":"mb-4 show"}>
            <div className='row'>
              <div className='col-md-6 mb-4'>
                  <h5>Breakup as Per Team Leader</h5>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        wrapperStyle={{ marginRight: '20px' }}
                      />

                        <Pie dataKey="leadCount" data={teamLeaderChartDataFormatted} cx="50%" cy="50%" outerRadius={80} labelLine={false}>
                          {teamLeaderChartDataFormatted.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorScale(index)}
                              onClick={() => handlePieSegmentClick(entry.userId, entry.name, "team_lead")}
                            />
                          ))}
                          <LabelList dataKey="leadCount" position="inside" />
                        </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
              </div>

              <div className='col-md-6 mb-4'>
                    <div id='main-wrap-' className='mb-4 search-filter'>
                      <h5>Follow Ups</h5>
                      <DataTableExtensions
                          exportHeaders
                          columns={followUpsLeadColumns}
                          data={getAllFollowUpsLeadData}
                        >
                          <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                          />
                      </DataTableExtensions>
                    </div>
              </div>
            </div>
          </div>

          <div id='main-wrap' className='mb-4'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <div id='main-wrap-' className='mb-4 search-filter'>
                  <h5>Leads as Per Source, Lead Status and Assigned to</h5>
                  <DataTableExtensions
                      exportHeaders
                      columns={sourceWithLeadStatusColumns}
                      data={getAllSourceWithLeadStatusData}
                    >
                      <DataTable
                        noHeader
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                      />
                  </DataTableExtensions>

                  {/* <DataTable
                      title="Leads as Per Source, Lead Status and Assigned to"
                      columns={sourceWithLeadStatusColumns}
                      data={getAllSourceWithLeadStatusData}
                      pagination
                      highlightOnHover
                      actions={
                        <div>
                          <button className='btn btn-primary' onClick={() => {
                            downloadCSV(sourceWithLeadStatusColumns, getAllSourceWithLeadStatusData);
                          }}>Export CSV</button>
                        </div>
                      }
                  /> */}
                </div>
                </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    leadBreakupCountData : state.leadBreakupCountData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(reportActions.default, userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadBreakupPage);