import { useState, useEffect } from 'react';
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import PdfDetails from './PdfDetails';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import * as userActions from "../../actions/userActions";
import AccessDenied from '../common/AccessDenied';
import * as leadActions from "../../actions/leadActions";

const SummaryInfo = (props) => { 
    const { leadInfo, updateParentState,handleCrifAPI, scoreCard,handleCrifOTPAPI,handleCrifResedOTPAPI} = props;

    const { actions } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const downloadBankStatementUrl = `${config.API_BASE_URL}uploads/lead_bank_statement_document/`;
    const downloadGsttUrl = `${config.API_BASE_URL}uploads/lead_gst_document/`;
    const downloadItrtUrl = `${config.API_BASE_URL}uploads/lead_itr_document/`;

    const [coApplicantStates, setCoApplicantStates] = useState({});
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [isMappingStatus, setIsMappingStatus] = useState(false);

    const [addNoteOpen, setAddNoteOpen] = React.useState(false);
    const [actionType, setActionType] = useState("");
    const [sctionId, setSctionId] = useState("");
    const [pageType, setPageType] = useState(""); 
    const [crifOpenModal, setCrifOpenModal] = React.useState(false);
    const [crifOpenOTPModal, setCrifOpenOTPModal] = React.useState(false);
    const [allStates, setAllStates] = useState([]);
    const [remarkRed, setRemarkRed] = useState(false);
    
    const [allLeadCheckLists, setAllLeadCheckLists] = useState([]);
    const [storedQuestions, setStoredQuestions] = useState([]);
    const [requiredQuestionOpenModal, setRequiredQuestionOpenModal] = React.useState(false);
    const [exportExcelFileButtonVisible, setExportExcelFileButtonVisible] = useState(false);
    const [fiSheetCheckId, setFiSheetCheckId] = useState(config.FI_SHEET_CHECK_ID);
    const [openViewScoreCard, setOpenViewScoreCard] = useState(false);
    const [allLeadScore, setAllLeadScore] = useState([]);
    const [leadTotalScore, setLeadTotalScore] = useState('Not Found');

    const [creditScoreCard, setCreditScoreCard] = useState("");
    const [allCreditLeadScore, setAllCreditLeadScore] = useState([]);
    const [leadCreditTotalScore, setLeadCreditTotalScore] = useState('Not Found');
    const [lastUpdateBy, setLastUpdateBy] = useState('Not Found');
    const [lastUpdateDate, setLastUpdateDate] = useState('Not Found');

    const [noteOpenModal, setNoteOpenModal] = React.useState(false);
    const [viewModalNoteInfo, setViewModalNoteInfo] = useState([]);

    useEffect(() => {

        setCreditScoreCard(scoreCard);

        console.log("summary info component -> leadInfo = ", props.leadInfo);
        setFiSheetCheckId(config.FI_SHEET_CHECK_ID);
        setAllStates(props.allStates);

        if (props.leadInfo && props.leadInfo.data && Array.isArray(props.leadInfo.data) && props.leadInfo.data.length > 0) {
            const checkListsResults = props.leadInfo.data[0].all_checklists;
            if (checkListsResults) {
              const checkListsResultsArr = Object.keys(checkListsResults).map((key) => checkListsResults[key]);
              setAllLeadCheckLists(checkListsResultsArr);
            }

            if (checkListsResults && checkListsResults.length > 0) {
                const checklist = checkListsResults.find(item => item.checklist_id === fiSheetCheckId);
                if (checklist) {
                    setExportExcelFileButtonVisible(true);
                }
            }
        }

        setCoApplicantStates((prevCoApplicantStates) => {
            const updatedStates = {};
            Object.keys(prevCoApplicantStates).forEach((index) => {
              updatedStates[index] = {
                isComponentOpen: false,
                selectedAction: null,
                selectedPageType: null,
              };
            });
            return updatedStates;
          });
    }, [leadInfo, setExportExcelFileButtonVisible, setCreditScoreCard]);

    const handleNoteOpen = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.role_id));
        setViewModalNoteInfo(itemObj);
        setNoteOpenModal(true);
        setIsMappingStatus(false);
    }
    
    const handleNoteClose = () => {
        setNoteOpenModal(false);
        setViewModalNoteInfo([]);
        // setValue("note", "");
    };

    const handleAddNoteOpen = (action = null, pageType = null, id = null) => {
        
        setAddNoteOpen(true);
        setActionType(action);
        setSctionId(id);
        setPageType(pageType);
        setValue("note", "");
    }

    const handleAddNoteClose = () => setAddNoteOpen(false);

    const onSubmit = (data) => {

        const leadId = props.leadId;

        if (data.note.trim() === "") {
            toast.error("Note is blank.", {
                position: "top-right",
            });
        } else {
            const params = { "id": sctionId, "action": actionType, "pageType": pageType, "comment": data.note, "lead_id": leadId };
            
            const requestData = encryptData(params);
            const response = actions.addSummaryInfo(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    props.updateParentState(false);
                    reset();
                    setAddNoteOpen(false)
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };


    // State for form data
    const [formRemarkData, setFormRemarkData] = useState({ remarks: '', consentDetailsStatus: false , consentDetails: '',dob :'', full_address :'',city :'',state :'',pin :'',pan_number:'', purpose_deployment_vehicle:'', investor_profile:'' });
  
    const [formOTPData, setFormOTPData] = useState({ otp: ''});

    const [isConsentDetails, setIsConsentDetails] = useState(false);
    const [crifSectionId, setCrifSectionId] = useState('');
    const [crifActionName, setCrifActionName] = useState('');

    useEffect(() => {
        const requiredQuestions = allLeadCheckLists
          .filter(
            (checklist) =>
              checklist.questions_data &&
              checklist.questions_data.length > 0 &&
              checklist.total_required_questions > 0 &&
              checklist.total_required_answered_questions < checklist.total_required_questions
          )
          .map((checklist) => {
            const questionsData = JSON.parse(checklist.questions_data);
    
            const requiredQuestions = questionsData
                .filter((question) => question.questionRequired === 'on' && !hasUserAnswer(question))
                .map((question) => question.questionText);
    
            return { checkListName: checklist.check_list_name, requiredQuestions };
          });
    
        setStoredQuestions(requiredQuestions);
      }, [setStoredQuestions, allLeadCheckLists]);

      const hasUserAnswer = (question) => {
        return question.userAnswers && question.userAnswers.length > 0;
      };

    const clickActionModal = (actionName, sectionId,otherInfo, isExternalUser) => { 
        
        if (storedQuestions.length === 0) {
            
            setCrifOpenModal(true);
            setCrifActionName(actionName);
            setCrifSectionId(sectionId);
            setIsConsentDetails(false);

            const specialCharacterRegex = /[!@#$%^&*()_+|~=`{}[\]:";'<>?,./\\-]/;

            if (actionName === 'lead') {

                if (specialCharacterRegex.test(otherInfo.name)) {
                    setCrifOpenModal(false);
                    toast.error("Lead name special characters not allowed.", {
                        position: "top-right",
                    });
                }

                let panNumber = '';
                if (Object.prototype.hasOwnProperty.call(otherInfo, 'pancard_number') && otherInfo.pan_number !== '' && otherInfo.pancard_number !== null) {
                    panNumber = otherInfo.pancard_number.replace(/"/g, '');
                }

                setFormRemarkData({
                    remarks: '',
                    consentDetailsStatus: false,
                    consentDetails: '',
                    dob: otherInfo.dob,
                    full_address: otherInfo.full_address,
                    city: otherInfo.city,
                    state: otherInfo.state,
                    pin: otherInfo.pin,
                    pan_number: panNumber,
                    purpose_deployment_vehicle: '',
                    investor_profile: ''
                });

            }

            if (actionName === 'coApplicant') {
                if (specialCharacterRegex.test(otherInfo.first_name)) {
                    setCrifOpenModal(false);
                    toast.error("First name special characters not allowed.", {
                        position: "top-right",
                    });
                }

                if (specialCharacterRegex.test(otherInfo.last_name)) {
                    setCrifOpenModal(false);
                    toast.error("Last name special characters not allowed.", {
                        position: "top-right",
                    });
                }
            }
            
            if (actionName === 'guarantor') {
                if (specialCharacterRegex.test(otherInfo.first_name)) {
                    setCrifOpenModal(false);
                    toast.error("First name special characters not allowed.", {
                        position: "top-right",
                    });
                }

                if (specialCharacterRegex.test(otherInfo.last_name)) {
                    setCrifOpenModal(false);
                    toast.error("Last name special characters not allowed.", {
                        position: "top-right",
                    });
                }
            }

        } else {
            // Log storedQuestions

            setViewModalInfo(storedQuestions);
            setRequiredQuestionOpenModal(true);
            console.log("In Modal storedQuestions : ", storedQuestions);
        }

        console.log("otherInfo = ",otherInfo);

    }

    const handleCrifClose = () => { 
        setCrifOpenModal(false);
        setCrifSectionId('');
        setCrifActionName('');
    }

    const handleRequiredQuestionClose = () => { 
        setViewModalInfo([]);
        setRequiredQuestionOpenModal(false); 
    }
    
    const clickActionResedOTPModal = (actionName, sectionId,otherInfo) => { 
        setCrifActionName(actionName);
        setCrifSectionId(sectionId); 
        handleCrifResedOTPAPI(actionName, sectionId,[]);
    }
     
    const clickActionOTPModal = (actionName, sectionId,otherInfo) => { 
         
            setCrifOpenOTPModal(true);
            setCrifActionName(actionName);
            setCrifSectionId(sectionId); 
            console.log("otherInfo = ",otherInfo); 
    }
    const handleCrifOTPClose = () => { 
        setCrifOpenOTPModal(false);
        setCrifSectionId('');
        setCrifActionName('');
    }

    // Form OTP submission handler
    const handleSubmitOTPForm = (event) => {
        event.preventDefault();
        // Handle form submission logic for Form 1
        console.log('Form OTP submitted:', formOTPData);

        if(formOTPData.otp.trim() === ''){
            toast.error("OTP is blank.", {
                position: "top-right",
            }); 
          
        }else{
            setFormOTPData({ otp: ''});
            handleCrifOTPAPI(crifActionName, crifSectionId,formOTPData);
            setCrifOpenOTPModal(false);
        }
    }

    // Form OTP change handler
    const handleChangeBureauOTPForm = (event) => {
        const { name, value } = event.target;
        setFormOTPData((prevData) => ({ ...prevData, [name]: value }));
    };
   
    // Form 1 submission handler
    const handleSubmitBureauForm = (event) => {
        event.preventDefault();
        // Handle form submission logic for Form 1
        console.log('Form 1 submitted:', formRemarkData);
         
        if(formRemarkData.purpose_deployment_vehicle.trim() === ''){
            toast.error("Purpose and deployment of vehicle is blank.", {
                position: "top-right",
            }); 
          
        }else if(formRemarkData.investor_profile.trim() === ''){
            toast.error("Investor profile is blank.", {
                position: "top-right",
            }); 
          
        }else if(crifSectionId === ''){
            toast.error("Section is blank.", {
                position: "top-right",
            }); 
          
        }else if(formRemarkData.remarks.trim() === ''){
            console.log("purpose_deployment_vehicle");
            toast.error("Remarks is required.", {
                position: "top-right",
            }); 
           
        } else if (formRemarkData.remarks.trim().split(/\s+/).length < 20) {
            toast.error("Remarks required minimum 20 words", {
                position: "top-right",
            });
        }
        else if(!formRemarkData.consentDetailsStatus){
            toast.error("Please check the consent details checkbox if you want to proceed for bureau.", {
                position: "top-right",
            }); 
            
        }else if(formRemarkData.consentDetails.trim() === ''){
            toast.error("Consent Details is required.", {
                position: "top-right",
            });
        }else if(formRemarkData.dob.trim() === '' || formRemarkData.dob ==='0000-00-00'){
            toast.error("DoB is required.", {
                position: "top-right",
            });     
        }else if(formRemarkData.full_address.trim() === ''){
            toast.error("Address is required.", {
                position: "top-right",
            }); 
        }else if(formRemarkData.city.trim() === ''){
            toast.error("City is required.", {
                position: "top-right",
            });         
        }else if(formRemarkData.state.trim() === ''){
            toast.error("State is required.", {
                position: "top-right",
            }); 
            
        }else if(formRemarkData.pin.trim() === ''){
            toast.error("Pin is required.", {
                position: "top-right",
            }); 
        }else if(formRemarkData.pan_number.trim() === ''){
            toast.error("Pancard is required.", {
                position: "top-right",
            });
        }else if(!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(formRemarkData.pan_number)) {
            toast.error("Invalid PAN card number.", {
                position: "top-right",
            });
        }else{
            setFormRemarkData({ remarks: '', consentDetailsStatus: true , consentDetails: '' ,dob :'' , full_address :'',city :'',state :'',pin :'',pan_number:'', purpose_deployment_vehicle:'', investor_profile:''});
            handleCrifAPI(crifActionName, crifSectionId,formRemarkData);
            setCrifOpenModal(false);
        }

       
    };

    // Form 1 change handler
    const handleChangeBureauForm = (event) => {
        const { name, value } = event.target;

        if(name === "purpose_deployment_vehicle" && value === "ATTACHED"){
            setRemarkRed(true);
        }
        if(name === "purpose_deployment_vehicle" && value !== "ATTACHED"){
            setRemarkRed(false);
        }

        setFormRemarkData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleKeyPress = (event) => {
        const allowedCharacters = /^[a-zA-Z0-9\s]+$/;
        const pressedKey = event.key;

        if (!allowedCharacters.test(pressedKey)) {
            event.preventDefault();
            toast.error("Special characters not allowed.", {
                position: "top-right",
            }); 
        }
    }

    const handleConsentDetails = (e) => { 
        setIsConsentDetails(e.target.checked); 
        setFormRemarkData((prevData) => ({ ...prevData, 'consentDetailsStatus': e.target.checked }));
    };

    const viewCrifReport = (sectionName, sectionId,action) => { 
        
        const params = { "section_name": sectionName, "section_id": sectionId};
            
        const requestData = encryptData(params);
        const response = actions.getCrifReport(requestData, navigate);

        response.then((result) => {
            if (result.data.success === 1) {
                const html = result.data.html; 
                if(action ==='view'){
                        toast.success(result.data.message, {
                            position: "top-right",
                        }); 
                        // console.log("html : ", html); 
                        // Open the HTML content in a new tab
                        const newTab = window.open(); 
                        newTab.document.write(html);
                }else{
                          generatePdf(html);
                }
                
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            toast.error(error, {
                position: "top-right",
            });
        });
    }

    const generatePdf = (html) => {
        // Get HTML content as a string
        const htmlString = ReactDOMServer.renderToString(<PdfDetails />); 
         
        html2pdf(html);  
    };

    const clickActionModalViewScoreCard = (leadInfo, sectionId, sectionName) => {

        // console.log("leadInfo, ", leadInfo);

        setCreditScoreCard(scoreCard);

        let scoreParams = '';

        if (allLeadCheckLists && allLeadCheckLists.length > 0) {
            setLastUpdateBy(allLeadCheckLists[0].modifiedByName);
            setLastUpdateDate(allLeadCheckLists[0].modified_date);
        }

        if(scoreCard === "1"){
            if (Object.prototype.hasOwnProperty.call(leadInfo, 'lead_dynamic_score')) {
                const leadScoreResults = leadInfo.lead_dynamic_score;
                setLeadCreditTotalScore(leadScoreResults.total_score_crdit_lead);
                setLeadTotalScore(leadScoreResults.total_score_lead);
                setAllCreditLeadScore(leadScoreResults.lead_score);
                setOpenViewScoreCard(true);

                scoreParams = {"lead_id": props.leadId, "section_id": sectionId, "section_name" : sectionName, "total_score": leadScoreResults.total_score_crdit_lead}
            }
        } else {
            const leadScoreResults = leadInfo.lead_score;
            setAllLeadScore(leadScoreResults.lead_score);
            setLeadTotalScore(leadScoreResults.total_score);
            setOpenViewScoreCard(true);

            scoreParams = {"lead_id": props.leadId, "section_id": sectionId, "section_name" : sectionName, "total_score": leadScoreResults.total_score}
        }

        if(scoreParams !== ""){
            // console.log("scoreParams : ", scoreParams);
            const requestData = encryptData(scoreParams);
            const response = actions.storeScoreData(requestData, navigate);
            response.then((result) => {
                console.log("result : ", result);
            }).catch((error) => {
                console.log("error : ", error);
            });
        }
        
    }

    const handleViewScoreCardClose = () => { 
        setAllLeadScore([]);
        setLeadTotalScore("");
        setOpenViewScoreCard(false);

        setLeadCreditTotalScore([]);
        setAllCreditLeadScore("");
        setCreditScoreCard(scoreCard);

        setLastUpdateBy("");
        setLastUpdateDate("");
    }

    return (
        <div> 
            <div className="accordion first-level" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basic Information
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className="row">
 
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'name') && leadInfo.data[0].name !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Name</strong></div> <div className="col-md-9"> - {leadInfo.data[0].name}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'email') && leadInfo.data[0].email !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Email</strong></div> <div className="col-md-9"> - {leadInfo.data[0].email}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'mobile') && leadInfo.data[0].mobile !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Mobile</strong></div> <div className="col-md-9"> - {leadInfo.data[0].mobile}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'full_address') && leadInfo.data[0].full_address !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Address</strong></div> <div className="col-md-9"> - {leadInfo.data[0].full_address}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'city') && leadInfo.data[0].city !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>City</strong></div> <div className="col-md-9"> - {leadInfo.data[0].city}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'state') && leadInfo.data[0].state !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>State</strong></div> <div className="col-md-9"> - {leadInfo.data[0].state}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'pin') && leadInfo.data[0].pin !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Pin</strong></div> <div className="col-md-9"> - {leadInfo.data[0].pin}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'adhar_number') && leadInfo.data[0].adhar_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Aadhar Number</strong></div> <div className="col-md-9"> -   {(leadInfo.data[0].adhar_number !== '' && leadInfo.data[0].adhar_number !== null && leadInfo.data[0].adhar_number.replace(/"/g, ''))}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'pancard_number') && leadInfo.data[0].pancard_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>PAN Number</strong></div> <div className="col-md-9"> -   {(leadInfo.data[0].pancard_number !== '' && leadInfo.data[0].pancard_number !== null && leadInfo.data[0].pancard_number.replace(/"/g, ''))}</div></>}




                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'pancard_number') && leadInfo.data[0].lead_query !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Lead Query</strong></div> <div className="col-md-9"> - {leadInfo.data[0].lead_query}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'statusName') && leadInfo.data[0].statusName !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Lead Status</strong></div> <div className="col-md-9"> - {leadInfo.data[0].statusName}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'leadAssignedGroup') && leadInfo.data[0].leadAssignedGroup !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Assigned Group</strong></div> <div className="col-md-9"> - {leadInfo.data[0].leadAssignedGroup}</div></>}

                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'leadAssigned') && leadInfo.data[0].leadAssigned !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Assigned User</strong></div> <div className="col-md-9"> - {leadInfo.data[0].leadAssigned}</div></>}

                                
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data')) && (
                                    <>
                                        {Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'note') && leadInfo.data[0].note !== '' && leadInfo.data[0].action !== '' ? (
                                            <>
                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                <div className="col-md-9">
                                                    <RouterLink onClick={() => handleNoteOpen(leadInfo.data[0], 'view_note')}>View Note</RouterLink>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>

                                                {(props.userRoleType === 'Credit') && (

                                                    <div className="col-md-9 mt-2">
                                                        <div className="form-group mb-0">
                                                            <button
                                                                className="btn btn-success me-2"
                                                                disabled={props.leadStatusFlag === true}
                                                                onClick={() => handleAddNoteOpen('1', 'lead_info', leadInfo.data[0].id)}
                                                            >
                                                                Accept
                                                            </button>

                                                            <button
                                                                className="btn btn-danger"
                                                                disabled={props.leadStatusFlag === true}
                                                                onClick={() => handleAddNoteOpen('2', 'lead_info', leadInfo.data[0].id)}
                                                            >
                                                                Decline
                                                            </button>
                                                        </div>
                                                    </div>)}
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                <div className="col-md-12 pe-md-0">

                                    {!props.isExternalUser && (<>
                                        <button className="btn btn-info text-white float-end ms-2 mb-md-0 mb-2" onClick={() => clickActionModalViewScoreCard(leadInfo.data[0], leadInfo.data[0].id, "lead")}>
                                            View Scorecard
                                        </button>
                                    </>)}
                                    
                                    {(Object.prototype.hasOwnProperty.call(leadInfo, 'data')) && (
                                        <>
                                            {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'crif_info') && leadInfo.data[0].crif_info !== '' && Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'crif_status') && leadInfo.data[0].crif_info.crif_status) ? (
                                                <>
                                                    {leadInfo.data[0].crif_info.crif_status === 'otp_expired' ? (
                                                        <>
                                                            {Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'otp_details') &&
                                                            leadInfo.data[0].crif_info.otp_details && (
                                                                <>
                                                                    <p style={{ fontSize: '14px' }}>
                                                                        OTP Sent by {leadInfo.data[0].crif_info.otp_details.createdByName} on {leadInfo.data[0].crif_info.otp_details.otpSentDateFormated}
                                                                    </p>
                                                                </>
                                                            )}

                                                            <span className="text-red">OTP has been expired for {leadInfo.data[0].mobile}</span>
                                                            <button
                                                                disabled={leadInfo.data[0].crif_info.crif_status !== 'otp_expired'}
                                                                type="button"
                                                                className="btn btn-success float-right"
                                                                onClick={() => clickActionResedOTPModal('lead', leadInfo.data[0].id, leadInfo.data[0], null)}
                                                            >
                                                                Resend OTP
                                                            </button>
                                                        </>
                                                    ) : leadInfo.data[0].crif_info.crif_status === 'not_verified_yet' ? (
                                                        <>

                                                            {Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'otp_details') &&
                                                                leadInfo.data[0].crif_info.otp_details && (
                                                                <>
                                                                    <p style={{ fontSize: '14px' }}>
                                                                        OTP Sent by {leadInfo.data[0].crif_info.otp_details.createdByName} on {leadInfo.data[0].crif_info.otp_details.otpSentDateFormated}
                                                                    </p>
                                                                </>
                                                            )}

                                                            <span className="text-green">OTP has been sent to {leadInfo.data[0].mobile}</span>
                                                            <button disabled={leadInfo.data[0].crif_info.crif_status !== 'not_verified_yet'}
                                                                type="button"
                                                                className="btn btn-warning float-right"
                                                                /* onClick={() => handleCrifAPI('lead', leadInfo.data[0].id)} */

                                                                onClick={() => clickActionOTPModal('lead', leadInfo.data[0].id, leadInfo.data[0])}
                                                            >Verify OTP to Get Bureau Score</button>
                                                        </>
                                                    ) : (
                                                        <>

                                                            {props.isExternalUser ?
                                                                <>
                                                                    {leadInfo.data[0].crif_info && (
                                                                        <>
                                                                            <div className="info-container">
                                                                            {/* Pulled On section fixed at the top */}
                                                                            <div className="pulled-info">
                                                                                Pulled On: {leadInfo.data[0].crif_info.date_time}
                                                                            </div>

                                                                            {/* CRIF data section below */}
                                                                            <div className="crif-data">
                                                                                <div className="info-box">
                                                                                <div className="info-value">{leadInfo.data[0].crif_info.crif_score || 0}</div>
                                                                                <div className="info-title">Crif Score</div>
                                                                                </div>

                                                                                <div className="info-box">
                                                                                <div className="info-value">{leadInfo.data[0].crif_info.TOTAL_ACTIVE_ACOUNTS || 0}</div>
                                                                                <div className="info-title">Active Accounts</div>
                                                                                </div>

                                                                                <div className="info-box">
                                                                                <div className="info-value">{leadInfo.data[0].crif_info.TOTAL_ACOUNTS || 0}</div>
                                                                                <div className="info-title">Credit History</div>
                                                                                </div>

                                                                                <div className="info-box">
                                                                                <div className="info-value">{leadInfo.data[0].crif_info.TOTAL_OVERDUE_ACOUNTS || 0}</div>
                                                                                <div className="info-title">Overdue Accounts</div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                </> :
                                                                <>
                                                                    <div className='float-start bg-light my-md-3 p-3 rounded'>
                                                                        <div className="text-success fw-bold mb-1">Crif Score: {leadInfo.data[0].crif_info.crif_score}</div>
                                                                        <div><strong>Pulled On</strong>: {leadInfo.data[0].crif_info.date_time} </div>


                                                                        {(Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'crif_remarks') && leadInfo.data[0].crif_info.crif_remarks !== '') && <> <div><strong>Remarks</strong>  : {leadInfo.data[0].crif_info.crif_remarks}</div></>}

                                                                        {(Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'crif_consent_details_text') && leadInfo.data[0].crif_info.crif_consent_details_text !== '') && <> <div><strong>Consent Details</strong> : {leadInfo.data[0].crif_info.crif_consent_details_text}</div></>}

                                                                        {(Object.prototype.hasOwnProperty.call(leadInfo.data[0].crif_info, 'createdBy') && leadInfo.data[0].crif_info.createdBy !== '' && leadInfo.data[0].crif_info.crif_remarks !== '') && <> <div className="mt-2">Consent taken on {leadInfo.data[0].crif_info.date_time} by {leadInfo.data[0].crif_info.createdBy}</div></>}

                                                                        <div>
                                                                            <button type="button" className="btn btn-warning"
                                                                                onClick={() => viewCrifReport('lead', leadInfo.data[0].id, 'view')}
                                                                            >View Crif Report</button>

                                                                            <button type="button" className="btn btn-warning  d-none"
                                                                                onClick={() => viewCrifReport('lead', leadInfo.data[0].id, 'download')}
                                                                            >Download Report</button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )}


                                                </>
                                            ) : (<>
                                                {(props.isCrifUser === '1' || props.isExternalUser) && (<>
                                                    <button disabled={props.leadStatusFlag === true}
                                                        type="button"
                                                        className="btn btn-warning float-right"
                                                        /* onClick={() => handleCrifAPI('lead', leadInfo.data[0].id)} */

                                                        onClick={() => clickActionModal('lead', leadInfo.data[0].id, leadInfo.data[0], props.isExternalUser)}
                                                    >Get Bureau Score</button>
                                                </>)}
                                            </>)}
                                        </>)}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                {!props.isExternalUser && ( <>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Co-Applicant
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_co_applicants') && leadInfo.data[0].lead_co_applicants.length > 0) ? (
                                    leadInfo.data[0].lead_co_applicants.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5>Co-Applicant {index + 1}</h5>
                                            <div className="row">
                                                {(Object.prototype.hasOwnProperty.call(option, 'first_name') && option.first_name !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Name</strong></div> <div className="col-md-9"> - {option.first_name} {option.last_name}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'formatedDOB')) && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>DOB</strong></div> <div className="col-md-9"> - {option.formatedDOB}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'email') && option.email !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Email</strong></div> <div className="col-md-9"> - {option.email}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'mobile') && option.mobile !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Mobile</strong></div> <div className="col-md-9"> - {option.mobile}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'gender') && option.gender !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Gender</strong></div> <div className="col-md-9"> - {option.gender}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'marital_status') && option.marital_status !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Martial Status</strong></div> <div className="col-md-9"> - {option.marital_status}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'relationName') && option.relationName !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Relationship</strong></div> <div className="col-md-9"> - {option.relationName}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'email') && option.email !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>E-mail</strong></div> <div className="col-md-9"> - {option.email}</div></>}

                                                {/* {(Object.prototype.hasOwnProperty.call(option, 'identifierName') && option.identifierName !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Identifier Type</strong></div> <div className="col-md-9"> - {option.identifierName}</div></>} */}

                                                {(Object.prototype.hasOwnProperty.call(option, 'pan_number') && option.pan_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Pan Number</strong></div> <div className="col-md-9"> -
                                                    {(option.pan_number !== '' && option.pan_number !== null && option.pan_number.replace(/"/g, ''))}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'adhar_number') && option.adhar_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Aadhaar Number</strong></div> <div className="col-md-9"> -
                                                    {(option.adhar_number !== '' && option.adhar_number !== null && option.adhar_number.replace(/"/g, ''))}</div></>}




                                                {(Object.prototype.hasOwnProperty.call(option, 'full_address') && option.full_address !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Address</strong></div> <div className="col-md-9"> -
                                                    {(option.full_address !== '' && option.full_address)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'city') && option.city !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>City</strong></div> <div className="col-md-9"> -
                                                    {(option.city !== '' && option.city)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'state') && option.state !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>State</strong></div> <div className="col-md-9"> -
                                                    {(option.state !== '' && option.state)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'pin') && option.pin !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Pin</strong></div> <div className="col-md-9"> -
                                                    {(option.pin !== '' && option.pin)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_co_applicants')) && (
                                                    <>
                                                        {Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '' && option.action !== '' ? (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                <div className="col-md-9">
                                                                    <RouterLink onClick={() => handleNoteOpen(option, 'view_note')} >View Note</RouterLink>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                {(props.userRoleType === 'Credit') && (
                                                                    <div className="col-md-9 mt-2">
                                                                        <div className="form-group mb-0">
                                                                            <button
                                                                                className="btn btn-success me-2"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('1', 'co_applicants', option.id)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('2', 'co_applicants', option.id)}
                                                                            >
                                                                                Decline
                                                                            </button>
                                                                        </div>
                                                                    </div>)}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                               
                                                <div className="col-md-12 pe-md-0">

                                                    <button className ="btn btn-info text-white float-end ms-2 mb-md-0 mb-2" onClick={() => clickActionModalViewScoreCard(option, option.id, "coApplicant")}>
                                                        View Scorecard
                                                    </button>
                                                    
            {(Object.prototype.hasOwnProperty.call(option, 'crif_info') && option.crif_info !== '' && Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_status') && option.crif_info.crif_status) ? (
                <>

{option.crif_info.crif_status === 'otp_expired' ? (
            <>
                {Object.prototype.hasOwnProperty.call(option.crif_info, 'otp_details') && option.crif_info.otp_details && (
                    <>
                        <p style={{ fontSize: '14px' }}>
                            OTP Sent by {option.crif_info.otp_details.createdByName} on {option.crif_info.otp_details.otpSentDateFormated}
                        </p>
                    </>
                )}

                <span className="text-red">OTP has been expired for {option.mobile}</span>
                <button disabled={option.crif_info.crif_status !== 'otp_expired'}
                type="button"
                className="btn btn-success float-right"

                onClick={() => clickActionResedOTPModal('coApplicant', option.id,option, null)} 
            >Resend OTP</button>
         </>
    ) : option.crif_info.crif_status === 'not_verified_yet' ? (
    <>
        {Object.prototype.hasOwnProperty.call(option.crif_info, 'otp_details') && option.crif_info.otp_details && (
            <>
                <p style={{ fontSize: '14px' }}>
                    OTP Sent by {option.crif_info.otp_details.createdByName} on {option.crif_info.otp_details.otpSentDateFormated}
                </p>
            </>
        )}
        <span className="text-green">OTP has been sent to {option.mobile}</span>
        <button disabled={option.crif_info.crif_status !== 'not_verified_yet'}
            type="button"
            className="btn btn-warning float-right"
 
            onClick={() => clickActionOTPModal('coApplicant', option.id,option)} 
        >Verify OTP to Get Bureau Score</button>
    </>
    ) : (
                <div className='float-start bg-light my-md-3 p-3 rounded'>
                <div className="text-success fw-bold mb-1">Crif Score: {option.crif_info.crif_score}</div>
                <div><strong>Pulled On</strong>: {option.crif_info.date_time} </div>

                
                {(Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_remarks') && option.crif_info.crif_remarks !== '') && <> <div><strong>Remarks</strong> : {option.crif_info.crif_remarks}</div></>}

                {(Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_consent_details_text') && option.crif_info.crif_consent_details_text !== '') && <> <div><strong>Consent Details</strong> : {option.crif_info.crif_consent_details_text}</div></>}

                {(Object.prototype.hasOwnProperty.call(option.crif_info, 'createdBy') && option.crif_info.createdBy !== '' && option.crif_info.crif_remarks !== '') && <> <div className="mt-2">Consent taken on {option.crif_info.date_time} by {option.crif_info.createdBy}</div></>}         
                
                <div>
                    <button type="button" className="btn btn-warning"
                        onClick={() => viewCrifReport('coApplicant', option.id,'view')} 
                    >View Crif Report</button>

                    <button type="button" className="btn btn-warning d-none"
                        onClick={() => viewCrifReport('coApplicant', option.id,'download')} 
                    >Download Report</button>
                </div>

                </div> )}

                </>
            ) : (<>
                { props.isCrifUser==='1' && (<>
                <button disabled={props.leadStatusFlag === true}
                    type="button"
                    className="btn btn-warning float-right"
                    /* onClick={() => handleCrifAPI('coApplicant', option.id)} */

                    onClick={() => clickActionModal('coApplicant', option.id,option)} 

                >Get Bureau Score</button> </>)}
            </>)}
                                                </div>
                                               
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No Applicant</>)}
                            </>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Guarantor
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_guarantors') && leadInfo.data[0].lead_guarantors.length > 0) ? (
                                    leadInfo.data[0].lead_guarantors.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5>Guarantor {index + 1}</h5>
                                            <div className="row">
                                                {(Object.prototype.hasOwnProperty.call(option, 'first_name') && option.first_name !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Name</strong></div> <div className="col-md-9"> - {option.first_name} {option.last_name}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'formatedDOB')) && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>DOB</strong></div> <div className="col-md-9"> - {option.formatedDOB}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'email') && option.email !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Email</strong></div> <div className="col-md-9"> - {option.email}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'mobile') && option.mobile !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Mobile</strong></div> <div className="col-md-9"> - {option.mobile}</div></>}


                                                {(Object.prototype.hasOwnProperty.call(option, 'pan_number') && option.pan_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Pan Number</strong></div> <div className="col-md-9"> -
                                                    {(option.pan_number !== '' && option.pan_number !== null && option.pan_number.replace(/"/g, ''))}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'aadhaar_number') && option.aadhaar_number !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Aadhaar Number </strong></div> <div className="col-md-9"> -

                                                    {(option.aadhaar_number !== '' && option.aadhaar_number !== null && option.aadhaar_number.replace(/"/g, ''))}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'full_address') && option.full_address !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Address</strong></div> <div className="col-md-9"> -
                                                    {(option.full_address !== '' && option.full_address)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'city') && option.city !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>City</strong></div> <div className="col-md-9"> -
                                                    {(option.city !== '' && option.city)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'state') && option.state !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>State</strong></div> <div className="col-md-9"> -
                                                    {(option.state !== '' && option.state)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'pin') && option.pin !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Pin</strong></div> <div className="col-md-9"> -
                                                    {(option.pin !== '' && option.pin)}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_guarantors')) && (
                                                    <>
                                                        {Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '' && option.action !== '' ? (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                <div className="col-md-9">
                                                                    <RouterLink onClick={() => handleNoteOpen(option, 'view_note')} >View Note</RouterLink>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                {(props.userRoleType === 'Credit') && (
                                                                    <div className="col-md-9 mt-2">
                                                                        <div className="form-group mb-0">
                                                                            <button
                                                                                className="btn btn-success me-2"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('1', 'guarantors', option.id)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('2', 'guarantors', option.id)}
                                                                            >
                                                                                Decline
                                                                            </button>
                                                                        </div>
                                                                    </div>)}
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>

                                               
                                                <div className="col-md-12 pe-md-0">
                                                    
                                                    <button className= "btn btn-info text-white float-end ms-2 mb-md-0 mb-2" onClick={() => clickActionModalViewScoreCard(option, option.id, "guarantor")}>
                                                        View Scorecard
                                                    </button>
                                                    {(Object.prototype.hasOwnProperty.call(option, 'crif_info') && option.crif_info !== '' && Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_status') && option.crif_info.crif_status) ? (
                                                        <>
{option.crif_info.crif_status === 'otp_expired' ? (
            <>

            {Object.prototype.hasOwnProperty.call(option.crif_info, 'otp_details') && option.crif_info.otp_details && (
                <>
                    <p style={{ fontSize: '14px' }}>
                        OTP Sent by {option.crif_info.otp_details.createdByName} on {option.crif_info.otp_details.otpSentDateFormated}
                    </p>
                </>
            )}

            <span className="text-red">OTP has been expired for {option.mobile}</span>
            <button disabled={option.crif_info.crif_status !== 'otp_expired'}
            type="button"
            className="btn btn-success float-right"

            onClick={() => clickActionResedOTPModal('guarantor', option.id,option, null)} 
        >Resend OTP</button>
     </>
    ) : option.crif_info.crif_status === 'not_verified_yet' ? (
    <>
        {Object.prototype.hasOwnProperty.call(option.crif_info, 'otp_details') && option.crif_info.otp_details && (
            <>
                <p style={{ fontSize: '14px' }}>
                    OTP Sent by {option.crif_info.otp_details.createdByName} on {option.crif_info.otp_details.otpSentDateFormated}
                </p>
            </>
        )}
        
        <span className="text-green">OTP has been sent to {option.mobile}</span>
        <button disabled={option.crif_info.crif_status !== 'not_verified_yet'}
            type="button"
            className="btn btn-warning float-right"
 
            onClick={() => clickActionOTPModal('guarantor', option.id,option)} 
        >Verify OTP to Get Bureau Score</button>
    </>
    ) : (

                    <div className='float-start bg-light my-md-3 p-3 rounded'>
                        <div className="text-success fw-bold mb-1">Crif Score: {option.crif_info.crif_score}</div>
                        <div><strong>Pulled On</strong>: {option.crif_info.date_time} </div>


                        

                        {(Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_remarks') && option.crif_info.crif_remarks !== '') && <> <div><strong>Remarks</strong> : {option.crif_info.crif_remarks}</div></>}

                {(Object.prototype.hasOwnProperty.call(option.crif_info, 'crif_consent_details_text') && option.crif_info.crif_consent_details_text !== '') && <> <div><strong>Consent Details</strong> : {option.crif_info.crif_consent_details_text}</div></>}

                {(Object.prototype.hasOwnProperty.call(option.crif_info, 'createdBy') && option.crif_info.createdBy !== '' && option.crif_info.crif_remarks !== '') && <> <div className="mt-2">Consent taken on {option.crif_info.date_time} by {option.crif_info.createdBy}</div></>}  

                <div>
                    <button type="button" className="btn btn-warning"
                        onClick={() => viewCrifReport('guarantor', option.id,'view')} 
                    >View Crif Report</button>

                    <button type="button" className="btn btn-warning d-none"
                        onClick={() => viewCrifReport('guarantor', option.id,'download')} 
                    >Download Report</button>
                </div>

                    </div>
                    )}
                                </>
                            ) : (<>
                                { props.isCrifUser==='1' && (<>
                                <button disabled={props.leadStatusFlag === true}
                                    type="button"
                                    className="btn btn-warning float-right"
                                    
                                    /* onClick={() => handleCrifAPI('guarantor', option.id)} */

                                    onClick={() => clickActionModal('guarantor', option.id,option)} 
                                >Get Bureau Score</button> 
                                
                                </>)}
                            </>)}
                            
                        </div>

                                               
                                              
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No Guarantor</>)}
                            </>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Bank Statement
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_bank_statements') && leadInfo.data[0].lead_bank_statements.length > 0) ? (
                                    leadInfo.data[0].lead_bank_statements.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5> Bank Statement {index + 1}</h5>
                                            <div className="row">

                                                {(Object.prototype.hasOwnProperty.call(option, 'bank_statement_file') && option.bank_statement_file !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>File Name</strong></div> <div className="col-md-9"> -
                                                    <button className="badge text-bg-success text-white">
                                                        <a target="new" href={downloadBankStatementUrl + option.bank_statement_file} className='text-success text-decoration-none'>
                                                            View Document
                                                        </a>
                                                    </button>
                                                </div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'bank_statement_note') && option.bank_statement_note !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Notes</strong></div> <div className="col-md-9"> - {option.bank_statement_note}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'created_date') && option.created_date !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Created On</strong></div> <div className="col-md-9"> - {option.created_date}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_bank_statements')) && (
                                                    <>
                                                        {Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '' && option.action !== '' ? (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                <div className="col-md-9">
                                                                    <RouterLink onClick={() => handleNoteOpen(option, 'view_note')} >View Note</RouterLink>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                {(props.userRoleType === 'Credit') && (
                                                                    <div className="col-md-9 mt-2">
                                                                        <div className="form-group mb-0">
                                                                            <button
                                                                                className="btn btn-success me-2"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('1', 'bank_statement', option.bank_statement_id)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('2', 'bank_statement', option.bank_statement_id)}
                                                                            >
                                                                                Decline
                                                                            </button>
                                                                        </div>
                                                                    </div>)}
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                <br /><br />
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No Bank Statement</>)}
                            </>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            ITR
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_itrs') && leadInfo.data[0].lead_itrs.length > 0) ? (
                                    leadInfo.data[0].lead_itrs.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5> ITR {index + 1}</h5>
                                            <div className="row">
                                                {(Object.prototype.hasOwnProperty.call(option, 'itr_file') && option.itr_file !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>File Name</strong></div> <div className="col-md-9"> -
                                                    <button className="badge text-bg-success text-white">
                                                        <a target="new" href={downloadItrtUrl + option.itr_file} className='text-success text-decoration-none'>
                                                            View Document
                                                        </a>
                                                    </button>
                                                </div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'itr_note') && option.itr_note !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Notes</strong></div> <div className="col-md-9"> - {option.itr_note}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'created_date') && option.created_date !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Created On</strong></div> <div className="col-md-9"> - {option.created_date}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_itrs')) && (
                                                    <>
                                                        {Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '' && option.action !== '' ? (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                <div className="col-md-9">
                                                                    <RouterLink onClick={() => handleNoteOpen(option, 'view_note')} >View Note</RouterLink>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                {(props.userRoleType === 'Credit') && (
                                                                    <div className="col-md-9 mt-2">
                                                                        <div className="form-group mb-0">
                                                                            <button
                                                                                className="btn btn-success me-2"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('1', 'itr', option.itr_id)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('2', 'itr', option.itr_id)}
                                                                            >
                                                                                Decline
                                                                            </button>
                                                                        </div>
                                                                    </div>)}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No ITR</>)}
                            </>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            GST
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_gsts') && leadInfo.data[0].lead_gsts.length > 0) ? (
                                    leadInfo.data[0].lead_gsts.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5> GST {index + 1}</h5>
                                            <div className="row">
                                                {(Object.prototype.hasOwnProperty.call(option, 'gst_file') && option.gst_file !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>File Name</strong></div> <div className="col-md-9"> -
                                                    <button className="badge text-bg-success text-white">
                                                        <a target="new" href={downloadGsttUrl + option.gst_file} className='text-success text-decoration-none'>
                                                            View Document
                                                        </a>
                                                    </button>
                                                </div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'gst_note') && option.gst_note !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Notes</strong></div> <div className="col-md-9"> - {option.gst_note}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'created_date') && option.created_date !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Created On</strong></div> <div className="col-md-9"> - {option.created_date}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_gsts')) && (
                                                    <>
                                                        {Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '' && option.action !== '' ? (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                <div className="col-md-9">
                                                                    <RouterLink onClick={() => handleNoteOpen(option, 'view_note')} >View Note</RouterLink>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-3 d-md-block d-none">&nbsp;</div>
                                                                {(props.userRoleType === 'Credit') && (
                                                                    <div className="col-md-9 mt-2">
                                                                        <div className="form-group mb-0">
                                                                            <button
                                                                                className="btn btn-success me-2"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('1', 'gst', option.gst_id)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                disabled={props.leadStatusFlag === true}
                                                                                onClick={() => handleAddNoteOpen('2', 'gst', option.gst_id)}
                                                                            >
                                                                                Decline
                                                                            </button>
                                                                        </div>
                                                                    </div>)}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No </>)}
                            </>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            Document
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <>
                                {(Object.prototype.hasOwnProperty.call(leadInfo, 'data') && Object.prototype.hasOwnProperty.call(leadInfo.data[0], 'lead_document') && leadInfo.data[0].lead_document.length > 0) ? (
                                    leadInfo.data[0].lead_document.map((option, index) => (
                                        <div key={index} className="borderBottom mb-2 mt-2">
                                            <h5> Document {index + 1}</h5>
                                            <div className="row">
                                                {(Object.prototype.hasOwnProperty.call(option, 'created_date') && option.created_date !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Created On</strong></div> <div className="col-md-9"> - {option.created_date}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'documentTypeName') && option.documentTypeName !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Document Type</strong></div> <div className="col-md-9"> - {option.documentTypeName}</div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'document') && option.document !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>File</strong></div> <div className="col-md-9"> -
                                                    <button className="badge text-bg-success text-white">
                                                        <a target="new" href={option.document} className='text-success text-decoration-none'>
                                                            View Document
                                                        </a>
                                                    </button>
                                                </div></>}

                                                {(Object.prototype.hasOwnProperty.call(option, 'note') && option.note !== '') && <> <div className="col-md-3 mb-md-2 mb-0 pb-md-1"><strong>Notes</strong></div> <div className="col-md-9"> - {option.note}</div></>}
                                                
                                            </div>
                                        </div>
                                    ))
                                ) : (<>No </>)}
                            </>
                        </div>
                    </div>
                </div>
                </>)}

            </div> 
            <div id="pdf-section" className="d-none"> 
            <PdfDetails /> 
            </div> 
            <Modal
                open={noteOpenModal}
                onClose={handleNoteClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Summary Info</h4>
                    </div>
                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Action :</b></div>
                            <div className='col-7 ps-0'>
                                {viewModalNoteInfo.action === '1' ? 'Accept' : viewModalNoteInfo.action === '2' ? 'Decline' : null}
                            </div>
                        </div>
                    </div>

                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Note :</b></div>
                            <div className='col-7 ps-0'>{viewModalNoteInfo.note}</div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={addNoteOpen}
                onClose={handleAddNoteClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Add Notes</h4>
                        <Button onClick={handleAddNoteClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="" encType="multipart/form-data">
                                <div className="row mx-0">
                                    <div className='col-md-12 px-0'>
                                        <div className='form-group'>
                                            <label htmlFor="note">
                                                <span>Note <a style={{ color: "red" }}>*</a></span>
                                                <textarea
                                                    id='note'
                                                    className='form-control'
                                                    placeholder="Type Something" {...register('note')}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2">Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
 
            <Modal
                open={crifOpenModal}
                onClose={handleCrifClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Remarks</h4>
                        <Button onClick={handleCrifClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <form onSubmit={handleSubmitBureauForm} className="" encType="multipart/form-data">
                        <div className='modal-body'>
                            <div className='scroll-content pe-3'>
                                <div className="row">
                                    <div className="col-md-6" >
                                        <div className='form-group'>
                                            <label htmlFor="lead-profile">
                                                <span>Purpose and Deployment of Vehicle <a style={{ color: "red" }}>*</a></span>
                                                <select
                                                    id='purpose_deployment_vehicle'
                                                    name='purpose_deployment_vehicle'
                                                    className="form-select" 
                                                    value={formRemarkData.purpose_deployment_vehicle}
                                                    onChange={handleChangeBureauForm} 
                                                >
                                                    <option value=''>Please Select</option>
                                                    <option value='Porter'>Porter</option>
                                                    <option value='Uncle D'>Uncle D</option>
                                                    <option value='Captive Use'>Captive Use</option>
                                                    <option value='Passenger'>Passenger</option>
                                                    <option value='Contract Letter And Details'>Contract Letter And Details</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >&nbsp;</div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor="note">
                                                <span>Remarks <a style={{ color: "red" }}>*</a></span>
                                                <textarea
                                                    id='remarks'
                                                    className='form-control'
                                                    placeholder="Type Something" 
                                                    name="remarks" 
                                                    value={formRemarkData.remarks} 
                                                    onChange={handleChangeBureauForm}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </label>
                                            <small className="d-block text-end note">Minimum 20 Words</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >
                                        <div className='form-group'>
                                            <label htmlFor="lead-profile">
                                                <span>Investor Profile <a style={{ color: "red" }}>*</a></span>
                                                <select
                                                    id='investor_profile'
                                                    name='investor_profile'
                                                    className="form-select" 
                                                    value={formRemarkData.investor_profile}
                                                    onChange={handleChangeBureauForm} 
                                                >
                                                    <option value=''>Please Select</option>
                                                    <option value='YES'>YES</option>
                                                    <option value='NO'>NO</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >&nbsp;</div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                        <div className="form-check- form-switch-"> 
                                                <input
                                                    className="form-check-input-"
                                                    type="checkbox" 
                                                    id="consentDetailsStatus"
                                                    name="consentDetailsStatus"
                                                    checked={isConsentDetails} 
                                                    onChange={handleConsentDetails}
                                                /> &nbsp; <span>Consent Taken<a style={{ color: "red" }}>*</a></span> 
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor="note">
                                                <span>Consent Details <a style={{ color: "red" }}>*</a></span>
                                                <textarea
                                                    id='consentDetails'
                                                    className='form-control'
                                                    placeholder="Type Something" 
                                                    name="consentDetails" 
                                                    value={formRemarkData.consentDetails} 
                                                    onChange={handleChangeBureauForm}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label htmlFor="dob">
                                            <span>Date Of Birth <a style={{ color: "red" }}>*</a></span>
                                            <input type="date" 
                                            id="dob" 
                                            name="dob" 
                                            className="form-control" 
                                            value={formRemarkData.dob}
                                            onChange={handleChangeBureauForm}
                                            disabled={props.isExternalUser === true}
                                            />
                                        </label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="full_address">
                                                <span>Address <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='text'
                                                    id='full_address'
                                                    name='full_address'
                                                    className='form-control' 
                                                    value={formRemarkData.full_address}
                                                    onChange={handleChangeBureauForm}
                                                    disabled={props.isExternalUser === true}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="city">
                                                <span>City <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='text'
                                                    id='city'
                                                    name='city'
                                                    className='form-control' 
                                                    value={formRemarkData.city}
                                                    onChange={handleChangeBureauForm}
                                                    disabled={props.isExternalUser === true} 
                                                />
                                            </label>
                                        </div>
                                    </div> 

                                    <div className="col-md-6" >
                                        <div className='form-group'>
                                            <label htmlFor="lead-profile">
                                                <span>State <a style={{ color: "red" }}>*</a></span>
                                                <select
                                                    id='state'
                                                    name='state'
                                                    className="form-select" 
                                                    value={formRemarkData.state}
                                                    onChange={handleChangeBureauForm}
                                                    disabled={props.isExternalUser === true} 
                                                >
                                                    <option value=''>Select State</option>
                                                    {allStates.length > 0 && allStates.map((option) => (
                                                        <option value={option.state_code}>
                                                            {option.state_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="pin">
                                                <span>Pin <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='text'
                                                    id='pin'
                                                    name='pin'
                                                    className='form-control' 
                                                    value={formRemarkData.pin}
                                                    onChange={handleChangeBureauForm}
                                                    disabled={props.isExternalUser === true} 
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="pin">
                                                <span>Pan Number <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='text'
                                                    id='pan_number'
                                                    name='pan_number'
                                                    className='form-control' 
                                                    value={formRemarkData.pan_number}
                                                    onChange={handleChangeBureauForm}
                                                    disabled={props.isExternalUser === true} 
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
    
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <button className="btn btn-primary mt-2" disabled={!isConsentDetails}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>


            <Modal
                open={requiredQuestionOpenModal}
                onClose={handleRequiredQuestionClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Please answer the following required questions to proceed</h4>
                        <Button onClick={handleRequiredQuestionClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>

                    <div className='modal-body checklist-popup'>
                        <div className='scroll-content'>
                            {viewModalInfo.map((item, index) => (
                                <div key={index} className='border bg-lighter p-2 rounded mb-3 checklist-row'>
                                    <div className='row p-1'>
                                        <div className='col-4'><span><b>Checklist Name:</b></span></div>
                                        <div className='col-8 ps-0 mb-2'><span>{item.checkListName}</span></div>

                                        <div className='col-4'>
                                            <span><b>Required Questions:</b></span>
                                        </div>
                                        <div className='col-8 ps-0'>
                                            {item.requiredQuestions && item.requiredQuestions.length > 0 ? (
                                                item.requiredQuestions.map((question, requiredQuestionsIndex) => (
                                                <small className="mb-2 d-block d-flex text-danger" key={requiredQuestionsIndex}><small className="mt-2px">{requiredQuestionsIndex+1}.&nbsp;</small>{question}</small>
                                                ))
                                            ) : (
                                                <p>No required questions available</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openViewScoreCard}
                onClose={handleViewScoreCardClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Score Card</h4>
                        <Button onClick={handleViewScoreCardClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>

                    <div className='modal-body'>
                        <div className='scroll-content'>
                            
                            <div className="row justify-content-between">
                                <div className="col-md-12 text-end d--none">
                                    <div className="form-group mb-3">
                                        <span>Last answers given by : {lastUpdateBy}</span>
                                        <span>&nbsp;|&nbsp;</span>
                                        <span>Time : {lastUpdateDate}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={creditScoreCard === "0" ? "table-wrap mx-0" : " table-wrap mx-0 d-none"}>
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allLeadScore && allLeadScore.length > 0 ? (
                                            allLeadScore.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.questionText}</td>
                                                    <td>{item.userAnswers}</td>
                                                    <td>{item.score}</td>
                                                </tr>
                                            ))
                                        ) : (<p className="mt-3">No score found.</p>)}

                                        <tr className={allLeadScore && allLeadScore.length === 0 ? 'hide ' : 'show'}>
                                            <th colSpan={3} className="text-end border-bottom-0">
                                                <h6 className="fw-bold pt-2 mb-0 mt-1">Total Score: {leadTotalScore}</h6>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className={creditScoreCard === "1" ? "table-wrap mx-0" : " table-wrap mx-0 d-none"}>
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Old Answer</th>
                                            <th>Old Score</th>
                                            <th>New Answer</th>
                                            <th>New Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCreditLeadScore && allCreditLeadScore.length > 0 ? (
                                            allCreditLeadScore.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.questionText}</td>
                                                    <td>{item.old_userAnswers}</td>
                                                    <td>{item.lead_score}</td>
                                                    <td>{item.new_userAnswers}</td>
                                                    <td>{item.credit_lead_score}</td>
                                                </tr>
                                            ))
                                        ) : (<p className="mt-3">No score found.</p>)}

                                        <tr className={allCreditLeadScore && allCreditLeadScore.length === 0 ? 'hide ' : 'show'}>
                                            <th colSpan={5} className="text-end border-bottom-0">
                                                <h6 className="fw-bold pt-2 mb-0 mt-1">Total Score: {leadCreditTotalScore}</h6>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                open={crifOpenOTPModal}
                onClose={handleCrifOTPClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Verify OTP</h4>
                        <Button onClick={handleCrifOTPClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <form onSubmit={handleSubmitOTPForm} className="" encType="multipart/form-data">
                        <div className='modal-body'>
                            <div className='scroll-content pe-3'>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label htmlFor="otp">
                                            <span>OTP <a style={{ color: "red" }}>*</a></span>
                                            <input type="number" 
                                            id="otp" 
                                            name="otp" 
                                            className="form-control" 
                                            value={formOTPData.otp}
                                            onChange={handleChangeBureauOTPForm} 
                                            />
                                        </label>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <button className="btn btn-primary mt-2">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>

    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        addSummaryInfo:state.addSummaryInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryInfo);