
import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { Button, Typography, Modal, Container, Box, Tooltip, IconButton, Divider} from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as leadActions from "../../actions/leadActions";
import * as moduleAction from "../../actions/moduleAction";
import Loader from "../../components/Loader";
import * as masterAction from "../../actions/masterAction";
import TaskForm from './TaskForm';
import Iconify from '../../components/iconify';

const ModuleTaskPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, fetchModule, updateParentState, userRoleType, allIncidentTask, overrideRequestFlow, isEditableIncidentCurrentStatus, isEditableStatus, loggedInUserId, getIncidentTaskId } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const [incidentTaskId, setIncidentTaskId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [incidentId, setIncidentId] = useState('');

    const [getAllChildsData, setAllChildsData] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [assignTo, setAssignTo] = useState("");

    const formRef = useRef(null);

    const [selectedIncidentTaskFiles, setSelectedIncidentTaskFiles] = useState([]);
    const [preloaderStatus, setPreloaderStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [viewModalInfo, setViewModalInfo] = useState([]);

    const [allGroupData, setAllGroupData] = useState([]);
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [getAllUsersListData, setAllUsersListData] = useState([]);

    const [taskActivity, setTaskActivity] = useState([]);
    const [taskActivityCount, setTaskActivityCount] = useState(0);

    const [allApprovalMaster, setAllApprovalMaster] = useState([]);
    const [selectedApprovalMaster, setSelectedApprovalMaster] = useState("");
    const [selectedApprovalMasterUser, setSelectedApprovalMasterUser] = useState([]);
    const [fieldsShow, setFieldsShow] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [backSequence, setBackSequence] = useState("");
    const [presentSequence, setPresentSequence] = useState("");
    const [isLastSequence, setIsLastSequence] = useState(false);

    const [openWorkflowMaster, setOpenWorkflowMaster] = useState(false);
    const [openWorkflowMasterView, setOpenWorkflowMasterView] = useState(false);
    const [workflowMaster, setWorkflowMaster] = useState([]);
    const [formEntries, setFormEntries] = useState([{ role: '', users: [], selectedUsers: [], sequence: '' }]);
    const [selectedRole, setSelectedRole] = useState('');
    const [allRoles, setAllRoles] = useState([]);

    const [isNewFormOpen, setIsNewFormOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null);
    const [shouldReset, setShouldReset] = useState(false);
    const [activeAccordionItem, setActiveAccordionItem] = useState(null);
    
    const handleAccordionClick = (index) => {
        setIsNewFormOpen(false);
        setSelectedIncidentTaskFiles([]);

        if (activeAccordionItem === index) {
            setActiveAccordionItem(null);
            setActiveIndex(null);
        } else {
            setActiveAccordionItem(index);
            setActiveIndex(index);
        }
    };

    const initialFormValues = {
        task_name: '',
        assigned_group: '',
        assigned_to: '',
        due_date: '',
        is_completed: '',
        note: '',
        upload_file: '',
        approval_master_id: '',
        approval_user: '',
    };
    const [formValues, setFormValues] = useState(initialFormValues);

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [taskCreatorUser, setTaskCreatorUser] = useState('');

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved - getLoggedinUserChild:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                setAllChildsData(results);
            }
        }).catch((error) => {
            //  console.error("Promise rejected - getLoggedinUserChild:", error); 
        });
    }, [setAllChildsData]);

    useEffect(() => {
        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'approval_workflow_for': "2" };
        const requestData = encryptData(params);
        const response = actions.getApprovalMaster(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getApprovalMaster:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                setAllApprovalMaster(filteredResultArr);
            } else {
                setPreloaderStatus(false);
                setAllApprovalMaster([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getApprovalMaster:", error);
        });
    }, [setAllApprovalMaster]);

    const handleChangeAssignedGroup = (event, actionName = '') => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {

            setSelectedAssignedGroup(selectedValue);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id": selectedValue, "tag_name": "get_all_group_user" };
            const requestData = encryptData(obj);

            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);
                    }
                    setSelectedAssignedGroup(selectedValue);
                    if (actionName !== 'selectedUser') {
                        setValue("assigned_user", "");
                    }
                }
            }).catch((error) => {
                console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
            setSelectedAssignedGroup("");
        }
    };

    const handleChangeIsCompleted = (event) => {
        const { name, value } = event.target;
        if (name === 'is_completedd') {
            setIsCompleted(event.target.checked);
        }
    };

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  groupData list:", result);
            if (result.data.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.group_name.localeCompare(b.group_name)
                );
                setAllGroupData(sortedResultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });

    }, [setAllGroupData]);

    const handleChangeAssignTo = (event) => {
        const selectedValue = event.target.value;
        setAssignTo(selectedValue);
    }

    const handleChangeSequence = (event) => {
        const selectedValue = event.target.value;
        setBackSequence(selectedValue);
    }


    const handleChangeApprovalMaster = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {
            setFieldsShow(true);
            setSelectedApprovalMaster(selectedValue);

            const approvalMaster = allApprovalMaster.find(master => master.id === selectedValue);

            if (approvalMaster) {
                const approvalData = approvalMaster.approvalData;
                const sortedApprovalMasterUser = [...approvalData].sort((a, b) => a.sequence - b.sequence);
                setSelectedApprovalMasterUser(sortedApprovalMasterUser);
            }
        } else {
            setSelectedApprovalMasterUser([]);
            setSelectedApprovalMaster("");
            setFieldsShow(false);
        }
    };

    const onSubmit = (data, event) => {

        const action = event.nativeEvent.submitter.value;
        if (action === "submit") {
            handleFormSubmit(data);
        } else if (action === "proceed_next") {
            handleProceedToNextStep(data, action);
        } else if (action === "proceed_back") {
            handleProceedToNextStep(data, action);
        }
    };

    const handleFormSubmit = (data) => {

        if (selectedApprovalMaster.trim() === "") {
            toast.error(`${config.MODULE_SUB_TYPE_NAME} list is blank.`, {
                position: "top-right",
            });
        } else if (formValues.due_date.trim() === "" || formValues.due_date === '0000-00-00') {
            toast.error("Due date is blank.", {
                position: "top-right",
            });
        } else if (incidentTaskId !== '' && formValues.note === '') {
            toast.error("Comment is blank.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);

            const getIncidentReportId = props.getIncidentReportId;
            setIncidentId(getIncidentReportId);

            let params = {
                "incident_id": getIncidentReportId,
                "task_name": formValues.task_name,
                "assigned_group": selectedAssignedGroup,
                "assigned_to": assignTo,
                "due_date": formValues.due_date,
                "is_completed": (isCompleted ? '1' : '0'),
                "note": formValues.note,
                "upload_file": selectedIncidentTaskFiles,
                "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                "approval_master_id": selectedApprovalMaster,
                "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
            };

            if (incidentTaskId !== '') {
                params = {
                    "incident_task_id": incidentTaskId,
                    "incident_id": getIncidentReportId,
                    "task_name": formValues.task_name,
                    "assigned_group": selectedAssignedGroup,
                    "assigned_to": assignTo,
                    "due_date": formValues.due_date,
                    "is_completed": (isCompleted ? '1' : '0'),
                    "note": formValues.note,
                    "upload_file": selectedIncidentTaskFiles,
                    "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                    "approval_master_id": selectedApprovalMaster,
                    "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
                };
            }
            const requestData = encryptData(params);
            const response = actions.createModuleTask(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {

                    setIncidentTaskId("");
                    fetchModule();

                    setFormValues(initialFormValues);
                    setSelectedAssignedGroup('');
                    setAssignTo('');
                    setIsCompleted(false);
                    setSelectedIncidentTaskFiles([]);

                    setFieldsShow(false);
                    setSelectedApprovalMaster('');
                    setSelectedApprovalMasterUser([]);

                    reset();
                    setIsStart(false);
                    setShowDiv(false);
                    setPresentSequence("");
                    setIsNewFormOpen(false);
                    setActiveIndex(null);
                    setTaskCreatorUser("");
                    handleNewButton();

                    setShouldReset(true);

                    setPreloaderStatus(false);
                    
                    toast.success(result.data.message, {
                        position: "top-right",
                    });

                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleProceedToNextStep = (data, action) => {

        if (selectedApprovalMaster.trim() === "" && formValues.task_name.trim() === "") {
            toast.error(`${config.MODULE_SUB_TYPE_NAME} name is blank.`, {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && selectedAssignedGroup.trim() === "") {
            toast.error("Assigned group is blank.", {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && assignTo.trim() === "") {
            toast.error("Assigned to is blank.", {
                position: "top-right",
            });
        } else if (formValues.due_date.trim() === "" || formValues.due_date === '0000-00-00') {
            toast.error("Due date is blank.", {
                position: "top-right",
            });
        } else if (action === "proceed_back" && backSequence.trim() === "") {
            toast.error("Please select send back to.", {
                position: "top-right",
            });
        } else if (incidentTaskId !== '' && formValues.note === '') {
            toast.error("Comment is blank.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);

            const getIncidentReportId = props.getIncidentReportId;
            setIncidentId(getIncidentReportId);

            let params = {};

            if (incidentTaskId !== '') {
                params = {
                    "incident_task_id": incidentTaskId,
                    "incident_id": getIncidentReportId,
                    "task_name": formValues.task_name,
                    "assigned_group": selectedAssignedGroup,
                    "assigned_to": assignTo,
                    "due_date": formValues.due_date,
                    "is_completed": (isCompleted ? '1' : '0'),
                    "note": formValues.note,
                    "upload_file": selectedIncidentTaskFiles,
                    "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                    "approval_master_id": selectedApprovalMaster,
                    "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
                    "action": action,
                    "sequence": backSequence,
                };
            }

            const requestData = encryptData(params);
            const response = actions.moduleTaskActivity(requestData, navigate);
            response.then((result) => {
                if (result.data.success === 1) {

                    setIncidentTaskId("");
                    fetchModule();

                    setFormValues(initialFormValues);
                    setSelectedAssignedGroup('');
                    setAssignTo('');
                    setIsCompleted(false);
                    setSelectedIncidentTaskFiles([]);

                    setFieldsShow(false);
                    setSelectedApprovalMaster('');
                    setSelectedApprovalMasterUser([]);

                    reset();
                    setIsStart(false);
                    setShowDiv(false);
                    setPresentSequence("");
                    setIsNewFormOpen(false);
                    setActiveIndex(null);
                    setTaskCreatorUser("");

                    handleNewButton();
                    setShouldReset(true);

                    setPreloaderStatus(false);

                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(e.target.name === 'note'){
            formValues.note = e.target.value;
        }
        if(e.target.name === 'due_date'){
            formValues.due_date = e.target.value;
        }
        setFormValues({
            ...formValues,
            [name]: value,
        });

    };
    

    const handleFileChange = (e) => {
        if (!e || !e.target || !e.target.files) {
            setSelectedIncidentTaskFiles([]);
            return;
        }
    
        const files = Array.from(e.target.files);
        const base64Files = [];
    
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
    
                const parts = base64Data.split(',');
                const base64WithoutHeader = parts[1];
    
                const extension = fileName.split('.').pop().toLowerCase();
                if (extension === 'docx' || extension === 'doc') {
                    base64Files.push({ data: `data:application/document;base64,${base64WithoutHeader}`, name: fileName });
                } else if (extension === 'xlsx' || extension === 'xls') {
                    base64Files.push({ data: `data:application/sheet;base64,${base64WithoutHeader}`, name: fileName });
                } else {
                    base64Files.push({ data: base64Data, name: fileName });
                }
    
                if (base64Files.length === files.length) {
                    // Now, base64Files contains both data and name for each file

                    console.log("base64Files : ", base64Files);

                    setSelectedIncidentTaskFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allIncidentTask.filter(item =>
        item.task_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
    const endIndex = startIndex + config.ITEMS_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

    const onRowClickAction = (itemObj, btnAction) => {
        
        if (btnAction === 'edit') {
            
            setSelectedAssignedGroup("");
            setAssignTo("");

            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            setIncidentTaskId(itemObj.id);

            const initialFormValues = {
                task_name: itemObj.task_name,
                assigned_group: itemObj.assigned_group,
                assigned_to: itemObj.assigned_to,
                due_date: itemObj.due_date,
                is_completed: itemObj.is_completed,
                // note: itemObj.note,
                note: "",
            };
            setFormValues(initialFormValues);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'assigned_group') && itemObj.assigned_group) {
                handleChangeAssignedGroup({ target: { value: itemObj.assigned_group } });
                setSelectedAssignedGroup(itemObj.assigned_group);
            }

            if (Object.prototype.hasOwnProperty.call(itemObj, 'assigned_to') && itemObj.assigned_to) {
                handleChangeAssignTo({ target: { value: itemObj.assigned_to } });
                setAssignTo(itemObj.assigned_to);
            }

            handleChangeApprovalMaster({ target: { value: itemObj.approval_master_id } });
            setSelectedApprovalMaster(itemObj.approval_master_id);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_completed') && itemObj.is_completed) {
                let approvalUsers = [];
                // Check if itemObj.approval_user is defined and not empty
                if (itemObj.approval_user !== undefined && itemObj.approval_user !== null) {
                    if (typeof itemObj.approval_user === 'string' && itemObj.approval_user.trim() !== '') {
                        try {
                            approvalUsers = typeof itemObj.approval_user === 'string'
                                ? JSON.parse(itemObj.approval_user)
                                : Array.isArray(itemObj.approval_user)
                                    ? itemObj.approval_user
                                    : [];
                        } catch (e) {
                            approvalUsers = [];
                        }
                    }
                }
                setSelectedApprovalMasterUser(approvalUsers);
            }

            handleChangeIsCompleted({ target: { value: itemObj.is_completed } });
            setSelectedIncidentTaskFiles([]);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_completed') && itemObj.is_completed) {
                const isCompletedVal = itemObj.is_completed;

                if (isCompletedVal === 1 || isCompletedVal === "1") {
                    setIsCompleted(true);
                } else {
                    setIsCompleted(false);
                }
            }

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_start') && itemObj.is_start) {

                const isStartVal = itemObj.is_start;

                if (isStartVal === 1 || isStartVal === "1") {
                    setIsStart(true);
                } else {
                    setIsStart(false);
                }
            }
            setShowDiv(false);

            setPresentSequence(itemObj.present_sequence);

            setTaskCreatorUser(itemObj.created_by);

        } else if (btnAction === 'view') {

            setViewModalInfo(itemObj);

            if (itemObj.incidentTaskActivity && Array.isArray(itemObj.incidentTaskActivity) && itemObj.incidentTaskActivity.length > 0) {
                setTaskActivity(itemObj.incidentTaskActivity);
                setTaskActivityCount(itemObj.incidentTaskActivity.length);
            } else {
                setTaskActivity([]);
                setTaskActivityCount(0);
            }
            setOpen(true);
        } else if (btnAction === 'delete') {
            setShowDeleteConfirm(true);
            setViewModalInfo(itemObj);
        }
    }

    // Function to handle deletion confirmation
    const handleDeleteConfirm = (itemObj) => {
        if (itemObj) {
            setShowDeleteConfirm(false);
            setPreloaderStatus(true);
            setViewModalInfo([]);

            const taskParams = {
                "task_id": itemObj.id,
                "incident_id": itemObj.incident_id
            };
            const requestData = encryptData(taskParams);
            const response = actions.moduleTaskDelete(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setIncidentTaskId("");
                    fetchModule();
                    reset();
                    setActiveIndex(null);
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    // Function to close the delete confirmation modal
    const handleCloseDeleteConfirm = () => {
        setShowDeleteConfirm(false);
        setViewModalInfo([]);
    };


    const handleNewButton = () => {

        setActiveIndex(null);
        setIsNewFormOpen(true);
        
        setFormValues(initialFormValues);
        setSelectedAssignedGroup('');
        setAssignTo('');
        setIsCompleted(false);
        setSelectedIncidentTaskFiles([]);

        setFieldsShow(false);
        setSelectedApprovalMaster('');
        setSelectedApprovalMasterUser([]);

        setIncidentTaskId("");

        reset();
        setIsStart(false);
        setShowDiv(false);
        setPresentSequence("");
        setTaskCreatorUser("");

        setActiveIndex(null);
    }

    const handleClose = () => {
        setOpen(false);
        setTaskActivity([]);
        setTaskActivityCount(0);
    };

    const toggleDiv = () => {
        setShowDiv(true);
    };

    const getLastSequenceValue = (data) => {
        if (data.length > 0) {
            const sequences = data.map(item => parseInt(item.sequence, 10));
            return Math.max(...sequences);
        }
        return null;
    };

    useEffect(() => {
        const lastSequenceValue = getLastSequenceValue(selectedApprovalMasterUser);
        const presentSequenceInt = parseInt(presentSequence, 10);

        if (!Number.isNaN(lastSequenceValue) && !Number.isNaN(presentSequenceInt) && lastSequenceValue === presentSequenceInt) {
            setIsLastSequence(true);
        } else {
            setIsLastSequence(false);
        }


        const populatedFormEntries = selectedApprovalMasterUser.map(entry => ({
            role: entry.role,
            users: [], // We'll populate users separately
            selectedUsers: entry.assign_to.map(user => ({
                key: user.key,
                value: user.value
            })),
            sequence: entry.sequence
        }));
        setFormEntries(populatedFormEntries);

        populatedFormEntries.forEach((entry, index) => {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": entry.role };
            const requestData = encryptData(obj);
            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => ({
                        id: item.user_id,
                        name: item.name
                    }));
                    const resultArr = Object.values(extractedData);

                    setFormEntries(prevFormEntries => {
                        const updatedFormEntries = [...prevFormEntries];
                        updatedFormEntries[index].users = resultArr;
                        return updatedFormEntries;
                    });
                }
            });
        });

    }, [selectedApprovalMasterUser, presentSequence, setIsLastSequence, setFormEntries]);

    const onChangeApprovalWorkflow = (itemObj, btnAction) => {

        if(btnAction === "edit"){
            // setWorkflowMaster(itemObj);

            const populatedFormEntries = itemObj.map(entry => ({
                role: entry.role,
                users: [], // We'll populate users separately
                selectedUsers: entry.assign_to.map(user => ({
                    key: user.key,
                    value: user.value
                })),
                sequence: entry.sequence
            }));
            setFormEntries(populatedFormEntries);

            populatedFormEntries.forEach((entry, index) => {
                const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": entry.role };
                const requestData = encryptData(obj);
                const response = actions.getUserByRole(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const results = result.data.data.data;
                        const extractedData = results.map(item => ({
                            id: item.user_id,
                            name: item.name
                        }));
                        const resultArr = Object.values(extractedData);

                        setFormEntries(prevFormEntries => {
                            const updatedFormEntries = [...prevFormEntries];
                            updatedFormEntries[index].users = resultArr;
                            return updatedFormEntries;
                        });
                    }
                });
            });

            setSelectedIncidentTaskFiles([]);

            setOpenWorkflowMaster(true);
        } else if(btnAction === "view"){
            setWorkflowMaster(itemObj);
            setOpenWorkflowMasterView(true);
        }
        
    }

    const handleCloseApprovalWorkflow = () => {
        setWorkflowMaster([]);
        setOpenWorkflowMaster(false);
    }

    const handleCloseApprovalWorkflowView = () => {
        setWorkflowMaster([]);
        setOpenWorkflowMasterView(false);
    }

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.rolesData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  rolesData:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const extractedData = results.map(item => {
                    return { id: item.role_id, name: item.role_name };
                });
                const resultArr = Object.values(extractedData);
                setAllRoles(resultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  rolesData:", error);
        });

    }, [setAllRoles]);

    const handleChangeRole = (index, event) => {
        const selectedValue = event.target.value;
        setSelectedRole(selectedValue);

        const updatedFormEntries = [...formEntries];
        updatedFormEntries[index].role = selectedValue;

        if (selectedValue !== "") {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": selectedValue };
            const requestData = encryptData(obj);

            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => {
                        return { id: item.user_id, name: item.name };
                    });
                    const resultArr = Object.values(extractedData);
                    updatedFormEntries[index].users = resultArr;
                    setFormEntries(updatedFormEntries);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        } else {
            updatedFormEntries[index].users = [];
            setFormEntries(updatedFormEntries);
        }
    };

    useEffect(() => {
        if (formValues && typeof formValues === 'object') {
            const approvalMasterId = formValues.approval_master_id;
            if (approvalMasterId !== undefined && approvalMasterId !== null && approvalMasterId.trim() !== '') {
                setSelectedApprovalMaster(approvalMasterId);
            }
        }
    }, [formValues, setSelectedApprovalMaster]);

    const handleScrollAndSetFormValues = (index, item) => {
        // console.log("item : ", item);
    };

    const executedItemsRef = useRef(new Set());

    useEffect(() => {
    
        paginatedItems.forEach((item, index) => {
            let isActive = '';
            if(!isNewFormOpen){
                const shouldAutoOpen = getIncidentTaskId && getIncidentTaskId.trim() !== "";
                isActive = activeIndex === item.id || (shouldAutoOpen && item.id === getIncidentTaskId);
            }
    
            if (isActive && !executedItemsRef.current.has(item.id)) {
                executedItemsRef.current.add(item.id); // Mark this item as executed
    
                setTimeout(() => {
                    // Call onRowClickAction and handleAccordionClick
                    item.note = "";
                    console.log("item : ", item);

                    onRowClickAction(item, 'edit');
                    handleAccordionClick(item.id);
    
                    // Scroll to the row element
                    const rowElement = document.getElementById(`row-${index}`);
                    if (rowElement) {
                        rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
    
                    // Set form values and handle scroll
                    setFormValues(item);
                    handleScrollAndSetFormValues(index, item);
                }, 0);
            }
        });

    }, [paginatedItems, activeIndex, getIncidentTaskId, onRowClickAction, handleAccordionClick, setFormValues, handleScrollAndSetFormValues, isNewFormOpen]);

    return (
        <>
            <div>
                <div className='justify-content-between d-none'>
                    <h5 className='fw-bold mb-3'>{config.MODULE_SUB_TYPE_NAME}</h5>
                </div>

                <div className='row justify-content-end'>
                    <div className="col-md-6">&nbsp;</div>
                    <div className='col-md-6 pe-0 ps-lg-5'>
                        <div className='form-group text-end mb-2'>
                            <button className={isEditableIncidentCurrentStatus ? 'disabled btn btn-primary' : 'btn btn-primary'} onClick={handleNewButton}>
                                Add New {config.MODULE_SUB_TYPE_NAME}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Task Name</th>
                                <th>Due Date</th>
                                <th>Assigned To</th>
                                <th>Pending Stage</th>
                                <th>Status</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems.length > 0 ? (
                                paginatedItems
                                    .filter(item => item.status === "0")
                                    .map((item, index) => {

                                        let isActive = '';
                                        if(!isNewFormOpen){
                                            const shouldAutoOpen = getIncidentTaskId && getIncidentTaskId.trim() !== "";
                                            isActive = activeIndex === item.id || (shouldAutoOpen && item.id === getIncidentTaskId);
                                        }

                                        return (
                                            [
                                                <tr key={`row-${index}`} id={`row-${index}`}>
                                                    <td>
                                                        <Tooltip title={activeAccordionItem === item.id ? 'Press here to close the task' : 'Press here to expand the task'}>
                                                            <IconButton color="primary" >
                                                                <button
                                                                    className={isEditableIncidentCurrentStatus ? 'disabled btn btn-link' : 'btn btn-link'}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onRowClickAction(item, 'edit');
                                                                        handleAccordionClick(item.id);

                                                                        const rowElement = document.getElementById(`row-${index}`);
                                                                        if (rowElement) {
                                                                            rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                                        }
                                                                    }}
                                                                    aria-label="Edit task"
                                                                    style={{ background: 'none', border: 'none', padding: 0, color: 'black', textDecoration: 'none', fontWeight: 'bold' }}
                                                                >
                                                                    {activeAccordionItem === item.id ? 'Close' : 'Open'}

                                                                    {/* <i
                                                                    className="bi bi-plus text-dark"
                                                                    role="button"
                                                                    aria-label="Toggle Accordion"
                                                                    tabIndex="0"
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                                            handleAccordionClick(item.id);

                                                                            const rowElement = document.getElementById(`row-${index}`);
                                                                            if (rowElement) {
                                                                                rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                                            }
                                                                        }
                                                                    }}
                                                                /> */}
                                                                </button>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <span className={item.dueDateOver ? "text-danger crif-usage-remark" : "text-dark crif-usage-remark"}>
                                                            {item.task_name || item.approvalMasterTaskName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.dueDateOver ? "text-danger" : "text-dark"}>
                                                            {item.dueDate}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.dueDateOver ? "text-danger" : "text-dark"}>
                                                            {item.assignedTo}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.dueDateOver ? "text-danger" : "text-dark"}>
                                                            {item.roleName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.dueDateOver ? "text-danger" : "text-dark"}>
                                                            {item.statusName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onRowClickAction(item, 'delete');
                                                            }}
                                                            aria-label="Delete task"
                                                            style={{ background: 'none', border: 'none', padding: 0 }}
                                                        >
                                                            <i className="bi bi-trash text-danger" />
                                                        </button>
                                                    </td>
                                                </tr>,
                                                <tr key={`collapse-${item.id}`} className={`accordion-collapse collapse ${isActive ? 'show' : ''}`} id={`collapse${index}`}>
                                                    <td colSpan="7">
                                                        <TaskForm
                                                            formValues={item}
                                                            handleSubmit={handleSubmit}
                                                            onSubmit={onSubmit}
                                                            formRef={formRef}
                                                            handleChange={handleChange}
                                                            handleChangeApprovalMaster={handleChangeApprovalMaster}
                                                            handleChangeAssignedGroup={handleChangeAssignedGroup}
                                                            handleChangeAssignTo={handleChangeAssignTo}
                                                            handleFileChange={handleFileChange}
                                                            handleChangeIsCompleted={handleChangeIsCompleted}
                                                            handleChangeSequence={handleChangeSequence}
                                                            selectedApprovalMaster={selectedApprovalMaster}
                                                            allApprovalMaster={allApprovalMaster}
                                                            selectedAssignedGroup={selectedAssignedGroup}
                                                            allGroupData={allGroupData}
                                                            getAllUsersListData={getAllUsersListData}
                                                            selectedApprovalMasterUser={selectedApprovalMasterUser}
                                                            fieldsShow={fieldsShow}
                                                            showDiv={showDiv}
                                                            isStart={isStart}
                                                            userRoleType={userRoleType}
                                                            isLastSequence={isLastSequence}
                                                            overrideRequestFlow={overrideRequestFlow}
                                                            incidentTaskId={incidentTaskId}
                                                            presentSequence={presentSequence}
                                                            toggleDiv={toggleDiv}
                                                            onChangeApprovalWorkflow={onChangeApprovalWorkflow}
                                                            errors={errors}
                                                            isCompleted={isCompleted}
                                                            assignTo={assignTo}
                                                            loggedInUserId={loggedInUserId}
                                                            taskCreatorUser={taskCreatorUser}
                                                            shouldReset={shouldReset}
                                                        />
                                                    </td>
                                                </tr>
                                            ]
                                        );
                                    })
                            ) : (
                                <tr>
                                    <td colSpan="7">No tasks available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {isNewFormOpen && (
                    <div>
                        <hr style={{ borderColor: 'black' }} /> {/* Horizontal line at the start */}
                        <div>
                            <TaskForm
                                formValues={formValues}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                formRef={formRef}
                                handleChange={handleChange}
                                handleChangeApprovalMaster={handleChangeApprovalMaster}
                                handleChangeAssignedGroup={handleChangeAssignedGroup}
                                handleChangeAssignTo={handleChangeAssignTo}
                                handleFileChange={handleFileChange}
                                handleChangeIsCompleted={handleChangeIsCompleted}
                                handleChangeSequence={handleChangeSequence}
                                selectedApprovalMaster={selectedApprovalMaster}
                                allApprovalMaster={allApprovalMaster}
                                selectedAssignedGroup={selectedAssignedGroup}
                                allGroupData={allGroupData}
                                getAllUsersListData={getAllUsersListData}
                                selectedApprovalMasterUser={selectedApprovalMasterUser}
                                fieldsShow={fieldsShow}
                                showDiv={showDiv}
                                isStart={isStart}
                                userRoleType={userRoleType}
                                isLastSequence={isLastSequence}
                                overrideRequestFlow={overrideRequestFlow}
                                incidentTaskId={incidentTaskId}
                                presentSequence={presentSequence}
                                toggleDiv={toggleDiv}
                                onChangeApprovalWorkflow={onChangeApprovalWorkflow}
                                errors={errors}
                                isCompleted={isCompleted}
                                assignTo={assignTo}
                                loggedInUserId={loggedInUserId}
                                taskCreatorUser={taskCreatorUser}
                            />
                        </div>
                    </div>
                )}
                </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">{config.MODULE_SUB_TYPE_NAME} Information</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Note:</b></div>
                            <div className='col-8 ps-0' style={{ whiteSpace: 'pre-line' }}>
                                {viewModalInfo.note}
                            </div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Files:</b></div>
                            <div className='col-8 ps-0'>
                                {viewModalInfo.upload_file_actual_name ? (
                                    <div className='row ps-0'>
                                        {viewModalInfo.upload_file.split(',').map((file, fileIndex) => {
                                            const trimmedFile = file.trim(); // Trim any leading or trailing whitespace
                                            const actualName = viewModalInfo.upload_file_actual_name.split(',')[fileIndex].trim();
                                            return (
                                                <div key={fileIndex}>
                                                    <div className="row mx-0 bg-white shadow-sm align-items-center p-2 rounded file-download mb-2">
                                                        <div className='col-10 px-0'>
                                                            <p className="mb-0 f-14px text-truncate w-auto">{actualName}</p>
                                                        </div>
                                                        <div className='col-2 down-2 text-end px-0'>
                                                            <a href={trimmedFile} target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
                                                                <i className="bi bi-download">&nbsp;</i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className='col-7 ps-0'>
                                        No File
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>{config.MODULE_SUB_TYPE_NAME} Activity:</b></div>
                            <div className='col-8 ps-0 d-none' style={{ whiteSpace: 'pre-line' }}>
                                {taskActivity && taskActivity.map((item, index) => (
                                    <div key={index} id={index} className='lead-activity-item'>
                                        <div className='ps-0'>
                                            <p className="mb-1"><b>User By:</b> {item.createdBy}</p>
                                            <p className='mb-1'><b>Date:</b> {item.created_date}</p>
                                            <p className='mb-1'><b>Note:</b> {item.note}</p>
                                        </div>

                                        {taskActivity && index > 0 ? (
                                            index === taskActivityCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.statusName}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{taskActivity[index + 1]?.statusName}</strong> to <strong>{item.statusName}</strong></p>
                                            )

                                        ) : (
                                            index === taskActivityCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.statusName}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{taskActivity[index + 1]?.statusName}</strong> to <strong>{item.statusName}</strong></p>
                                            )
                                        )}

                                    </div>
                                ))}
                            </div>

                            <div className="col-8 ps-0">
                                <div className='history-tl-container comment-list ps-4 rounded'>
                                    <ul className='tl'>
                                        {taskActivity && taskActivity.length > 0 ? (
                                            taskActivity.map((item, index) => {
                                                return (
                                                    <li className='tl-item' key={index}>
                                                        <div className="item-title">Status:
                                                            <span className='text-success'>&nbsp;{item.statusName}</span>
                                                        </div>
                                                        {/* <div className="item-title">{item.createdByName}</div> */}
                                                        <div className="item-date">{item.created_date}</div>
                                                        <div className="item-user">{item.createdBy}</div>
                                                        <div style={{ whiteSpace: 'pre-line' }} className="item-detail">{item.note}</div>
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <p>{config.MODULE_SUB_TYPE_NAME} activity not available</p>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                open={openWorkflowMaster}
                onClose={handleCloseApprovalWorkflow}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Approval Workflow</h4>
                        <Button onClick={handleCloseApprovalWorkflow} color="primary" className=' btn'>
                            {/* <i className="bi bi-x">&nbsp;</i> */}
                            <span>Save</span>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className='row mb-2 pb-1'>
                            <div className='col-12 ps-1'>
                                {formEntries.map((entry, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label htmlFor={`role-${index}`}>
                                                    <span>Role</span>
                                                    <select
                                                        name={`role-${index}`}
                                                        className="form-select"
                                                        value={entry.role}
                                                        onChange={(e) => handleChangeRole(index, e)}
                                                    >
                                                        <option value="">Select Role</option>
                                                        {allRoles
                                                            .filter((option) => !formEntries.some((entry) => entry.role === option.id) || option.id === entry.role)
                                                            .map((option) => (
                                                                <option value={option.id} key={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='form-group'>
                                                <span>Assign to <a style={{ color: "red" }}>*</a></span>
                                                <div className="text-dark">
                                                    <Multiselect
                                                        id={`assign_to-${index}`}
                                                        options={entry.users?.map((option) => ({
                                                            key: option.id,
                                                            value: option.name,
                                                        })) || []}
                                                        selectedValues={entry.selectedUsers || []}
                                                        displayValue="value"
                                                        onSelect={(selectedItems) => {
                                                            const updatedFormEntries = [...formEntries];
                                                            updatedFormEntries[index].selectedUsers = selectedItems;
                                                            setFormEntries(updatedFormEntries);
                                                        }}
                                                        onRemove={(selectedItems) => {
                                                            const updatedFormEntries = [...formEntries];
                                                            updatedFormEntries[index].selectedUsers = selectedItems;
                                                            setFormEntries(updatedFormEntries);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-2 d-none'>
                                            <div className='form-group'>
                                                <label htmlFor={`sequence-${index}`}>
                                                    <span>Sequence</span>
                                                    <input
                                                        type='number'
                                                        name={`sequence-${index}`}
                                                        className='form-control'
                                                        value={entry.sequence}
                                                        onChange={(e) => handleChangeSequence(index, e)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openWorkflowMasterView}
                onClose={handleCloseApprovalWorkflowView}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Approval Workflow</h4>
                        <Button onClick={handleCloseApprovalWorkflowView} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {workflowMaster.length > 0 ? (
                            workflowMaster.map(user => (
                                <div key={user.sequence} className='row mb-3'>
                                    <div className='col-md-4'>
                                        <div className='info-label'><strong>Sequence:</strong> {user.sequence}</div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='info-label'><strong>Role:</strong> {user.roleName}</div>
                                            {user.assign_to.map(assignee => (
                                                <div key={assignee.key} className='assignee-item'>
                                                    {assignee.value}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No approval users available.</p>
                        )}
                    </div>
                </div>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal
                open={showDeleteConfirm}
                onClose={handleCloseDeleteConfirm}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >

                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Confirm Delete</h4>
                        <Button onClick={handleCloseDeleteConfirm} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        
                        <div className='row m-1'>

                            <div className='col-md-12'>
                                Are you sure you want to delete this task?
                            </div>

                            <div className='col-md-6 py-3'>
                                <Button variant="secondary" className='btn btn-primary' onClick={handleCloseDeleteConfirm}>
                                    Cancel
                                </Button>
                            </div>
                            <div className='col-md-6 py-3'>
                                <Button variant="danger" className='btn btn-danger' onClick={() => handleDeleteConfirm(viewModalInfo)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {preloaderStatus && <Loader />}

        </>
    );
}

function mapStateToProps(state) {
    return {
        addEditCoApplicant: state.addEditCoApplicant,
        coApplicantData: state.coApplicantData,
        relationShipData: state.relationShipData,
        identifiersData: state.identifiersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(incidentReporteActions.default, leadActions.default, moduleAction.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModuleTaskPage);
