import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const IncidentReportePage = (props) => {
    const { actions, incidentReporteData, leadInfo } = props;
    const { leadId } = useParams();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showListDetails, setShowListDetails] = useState(false);
    const [allIncidentReporteData, setAllIncidentReporteData] = useState([]);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [getLeadId, setLeadId] = useState('');

    const [showLeadDetails, setShowLeadDetails] = useState(false);
    const [getLeadInfo, setLeadInfo] = useState([]);
    const [pageHeading, setPageHeading] = useState('');

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Leads')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);


    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
    
        const leadDecodedId = atob(leadId);
        setLeadId(leadDecodedId);
    
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_id': leadDecodedId };
                const requestData = encryptData(obj);
                actions.leadInfo(requestData, false);
                const timer = setTimeout(() => {
                    if ('data' in leadInfo && leadInfo.data.length > 0) {
                        setShowLeadDetails(true);
                        setLeadInfo(leadInfo.data[0]);
    
                        setPageHeading(`${leadInfo.data[0].name} - ${leadInfo.data[0].mobile}`);
                    }
                }, 500);
        
      }, [setLeadInfo, setPageHeading]);


    useEffect(() => {

        const params = new URLSearchParams(location.search);
        
        setShowListDetails(false);
        if (leadId !== undefined && !showListDetails) {

            const decodedId = atob(leadId);
            setLeadId(decodedId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id" :  decodedId};
            const requestData = encryptData(obj);

            const response = actions.incidentReporteData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data.data;
                    console.log("incidentReporte = ", incidentReporte);
                    setAllIncidentReporteData(incidentReporte);
                    setShowListDetails(true);
                } else {
                    setShowListDetails(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        }

        
    }, [leadId, setLeadId]);

    
    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.role_permission_id));
        if (btnAction === 'edit') {
            const url = `/dashboard/edit-role-permission/${encodedId}`;
            // navigate(url);
            window.open(url, '_self');
        } else {
            setViewModalInfo(itemObj);
            setOpen(true);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = (allIncidentReporteData ?? []).filter(item =>
        item.type_topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);


    const onSubmit = (data) => {
        const params = {
          "incident_reporte_id" : data.incident_reporte_id,
        };

        console.log("params = ", params);
    
        // const requestData = encryptData(params);
        // const response = actions.incidentReportFileDownload(requestData, navigate);
        // response.then((result) => {
        //     // console.log("Promise resolved - incidentReportFileDownload :", result); 
        //     if (result.data.success === 1) {
        //         toast.success(result.data.message, {
        //             position: "top-right",
        //         });
        //     } else {
        //         toast.error(result.data.message, {
        //             position: "top-right",
        //         });
        //     }
        // }).catch((error) => {
        //     toast.error(error, {
        //         position: "top-right",
        //     });
        // });
    };

    return (
        <>
            <Helmet>
                <title> Incident Report | Ckers </title>
            </Helmet>

            <Container>
                {!userHasPermission ? (
                    <>
                        <AccessDeniedWithoutLeftMenu />
                    </>
                ) : (
                    <>
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            {pageHeading}
                        </Typography>
                        
                        <div id='main-wrap' className='mt-3'>
                            <div className='row justify-content-between'>
                                <div className='col-md-8'> 
                                    <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                        All Incident Report/Ticket
                                    </Typography>
                                </div>
                                <div className='col-md-4 pe-0 ps-lg-5'>
                                    <div className="form-group">
                                    &nbsp;&nbsp;
                                        {/* <label htmlFor="search">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                id="search"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </label> */}
                                    </div>
                                </div>
                            </div>
                            <div className='table-wrap'>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Incident Report ID</th>
                                            <th>Created Date</th>
                                            <th>Topic</th>
                                            <th>Priority </th>
                                            <th>Assigned to</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.formatetdID}</td>
                                                <td>{item.created_date}</td>
                                                <td>{item.type_topic}</td>
                                                <td><span
                                                    className={(item.priority_type === '1') ? "badge text-bg-danger text-white" : (item.priority_type === '2') ? "badge text-bg-success text-white" : "badge text-bg-success text-white"}
                                                >{item.priorityType}</span>
                                                </td>
                                                <th>{item.assignedTo}</th>
                                                <td>
                                                <div className="btn-group dropstart" title="Edit">
                                                    <NavLink to={`/dashboard/edit-incident-reporting/${btoa(String(getLeadId))}/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink>
                                                </div>
                                                </td>                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>

                                <nav className='mb-1'>
                                    <ul className="pagination pagination-md justify-content-end">
                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                            <li className="page-item">
                                                <button
                                                    key={page}
                                                    onClick={() => handlePageChange(page)}
                                                    disabled={currentPage === page}
                                                    className="page-link"
                                                >
                                                    {page}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </>
                )}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Incident Report  : {viewModalInfo.type_topic}</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Incident Report ID :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.formatetdID}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Created By :</b></div>
                            <div className='col-7 ps-0'>{viewModalInfo.assignedUser}</div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Incident Report Status :</b></div>
                            <div className='col-7 ps-0'><span className={(viewModalInfo.resolve_status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.resolve_status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
                        </div>
                    </div>
                </div>
            </Modal>


        </>
    );
}

function mapStateToProps(state) {
    return {
        incidentReporteData: state.incidentReporteData,
        getUserModuleData: state.getUserModuleData,
        leadInfo: state.leadInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, incidentReporteActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentReportePage);