 const API_SERVER ="https://lms.ckersfinance.in/ckers_apis/public/";
const config = {
  APP_URL: API_SERVER,
  API_BASE_URL_LOCAL: API_SERVER, // mocked for dev purposes
  API_BASE_URL_LIVE: API_SERVER, // mocked for dev purposes
  API_BASE_URL: API_SERVER, // mocked for dev purposes  
  CryptoJS_API :"ckers#321",
  ENCKEY :"ckers#321",
  HEXKEY : "FgLFXEr1MZl2mEnk",
  ITEMS_PER_PAGE:10,
  HUNDREAD_PER_PAGE:100,
  MAX_RECORDS:5000,
  PUSH_ALERT_COOKIE_SUBS_ID:'pushalert_62552_1_subs_id',
  PUSH_ALERT_COOKIE_SUBS_STATUS:'pushalert_62552_1_subs_status',
  FI_SHEET_CHECK_ID:'15',
  AUTO_LOGOUT_TIME_IN_SECONDS: 86400,
  GOOGLE_MAPS_API_KEY : "AIzaSyBH2fhzJNP5iwdTEx191wcqztPgx4J1j08",
  MODULE_TYPE_NAME : "Request",
  MODULE_SUB_TYPE_NAME : "Task",
  GOOGLE_KEY : "AIzaSyBH2fhzJNP5iwdTEx191wcqztPgx4J1j08",
  GOOGLE_CLIENT_ID : '519042105318-92jfkbugidip8vqq8ii1of3j5iqhmjpg.apps.googleusercontent.com',
};

export default config; 

