// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userInfo: {},
  userParams: {}, 
  isFetching: false,
  userLogin: false, 
  deviceToken: null,
  usersData: {},
  departmentsData:{},
  departmentInfo:{},
  rolesData:{},
  roleInfo:{},
  rolePermissionData:{},
  rolePermissionInfo:{},
  leadsData:{},
  leadInfo:{},
  leadCommunicationInfo:{},
  leadChecklistData:{},
  sourceData:{},
  masterProfileData:{},
  interactionTypeData:{},
  leadStatusData:{},
  createLead: {},
  checklistsData: {},
  leadProfileInfo:{},
  sourceLeadInfo:{},
  userTypesData:{},
  leadCountData:{},
  leadStatusInfo:{},
  campaignData:{},
  campaignInfo:{},
  leadBreakupCountData:{},
  loggedinUserLeadsData:{},
  leadBreakupCountDetailsData:{},
  loggedinUserActivityData:{},
  loggedinUserFollowupLeadsData:{},
  loggedinUserNewLeadsData:{},
  incidentReporteData:{},
  incidentReportStatusData:{},
  incidentCategoryData:{},
  incidentPriorityData:{},
  allTicketData:{},
  viewTicketData:{},
  groupsUsersData:{},
  groupData:{},
  groupInfo:{},
  leadGstData:{},
  leadGstByLeadData:{},
  coApplicantData:{}, 
  guarantorData:{},
  leadItrData: {},
  leadItrByLeadData: {},
  leadBankStatementData: {},
  leadBankStatementByLeadData: {}, 
};
