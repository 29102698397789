import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import AccessDenied from '../common/AccessDenied';

// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LoanBookListingPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, lmsHabileUsage, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');
    
    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allLoanBookData, setAllLoanBookData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Habile Usage')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS};
        const requestData = encryptData(params);
        const response = actions.getLoanBookData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getLoanBookData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.all_loans_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllLoanBookData(resultArr);

            } else {
                setPreloaderStatus(false);
                setAllLoanBookData([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getLoanBookData:", error);
        });

    }, [setAllLoanBookData]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allLoanBookData.filter(item => {
        const customerId = item.customerId?.toLowerCase() || '';
        const loanAccountNo = item.loanAccountNo?.toLowerCase() || ''

        return (
            customerId.includes(searchQuery.toLowerCase()) ||
            loanAccountNo.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    const exportLoanBookData = async () => {
        const table = document.getElementById('exportExcelFile');

        TableToExcel.convert(table, {
            name: 'Loan-Book.xlsx',
            sheet: {
                name: 'Loan-Book',
            },
        });
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Loan Book</title>
            </Helmet>

            <Container>
                <div id='main-wrap' className='mb-4'>

                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                Loan Book
                            </Typography>
                        </div>
                        <div className='col-md-5 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <label htmlFor="search" className='w-100'>
                                    {/* Search : */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Customer ID, Account no .. "
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                                <button className="btn btn-info text-white ms-3" onClick={() => exportLoanBookData()}>
                                    Export
                                </button>
                            </div>
                            <div className="float-left mb-2"><b>Total Records : {allLoanBookData.length}</b></div>
                        </div>
                    </div>
                    <div className='table-wrap table-responsive'>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th className='text-nowrap-'>ग्राहक पहचान सं. Customer ID</th>
                                <th className='text-nowrap-'>ऋण खाता सं. Loan Account no.</th>
                                <th className='text-nowrap-'>उद्यम रजजस्ट्रीकरण प्रमाण पत्र स. /Udyam Registration Certificate No.</th>
                                <th className='text-nowrap-'>Permanent Account No. (PAN) issued by Income tax deptt/ FORM 60 in case of Micro Loan</th>
                                <th className='text-nowrap-'>Any KYC document viz (आधार स.) Aadhar No, Driving Licence no, Passport, Election Card, Ration Card etc</th>
                                <th className='text-nowrap-'>लाभग्राही इकाई का नाम Name of the Beneficiary Unit</th>
                                <th className='text-nowrap-'>पता Address</th>
                                <th className='text-nowrap-'>ज़िला District (deployment location of the assets)</th>
                                <th className='text-nowrap-'>राज्य State (deployment location of the assets)</th>
                                <th className='text-nowrap-'>(पिनकोड) Pincode  (deployment location of the assets)</th>
                                <th className='text-nowrap-'>मंज़ूरी की तिथि  Date of Sanction</th>
                                <th className='text-nowrap-'>ऋण राशि Amount of loan</th>
                                <th className='text-nowrap-'>Remaining Tenure of Loan in months ऋण की शेष अवधि</th>
                                <th className='text-nowrap-'>बकाया राशि Outstanding Amount</th>
                                <th className='text-nowrap-'>Date of Maturity of Loan / ऋण की परिपक्वता तिथि</th>
                                <th className='text-nowrap-'>पिछले दिनों देय Days Past Due(DPD)</th>
                                <th className='text-nowrap-'>ब्याजदर ROI</th>
                                <th className='text-nowrap-'>ऋर्ण का प्रकार (सुरक्षित / असुरक्षित) Type of loan (Secured/Unsecured)</th>
                                <th className='text-nowrap-'>ऋण का उद्देश्य  Purpose of Loan</th>
                                <th className='text-nowrap-'>उद्योग / Industry</th>
                                <th className='text-nowrap-'>इकाई का क्षेत्र( (सूक्ष्म/लघु)Sector of Unit (Micro/Small/ Medium) Medium will be case of on lending only.</th>
                                <th className='text-nowrap-'>प्रवर्तक की श्रेणी (सामान्य/ पिछड़ा वर्ग/ अनुसूचित जाति/ अनुसूचित जनजाति) Promoter Category  (Gen/OBC/SC/ST/Women)</th>
                                <th className='text-nowrap-'>लीथियम ऑयन बैटरी/ Lithium-ion battery (Yes/ No)</th>
                                <th className='text-nowrap-'>आरटीओ / RTO certification completed (Yes/ no)</th>
                                <th className='text-nowrap-'>पंजीकरण संख्या/ Vehicle registration number</th>
                                <th className='text-nowrap-'>Type of electric vehicle financed (2-w / e-rickshaw/ 3-w /4-w)</th>
                                <th className='text-nowrap-'>Model Name</th>
                                <th className='text-nowrap-'>Name of OEM</th>
                                <th className='text-nowrap-'>Date of Disbusrement</th>
                                <th className='text-nowrap-'>No. of Vehicles sanctioned</th>
                                <th className='text-nowrap-'>Warranty details (Months)</th>
                                <th className='text-nowrap-'>Insurance details (Name of insurance company and validity)</th>
                                <th className='text-nowrap-'>Insurance Validity</th>
                                <th className='text-nowrap-'>Branch</th>
                                <th className='text-nowrap-'>Co applicant Name</th>
                                <th className='text-nowrap-'>Guarantor Name</th>
                                <th className='text-nowrap-'>Address Proof</th>
                                <th className='text-nowrap-'>Address Proof No</th>
                                <th className='text-nowrap-'>DOB Applicant</th>
                                <th className='text-nowrap-'>Customer Email ID</th>
                                <th className='text-nowrap-'>Credit Bureau Score</th>
                                <th className='text-nowrap-'>Co- App PAN</th>
                                <th className='text-nowrap-'>identity proof (co applicant)</th>
                                <th className='text-nowrap-'>identity proof no Co applicant</th>
                                <th className='text-nowrap-'>Co-applicant Email ID</th>
                                <th className='text-nowrap-'>Address Proof ( Co-App.)</th>
                                <th className='text-nowrap-'>Address Proof No( Co-App.)</th>
                                <th className='text-nowrap-'>DOBCoapplcant</th>
                                <th className='text-nowrap-'>Chassis No</th>
                                <th className='text-nowrap-'>Registration Date</th>
                                <th className='text-nowrap-'>Asset Cost</th>
                                <th className='text-nowrap-'>LTV</th>
                                <th className='text-nowrap-'>EMI Amount</th>
                                <th className='text-nowrap-'>Repayment Frequency</th>
                                <th className='text-nowrap-'>EMI Mode</th>
                                <th className='text-nowrap-'>EMI Start Date</th>
                                <th className='text-nowrap-'>EMI End Date</th>
                                <th className='text-nowrap-'>Original Tenure</th>
                                <th className='text-nowrap-'>DPD (in days)</th>
                                <th className='text-nowrap-'>Customer IRR (All Inclusive)</th>
                                <th className='text-nowrap-'>Occupation of the Applicant</th>
                                <th className='text-nowrap-'>Sector</th>
                                <th className='text-nowrap-'>Sub Sector</th>
                                <th className='text-nowrap-'>Nature of Business</th>
                                <th className='text-nowrap-'>Business Type (Proprietorship/Partnership/ Pvt Ltd)</th>
                                <th className='text-nowrap-'>Business Entity Name (if applicable)</th>
                                <th className='text-nowrap-'>Manufacturing / Service</th>
                                <th className='text-nowrap-'>Turnover per annum</th>
                                <th className='text-nowrap-'>Vintage of business (M/Y)</th>
                            </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.customerId}</td>
                                        <td>{item.loanAccountNo}</td>
                                        <td>{item.udyamRegistrationCertificateNo}</td>
                                        <td>{item.PermanentAccountNo}</td>
                                        <td>{item.KycDocument}</td>
                                        <td>{item.BeneficiaryUnit}</td>
                                        <td>{item.Address}</td>
                                        <td>{item.District}</td>
                                        <td>{item.State}</td>
                                        <td>{item.Pincode}</td>
                                        <td>{item.DateOfSanction}</td>
                                        <td>{item.AmountOfLoan}</td>
                                        <td>{item.RemainingTenureOfLoanInMonths}</td>
                                        <td>{item.OutstandingAmount}</td>
                                        <td>{item.DateOfMaturityOfLoan}</td>
                                        <td>{item.Dpd}</td>
                                        <td>{item.Roi}</td>
                                        <td>{item.TypeOfLoan}</td>
                                        <td>{item.PurposeOfLoan}</td>
                                        <td>{item.Industry}</td>
                                        <td>{item.SectorOfUnit}</td>
                                        <td>{item.PromoterCategory}</td>
                                        <td>{item.LithiumIonBattery}</td>
                                        <td>{item.RtoCertificationCompleted}</td>
                                        <td>{item.VehicleRegistrationNumber}</td>
                                        <td>{item.TypeOfElectricVehicleFinanced}</td>
                                        <td>{item.ModelName}</td>
                                        <td>{item.NameOfOem}</td>
                                        <td>{item.DateOfDisbusrement}</td>
                                        <td>{item.NoOfVehiclesSanctioned}</td>
                                        <td>{item.WarrantyDetails}</td>
                                        <td>{item.InsuranceDetails}</td>
                                        <td>{item.InsuranceValidity}</td>
                                        <td>{item.Branch}</td>
                                        <td>{item.CoApplicantName}</td>
                                        <td>{item.GuarantorName}</td>
                                        <td>{item.AddressProof}</td>
                                        <td>{item.AddressProofNo}</td>
                                        <td>{item.DobApplicant}</td>
                                        <td>{item.CustomerEmailId}</td>
                                        <td>{item.CreditBureauScore}</td>
                                        <td>{item.CoAppPan}</td>
                                        <td>{item.IdentityProofCoApplicant}</td>
                                        <td>{item.IdentityProofNoCoApplicant}</td>
                                        <td>{item.CoApplicantEmailId}</td>
                                        <td>{item.AddressProofCoApplicant}</td>
                                        <td>{item.AddressProofNoCoApplicant}</td>
                                        <td>{item.DOBCoapplcant}</td>
                                        <td>{item.ChassisNo}</td>
                                        <td>{item.RegistrationDate}</td>
                                        <td>{item.AssetCost}</td>
                                        <td>{item.Ltv}</td>
                                        <td>{item.EmiAmount}</td>
                                        <td>{item.RepaymentFrequency}</td>
                                        <td>{item.EmiMode}</td>
                                        <td>{item.EmiStartDate}</td>
                                        <td>{item.EmiEndDate}</td>
                                        <td>{item.OriginalTenure}</td>
                                        <td>{item.DpdInDays}</td>
                                        <td>{item.CustomerIrr}</td>
                                        <td>{item.OccupationOfTheApplicant}</td>
                                        <td>{item.Sector}</td>
                                        <td>{item.SubSector}</td>
                                        <td>{item.NatureOfBusiness}</td>
                                        <td>{item.BusinessType}</td>
                                        <td>{item.BusinessEntityName}</td>
                                        <td>{item.ManufacturingService}</td>
                                        <td>{item.TurnoverPerAnnum}</td>
                                        <td>{item.VintageOfBusiness}</td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={15} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className='table-wrap table-responsive d-none'>
                        <table className="table table-hover" id="exportExcelFile">
                            <thead>
                            <tr>
                                <th className='text-nowrap-'>ग्राहक पहचान सं. Customer ID</th>
                                <th className='text-nowrap-'>ऋण खाता सं. Loan Account no.</th>
                                <th className='text-nowrap-'>उद्यम रजजस्ट्रीकरण प्रमाण पत्र स. /Udyam Registration Certificate No.</th>
                                <th className='text-nowrap-'>Permanent Account No. (PAN) issued by Income tax deptt/ FORM 60 in case of Micro Loan</th>
                                <th className='text-nowrap-'>Any KYC document viz (आधार स.) Aadhar No, Driving Licence no, Passport, Election Card, Ration Card etc</th>
                                <th className='text-nowrap-'>लाभग्राही इकाई का नाम Name of the Beneficiary Unit</th>
                                <th className='text-nowrap-'>पता Address</th>
                                <th className='text-nowrap-'>ज़िला District (deployment location of the assets)</th>
                                <th className='text-nowrap-'>राज्य State (deployment location of the assets)</th>
                                <th className='text-nowrap-'>(पिनकोड) Pincode  (deployment location of the assets)</th>
                                <th className='text-nowrap-'>मंज़ूरी की तिथि  Date of Sanction</th>
                                <th className='text-nowrap-'>ऋण राशि Amount of loan</th>
                                <th className='text-nowrap-'>Remaining Tenure of Loan in months ऋण की शेष अवधि</th>
                                <th className='text-nowrap-'>बकाया राशि Outstanding Amount</th>
                                <th className='text-nowrap-'>Date of Maturity of Loan / ऋण की परिपक्वता तिथि</th>
                                <th className='text-nowrap-'>पिछले दिनों देय Days Past Due(DPD)</th>
                                <th className='text-nowrap-'>ब्याजदर ROI</th>
                                <th className='text-nowrap-'>ऋर्ण का प्रकार (सुरक्षित / असुरक्षित) Type of loan (Secured/Unsecured)</th>
                                <th className='text-nowrap-'>ऋण का उद्देश्य  Purpose of Loan</th>
                                <th className='text-nowrap-'>उद्योग / Industry</th>
                                <th className='text-nowrap-'>इकाई का क्षेत्र( (सूक्ष्म/लघु)Sector of Unit (Micro/Small/ Medium) Medium will be case of on lending only.</th>
                                <th className='text-nowrap-'>प्रवर्तक की श्रेणी (सामान्य/ पिछड़ा वर्ग/ अनुसूचित जाति/ अनुसूचित जनजाति) Promoter Category  (Gen/OBC/SC/ST/Women)</th>
                                <th className='text-nowrap-'>लीथियम ऑयन बैटरी/ Lithium-ion battery (Yes/ No)</th>
                                <th className='text-nowrap-'>आरटीओ / RTO certification completed (Yes/ no)</th>
                                <th className='text-nowrap-'>पंजीकरण संख्या/ Vehicle registration number</th>
                                <th className='text-nowrap-'>Type of electric vehicle financed (2-w / e-rickshaw/ 3-w /4-w) and Model Name</th>
                                <th className='text-nowrap-'>Name of OEM</th>
                                <th className='text-nowrap-'>Date of Disbusrement</th>
                                <th className='text-nowrap-'>No. of Vehicles sanctioned</th>
                                <th className='text-nowrap-'>Warranty details (Months)</th>
                                <th className='text-nowrap-'>Insurance details (Name of insurance company and validity)</th>
                                <th className='text-nowrap-'>Insurance Validity</th>
                                <th className='text-nowrap-'>Branch</th>
                                <th className='text-nowrap-'>Co applicant Name</th>
                                <th className='text-nowrap-'>Guarantor Name</th>
                                <th className='text-nowrap-'>Address Proof</th>
                                <th className='text-nowrap-'>Address Proof No</th>
                                <th className='text-nowrap-'>DOB Applicant</th>
                                <th className='text-nowrap-'>Customer Email ID</th>
                                <th className='text-nowrap-'>Credit Bureau Score</th>
                                <th className='text-nowrap-'>Co- App PAN</th>
                                <th className='text-nowrap-'>identity proof (co applicant)</th>
                                <th className='text-nowrap-'>identity proof no Co applicant</th>
                                <th className='text-nowrap-'>Co-applicant Email ID</th>
                                <th className='text-nowrap-'>Address Proof ( Co-App.)</th>
                                <th className='text-nowrap-'>Address Proof No( Co-App.)</th>
                                <th className='text-nowrap-'>DOBCoapplcant</th>
                                <th className='text-nowrap-'>Chassis No</th>
                                <th className='text-nowrap-'>Registration Date</th>
                                <th className='text-nowrap-'>Asset Cost</th>
                                <th className='text-nowrap-'>LTV</th>
                                <th className='text-nowrap-'>EMI Amount</th>
                                <th className='text-nowrap-'>Repayment Frequency</th>
                                <th className='text-nowrap-'>EMI Mode</th>
                                <th className='text-nowrap-'>EMI Start Date</th>
                                <th className='text-nowrap-'>EMI End Date</th>
                                <th className='text-nowrap-'>Original Tenure</th>
                                <th className='text-nowrap-'>DPD (in days)</th>
                                <th className='text-nowrap-'>Customer IRR (All Inclusive)</th>
                                <th className='text-nowrap-'>Occupation of the Applicant</th>
                                <th className='text-nowrap-'>Sector</th>
                                <th className='text-nowrap-'>Sub Sector</th>
                                <th className='text-nowrap-'>Nature of Business</th>
                                <th className='text-nowrap-'>Business Type (Proprietorship/Partnership/ Pvt Ltd)</th>
                                <th className='text-nowrap-'>Business Entity Name (if applicable)</th>
                                <th className='text-nowrap-'>Manufacturing / Service</th>
                                <th className='text-nowrap-'>Turnover per annum</th>
                                <th className='text-nowrap-'>Vintage of business (M/Y)</th>
                            </tr>
                            </thead>
                            <tbody>
                                {allLoanBookData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.customerId}</td>
                                        <td>{item.loanAccountNo}</td>
                                        <td>{item.udyamRegistrationCertificateNo}</td>
                                        <td>{item.PermanentAccountNo}</td>
                                        <td>{item.KycDocument}</td>
                                        <td>{item.BeneficiaryUnit}</td>
                                        <td>{item.Address}</td>
                                        <td>{item.District}</td>
                                        <td>{item.State}</td>
                                        <td>{item.Pincode}</td>
                                        <td>{item.DateOfSanction}</td>
                                        <td>{item.AmountOfLoan}</td>
                                        <td>{item.RemainingTenureOfLoanInMonths}</td>
                                        <td>{item.OutstandingAmount}</td>
                                        <td>{item.DateOfMaturityOfLoan}</td>
                                        <td>{item.Dpd}</td>
                                        <td>{item.Roi}</td>
                                        <td>{item.TypeOfLoan}</td>
                                        <td>{item.PurposeOfLoan}</td>
                                        <td>{item.Industry}</td>
                                        <td>{item.SectorOfUnit}</td>
                                        <td>{item.PromoterCategory}</td>
                                        <td>{item.LithiumIonBattery}</td>
                                        <td>{item.RtoCertificationCompleted}</td>
                                        <td>{item.VehicleRegistrationNumber}</td>
                                        <td>{item.TypeOfElectricVehicleFinanced}</td>
                                        <td>{item.NameOfOem}</td>
                                        <td>{item.DateOfDisbusrement}</td>
                                        <td>{item.NoOfVehiclesSanctioned}</td>
                                        <td>{item.WarrantyDetails}</td>
                                        <td>{item.InsuranceDetails}</td>
                                        <td>{item.InsuranceValidity}</td>
                                        <td>{item.Branch}</td>
                                        <td>{item.CoApplicantName}</td>
                                        <td>{item.GuarantorName}</td>
                                        <td>{item.AddressProof}</td>
                                        <td>{item.AddressProofNo}</td>
                                        <td>{item.DobApplicant}</td>
                                        <td>{item.CustomerEmailId}</td>
                                        <td>{item.CreditBureauScore}</td>
                                        <td>{item.CoAppPan}</td>
                                        <td>{item.IdentityProofCoApplicant}</td>
                                        <td>{item.IdentityProofNoCoApplicant}</td>
                                        <td>{item.CoApplicantEmailId}</td>
                                        <td>{item.AddressProofCoApplicant}</td>
                                        <td>{item.AddressProofNoCoApplicant}</td>
                                        <td>{item.DOBCoapplcant}</td>
                                        <td>{item.ChassisNo}</td>
                                        <td>{item.RegistrationDate}</td>
                                        <td>{item.AssetCost}</td>
                                        <td>{item.Ltv}</td>
                                        <td>{item.EmiAmount}</td>
                                        <td>{item.RepaymentFrequency}</td>
                                        <td>{item.EmiMode}</td>
                                        <td>{item.EmiStartDate}</td>
                                        <td>{item.EmiEndDate}</td>
                                        <td>{item.OriginalTenure}</td>
                                        <td>{item.DpdInDays}</td>
                                        <td>{item.CustomerIrr}</td>
                                        <td>{item.OccupationOfTheApplicant}</td>
                                        <td>{item.Sector}</td>
                                        <td>{item.SubSector}</td>
                                        <td>{item.NatureOfBusiness}</td>
                                        <td>{item.BusinessType}</td>
                                        <td>{item.BusinessEntityName}</td>
                                        <td>{item.ManufacturingService}</td>
                                        <td>{item.TurnoverPerAnnum}</td>
                                        <td>{item.VintageOfBusiness}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        lmsHabileUsage: state.lmsHabileUsage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoanBookListingPage);