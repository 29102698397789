import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadProfileCreatePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, leadProfileInfo, checklistsData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [leadProfileId, setLeadProfileId] = useState('');
  const [showLeadProfileDetails, setShowLeadProfileDetails] = useState(false);
  const [allChecklists, setAllChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState([]);
  const [numbersArray, setNumbersArray] = useState([]);
  const isSelected = true;

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [allMasterProfiles, setAllMasterProfiles] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Lead Profile');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);


  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.checklistsData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  checklist data:", result);
      if (result.data.success === 1) {
        const results = result.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        const filteredResultArr = resultArr.filter((item) => item.status === "1");
        const sortedResultArr = filteredResultArr.map(item => {
          return { id: item.id, name: item.check_list_name };
        });
        setAllChecklists(sortedResultArr);
      }
    }).catch((error) => {
      console.error("Promise rejected -  checklist data:", error);
    });
  }, [setAllChecklists]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowLeadProfileDetails(false);
    if (id !== undefined && !showLeadProfileDetails) {
      const decodedId = atob(id);
      setLeadProfileId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_profile_id': decodedId };
      const requestData = encryptData(obj);
      const response = actions.masterProfileData(requestData, false);
      response.then((result) => {
          // console.log("Promise resolved -  master profile data:", result); 
          if (result.data.success === 1) {
            const results = result.data.data;

            setShowLeadProfileDetails(true);
            setValue("lead_profile_name", results.data[0].lead_profile_name);
            // setValue("action_user_id", results.data[0].created_by);
            setValue("status", results.data[0].status);
            setValue("lead_checklist", results.data[0].lead_checklist);

            const leadChecklistString = results.data[0].lead_checklist;
            const leadChecklistArray = leadChecklistString.split(',');
            setSelectedChecklist(leadChecklistArray);

            // setNumbersArray([{'key': 1,'value': 'Checklist 1'},{'key': 3,'value': 'Checklist 2'}]);
            setNumbersArray(results.data[0].selected_checklist);
              
          }
      }).catch((error) => {
          //  console.error("Promise rejected -  source data :", error); 
      });
    }
  }, [id, setValue, setShowLeadProfileDetails, setSelectedChecklist, setNumbersArray]);

  const onSubmit = (data) => {

    const leadCheklist = JSON.stringify(selectedChecklist);
    console.log("selectedChecklist = ", selectedChecklist);
    console.log("leadCheklist = ", leadCheklist);

    let params = { 'lead_profile_name': data.lead_profile_name, 'status': data.status, "lead_checklist": leadCheklist };

    if (leadProfileId !== '') {
      params = { 'lead_profile_name': data.lead_profile_name, 'status': data.status, 'lead_profile_id': leadProfileId, "lead_checklist": leadCheklist };
    }
    // console.log(params);
    const requestData = encryptData(params);
    const response = actions.createLeadProfile(requestData, navigate);
    response.then((result) => { 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            setTimeout(() => {
                navigate(`/dashboard/lead-profile`);
            }, 1000);
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
    }).catch((error) => {
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{showLeadProfileDetails ? "Edit Lead Profile" : "Create Lead Profile"}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showLeadProfileDetails ? "Edit Lead Profile" : "Create Lead Profile"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Lead Profile Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='lead_profile_name' className='form-control'
                      {...register('lead_profile_name', { required: true })} />
                  </label>
                  {errors.lead_profile_name && <span className="required">This field is required.</span>}
                </div>
              </div>

              { /* <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="created-by">
                    <span>Lead Profile Created by</span>
                    <select
                      name="action_user_id"
                      className="form-select"
                      {...register('action_user_id', { required: true })}
                    >
                      <option value="">Select User</option>
                      {allUsers.map((option) => (
                        <option value={option.user_id}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                  </label>
                  {errors.action_user_id && <span className="required">This field is required.</span>}
                </div>
              </div>
              */ }
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Lead Profile Status <a style={{ color: "red" }}>*</a></span>
                    <select name='status' className="form-select" {...register('status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.status && <span className="required">This field is required.</span>}
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <span>Check List</span>
                  <div className="text-dark">
                    <Multiselect
                      id="lead_profile"
                      options={allChecklists.map((option) => ({
                        key: option.id,
                        value: option.name,
                      }))}
                      selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedChecklist(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedChecklist(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    checklistsData: state.checklistsData,
    leadProfileInfo: state.leadProfileInfo,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(departmentActions.default, userActions.default, leadActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadProfileCreatePage);