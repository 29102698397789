import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function incidentGroupDataReducer(state = initialState.groupData, action) {
  switch (action.type) {
    case types.LOAD_INCIDENT_GROUP_DATA_SUCCESS:
      return action.groupData;
    default:
      return state;
  }
}
