import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const CreateCampaignPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, campaignInfo, checklistsData, sourceData, groupsUsersData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [campaignId, setCampaignId] = useState('');
  const [showCampaignDetails, setShowCampaignDetails] = useState(false);
  const [allSourceData, setAllSource] = useState([]);
  const [selectedAssignTo, setSelectedAssignTo] = useState([]);
  const [numbersArray, setNumbersArray] = useState([]);
  const isSelected = true;

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const [allGroupData, setAllGroupData] = useState([]);
  const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
  const [getAllUsersListData, setAllUsersListData] = useState([]);
  const [selectedAssignedUser, setSelectedAssignedUser] = useState('');
  const [selectedSourceOfLead, setSelectedSourceOfLead] = useState('');

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Campaigns');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);


  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);

    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.sourceData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  source data:", result); 
      if (result.data.success === 1) {
        const results = result.data.data.data;
        const resultArr = Object.keys(results).filter((key) => results[key].status === "1").map((key) => results[key]);
        setAllSource(resultArr);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllSource]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.groupData(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved -  groupData list:", result);
      if (result.data.data.success === 1) {
        const groupDataListing = result.data.data.data;
        const resultArr = Object.keys(groupDataListing).filter((key) => groupDataListing[key].status === "1").map((key) => groupDataListing[key]);
        setAllGroupData(resultArr);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });

  }, [setAllGroupData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowCampaignDetails(false);
    if (id !== undefined && !showCampaignDetails) {
      const decodedId = atob(id);
      setCampaignId(decodedId);

      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'campaign_id': decodedId };

      const requestData = encryptData(obj);
      const response = actions.campaignData(requestData, false);
      response.then((result) => {
        // console.log("Promise resolved -  campaignData:", result); 
        if (result.data.success === 1) {
          setShowCampaignDetails(true);

          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);

          setValue("campaign_name", resultArr[0].campaign_name);
          setValue("source_of_lead", resultArr[0].sorce_of_lead);
          setValue("status", resultArr[0].status);

          handleChangeAssignedGroup({ target: { value: resultArr[0].assigned_group } }, 'selectedUser');

          setValue("assigned_group", resultArr[0].assigned_group);
          setSelectedSourceOfLead(resultArr[0].sorce_of_lead);

          if(!resultArr[0].assigned_user && resultArr[0].assigned_user === ''){
            setSelectedAssignedUser("any_user");
          } else {
            setSelectedAssignedUser(resultArr[0].assigned_user);
          }

        }
      }).catch((error) => {
        //  console.error("Promise rejected -  campaignData :", error); 
      });
    }
  }, [id, setValue, setShowCampaignDetails, setSelectedAssignedUser, setSelectedSourceOfLead]);

  const onSubmit = (data) => {

    if (data.campaign_name.trim() === "") {
      toast.error("Campaign Name is blank.", {
        position: "top-right",
      });
    } else if (data.source_of_lead.trim() === "") {
      toast.error("Please select Source Of Lead.", {
        position: "top-right",
      });
    } else if (selectedAssignedGroup === "") {
      toast.error("Please select group.", {
        position: "top-right",
      });
    } else if (selectedAssignedUser=== "") {
      toast.error("Please select user.", {
        position: "top-right",
      });
    } else {
      const params = {
        'campaign_name': data.campaign_name,
        'sorce_of_lead': data.source_of_lead,
        "campaign_status": data.status,
        "assigned_group": selectedAssignedGroup,
        "assigned_user": selectedAssignedUser,
      };

      if (campaignId !== '') {
        params.campaign_id = campaignId;
      }
      
      const requestData = encryptData(params);
      const response = actions.createCampaign(requestData, navigate);
      response.then((result) => {
        // console.log("Promise resolved - add/edit campaign form:", result); 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            setTimeout(() => {
                navigate('/dashboard/campaigns');
          }, 1000);
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
      }).catch((error) => {
          toast.error(error, {
              position: "top-right",
          });
      });
    }
  };

  const handleChangeSourceOfLead = (event, actionName = '') => {
    const selectedValue = event.target.value;
    setSelectedSourceOfLead(selectedValue);
  }

  const handleChangeAssignedGroup = (event, actionName = '') => {
    const selectedValue = event.target.value;
    setSelectedAssignedGroup(selectedValue);

    if (selectedValue !== "") {

      const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id": selectedValue };
      const requestData = encryptData(obj);

      const response = actions.groupsUsersData(requestData);
      response.then((result) => {
        // console.log("Promise resolved -  groupsUsersData list:", result);
        if (result.data.success === 1) {
          const usersListData = result.data.data.data;
          // console.log("usersListData = ", usersListData);
          if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
            const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
            setAllUsersListData(resultArr);
           // setAllUsersListData(usersListData[0].users_list);
          }
          setSelectedAssignedGroup(selectedValue);

          if( actionName !== 'selectedUser' ){
            setValue("assigned_user", "");
          }
        }
      }).catch((error) => {
        // console.error("Promise rejected -  groupsUsersData list:", error);
      });
    } else {
      setAllUsersListData([]);
    }
  };

  const handleAssignedUserChange = (event) => {
    const newValue = event.target.value;
    setSelectedAssignedUser(newValue);
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{showCampaignDetails ? "Edit Campaign" : "Create Campaign"}</title>
      </Helmet>

      <Container>
        <div className="flex-container">
            <Typography variant="h4" sx={{ mb: 5 }}>
              {showCampaignDetails ? "Edit Campaign" : "Create Campaign"}
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://www.loom.com/share/3d9cc97e1fc64792ab7ea34d6b7d135c?sid=adffd62f-8fd9-4056-a903-6d45b32b4610', '_blank')}
            >
                Tutorial  Video
            </Button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor="campaign-name">
                    <span>Campaign Name <a style={{color: "red"}}>*</a></span>
                    <input type='text' id='campaign_name' className='form-control' {...register('campaign_name', { required: true })} />
                  </label>
                  {errors.campaign_name && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="source-of-lead">
                    <span>Source of Lead <a style={{color: "red"}}>*</a></span>
                    <select id='source_of_lead'
                      className="form-select"
                      name='source_of_lead'
                      {...register('source_of_lead')}
                      value={selectedSourceOfLead}
                      onChange={handleChangeSourceOfLead} 
                    >
                      <option value="">Select Source Of Lead</option>
                      {allSourceData.length > 0 && allSourceData.map((option) => (
                        <option value={option.id}>
                          {option.source_of_lead}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>


              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="assigned-group">
                    <span>Assigned group <a style={{color: "red"}}>*</a></span>
                    <select
                      id='assigned_group'
                      name='assigned_group'
                      className="form-select"
                      {...register('assigned_group')}
                      value={selectedAssignedGroup}
                      onChange={handleChangeAssignedGroup}
                    >
                      <option value=''>Select assigned group</option>
                      {allGroupData.map((option) => (
                        <option value={option.id}>
                          {option.group_name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="assigned-user">
                    <span>Assigned to <a style={{color: "red"}}>*</a></span>
                    <select
                      id='assigned_user'
                      name='assigned_user'
                      className="form-select"
                      value={selectedAssignedUser}
                      onChange={handleAssignedUserChange}
                    >
                      <option value=''>Select User</option>
                      {getAllUsersListData.map((option) => (
                        <option value={option.user_id} key={option.user_id}>
                          {option.name}
                        </option>
                      ))}
                      {getAllUsersListData.length > 0 && (
                        <option value='any_user'>All User</option>
                      )}
                    </select>
                  </label>
                </div>
              </div>


              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Campaign Status <a style={{color: "red"}}>*</a></span>
                    <select name='status' className="form-select" {...register('status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    campaignInfo: state.campaignInfo,
    sourceData: state.sourceData,
    getUserModuleData: state.getUserModuleData,
    groupData: state.groupData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default, groupsUsersActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignPage);