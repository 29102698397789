import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function departmentReducer(state = initialState.departmentsData, action) {
  switch (action.type) {
    case types.LOAD_DEPARTMENTS_DATA_SUCCESS:
      return action.departmentsData;
    default:
      return state;
  }
}
