import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { Link as RouterLink , useNavigate,useParams ,useLocation} from 'react-router-dom';
import DataTable from 'react-data-table-component'; 
import Chart from 'react-apexcharts';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { PieChart, Pie, Tooltip, BarChart, XAxis, YAxis, Legend, CartesianGrid, Bar, ResponsiveContainer, Cell, Label, LabelList} from "recharts";
import { scaleOrdinal } from 'd3-scale'; // Import 'scaleOrdinal' from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as userActions from "../../actions/userActions";
import * as dashboardActions from "../../actions/dashboardActions";
 

const CrifPortfolioDashboard = (props) => {
 
  let appUrl = config.APP_URL;
  appUrl = appUrl.replace('/public/', '/');
  const theme = useTheme();
  const { actions, userInfo, loggedinUserActivityData, loggedinUserFollowupLeadsData, loggedinUserNewLeadsData, requestData} = props;
  const navigate = useNavigate();
  const [getAllFilesData, setGetAllFilesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: registerFormOptions, handleSubmit: handleSubmitGraphs, formState: { errors: errorsForm }, setValue: setValueHabile } = useForm();
 
  const [firstRecordState, setFirstRecordState] = useState('');
  const [secondRecordState, setSecondRecordState] = useState('');

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if(authToken === null || authToken === ""){
        navigate('/login');
    }
  }, []);
 
  const [pieChartScoreData, setPieChartScoreData] = useState({
    series: [0, 0, 0],
    labels: ['Increased Score', 'Decreased Score', 'Same Score'],
    urls: ['/dashboard/ckers-finance-combine-report/increased-score', '/dashboard/ckers-finance-combine-report/decreased-score', '/dashboard/ckers-finance-combine-report/same-score']  
  });  
 

  const [barChartScoreData, setBarChartScoreData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-score/dpd', '/dashboard/ckers-finance-combine-report/decreased-score/dpd', '/dashboard/ckers-finance-combine-report/same-score/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-score/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-score/non-dpd', '/dashboard/ckers-finance-combine-report/same-score/non-dpd']  
}]); 

const [barChartOverdueAccountsData, setBarChartOverdueAccountsData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-overdue_accounts/dpd', '/dashboard/ckers-finance-combine-report/decreased-overdue_accounts/dpd', '/dashboard/ckers-finance-combine-report/same-overdue_accounts/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-overdue_accounts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-overdue_accounts/non-dpd', '/dashboard/ckers-finance-combine-report/same-overdue_accounts/non-dpd']  
  }]); 
  
  const [barChartDeliquentAccountsData, setBarChartDeliquentAccountsData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-delinquentacctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/decreased-delinquentacctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/same-delinquentacctsinlastsixmonths/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-delinquentacctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-delinquentacctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/same-delinquentacctsinlastsixmonths/non-dpd']  
  }]);  


  const [barChartPricurrentbalanceData, setBarChartPricurrentbalanceData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-pricurrentbalance/dpd', '/dashboard/ckers-finance-combine-report/decreased-pricurrentbalance/dpd', '/dashboard/ckers-finance-combine-report/same-pricurrentbalance/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-pricurrentbalance/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-pricurrentbalance/non-dpd', '/dashboard/ckers-finance-combine-report/same-pricurrentbalance/non-dpd']  
  }]); 
  
  const [barChartPrisanctionedamountData, setBarChartPrisanctionedamountData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prisanctionedamount/dpd', '/dashboard/ckers-finance-combine-report/decreased-prisanctionedamount/dpd', '/dashboard/ckers-finance-combine-report/same-prisanctionedamount/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prisanctionedamount/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-prisanctionedamount/non-dpd', '/dashboard/ckers-finance-combine-report/same-prisanctionedamount/non-dpd']  
  }]); 
  
  const [barChartPridisbursedamountData, setBarChartPridisbursedamountData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-pridisbursedamount/dpd', '/dashboard/ckers-finance-combine-report/decreased-pridisbursedamount/dpd', '/dashboard/ckers-finance-combine-report/same-pridisbursedamount/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-pridisbursedamount/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-pridisbursedamount/non-dpd', '/dashboard/ckers-finance-combine-report/same-pridisbursedamount/non-dpd']  
  }]); 
  
  const [barChartPrimaryinstalamtData, setBarChartPrimaryinstalamtData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-primaryinstalamt/dpd', '/dashboard/ckers-finance-combine-report/decreased-primaryinstalamt/dpd', '/dashboard/ckers-finance-combine-report/same-primaryinstalamt/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-primaryinstalamt/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-primaryinstalamt/non-dpd', '/dashboard/ckers-finance-combine-report/same-primaryinstalamt/non-dpd']  
  }]); 
  
  const [barChartInquiriesData, setBarChartInquiriesData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-enquiries/dpd', '/dashboard/ckers-finance-combine-report/decreased-enquiries/dpd', '/dashboard/ckers-finance-combine-report/same-enquiries/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-enquiries/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-enquiries/non-dpd', '/dashboard/ckers-finance-combine-report/same-enquiries/non-dpd']  
  }]); 
  
  

  const [barChartPrinoofacctsData, setBarChartPrinoofacctsData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prinoofaccts/dpd', '/dashboard/ckers-finance-combine-report/decreased-prinoofaccts/dpd', '/dashboard/ckers-finance-combine-report/same-prinoofaccts/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prinoofaccts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-prinoofaccts/non-dpd', '/dashboard/ckers-finance-combine-report/same-prinoofaccts/non-dpd']  
  }]);

  const [barChartPriactiveacctsData, setBarChartPriactiveacctsData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-priactiveaccts/dpd', '/dashboard/ckers-finance-combine-report/decreased-priactiveaccts/dpd', '/dashboard/ckers-finance-combine-report/same-priactiveaccts/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-priactiveaccts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-priactiveaccts/non-dpd', '/dashboard/ckers-finance-combine-report/same-priactiveaccts/non-dpd']  
  }]);

  const [barChartAcctsinlastsixmonthsData, setBarChartAcctsinlastsixmonthsData] = useState([{
    name: 'DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-acctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/decreased-acctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/same-acctsinlastsixmonths/dpd']  
  }, {
    name: 'Non DPD',
    data: [0, 0,0], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-acctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-acctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/same-acctsinlastsixmonths/non-dpd']  
  }]);

  

  const fetchData = async () => {
    try {
      const params = { start: '0', length: config.MAX_RECORDS, start_date: '', end_date: '' };
      const requestData = encryptData(params);
      const response = await actions.getCrifPortfolioData(requestData, false);
      if (response.data.success === 1) {
        const results = response.data.data; 
        setGetAllFilesData(results.downloadFiles);
        setPieChartScoreData({
          series: [results.score_analytics.increased_score, results.score_analytics.decreased_score, results.score_analytics.same_score],
          labels: ['Increased Score', 'Decreased Score', 'Same Score'],
          urls: ['/dashboard/ckers-finance-combine-report/increased-score', '/dashboard/ckers-finance-combine-report/decreased-score', '/dashboard/ckers-finance-combine-report/same-score']  
        }); 


        setBarChartScoreData([{
          name: 'DPD',
          data: [results.score_analytics.increased_score_dpd, results.score_analytics.decreased_score_dpd,results.score_analytics.same_score_dpd], 
          urls: ['/dashboard/ckers-finance-combine-report/increased-score/dpd', '/dashboard/ckers-finance-combine-report/decreased-score/dpd', '/dashboard/ckers-finance-combine-report/same-score/dpd']  
        }, {
          name: 'Non DPD',
          data: [results.score_analytics.increased_score_non_dpd, results.score_analytics.decreased_score_non_dpd,results.score_analytics.same_score_non_dpd], 
          urls: ['/dashboard/ckers-finance-combine-report/increased-score/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-score/non-dpd', '/dashboard/ckers-finance-combine-report/same-score/non-dpd']  
      }]);  
        
       
        setBarChartOverdueAccountsData([{
          name: 'DPD',
          data: [results.overdue_accounts_analytics.increased_overdue_accounts_dpd, results.overdue_accounts_analytics.decreased_overdue_accounts_dpd,results.overdue_accounts_analytics.same_overdue_accounts_dpd], 
          urls: ['/dashboard/ckers-finance-combine-report/increased-overdue_accounts/dpd', '/dashboard/ckers-finance-combine-report/decreased-overdue_accounts/dpd', '/dashboard/ckers-finance-combine-report/same-overdue_accounts/dpd']  
        }, {
          name: 'Non DPD',
          data: [results.overdue_accounts_analytics.increased_overdue_accounts_non_dpd, results.overdue_accounts_analytics.decreased_overdue_accounts_non_dpd,results.overdue_accounts_analytics.same_overdue_accounts_non_dpd], 
          urls: ['/dashboard/ckers-finance-combine-report/increased-overdue_accounts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-overdue_accounts/non-dpd', '/dashboard/ckers-finance-combine-report/same-overdue_accounts/non-dpd']  
      }]); 
      
      setBarChartDeliquentAccountsData([{
        name: 'DPD',
        data: [results.delinquentacctsinlastsixmonths_analytics.increased_delinquentacctsinlastsixmonths_dpd, results.delinquentacctsinlastsixmonths_analytics.decreased_delinquentacctsinlastsixmonths_dpd,results.delinquentacctsinlastsixmonths_analytics.same_delinquentacctsinlastsixmonths_dpd], 
        urls: ['/dashboard/ckers-finance-combine-report/increased-delinquentacctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/decreased-delinquentacctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/same-delinquentacctsinlastsixmonths/dpd']  
      }, {
        name: 'Non DPD',
        data: [results.delinquentacctsinlastsixmonths_analytics.increased_delinquentacctsinlastsixmonths_non_dpd, results.delinquentacctsinlastsixmonths_analytics.decreased_delinquentacctsinlastsixmonths_non_dpd,results.delinquentacctsinlastsixmonths_analytics.same_delinquentacctsinlastsixmonths_non_dpd], 
        urls: ['/dashboard/ckers-finance-combine-report/increased-delinquentacctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-delinquentacctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/same-delinquentacctsinlastsixmonths/non-dpd']  
    }]); 
    
    
    setBarChartPricurrentbalanceData([{
      name: 'DPD',
      data: [results.pricurrentbalance_analytics.increased_pricurrentbalance_dpd, results.pricurrentbalance_analytics.decreased_pricurrentbalance_dpd,results.pricurrentbalance_analytics.same_pricurrentbalance_dpd], 
      urls: ['/dashboard/ckers-finance-combine-report/increased-pricurrentbalance/dpd', '/dashboard/ckers-finance-combine-report/decreased-pricurrentbalance/dpd', '/dashboard/ckers-finance-combine-report/same-pricurrentbalance/dpd']  
    }, {
      name: 'Non DPD',
      data: [results.pricurrentbalance_analytics.increased_pricurrentbalance_non_dpd, results.pricurrentbalance_analytics.decreased_pricurrentbalance_non_dpd,results.pricurrentbalance_analytics.same_pricurrentbalance_non_dpd], 
      urls: ['/dashboard/ckers-finance-combine-report/increased-pricurrentbalance/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-pricurrentbalance/non-dpd', '/dashboard/ckers-finance-combine-report/same-pricurrentbalance/non-dpd']  
  }]); 


  setBarChartPrisanctionedamountData([{
    name: 'DPD',
    data: [results.prisanctionedamount_analytics.increased_prisanctionedamount_dpd, results.prisanctionedamount_analytics.decreased_prisanctionedamount_dpd,results.prisanctionedamount_analytics.same_prisanctionedamount_dpd], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prisanctionedamount/dpd', '/dashboard/ckers-finance-combine-report/decreased-prisanctionedamount/dpd', '/dashboard/ckers-finance-combine-report/same-prisanctionedamount/dpd']  
  }, {
    name: 'Non DPD',
    data: [results.prisanctionedamount_analytics.increased_prisanctionedamount_non_dpd, results.prisanctionedamount_analytics.decreased_prisanctionedamount_non_dpd,results.prisanctionedamount_analytics.same_prisanctionedamount_non_dpd], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-prisanctionedamount/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-prisanctionedamount/non-dpd', '/dashboard/ckers-finance-combine-report/same-prisanctionedamount/non-dpd']  
}]); 


setBarChartPridisbursedamountData([{
  name: 'DPD',
  data: [results.pridisbursedamount_analytics.increased_pridisbursedamount_dpd, results.pridisbursedamount_analytics.decreased_pridisbursedamount_dpd,results.pridisbursedamount_analytics.same_pridisbursedamount_dpd], 
  urls: ['/dashboard/ckers-finance-combine-report/increased-pridisbursedamount/dpd', '/dashboard/ckers-finance-combine-report/decreased-pridisbursedamount/dpd', '/dashboard/ckers-finance-combine-report/same-pridisbursedamount/dpd']  
}, {
  name: 'Non DPD',
  data: [results.pridisbursedamount_analytics.increased_pridisbursedamount_non_dpd, results.pridisbursedamount_analytics.decreased_pridisbursedamount_non_dpd,results.pridisbursedamount_analytics.same_pridisbursedamount_non_dpd], 
  urls: ['/dashboard/ckers-finance-combine-report/increased-pridisbursedamount/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-pridisbursedamount/non-dpd', '/dashboard/ckers-finance-combine-report/same-pridisbursedamount/non-dpd']  
}]);

setBarChartPrimaryinstalamtData([{
name: 'DPD',
data: [results.primaryinstalamt_analytics.increased_primaryinstalamt_dpd, results.primaryinstalamt_analytics.decreased_primaryinstalamt_dpd,results.primaryinstalamt_analytics.same_primaryinstalamt_dpd], 
urls: ['/dashboard/ckers-finance-combine-report/increased-primaryinstalamt/dpd', '/dashboard/ckers-finance-combine-report/decreased-primaryinstalamt/dpd', '/dashboard/ckers-finance-combine-report/same-primaryinstalamt/dpd']  
}, {
name: 'Non DPD',
data: [results.primaryinstalamt_analytics.increased_primaryinstalamt_non_dpd, results.primaryinstalamt_analytics.decreased_primaryinstalamt_non_dpd,results.primaryinstalamt_analytics.same_primaryinstalamt_non_dpd], 
urls: ['/dashboard/ckers-finance-combine-report/increased-primaryinstalamt/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-primaryinstalamt/non-dpd', '/dashboard/ckers-finance-combine-report/same-primaryinstalamt/non-dpd']  
}]);

setBarChartInquiriesData([{
name: 'DPD',
data: [results.enquiries_analytics.increased_enquiries_dpd, results.enquiries_analytics.decreased_enquiries_dpd,results.enquiries_analytics.same_enquiries_dpd], 
urls: ['/dashboard/ckers-finance-combine-report/increased-enquiries/dpd', '/dashboard/ckers-finance-combine-report/decreased-enquiries/dpd', '/dashboard/ckers-finance-combine-report/same-enquiries/dpd']  
}, {
name: 'Non DPD',
data: [results.enquiries_analytics.increased_enquiries_non_dpd, results.enquiries_analytics.decreased_enquiries_non_dpd,results.enquiries_analytics.same_enquiries_non_dpd], 
urls: ['/dashboard/ckers-finance-combine-report/increased-enquiries/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-enquiries/non-dpd', '/dashboard/ckers-finance-combine-report/same-enquiries/non-dpd']  
}]);

setBarChartPrinoofacctsData([{
  name: 'DPD',
  data: [results.prinoofaccts_analytics.increased_prinoofaccts_dpd, results.prinoofaccts_analytics.decreased_prinoofaccts_dpd,results.prinoofaccts_analytics.same_prinoofaccts_dpd], 
  urls: ['/dashboard/ckers-finance-combine-report/increased-prinoofaccts/dpd', '/dashboard/ckers-finance-combine-report/decreased-prinoofaccts/dpd', '/dashboard/ckers-finance-combine-report/same-prinoofaccts/dpd']  
  }, {
  name: 'Non DPD',
  data: [results.prinoofaccts_analytics.increased_prinoofaccts_non_dpd, results.prinoofaccts_analytics.decreased_prinoofaccts_non_dpd,results.prinoofaccts_analytics.same_prinoofaccts_non_dpd], 
  urls: ['/dashboard/ckers-finance-combine-report/increased-prinoofaccts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-prinoofaccts/non-dpd', '/dashboard/ckers-finance-combine-report/same-prinoofaccts/non-dpd']  
  }]);

  setBarChartPriactiveacctsData([{
    name: 'DPD',
    data: [results.priactiveaccts_analytics.increased_priactiveaccts_dpd, results.priactiveaccts_analytics.decreased_priactiveaccts_dpd,results.priactiveaccts_analytics.same_priactiveaccts_dpd], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-priactiveaccts/dpd', '/dashboard/ckers-finance-combine-report/decreased-priactiveaccts/dpd', '/dashboard/ckers-finance-combine-report/same-priactiveaccts/dpd']  
    }, {
    name: 'Non DPD',
    data: [results.priactiveaccts_analytics.increased_priactiveaccts_non_dpd, results.priactiveaccts_analytics.decreased_priactiveaccts_non_dpd,results.priactiveaccts_analytics.same_priactiveaccts_non_dpd], 
    urls: ['/dashboard/ckers-finance-combine-report/increased-priactiveaccts/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-priactiveaccts/non-dpd', '/dashboard/ckers-finance-combine-report/same-priactiveaccts/non-dpd']  
    }]);

    setBarChartAcctsinlastsixmonthsData([{
      name: 'DPD',
      data: [results.acctsinlastsixmonths_analytics.increased_acctsinlastsixmonths_dpd, results.acctsinlastsixmonths_analytics.decreased_acctsinlastsixmonths_dpd,results.acctsinlastsixmonths_analytics.same_acctsinlastsixmonths_dpd], 
      urls: ['/dashboard/ckers-finance-combine-report/increased-acctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/decreased-acctsinlastsixmonths/dpd', '/dashboard/ckers-finance-combine-report/same-acctsinlastsixmonths/dpd']  
      }, {
      name: 'Non DPD',
      data: [results.acctsinlastsixmonths_analytics.increased_acctsinlastsixmonths_non_dpd, results.acctsinlastsixmonths_analytics.decreased_acctsinlastsixmonths_non_dpd,results.acctsinlastsixmonths_analytics.same_acctsinlastsixmonths_non_dpd], 
      urls: ['/dashboard/ckers-finance-combine-report/increased-acctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/decreased-acctsinlastsixmonths/non-dpd', '/dashboard/ckers-finance-combine-report/same-acctsinlastsixmonths/non-dpd']  
      }]);

        console.log("setPieChartScoreData = ",pieChartScoreData);

      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(error.message || 'Error fetching data', {
        position: 'top-right'
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [setPieChartScoreData,setGetAllFilesData,setFirstRecordState]);


  const handleFilterGraphs = (data) =>{ 
    setFirstRecordState(data.firstRecordOption);
    setSecondRecordState(data.secondRecordOption);
    if(data.firstRecordOption === data.secondRecordOption){
      toast.error("Please select different option to compare." || 'Error fetching data', {
        position: 'top-right'
      });
    }else{
       fetchData(); 
    }
  };

 

  const handleSliceScoreClick = (event, chartContext, config) => {
    const url = pieChartScoreData.urls[config.dataPointIndex];
    if (url) {
      window.open(url, '_blank');
    }
  };
 
  const pieChartScoreOptions = {
    chart: {
      type: 'pie',
      events: {
        dataPointSelection: handleSliceScoreClick
      }
    },
    labels: pieChartScoreData.labels,
    dataLabels: {
      enabled: true,
      formatter: (val, opt) => {
        return opt.w.globals.series[opt.seriesIndex];
      }
    }
  }; 

 
  const handleScoreClick = (event, chartContext, config) => { 
    const url = barChartScoreData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartScoreOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handleScoreClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };

  const handleOverdueAccountsClick = (event, chartContext, config) => { 
    const url = barChartOverdueAccountsData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartOverdueAccountsOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handleOverdueAccountsClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


const handleDeliquentAccountsClick = (event, chartContext, config) => { 
    const url = barChartDeliquentAccountsData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartDeliquentAccountsOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handleDeliquentAccountsClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


  const handlePricurrentbalanceClick = (event, chartContext, config) => { 
    const url = barChartPricurrentbalanceData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPricurrentbalanceOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePricurrentbalanceClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


  const handlePrisanctionedamountClick = (event, chartContext, config) => { 
    const url = barChartPrisanctionedamountData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPrisanctionedamountOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePrisanctionedamountClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


  const handlePridisbursedamountClick = (event, chartContext, config) => { 
    const url = barChartPridisbursedamountData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPridisbursedamountOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePridisbursedamountClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };
  

  const handlePrimaryinstalamtClick = (event, chartContext, config) => { 
    const url = barChartPrimaryinstalamtData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPrimaryinstalamtOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePrimaryinstalamtClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


  const handleInquiriesClick = (event, chartContext, config) => { 
    const url = barChartInquiriesData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartInquiriesOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handleInquiriesClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };

  const handlePrinoofacctsClick = (event, chartContext, config) => { 
    const url = barChartPrinoofacctsData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPrinoofacctsOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePrinoofacctsClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };

  const handlePriactiveacctsClick = (event, chartContext, config) => { 
    const url = barChartPriactiveacctsData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartPriactiveacctsOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handlePriactiveacctsClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };


  
  const handleAcctsinlastsixmonthsClick = (event, chartContext, config) => { 
    const url = barChartAcctsinlastsixmonthsData[config.seriesIndex].urls[config.dataPointIndex];
     if (url) {
      window.open(url, '_blank');
    }
  };
  const barChartAcctsinlastsixmonthsOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        dataPointSelection: handleAcctsinlastsixmonthsClick
      }
    },
    xaxis: {
      categories: ['Increased Count', 'Decreased Count', 'Same Count'] // Add your stack names here
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
    },
  };

  // Function to download excel file
  const handleOptionChange = (e) => {    
    setSelectedOption(e.target.value);

    if(e.target.value !==''){
      const filePath = `${appUrl}${e.target.value}`;

      const downloadLink = document.createElement('a');
      downloadLink.href = filePath;
      downloadLink.download = filePath.substring(filePath.lastIndexOf('/') + 1); // Set the download filename
      downloadLink.click(); // Trigger the click event
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Ckers </title>
      </Helmet>

      <Container maxWidth="xl">
        <div id='main-wrap-'>  
          <div className='row'>

          <div className='col-md-7 mb-4'> <Typography variant="h4" sx={{ mb: 5 }}>
         CRIF Portfolio Dashboard
        </Typography> </div>
            <div className='col-md-5 mb-4'>
             Download Files 
            <select
                className="form-select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value=''>Please select</option>
                {getAllFilesData.length > 0 && getAllFilesData.map((option) => (
                  <option value={option.file_name}>
                    {option.display_name}
                  </option>
                ))}
              </select>

            </div> 
          </div>
          
          <div className='row'>

          <form onSubmit={handleSubmitGraphs(handleFilterGraphs)}>
                <div id='main-wrap' className='mb-4 filter'>
                  <div className='row mx-0'>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor="start-date">
                          <span>Select Fisrt File</span>
                          
                            <select
                              className="form-select"
                              value={firstRecordState}
                              name='firstRecordOption'
                              {...registerFormOptions('firstRecordOption', { required: true })}
                              onChange={(e) => setFirstRecordState(e.target.value)}
                            >
                              <option value=''>Please select</option>
                              {getAllFilesData.length > 0 && getAllFilesData.map((option) => (
                                <option value={option.file_name}>
                                  {option.display_name}
                                </option>
                              ))}
                            </select>
                        </label>
                        {errorsForm.firstRecordOption && <span className="required">This field is required.</span>}
                      </div>
                    </div>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor="end-date">
                          <span>Select Second File</span>
                         
                          <select
                              className="form-select"
                              value={secondRecordState}
                              name='secondRecordOption'
                              {...registerFormOptions('secondRecordOption', { required: true })}
                              onChange={(e) => setSecondRecordState(e.target.value)}
                            >
                              <option value=''>Please select</option>
                              {getAllFilesData.length > 0 && getAllFilesData.map((option) => (
                                <option value={option.file_name}>
                                  {option.display_name}
                                </option>
                              ))}
                            </select>
                        </label>
                        {errorsForm.secondRecordOption && <span className="required">This field is required.</span>}
                      </div>
                    </div>
                    <div className='col'>
                      <div className='form-group mb-2'>
                        <span className='d-block'>&nbsp;</span>
                        <button className='btn btn-primary'>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

          </div>    

          <div className='row bg-firstLevel'>    
          <div className='col-md-4 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">Score Analytics 
                    &nbsp; 
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Perform CNS Score</div>
                    </div>
                    {/* Tooltip :end */} </h5>
                               
                  </div>
                  
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartScoreOptions} series={barChartScoreData} type="bar" height={400} />
                      </div>
                  </div>
                </div>
              </div>
          </div>
  
          <div className='col-md-4 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">Overdue accounts
                    &nbsp; 
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Overdue accounts</div>
                    </div>
                    {/* Tooltip :end */}
                    </h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartOverdueAccountsOptions} series={barChartOverdueAccountsData} type="bar" height={400} />
                      </div>
                  </div>
                </div>
              </div>
          </div>

          <div className='col-md-4 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">Deliquent Accounts(In Last 6 Months)
                    &nbsp;  
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Deliquent Accounts in Last 6 Months</div>
                    </div>
                    {/* Tooltip :end */}
                    </h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartDeliquentAccountsOptions} series={barChartDeliquentAccountsData} type="bar" height={400} />
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>                        

          <div className='row bg-secondLevel mt-4'>
           {/* <div className='col-md-6 mb-4 none hide'>
              <div id='main-wrap' className='search-filter h-100'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">Score Analytics</h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>                    
                      <Chart
                        options={pieChartScoreOptions}
                        series={pieChartScoreData.series}
                        type="pie"
                        width="380"
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div> */}



<div className='col-md-3 mb-4'>
      <div id='main-wrap' className='search-filter h-100'>
      <div className='row'>
          <div className='col-md-12'>
            <h5 className="fw-600 mb-0">Current Balance 
            &nbsp; 
            {/* Tooltip :start */}
            <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Current Balance</div>
                    </div>
                    {/* Tooltip :end */}
            </h5>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <Chart options={barChartPricurrentbalanceOptions} series={barChartPricurrentbalanceData} type="bar" height={250} />
              </div>
          </div>
        </div>
      </div>
  </div>

  <div className='col-md-3 mb-4'>
      <div id='main-wrap' className='search-filter h-100'>
      <div className='row'>
          <div className='col-md-12'>
            <h5 className="fw-600 mb-0">Sanctioned Amount
            &nbsp; 
            {/* Tooltip :start */}
            <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Sanctioned Amount</div>
                    </div>
                    {/* Tooltip :end */}
            </h5>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <Chart options={barChartPrisanctionedamountOptions} series={barChartPrisanctionedamountData} type="bar" height={250} />
              </div>
          </div>
        </div>
      </div>
  </div>



  <div className='col-md-3 mb-4'>
      <div id='main-wrap' className='search-filter h-100'>
      <div className='row'>
          <div className='col-md-12'>
            <h5 className="fw-600 mb-0">Disbursed Amount 
            &nbsp; 
            {/* Tooltip :start */}
            <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Disbursed Amount</div>
                    </div>
                    {/* Tooltip :end */}
            </h5>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <Chart options={barChartPridisbursedamountOptions} series={barChartPridisbursedamountData} type="bar" height={250} />
              </div>
          </div>
        </div>
      </div>
  </div>

  <div className='col-md-3 mb-4'>
      <div id='main-wrap' className='search-filter h-100'>
      <div className='row'>
          <div className='col-md-12'>
            <h5 className="fw-600 mb-0">Instalment Amt 
            &nbsp; 
            {/* Tooltip :start */}
            <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">Instalment Amt</div>
                    </div>
                    {/* Tooltip :end */}
            </h5>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <Chart options={barChartPrimaryinstalamtOptions} series={barChartPrimaryinstalamtData} type="bar" height={250} />
              </div>
          </div>
        </div>
      </div>
  </div>


  <div className='col-md-3 mb-4'>
      <div id='main-wrap' className='search-filter h-100'>
      <div className='row'>
          <div className='col-md-12'>
            <h5 className="fw-600 mb-0">Inquiries 
            &nbsp; 
            {/* Tooltip :start */}
            <div className="tooltip-container">
                      <span className="tooltip-icon" role="img">ℹ️</span>
                      <div className="tooltip-text">No of Inquiries</div>
                    </div>
                    {/* Tooltip :end */}
            </h5>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <Chart options={barChartInquiriesOptions} series={barChartInquiriesData} type="bar" height={250} />
              </div>
          </div>
        </div>
      </div>
  </div>

             

          </div>

          <div className='row mt-4'>

          {/* PRI-NO-OF-ACCTS :  start here */}  
          <div className='col-md-3 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">No Of Accounts 
                    &nbsp; 
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                              <span className="tooltip-icon" role="img">ℹ️</span>
                              <div className="tooltip-text">No Of Accounts</div>
                            </div>
                            {/* Tooltip :end */}
                    </h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartPrinoofacctsOptions} series={barChartPrinoofacctsData} type="bar" height={250} />
                      </div>
                  </div>
                </div>
              </div>
          </div>
          {/* PRI-NO-OF-ACCTS :  end here */}  


          {/* PRI-ACTIVE-ACCTS :  start here */}  
          <div className='col-md-3 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">Active Accounts 
                    &nbsp; 
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                              <span className="tooltip-icon" role="img">ℹ️</span>
                              <div className="tooltip-text">Active Accounts</div>
                            </div>
                            {/* Tooltip :end */}
                    </h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartPriactiveacctsOptions} series={barChartPriactiveacctsData} type="bar" height={250} />
                      </div>
                  </div>
                </div>
              </div>
          </div>
          {/* PRI-ACTIVE-ACCTS :  end here */} 

            {/* NEW-ACCTS-IN-LAST-SIX-MONTHS :  start here */}  
            <div className='col-md-3 mb-4'>
              <div id='main-wrap' className='search-filter h-100'>
              <div className='row'>
                  <div className='col-md-12'>
                    <h5 className="fw-600 mb-0">New Accts In Last 6 Months
                    &nbsp; 
                    {/* Tooltip :start */}
                    <div className="tooltip-container">
                              <span className="tooltip-icon" role="img">ℹ️</span>
                              <div className="tooltip-text">New Accts In Last 6 Months</div>
                            </div>
                            {/* Tooltip :end */}
                    </h5>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Chart options={barChartAcctsinlastsixmonthsOptions} series={barChartAcctsinlastsixmonthsData} type="bar" height={250} />
                      </div>
                  </div>
                </div>
              </div>
          </div>
          {/* NEW-ACCTS-IN-LAST-SIX-MONTHS :  end here */} 


          

          </div>
        </div>

      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    loggedinUserActivityData: state.loggedinUserActivityData,
    loggedinUserFollowupLeadsData: state.loggedinUserFollowupLeadsData,
    loggedinUserNewLeadsData: state.loggedinUserNewLeadsData,
    incidentReportAssignedMe: state.incidentReportAssignedMe,
    getLoggedinUserSourceLeads: state.getLoggedinUserSourceLeads,
   }
}
 
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, dashboardActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CrifPortfolioDashboard);
