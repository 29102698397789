import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function rolesReducer(state = initialState.rolesData, action) {
  switch (action.type) {
    case types.LOAD_ROLES_DATA_SUCCESS:
      return action.rolesData;
    default:
      return state;
  }
}
