import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink , useNavigate,useParams ,useLocation} from 'react-router-dom'; 
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadChecklistForm = (props) => {

    const { id } = useParams();
    const location = useLocation();
    const { actions,usersData, basicLeadInfo, sourceData, masterProfileData} = props;
      
  return (
    <>
      <Helmet>
        <title>Lead Qualification Checklist | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Lead Qualification Checklist
        </Typography>
        
      </Container>
    </>
  );
}

function mapStateToProps(state) {
    return {
       usersData: state.usersData,
       userInfo: state.userInfo,
       sourceData: state.sourceData,
       masterProfileData : state.masterProfileData
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(LeadChecklistForm);