import React, { useState, useEffect, memo } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
// @mui  
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";


const QuestionSlide = React.memo(({ question, onNext, onPrevious, onAnswer, currentIndex, totalQuestions, answers }) => {
    const [selectedAnswer, setSelectedAnswer] = useState(answers[currentIndex] || '');

    useEffect(() => {
        // Set the initial selected answer based on the current index
        setSelectedAnswer(answers[currentIndex]);
        // Check if 'userAnswers' is present in the question
        if ('userAnswers' in question) {
            if (question.answerType === 'checkbox') {
                // If it's a checkbox, set selected answers from the current index
                // If it's not a checkbox, set the selected answer from userAnswers[0]
                let ansVal = '';
                if (Object.prototype.hasOwnProperty.call(question.userAnswers, "0")) {
                    ansVal = question.userAnswers[0];
                }
                setSelectedAnswer(ansVal);
            } else {
                // If it's not a checkbox, set the selected answer from userAnswers[0]
                let ansVal = '';
                if (Object.prototype.hasOwnProperty.call(question.userAnswers, "0")) {
                    ansVal = question.userAnswers[0];
                }
                setSelectedAnswer(ansVal);
            }
        }
    }, [setSelectedAnswer, currentIndex, question]);

    const handleAnswer = (answer) => {
        setSelectedAnswer(answer);
        onAnswer(currentIndex, answer);
    };

    return (
        <div className='row'>
            <div className='col-md-12'>
                <div className='form-group'>
                    <label htmlFor="question">
                        <span>Question Text</span>
                        <input type='text' className='form-control' placeholder={question.questionText} value={question.questionText} readOnly disabled />
                    </label>
                </div>
            </div>


            <div className='col-md-12 questions'>
                <span className='mb-2'>Answer Option</span>
                <div className='form-group'>
                    <div className='row'>
                        {question.answerType === 'textarea' ? (
                            <div className='col-md-12'>
                                <textarea value={selectedAnswer}
                                    onChange={(e) => handleAnswer(e.target.value)}
                                    className="form-control" />
                            </div>
                        ) : (
                            <div className='col-md-12'>
                                {question.answerType === 'input' ? (
                                    <input value={selectedAnswer}
                                        onChange={(e) => handleAnswer(e.target.value)}
                                        className="form-control" />
                                ) : (
                                    question.answerOptions.map((option, index) => (
                                        <div key={index}>
                                            {question.answerType === 'radio' ? (
                                                <input
                                                    className="select-l mt-1 me-2"
                                                    type="radio"
                                                    id={`option-${index}`}
                                                    name={`question-${currentIndex}`}
                                                    value={option}
                                                    checked={selectedAnswer === option}
                                                    onChange={() => handleAnswer(option)}
                                                />
                                            ) : (
                                                <input
                                                    className="select-l mt-1 me-2"
                                                    type="checkbox"
                                                    id={`option-${index}`}
                                                    name={`question-${currentIndex}`}
                                                    value={option}
                                                    checked={selectedAnswer.includes(option)}
                                                    onChange={() => handleAnswer(option)}
                                                />
                                            )}
                                            <label htmlFor={`option-${index}`} className='mb-2'>{option}</label>
                                        </div>
                                    )

                                    )
                                )}
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>

            <div className='col-12 text-end question-btns'>
                <button className="btn prev-btn mt-5" onClick={onPrevious} disabled={currentIndex === 0}>Prev Question</button>&nbsp;

                <button className="btn next-btn mt-5" onClick={onNext} disabled={currentIndex === totalQuestions - 1}>Next Question</button>
            </div>
        </div>
    );
});

const Questionnaire = ({ questions, checklistInfo, props, setOpen, fetchLeads, updateParentState }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [answers, setAnswers] = useState(new Array(questions.length).fill(''));
    const { leadInfo, leadData, actions } = props;
    const [currentChecklistId, setCurrentChecklistId] = useState(checklistInfo.id);

    useEffect(() => {
        // set answers of each questions in array
        const userAnswersArray = questions.map((item) => (item.userAnswers ? item.userAnswers[0] : ''));
        setAnswers(userAnswersArray);
    }, [actions, leadInfo]);

    const handleNext = () => {
        console.log("currentStep = ", currentStep);
        console.log("answers = ", answers);
        handleSubmit('nextBtnClickHandler');
        if (currentStep < questions.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleAnswer = (questionIndex, answer) => {
        const updatedAnswers = [...answers];
        // updatedAnswers[questionIndex] = answer;

        console.log("questionIndex = ", questionIndex);
        console.log("answer = ", answer);
        console.log("answerType = ", questions[questionIndex].answerType);
        updatedAnswers[questionIndex] = answer;
        setAnswers(updatedAnswers);
    };

    const handleSubmit = (actionHandler) => {
        // Save answers to the database (convert to JSON if needed)
        console.log(actionHandler);
        const answersJSON = JSON.stringify(answers);
        console.log(answersJSON);

        if (currentChecklistId === '') {
            toast.error("Checklist id in null   ", {
                position: "top-right",
            });
            return;
        }
        const params = { 'id': currentChecklistId, 'answers': answersJSON };
        const requestData = encryptData(params);
        const response = actions.updateLeadChecklistQuestionAnswer(requestData);

        response.then((result) => {
            // console.log("Promise resolved - add/edit question answer form:", result); 
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                // setShowLeadDetails(false);
                setTimeout(() => {
                    if (actionHandler !== 'nextBtnClickHandler') {
                        updateParentState(false);
                        fetchLeads();
                        setOpen(false);
                    }
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            //  console.error("Promise rejected - add/edit question answer form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    }; 

    const backToChecklistsHandler = () => {
        setOpen(false);
    }
 
    return (
        <div>
            <div className='d-flex align-items-center justify-content-between'>
                <h5 className='fw-bold mb-3'>Checklist : {checklistInfo.check_list_name}</h5>
                <button onClick={backToChecklistsHandler} onKeyDown={backToChecklistsHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded'> Back to List</button>
            </div>
            <QuestionSlide
                question={questions[currentStep]}
                onNext={handleNext}
                onPrevious={handlePrevious}
                onAnswer={handleAnswer}
                currentIndex={currentStep}
                totalQuestions={questions.length}
                answers={answers}
            />
            {currentStep === questions.length - 1 && <button className="btn btn-success mb-2" onClick={handleSubmit}>Complete</button>}
            <div>&nbsp;</div> 
           
        </div>
    );
};



function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        leadInfo: state.leadInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);