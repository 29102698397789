import CryptoJS from 'crypto-js';
import config from "../config";

function encryptData(obj) {
  const encKey = config.ENCKEY;
  const text = JSON.stringify(obj);
  const iv = CryptoJS.enc.Hex.parse(config.HEXKEY);
  const checksum = CryptoJS.AES.encrypt(text, encKey, { iv }).toString();

  // const decrypted = CryptoJS.AES.decrypt(checksum, encKey).toString(CryptoJS.enc.Utf8);
  // console.log('========actual parameters : start here ===========');
  // console.log(decrypted);
  // console.log('========actual parameters : end here ===========');

  const encryptedObj = JSON.stringify({ "checksum": checksum });
  return encryptedObj;
}

export { encryptData };