import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const loginDataSuccess = (userInfo) => {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

const ParamsDataSuccess = (userParams) => {
  return { type: types.LOADED_USER_PARAM_SUCCESS, userParams };
}

const userLoginDataSuccess = (userLogin) => {
  return { type: types.LOADED_USER_LOGIN_SUCCESS, userLogin };
}

const loadUserAuth = (isAuth) => {
  return { type: types.LOADED_USER_AUTH_SUCCESS, isAuth };
}

export function usersDataSuccess(usersData) {
  return { type: types.LOAD_USERS_DATA_SUCCESS, usersData };
}

export function roleInfoSuccess(roleInfo) {
  return { type: types.LOAD_ROLE_INFO_SUCCESS, roleInfo };
}

export function rolePermissionDataSuccess(rolePermissionData) {
  return { type: types.LOAD_ROLE_PERMISSION_DATA_SUCCESS, rolePermissionData };
}

export function rolePermissionInfoSuccess(rolePermissionInfo) {
  return { type: types.LOAD_ROLE_PERMISSION_INFO_SUCCESS, rolePermissionInfo };
}



const userLoginData = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.LOGIN, params)
      .then((response) => { 
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            dispatch(userLoginDataSuccess(false));
            dispatch(loginDataSuccess(response.data.data));
            dispatch(loadUserAuth(true)); 
            localStorage.setItem("authToken", response.data.login_token);
            const authToken = localStorage.getItem('authToken');

            const logInTimestamp = Math.floor(Date.now() / 1000);
            localStorage.setItem("logInTimestamp", logInTimestamp);

            if (params.remember_me) {
              dispatch(ParamsDataSuccess(params));
            } else {
              dispatch(ParamsDataSuccess({}));
            }
          }
        } else{ 
            dispatch(toggleNetworkRequestStatus(false));
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

const userLogout = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.LOG_OUT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    }
  };
}

const usersData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_USERS, params);
      } else {
        response = await agent.post(API.GET_USERS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(usersDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const rolesData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_ROLES, params);
      } else {
        response = await agent.post(API.GET_ROLES, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const createRole = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CU_ROLE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const roleInfo  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_ROLES, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
             dispatch(roleInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

const rolePermissionData  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_ROLE_PERMISSION, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
            dispatch(rolePermissionDataSuccess(response.data.data)); 
           }
        }
      })
      .catch((error) => { 
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

const rolePermissionInfo  = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_ROLE_PERMISSION, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
             dispatch(rolePermissionInfoSuccess(response.data.data)); 
          }
        }
      })
      .catch((error) => { 
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

const createRolePermission = (params,push) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.CU_ROLE_PERMISSION, params)
      .then((response) => {  
 
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            setToaster(response.data.message); 
            push("/dashboard/role-permissions"); 
          }
        } else{
            dispatch(toggleNetworkRequestStatus(false));
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

const fetchModulePermissionsData = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.GET_MODULES_PERMISSIONS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    }
  };
};

const saveModulePermissions = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.SAVE_MODULES_PERMISSIONS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    }
  };
};


export function userTypesDataSuccess(userTypesData) {
  return { type: types.GET_USER_TYPES_DATA_SUCCESS, userTypesData };
}
const userTypesData  = (params, preloader = true) => { 
  return async function (dispatch) { 
    const loader = preloader ? dispatch(toggleNetworkRequestStatus(true)) : '';
    try {
      const response = await agent.post(API.GET_USER_TYPES, params); 
      const loader = preloader ? dispatch(toggleNetworkRequestStatus(false)) : '';
      return response;
    } catch (error) { 
      const loader = preloader ? dispatch(toggleNetworkRequestStatus(false)) : '';
      throw error;
    }
  }; 
}
 

const addEditUser = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CU_USER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}


const getUserModuleData = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(false));
    try {
      const response = await agent.post(API.GET_USER_MODULE_PERMISSION, params);
      dispatch(toggleNetworkRequestStatus(false)); 
      return response;
    } catch (error) { 
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getRoleTypeData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_ROLE_TYPE, params);
      } else {
        response = await agent.post(API.GET_ROLE_TYPE, params);       
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getUserNotificationsData = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(false));
    try {
      const response = await agent.post(API.GET_USER_NOTIFICATIONS, params);
      dispatch(toggleNetworkRequestStatus(false)); 
      return response;
    } catch (error) { 
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const readNotifications = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(false));
    try {
      const response = await agent.post(API.READ_USER_NOTIFICATIONS, params);
      dispatch(toggleNetworkRequestStatus(false)); 
      return response;
    } catch (error) { 
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getLoggedinUserChild = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_CHILD, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_CHILD, params);       
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const userResetPasswordSendEmail = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.USER_RESET_PASSWORD_SEND_EMAIL, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const updatePassword = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_PASSWORD, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}
 
const generateQRcode = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.PAYSPRINT_GENERATE_QR_CODE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const updateUserActiveRole = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_USER_ACTIVE_ROLE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const moduleAccess = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    }

    try {
      const response = await agent.post(API.MODULE_REDIRECT, params);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      if (response.status === 200 || response.status === 201) {
        if (!response.data.success) {
          setToaster(response.data.message);
        }

        if (response.data.status === 422 || response.data.status === 201) {
          setToaster(response.data.message);
        }

        if (response.data.success) {
          dispatch(userLoginDataSuccess(false));
          dispatch(loginDataSuccess(response.data.data));
          dispatch(loadUserAuth(true));
          localStorage.setItem("authToken", response.data.login_token);

          const logInTimestamp = Math.floor(Date.now() / 1000);
          localStorage.setItem("logInTimestamp", logInTimestamp);

          if (params.remember_me) {
            dispatch(ParamsDataSuccess(params));
          } else {
            dispatch(ParamsDataSuccess({}));
          }
        }
      } else {
        setToaster(response.data.message);
      }
      
      return response;
      
    } catch (error) {
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }
      
      throw error;
    }
  };
}

const userLoginGoogleauth = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GOOGLEAUTH, params)
      .then((response) => { 
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (!response.data.success) {
            setToaster(response.data.message);
          }
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.success) {
            dispatch(userLoginDataSuccess(false));
            dispatch(loginDataSuccess(response.data.data));
            dispatch(loadUserAuth(true)); 
            localStorage.setItem("authToken", response.data.login_token);
            const authToken = localStorage.getItem('authToken');

            const logInTimestamp = Math.floor(Date.now() / 1000);
            localStorage.setItem("logInTimestamp", logInTimestamp);

            if (params.remember_me) {
              dispatch(ParamsDataSuccess(params));
            } else {
              dispatch(ParamsDataSuccess({}));
            }
          }
        } else{ 
            dispatch(toggleNetworkRequestStatus(false));
            setToaster(response.data.message);
        } 
      })
      .catch((error) => {
        throw error;
      });
  };
}

const getLoanQRRespoanseData = (params) => {
  return async function (dispatch) { 
    dispatch(toggleNetworkRequestStatus(false));
    try {
      const response = await agent.post(API.PAYSPRINT_GET_QR_PAYMENT_RESPONSE, params);
      dispatch(toggleNetworkRequestStatus(false)); 
      return response;
    } catch (error) { 
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}




export default {
  loginDataSuccess,
  ParamsDataSuccess,
  userLoginDataSuccess,
  loadUserAuth,
  userLoginData,
  userLogout,
  usersDataSuccess,
  usersData,
  rolesData,
  createRole,
  roleInfo,
  roleInfoSuccess, 
  rolePermissionData,
  rolePermissionInfo,
  rolePermissionInfoSuccess,
  createRolePermission,
  fetchModulePermissionsData,
  saveModulePermissions,
  userTypesData,
  addEditUser,
  getUserModuleData,
  getRoleTypeData,
  getUserNotificationsData,
  readNotifications,
  getLoggedinUserChild,
  userResetPasswordSendEmail,
  updatePassword,
  generateQRcode,
  updateUserActiveRole,
  moduleAccess,
  userLoginGoogleauth,
  getLoanQRRespoanseData
} 