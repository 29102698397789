import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";
import * as moduleAction from "../../actions/moduleAction";

import AccessDenied from '../common/AccessDenied';
import Loader from "../../components/Loader";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const ModuleCreatePage = (props) => {
    const { leadId, incidentReporteId } = useParams();
    const location = useLocation();
    const { actions, usersData, incidentReporteData, checklistsData, leadInfo, fetchIncidentReporte, allTicketData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [getLeadId, setLeadId] = useState('');
    const [getIncidentReporteId, setIncidentReporteId] = useState('');
    const [expectedResolutionDate, setExpectedResolutionDate] = useState(new Date());
    const [allIncidentReportStatus, setAllIncidentReportStatusData] = useState([]);
    const [getAllIncidentCategoryData, setAllIncidentCategoryData] = useState([]);
    const [getAllIncidentpriorityData, setAllIncidentpriorityData] = useState([]);

    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showTicketListDetails, setShowTicketListDetails] = useState(false);
    const [getAllTicketData, setAllTicketData] = useState([]);
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [getAllUsersListData, setAllUsersListData] = useState([]);
    const [allGroupData, setAllGroupData] = useState([]);
    const [leadName, setLeadName] = useState('');
    const [leadEmail, setLeadEmail] = useState('');
    const [leadAddress, setLeadAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [incidentOpenString, setIncidentOpenString] = useState('');
    const [incidentCloseString, setIncidentCloseString] = useState('');

    const [selectedAssignTo, setSelectedAssignTo] = useState([]);
    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [formValues, setFormValues] = useState({
        action_type: config.MODULE_TYPE_NAME,
        lead_id: "",
        incident_category: "",
        type_topic: "",
        description: "",
        priority_type: "",
        expected_resolution_date: "",
        assigned_group: "",
        assigned_user: "",
        name: "",
        mobile: "",
        email: "",
        address: "",
    });

    const handleExpectedResolutionDateChange = (date) => {
        setExpectedResolutionDate(date);
    };

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Request');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(leadId !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.incidentReportStatusData(requestData);

        response.then((result) => {
            // console.log("Promise resolved -  lead status data :", result); 
            if (result.data.success === 1) {
                setAllIncidentReportStatusData(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllIncidentReportStatusData(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  lead status data :", error); 
        });
    }, [setAllIncidentReportStatusData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'category_for': '2' };
        const requestData = encryptData(obj);
        const response = actions.incidentCategoryData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data
                const filteredResultArr = results.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.incident_category.localeCompare(b.incident_category)
                );
                setAllIncidentCategoryData(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
      }, [setAllIncidentCategoryData]);
    
      useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData, false);
        response.then((result) => {
          // console.log("Promise resolved -  groupData list:", result);
          if (result.data.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
              a.group_name.localeCompare(b.group_name)
            );
            setAllGroupData(sortedResultArr);
          }
        }).catch((error) => {
          console.error("Promise rejected -  lead list:", error);
        });
    
      }, [setAllGroupData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.incidentPriorityData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllIncidentpriorityData(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllIncidentpriorityData]);

    const handleChangeAssignedGroup = (event, actionName = '') => {
        const selectedValue = event.target.value;
        setSelectedAssignedGroup(selectedValue);

        if (selectedValue !== "") {

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id": selectedValue , "tag_name": "get_all_group_user"};
            const requestData = encryptData(obj);

            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);

                        console.log("resultArr : ", resultArr);
                        // setAllUsersListData(usersListData[0].users_list);
                    }
                    setSelectedAssignedGroup(selectedValue);
                    if (actionName !== 'selectedUser') {
                        setValue("assigned_user", "");
                    }
                }
            }).catch((error) => {
                console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
        }
    };

    const checkMobileNumber = () => {
        const mobile = document.getElementById('phone').value;

        if (mobile.length === 0) {
            toast.error("Please enter mobile number.", {
                position: "top-right",
            });
        } else {
            const obj = { 'mobile': mobile };
            const requestData = encryptData(obj);
            const response = actions.checkModuleCount(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  checkModuleCount list:", result);
                if (result.data.success === 1) {
                    const incidentData = result.data.data;

                    if (Object.prototype.hasOwnProperty.call(incidentData, 'id') && incidentData.id) {
                        setLeadId(incidentData.id);
                    } else {
                        setLeadId("");
                    }
                    if (Object.prototype.hasOwnProperty.call(incidentData, 'name') && incidentData.name) {
                        setLeadName(incidentData.name);
                    } else {
                        setLeadName("");
                    }
                    if (Object.prototype.hasOwnProperty.call(incidentData, 'email') && incidentData.email) {
                        setLeadEmail(incidentData.email);
                    } else {
                        setLeadEmail("");
                    }
                    if (Object.prototype.hasOwnProperty.call(incidentData, 'full_address') && incidentData.full_address) {
                        setLeadAddress(incidentData.full_address);
                    } else {
                        setLeadAddress("");
                    }
                    if (Object.prototype.hasOwnProperty.call(incidentData, 'open') && incidentData.open) {
                        setIncidentOpenString(`Opened ${config.MODULE_TYPE_NAME} - ${incidentData.open}`);
                    } else {
                        setIncidentOpenString("");
                    }
                    if (Object.prototype.hasOwnProperty.call(incidentData, 'close') && incidentData.close) {
                        setIncidentCloseString(`Closed ${config.MODULE_TYPE_NAME} - ${incidentData.close}`);        
                    } else {
                        setIncidentCloseString(""); 
                    }

                    toast.success(result.data.message, {
                        position: "top-right",
                    });

                } else {
                    setLeadId();
                    setLeadName('');
                    setLeadEmail('');
                    setLeadAddress('');
                    setIncidentOpenString('');
                    setIncidentCloseString('');

                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    }

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];
    
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name; // Get the file name

                const parts = base64Data.split(',');
                const base64WithoutHeader = parts[1];

                const extension = fileName.split('.').pop().toLowerCase();
                if (extension === 'docx' || extension === 'doc') {
                    base64Files.push({ file: `data:application/document;base64,${base64WithoutHeader}`, name: fileName });
                } else if (extension === 'xlsx' || extension === 'xls') {
                    base64Files.push({ file: `data:application/sheet;base64,${base64WithoutHeader}`, name: fileName });
                } else {
                    base64Files.push({ file: base64Data, name: fileName });
                }
                
                if (base64Files.length === files.length) {
                    // Now, base64Files contains both data and name for each file
                    setSelectedFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };


    // 

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (incidentReporteId !== undefined) {
            const decodedIncidentReportId = atob(incidentReporteId);
            setIncidentReporteId(decodedIncidentReportId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'incident_report_id': decodedIncidentReportId };
            const requestData = encryptData(obj);
            const response = actions.getModuleDataWithcomments(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data.incident_report[0];

                    setLeadName(incidentReporte.name);
                    setLeadEmail(incidentReporte.email);
                    
                    setValue("name", incidentReporte.name);
                    setValue("due_date", incidentReporte.due_date);
                    setValue("incident_category", incidentReporte.incident_category);
                    setValue("description", incidentReporte.description);
                    setValue("priority_type", incidentReporte.priority_type);
                    setValue("email", incidentReporte.email);

                    setValue("assigned_group", incidentReporte.assigned_group);
                    handleChangeAssignedGroup({ target: { value: incidentReporte.assigned_group } }, 'selectedUser');

                    const assignToString = incidentReporte.assigned_user;
                    const assignToArray = assignToString.split(',');
                    setSelectedAssignTo(assignToArray);
                    setNumbersArray(incidentReporte.selected_assigned_user);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        } else {
            setIncidentReporteId('');
        }
    }, [incidentReporteId, setValue, setLeadName, setSelectedAssignTo, setNumbersArray]);

    const onSubmit = (data) => {

        const assignTo = JSON.stringify(selectedAssignTo);

        const mobile = document.getElementById('phone').value;

        if (leadName === "") {
            toast.error("Job Name is blank.", {
                position: "top-right",
            });
        } else if (data.due_date.trim() === "" || data.due_date === '0000-00-00') {
            toast.error("Due Date is blank.", {
                position: "top-right",
            });
        } else if (data.description.trim() === "") {
            toast.error("Description is blank.", {
                position: "top-right",
            });
        } else if (selectedAssignedGroup.trim() === "") {
            toast.error("Please select group.", {
                position: "top-right",
            });
        } else if (selectedAssignedGroup.trim() !== "" && (!selectedAssignTo || selectedAssignTo.length === 0)) {
            toast.error("Please select user.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);

            const params = {
                "action_type": config.MODULE_TYPE_NAME,
                "lead_id": getLeadId,
                "incident_category": data.incident_category,
                "type_topic": data.type_topic,
                "description": data.description,
                "priority_type": data.priority_type,
                "expected_resolution_date": expectedResolutionDate,
                "assigned_group": selectedAssignedGroup,
                "assigned_user": assignTo,
                "name": leadName,
                "mobile": mobile,
                "email": leadEmail,
                "address": leadAddress,
                "upload_files": selectedFiles,
                "due_date": data.due_date,
            };
            
            if (getIncidentReporteId !== '') {
                params.incident_reporte_id = getIncidentReporteId;
            }

            // console.log("params : ", params);



            const requestData = encryptData(params);
            const response = actions.addEditModule(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                    navigate('/dashboard/module-listing-page');
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleLeadMobile = (e) => {
        setMobile(e.target.value);
    };

    const handleLeadName = (e) => {
        setLeadName(e.target.value);
    };

    const handleLeadEamil = (e) => {
        setLeadEmail(e.target.value);
    };

    const handleLeadAddress = (e) => {
        setLeadAddress(e.target.value);
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title>{getIncidentReporteId ? "Edit " : "Create "} {config.MODULE_TYPE_NAME}</title>
            </Helmet>

            <Container>

                <div className='row'>

                    <div className='col-md-6'>
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            {getIncidentReporteId ? "Edit " : "Create "} {config.MODULE_TYPE_NAME}
                        </Typography>
                    </div>
                    <div className='col-md-6 flex-container'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.open('https://www.loom.com/share/ca221d9ef88840259f76e4126787879d?sid=5e60bfbc-48d0-4128-bdbd-950910de688f', '_blank')}
                        >
                            Tutorial  Video 1
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.open('https://www.loom.com/share/54543aad1508474cbab74b46fd281f38?sid=0ca295c7-346c-4f88-b4c1-954983349b79', '_blank')}
                        >
                            Tutorial  Video 2
                        </Button>
                    </div>
                </div>

                <div id='main-wrap' className='mb-4'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form onSubmit={handleSubmit(onSubmit)} className="row" encType="multipart/form-data">

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="name">
                                            <span>{config.MODULE_TYPE_NAME} Name <a style={{ color: "red" }}>*</a></span>
                                            <input type='text' id='name' name='name' className='form-control' value={leadName} onChange={handleLeadName}/>
                                        </label>
                                        {errors.name && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="due_date">
                                            <span>Due Date <a style={{ color: "red" }}>*</a></span>
                                            <input type="date" id="due_date" name="due_date" className="form-control" {...register('due_date')}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="reporting-user">
                                            <span>{config.MODULE_TYPE_NAME} Category <a style={{ color: "red" }}>*</a></span>
                                            <select name='incident_category' className="form-select" {...register('incident_category', { required: true })}>
                                                <option value="">Select {config.MODULE_TYPE_NAME} Category</option>
                                                {getAllIncidentCategoryData.length > 0 && getAllIncidentCategoryData.map((option) => (
                                                    <option value={option.id}>
                                                        {option.incident_category}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        {errors.incident_category && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col-md-12 d-none'>
                                    <div className='form-group'>
                                        <label htmlFor="name">
                                            <span>Type topic of the {config.MODULE_TYPE_NAME} <a style={{ color: "red" }}>*</a></span>
                                            <input type='text' name='type_topic' className='form-control'
                                                {...register('type_topic')} />
                                        </label>
                                        {errors.type_topic && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor="query">
                                            <span>Type detailed description <a style={{ color: "red" }}>*</a></span>
                                            <textarea
                                                id='description'
                                                name='description'
                                                className='form-control'
                                                {...register('description')}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="priority-type">
                                            <span>Set Priority <a style={{ color: "red" }}>*</a></span>
                                            <select name='priority_type' className="form-select" {...register('priority_type', { required: true })}>
                                                <option value="">Select Priority</option>
                                                {getAllIncidentpriorityData.length > 0 && getAllIncidentpriorityData.map((option) => (
                                                    <option value={option.id}>
                                                        {option.incident_priority}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        {errors.priority_type && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="upload_files">
                                            <span>File Upload</span>
                                            <input type='file' multiple id='upload_files' name='upload_files' className='form-control' onChange={handleItrFileChange} />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-6 d-none'>
                                    <div className='form-group'>
                                        <label htmlFor="expected_resolution_date">
                                            <span>Resolution Date/Time</span>
                                            { /* <input type='date' id='expected_resolution_date' name='expected_resolution_date' className='form-control' {...register('expected_resolution_date')} /> */}
                                            <DatePicker selected={expectedResolutionDate} className='form-control'
                                                onChange={handleExpectedResolutionDateChange}
                                                showTimeSelect
                                                dateFormat="Pp"
                                                minDate={new Date()}
                                                id='expected_resolution_date' name='expected_resolution_date' />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="assigned-group">
                                            <span>Assigned group <a style={{ color: "red" }}>*</a></span>
                                            <select
                                                id='assigned_group'
                                                name='assigned_group'
                                                className="form-select"
                                                {...register('assigned_group')}
                                                value={selectedAssignedGroup}
                                                onChange={handleChangeAssignedGroup}
                                            >
                                                <option value=''>Select assigned group</option>
                                                {allGroupData.map((option) => (
                                                    <option value={option.id}>
                                                        {option.group_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <span>Assign to <a style={{ color: "red" }}>*</a></span>
                                        <div className="text-dark">
                                            <Multiselect
                                                id="assign_to"
                                                options={getAllUsersListData.map((option) => ({
                                                    key: option.user_id,
                                                    value: option.name,
                                                }))}
                                                selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                                displayValue="value"
                                                onSelect={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedAssignTo(selectedValues);
                                                }}
                                                onRemove={(selectedItems) => {
                                                    const selectedValues = selectedItems.map(item => item.key);
                                                    setSelectedAssignTo(selectedValues);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6  d-none'>
                                    <div className='form-group'>
                                        <label htmlFor="phone">
                                            <span>Phone Number</span>
                                            <input type='tel' id='phone' name='phone' className='form-control' onChange={handleLeadMobile}/>
                                        </label>
                                        {errors.phone && <span className="required">This field is required.</span>}
                                    </div>
                                </div>
                                
                                <div className='col-md-6  d-none'>
                                    <span className='d-md-block d-none'>&nbsp;</span>
                                    <div className='form-group mb-2 d-flex'>
                                        <div>
                                            <button type="button" className="btn btn-success" onClick={() => checkMobileNumber()}>
                                                Check Phone Number
                                            </button>
                                        </div>

                                        <div className='d-inline-block'>
                                            <span className="d-block ms-3">{incidentOpenString}</span>
                                            <span className="d-block ms-3">{incidentCloseString}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="email_id">
                                            <span>Email Id</span>
                                            <input type='text' id='email_id' name='email_id' className='form-control' value={leadEmail} onChange={handleLeadEamil}/>
                                        </label>
                                        {errors.email_id && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col-md-12  d-none'>
                                    <div className='form-group'>
                                        <label htmlFor="mobile">
                                            <span>Address</span>
                                            {/* <input type='text' id='address' name='address' className='form-control' value={leadAddress} onChange={handleLeadAddress}/> */}
                                            <textarea
                                                id='address'
                                                name='address'
                                                className='form-control'
                                                value={leadAddress}
                                                onChange={handleLeadAddress}
                                            />
                                        </label>
                                        {errors.address && <span className="required">This field is required.</span>}
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group mb-2'>
                                        <button className='btn btn-primary'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        checklistsData: state.checklistsData,
        incidentReporteData: state.incidentReporteData,
        getUserModuleData: state.getUserModuleData,
        leadInfo: state.leadInfo,
        groupsUsersData: state.groupsUsersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, incidentReporteActions.default, groupsUsersActions.default, moduleAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModuleCreatePage);