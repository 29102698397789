import { notice, check, toast } from "react-interaction";
// import config from "../../config";

export const setToaster = (value) => {
  notice(value, {
    okStyle: {
      backgroundColor: "#103996",
      color: "#fff",
    },
    okText: "Cancel",
    contentClassName: "account-logout",
    contentStyle: {
      width: 450,
    },
  });
};

export const checkConform = async (callback, message) => {
  try {
    const isConfirmed = await check(message, {
      okStyle: {
        backgroundColor: "#103996",
        color: "#fff",
      },
      contentClassName: "account-logout",
      contentStyle: {
        width: 500,
      },
      okText: "Yes",
      cancelClassName: "my-check-cancel",
      cancelStyle: {
        backgroundColor: "#ccc",
        color: "#fff",
      },
      cancelText: "No",
    });

    if (isConfirmed) {
      callback();
    }
  } catch (error) {
    alert(error);
  }
};

export const showToaster = (value) => {
  toast(value, { time: 2000 });
};

export const renderTitle = (title) => {
  if (!title) {
    return "";
  }

   return title;
};

export const catchError = (response) => {
  if (response instanceof Error) {
    throw new Error(response.response?.data?.message || "An error occurred");
  }
};
 