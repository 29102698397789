import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadBankStatementByLeadDataReducer(state = initialState.leadBankStatementByLeadData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_BANK_STATEMENT_BY_LEAD_DATA_SUCCESS:
      return action.leadBankStatementByLeadData;
    default:
      return state;
  }
}
