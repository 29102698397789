import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Chart from 'react-apexcharts';

import {
    PieChart,
    Pie,
    Tooltip,
    BarChart,
    XAxis,
    YAxis,
    Legend,
    CartesianGrid,
    Bar,
    ResponsiveContainer,
    Cell,
    Label,
    LabelList,
} from "recharts";

import { scaleOrdinal } from 'd3-scale'; // Import 'scaleOrdinal' from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import AccessDenied from '../common/AccessDenied';

// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const IncidentDashboardPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, incidentDashboard, usersData } = props;
    const [allUsers, setAllUsers] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [incidentCategoryData, setincidentCategoryData] = useState([]);
    const [incidentCommentStatusData, setIncidentCommentStatusData] = useState([]);

    const [incidentCommentStatus, setIncidentCommentStatus] = useState([]);
    const [incidentCommentCount, setIncidentCommentCount] = useState([]);

    const [assignedMeStatus, setAssignedMeStatus] = useState([]);
    const [assignedMeCount, setAssignedMeCount] = useState([]);

    const [totalIncidentCount, setTotalIncidentCount] = useState("");
    const [assignedIncidentCount, setAssignedIncidentCount] = useState("");
    const [incidentDate, setIncidentDate] = useState("");

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Incident Dashboard')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const today = new Date();

        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 6);

        const previousDayFormatted = formatDate(previousDay);
        const todayFormatted = formatDate(today);

        setValue('start_date', previousDayFormatted);
        setValue('end_date', todayFormatted);

        setStartDate(previousDayFormatted);
        setEndDate(todayFormatted);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': previousDayFormatted, 'end_date': todayFormatted };
        const requestData = encryptData(params);
        const response = actions.incidentDashboard(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved - incidentDashboard", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                if ('incident_category_res' in results) {
                    setincidentCategoryData(results.incident_category_res);
                }

                if ('incidentc_comment_status_res' in results) {
                    setIncidentCommentStatusData(results.incidentc_comment_status_res);

                    const leadStatusName = [];
                    const leadStatusValue = [];

                    const incidentStatusData = results.incidentc_comment_status_res;

                    if (Array.isArray(incidentStatusData) && incidentStatusData.length > 0) {

                        for (let i = 0; i < incidentStatusData.length; i += 1) {
                            leadStatusName.push(incidentStatusData[i].incidentCommentStatus);
                            leadStatusValue.push(incidentStatusData[i].incidentCommentStatusCount);
                        }

                        setIncidentCommentStatus(leadStatusName);
                        setIncidentCommentCount(leadStatusValue);
                    }
                }

                if ('final_count_data' in results) {
                    const finalCountData = results.final_count_data;
                    setTotalIncidentCount(finalCountData.totalIncidentCounts);
                    setAssignedIncidentCount(finalCountData.assignedIncidentCounts);
                    setIncidentDate(finalCountData.incidentDates);
                }
            }
        }).catch((error) => {
            console.error("Promise rejected - incidentDashboard", error);
            toast.error(error, {
                position: "top-right",
            });
        });

    }, [setincidentCategoryData, setIncidentCommentStatusData, setIncidentCommentStatus, setIncidentCommentCount, setTotalIncidentCount, setAssignedIncidentCount, setIncidentDate]);
    
    const onSubmit = (data) => {

        setIncidentCommentStatus([]);
        setIncidentCommentCount([]);

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': data.start_date, 'end_date': data.end_date };
        const requestData = encryptData(params);
        const response = actions.incidentDashboard(requestData, false);
        response.then((result) => {

            // setDate(data.date);
            // console.log("Promise resolved - incidentDashboard", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                if ('incident_category_res' in results) {
                    setincidentCategoryData(results.incident_category_res);
                }
                if ('incidentc_comment_status_res' in results) {
                    setIncidentCommentStatusData(results.incidentc_comment_status_res);

                    const leadStatusName = [];
                    const leadStatusValue = [];

                    const incidentStatusData = results.incidentc_comment_status_res;

                    if (Array.isArray(incidentStatusData) && incidentStatusData.length > 0) {

                        for (let i = 0; i < incidentStatusData.length; i += 1) {
                            leadStatusName.push(incidentStatusData[i].incidentCommentStatus);
                            leadStatusValue.push(incidentStatusData[i].incidentCommentStatusCount);
                        }

                        setIncidentCommentStatus(leadStatusName);
                        setIncidentCommentCount(leadStatusValue);
                    }
                }

                if ('final_count_data' in results) {
                    const finalCountData = results.final_count_data;
                    setTotalIncidentCount(finalCountData.totalIncidentCounts);
                    setAssignedIncidentCount(finalCountData.assignedIncidentCounts);
                    setIncidentDate(finalCountData.incidentDates);
                }
            }
        }).catch((error) => {
            console.error("Promise rejected - incidentDashboard", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    };

    const incidentCategoryChartDataFormatted = incidentCategoryData.map((entry, index) => ({
        name: entry.incidentName,
        incidentId: entry.incidentId,
        leadCount: parseInt(entry.incidentCount, 10), // Convert lead_count to an integer
        color: theme.palette[index % theme.palette.length]?.main || '#000', // Use a fallback color if 'main' is not available
    }));

    const colorScale = scaleOrdinal(schemeCategory10);

    const assignedIncident = assignedIncidentCount.split(',').map(value => parseInt(value.trim(), 10));
    const totalIncident = totalIncidentCount.split(',').map(value => parseInt(value.trim(), 10));
    const incidentDateArray = incidentDate.split(',');
    
    const state =
    {
        series: [{
            name: 'Assigned to me',
            type: 'column',
            data: assignedIncident
        }, {
            name: 'Total Incident',
            type: 'column',
            data: totalIncident
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 4]
            },
            xaxis: {
                categories: incidentDateArray,
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            ],
            tooltip: {
                fixed: {
                    enabled: false,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            }
        },


    };

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>cKers | Incident Dashboard</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Incident Dashboard
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date</span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id='main'>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <div id='main-wrap'>
                                <h5 className="fw-600 mb-0">Incident Comment Status</h5>

                                <Chart type="bar" height={315}
                                    series={[
                                        {
                                            name: "Number Of Incident",
                                            data: incidentCommentCount,
                                        },
                                    ]}
                                    options={{
                                        colors: ["#3399FF"],
                                        theme: { mode: "light" },
                                        chart: {
                                            toolbar: {
                                                show: false
                                            }
                                        },
                                        xaxis: {
                                            tickPlacement: "on",
                                            categories: incidentCommentStatus,
                                            // title: {
                                            //   text: "Lead Status",
                                            //   style: { fontSize: "15" },
                                            // },
                                            labels: {
                                                rotateAlways: true,
                                                rotate: -35,
                                                style: {
                                                    fontSize: "10",
                                                },
                                            },
                                        },

                                        yaxis: {
                                            labels: {
                                                formatter: (val) => {
                                                    return `${val}`;
                                                },
                                                style: { fontSize: "15" },
                                            },
                                        },

                                        legend: {
                                            show: true,
                                            position: "right",
                                        },

                                        dataLabels: {
                                            formatter: (val) => {
                                                return `${val}`;
                                            },
                                            style: {
                                                fontSize: "15",
                                            },
                                        },
                                        plotOptions: {
                                            bar: {
                                                columnMaxWidth: "30%", // You can adjust this value to control the column width
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-md-6 mb-4'>
                            <div id='main-wrap'>
                                <h5 className="fw-600 mb-0">Incident by Category</h5>
                                <ResponsiveContainer width="100%" height={328}>
                                    <PieChart>
                                        {/* Add your Legend and other configurations here */}
                                        <Legend
                                            iconSize={10}
                                            layout="vertical"
                                            verticalAlign="middle"
                                            align="right"
                                            wrapperStyle={{ marginRight: '20px' }}
                                        />
                                        <Pie dataKey="leadCount" data={incidentCategoryChartDataFormatted} cx="50%" cy="50%" outerRadius={80} labelLine={false}>
                                            {incidentCategoryChartDataFormatted.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={colorScale(index)}
                                                // onClick={() => handlePieSegmentClick(entry.sourceId, entry.name, "source")}
                                                />
                                            ))}
                                            <LabelList dataKey="leadCount" position="inside" />
                                        </Pie>

                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className='col-md-12 mb-4'>
                            <div id='main-wrap'>
                                <h5 className="fw-600 mb-0">Incident Reports</h5>
                                <div id="chart">
                                    <Chart options={state.options} series={state.series} type="line" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        incidentDashboard: state.incidentDashboard,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentDashboardPage);