import { useState, useEffect } from 'react';
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button, Typography, Container, Box, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import firebase  from '../../../firebase';
// import { css } from '@emotion/react';
// components
import * as userActions from "../../../actions/userActions";
import Iconify from '../../../components/iconify';
import config from "../../../config";
import { encryptData } from '../../../utils/cryptoUtils';


// ----------------------------------------------------------------------
const LoginForm = (props) => {
  const { actions, userInfo } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [fCMtoken, setFCMToken] = useState('');

  const [requiredOpenModal, setRequiredOpenModal] = React.useState(false);
  const [formResetData, setFormResetData] = useState({ reset_email: '' });
  const [pageURL, setPageURL] = useState(0);
  const [roleIdWithName, setRoleIdWithName] = useState([]);
  const [roleSelectOpenModal, setRoleSelectOpenModal] = React.useState(false);
  const [formRoleData, setFormRoleData] = useState({ role: '' });

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    // console.log("LoginForm authToken : ", authToken);

    if (authToken && authToken !== '') {
      
      if (Object.prototype.hasOwnProperty.call(userInfo, 'roleIdWithName') && Array.isArray(userInfo.roleIdWithName) && userInfo.roleIdWithName.length > 1) {
        setRoleIdWithName(userInfo.roleIdWithName);
        setRoleSelectOpenModal(true);
      } else {
        navigate('/dashboard/app');
      }
    }
  }, [userInfo]);

  useEffect(() => {
    setPageURL(window.location.href);
  })

  
  useEffect(() => {
    const FCMTokenSession = localStorage.getItem("FCMToken");
    const PUSHALERTTOKENSession = localStorage.getItem("PUSH_ALERT_TOKEN");
    console.log("Login form PUSH_ALERT_TOKENSession = ",PUSHALERTTOKENSession)
    setFCMToken(FCMTokenSession); 
  }, []);  

  const onSubmit = (data) => {
    const PUSHALERTTOKENSession = localStorage.getItem("PUSH_ALERT_TOKEN");
    const obj = { 'email': data.email.toLowerCase(), 'password': data.password, 'firebase_token': fCMtoken, 'push_alert_subs_id': PUSHALERTTOKENSession };

    const requestData = encryptData(obj);
    actions.userLoginData(requestData);
  };

  const clickActionModal = (actionName) => { 

    // console.log("actionName : ", actionName);

    setRequiredOpenModal(true);
  }

  const handleRequiredClose = () => { 
    setRequiredOpenModal(false);
    setFormResetData({
      reset_email: ''
    });
  }

  const handleRoleSelctClose = () => { 
    setRoleSelectOpenModal(false);
    setFormRoleData({
      role: ''
    });
  }

  const handleResetSubmitForm = (event) => {
    event.preventDefault();

    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formResetData.reset_email.trim());

    if(formResetData.reset_email.trim() === ""){
      toast.error("Email address is blank.", {
        position: "top-right",
      });
    } else if(!isEmailValid) {
      toast.error("Email address not valid.", {
        position: "top-right",
      });
    } else {

      const newPath = pageURL.replace('/login', '');

      const obj = { 'reset_email': formResetData.reset_email.toLowerCase(), "page_url": newPath};
      // console.log("obj : ", obj);
      
      const requestData = encryptData(obj);
      const response = actions.userResetPasswordSendEmail(requestData);
      response.then((result) => {
        // console.log("Promise resolved - add/edit lead form:", result); 
        if (result.data.success === 1) {
          toast.success(result.data.message, {
            position: "top-right",
          });
          setRequiredOpenModal(false);
          setFormResetData({
            reset_email: ''
          });
        } else {
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    }
    
    
  }

  const handleChangeResetForm = (event) => {
    const { name, value } = event.target;
    setFormResetData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeRoleForm = (event) => {
    const { name, value } = event.target;
    setFormRoleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRoleSubmitForm = (event) => {
    event.preventDefault();

    if (formRoleData.role.trim() === "") {
      toast.error("Please select role.", {
        position: "top-right",
      });
    } else {
      // localStorage.setItem("roleId", formRoleData.role);

      const params = {
        "roleId": formRoleData.role,
      }

      const requestData = encryptData(params);
      const response = actions.updateUserActiveRole(requestData);

      response.then((result) => {
        // console.log("Promise resolved - addEditVisitePlan form:", result); 
        if (result.data.success === 1) {
          toast.success(result.data.message, {
            position: "top-right",
          });
          setTimeout(() => {
            navigate('/dashboard/app');
          }, 1000);
        } else {
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    }
  }

  const handleLoginSuccess = (credentialResponse) => {

    const PUSHALERTTOKENSession = localStorage.getItem("PUSH_ALERT_TOKEN");

    const obj = { "credentialResponse": credentialResponse, 'firebase_token': fCMtoken, 'push_alert_subs_id': PUSHALERTTOKENSession };
    const requestData = encryptData(obj);
    const response = actions.userLoginGoogleauth(requestData);
    
  };

  const handleLoginFailure = () => {
    console.log('Google Login Failed');
    toast.error("Google login failed!");
  };
  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            {...register('email', {
              required: true,
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            })}
          />
          {errors.email && <span className="required mt-1">This field is required.</span>}

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', { required: true, minLength: 3 })}
          />
          {errors.password && <span className="required mt-1">This field is required.</span>}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <span><Checkbox name="remember" label="Remember me"/>Remember me</span>
          <Link variant="subtitle2" className='cursor-pointer' underline="hover" onClick={() => clickActionModal('forgot_password')}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>

      </form>

      <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
        <h1>&nbsp;</h1>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </GoogleOAuthProvider>

      <Modal
        open={requiredOpenModal}
        onClose={handleRequiredClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className='modal-custom rounded'>
          <div className='modal-head'>
            <h4 id="modal-title">Reset Passowrd</h4>
            <Button onClick={handleRequiredClose} color="primary" className=' btn'>
              <i className="bi bi-x">&nbsp;</i>
            </Button>
          </div>

          <div className='modal-body checklist-popup'>
            <div className='scroll-content pb-2'>
              <div className='row p-1'>
                <div className='col-12 mb-3'>Enter the email address associated with your account and we'll send you a link to reset your password.</div>
              </div>

              <form onSubmit={handleResetSubmitForm} className="" encType="multipart/form-data">
                
                  <Stack spacing={3}>
                    <TextField id='reset_email'
                            name='reset_email'
                            onChange={handleChangeResetForm}
                            value={formResetData.reset_email}
                            label="Email address"/>
                    {errors.reset_email && <span className="required mt-1">This field is required.</span>}
                  </Stack>
                <div className='col-md-12'>
                  {/* <div className='form-group'>
                      <label htmlFor="reset_email">
                          <span>Email Address<a style={{ color: "red" }}>*</a></span>
                          <input
                            type='text'
                            id='reset_email'
                            name='reset_email'
                            className='form-control'
                            onChange={handleChangeResetForm}
                            value={formResetData.reset_email}
                            {...register('reset_email', { required: true})}
                          />
                      </label>
                  </div> */}
                </div>

                <div className="col-md-12">
                  <div className="form-group mb-0">
                    <button className="btn btn-primary mt-3">Send Request</button>
                  </div>
                </div>
              </form>
            </div>


          </div>
        </div>
      </Modal>

      <Modal
        open={roleSelectOpenModal}
        onClose={handleRoleSelctClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className='modal-custom rounded'>
          <div className='modal-head'>
            <h4 id="modal-title">Login Succesful.</h4>
            <Button onClick={handleRoleSelctClose} color="primary" className=' btn'>
              <i className="bi bi-x">&nbsp;</i>
            </Button>
          </div>

          <div className='modal-body checklist-popup'>
            <div className='scroll-content pb-2'>
              <form onSubmit={handleRoleSubmitForm} className="" encType="multipart/form-data">

                <div className='row'>
                  <div className="col-md-9">
                    <div className="form-group mb-3">
                      <label htmlFor="approval-master">
                        <span>Please select the role you want to login with. <a style={{ color: "red" }}>*</a></span>
                        <select
                          id='role'
                          name='role'
                          className="form-select"
                          onChange={handleChangeRoleForm}
                          value={formRoleData.role}
                        >
                          <option value=''>Select Role</option>
                          {roleIdWithName.map((option) => (
                            <option value={option.role_id} key={option.role_id}>
                              {option.role_name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-0">
                      <button className="btn btn-primary mt-3">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </Modal>

    </>
  );
}

function mapStateToProps(state) {
  return {
    userLogin: state.userLogin,
    userParams: state.userParams,
    deviceToken: state.deviceToken,
    userInfo: state.userInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
;
