import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";


export function guarantorDataSuccess(guarantorData) {
    return { type: types.LOAD_GUARANTOR_DATA_SUCCESS, guarantorData };
}


const addEditGuarantor = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {  
      const response = await agent.post(API.CREATE_GUARANTOR, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}


const guarantorData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {  
        response = await agent.post(API.GET_GUARANTOR, params);
      } else {
        response = await agent.post(API.GET_GUARANTOR, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(guarantorDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};




  export default {    
    addEditGuarantor,
    guarantorDataSuccess,
    guarantorData,
    
  }