import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const DocumentTypeAddEdit = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, checklistsData, masterDocumentTypeData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [documentTypeId, setDocumentTypeId] = useState('');
    const [showDocumentTypeDetails, setShowDocumentTypeDetails] = useState(false);

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Document Type');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);


    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);

        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setShowDocumentTypeDetails(false);
        if (id !== undefined && !showDocumentTypeDetails) {
            const decodedId = atob(id);
            setDocumentTypeId(decodedId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'document_type_id': decodedId };
            const requestData = encryptData(obj);

            const response = actions.masterDocumentTypeData(requestData,false);
            response.then((result) => {
                // console.log("Promise resolved -  source data:", result); 
                if (result.data.success === 1) {
                    setShowDocumentTypeDetails(true);
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    setValue("document_type", resultArr[0].document_type);
                    setValue("prefill_template_type", resultArr[0].prefill_template_type);
                    setValue("status", resultArr[0].status);

                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });
        }
    }, [id, setValue, setShowDocumentTypeDetails]);

    const onSubmit = (data) => {

        if (data.document_type.trim() === "") {
            toast.error("Document Type is blank.", {
                position: "top-right",
            });
        } else {
            const params = { 'document_type': data.document_type, "status": data.status };

            if (documentTypeId !== '') {
                params.document_type_id = documentTypeId;
            }

            const requestData = encryptData(params);
            const response = actions.addEditDocumentTypeData(requestData);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setTimeout(() => {
                        navigate('/dashboard/document-type');
                    }, 1000);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title>{showDocumentTypeDetails ? "Edit Document Type" : "Create Document Type"}</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    {showDocumentTypeDetails ? "Edit Document Type" : "Create Document Type"}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="campaign-name">
                                        <span>Document Type <a style={{ color: "red" }}>*</a></span>
                                        <input type='text' id='document_type' className='form-control' {...register('document_type', { required: true })} />
                                    </label>
                                    {errors.document_type && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="reporting-user">
                                        <span>Status <a style={{ color: "red" }}>*</a></span>
                                        <select name='status' className="form-select" {...register('status', { required: true })}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </label>
                                    {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        masterDocumentTypeData: state.masterDocumentTypeData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeAddEdit);