import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import * as userActions from "../../actions/userActions";
import AccessDenied from '../common/AccessDenied';

import * as leadGstActions from "../../actions/leadGstActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const UploadGST = (props) => {
  const { leadId, id } = useParams();
  const location = useLocation();
  const { actions, usersData, fetchLeadIdGst, allLeadGstByLeadData } = props;
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [selectedGstFiles, setSelectedGstFiles] = useState([]);
  const [getLeadId, setLeadId] = useState('');
  const [getLeadGstId, setLeadGstId] = useState('');
  const [getAllLeadGstByLeadData, setAllLeadGstByLeadData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const formRef = useRef(null);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  const handleGstFileChange = (e) => {
    const files = Array.from(e.target.files);
    const base64Files = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result;
        const fileName = file.name;
        base64Files.push({ file: base64Data, name: fileName });
        if (base64Files.length === files.length) {
          setSelectedGstFiles(base64Files);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = (data) => {

    const leadId = props.leadId;
    setLeadId(leadId);

    if (!getLeadGstId && (!selectedGstFiles || selectedGstFiles.length === 0)) {
      toast.error("No file has been selected.", {
        position: "top-right",
      });
    } else if (data.gst_note.trim() === "") {
      toast.error("Note is blank.", {
        position: "top-right",
      });
    } else {

      const params = { "lead_id": leadId, "gst_note": data.gst_note, "gst_file": selectedGstFiles };

      if (getLeadGstId !== '') {
        params.lead_gst_id = getLeadGstId;
      }

      const requestData = encryptData(params);
      const response = actions.addEditLeadGst(requestData, navigate);

      response.then((result) => {
        // console.log("Promise resolved - add/edit lead form:", result); 
        if (result.data.success === 1) {
          toast.success(result.data.message, {
            position: "top-right",
          });
          const leadGstId = result.data.lead_gst_id;
          // setLeadGstId(leadGstId);
          fetchLeadIdGst();
          reset();
          setSelectedGstFiles("");
          props.updateParentState(false);
        } else {
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredItems = (allLeadGstByLeadData ?? []).filter(item =>
    item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
  const endIndex = startIndex + config.ITEMS_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

  const docViewUrl = `${config.API_BASE_URL}uploads/lead_gst_document/`;

  const onRowClickAction = (itemObj, btnAction) => {
    if (btnAction === 'edit') {

      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      setLeadGstId(itemObj.gst_id);
      setValue("gst_file", docViewUrl + itemObj.gst_file);      
      setValue("gst_note", itemObj.gst_note);

    } else if (btnAction === "delete") {
      const leadId = props.leadId;
      const obj = { "lead_id": leadId, "id": itemObj.gst_id, "basic_info_type": "gst" };
      const requestData = encryptData(obj);
      const response = actions.deleteLeadBasicInfo(requestData);
      response.then((result) => {
        // console.log("Promise resolved - deleteLeadBasicInfo result:", result);
        if (result.data.success === 1) {
          toast.success(result.data.data.message, {
            position: "top-right",
          });
          fetchLeadIdGst();
          props.updateParentState(false);
        } else {
          toast.error(result.data.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    }
  }

  const handleNewButton = () => {
    setLeadGstId("");
    setSelectedGstFiles("");
    reset();
  }

  return (
    <>
      <h5 className="fw-bold">Upload GST</h5>

      <div id="main-wrap" className='mt-4'>
        <div className="table-wrap">
          <table className="table table-hover">
            <thead>
              <tr>
                {/* <th>GST ID</th> */}
                <th>GST UID</th>
                <th>Created Date</th>
                <th>View File</th>
                {!props.showActionLeadInfo && (
                  <th>Action</th>
                )}
              </tr>
            </thead>

            {paginatedItems.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>
                    <h5>No GST</h5>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {paginatedItems.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{item.formatetdID}</td> */}
                    <td>{item.uid}</td>
                    <td>{item.created_date}</td>
                    <td>
                      <span className="badge text-bg-success text-white">
                        <a target="new" href={docViewUrl + item.gst_file} className='text-success text-decoration-none'>
                          <i className="bi bi-eye">&nbsp;</i>
                        </a>
                      </span>
                    </td>
                    {!props.showActionLeadInfo && (
                      <td>
                        <div className="btn-group dropstart">
                          <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots-vertical">&nbsp;</i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <RouterLink onClick={() => onRowClickAction(item, 'edit')} ><i className="bi bi-pen">&nbsp;</i>Edit</RouterLink>
                            </li>
                            <li>
                              <RouterLink onClick={() => onRowClickAction(item, 'delete')} ><i className="bi bi-trash">&nbsp;</i>Delete</RouterLink>
                            </li>
                          </ul>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <div>
          <nav className='mb-1'>
            <ul className="pagination pagination-md justify-content-end">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <li className="page-item">
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    disabled={currentPage === page}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <div id="main-wrap" className='mt-4'>
        <div className='row justify-content-between'>
          <div className="row">
            <div className='col-md-9'>
              <h5> {getLeadGstId === '' ? 'Add' : "Edit"} GST</h5>
            </div>
            {getLeadGstId !== '' && (
              <div className='col-md-3'>
                <div className='form-group text-end mb-2'>
                  <button className="btn btn-primary" onClick={() => handleNewButton()}>Add New</button>
                </div>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={formRef}>
            <div className="row guarantor" id="bank_statement-1">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="gst_file">
                    <span>File Upload <a style={{ color: "red" }}>*</a></span>
                    <input disabled={props.leadStatusFlag === true} type='file' id='gst_file' name='gst_file' className='form-control' onChange={handleGstFileChange} />
                  </label>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor="gst_note">
                    <span>Notes <a style={{ color: "red" }}>*</a></span>
                    <textarea readOnly={props.leadStatusFlag === true}
                      id='gst_note'
                      className='form-control'
                      placeholder="Type Something" {...register('gst_note')}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <button disabled={props.leadStatusFlag === true} className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    // getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, leadGstActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadGST);
