import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as reportActions from "../../actions/reportActions";
import * as campaignsActions from "../../actions/campaignsActions";
import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadCountPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, sourceData, leadCountData, leadStatusData, campaignData} = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [leadSorceData, setLeadSorceData] = useState([]);
  const [allLeadStatusData, setAllLeadStatusData] = useState([]);
  const [allLeadAssignedUserData, setAllLeadAssignedUserData] = useState([]);
  const [dataTableData, setDataTableData] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);

  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [userRoleType, setUserRoleType] = useState('User');
  const [loggeInUserID, setLoggeInUserID] = useState('');

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
        // console.log("Promise resolved -  lead list:", result);
        if (result.data.success === 1) {
            const permissionsData = result.data.data;
            const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Count')?.view_permission;
            setPermissions(permissionsData);
            setUserHasPermission(hasViewPermissions);

            if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                setUserRoleType(result.data.user_info[0].role_type);
                // alert(result.data.user_info[0].role_type);
                setLoggeInUserID(result.data.user_info[0].user_id);
            }

        }
    }).catch((error) => {
        // console.error("Promise rejected -  lead list:", error);
    });
}, [setPermissions, setUserHasPermission]);
  
  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      const sortedUsers = [...usersData.data].sort((a, b) => a.name.localeCompare(b.name));
      setAllUsers(sortedUsers);
    }
  }, []);

  useEffect(() => {
    const objSource = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestSourceData = encryptData(objSource);
    const responseSource = actions.sourceData(requestSourceData, false);
    responseSource.then((result) => {
      // console.log("Promise resolved -  source data:", result); 
      if (result.data.success === 1) {
        const results = result.data.data.data
        const filteredResultArr = results.filter((item) => item.status === "1");
        const sortedResultArr = filteredResultArr.sort((a, b) =>
          a.source_of_lead.localeCompare(b.source_of_lead)
        );
        const extractedData = sortedResultArr.map(item => {
          return { id: item.id, name: item.source_of_lead };
        });
        const resultArr = Object.values(extractedData);
        setAllLeadSource(resultArr);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  source data :", error); 
    });

  }, [setAllLeadSource]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.campaignData(requestData, false);
    response.then((result) => {
    //   console.log("Promise resolved -  campaignData:", result);
      if (result.data.success === 1) {
        const results = result.data.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        const filteredResultArr = resultArr.filter((item) => item.status === "1");
        const sortedResultArr = filteredResultArr.sort((a, b) =>
            a.campaign_name.localeCompare(b.campaign_name)
        );
        const extractedData = sortedResultArr.map(item => {
          return { id: item.id, name: item.campaign_name };
        });
        const resultArrObj = Object.values(extractedData);
        setAllCampaigns(resultArrObj);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  campaignData :", error); 
    });

}, [setAllCampaigns]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.leadStatusData(requestData);

    response.then((result) => {
      // console.log("Promise resolved -  lead status data :", result); 
      if (result.data.success === 1) {
        setAllLeadStatusData(true);
        const results = result.data.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        setAllLeadStatusData(resultArr);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  lead status data :", error); 
    });

  }, [setAllLeadStatusData]);

  useEffect(() => {
    // setAllLeadStatusData(false);
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getLoggedinUserChild(requestData, false);

    response.then((result) => {
      // console.log("Promise resolved -  lead Status data:", result); 
      if (result.data.success === 1) {
        const results = result.data.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        setAllLoggedinUserChild(resultArr);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllLoggedinUserChild]);

  const onSubmit = (data) => {
    
    const params = { 'user_id': data.user_id, 'type': data.type, 'start_date': data.start_date, 'end_date': data.end_date, "lead_source": selectedLeadSource, "campaign":selectedCampaign };
    const requestData = encryptData(params);
    const response = actions.leadCountData(requestData, false);
    response.then((result) => {
        // console.log("Promise resolved - leadBreakupCountData", result); 
        if (result.data.success === 1) {
          const results = result.data.data;
          if('lead_assigned_user' in results){
            setAllLeadAssignedUserData(results.lead_assigned_user);
          }
          if('lead_res' in results){
            setLeadData(results.lead_res);
          }
          if('lead_resource' in results){
            setLeadSorceData(results.lead_resource);
          }
        }
    }).catch((error) => {
        // console.error("Promise rejected - leadBreakupCountData", error);
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  const leadColumns = [
    {
      name: 'LABLE',
      selector: 'lead_status',
      sortable: true,
    },
    {
      name: 'COUNT',
      selector: 'status_count',
      sortable: true,
    },
  ];

  const leadSourceColumns = [
    {
      name: 'LEAD SOURCE',
      selector: 'source_name',
      sortable: true,
    },
    {
      name: 'COUNT',
      selector: 'lead_count',
      sortable: true,
    },
  ];

    allLeadStatusData.sort((a, b) => {
        return a.lead_status.localeCompare(b.lead_status, 'en', { sensitivity: 'base' });
    });
    const columnNames = allLeadStatusData.map(item => item.lead_status);
    columnNames.unshift("USER", "ASSIGNED");
    const leadAssignedColumns = columnNames.map(columnName => ({
        name: columnName,
        selector: columnName,
        sortable: true,
    }));

    useEffect(() => {
        if (Array.isArray(allLeadAssignedUserData)) {
          const newDataTableData = allLeadAssignedUserData.map(userItem => {
            const rowData = {};
            columnNames.forEach(columnName => {
              rowData[columnName] = userItem[columnName];
            });
            return rowData;
          });
          setDataTableData(newDataTableData);
        } else {
          console.error("allLeadAssignedUserData is not an array or is undefined.");
        }
    }, [allLeadAssignedUserData]);

  const checkLogout = () => {
    const authToken = localStorage.getItem('authToken');
    const logInTimestamp = localStorage.getItem('logInTimestamp');

    if (logInTimestamp !== null) {
      const loginTimestamp = parseInt(logInTimestamp, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - loginTimestamp;
      const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

      if (timeElapsed >= autoLogoutTimeInSecod) {
        const params = { 'login_token': authToken };
        console.log("params : ", params);
        const requestData = encryptData(params);
        const response = actions.userLogout(requestData);
        response.then((result) => {
          if (result.data.success === 1) {
            const data = {};
            actions.loadUserAuth(false);
            actions.loginDataSuccess(data);
            localStorage.setItem("authToken", '');
            localStorage.setItem("logInTimestamp", '');
            navigate("/login");
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }

  useEffect(() => {
      checkLogout();
  });
  
    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

  return (
    <>
      <Helmet>
        <title>Lead Count</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Lead Count
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap' className='mb-3 filter'>
            <div className='row mx-0'>
                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="created-by">
                            <span>All Users</span>
                            <select
                            name="user_id"
                            className="form-select"
                            {...register('user_id', { required: true })}
                            >
                            <option value="">Select User</option>
                            {allLoggedinUserChild.map((option) => (
                                <option value={option.user_id}>
                                {option.name}
                                </option>
                            ))}
                            </select>
                        </label>
                        {errors.user_id && <span className="required">This field is required.</span>}
                    </div>
                </div>

                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="reporting-user">
                            <span>Type</span>
                            <select name='type' className="form-select" {...register('type', { required: true })}>
                            <option value="1">INDIVIDUAL</option>
                            <option value="2">TEAM</option>
                            </select>
                        </label>
                        {errors.type && <span className="required">This field is required.</span>}
                    </div>
                </div>

                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="start-date">
                            <span>Start Date</span>
                            <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                        </label>
                        {errors.start_date && <span className="required">This field is required.</span>}
                    </div>
                </div>

                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor="end-date">
                            <span>End Date</span>
                            <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                        </label>
                        {errors.end_date && <span className="required">This field is required.</span>}
                    </div>
                </div>

                <div className='col'>
                    <div className='form-group'>
                        <span>Lead Source</span>
                        <div className="text-dark">
                          <Multiselect
                            id="lead_profile"
                            options={allLeadSource.map((option) => ({
                                key: option.id,
                                value: option.name,
                            }))}
                            displayValue="value"
                            onSelect={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedLeadSource(selectedValues);
                            }}
                            onRemove={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedLeadSource(selectedValues);
                            }}
                          />
                        </div>
                    </div>
                </div>

                <div className='col'>
                    <div className='form-group'>
                        <span>Campaigns</span>
                        <div className="text-dark">
                          <Multiselect
                            id="lead_profile"
                            options={allCampaigns.map((option) => ({
                                key: option.id,
                                value: option.name,
                            }))}
                            displayValue="value"
                            onSelect={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedCampaign(selectedValues);
                            }}
                            onRemove={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedCampaign(selectedValues);
                            }}
                          />
                        </div>
                    </div>
                </div>
              <div className='col'>
                <div className='form-group mb-2'>
                  <span className='d-block'>&nbsp;</span>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div id='main-wrap-' className='mt-4'>
            <div className='row'>
                <div className='col-md-6'>
                    <div id='main-wrap' className='mb-4 search-filter'>
                      <h5>Results</h5>
                      <DataTableExtensions
                          exportHeaders
                          columns={leadColumns}
                          data={leadData}
                        >
                          <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                          />
                      </DataTableExtensions>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div id='main-wrap' className='mb-4 search-filter'>
                      <h5>Leads Assigned by Source</h5>
                      <DataTableExtensions
                          exportHeaders
                          columns={leadSourceColumns}
                          data={leadSorceData}
                        >
                          <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                          />
                      </DataTableExtensions>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div id='main-wrap' className='mb-4 search-filter'>
                      <h5>Leads Assigned to All Users</h5>
                      <DataTableExtensions
                          exportHeaders
                          columns={leadAssignedColumns}
                          data={dataTableData}
                        >
                          <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                          />
                      </DataTableExtensions>
                    </div>
                </div>
            </div>
        </div>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData : state.usersData,
    leadProfileInfo : state.leadProfileInfo,
    sourceData : state.sourceData,
    leadCountData : state.leadCountData,
    leadStatusData : state.leadStatusData,
    campaignData: state.campaignData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, leadActions.default, campaignsActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadCountPage);