import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter,useSearchParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import uuid from "react-uuid";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import * as userActions from "./actions/userActions";
// routes
import Router from './routes';
// theme 
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import Loader from "./components/Loader";

// import { firebase, messaging, requestFirebaseNotificationPermission }  from './firebase'; 

import config from "./config";
// import firebase from './firebase'
// ---------------------------------------------------------------------- 


const customToast = (msg) => {
  toast.custom((t) => (
    <div
    className={`${
      t.visible ? 'animate-enter' : 'animate-leave'
    } modal-content animate-enter max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
  >
    <div className="">
      <div className="">

        <div className="">
          <p className="">
          <h5>Google Chrome</h5>
Click the lock icon in the address bar of your Chrome browser.
Click to toggle the Notifications switch on.
If Notifications does not appear in the lock menu, select Site settings, then select Allow in the dropdown menu to the right of Notifications.
</p>
<p>
<h5>Firefox</h5>
Click the lock icon in the address bar of your Firefox browser.
On the right, click the arrow symbol  beside Connection status.
Click More Information, then select the Permissions tab.
Under Send Notifications, uncheck Use Default and select Allow.
          </p>

        </div>
      </div>
    </div>
    <div className="">
      <button
        onClick={() => toast.dismiss(t.id)}
        className=" dismiss-btn border border-transparent rounded-none float-right btn-danger-bg text-white"
      >
        Close
      </button>
    </div>
  </div>
  ),
  {duration:Infinity})
 };
const App = (props) =>{
  const { isFetching, actions, deviceToken } = props;
  const [fCMtoken, setFCMToken] = useState('');
  const queryParams = new URLSearchParams(window.location.search)
  const appDeviceId = queryParams.get("app_device_id");
  if(appDeviceId !== null) {
    localStorage.setItem('APP_DEVICE_ID',appDeviceId);
  }

  const getCookie = (name) => {
    let cookieValue = '';

    console.log("App document.cookie = ",document.cookie)
    if(document.cookie !== '') {
      const cookieRow = document.cookie.split('; ').find((row) => row.startsWith(name));
      cookieValue = cookieRow ? cookieRow.split('=')[1] : ''; 
     }
    return cookieValue;
  };

  
/*
  useEffect(() => {
   
    const PUSH_ALERT_COOKIE_SUBS_STATUS = getCookie(config.PUSH_ALERT_COOKIE_SUBS_STATUS);
    console.log("App PUSH_ALERT_COOKIE_SUBS_STATUS = ",PUSH_ALERT_COOKIE_SUBS_STATUS)

    const PUSH_ALERT_COOKIE_SUBS_ID = getCookie(config.PUSH_ALERT_COOKIE_SUBS_ID);
    
    localStorage.setItem('PUSH_ALERT_TOKEN',PUSH_ALERT_COOKIE_SUBS_ID);
    console.log("App PUSH_ALERT_TOKENSession = ",PUSH_ALERT_COOKIE_SUBS_ID)
    localStorage.setItem('PUSH_ALERT_TOKEN',PUSH_ALERT_COOKIE_SUBS_ID);
   
  }, []);

  */

  useEffect(() => {
    if (deviceToken === null) {
      const token = uuid();
      actions.setDeviceToken(token);
    } 

   // localStorage.setItem('app_device_id',PUSH_ALERT_COOKIE_SUBS_ID);
  }, [deviceToken, actions]);

  useEffect(() => {
    try{
      console.log(window);
      if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
        toast((t) => (
          <span>
            <div className='text-center'>Notifications Blocked</div>
            <button className="btn">Your browser currently does not support notifications.<br />Please use either Google Chrome or Firefox to receive timely notifications.</button>
            <button className="w-full border border-transparent rounded-none float-right btn-danger-bg text-white" onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </button>
          </span>
        ),
        {duration: Infinity});
      } else {
       /* const msg=firebase.messaging();
        console.log(msg);
        msg.getToken().then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log( currentToken);
            localStorage.setItem('FCMToken',currentToken);
            // ...
          } else {
            console.log(currentToken)
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);

          toast((t) => (
            <span>
              <div className='text-center'>Notifications Blocked</div>
              <button className="btn" onClick={() => {toast.dismiss(t.id);customToast()}}>Click here for instructions to enable notifications</button>
              <button className="w-full border border-transparent rounded-none float-right btn-danger-bg text-white" onClick={() => toast.dismiss(t.id)}>
                Dismiss
              </button>
            </span>
          ),
          {duration: Infinity});
        });
        */
      }      

    }catch(e) {
      console.log('Browser not supported. ', e);
      toast((t) => (
        <span>
          <div className='text-center'>Notifications Blocked</div>
          <button className="btn">Your browser currently does not support notifications.<br />Please use either Google Chrome or Firefox to receive timely notifications.</button>
          <button className="w-full border border-transparent rounded-none float-right btn-danger-bg text-white" onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </button>
        </span>
      ),
      {duration: Infinity});
    }

  }, []);
  
  return (
    <HelmetProvider>
      <ToastContainer/>
      <Toaster position="top-right"
      reverseOrder={false}
     />
      <BrowserRouter> 
        <ThemeProvider> 
          <ScrollToTop />
          {isFetching && <Loader />}
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
    deviceToken: state.deviceToken,
  }
}
 
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
