import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as userActions from "../../actions/userActions";
import AccessDenied from '../common/AccessDenied';
import * as groupsUsersActions from "../../actions/groupsUsersActions";

import IncidentChecklists from './IncidentChecklists';
import IncidentQuestionnaire from './IncidentQuestionnaire';
import Loader from "../../components/Loader";
import IncidentTaskPage from './IncidentTaskPage';


import * as API from "../../utils/apiPath";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const ViewTicket = (props) => {
    const { id, incidentReportId } = useParams();
    const location = useLocation();
    const { actions, usersData, viewTicketData, checklistsData, groupData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [getIncidentReportId, setIncidentReportId] = useState('');
    const [showIncidentReporte, setShowIncidentReporte] = useState(false);

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [getIncidentReport, setIncidentReport] = useState([]);
    const [getComment, setComment] = useState([]);

    const [createdBy, setCreatedBy] = useState('');
    const [incidentCategory, setincIdentCategory] = useState('');
    const [description, setDescription] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [assignedUser, setAssignedUser] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allIncidentReportStatus, setAllIncidentReportStatusData] = useState([]);

    const [showListDetails, setShowListDetails] = useState(false);
    const [allGroupData, setAllGroupData] = useState([]);
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [getAllUsersListData, setAllUsersListData] = useState([]);
    const [selectedAssignedUser, setSelectedAssignedUser] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [priority, setPriority] = useState('');
    const [incidetFiles, setIncidetFiles] = useState([]);
    const [commentCount, setCommentCount] = useState(0);
    const [typeTopic, setTypeTopic] = useState("");
    const [userRoleType, setUserRoleType] = useState('User');
    const [showLeadDetails, setShowLeadDetails] = useState(false);

    const [allIncidentCheckLists, setAllIncidentCheckLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [openQue, setOpenQue] = useState(false);
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [selectedAssignTo, setSelectedAssignTo] = useState([]);
    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;

    const [allIncidentTask, setAllIncidentTask] = useState([]);
    const [allchildIds, setAllchildIds] = useState([]);

    const [isEditableStatus, setIsEditableStatus] = useState(false);
    const [dueDate, setDueDate] = useState("");
    const [incidentCreatedBy, setIncidentCreatedBy] = useState("");
    const [loggedInUserId, setLoggedInUserId] = useState("");
    
    const [incidentCurrentStatus, setIncidentCurrentStatus] = useState("");
    const [isEditableIncidentCurrentStatus, setIsEditableIncidentCurrentStatus] = useState(false);
    

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'All Incident Report');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);

                if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type')  && result.data.user_info[0].role_type !==''){
                    setUserRoleType(result.data.user_info[0].role_type);
                    setLoggedInUserId(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggedInUserId]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  groupData list:", result);
            if (result.data.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.group_name.localeCompare(b.group_name)
                );
                setAllGroupData(sortedResultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });

    }, [setAllGroupData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.incidentReportStatusData(requestData);

        response.then((result) => {
            // console.log("Promise resolved -  lead status data :", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.incident_report_status.localeCompare(b.incident_report_status)
                );
                setAllIncidentReportStatusData(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  lead status data :", error); 
        });
    }, [setAllIncidentReportStatusData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setShowIncidentReporte(false);
        if (incidentReportId !== undefined && !showIncidentReporte) {
            const decodedIncidentReportId = atob(incidentReportId);
            setIncidentReportId(decodedIncidentReportId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'incident_report_id': decodedIncidentReportId };
            const requestData = encryptData(obj);
            const response = actions.viewTicketData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data;
                    setIncidentReport(incidentReporte.incident_report);
                    setComment(incidentReporte.comment);
                    setCommentCount(incidentReporte.comment.length);

                    setCreatedBy(`${incidentReporte.incident_report[0].createdByName} on ${incidentReporte.incident_report[0].created_date}`);
                    setincIdentCategory(`${incidentReporte.incident_report[0].incidentCategory}`);
                    setDescription(`${incidentReporte.incident_report[0].description}`);
                    setTypeTopic(`${incidentReporte.incident_report[0].type_topic}`);
                    setTicketNumber(`${incidentReporte.incident_report[0].id}`);

                    setPhoneNumber(`${incidentReporte.incident_report[0].mobile}`);
                    setName(`${incidentReporte.incident_report[0].name}`);
                    setEmail(`${incidentReporte.incident_report[0].email}`);
                    setAddress(`${incidentReporte.incident_report[0].address}`);
                    setPriority(`${incidentReporte.incident_report[0].priorityType}`);
                    setIncidetFiles(`${incidentReporte.incident_report[0].files}`);

                    setAssignedUser(`${incidentReporte.incident_report[0].assigned_user}`);
                    handleChangeAssignedGroup({ target: { value: incidentReporte.incident_report[0].assigned_group } }, 'selectedUser');
                    setValue("assigned_group", incidentReporte.incident_report[0].assigned_group);

                    const assignToString = incidentReporte.incident_report[0].assigned_user;
                    const assignToArray = assignToString.split(',');
                    setSelectedAssignTo(assignToArray);
                    setNumbersArray(incidentReporte.incident_report[0].selected_assigned_user);

                    const checkListsResults = incidentReporte.incident_report[0].all_checklists;
                    const checkListsResultsArr = Object.keys(checkListsResults).map((key) => checkListsResults[key]);
                    setAllIncidentCheckLists(checkListsResultsArr);
                    
                    setAllIncidentTask(incidentReporte.incident_task);

                    setDueDate(`${incidentReporte.incident_report[0].dueDate}`);
                    setIncidentCreatedBy(`${incidentReporte.incident_report[0].created_by}`);

                    setIncidentCurrentStatus(`${incidentReporte.incident_report[0].statusName}`);

                    if(Object.prototype.hasOwnProperty.call(incidentReporte, 'childIds') && incidentReporte.childIds !==''){
                        setAllchildIds(incidentReporte.childIds);
                    }

                    setShowIncidentReporte(true);
                } else {
                    setShowIncidentReporte(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        }
    }, [incidentReportId, setValue, setIncidentReport, setComment, setShowIncidentReporte, setCreatedBy, setincIdentCategory, setDescription, setTicketNumber, setAssignedUser, setSelectedAssignedUser, setPhoneNumber, setName, setEmail, setAddress, setPriority, setIncidetFiles, setCommentCount, setTypeTopic, setAllIncidentCheckLists, setSelectedAssignTo, setNumbersArray, setAllIncidentTask, setAllchildIds, setDueDate, setIncidentCreatedBy, setIncidentCurrentStatus]);

    const onSubmit = (data) => {

        setPreloaderStatus(true);

        const assignTo = JSON.stringify(selectedAssignTo);

        if (selectedAssignedGroup.trim() === "") {
            setPreloaderStatus(false);
            toast.error("Please select group.", {
                position: "top-right",
            });
        } else if (selectedAssignedGroup.trim() !== "" && (!selectedAssignTo || selectedAssignTo.length === 0)) {
            setPreloaderStatus(false);
            toast.error("Please select user.", {
                position: "top-right",
            });
        } else if (data.incident_report_status.trim() === "") {
            setPreloaderStatus(false);
            toast.error("Please select status.", {
                position: "top-right",
            });
        } else {
            
            const params = { "incident_report_id": getIncidentReportId, "comment": data.add_comment, "incident_report_status": data.incident_report_status, "report_file": selectedFiles, "assigned_group": selectedAssignedGroup, "assigned_user": assignTo };
            // console.log(params);
            const requestData = encryptData(params);
            const response = actions.addIncidentReportComment(requestData, navigate);
            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    reset();
                    setSelectedFiles([]);
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    if (getIncidentReportId === '') {
                        const encodedId = btoa(String(result.data.data));
                        navigate(`/dashboard/view-ticket/${btoa(String(getIncidentReportId))}`);
                    } else {
                        updateParentState(false);
                        fetchIncident();
                    }
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleChangeAssignedGroup = (event, actionName = '') => {
        const selectedValue = event.target.value;
        setSelectedAssignedGroup(selectedValue);

        if (selectedValue !== "") {

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id": selectedValue, "tag_name": "get_all_group_user" };
            const requestData = encryptData(obj);

            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);
                        // setAllUsersListData(usersListData[0].users_list);
                    }
                    setSelectedAssignedGroup(selectedValue);
                    if (actionName !== 'selectedUser') {
                        setValue("assigned_user", "");
                    }
                }
            }).catch((error) => {
                console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
        }
    };

    const handleAssignedUserChange = (event) => {
        const newValue = event.target.value;
        setSelectedAssignedUser(newValue);
    };

    const fetchIncident = useCallback(() => {
        const defaultInput = fileInputs[0]; // Save the default input
        setFileInputs([defaultInput]);
        
        const params = new URLSearchParams(location.search);

        setShowIncidentReporte(false);
        if (incidentReportId !== undefined && !showIncidentReporte) {
            const decodedIncidentReportId = atob(incidentReportId);
            setIncidentReportId(decodedIncidentReportId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'incident_report_id': decodedIncidentReportId };
            const requestData = encryptData(obj);
            const response = actions.viewTicketData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data;
                    setIncidentReport(incidentReporte.incident_report);
                    setComment(incidentReporte.comment);
                    setCommentCount(incidentReporte.comment.length);

                    setCreatedBy(`${incidentReporte.incident_report[0].createdByName} on ${incidentReporte.incident_report[0].created_date}`);
                    setincIdentCategory(`${incidentReporte.incident_report[0].incidentCategory}`);
                    setDescription(`${incidentReporte.incident_report[0].description}`);
                    setTypeTopic(`${incidentReporte.incident_report[0].type_topic}`);
                    setTicketNumber(`${incidentReporte.incident_report[0].id}`);

                    setPhoneNumber(`${incidentReporte.incident_report[0].mobile}`);
                    setName(`${incidentReporte.incident_report[0].name}`);
                    setEmail(`${incidentReporte.incident_report[0].email}`);
                    setAddress(`${incidentReporte.incident_report[0].address}`);
                    setPriority(`${incidentReporte.incident_report[0].priorityType}`);
                    setIncidetFiles(`${incidentReporte.incident_report[0].files}`);

                    setAssignedUser(`${incidentReporte.incident_report[0].assigned_user}`);
                    handleChangeAssignedGroup({ target: { value: incidentReporte.incident_report[0].assigned_group } }, 'selectedUser');

                    setValue("assigned_group", incidentReporte.incident_report[0].assigned_group);
                    // setValue("assigned_user", );

                    const assignToString = incidentReporte.incident_report[0].assigned_user;
                    const assignToArray = assignToString.split(',');
                    setSelectedAssignTo(assignToArray);
                    setNumbersArray(incidentReporte.incident_report[0].selected_assigned_user);

                    setSelectedAssignedUser(incidentReporte.incident_report[0].assigned_user);

                    const checkListsResults = incidentReporte.incident_report[0].all_checklists;
                    const checkListsResultsArr = Object.keys(checkListsResults).map((key) => checkListsResults[key]);
                    setAllIncidentCheckLists(checkListsResultsArr);

                    setAllIncidentTask(incidentReporte.incident_task);

                    setDueDate(`${incidentReporte.incident_report[0].dueDate}`);
                    setIncidentCreatedBy(`${incidentReporte.incident_report[0].created_by}`);
                    setIncidentCurrentStatus(`${incidentReporte.incident_report[0].statusName}`);

                    if(Object.prototype.hasOwnProperty.call(incidentReporte, 'childIds') && incidentReporte.childIds !==''){
                        setAllchildIds(incidentReporte.childIds);
                    }

                    setShowIncidentReporte(true);
                } else {
                    setShowIncidentReporte(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        }

    }, [incidentReportId, setValue, setIncidentReport, setComment, setShowIncidentReporte, setCreatedBy, setincIdentCategory, setDescription, setTicketNumber, setAssignedUser, setSelectedAssignedUser, setPhoneNumber, setName, setEmail, setAddress, setPriority, setIncidetFiles, setCommentCount, setTypeTopic, setAllIncidentCheckLists, setAllIncidentTask, setDueDate, setIncidentCreatedBy, setIncidentCurrentStatus]);

    const updateParentState = (newValue) => {
        // console.log("updateParentState = ", newValue);

        const params = new URLSearchParams(location.search);

        setShowIncidentReporte(newValue);
        if (incidentReportId !== undefined && !showIncidentReporte) {
            const decodedIncidentReportId = atob(incidentReportId);
            setIncidentReportId(decodedIncidentReportId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'incident_report_id': decodedIncidentReportId };
            const requestData = encryptData(obj);
            const response = actions.viewTicketData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  incidentReporteData list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data;
                    setIncidentReport(incidentReporte.incident_report);
                    setComment(incidentReporte.comment);
                    setCommentCount(incidentReporte.comment.length);

                    setCreatedBy(`${incidentReporte.incident_report[0].createdByName} on ${incidentReporte.incident_report[0].created_date}`);
                    setincIdentCategory(`${incidentReporte.incident_report[0].incidentCategory}`);
                    setDescription(`${incidentReporte.incident_report[0].description}`);
                    setTypeTopic(`${incidentReporte.incident_report[0].type_topic}`);
                    setTicketNumber(`${incidentReporte.incident_report[0].id}`);

                    setPhoneNumber(`${incidentReporte.incident_report[0].mobile}`);
                    setName(`${incidentReporte.incident_report[0].name}`);
                    setEmail(`${incidentReporte.incident_report[0].email}`);
                    setAddress(`${incidentReporte.incident_report[0].address}`);
                    setPriority(`${incidentReporte.incident_report[0].priorityType}`);
                    setIncidetFiles(`${incidentReporte.incident_report[0].files}`);

                    setAssignedUser(`${incidentReporte.incident_report[0].assigned_user}`);
                    handleChangeAssignedGroup({ target: { value: incidentReporte.incident_report[0].assigned_group } }, 'selectedUser');

                    setValue("assigned_group", incidentReporte.incident_report[0].assigned_group);
                    // setValue("assigned_user", );

                    setSelectedAssignedUser(incidentReporte.incident_report[0].assigned_user);

                    const checkListsResults = incidentReporte.incident_report[0].all_checklists;
                    const checkListsResultsArr = Object.keys(checkListsResults).map((key) => checkListsResults[key]);
                    setAllIncidentCheckLists(checkListsResultsArr);

                    setAllIncidentTask(incidentReporte.incident_task);

                    setDueDate(`${incidentReporte.incident_report[0].dueDate}`);
                    setIncidentCreatedBy(`${incidentReporte.incident_report[0].created_by}`);
                    setIncidentCurrentStatus(`${incidentReporte.incident_report[0].statusName}`);

                    setShowIncidentReporte(true);
                } else {
                    setShowIncidentReporte(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  incidentReporteData list:", error);
            });
        }
    };

    useEffect(() => {
        fetchIncident(); 
    }, [fetchIncident]);

    useEffect(() => {
        const setIsEditable = () => {
            let isEditable = false;
            if (allchildIds && Object.keys(allchildIds).length > 0 && numbersArray && numbersArray.length > 0) {
                Object.keys(allchildIds).forEach(key => {
                    const value = allchildIds[key];
                    const found = numbersArray.some(obj => obj.key === value);

                    if(incidentCreatedBy === loggedInUserId || found){
                        isEditable = false;
                    } else {
                        isEditable = true;
                    }
                });
            }
            setIsEditableStatus(isEditable);
        };

        setIsEditable();
    }, [allchildIds, numbersArray]);

    useEffect(() => {
        if (incidentCurrentStatus === "Closed") {
            setIsEditableIncidentCurrentStatus(true);
        }
    }, [incidentCurrentStatus, setIsEditableIncidentCurrentStatus]);

    const handleIncidentReportStatus = (e) => {
        const incidentReportStatusVal = e.target.value;
        const selectedOption = allIncidentReportStatus.find(option => option.id === incidentReportStatusVal);
        const incidentReportStatusName = selectedOption ? selectedOption.incident_report_status : '';

        if(incidentCurrentStatus === "Closed" && incidentReportStatusName === "Reopen"){
            setIsEditableIncidentCurrentStatus(false);
        } else if (incidentCurrentStatus === "Closed" && incidentReportStatusName !== "Reopen"){
            setIsEditableIncidentCurrentStatus(true);
        }
    };

    const [fileInputs, setFileInputs] = useState([
        { id: 0, element: <input key={0} type='file' id='report_file_0' name='report_file_0' className='form-control' onChange={(e) => handleFileChange(e, fileInputs)} disabled={isEditableIncidentCurrentStatus || isEditableStatus} /> }
    ]);

    const handleFileChange = (e, fileInputs) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file, index) => { // Use index to assign unique id
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;

                const parts = base64Data.split(',');
                const base64WithoutHeader = parts[1];

                const extension = fileName.split('.').pop().toLowerCase();
                if (extension === 'docx' || extension === 'doc') {
                    base64Files.push({ id: selectedFiles.length + index, data: `data:application/document;base64,${base64WithoutHeader}`, name: fileName });
                } else if (extension === 'xlsx' || extension === 'xls') {
                    base64Files.push({ id: selectedFiles.length + index, data: `data:application/sheet;base64,${base64WithoutHeader}`, name: fileName });
                } else {
                    base64Files.push({ id: selectedFiles.length + index, data: base64Data, name: fileName });
                }

                if (base64Files.length === files.length) {
                    setSelectedFiles([...selectedFiles, ...base64Files]);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const addFileInput = () => {
        const id = fileInputs.length;
        const newFileInput = {
            id,
            element: <input key={id} type='file' id={`report_file_${id}`} name={`report_file_${id}`} className='form-control' onChange={(e) => handleFileChange(e, fileInputs)} disabled={isEditableIncidentCurrentStatus || isEditableStatus} />
        };
        setFileInputs([...fileInputs, newFileInput]);
    };    
    
    const removeFileInput = (id) => {
        setFileInputs(fileInputs.filter(input => input.id !== id));
        const updatedSelectedFiles = selectedFiles.filter(file => file.id !== id);
        setSelectedFiles(updatedSelectedFiles);
    };

    const apiBaseUrl = `${config.API_BASE_URL}uploads/incident_reporte_document/`;

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title> View Ticket | Ckers </title>
            </Helmet>
            <Container>
                <Typography className="d-none" variant="h4" sx={{ mb: 5 }}> View Ticket </Typography>
                <div id="main-wrap" className="mt-2 mb-5">
                    <div className="row align-items-center mb-0">
                        <div className="col-12">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item mb-0" role="presentation">
                                    <button className="nav-link active" id="pills-task_details-tab" data-bs-toggle="pill" data-bs-target="#pills-task_details" type="button" role="tab" aria-controls="pills-task_details" aria-selected="true"> Incident Details </button>
                                </li>

                                <li className={allIncidentCheckLists.length !== 0 ? "nav-item" : "nav-item d-none"} role="presentation">
                                    <button className={isEditableIncidentCurrentStatus || isEditableStatus ? "nav-link disabled" : "nav-link"} id="pills-incident-checklist-tab" data-bs-toggle="pill" data-bs-target="#pills-incident-checklist" type="button" role="tab" aria-controls="pills-incident-checklist" aria-selected="false"> Incident Checklist </button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className={isEditableIncidentCurrentStatus ? "nav-link disabled" : "nav-link"} id="pills-ac_history-tab" data-bs-toggle="pill" data-bs-target="#pills-ac_history" type="button" role="tab" aria-controls="pills-ac_history" aria-selected="false"> Activity History <span className="badge bg-info rounded px-2 ms-md-2 fw-600">{getComment.length}</span>
                                    </button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className={isEditableIncidentCurrentStatus ? "nav-link disabled" : "nav-link"} id="pills-task-list-tab" data-bs-toggle="pill" data-bs-target="#pills-task-list" type="button" role="tab" aria-controls="pills-task-list" aria-selected="false"> Task List </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-task_details" role="tabpanel" aria-labelledby="pills-task_details-tab" tabIndex="0">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="px-3 py-2 border rounded mb-4">
                                        <Typography variant="h4" sx={{ mb: 5 }}> {typeTopic} - <h5 className="d-inline-block fw-bold">Incident no. {ticketNumber}</h5>
                                        </Typography>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Phone Number:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{phoneNumber}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Name:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{name}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Email:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{email}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Address:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{address}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Priority:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{priority}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Created By:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{createdBy}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Category:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{incidentCategory}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Due Date:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal">{dueDate}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Description:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                <span className="fw-normal"> {description} </span>
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-3">
                                                <span>Files:</span>
                                            </div>
                                            <div className="col-md-9 ps-lg-0">
                                                {incidetFiles.length > 0 ? (
                                                    <button className="badge border text-bg-success text-white">
                                                        <a target="new" href={incidetFiles} className='text-success  text-decoration-none'>
                                                            Download File
                                                        </a>
                                                    </button>
                                                ) : (
                                                    <span>No file available.</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 py-2 mb-md-0 mb-4 border rounded">
                                        <Typography variant="h4" sx={{ mb: 5 }}> Add Comment </Typography>
                                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor="assigned-group">
                                                            <span>Assigned group <a style={{ color: "red" }}>*</a></span>
                                                            <select
                                                                id='assigned_group'
                                                                name='assigned_group'
                                                                className="form-select"
                                                                {...register('assigned_group')}
                                                                value={selectedAssignedGroup}
                                                                onChange={handleChangeAssignedGroup}
                                                                disabled={isEditableIncidentCurrentStatus || isEditableStatus}
                                                            >
                                                                <option value=''>Select assigned group</option>
                                                                {allGroupData.map((option) => (
                                                                    <option value={option.id}>
                                                                        {option.group_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                    <span>Assign to <a style={{ color: "red" }}>*</a></span>
                                                    <div className="text-dark">
                                                        <Multiselect
                                                        id="assign_to"
                                                        options={getAllUsersListData.map((option) => ({
                                                            key: option.user_id,
                                                            value: option.name,
                                                        }))}
                                                        selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                                        displayValue="value"
                                                        onSelect={(selectedItems) => {
                                                            const selectedValues = selectedItems.map(item => item.key);
                                                            setSelectedAssignTo(selectedValues);
                                                        }}
                                                        onRemove={(selectedItems) => {
                                                            const selectedValues = selectedItems.map(item => item.key);
                                                            setSelectedAssignTo(selectedValues);
                                                        }}
                                                        className={isEditableIncidentCurrentStatus || isEditableStatus ? "multiselect-disabled" : ""}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <label htmlFor="r_file">
                                                            <span>File Upload</span>
                                                            <input type='file' multiple id='report_file' name='report_file' className='form-control' onChange={handleFileChange} disabled={isEditableIncidentCurrentStatus || isEditableStatus}/>
                                                        </label>
                                                    </div>
                                                </div> */}
                                                    {fileInputs.map((input, index) => (
                                                        <div key={input.id} className='row mb-3 mx-0 px-1'>
                                                            <div className='col-md-10 col-10 pe-md-0 pe-1'>
                                                                <div className='form-group'>
                                                                    <label htmlFor={`report_file_${input.id}`}>
                                                                        <span>File Upload</span>
                                                                        {input.element}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 col-2 pt-1'>
                                                                <span className='d-block'>&nbsp;</span>
                                                                {index !== 0 && <button type="button" onClick={() => removeFileInput(input.id)} className="btn float-right btn-danger dismiss-btn"><i className="bi bi-x-lg">&nbsp;</i></button>}
                                                            </div>
                                                        </div>
                                                    ))}

                                                {/* Button to add more file upload inputs */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button type="button" onClick={addFileInput} className="btn btn-info float-right" disabled={isEditableIncidentCurrentStatus || isEditableStatus}>Add More File Upload</button>
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor="name">
                                                            <span>Comment <a style={{ color: "red" }}>*</a></span>
                                                            <textarea id="add_comment" name='add_comment' className="form-control" placeholder="Type Something" {...register('add_comment', { required: true })} disabled={isEditableIncidentCurrentStatus || isEditableStatus}/>
                                                        </label> {errors.add_comment && <span className="required">This field is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="incident_report_status">
                                                            <span>Select Status <a style={{ color: "red" }}>*</a></span>
                                                            <select id="incident_report_status" name='incident_report_status' className="form-select" {...register('incident_report_status')} disabled={isEditableStatus} onChange={handleIncidentReportStatus}>
                                                                <option selected value="">Select Status</option> {allIncidentReportStatus.length > 0 && allIncidentReportStatus.map((option) => (<option value={option.id}> {option.incident_report_status} </option>))}
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <button className={isEditableIncidentCurrentStatus || isEditableStatus ? "btn btn-primary disabled" : "btn btn-primary"}>Add Comment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <Typography variant="h4" sx={{ mb: 1 }}> Comments </Typography>
                                    <div className='history-tl-container comment-list ps-4 rounded'>
                                        <ul className='tl w-100'>
                                            {getComment && getComment.length > 0 ? (
                                                getComment.map((item, index) => {

                                                    const uploadedFilesNameArray = item.uploaded_files_name ? item.uploaded_files_name.split(', ') : [];

                                                    const uploadedFilesArray = item.uploaded_files ? item.uploaded_files.split(', ') : [];

                                                    return (
                                                        <li className='tl-item' key={index}>
                                                            {/* <div className="timestamp yes">{item.incidentReportStatus}<i className="bi bi-caret-right-fill">&nbsp;</i></div> */}
                                                            <div className="item-title">Status:
                                                                <span className='text-success'>&nbsp;{item.incidentReportStatus}</span>
                                                            </div>
                                                            {/* <div className="item-title">{item.createdByName}</div> */}
                                                            <div className="item-date">{item.created_date}</div>
                                                            <div className="item-user">{item.createdByName}</div>
                                                            <div style={{ whiteSpace: 'pre-line' }} className="item-detail">{item.comment}</div>
                                                            <div className="row mt-2">


                                                                {uploadedFilesArray.map((file, fileIndex) => (
                                                                    <div key={fileIndex} className="col-12">
                                                                        <div className="row mx-0 bg-white shadow-sm align-items-center p-2 rounded file-download mb-2">
                                                                            <div className='col-10 px-0'>
                                                                                {uploadedFilesNameArray.length > 0 && (
                                                                                    <p className="mb-0 f-14px text-truncate w-auto">{uploadedFilesNameArray[fileIndex]}</p>
                                                                                )}
                                                                                {uploadedFilesNameArray.length === 0 && (
                                                                                    <p className="mb-0 f-14px text-truncate w-auto">File Download {fileIndex + 1}</p>
                                                                                )}
                                                                            </div>
                                                                            <div className='col-2 down-2 text-end px-0'>
                                                                                <a target="new" className='text-decoration-none' href={file}>
                                                                                    <i className="bi bi-download">&nbsp;</i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <p>No comments available</p>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-incident-checklist" role="tabpanel" aria-labelledby="pills-incident-checklist-tab" tabIndex="0">
                            {/* <Typography variant="h4" sx={{ mb: 5 }}> Incident Checklist </Typography> */}

                            {!open ? (<IncidentChecklists
                                props={props}
                                allLeadCheckLists={allIncidentCheckLists}
                                setOpen={setOpen}
                                setViewModalInfo={setViewModalInfo}
                                setQuestions={setQuestions}
                                setOpenQue={setOpenQue}
                                userRoleType={userRoleType}
                            />
                            ) : (
                                <>
                                    <IncidentQuestionnaire
                                        questions={questions}
                                        checklistInfo={viewModalInfo}
                                        props={props}
                                        setOpen={setOpen}
                                        fetchIncident={fetchIncident}
                                        updateParentState={updateParentState}
                                        userRoleType={userRoleType}
                                    />
                                </>
                            )}

                        </div>

                        <div className="tab-pane fade" id="pills-ac_history" role="tabpanel" aria-labelledby="pills-ac_history-tab" tabIndex="0">
                            {/* <Typography variant="h4" sx={{ mb: 5 }}> Activity </Typography> */}
                            <div id="main-wrap">
                                {getComment.map((item, index) => (
                                    // {previousStatusValue = comments[index - 1].incidentReportStatus}
                                    <div key={index} id={index} className='lead-activity-item'>
                                        <div className='ps-0'>
                                            <p className="mb-1"><b>Comment ID:</b> {item.formatetdID}</p>
                                            <p className="mb-1"><b>Created By:</b> {item.createdByName}</p>
                                            <p className='mb-1'><b>Created Date:</b> {item.created_date}</p>
                                            {/* <p className='mb-1'><b>Comment:</b> {item.comment}</p> */}
                                        </div>

                                        {/* {index > 0 ? (
                                            <p className='mb-0'>Changed Status from <strong>{item.incidentReportStatus}</strong> to <strong>{getComment[index - 1].incidentReportStatus}</strong></p>
                                        ) : (
                                            <p className='mb-0'>Status <strong>{item.incidentReportStatus}</strong></p>
                                        )} */}

                                        {getComment && index > 0 ? (
                                            index === commentCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.incidentReportStatus}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{getComment[index + 1]?.incidentReportStatus}</strong> to <strong>{item.incidentReportStatus}</strong></p>
                                            )
                                            
                                        ) : (
                                            index === commentCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.incidentReportStatus}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{getComment[index + 1]?.incidentReportStatus}</strong> to <strong>{item.incidentReportStatus}</strong></p>
                                            )
                                        )}

                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-task-list" role="tabpanel" aria-labelledby="pills-task-list-tab" tabIndex="0">
                            <IncidentTaskPage
                                props={props}
                                getIncidentReportId={getIncidentReportId}
                                setOpen={setOpen}
                                fetchIncident={fetchIncident}
                                updateParentState={updateParentState}
                                userRoleType={userRoleType}
                                allIncidentTask={allIncidentTask}
                            />
                        </div>
                    </div>
                </div>
            </Container>

            {preloaderStatus && <Loader />}
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        checklistsData: state.checklistsData,
        viewTicketData: state.viewTicketData,
        getUserModuleData: state.getUserModuleData,
        groupData: state.groupData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(incidentReporteActions.default, userActions.default, leadActions.default, groupsUsersActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);