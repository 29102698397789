import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink,useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as campaignsActions from "../../actions/campaignsActions";

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ImportReportPage = (props) => {
    const { importName } = useParams();
    const { actions, campaignData } = props; 
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pageHeading , setPageHeading]  = useState('');
    const [iframeSrc , setIframeSrc]  = useState('');
    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {

        let appUrl = config.APP_URL;
        appUrl = appUrl.replace('/public/', '/');

        if(importName === 'consumer'){
            setPageHeading('Import Consumer Data');
            setIframeSrc(`${appUrl}excel_report/import_consumer.php?referrer=${btoa('lms_page')}`);
        }else if(importName === 'bussiness'){
            setPageHeading('Import Business Report');
            setIframeSrc(`${appUrl}excel_report/excel_import_script_business_done_report.php?referrer=${btoa('lms_page')}`);
        }else if(importName === 'cibil'){
            setPageHeading('Import Cibil Report');
            setIframeSrc(`${appUrl}excel_report/excel_import_script_cibil_submission_report.php?referrer=${btoa('lms_page')}`);
        }else if(importName === 'details'){
            setPageHeading('Import Details Due Report');
            setIframeSrc(`${appUrl}excel_report/import_detail_due_report.php?referrer=${btoa('lms_page')}`);
        } else {
            setPageHeading('Export Ckers Finance Report');
            setIframeSrc(`${appUrl}excel_report/ckers_data.php?referrer=${btoa('lms_page')}`);
        }
        setIframeLoaded(false);

    }, [importName, setIframeLoaded]);

    // console.log("importName : ", importName);
    // console.log("iframeSrc : ", iframeSrc);

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    return (
        <>
            <Helmet>
                <title> {pageHeading} | Ckers </title>
            </Helmet>

            <Container>
                    <>
                        <div id='main-wrap-' className='mmt-7-'>
                            <div className='row justify-content-between mb-3 d-none'>
                                <div className='col-md-8'> 
                                <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                    {pageHeading}
                                </Typography>
                                </div>
                                <div className='col-md-4 pe-0 ps-lg-5'>
                                    &nbsp;
                                </div>
                            </div>
                            <div className='table-wrap'>
                            {!iframeLoaded && <div><div className='p-spinner'><div className="spinner">&nbsp;</div></div></div>}
                                <iframe
                                    title="Report"
                                    width="100%"
                                    height="600"
                                    src= {iframeSrc}
                                    frameBorder="0"
                                    allowFullScreen
                                    onLoad={handleIframeLoad}
                                    style={{ display: iframeLoaded ? 'block' : 'none' }}
                                 />
                            </div>
                        </div>
                    </> 
            </Container>
 
        </>
    );
}

function mapStateToProps(state) {
    return {
        campaignData: state.campaignData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportReportPage);