import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadItrDataReducer(state = initialState.leadItrData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_ITR_DATA_SUCCESS:
      return action.leadItrData;
    default:
      return state;
  }
}
