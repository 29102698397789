import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
import ModuleAllListingPage from './ModuleAllListingPage';
import ArchiveModuleAllListingPage from './ArchiveModuleAllListingPage';
import * as moduleAction from "../../actions/moduleAction";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ModuleListingPage = (props) => {
    const { actions, allTicketData, leadInfo } = props;
    const { leadId } = useParams();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showListDetails, setShowListDetails] = useState(false);
    const [allIncidentReports, setAllIncidentReports] = useState([]);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [getAllIncidentCategoryData, setAllIncidentCategoryData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedIncidentCategory, setSelectedIncidentCategory] = useState([]);
    const [allGroupData, setAllGroupData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const [allOpenIncident, setAllOpenIncident] = useState([]);
    const [allClosedIncident, setAllClosedIncident] = useState([]);
    const [allDeleteIncident, setAllDeleteIncident] = useState([]);

    useEffect(() => {

        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Request')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.usersData(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const filteredResultArr = results.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );

                const extractedData = sortedResultArr.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllUsers(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllUsers]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'category_for': '2' };
        const requestData = encryptData(obj);
        const response = actions.incidentCategoryData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data
                const filteredResultArr = results.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.incident_category.localeCompare(b.incident_category)
                );
                setAllIncidentCategoryData(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllIncidentCategoryData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData, false);
        response.then((result) => {
          // console.log("Promise resolved -  groupData list:", result);
          if (result.data.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
              a.group_name.localeCompare(b.group_name)
            );
            setAllGroupData(sortedResultArr);
          }
        }).catch((error) => {
          console.error("Promise rejected -  lead list:", error);
        });
    
      }, [setAllGroupData]);


    const fetchModules = useCallback(() => {
        if (!showListDetails) {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS };
            const requestData = encryptData(obj);

            const response = actions.getModule(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  getModule list:", result);
                if (result.data.success === 1) {
                    const incidentReporte = result.data.data;
                    // console.log("incidentReporte = ", incidentReporte);
                    setAllIncidentReports(incidentReporte);
                    setShowListDetails(true);
                } else {
                    setShowListDetails(true);
                }
            }).catch((error) => {
                console.error("Promise rejected -  getModule list:", error);
            });
        }
    
    }, [setAllIncidentReports, setShowListDetails]);

    useEffect(() => {
        fetchModules();
    }, [fetchModules]);

    const onSubmit = (data) => {

        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'assign_to': selectedUsers,
            'incident_category': selectedIncidentCategory,
            'topic_incident': data.topic_incident,
            'keyword': data.keyword,
            'assigned_group': selectedGroup,
        };

        const requestData = encryptData(params);
        const response = actions.getModule(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  getModule list:", result);
            if (result.data.success === 1) {
                const incidentReporte = result.data.data;
                // console.log("incidentReporte = ", incidentReporte);
                setAllIncidentReports(incidentReporte);
                setShowListDetails(true);
            } else {
                setShowListDetails(true);
                setAllIncidentReports([]);
                setAllOpenIncident([]);
                setAllClosedIncident([]);
                setAllDeleteIncident([]);
            }
        }).catch((error) => {
            console.error("Promise rejected -  getModule list:", error);
        });
    };

    // console.log("allIncidentReports : ", allIncidentReports);

    useEffect(() => {
        if (allIncidentReports && allIncidentReports.length > 0) {
            const openIncidents = allIncidentReports.filter(incident => incident.incidentReportStatus !== "Closed" && incident.module_status.trim() !== "1");
            const closedIncidents = allIncidentReports.filter(incident => incident.incidentReportStatus === "Closed" && incident.module_status.trim() !== "1");
            const deleteIncidents = allIncidentReports.filter((incident => incident.module_status.trim() === "1"));

            setAllOpenIncident(openIncidents);
            setAllClosedIncident(closedIncidents);

            setAllDeleteIncident(deleteIncidents);

        }
    }, [allIncidentReports, setAllOpenIncident, setAllClosedIncident]);

    return (
        <>
            <Helmet>
                <title> All {config.MODULE_TYPE_NAME} | Ckers </title>
            </Helmet>

            <Container>
                <div className="d-flex align-items-center justify-content-between mb-0">
                    <span>&nbsp;</span>
                    <RouterLink to='/dashboard/module-create-page' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Create {config.MODULE_TYPE_NAME}</RouterLink>
                </div>
                {!userHasPermission ? (
                    <>
                        <AccessDeniedWithoutLeftMenu />
                    </>
                ) : (
                    <>

                        <div id='main-wrap' className='mb-3 mmt-7 filter'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row mx-0'>

                                    <div className='col'>
                                        <div className='form-group'>
                                            <span>Assigned Group</span>
                                            <div className="text-dark">
                                                <Multiselect
                                                    id="assigned_group"
                                                    options={allGroupData.map((option) => ({
                                                        key: option.id,
                                                        value: option.group_name,
                                                    }))}
                                                    displayValue="value"
                                                    onSelect={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedGroup(selectedValues);
                                                    }}
                                                    onRemove={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedGroup(selectedValues);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='form-group'>
                                            <span>Assign To</span>
                                            <div className="text-dark">
                                                <Multiselect
                                                    id="assign_to"
                                                    options={allUsers.map((option) => ({
                                                        key: option.id,
                                                        value: option.name,
                                                    }))}
                                                    displayValue="value"
                                                    onSelect={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedUsers(selectedValues);
                                                    }}
                                                    onRemove={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedUsers(selectedValues);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='form-group'>
                                            <span>{config.MODULE_TYPE_NAME} Category</span>
                                            <div className="text-dark">
                                                <Multiselect
                                                    id="incident_category"
                                                    options={getAllIncidentCategoryData.map((option) => ({
                                                        key: option.id,
                                                        value: option.incident_category,
                                                    }))}
                                                    displayValue="value"
                                                    onSelect={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedIncidentCategory(selectedValues);
                                                    }}
                                                    onRemove={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedIncidentCategory(selectedValues);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='form-group'>
                                            <label htmlFor="keyword">
                                                <span>Topic</span>
                                                <input type='text' name='topic_incident' id='topic_incident' className='form-control'{...register('topic_incident')} placeholder="Topic of the incident" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='form-group'>
                                            <label htmlFor="keyword">
                                                <span>Keyword</span>
                                                <input type='text' name='keyword' id='keyword' className='form-control'{...register('keyword')} placeholder="Name or mobile" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <span className='d-block'>&nbsp;</span>
                                            <button className='btn btn-primary'>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div id='main-wrap' className='mt-3 mb-4'>
                            
                            <div className='row justify-content-between'>
                                <div className='col-md-12'>
                                    <Typography className='line-initial' variant="h4" sx={{ mb: 2 }}>
                                        All {config.MODULE_TYPE_NAME}
                                    </Typography>
                                </div>
                            </div>
                            
                            <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-open-tab" data-bs-toggle="pill" data-bs-target="#pills-open" type="button" role="tab" aria-controls="pills-open">Open {config.MODULE_TYPE_NAME}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-closed-tab" data-bs-toggle="pill" data-bs-target="#pills-closed" type="button" role="tab" aria-controls="pills-closed">Closed {config.MODULE_TYPE_NAME}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-archive-tab" data-bs-toggle="pill" data-bs-target="#pills-archive" type="button" role="tab" aria-controls="pills-archive">Deleted {config.MODULE_TYPE_NAME}</button>
                                </li>
                            </ul>

                            <div className= "tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-open" role="tabpanel" aria-labelledby="pills-open-tab" tabIndex="0">
                                    <ModuleAllListingPage
                                        allIncidentReports={allOpenIncident}
                                        actions={actions}
                                        fetchModules={fetchModules}
                                    />
                                </div>

                                <div className="tab-pane fade" id="pills-closed" role="tabpanel" aria-labelledby="pills-closed-tab" tabIndex="0">
                                    <ModuleAllListingPage
                                        allIncidentReports={allClosedIncident}
                                        actions={actions}
                                        fetchModules={fetchModules}
                                    />
                                </div>

                                <div className="tab-pane fade" id="pills-archive" role="tabpanel" aria-labelledby="pills-archive-tab" tabIndex="0">
                                    <ArchiveModuleAllListingPage
                                        allIncidentReports={allDeleteIncident}
                                        actions={actions}
                                        fetchModules={fetchModules}
                                    />
                                </div>
                            </div>

                        </div>
                    </>
                )}
            </Container>

        </>
    );
}

function mapStateToProps(state) {
    return {
        getModule: state.getModule,
        getUserModuleData: state.getUserModuleData,
        leadInfo: state.leadInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, incidentReporteActions.default, groupsUsersActions.default, moduleAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModuleListingPage);