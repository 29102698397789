import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";

const MapContainer = ({ markers, fetchUserLocations }) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [defaultCenter, setDefaultCenter] = useState({
        lat: 28.553620,
        lng: 77.268650
    });
    const [mapBounds, setMapBounds] = useState(null);

    const mapStyles = {
        height: '350px',
        width: '100%'
    };

    useEffect(() => {
        if (markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach(marker => {
                bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
            });
            setMapBounds(bounds);
        }
    }, [markers]);

    useEffect(() => {
        if (map && mapBounds) {
            map.fitBounds(mapBounds);
        }
    }, [map, mapBounds]);

    const onLoad = map => {
        setMap(map);
    };

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
        setDefaultCenter({ lat: marker.lat, lng: marker.lng });
    };

    const handleOnClickUser = (userId) => {
        fetchUserLocations(userId);
        setSelectedMarker(null);
    };

    const svgIcon = `
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="%COLOR%"
        width="50px"
        height="50px"
        >
        <path d="M12 2C8.14 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.14-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        <path fill="none" d="M0 0h24v24H0z" />
        </svg>`;

    // Function to create SVG icon with dynamic color
    const createSvgIcon = (color) => {
        return svgIcon.replace('%COLOR%', color);
    };

    return (

        <>
            <LoadScript googleMapsApiKey={config.GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={5}
                    center={defaultCenter}
                    onLoad={onLoad}
                    // Disable default UI
                    disableDefaultUI
                >
                    {/* {markers.map((marker, index) => {
                    const color = marker.color ? marker.color.substring(1) : ''; // Check if color is defined
                    const iconUrl = `https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}|000000`;
                    return (
                        <Marker
                            key={marker.index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            onClick={() => handleMarkerClick(marker)}
                            icon={{
                                url: iconUrl,
                                scaledSize: new window.google.maps.Size(27, 40),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(20, 60),
                            }}
                        />
                    );
                })} */}

                    {markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            icon={{
                                url: `data:image/svg+xml;base64,${btoa(createSvgIcon(marker.color))}`,
                                scaledSize: new window.google.maps.Size(50, 50),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(25, 50),
                            }}
                            onClick={() => handleMarkerClick(marker)}
                        />
                    ))}

                    {selectedMarker && (
                        <InfoWindow
                            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div className='user-location-details'>
                                <p>{selectedMarker.gpsLocation}</p>
                                <p>{selectedMarker.address}</p>
                                <p>{selectedMarker.createdAt}</p>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p>
                                        <a href="#" onClick={() => handleOnClickUser(selectedMarker.userId)}>
                                            {selectedMarker.createdByName}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            </LoadScript>
        </>

    );
};

export default MapContainer;