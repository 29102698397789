import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as masterAction from "../../actions/masterAction";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadProfileCreatePage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, sourceLeadInfo, checklistsData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [sourceLeadId, setSourceLeadId] = useState('');
    const [showLeadProfileDetails, setShowSourceLeadDetails] = useState(false);
    const [allStatusData, setAllStatusData] = useState([]);
    const [showLeadStatusDetails, setShowLeadStatusDetails] = useState(false);
    const [selectedLeadStatus, setSelectedLeadStatus] = useState([]);

    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Sub Lead Status');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.leadStatusData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  leadStatusData:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.map(item => {
                    return { id: item.id, name: item.lead_status };
                });
                setAllStatusData(sortedResultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  leadStatusData:", error);
        });
    }, [setAllStatusData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setShowSourceLeadDetails(false);
        if (id !== undefined && !showLeadProfileDetails) {
            const decodedId = atob(id);
            setSourceLeadId(decodedId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'id': decodedId };

            const requestData = encryptData(obj);
            const response = actions.getSubLeadStatus(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  source data:", result); 
                if (result.data.success === 1) {
                    const results = result.data.data;
                    setShowSourceLeadDetails(true);
                    setValue("sub_lead_status", results.data[0].name);
                    // setValue("action_user_id", results.data[0].created_by);
                    setValue("status", results.data[0].status);
                    setValue("lead_status", results.data[0].lead_status);
                    
                    const leadLeadStatusString = results.data[0].lead_status;
                    const leadLeadStatusArray = leadLeadStatusString.split(',');
                    setSelectedLeadStatus(leadLeadStatusArray);
                    setNumbersArray(results.data[0].selected_lead_status);
                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });

        }
    }, [id, setValue, setShowSourceLeadDetails, setSelectedLeadStatus, setNumbersArray]);

    const onSubmit = (data) => {

        const leadStatus = JSON.stringify(selectedLeadStatus);
        let params = { 'name': data.sub_lead_status, 'status': data.status };

        if (sourceLeadId !== '') {
            params = { 'name': data.sub_lead_status, 'status': data.status, 'id': sourceLeadId };
        }

        console.log(leadStatus);
        const requestData = encryptData(params);
        const response = actions.addEditSubLeadStatus(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                setTimeout(() => {
                    navigate(`/dashboard/sub-lead-status`);
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            toast.error(error, {
                position: "top-right",
            });
        });
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title>{showLeadProfileDetails ? "Edit Sub Lead Status" : "Create Sub Lead Status"}</title>
            </Helmet>

            <Container>
                <div className="flex-container">
                    <Typography variant="h4">
                        {showLeadProfileDetails ? "Edit Sub Lead Status" : "Create Sub Lead Status"}
                    </Typography>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Sub Lead Status Name <a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='sub_lead_status' className='form-control'
                                            {...register('sub_lead_status', { required: true })} />
                                    </label>
                                    {errors.sub_lead_status && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6 d-none'>
                                <div className='form-group'>
                                    <span>Lead Status</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="lead_profile"
                                            options={allStatusData.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedLeadStatus(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedLeadStatus(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="reporting-user">
                                        <span>Status <a style={{ color: "red" }}>*</a></span>
                                        <select name='status' className="form-select" {...register('status', { required: true })}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </label>
                                    {errors.status && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        checklistsData: state.checklistsData,
        sourceLeadInfo: state.sourceLeadInfo,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadProfileCreatePage);