import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const RolePermissionCreatePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, rolePermissionInfo } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Role Permission');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowDetails(false);
    if (id !== undefined && !showDetails) {

      const decodedId = atob(id);
      setRoleId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'role_permission_id': decodedId };
      const requestData = encryptData(obj);
      actions.rolePermissionInfo(requestData);
      const timer = setTimeout(() => {
        console.log("=========rolePermissionInfo start================");
        console.log(rolePermissionInfo);
        console.log("=========rolePermissionInfo start================");

        if ('data' in rolePermissionInfo && rolePermissionInfo.data.length > 0) {
          setShowDetails(true);
          setValue("role_permission_name", rolePermissionInfo.data[0].role_permission_name);
         // setValue("action_user_id", rolePermissionInfo.data[0].created_by);
          setValue("role_permission_status", rolePermissionInfo.data[0].status);
        }

      }, 500);

    }
  }, [id, setValue, setShowDetails, rolePermissionInfo]);

  const onSubmit = (data) => {
    let params = { 'role_permission_name': data.role_permission_name, 'role_permission_status': data.role_permission_status  };

    if (roleId !== '') {
      params = { 'role_permission_name': data.role_permission_name, 'role_permission_status': data.role_permission_status, 'role_permission_id': roleId };
    }
    const requestData = encryptData(params);
    actions.createRolePermission(requestData, navigate);
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title> Role Permission | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showDetails ? "Edit Role Permission" : "Create Role Permission"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Role Permission Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='role_permission_name' className='form-control'
                      {...register('role_permission_name', { required: true })} />
                  </label>
                  {errors.role_permission_name && <span className="required">This field is required.</span>}
                </div>
              </div>
              { /* <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="created-by">
                    <span>Role Permission Created by</span>
                    <select
                      name="action_user_id"
                      className="form-select"
                      {...register('action_user_id', { required: true })}
                    >
                      <option value="">Select User</option>
                      {allUsers.map((option) => (
                        <option value={option.user_id}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                  </label>
                  {errors.action_user_id && <span className="required">This field is required.</span>}
                </div>
                      </div> */ }
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Role Permission Status <a style={{ color: "red" }}>*</a></span>
                    <select name='role_permission_status' className="form-select" {...register('role_permission_status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.role_permission_status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    rolePermissionInfo: state.rolePermissionInfo,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RolePermissionCreatePage);