import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function incidentPriorityDataReducer(state = initialState.incidentPriorityData, action) {
  switch (action.type) {
    case types.LOAD_INCIDENT_PRIORITY_DATA_SUCCESS:
      return action.incidentPriorityData;
    default:
      return state;
  }
}
