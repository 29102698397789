import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const IncidentCategoryCreatePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, incidentCategoryData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [incidentCategoryId, setIncidentCategoryId] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Incident Category');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowDetails(false);
    if (id !== undefined && !showDetails) {

      const decodedId = atob(id);
      setIncidentCategoryId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'incident_category_id': decodedId };
      const requestData = encryptData(obj);
      const response = actions.incidentCategoryData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  incidentCategoryData:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                setValue("incident_category", results[0].incident_category);
                setValue("status", results[0].status);
                setValue("category_for", results[0].category_for);
                setShowDetails(true);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }
  }, [id, setValue, setShowDetails, incidentCategoryData]);

  const onSubmit = (data) => {
    let params = { 'incident_category': data.incident_category, 'status': data.status, 'category_for': data.category_for };

    if (incidentCategoryId !== '') {
      params = { 'incident_category': data.incident_category, 'status': data.status, 'category_for': data.category_for, 'incident_category_id': incidentCategoryId };
    }
    const requestData = encryptData(params);
    const response = actions.addEditIncidentCategory(requestData, navigate);
    response.then((result) => { 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            setTimeout(() => {
                navigate(`/dashboard/incident-category`);
            }, 1000);
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
    }).catch((error) => {
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title> Incident Category | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showDetails ? "Edit Incident Category" : "Create Incident Category"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Category For <a style={{ color: "red" }}>*</a></span>
                    <select name='category_for' className="form-select" {...register('category_for', { required: true })}>
                      <option value="">Please select option</option>
                      <option value="1">Incident</option>
                      <option value="2">Request</option>
                    </select>
                  </label>
                  {errors.category_for && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Incident Category Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='incident_category' className='form-control'
                      {...register('incident_category', { required: true })} />
                  </label>
                  {errors.incident_category && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Incident Category Status <a style={{ color: "red" }}>*</a></span>
                    <select name='status' className="form-select" {...register('status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    incidentCategoryData: state.incidentCategoryData,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentCategoryCreatePage);