import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as campaignsActions from "../../actions/campaignsActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const BeatPlanPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    const handleAssignedUser = (event) => {
        const selecteduserId = event.target.value;
        setSelectedUsers(selecteduserId);
    }

    const onSubmit = (data) => {

        if (data.assign_to.trim() === '') {
            toast.error("User name is required.", {
                position: "top-right",
            });
        } else if (data.date.trim() === '') {
            toast.error("Date is required.", {
                position: "top-right",
            });
        } else {
            setPreloaderStatus(true);
            const params = {
                'start': '0',
                'length': config.MAX_RECORDS,
                'assign_to': data.assign_to,
                'date': data.date
            };

            setStartDate(data.date);

            const requestData = encryptData(params);
            const response = actions.getUserBeatPlanData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  lead list:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data.beat_plan_data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllBeatPlans(resultArr);
                } else {
                    setPreloaderStatus(false);
                    setAllBeatPlans([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  lead list:", error);
            });
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allBeatPlans.filter(item => {
        const companyName = item.companyName?.toLowerCase() || '';
        const assignedUser = item.assignedUser?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        return (
            companyName.includes(searchQuery.toLowerCase()) ||
            assignedUser.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Due Daily Beat | Ckers</title>
            </Helmet>

            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <span>User Name <a style={{ color: "red" }}>*</a></span>
                                    <div className="text-dark">
                                        <select id="assign_to" name='assign_to' className="form-select" {...register('assign_to')} value={selectedUsers} onChange={handleAssignedUser}>
                                            <option value=''>Select User</option>
                                            {allLoggedinUserChild.length > 0 && allLoggedinUserChild.map((option) => (
                                                <option value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                        {/* <Multiselect
                                            id="created_by"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        /> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Date <a style={{ color: "red" }}>*</a></span>
                                        <input type='date' name='date' className='form-control'{...register('date')} />
                                    </label>
                                    {errors.date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="main-wrap" className="mb-5">

                    <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                        Due Daily Beat
                    </Typography>
                    <div className="history-tl-container beatplan-history">
                        <ul className="tl">
                            {paginatedItems.map((item, index) => (
                                item && (
                                    <li key={index} className="tl-item">
                                        {item.flag === 'loggedin' && (
                                            <>
                                                <div className="tl-item">
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">Login</span>
                                                    </div>
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">Time</span>
                                                        <span className="item-date">{item.loginDateTime}</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {item.flag === 'beat_plan' && ( // Check for 'beat_plan' flag and required fields
                                            <>
                                                <div className="tl-item">
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">Beat Plan</span>
                                                        <span className = {(item.beatPlanStartTime === "" ? "item-date text-danger" : "item-date")}>{item.company_name}</span>
                                                    </div>
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">Scheduled Time</span>
                                                        <span className = {(item.beatPlanStartTime === "" ? "item-date text-danger" : "item-date")}>{item.scheduledTime}</span>
                                                    </div>
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">Actual Start Time</span>
                                                        <span className="item-date">{item.beatPlanStartTime}</span>
                                                        <span className="item-date">{item.startGpsAddress}</span>
                                                    </div>
                                                    <div className="tl-line">
                                                        <span className="d-block text-nowrap">End Time</span>
                                                        <span className="item-date">{item.beatPlanEndTime}</span>
                                                        <span className="item-date">{item.endGpsAddress}</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                )
                            ))}

                            {paginatedItems.length < 1 && (
                                <>
                                    <span className="d-block text-nowrap">No record found</span>    
                                </>
                            )}
                        </ul>
                    </div>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBeatPlanData: state.getBeatPlanData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BeatPlanPage);