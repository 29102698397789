import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function masterProfileDataReducer(state = initialState.masterProfileData, action) {
  switch (action.type) {
    case types.GET_LEAD_MASTER_PROFILE:
      return action.masterProfileData;
    default:
      return state;
  }
}
