import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import config from "../../config";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import { encryptData } from '../../utils/cryptoUtils';

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
import * as manageShipmentActions from "../../actions/manageShipmentActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ManageShipmentByLeadListingPage = ({ props, allShipment, leadId }) => {

    useEffect(() => {
        // console.log("allShipment = ", allShipment);
        // console.log("leadId = ", leadId);
    }, [allShipment, leadId]);

    const { actions } = props;

    const [open, setOpen] = useState(false);
    const [viewModalInfo, setViewModalInfo] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.id));
        if (btnAction === 'cancel') {

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'shipment_order_id': itemObj.id };
            const requestData = encryptData(obj);

            const response = actions.cancelOrder(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });

        } else {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'shipment_order_id': itemObj.id };
            const requestData = encryptData(obj);

            const response = actions.shipmentTrackingAwbNumber(requestData, false);

            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {
                    const arrResult = result.data.data;
                    setViewModalInfo(arrResult);
                    setOpen(true);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - add/edit lead form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    }


    return (
        <>
            <div id='main-wrap' className='mt-4'>
                <div className='row justify-content-between'>
                    <div className='col-md-8'>
                        <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                            All Shipments
                        </Typography>
                    </div>

                    <div className='col-md-4'>
                        <div className="d-flex align-items-center justify-content-between mb-0">
                            <span>&nbsp;</span>
                            <RouterLink to= {`/dashboard/create-shipment/${btoa(String(leadId))}`} className='btn-info btn'><i className="bi bi-plus">&nbsp;</i>Create Shipment</RouterLink>
                        </div>
                    </div>
                </div>
                <div className='table-wrap mt-2'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Lead</th>
                                <th>Created By</th>
                                <th>Created On</th>
                                <th>Shipment Status</th>
                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {allShipment && allShipment.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.leadName}</td>
                                    <td>{item.createdByName}</td>
                                    <td>{item.created_date}</td>
                                    <td>{item.shipment_status}</td>
                                    {/* <td>
                                        <div className="btn-group dropstart">
                                            <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <RouterLink onClick={() => onRowClickAction(item, 'track')} >Track Shipment</RouterLink>
                                                </li>
                                                <li>
                                                    <RouterLink onClick={() => onRowClickAction(item, 'cancel')} >Cancel Shipment</RouterLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body'>
                        <div className="history-tl-container beatplan-history">
                            <ul className="tl">
                                <div className="history-tl-container beatplan-history">
                                    <ul className="tl">
                                        {viewModalInfo && viewModalInfo.tracking_data && viewModalInfo.tracking_data.shipment_track_activities && viewModalInfo.tracking_data.shipment_track_activities.length > 0 ? (
                                            viewModalInfo.tracking_data.shipment_track_activities.map((activity, index) => (
                                                <li key={index} className="tl-item">
                                                    {activity && (
                                                        <>
                                                            <div className="tl-item">
                                                                <div className="tl-line">
                                                                    <span className="d-block text-nowrap">Status : {activity.activity}</span>
                                                                    <span className="d-block text-nowrap">Location : {activity.location}</span>
                                                                    <span className="d-block text-nowrap">Date : {activity.date}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </li>
                                            ))
                                        ) : (
                                            <li className="tl-item">
                                                <span className="d-block text-nowrap">No shipment activities found</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </ul>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, manageShipmentActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageShipmentByLeadListingPage);