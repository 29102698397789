import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadItrByLeadDataReducer(state = initialState.leadItrByLeadData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_ITR_BY_LEAD_DATA_SUCCESS:
      return action.leadItrByLeadData;
    default:
      return state;
  }
}
