import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function roleInfoReducer(state = initialState.roleInfo, action) {
  switch (action.type) {
    case types.LOAD_ROLE_INFO_SUCCESS:
      return action.roleInfo;
    default:
      return state;
  }
}
