import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import TableToExcel from '@linways/table-to-excel';
import { reset } from 'numeral';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import AccessDenied from '../common/AccessDenied';

// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const FiReportPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [leadsFiReported, setLeadsFiReported] = useState([]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }
    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'FI Report')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        // Get the current date
        const today = new Date();

        // Calculate the previous day
        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 1);

        // Format the dates in YYYY-MM-DD format
        const previousDayFormatted = formatDate(previousDay);
        const todayFormatted = formatDate(today);
        // Set the initial values in the form without triggering validation
        setValue('start_date', todayFormatted);
        setValue('end_date', todayFormatted);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted };
        const requestData = encryptData(params);
        const response = actions.getLeadsFiReported(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getLeadsFiReported:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setLeadsFiReported(resultArr);
            } else {
                setPreloaderStatus(false);
                setLeadsFiReported([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getLeadsFiReported:", error);
        });

    }, [setLeadsFiReported]);

    const onSubmit = (data) => {
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'start_date': data.start_date,
            'end_date': data.end_date,
        };

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        setPreloaderStatus(true);

        const requestData = encryptData(params);
        const response = actions.getLeadsFiReported(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setLeadsFiReported(resultArr);
            } else {
                setPreloaderStatus(false);
                setLeadsFiReported([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = leadsFiReported.filter(item => {
        const name = item.name?.toLowerCase() || '';
        const leadAssignedUserName = item.leadAssignedUserName?.toLowerCase() || '';
        return (
            name.includes(searchQuery.toLowerCase()) ||
            leadAssignedUserName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const exportLeadsFiReported = async () => {
        const table = document.getElementById('exportExcelFile');

        TableToExcel.convert(table, {
            name: 'FI-Reported.xlsx',
            sheet: {
                name: 'FI-Reported',
            },
        });
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>cKers | FI Reported</title>
            </Helmet>

            <Container>

                <Typography variant="h4" sx={{ mb: 5 }}>
                    FI Reported
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date</span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id='main-wrap' className='mb-4'>

                    <div className="row justify-content-between">

                        <div className='col-md-6 text-end ps-lg-5'>&nbsp;</div>
                        <div className='col-md-4 text-end pe-0 ps-lg-5'>
                            <div className="form-group">

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by lead name and user name ..."
                                    id="search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                            </div>
                        </div>

                        <div className='col-md-2 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <button className="btn btn-info text-white ms-3" onClick={() => exportLeadsFiReported()}>
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='table-wrap table-responsive'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>Sales User Name</th>
                                    <th className='text-nowrap'>Lead Name</th>
                                    <th className='text-nowrap'>Mobile</th>
                                    <th className='text-nowrap'>OEM</th>
                                    <th className='text-nowrap'>Lead Created Date & Time</th>
                                    <th className='text-nowrap'>First FI Reported Date & Time</th>
                                    <th className='text-nowrap'>Last FI Reported Date & Time</th>
                                    <th className='text-nowrap'>CIBIL Creation Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.leadAssignedUserName}</td>
                                        <td className="text-nowrap">{item.name}</td>
                                        <td className="text-nowrap">{item.mobile}</td>
                                        <td className="text-nowrap">{item.oem}</td>
                                        <td className="text-nowrap">{item.created_date}</td>
                                        <td className="text-nowrap">{item.firstFiReporAt}</td>
                                        <td className="text-nowrap">{item.lastFiReporAt}</td>
                                        <td className="text-nowrap">{item.crifReportAt}</td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={8} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>

                        {paginatedItems.length > 1 && (
                            <>
                                <div className="mb-2">
                                    Record - {paginatedItems.length}
                                </div>
                            </>
                        )}

                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>

                    <div className='table-wrap table-responsive d-none'>
                        <table className="table table-hover" id="exportExcelFile">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>Sales User Name</th>
                                    <th className='text-nowrap'>Lead Name</th>
                                    <th className='text-nowrap'>Mobile</th>
                                    <th className='text-nowrap'>OEM</th>
                                    <th className='text-nowrap'>Lead Created Date & Time</th>
                                    <th className='text-nowrap'>First FI Reported Date & Time</th>
                                    <th className='text-nowrap'>Last FI Reported Date & Time</th>
                                    <th className='text-nowrap'>CIBIL Creation Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadsFiReported.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.leadAssignedUserName}</td>
                                        <td className="text-nowrap">{item.name}</td>
                                        <td className="text-nowrap">{item.mobile}</td>
                                        <td className="text-nowrap">{item.oem}</td>
                                        <td className="text-nowrap">{item.created_date}</td>
                                        <td className="text-nowrap">{item.firstFiReporAt}</td>
                                        <td className="text-nowrap">{item.lastFiReporAt}</td>
                                        <td className="text-nowrap">{item.crifReportAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        scoreCardExport: state.scoreCardExport,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FiReportPage);