import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus"; 

export function loggedinUserActivityDataSuccess(loggedinUserActivityData) {
  return { type: types.LOAD_LOGGEDIN_USER_ACTICITY_DATA_SUCCESS, loggedinUserActivityData };
}

export function loggedinUserFollowupLeadsDataSuccess(loggedinUserFollowupLeadsData) {
  return { type: types.LOAD_LOGGEDIN_USER_FOLLOWUP_LEADS_DATA_SUCCESS, loggedinUserFollowupLeadsData };
}

export function loggedinUserNewLeadsDataSuccess(loggedinUserNewLeadsData) {
  return { type: types.LOAD_LOGGEDIN_USER_NEW_LEADS_DATA_SUCCESS, loggedinUserNewLeadsData };
}


const loggedinUserActivityData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_ACTIVITY, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_ACTIVITY, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loggedinUserActivityDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const loggedinUserFollowupLeadsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_FOLLOWUPS, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_FOLLOWUPS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loggedinUserFollowupLeadsDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};


const loggedinUserNewLeadsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_NEW_LEADS, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_NEW_LEADS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loggedinUserNewLeadsDataSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const incidentReportAssignedMe = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.INCIDENT_REPORTE_ASSIGNED_ME, params);
      } else {
        response = await agent.post(API.INCIDENT_REPORTE_ASSIGNED_ME, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getLoggedinUserSourceLeads = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_SOURCE_LEADS, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_SOURCE_LEADS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getLoggedinUserScorecardActivity = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_SCORECARD_ACTIVITY, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_SCORECARD_ACTIVITY, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getDailyLeadsActivity = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_DAILY_LEADS_ACTIVITY, params);
      } else {
        response = await agent.post(API.GET_DAILY_LEADS_ACTIVITY, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const incidentTaskAssignedMe = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.INCIDENT_TASK_ASSIGNED_ME, params);
      } else {
        response = await agent.post(API.INCIDENT_TASK_ASSIGNED_ME, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};


const getCrifPortfolioData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_CRIF_PORTFOLIO_DATA, params);
      } else {
        response = await agent.post(API.GET_CRIF_PORTFOLIO_DATA, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getLoggedinUserExternalLeads = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_LOGGEDIN_USER_EXTERNAL_LEADS, params);
      } else {
        response = await agent.post(API.GET_LOGGEDIN_USER_EXTERNAL_LEADS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

export default {
  loggedinUserActivityData,
  loggedinUserActivityDataSuccess,
  loggedinUserFollowupLeadsData,
  loggedinUserFollowupLeadsDataSuccess,
  loggedinUserNewLeadsData,
  loggedinUserNewLeadsDataSuccess,
  incidentReportAssignedMe,
  getLoggedinUserSourceLeads,
  getLoggedinUserScorecardActivity,
  getDailyLeadsActivity,
  incidentTaskAssignedMe,
  getCrifPortfolioData,
  getLoggedinUserExternalLeads,
}