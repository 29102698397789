import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function rolePermissionInfoReducer(state = initialState.rolePermissionInfo, action) {
  switch (action.type) {
    case types.LOAD_ROLE_PERMISSION_INFO_SUCCESS:
      return action.rolePermissionInfo;
    default:
      return state;
  }
}
