import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadGstDataReducer(state = initialState.leadGstData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_GST_DATA_SUCCESS:
      return action.leadGstData;
    default:
      return state;
  }
}
