import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadInfoReducer(state = initialState.leadInfo, action) {
  switch (action.type) {
    case types.LOAD_LEAD_INFO_SUCCESS:
      return action.leadInfo;
    default:
      return state;
  }
}
