import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const IncidentReporteCreate = (props) => {
  const { leadId, incidentReporteId } = useParams();
  const location = useLocation();
  const { actions, usersData, incidentReporteData, checklistsData, leadInfo, fetchIncidentReporte, allTicketData, allGroupData } = props;
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const [getLeadId, setLeadId] = useState('');
  const [getIncidentReporteId, setIncidentReporteId] = useState('');
  const [expectedResolutionDate, setExpectedResolutionDate] = useState(new Date());
  const [allIncidentReportStatus, setAllIncidentReportStatusData] = useState([]);
  const [getAllIncidentCategoryData, setAllIncidentCategoryData] = useState([]);
  const [getAllIncidentpriorityData, setAllIncidentpriorityData] = useState([]);

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewModalInfo, setViewModalInfo] = useState([]);
  const [showTicketListDetails, setShowTicketListDetails] = useState(false);
  const [getAllTicketData, setAllTicketData] = useState([]);
  const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
  const [getAllUsersListData, setAllUsersListData] = useState([]);


  useEffect(() => {
    setAllIncidentReportStatusData(false);
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj); 
    const response = actions.incidentReportStatusData(requestData);

    response.then((result) => {
        // console.log("Promise resolved -  lead status data :", result); 
        if (result.data.success === 1) {
            setAllIncidentReportStatusData(true);
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          setAllIncidentReportStatusData(resultArr);
        }
    }).catch((error) => {
       //  console.error("Promise rejected -  lead status data :", error); 
    }); 
  }, [setAllIncidentReportStatusData]);

  const handleExpectedResolutionDateChange = (date) => {
    setExpectedResolutionDate(date);
  };

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Leads');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(leadId !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);
    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.incidentCategoryData(requestData);
    response.then((result) => {
        // console.log("Promise resolved -  source data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            setAllIncidentCategoryData(resultArr);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllIncidentCategoryData]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.incidentPriorityData(requestData);
    response.then((result) => {
        // console.log("Promise resolved -  source data:", result); 
        if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            setAllIncidentpriorityData(resultArr);
        }
    }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllIncidentpriorityData]);

  const onSubmit = (data) => {

    // console.log("IncidentReport =  ", props);
    const leadId = props.leadId;
    setLeadId(leadId);

    if(data.description.trim() === ""){
      toast.error("Description is blank.", {
        position: "top-right",
      });
    } else if(data.assigned_group.trim() === ""){
      toast.error("Please select group.", {
        position: "top-right",
      });
    } else if(data.assigned_group.trim() !== "" && data.assigned_user.trim() === ""){
      toast.error("Please select user.", {
        position: "top-right",
      });
    } else {
      
      const params = {
        "lead_id" : leadId,
        "incident_category" : data.incident_category,
        "type_topic" : data.type_topic,
        "description" : data.description,
        "priority_type" : data.priority_type,
        "expected_resolution_date" : expectedResolutionDate,
        "assigned_group" : data.assigned_group,
        "assigned_user" : data.assigned_user,
      };

      if (getIncidentReporteId !== '') {
        params.incident_reporte_id = getIncidentReporteId;
      }

      const requestData = encryptData(params);
      const response = actions.addEditIncidentReporte(requestData, navigate);

      response.then((result) => {
        // console.log("Promise resolved - add/edit lead form:", result); 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            // const incidentReporteId = result.data.incident_reporte_id;
            // setIncidentReporteId(incidentReporteId);

            fetchIncidentReporte();
            reset();
            setSelectedAssignedGroup("");
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
      }).catch((error) => {
          toast.error(error, {
              position: "top-right",
          });
      });
    }
  };

  useEffect(() => {
    setLeadId(props.leadId); 
    if (allTicketData && allTicketData.length > 0) {
      setAllTicketData(allTicketData);
    }
  }, [allTicketData]);

  const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
  };

  const filteredItems = getAllTicketData.filter(item =>
    item.type_topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
  const endIndex = startIndex + config.ITEMS_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

  const handleChangeAssignedGroup = (event, actionName ='') => {
    const selectedValue = event.target.value;
    setSelectedAssignedGroup(selectedValue);

    if(selectedValue !== ""){

        const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id" :  selectedValue};
        const requestData = encryptData(obj);
    
        const response = actions.groupsUsersData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  groupsUsersData list:", result);
            if (result.data.success === 1) {
                const usersListData = result.data.data.data;
                // console.log("usersListData = ", usersListData);

                if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                  const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                  setAllUsersListData(resultArr);
                 // setAllUsersListData(usersListData[0].users_list);
                }
                setSelectedAssignedGroup(selectedValue);
                if( actionName !== 'selectedUser' ){
                  setValue("assigned_user", "");
                }
            }
        }).catch((error) => {
            console.error("Promise rejected -  groupsUsersData list:", error);
        });
    } else {
        setAllUsersListData([]);
    }
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{getIncidentReporteId ? "Edit Incident Report" : "Create Incident Report"}</title>
      </Helmet>

      <Container className='px-0'>

          <div id='main-wrap' className='mt-4'>
            <Typography variant="h4" sx={{ mb: 5 }}>
              {getIncidentReporteId ? "Edit Incident Report" : "Create Incident Report"}
            </Typography>
            <div className='row'>

              <div className='col-md-12'>
                <form onSubmit={handleSubmit(onSubmit)} className="row" encType="multipart/form-data">
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor="reporting-user">
                        <span>Approval Workflow For <a style={{ color: "red" }}>*</a></span>
                        <select name='approval_workflow_for' className="form-select" {...register('approval_workflow_for', { required: true })}>
                          <option value="">Please select option</option>
                          <option value="1">Incident</option>
                          <option value="2">Request</option>
                        </select>
                      </label>
                      {errors.approval_workflow_for && <span className="required">This field is required.</span>}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor="reporting-user">
                        <span>Incident Category</span>
                        <select name='incident_category' className="form-select" {...register('incident_category', { required: true })}>
                          <option value="">Select Incident Category</option>
                          {getAllIncidentCategoryData.length > 0 && getAllIncidentCategoryData.map((option) => (
                              <option value={option.id}>
                                  {option.incident_category}
                              </option>
                          ))}
                        </select>
                      </label>
                      {errors.incident_category && <span className="required">This field is required.</span>}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor="name">
                        <span>Type topic of the ticket</span>
                        <input type='text' name='type_topic' className='form-control'
                          {...register('type_topic', { required: true })} />
                      </label>
                      {errors.type_topic && <span className="required">This field is required.</span>}
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='form-group'>
                        <label htmlFor="query">
                            <span>Type detailed description</span>
                            <textarea
                                id='description'
                                name='description'
                                className='form-control'
                                {...register('description')}
                            />
                        </label>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor="priority-type">
                        <span>Set Priority</span>
                        <select name='priority_type' className="form-select" {...register('priority_type', { required: true })}>
                          <option value="">Select Priority</option>
                          {getAllIncidentpriorityData.length > 0 && getAllIncidentpriorityData.map((option) => (
                              <option value={option.id}>
                                  {option.incident_priority}
                              </option>
                          ))}
                        </select>
                      </label>
                      {errors.priority_type && <span className="required">This field is required.</span>}
                    </div>
                  </div>

                  <div className='col-md-6'>
                      <div className='form-group'>
                      <label htmlFor="expected_resolution_date">
                            <span>Resolution Date/Time</span>
                            { /* <input type='date' id='expected_resolution_date' name='expected_resolution_date' className='form-control' {...register('expected_resolution_date')} /> */ }
                            <DatePicker selected={expectedResolutionDate} className='form-control'  
                                onChange={handleExpectedResolutionDateChange} 
                                showTimeSelect 
                                dateFormat="Pp" 
                                minDate={new Date()} 
                                id='expected_resolution_date' name='expected_resolution_date' />
                        </label>
                      </div>
                  </div>

                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="assigned-group">
                              <span>Assigned group</span>
                              <select
                                  id='assigned_group'
                                  name='assigned_group'
                                  className="form-select"
                                  {...register('assigned_group')}
                                  value={selectedAssignedGroup}
                                  onChange={handleChangeAssignedGroup}
                              >
                                <option value=''>Select assigned group</option>
                                {allGroupData.map((option) => (
                                    <option value={option.id}>
                                        {option.group_name}
                                    </option>
                                ))}
                              </select>
                          </label>
                      </div>
                  </div>

                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="assigned-user">
                              <span>Assigned to</span>
                              <select
                                  id='assigned_user'
                                  name='assigned_user'
                                  className="form-select"
                                  {...register('assigned_user')}
                              >
                                <option value=''>Select User</option>
                                {getAllUsersListData.map((option) => (
                                    <option value={option.user_id}>
                                        {option.name}
                                    </option>
                                ))}
                                {getAllUsersListData.length > 0 && (
                                    <option value='any_user'>Any User</option>
                                )}
                              </select>
                          </label>
                      </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='form-group mb-2'>
                      <button className='btn btn-primary'>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div id='main-wrap' className='mt-4'>
              <div className='row justify-content-between'>
                  <div className='col-md-8'> 
                      <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                          All Incident Reports
                      </Typography>
                  </div>
                  <div className='col-md-4 pe-0 ps-lg-5'>
                      <div className="form-group">
                      &nbsp;&nbsp;
                          {/* <label htmlFor="search">
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  id="search"
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                              />
                          </label> */}
                      </div>
                  </div>
              </div>
              <div className='table-wrap'>
                  <table className="table table-hover">
                      <thead>
                          <tr>
                              <th>Created By</th>
                              <th>Created Date</th>
                              <th>Topic</th>
                              <th>Assigned to</th>
                              <th>Group Name</th>
                              <th>Priority </th>
                              {/* <th>Action</th> */}
                          </tr>
                      </thead>
                      <tbody>
                          {paginatedItems.map((item, index) => (
                              <tr key={index}>
                                  <td>{item.createdByName}</td>
                                  <td>{item.created_date}</td>
                                  <td>{item.type_topic}</td>
                                  <td>{item.assignedTo}</td>
                                  <td>{item.assignedToGroup}</td>
                                  <td><span
                                      className={(item.priority_type === '1') ? "badge text-bg-danger text-white" : (item.priority_type === '2') ? "badge text-bg-success text-white" : "badge text-bg-success text-white"}
                                  >{item.priorityType}</span>
                                  </td>
                                  {/* <td>
                                    <div className="btn-group dropstart" title="Edit">
                                        <NavLink to={`/dashboard/view-ticket/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink>
                                    </div>
                                  </td>                                                 */}
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
              <div>
                <nav className='mb-1'>
                    <ul className="pagination pagination-md justify-content-end">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                            <li className="page-item">
                                <button
                                    key={page}
                                    onClick={() => handlePageChange(page)}
                                    disabled={currentPage === page}
                                    className="page-link"
                                >
                                    {page}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
              </div>
          </div>
        
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    checklistsData: state.checklistsData,
    incidentReporteData: state.incidentReporteData,
    getUserModuleData: state.getUserModuleData,
    leadInfo: state.leadInfo,
    groupsUsersData: state.groupsUsersData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, incidentReporteActions.default, groupsUsersActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentReporteCreate);