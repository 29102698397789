import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui  
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import * as reportActions from "../../actions/reportActions";

import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadBreakupDetailPage = (props) => {
  const { leadTypeId, leadBreakupType, getDate } = useParams();
  const location = useLocation();
  const { actions, leadBreakupCountDetailsData, usersData, sourceData, getUserModuleData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allLeads, setAllLeads] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewModalInfo, setViewModalInfo] = useState([]);
  const [showLeadDetails, setShowLeadDetails] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allLeadSource, setAllLeadSource] = useState([]);


  const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [showLeadProfileDetails, setShowLeadProfileDetails] = useState(false);

  const [getLeadTypeId, setLeadTypeId] = useState('');
  const [getLeadBreakupType, setLeadBreakupType] = useState('');
  const [getLeadDate, setLeadDate] = useState('');

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    setShowLeadDetails(false);
    console.log("Render LeadBreakupDetailPage component ", showLeadDetails);

    const params = new URLSearchParams(location.search);
    if (leadTypeId !== undefined && leadBreakupType !== undefined && getDate !== undefined && !showLeadDetails) {
      const decodedLeadTypeId = atob(leadTypeId);
      const decodedLeadBreakupType = atob(leadBreakupType);
      const decodedGetDate = atob(getDate);
      setLeadTypeId(decodedLeadTypeId);
      setLeadBreakupType(decodedLeadBreakupType);
      setLeadDate(decodedGetDate);

      const obj = { 'start': '0', 'length': config.MAX_RECORDS, "leadTypeId": decodedLeadTypeId, "leadBreakupType": decodedLeadBreakupType, "leadDate": decodedGetDate };
      const requestData = encryptData(obj);
      const response = actions.leadBreakupCountDetailsData(requestData);
      response.then((result) => {
        console.log("Promise resolved -  lead list:", result);
        if (result.data.success === 1) {
          setShowLeadDetails(true);
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          setAllLeads(resultArr);
        }
      }).catch((error) => {
        console.error("Promise rejected -  lead list:", error);
      });
    }
  }, [leadBreakupCountDetailsData]);

  const handleClose = () => {
    setOpen(false);
  };

  const onRowClickAction = (itemObj, btnAction) => {
    const encodedId = btoa(String(itemObj.id));
    if (btnAction === 'edit') {
      const url = `/dashboard/edit-lead/${encodedId}`;
    } else {
      setViewModalInfo(itemObj);
      setOpen(true);
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const filteredItems = allLeads.filter(item => {
    const name = item.name?.toLowerCase() || '';
    const formattedID = item.formatetdID?.toLowerCase() || '';
    const leadAssigned = item.leadAssigned?.toLowerCase() || '';
    const statusName = item.statusName?.toLowerCase() || '';
  
    return (
      name.includes(searchQuery.toLowerCase()) ||
      formattedID.includes(searchQuery.toLowerCase()) ||
      leadAssigned.includes(searchQuery.toLowerCase()) ||
      statusName.includes(searchQuery.toLowerCase())
    );
  });

  const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
  const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title> Leads | Ckers </title>
      </Helmet>

      <Container>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography variant="h4" sx={{ mb: 0 }}>
            All Leads
          </Typography>
          {/* <RouterLink to='/dashboard/create-lead' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add Lead</RouterLink> */}
        </div>

        <div id='main-wrap' className='mb-4'>
          <div className='row justify-content-end mt-2'>
            <div className='col-md-4 pe-0 ps-lg-5'>
              <div className="form-group">
                <label htmlFor="search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Status, Name or Assigned To.. "
                    id="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className='table-wrap'>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Assigned To</th>
                  <th>Created Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.leadAssigned}</td>
                    <td>{item.created_date}</td>
                    <td><span
                      className={(item.status === '1') ? "badge text-bg-success text-white" : (item.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}
                    >{item.statusName}</span></td>
                    <td>
                        <div className="btn-group dropstart" title="Edit">
                            <NavLink to={`/dashboard/edit-lead/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink>
                        </div>
                    </td>
                  </tr>
                ))}

                { paginatedItems.length < 1 && (
                    <>
                      <tr>
                        <td>No record found</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </>
                )}
              </tbody>
            </table>
          </div>
          <div>
          <nav className='mb-1'>
            <ul className="pagination pagination-md justify-content-end">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <li className="page-item">
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    disabled={currentPage === page}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          </div>
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className='modal-custom rounded'>
          <div className='modal-head'>
            <h4 id="modal-title">Lead  : {viewModalInfo.name}</h4>
            <Button onClick={handleClose} color="primary" className=' btn'>
              <i className="bi bi-x">&nbsp;</i>
            </Button>
          </div>
          <div className='modal-body'>
            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Lead ID :</b></div>
              <div className='col-7 ps-0'>{viewModalInfo.id}</div>
            </div>

            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Created By :</b></div>
              <div className='col-7 ps-0'>{viewModalInfo.lead_created_by}</div>
            </div>

            <div className='row mb-2 pb-1'>
              <div className='col-4'><b>Lead Status :</b></div>
              <div className='col-7 ps-0'><span className={(viewModalInfo.status === '1') ? "badge text-bg-success text-white" : (viewModalInfo.status === '2') ? "badge text-bg-warning text-white" : "badge text-bg-danger text-white"}>{viewModalInfo.statusName}</span></div>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
}

function mapStateToProps(state) {
  return {
    leadBreakupCountDetailsData: state.leadBreakupCountDetailsData,
    usersData: state.usersData,
    sourceData: state.sourceData,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default, userActions.default, reportActions.default), dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadBreakupDetailPage);