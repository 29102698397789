import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from '../../config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../common.scss';
import { encryptData } from '../../utils/cryptoUtils';
import * as userActions from '../../actions/userActions';
import * as leadActions from '../../actions/leadActions';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadChecklistScore = ({ props, allLeadCheckListScore, setOpen, leadId, actions }) => {
    const [allLeadScore, setAllLeadScore] = useState([]);
    const [teadTotalScore, setLeadTotalScore] = useState('Not Found');
    const [leadIdValue, setLeadIdValue] = useState('');

    const [scoreData, setScoreData] = useState([]);
    const [dataInCSV, setDataInCSV] = useState('');

    const [scoreDataFormate, setScoreDataFormate] = useState(false);



    useEffect(() => {
        // console.log("allLeadCheckListScore = ", allLeadCheckListScore);

        setLeadIdValue(leadId);
        if (allLeadCheckListScore.length !== 0) {
            setAllLeadScore(allLeadCheckListScore[0]);
            setLeadTotalScore(allLeadCheckListScore[1]);
        }

        // const leadScoreResults = allLeadCheckListScore.lead_score;
        // const leadScoreResultsArr = Object.keys(leadScoreResults).map((key) => leadScoreResults[key]);
        // setAllLeadScore(leadScoreResultsArr);

        // console.log("leadScoreResultsArr = ", leadScoreResultsArr);
    }, [allLeadCheckListScore, setAllLeadScore, setLeadTotalScore, setLeadIdValue]);

    const exportFile = () => {

        const params = { "lead_id": leadIdValue, "checklist_id": config.FI_SHEET_CHECK_ID };

        const requestData = encryptData(params);
        const response = actions.exportLeadScoreCard(requestData);
        response.then((result) => {
            // console.log("Promise resolved - add/edit lead form:", result);
            if (result.data.success === 1) {
                const resResult = result.data.data;

                if (Object.prototype.hasOwnProperty.call(resResult, 'scoreData')) {
                    setScoreData(resResult.scoreData);
                    
                    if(!scoreDataFormate){
                        $(document).ready(() => {
                            const thirdRow = $('.data_table tr:eq(1)');
                            const fourthRow = $('.data_table tr:eq(2)');
                            const fiththRow = $('.data_table tr:eq(3)');

                            const cellsToMove1 = thirdRow.find('td').add(fourthRow.find('td')).add(fiththRow.find('td'));
                            const append1 = $('.data_table tr:eq(0)');

                            cellsToMove1.each((index, cell) => {
                                append1.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const seventhRow = $('.data_table tr:eq(5)');
                            const eightthRow = $('.data_table tr:eq(6)');
                            const ninethRow = $('.data_table tr:eq(7)');
                            const tenthRow = $('.data_table tr:eq(8)');

                            const cellsToMove2 = seventhRow
                                .find('td')
                                .add(eightthRow.find('td'))
                                .add(ninethRow.find('td'))
                                .add(tenthRow.find('td'));
                            const append2 = $('.data_table tr:eq(4)');

                            cellsToMove2.each((index, cell) => {
                                append2.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const elevenRow = $('.data_table tr:eq(10)');
                            const twelvthRow = $('.data_table tr:eq(11)');
                            const thirteenRow = $('.data_table tr:eq(12)');

                            const cellsToMove3 = elevenRow.find('td').add(twelvthRow.find('td')).add(thirteenRow.find('td'));
                            const append3 = $('.data_table tr:eq(9)');

                            cellsToMove3.each((index, cell) => {
                                append3.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const fourteenRow = $('.data_table tr:eq(14)');
                            const fithteenRow = $('.data_table tr:eq(15)');
                            const sixteenRow = $('.data_table tr:eq(16)');
                            const seventeenRow = $('.data_table tr:eq(17)');

                            const cellsToMove4 = fourteenRow
                                .find('td')
                                .add(fithteenRow.find('td'))
                                .add(sixteenRow.find('td'))
                                .add(seventeenRow.find('td'));
                            const append4 = $('.data_table tr:eq(13)');

                            cellsToMove4.each((index, cell) => {
                                append4.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const nineteenRow = $('.data_table tr:eq(19)');
                            const twentyRow = $('.data_table tr:eq(20)');

                            const cellsToMove5 = nineteenRow.find('td').add(twentyRow.find('td'));
                            const append5 = $('.data_table tr:eq(18)');

                            cellsToMove5.each((index, cell) => {
                                append5.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const twentythreeRow = $('.data_table tr:eq(23)');
                            const twentyfourRow = $('.data_table tr:eq(24)');
                            const twentyfiveRow = $('.data_table tr:eq(25)');

                            const cellsToMove6 = twentythreeRow
                                .find('td')
                                .add(twentyfourRow.find('td'))
                                .add(twentyfiveRow.find('td'));
                            const append6 = $('.data_table tr:eq(22)');

                            cellsToMove6.each((index, cell) => {
                                append6.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const twentysevenRow = $('.data_table tr:eq(27)');
                            const twentyeightRow = $('.data_table tr:eq(28)');

                            const cellsToMove7 = twentysevenRow.find('td').add(twentyeightRow.find('td'));
                            const append7 = $('.data_table tr:eq(26)');

                            cellsToMove7.each((index, cell) => {
                                append7.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const thirtyOneRow = $('.data_table tr:eq(31)');
                            const thirtyTwoRow = $('.data_table tr:eq(32)');

                            const cellsToMove8 = thirtyOneRow.find('td').add(thirtyTwoRow.find('td'));
                            const append8 = $('.data_table tr:eq(30)');

                            cellsToMove8.each((index, cell) => {
                                append8.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const thirtyFourRow = $('.data_table tr:eq(34)');

                            const cellsToMove9 = thirtyFourRow.find('td');
                            const append9 = $('.data_table tr:eq(33)');

                            cellsToMove9.each((index, cell) => {
                                append9.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const thirtySixRow = $('.data_table tr:eq(36)');
                            const thirtySevenRow = $('.data_table tr:eq(37)');

                            const cellsToMove10 = thirtySixRow.find('td').add(thirtySevenRow.find('td'));
                            const append10 = $('.data_table tr:eq(35)');

                            cellsToMove10.each((index, cell) => {
                                append10.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            

                            const FourtyOneRow = $('.data_table tr:eq(41)');
                            const FourtyTowRow = $('.data_table tr:eq(42)');
                            const FourtyThreeRow = $('.data_table tr:eq(43)');

                            const cellsToMove11 = FourtyOneRow.find('td').add(FourtyTowRow.find('td')).add(FourtyThreeRow.find('td'));
                            const append11 = $('.data_table tr:eq(40)');

                            cellsToMove11.each((index, cell) => {
                                append11.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            

                            const FourtyNineRow = $('.data_table tr:eq(49)');
                            const cellsToMove12 = FourtyNineRow.find('td');
                            const append12 = $('.data_table tr:eq(48)');

                            cellsToMove12.each((index, cell) => {
                                append12.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                            

                            const FiftyOneRow = $('.data_table tr:eq(51)');
                            const cellsToMove13 = FiftyOneRow.find('td');
                            const append13 = $('.data_table tr:eq(50)');

                            cellsToMove13.each((index, cell) => {
                                append13.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                            

                            const FiftyThreeRow = $('.data_table tr:eq(53)');
                            const FiftyFourRow = $('.data_table tr:eq(54)');
                            const cellsToMove14 = FiftyThreeRow.find('td').add(FiftyFourRow.find('td'));
                            const append14 = $('.data_table tr:eq(52)');

                            cellsToMove14.each((index, cell) => {
                                append14.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                            

                            const FiftySixRow = $('.data_table tr:eq(56)');
                            const FiftySevenRow = $('.data_table tr:eq(57)');
                            const cellsToMove15 = FiftySixRow.find('td').add(FiftySevenRow.find('td'));
                            const append15 = $('.data_table tr:eq(55)');

                            cellsToMove15.each((index, cell) => {
                                append15.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                        
                            

                            const FiftyNineRow = $('.data_table tr:eq(60)');
                            const SixtyRow = $('.data_table tr:eq(61)');
                            const SixtyOneRow = $('.data_table tr:eq(62)');
                            const cellsToMove17 = FiftyNineRow.find('td').add(SixtyRow.find('td')).add(SixtyOneRow.find('td'));
                            const append17 = $('.data_table tr:eq(59)');

                            cellsToMove17.each((index, cell) => {
                                append17.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                            

                            const SeventyRow = $('.data_table tr:eq(70)');
                            const SeventyOneRow = $('.data_table tr:eq(71)');
                            const SeventyTwoRow = $('.data_table tr:eq(72)');
                            const cellsToMove18 = SeventyRow.find('td').add(SeventyOneRow.find('td')).add(SeventyTwoRow.find('td'));
                            const append18 = $('.data_table tr:eq(69)');

                            cellsToMove18.each((index, cell) => {
                                append18.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });
                            

                            const SeventyFourRow = $('.data_table tr:eq(74)');
                            const SeventyFiveRow = $('.data_table tr:eq(75)');
                            const cellsToMove19 = SeventyFourRow.find('td').add(SeventyFiveRow.find('td'));
                            const append19 = $('.data_table tr:eq(73)');

                            cellsToMove19.each((index, cell) => {
                                append19.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            thirdRow.remove();
                            fourthRow.remove();
                            fiththRow.remove();
                            seventhRow.remove();
                            eightthRow.remove();
                            ninethRow.remove();
                            tenthRow.remove();
                            elevenRow.remove();
                            twelvthRow.remove();
                            thirteenRow.remove();
                            fourteenRow.remove();
                            fithteenRow.remove();
                            sixteenRow.remove();
                            seventeenRow.remove();
                            nineteenRow.remove();
                            twentyRow.remove();
                            twentythreeRow.remove();
                            twentyfourRow.remove();
                            twentyfiveRow.remove();
                            twentysevenRow.remove();
                            twentyeightRow.remove();
                            thirtyOneRow.remove();
                            thirtyTwoRow.remove();
                            thirtyFourRow.remove();
                            // thirtyFiveRow.remove();
                            thirtySixRow.remove();
                            thirtySevenRow.remove();
                            FourtyOneRow.remove();
                            FourtyTowRow.remove();
                            FourtyThreeRow.remove();
                            FourtyNineRow.remove();
                            FiftyOneRow.remove();
                            FiftyThreeRow.remove();
                            FiftyFourRow.remove();
                            FiftySevenRow.remove();
                            FiftySixRow.remove();
                            FiftyNineRow.remove();
                            SixtyRow.remove();
                            SixtyOneRow.remove();
                            SeventyRow.remove();
                            SeventyOneRow.remove();
                            SeventyTwoRow.remove();
                            SeventyFourRow.remove();
                            SeventyFiveRow.remove();
                        });

                        setScoreDataFormate(true);
                    }

                    toast.success(result.data.message, {
                        position: 'top-right',
                    });

                    adjustTableAndExport();
                } else {
                    toast.error(result.data.message, {
                        position: 'top-right',
                    });  
                }

                if (Object.prototype.hasOwnProperty.call(resResult, 'csvData')) {
                    setDataInCSV(resResult.csvData);
                    const csvData = resResult.csvData;

                    // if (csvData) {
                    //     const dataInCSV = csvData.map(row => row.join(',')).join('\n');
                    //     const downloadLink = document.createElement('a');
                    //     downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(`${dataInCSV}\n`)}`;
                    //     downloadLink.download = 'filename.csv';
                    //     downloadLink.click();

                    // }
                }
                
            } else {
                toast.error(result.data.message, {
                    position: 'top-right',
                });
            }
        }).catch((error) => {
            toast.error(error, {
                position: 'top-right',
            });
        });
    };

    const adjustTableAndExport =() =>{
        $(document).ready(() => {
            $(this).addClass('');
            $('.data_table tr:eq(0) td:eq(1)').attr('colspan', '3');
            $('.data_table tr:eq(0) td:eq(5)').attr('colspan', '3');
            $('.data_table tr:eq(1) td:eq(1)').attr('colspan', '3');
            $('.data_table tr:eq(2) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(2) td:eq(6)').attr('colspan', '4');
            $('.data_table tr:eq(3) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(3) td:eq(7)').attr('colspan', '2');
            $('.data_table tr:eq(4) td:eq(1)').attr('colspan', '4');
            $('.data_table tr:eq(4) td:eq(3)').attr('colspan', '4');
            $('.data_table tr:eq(5) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(6) td:eq(1)').attr('colspan', '3');
            $('.data_table tr:eq(6) td:eq(5)').attr('colspan', '3');
            $('.data_table tr:eq(7) td:eq(3)').attr('colspan', '3');
            $('.data_table tr:eq(7) td:eq(5)').attr('colspan', '5');
            $('.data_table tr:eq(8) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(9) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(9) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(9) td:eq(5)').attr('colspan', '5');
            $('.data_table tr:eq(10) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(10) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(11) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(11) td:eq(2)').attr('colspan', '3');
            $('.data_table tr:eq(11) td:eq(2)').attr('colspan', '3');
            $('.data_table tr:eq(11) td:eq(5)').attr('colspan', '4');
            $('.data_table tr:eq(12) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(13) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(14) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(14) td:eq(7)').attr('colspan', '4');
            $('.data_table tr:eq(15) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(16) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(17) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(18) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(19) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(19) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(20) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(20) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(21) td:eq(1)').attr('colspan', '4');
            $('.data_table tr:eq(21) td:eq(3)').attr('colspan', '4');
            $('.data_table tr:eq(22) td:eq(3)').attr('colspan', '3');
            $('.data_table tr:eq(22) td:eq(5)').attr('colspan', '5');
            $('.data_table tr:eq(23) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(24) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(24) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(25) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(26) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(26) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(27) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(28) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(29) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(30) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(31) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(31) td:eq(2)').attr('colspan', '2');
            $('.data_table tr:eq(31) td:eq(7)').attr('colspan', '3');
            $('.data_table tr:eq(32) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(32) td:eq(5)').attr('colspan', '6');
            $('.data_table tr:eq(33) td:eq(0)').attr('rowspan', '2');
            $('.data_table tr:eq(33) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(34) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(35) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(36) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(37) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(38) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(39) td:eq(1)').attr('colspan', '11');

            TableToExcel.convert(document.getElementById('ExportExcel'), {
                name: 'FI Report.xlsx',
                sheet: {
                    name: 'FI Report',
                },
            });
        });
    };

    return (
        <>
            <div id="main-wrap">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <h5 className="fw-bold mb-3">Score Card</h5>
                            </div>
                            <div className="col-md-6 text-end d--none">
                                <div className="form-group mb-3">
                                    <button className="btn btn-info text-white" onClick={() => exportFile()}>
                                        Export Excel File
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="table-wrap">
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allLeadScore.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.questionText}</td>
                                            <td>{item.userAnswers}</td>
                                            <td>{item.score}</td>
                                        </tr>
                                    ))}

                                    <tr className={allLeadCheckListScore.length === 0 ? 'hide ' : 'show'}>
                                        <th colSpan={3} className="text-end border-bottom-0">
                                            <h6 className="fw-bold pt-2 mb-0 mt-1">Total Score: {teadTotalScore}</h6>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* {allLeadScore.map((leadCheckListScore) => (
                            <div key={leadCheckListScore.id} className='lead-activity-item'>
                                <p className='mb-2'><b>Queryuestion Text:</b> {leadCheckListScore.questionText}</p>
                                <p className='mb-2'><b>Answers:</b> {leadCheckListScore.userAnswers}</p>
                                <p className='mb-0'><b>Score:</b> {leadCheckListScore.score}</p>
                            </div>
                        ))} */}
                        {allLeadScore.length === 0 && <p className="mt-3">No score found.</p>}
                    </div>

                    <div className="table--wrap table-responsive">
                        <table
                            className="table data_table d-none table-bordered mt-5 table-hover mb-0"
                            id="ExportExcel"
                            data-cols-width="22,16,16,16,16,16,16,16,16,16" data-fill-color="#fff"
                        >
                            <tbody>
                                {scoreData.map((item, index) => (
                                    <tr key={index}>
                                        <td className='p-2' data-b-a-s="thin" data-a-wrap="true" data-b-a-c="000" data-f-sz="10">
                                            {item.questionText}
                                        </td>
                                        <td className='p-2' data-b-a-s="thin" data-a-wrap="true" data-b-a-c="000" data-f-sz="10">
                                            {item.userAnswers}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default), dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadChecklistScore);