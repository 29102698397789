import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadStatusReducer(state = initialState.leadStatusData, action) {
  switch (action.type) {
    case types.LOAD_LEAD_STATUS:
      return action.leadStatusData;
    default:
      return state;
  }
}
