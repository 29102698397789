
import react, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { Button, Typography, Modal, Container, Box, } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as leadActions from "../../actions/leadActions";
import * as moduleAction from "../../actions/moduleAction";
import Loader from "../../components/Loader";
import * as masterAction from "../../actions/masterAction";

const IncidentTaskPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, fetchIncident, updateParentState, userRoleType, allIncidentTask } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const [incidentTaskId, setIncidentTaskId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [incidentId, setIncidentId] = useState('');

    const [getAllChildsData, setAllChildsData] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [assignTo, setAssignTo] = useState("");

    const formRef = useRef(null);

    const [selectedIncidentTaskFiles, setSelectedIncidentTaskFiles] = useState([]);
    const [preloaderStatus, setPreloaderStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [viewModalInfo, setViewModalInfo] = useState([]);

    const [allGroupData, setAllGroupData] = useState([]);
    const [selectedAssignedGroup, setSelectedAssignedGroup] = useState('');
    const [getAllUsersListData, setAllUsersListData] = useState([]);

    const [taskActivity, setTaskActivity] = useState([]);
    const [taskActivityCount, setTaskActivityCount] = useState(0);

    const [allApprovalMaster, setAllApprovalMaster] = useState([]);
    const [selectedApprovalMaster, setSelectedApprovalMaster] = useState("");
    const [selectedApprovalMasterUser, setSelectedApprovalMasterUser] = useState([]);
    const [fieldsShow, setFieldsShow] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [backSequence, setBackSequence] = useState("");
    const [presentSequence, setPresentSequence] = useState("");
    const [isLastSequence, setIsLastSequence] = useState(false);

    const [openWorkflowMaster, setOpenWorkflowMaster] = useState(false);
    const [workflowMaster, setWorkflowMaster] = useState([]);
    const [formEntries, setFormEntries] = useState([{ role: '', users: [], selectedUsers: [], sequence: '' }]);
    const [selectedRole, setSelectedRole] = useState('');
    const [allRoles, setAllRoles] = useState([]);


    const [formValues, setFormValues] = useState({
        task_name: '',
        assigned_group: '',
        assigned_to: '',
        due_date: '',
        is_completed: '',
        note: '',
        upload_file: '',
        approval_master_id: '',
        approval_user:'',
    });

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved - getLoggedinUserChild:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                setAllChildsData(results);
            }
        }).catch((error) => {
            //  console.error("Promise rejected - getLoggedinUserChild:", error); 
        });
    }, [setAllChildsData]);

    useEffect(() => {
        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'approval_workflow_for': "1" };
        const requestData = encryptData(params);
        const response = actions.getApprovalMaster(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getApprovalMaster:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                setAllApprovalMaster(filteredResultArr);
            } else {
                setPreloaderStatus(false);
                setAllApprovalMaster([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getApprovalMaster:", error);
        });
    }, [setAllApprovalMaster]);

    const handleChangeAssignedGroup = (event, actionName = '') => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {

            setSelectedAssignedGroup(selectedValue);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "group_id": selectedValue , "tag_name": "get_all_group_user"};
            const requestData = encryptData(obj);

            const response = actions.groupsUsersData(requestData);
            response.then((result) => {
                // console.log("Promise resolved -  groupsUsersData list:", result);
                if (result.data.success === 1) {
                    const usersListData = result.data.data.data;
                    // console.log("usersListData = ", usersListData);

                    if (Object.prototype.hasOwnProperty.call(usersListData[0], 'users_list')) {
                        const resultArr = usersListData[0].users_list.sort((a, b) => a.name.localeCompare(b.name));
                        setAllUsersListData(resultArr);
                    }
                    setSelectedAssignedGroup(selectedValue);
                    if (actionName !== 'selectedUser') {
                        setValue("assigned_user", "");
                    }
                }
            }).catch((error) => {
                console.error("Promise rejected -  groupsUsersData list:", error);
            });
        } else {
            setAllUsersListData([]);
            setSelectedAssignedGroup("");
        }
    };

    const handleChangeIsCompleted = (event) => {
        const { name, value } = event.target;
        if (name === 'is_completedd') {
            setIsCompleted(event.target.checked);
        }
    };

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.groupData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  groupData list:", result);
            if (result.data.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.group_name.localeCompare(b.group_name)
                );
                setAllGroupData(sortedResultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });

    }, [setAllGroupData]);

    const handleChangeAssignTo = (event) => {
        const selectedValue = event.target.value;
        setAssignTo(selectedValue);
    }

    const handleChangeSequence = (event) => {
        const selectedValue = event.target.value;
        setBackSequence(selectedValue);
    }


    const handleChangeApprovalMaster = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {
            setFieldsShow(true);
            setSelectedApprovalMaster(selectedValue);

            const approvalMaster = allApprovalMaster.find(master => master.id === selectedValue);

            if (approvalMaster) {
                const approvalData = approvalMaster.approvalData;
                const sortedApprovalMasterUser = [...approvalData].sort((a, b) => a.sequence - b.sequence);
                setSelectedApprovalMasterUser(sortedApprovalMasterUser);
            }
        } else {
            setSelectedApprovalMasterUser([]);
            setSelectedApprovalMaster("");
            setFieldsShow(false);
        }
    };

    const onSubmit = (data, event) => {
        const action = event.nativeEvent.submitter.value;

        if (action === "submit") {
            handleFormSubmit(data);
        } else if (action === "proceed_next") {
            handleProceedToNextStep(data, action);
        } else if (action === "proceed_back") {
            handleProceedToNextStep(data, action);
        }
    };

    const handleFormSubmit = (data) => {

        if (selectedApprovalMaster.trim() === "" && formValues.task_name.trim() === "") {
            toast.error(`${config.MODULE_SUB_TYPE_NAME} name is blank.`, {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && selectedAssignedGroup.trim() === "") {
            toast.error("Assigned group is blank.", {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && assignTo.trim() === "") {
            toast.error("Assigned to is blank.", {
                position: "top-right",
            });
        } else if (formValues.due_date.trim() === "" || formValues.due_date === '0000-00-00') {
            toast.error("Due date is blank.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);

            const getIncidentReportId = props.getIncidentReportId;
            setIncidentId(getIncidentReportId);

            let params = {
                "incident_id": getIncidentReportId,
                "task_name": formValues.task_name,
                "assigned_group": selectedAssignedGroup,
                "assigned_to": assignTo,
                "due_date": formValues.due_date,
                "is_completed": (isCompleted ? '1' : '0'),
                "note": formValues.note,
                "upload_file": selectedIncidentTaskFiles,
                "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                "approval_master_id": selectedApprovalMaster,
                "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
            };

            if (incidentTaskId !== '') {
                params = {
                    "incident_task_id": incidentTaskId,
                    "incident_id": getIncidentReportId,
                    "task_name": formValues.task_name,
                    "assigned_group": selectedAssignedGroup,
                    "assigned_to": assignTo,
                    "due_date": formValues.due_date,
                    "is_completed": (isCompleted ? '1' : '0'),
                    "note": formValues.note,
                    "upload_file": selectedIncidentTaskFiles,
                    "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                    "approval_master_id": selectedApprovalMaster,
                    "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
                };
            }
            const requestData = encryptData(params);
            const response = actions.createIncidentTask(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    setPreloaderStatus(false);

                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setIncidentTaskId("");
                    fetchIncident();

                    setFormValues({
                        task_name: '',
                        assigned_group: '',
                        assigned_to: '',
                        due_date: '',
                        is_completed: '',
                        note: '',
                        upload_file: '',
                        approval_master_id: '',
                        approval_user: '',
                    });
                    setSelectedAssignedGroup('');
                    setAssignTo('');
                    setIsCompleted(false);
                    setSelectedIncidentTaskFiles([]);

                    setFieldsShow(false);
                    setSelectedApprovalMaster('');
                    setSelectedApprovalMasterUser([]);

                    reset();
                    setIsStart(false);
                    setShowDiv(false);
                    setPresentSequence("");

                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleProceedToNextStep = (data, action) => {

        if (selectedApprovalMaster.trim() === "" && formValues.task_name.trim() === "") {
            toast.error(`${config.MODULE_SUB_TYPE_NAME} name is blank.`, {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && selectedAssignedGroup.trim() === "") {
            toast.error("Assigned group is blank.", {
                position: "top-right",
            });
        } else if (selectedApprovalMaster.trim() === "" && assignTo.trim() === "") {
            toast.error("Assigned to is blank.", {
                position: "top-right",
            });
        } else if (formValues.due_date.trim() === "" || formValues.due_date === '0000-00-00') {
            toast.error("Due date is blank.", {
                position: "top-right",
            });
        } else if (action === "proceed_back" && backSequence.trim() === "") {
            toast.error("Please select send back to.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);

            const getIncidentReportId = props.getIncidentReportId;
            setIncidentId(getIncidentReportId);

            let params = {};

            if (incidentTaskId !== '') {
                params = {
                    "incident_task_id": incidentTaskId,
                    "incident_id": getIncidentReportId,
                    "task_name": formValues.task_name,
                    "assigned_group": selectedAssignedGroup,
                    "assigned_to": assignTo,
                    "due_date": formValues.due_date,
                    "is_completed": (isCompleted ? '1' : '0'),
                    "note": formValues.note,
                    "upload_file": selectedIncidentTaskFiles,
                    "sub_action_type": config.MODULE_SUB_TYPE_NAME,
                    "approval_master_id": selectedApprovalMaster,
                    "approval_user": formEntries.map(entry => ({
                        role: entry.role,
                        assign_to: entry.selectedUsers.map(user => user.key), // Include selected user IDs
                        sequence: entry.sequence,
                    })),
                    "action": action,
                    "sequence": backSequence,
                };
            }
            const requestData = encryptData(params);
            const response = actions.incidentTaskActivity(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    setPreloaderStatus(false);

                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setIncidentTaskId("");
                    fetchIncident();

                    setFormValues({
                        task_name: '',
                        assigned_group: '',
                        assigned_to: '',
                        due_date: '',
                        is_completed: '',
                        note: '',
                        upload_file: '',
                        approval_master_id: '',
                        approval_user: '',
                    });
                    setSelectedAssignedGroup('');
                    setAssignTo('');
                    setIsCompleted(false);
                    setSelectedIncidentTaskFiles([]);

                    setFieldsShow(false);
                    setSelectedApprovalMaster('');
                    setSelectedApprovalMasterUser([]);

                    reset();
                    setIsStart(false);
                    setShowDiv(false);
                    setPresentSequence("");

                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name; // Get the file name

                const parts = base64Data.split(',');
                const base64WithoutHeader = parts[1];

                const extension = fileName.split('.').pop().toLowerCase();
                if (extension === 'docx' || extension === 'doc') {
                    base64Files.push({ data: `data:application/document;base64,${base64WithoutHeader}`, name: fileName });
                } else if (extension === 'xlsx' || extension === 'xls') {
                    base64Files.push({ data: `data:application/sheet;base64,${base64WithoutHeader}`, name: fileName });
                } else {
                    base64Files.push({ data: base64Data, name: fileName });
                }

                if (base64Files.length === files.length) {
                    // Now, base64Files contains both data and name for each file
                    setSelectedIncidentTaskFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allIncidentTask.filter(item =>
        item.task_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
    const endIndex = startIndex + config.ITEMS_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

    const onRowClickAction = (itemObj, btnAction) => {
        if (btnAction === 'edit') {

            setSelectedAssignedGroup("");
            setAssignTo("");

            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            setIncidentTaskId(itemObj.id);

            const initialFormValues = {
                task_name: itemObj.task_name,
                assigned_group: itemObj.assigned_group,
                assigned_to: itemObj.assigned_to,
                due_date: itemObj.due_date,
                is_completed: itemObj.is_completed,
                note: itemObj.note,
            };
            setFormValues(initialFormValues);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'assigned_group') && itemObj.assigned_group) {
                handleChangeAssignedGroup({ target: { value: itemObj.assigned_group } });
                setSelectedAssignedGroup(itemObj.assigned_group);
            }

            if (Object.prototype.hasOwnProperty.call(itemObj, 'assigned_to') && itemObj.assigned_to) {
                handleChangeAssignTo({ target: { value: itemObj.assigned_to } });
                setAssignTo(itemObj.assigned_to);
            }

            handleChangeApprovalMaster({ target: { value: itemObj.approval_master_id } });
            setSelectedApprovalMaster(itemObj.approval_master_id);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_completed') && itemObj.is_completed) {
                let approvalUsers = [];
                // Check if itemObj.approval_user is defined and not empty
                if (itemObj.approval_user !== undefined && itemObj.approval_user !== null) {
                    if (typeof itemObj.approval_user === 'string' && itemObj.approval_user.trim() !== '') {
                        try {
                            approvalUsers = typeof itemObj.approval_user === 'string'
                                ? JSON.parse(itemObj.approval_user)
                                : Array.isArray(itemObj.approval_user)
                                    ? itemObj.approval_user
                                    : [];
                        } catch (e) {
                            approvalUsers = [];
                        }
                    }
                }
                setSelectedApprovalMasterUser(approvalUsers);
            }

            handleChangeIsCompleted({ target: { value: itemObj.is_completed } });
            setSelectedIncidentTaskFiles([]);

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_completed') && itemObj.is_completed) {
                const isCompletedVal = itemObj.is_completed;

                if (isCompletedVal === 1 || isCompletedVal === "1") {
                    setIsCompleted(true);
                } else {
                    setIsCompleted(false);
                }
            }

            if (Object.prototype.hasOwnProperty.call(itemObj, 'is_start') && itemObj.is_start) {

                const isStartVal = itemObj.is_start;

                if (isStartVal === 1 || isStartVal === "1") {
                    setIsStart(true);
                } else {
                    setIsStart(false);
                }
            }
            setShowDiv(false);

            setPresentSequence(itemObj.present_sequence);

        } else if (btnAction === 'view') {
            // console.log("itemObj : ", itemObj);
            setViewModalInfo(itemObj);

            if (itemObj.incidentTaskActivity && Array.isArray(itemObj.incidentTaskActivity) && itemObj.incidentTaskActivity.length > 0) {
                setTaskActivity(itemObj.incidentTaskActivity);
                setTaskActivityCount(itemObj.incidentTaskActivity.length);
            } else {
                setTaskActivity([]);
                setTaskActivityCount(0);
            }
            setOpen(true);
        } else if (btnAction === 'delete') {

            setPreloaderStatus(true);

            const taskParams = {
                "task_id": itemObj.id,
                "incident_id": itemObj.incident_id
            };
            const requestData = encryptData(taskParams);
            const response = actions.incidentTaskDelete(requestData, navigate);

            response.then((result) => {
                if (result.data.success === 1) {
                    setPreloaderStatus(false);

                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setIncidentTaskId("");
                    fetchIncident();

                    reset();

                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    }

    const handleNewButton = () => {
        setFormValues({
            task_name: '',
            assigned_group: '',
            assigned_to: '',
            due_date: '',
            is_completed: '',
            note: '',
            upload_file: '',
            approval_master_id: '',
            approval_user: '',
        });
        setSelectedAssignedGroup('');
        setAssignTo('');
        setIsCompleted(false);
        setSelectedIncidentTaskFiles([]);

        setFieldsShow(false);
        setSelectedApprovalMaster('');
        setSelectedApprovalMasterUser([]);

        setIncidentTaskId("");

        reset();
        setIsStart(false);
        setShowDiv(false);
        setPresentSequence("");
    }

    const handleClose = () => {
        setOpen(false);
        setTaskActivity([]);
        setTaskActivityCount(0);
    };

    const toggleDiv = () => {
        setShowDiv(true);
    };

    const getLastSequenceValue = (data) => {
        if (data.length > 0) {
            const sequences = data.map(item => parseInt(item.sequence, 10));
            return Math.max(...sequences);
        }
        return null;
    };

    useEffect(() => {
        const lastSequenceValue = getLastSequenceValue(selectedApprovalMasterUser);
        const presentSequenceInt = parseInt(presentSequence, 10);

        if (!Number.isNaN(lastSequenceValue) && !Number.isNaN(presentSequenceInt) && lastSequenceValue === presentSequenceInt) {
            setIsLastSequence(true);
        } else {
            setIsLastSequence(false);
        }


        const populatedFormEntries = selectedApprovalMasterUser.map(entry => ({
            role: entry.role,
            users: [], // We'll populate users separately
            selectedUsers: entry.assign_to.map(user => ({
                key: user.key,
                value: user.value
            })),
            sequence: entry.sequence
        }));
        setFormEntries(populatedFormEntries);

        populatedFormEntries.forEach((entry, index) => {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": entry.role };
            const requestData = encryptData(obj);
            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => ({
                        id: item.user_id,
                        name: item.name
                    }));
                    const resultArr = Object.values(extractedData);

                    setFormEntries(prevFormEntries => {
                        const updatedFormEntries = [...prevFormEntries];
                        updatedFormEntries[index].users = resultArr;
                        return updatedFormEntries;
                    });
                }
            });
        });

    }, [selectedApprovalMasterUser, presentSequence, setIsLastSequence, setFormEntries]);

    const onChangeApprovalWorkflow = (itemObj, btnAction) => {
        // console.log("itemObj : ", itemObj);
        setWorkflowMaster(itemObj);

        const populatedFormEntries = itemObj.map(entry => ({
            role: entry.role,
            users: [], // We'll populate users separately
            selectedUsers: entry.assign_to.map(user => ({
                key: user.key,
                value: user.value
            })),
            sequence: entry.sequence
        }));
        setFormEntries(populatedFormEntries);

        populatedFormEntries.forEach((entry, index) => {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": entry.role };
            const requestData = encryptData(obj);
            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => ({
                        id: item.user_id,
                        name: item.name
                    }));
                    const resultArr = Object.values(extractedData);

                    setFormEntries(prevFormEntries => {
                        const updatedFormEntries = [...prevFormEntries];
                        updatedFormEntries[index].users = resultArr;
                        return updatedFormEntries;
                    });
                }
            });
        });

        setOpenWorkflowMaster(true);
    }

    const handleCloseApprovalWorkflow = () => {
        setWorkflowMaster([]);
        setOpenWorkflowMaster(false);
    }

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);

        const response = actions.rolesData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  rolesData:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const extractedData = results.map(item => {
                    return { id: item.role_id, name: item.role_name };
                });
                const resultArr = Object.values(extractedData);
                setAllRoles(resultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  rolesData:", error);
        });

    }, [setAllRoles]);

    const handleChangeRole = (index, event) => {
        const selectedValue = event.target.value;
        setSelectedRole(selectedValue);

        const updatedFormEntries = [...formEntries];
        updatedFormEntries[index].role = selectedValue;

        if (selectedValue !== "") {
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "role": selectedValue };
            const requestData = encryptData(obj);

            const response = actions.getUserByRole(requestData);
            response.then((result) => {
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const extractedData = results.map(item => {
                        return { id: item.user_id, name: item.name };
                    });
                    const resultArr = Object.values(extractedData);
                    updatedFormEntries[index].users = resultArr;
                    setFormEntries(updatedFormEntries);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        } else {
            updatedFormEntries[index].users = [];
            setFormEntries(updatedFormEntries);
        }
    };

    return (
        <>
            <div id="main-wrap" className='mt-4'>
                <div className='row justify-content-between'>
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-3'>Task</h5>
                    </div>
                </div>
                <div className="table-wrap">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Task Name</th>
                                <th>Due Date</th>
                                <th>Assigned Group</th>
                                <th>Assigned To</th>
                                <th>Pending Stage</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {paginatedItems.length === 0 ? (
                            <tbody>
                                <tr>
                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                        <h5>No Task</h5>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className={(item.dueDateOver ? "text-danger crif-usage-remark" : "text-dark crif-usage-remark")}>{item.task_name || item.approvalMasterTaskName}</td>
                                        <td className={(item.dueDateOver ? "text-danger" : "text-dark")}>{item.dueDate}</td>
                                        <td className={(item.dueDateOver ? "text-danger" : "text-dark")}>{item.assignedToGroup}</td>
                                        <td className={(item.dueDateOver ? "text-danger" : "text-dark")}>{item.assignedTo}</td>
                                        <td className={(item.dueDateOver ? "text-danger" : "text-dark")}>{item.roleName}</td>
                                        <td><span
                                            className={(item.is_completed === '1') ? "badge text-bg-success text-white" : "badge text-bg-warning text-white"}
                                        >{item.statusName}</span>
                                        </td>
                                        <td>
                                            <div className="btn-group dropstart">
                                                <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <RouterLink onClick={() => onRowClickAction(item, 'edit')} ><i className="bi bi-pen">&nbsp;</i>Edit</RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink onClick={() => onRowClickAction(item, 'view')} ><i className="bi bi-eye">&nbsp;</i>View</RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink onClick={() => onRowClickAction(item, 'delete')} ><i className="bi bi-trash">&nbsp;</i>Delete</RouterLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>

                <div>
                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>

            <div id="main-wrap" className='mt-4'>
                <div className='row justify-content-between'>

                    <div className="row">
                        <div className='col-md-9'>
                            <h5> {incidentTaskId === '' ? 'Add' : "Edit"} Task</h5>
                        </div>
                        {incidentTaskId !== '' && (
                            <div className='col-md-3'>
                                <div className='form-group text-end mb-2'>
                                    <button className="btn btn-primary" onClick={() => handleNewButton()}>Add New Task</button>
                                </div>
                            </div>
                        )}
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={formRef}>
                        <div className="row task" id="task">

                            <div className='col-md-5'>
                                <div className='form-group'>
                                    <label htmlFor="approval-master">
                                        <span>Task List<a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id='approval_master'
                                            name='approval_master'
                                            className="form-select"
                                            {...register('approval_master')}
                                            disabled={isStart || selectedAssignedGroup !== '' === true}
                                            value={selectedApprovalMaster}
                                            onChange={handleChangeApprovalMaster}
                                        >
                                            <option value=''>Select Task</option>
                                            {allApprovalMaster.map((option) => (
                                                <option value={option.id}>
                                                    {option.task_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-1'>
                                OR
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="task_name">
                                        <span>Task Name <a style={{ color: "red" }}>*</a></span>
                                        <input type="text" id="task_name" name="task_name" className="form-control"
                                            {...register('task_name')}
                                            value={formValues.task_name}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="due_date">
                                        <span>Due Date <a style={{ color: "red" }}>*</a></span>
                                        <input type="date" id="due_date" name="due_date" className="form-control"
                                            {...register('due_date')}
                                            value={formValues.due_date}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={`col-md-6 px-3 py-3 mb-md-0 mb-4 ${!fieldsShow ? 'd-none' : ''}`}>
                                <div className="row justify-content-between">
                                    <div className='col-md-2'>
                                        &nbsp;
                                    </div>

                                    <div className='col-md-6'>
                                        {selectedApprovalMasterUser.length > 0 ? (
                                            <>
                                                <ul className="approval-list">
                                                    {selectedApprovalMasterUser.map(user => (
                                                        <li key={user.sequence} className="approval-item">
                                                            <div className="approval-sequence">
                                                                <strong>Sequence:</strong> {user.sequence}
                                                            </div>
                                                            <div className="approval-assignees">
                                                                <strong>Role:</strong> {user.roleName}
                                                                <ul className="assignee-list">
                                                                    {user.assign_to.map(assignee => (
                                                                        <li key={assignee.key} className="assignee-item">
                                                                            {assignee.value}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <p>No approval users available.</p>
                                        )}
                                    </div>

                                    <div className='col-md-4 ps-lg-5'>
                                        <RouterLink onClick={() => onChangeApprovalWorkflow(selectedApprovalMasterUser, 'Edit')} className='custom-button'><i className="bi bi-pen">&nbsp;</i></RouterLink>
                                    </div>
                                </div>
                                

                            </div>


                            <div className={fieldsShow ? "d-none col-md-6" : "col-md-6"}>
                                <div className='form-group'>
                                    <label htmlFor="assigned-group">
                                        <span>Assigned Group <a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id='assigned_group'
                                            name='assigned_group'
                                            className="form-select"
                                            {...register('assigned_group')}
                                            value={selectedAssignedGroup}
                                            onChange={handleChangeAssignedGroup}
                                        >
                                            <option value=''>Select assigned group</option>
                                            {allGroupData.map((option) => (
                                                <option value={option.id}>
                                                    {option.group_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className={fieldsShow ? "d-none col-md-6" : "col-md-6"}>
                                <div className="form-group">
                                    <label htmlFor="assigned_to">
                                        <span>Assigned To <a style={{ color: "red" }}>*</a></span>
                                        <select id="assigned_to" name="assigned_to" className="form-select"  {...register('assigned_to')}
                                            value={assignTo}
                                            onChange={handleChangeAssignTo}>
                                            <option value="">Please Select</option>
                                            {getAllUsersListData.length > 0 && getAllUsersListData.map((option) => (
                                                <option value={option.user_id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Note</span>
                                        <textarea
                                            id="note"
                                            name='note'
                                            className="form-control"
                                            placeholder="Type Something"
                                            {...register('note')}
                                            value={formValues.note}
                                            onChange={handleChange}
                                        />
                                    </label> {errors.note && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label htmlFor="r_file">
                                        <span>File Upload</span>
                                        <input type='file' multiple id='report_file' name='report_file' className='form-control' onChange={handleFileChange} />
                                    </label>
                                </div>
                            </div>

                            <div className={(userRoleType === "Superadmin" && incidentTaskId !== '') || (isLastSequence && incidentTaskId !== '') || (selectedAssignedGroup !=="" && incidentTaskId !== '') ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className='form-group'>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="is_completedd">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="is_completedd"
                                                name="is_completedd"
                                                checked={isCompleted}
                                                onChange={handleChangeIsCompleted}
                                            />
                                            <span>Is Completed</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={showDiv ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className="form-group">
                                    <label htmlFor="sequence">
                                        <span>Send Back To <a style={{ color: "red" }}>*</a></span>
                                        <select
                                            id="sequence"
                                            name="sequence"
                                            className="form-select"
                                            {...register('sequence')}
                                            onChange={handleChangeSequence}
                                        >
                                            <option value="">Please Select</option>
                                            {selectedApprovalMasterUser
                                                .filter((option) => option.sequence === presentSequence || option.sequence < presentSequence)
                                                .map((option) => (
                                                    <option key={option.sequence} value={option.sequence}>
                                                        {option.roleName}
                                                    </option>
                                                ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='row m-3'>
                            <div className={incidentTaskId === ''  ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>

                            <div className={incidentTaskId !== '' && selectedAssignedGroup !== '' ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                        <div className={selectedAssignedGroup === '' ? 'row m-3' : "row m-3 d-none"}>
                            <div className={incidentTaskId !== '' ? 'col-md-6' : "col-md-6 d-none"}>
                                <div className="form-group mb-0">
                                    <button type="submit" name="action" value="proceed_next" className="btn btn-info">Proceed To Next Step</button>
                                </div>
                            </div>

                            <div className={incidentTaskId === '' || !isStart ? 'col-md-4 d-none' : "col-md-4"}>
                                <div className="form-group mb-0">
                                    <button className={!showDiv ? 'btn btn-primary' : "btn btn-primary d-none"} onClick={toggleDiv}>
                                        Send Back
                                    </button>

                                    <button type="submit" name="action" value="proceed_back" className={showDiv ? 'btn btn-danger' : "btn btn-danger d-none"} >Send Back</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Task Information</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Note:</b></div>
                            <div className='col-8 ps-0' style={{ whiteSpace: 'pre-line' }}>
                                {viewModalInfo.note}
                            </div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Files:</b></div>
                            <div className='col-8 ps-0'>
                                {viewModalInfo.upload_file_actual_name ? (
                                    <div className='row ps-0'>
                                        {viewModalInfo.upload_file.split(',').map((file, fileIndex) => {
                                            const trimmedFile = file.trim(); // Trim any leading or trailing whitespace
                                            const actualName = viewModalInfo.upload_file_actual_name.split(',')[fileIndex].trim();
                                            return (
                                                <div key={fileIndex}>
                                                    <div className="row mx-0 bg-white shadow-sm align-items-center p-2 rounded file-download mb-2">
                                                        <div className='col-10 px-0'>
                                                            <p className="mb-0 f-14px text-truncate w-auto">{actualName}</p>
                                                        </div>
                                                        <div className='col-2 down-2 text-end px-0'>
                                                            <a href={trimmedFile} target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
                                                                <i className="bi bi-download">&nbsp;</i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className='col-7 ps-0'>
                                        No File
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-2 pb-1'>
                            <div className='col-4'><b>Task Activity:</b></div>
                            <div className='col-8 ps-0 d-none' style={{ whiteSpace: 'pre-line' }}>
                                {taskActivity && taskActivity.map((item, index) => (
                                    <div key={index} id={index} className='lead-activity-item'>
                                        <div className='ps-0'>
                                            <p className="mb-1"><b>User By:</b> {item.createdBy}</p>
                                            <p className='mb-1'><b>Date:</b> {item.created_date}</p>
                                            <p className='mb-1'><b>Note:</b> {item.note}</p>
                                        </div>

                                        {taskActivity && index > 0 ? (
                                            index === taskActivityCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.statusName}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{taskActivity[index + 1]?.statusName}</strong> to <strong>{item.statusName}</strong></p>
                                            )

                                        ) : (
                                            index === taskActivityCount - 1 ? (
                                                <p className='mb-0 mt-2'>Status <strong>{item.statusName}</strong></p>
                                            ) : (
                                                <p className='mb-0 mt-2'>Changed Status from <strong>{taskActivity[index + 1]?.statusName}</strong> to <strong>{item.statusName}</strong></p>
                                            )
                                        )}

                                    </div>
                                ))}
                            </div>

                            <div className="col-8 ps-0">
                                <div className='history-tl-container comment-list ps-4 rounded'>
                                    <ul className='tl'>
                                        {taskActivity && taskActivity.length > 0 ? (
                                            taskActivity.map((item, index) => {
                                                return (
                                                    <li className='tl-item' key={index}>
                                                        <div className="item-title">Status:
                                                            <span className='text-success'>&nbsp;{item.statusName}</span>
                                                        </div>
                                                        {/* <div className="item-title">{item.createdByName}</div> */}
                                                        <div className="item-date">{item.created_date}</div>
                                                        <div className="item-user">{item.createdBy}</div>
                                                        <div style={{ whiteSpace: 'pre-line' }} className="item-detail">{item.note}</div>
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <p>Task activity not available</p>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                open={openWorkflowMaster}
                onClose={handleCloseApprovalWorkflow}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Approval Workflow</h4>
                        <Button onClick={handleCloseApprovalWorkflow} color="primary" className=' btn'>
                            {/* <i className="bi bi-x">&nbsp;</i> */}
                            <span>Save</span>
                        </Button>
                    </div>
                    <div className='modal-body' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className='row mb-2 pb-1'>
                            <div className='col-12 ps-1'>
                                {formEntries.map((entry, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label htmlFor={`role-${index}`}>
                                                    <span>Role</span>
                                                    <select
                                                        name={`role-${index}`}
                                                        className="form-select"
                                                        value={entry.role}
                                                        onChange={(e) => handleChangeRole(index, e)}
                                                    >
                                                        <option value="">Select Role</option>
                                                        {allRoles
                                                            .filter((option) => !formEntries.some((entry) => entry.role === option.id) || option.id === entry.role)
                                                            .map((option) => (
                                                                <option value={option.id} key={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='form-group'>
                                                <span>Assign to <a style={{ color: "red" }}>*</a></span>
                                                <div className="text-dark">
                                                    <Multiselect
                                                        id={`assign_to-${index}`}
                                                        options={entry.users?.map((option) => ({
                                                            key: option.id,
                                                            value: option.name,
                                                        })) || []}
                                                        selectedValues={entry.selectedUsers || []}
                                                        displayValue="value"
                                                        onSelect={(selectedItems) => {
                                                            const updatedFormEntries = [...formEntries];
                                                            updatedFormEntries[index].selectedUsers = selectedItems;
                                                            setFormEntries(updatedFormEntries);
                                                        }}
                                                        onRemove={(selectedItems) => {
                                                            const updatedFormEntries = [...formEntries];
                                                            updatedFormEntries[index].selectedUsers = selectedItems;
                                                            setFormEntries(updatedFormEntries);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-2 d-none'>
                                            <div className='form-group'>
                                                <label htmlFor={`sequence-${index}`}>
                                                    <span>Sequence</span>
                                                    <input
                                                        type='number'
                                                        name={`sequence-${index}`}
                                                        className='form-control'
                                                        value={entry.sequence}
                                                        onChange={(e) => handleChangeSequence(index, e)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {preloaderStatus && <Loader />}

        </>
    );
}

function mapStateToProps(state) {
    return {
        addEditCoApplicant: state.addEditCoApplicant,
        coApplicantData: state.coApplicantData,
        relationShipData: state.relationShipData,
        identifiersData: state.identifiersData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(incidentReporteActions.default, leadActions.default, moduleAction.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentTaskPage);
