import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as incidentReporteActions from "../../actions/incidentReporteActions";
import * as groupsUsersActions from "../../actions/groupsUsersActions";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const CreatIncidentGroupPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, groupInfo, checklistsData, sourceData, createIncidentGroup } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [groupId, setGroupId] = useState('');
  const [showIncidentGroupDetails, setShowIncidentGroupDetails] = useState(false);
  const [allSourceData, setAllSource] = useState([]);
  const [selectedAssignTo, setSelectedAssignTo] = useState([]);
  const [numbersArray, setNumbersArray] = useState([]);
  const isSelected = true;

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'User Group');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);


  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);

    if (usersData.success) {
      const sortedUsers = [...usersData.data].sort((a, b) => a.name.localeCompare(b.name));
      setAllUsers(sortedUsers);
     // setAllUsers(usersData.data);
    }
  }, []);

  useEffect(() => {
    setAllSource(false);
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.sourceData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  source data:", result); 
      if (result.data.success === 1) {
        setAllSource(true);
        const results = result.data.data.data;
        const resultArr = Object.keys(results).map((key) => results[key]);
        setAllSource(resultArr);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  source data :", error); 
    });

  }, [setAllSource]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowIncidentGroupDetails(false);
    if (id !== undefined && !showIncidentGroupDetails) {
      const decodedId = atob(id);
      setGroupId(decodedId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'id': decodedId };
      const requestData = encryptData(obj);
      // actions.groupInfo(requestData);
      // console.log(groupInfo);
      const response = actions.groupData(requestData);
      response.then((result) => {
        console.log("Promise resolved -  groupData list:", result);
        if (result.data.data.success === 1) {
            setShowIncidentGroupDetails(true);            
            const groupData = result.data.data.data;
            // console.log("groupData = ", groupData);            
            setValue("group_name", groupData[0].group_name);          
            setValue("status", groupData[0].status);

            const assignToString = groupData[0].users;
            const assignToArray = assignToString.split(',');
            setSelectedAssignTo(assignToArray);

            setNumbersArray(groupData[0].selected_assigned_user);
          }
      }).catch((error) => {
          console.error("Promise rejected -  lead list:", error);
      });

    }      
    
  }, [id, setValue, setShowIncidentGroupDetails,setGroupId]);

  const onSubmit = (data) => {
    const assignTo = JSON.stringify(selectedAssignTo);

    let params = { 'group_name': data.group_name, "status": data.status, "assigned_user": assignTo };

    if (groupId !== '') {
      params = { 'group_name': data.group_name, "status": data.status, "assigned_user": assignTo, 'id': groupId };
    }

    // console.log(params);
    const requestData = encryptData(params);
    const response = actions.createIncidentGroup(requestData, navigate);
    response.then((result) => { 
        if (result.data.success === 1) {
            toast.success(result.data.message, {
                position: "top-right",
            });
            setTimeout(() => {
                navigate(`/dashboard/incident-report-user-group`);
            }, 1000);
        } else {
            toast.error(result.data.message, {
                position: "top-right",
            });
        }
    }).catch((error) => {
        toast.error(error, {
            position: "top-right",
        });
    });
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{showIncidentGroupDetails ? "Edit User Group" : "Create User Group"}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showIncidentGroupDetails ? "Edit User Group" : "Create User Group"}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor="group_name">
                    <span>Group Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' id='group_name' className='form-control' {...register('group_name', { required: true })} />
                  </label>
                  {errors.group_name && <span className="required">This field is required.</span>}
                </div>
              </div>
             

              <div className='col-md-6'>
                <div className='form-group'>
                  <span>Assign To</span>
                  <div className="text-dark">
                    <Multiselect
                      id="assign_to"
                      options={allUsers.map((option) => ({
                        key: option.user_id,
                        value: option.name,
                      }))}
                      selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                      displayValue="value"
                      onSelect={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedAssignTo(selectedValues);
                      }}
                      onRemove={(selectedItems) => {
                        const selectedValues = selectedItems.map(item => item.key);
                        setSelectedAssignTo(selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>User Group Status <a style={{ color: "red" }}>*</a></span>
                    <select name='status' className="form-select" {...register('status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.status && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    groupInfo: state.groupInfo,
    sourceData: state.sourceData,
    getUserModuleData: state.getUserModuleData,
    createIncidentGroup:state.createIncidentGroup,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, incidentReporteActions.default, groupsUsersActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatIncidentGroupPage);