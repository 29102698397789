import React from "react";
import ReactDOM from 'react-dom/client';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import storeConfig from "./store/index"; 
import { PermissionProvider } from './contexts/PermissionContext';

const { store, persistor } = storeConfig;
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
       <PermissionProvider>
         <App />
        </PermissionProvider>
      </PersistGate>
    </Provider>
  );

// If you want to enable client cache, register instead.
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
