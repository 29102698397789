import { useState, useEffect } from 'react';
import * as React from 'react';
 
const boldTextStyle = {
    fontWeight: 'bold',
  };

  const customStyles = {
    fontWeight: 'bold',
    backgroundColor: '#34aec5',
    // Add more styles as needed
  };

  const customStylesBlue = {
    fontWeight: 'bold',
    color: 'blue',
    // Add more styles as needed
  };

  const customStyles2 = {
    fontWeight: 'bold',
    backgroundColor: '#afd5e9',
    // Add more styles as needed
  };
const PdfDetails = (props) => { 
    
    return (
        <div>
            
            <div className="row" id="main-wrap">
  <h1>
    Crif Report
  </h1>
  <br/>
  <div className="table-wrap">
    <table width="900" border="0">
      <tr>
        <td colSpan="4" style={customStyles}>
          Inquiry Input Information
        </td>
      </tr>
      <tr>
        <td width="150" style={boldTextStyle}>
          Name
        </td>
        <td width="150">
          GANNOJU GOPALACHARI
        </td>
       
        <td width="150" style={boldTextStyle}>
          DOB/Age
        </td>
        <td width="150">
          27-06-1974
        </td>
        </tr>
      <tr>  
        <td width="10%" style={boldTextStyle}>
          Phone Number
        </td>
        <td width="150">
          6657862214
        </td>
      
        <td width="150" style={boldTextStyle}>
          E-Mail
        </td>
        <td width="150">
          gannoju@gmail.com
        </td>
        </tr>
      <tr>  
        <td  width="150" style={boldTextStyle}>
          Id
        </td>
        <td  width="150">
          OOEPB8484R[PAN]
        </td>
      
        <td width="150" style={boldTextStyle}>
          Address
        </td>
        <td  width="150">
          2 30 PEDDAPALLY TELKAPALLY NAGARKURNOOL MAHBUBNAGAR MAHABUB NAGAR 509385
          MAHBUBNAGAR 509385 TS
        </td>
      </tr>
    </table>
  </div>
  </div>
        </div> 
    );
}
 

export default PdfDetails;