import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function leadProfileInfoReducer(state = initialState.leadProfileInfo, action) {
  switch (action.type) {
    case types.LOAD_LEAD_PROFILE_DATA_SUCCESS:
      return action.leadProfileInfo;
    default:
      return state;
  }
}
