import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import * as userActions from "../../actions/userActions";
import AccessDenied from '../common/AccessDenied';

import * as leadActions from "../../actions/leadActions";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const UploadDocument = (props) => {
    const { leadId, id } = useParams();
    const location = useLocation();
    const { actions, usersData, fetchLeadIdDocument, allLeadDocumentByLeadData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [allUsers, setAllUsers] = useState([]);
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
    const [getLeadId, setLeadId] = useState('');
    const [allUploadLeadDocument, setAllUploadLeadDocument] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const formRef = useRef(null);
    const [propLeadId, setPropLeadId] = useState('');
    const [allDocumentTypeData, setAllDocumentTypeData] = useState([]);
    const [open, setOpen] = useState(false);
    const [viewModalInfo, setViewModalInfo] = useState([]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        if (id !== undefined) {
            setPropLeadId(atob(id));
            setLeadId(propLeadId);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": atob(id) };
            // console.log("obj lead id- ", obj);
            const requestData = encryptData(obj);
            const response = actions.getUploadLeadDocument(requestData);

            response.then((result) => {
                // console.log("Promise resolved -  source data:", result); 
                if (result.data.success === 1) {
                    const results = result.data.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllUploadLeadDocument(resultArr);
                }
            }).catch((error) => {
                //  console.error("Promise rejected -  source data :", error); 
            });
        }

    }, [setAllUploadLeadDocument]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.masterDocumentTypeData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data
                const filteredResultArr = results.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.document_type.localeCompare(b.document_type)
                );
                setAllDocumentTypeData(sortedResultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

    }, [setAllDocumentTypeData]);

    const handleDocumentFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedDocumentFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const onSubmit = (data) => {

        setPropLeadId(atob(id));
        setLeadId(propLeadId);

        if (data.document_type.trim() === "") {
            toast.error("No document type has been selected.", {
                position: "top-right",
            });
        } else if (!selectedDocumentFiles || selectedDocumentFiles.length === 0) {
            toast.error("No file has been selected.", {
                position: "top-right",
            });
        } else if (data.document_note.trim() === "") {
            toast.error("Note is blank.", {
                position: "top-right",
            });
        } else {

            const params = { "lead_id": propLeadId, "document_type": data.document_type, "document_note": data.document_note, "document_file": selectedDocumentFiles };

            const requestData = encryptData(params);
            const response = actions.addEditLeadDocument(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - addEditLeadDocument form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });

                    const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": propLeadId };
                    // console.log("obj lead id- ", obj);
                    const requestData = encryptData(obj);
                    const response = actions.getUploadLeadDocument(requestData);

                    response.then((result) => {
                        // console.log("Promise resolved -  source data:", result); 
                        if (result.data.success === 1) {
                            const results = result.data.data.data;
                            const resultArr = Object.keys(results).map((key) => results[key]);
                            setAllUploadLeadDocument(resultArr);
                        }
                    }).catch((error) => {
                        //  console.error("Promise rejected -  source data :", error); 
                    });

                    reset();
                    setSelectedDocumentFiles("");
                    props.updateParentState(false);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredItems = (allUploadLeadDocument ?? []).filter(item =>
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.assignedUser.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
    const endIndex = startIndex + config.ITEMS_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

    const onRowClickAction = (itemObj, btnAction) => {
        setViewModalInfo(itemObj);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <h5 className="fw-bold">Upload Document</h5>

            <div id="main-wrap" className='mt-4'>
                <div className="table-wrap">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Document ID</th>
                                <th>Created Date</th>
                                <th>Document Type</th>
                                <th>View Note</th>
                                <th>View Document</th>
                            </tr>
                        </thead>

                        {paginatedItems.length === 0 ? (
                            <tbody>
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                        <h5>No Document</h5>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.formatetdID}</td>
                                        <td>{item.created_date}</td>
                                        <td>{item.documentTypeName}</td>
                                        <td>
                                            <RouterLink onClick={() => onRowClickAction(item)}><i className="bi bi-eye"/></RouterLink>
                                        </td>
                                        <td>
                                            <a target="new" href={item.document} className='text-decoration-none'>
                                                <i className="bi bi-eye"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>

                <div>
                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>

            <div id="main-wrap" className='mt-4'>
                <div className='row justify-content-between'>
                    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={formRef}>
                        <div className="row guarantor" id="bank_statement-1">

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="document-type">
                                        <span>Document Type <a style={{ color: "red" }}>*</a></span>
                                        <select disabled={props.leadStatusFlag === true} id='document_type' className="form-select" name='document_type' {...register('document_type')}>
                                            <option value="">Select Document Type</option>
                                            {allDocumentTypeData.length > 0 && allDocumentTypeData.map((option) => (
                                                <option value={option.id}>
                                                    {option.document_type}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="document_file">
                                        <span>File Upload <a style={{ color: "red" }}>*</a></span>
                                        <input disabled={props.leadStatusFlag === true} type='file' id='document_file' name='document_file' className='form-control' onChange={handleDocumentFileChange} />
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="document_note">
                                        <span>Notes <a style={{ color: "red" }}>*</a></span>
                                        <textarea readOnly={props.leadStatusFlag === true}
                                            id='document_note'
                                            className='form-control'
                                            placeholder="Type Something" {...register('document_note')}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-0">
                                    <button disabled={props.leadStatusFlag === true} className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Lead Document</h4>
                    </div>

                    <div className='modal-body'>
                        <div className='row mb-2 pb-1'>
                            <div className='col-1'>&nbsp;</div>
                            <div className='col-10 ps-0'>{viewModalInfo.note}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        getUserModuleData: state.getUserModuleData,
        getUploadLeadDocument: state.getUploadLeadDocument,
        masterDocumentTypeData: state.masterDocumentTypeData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
