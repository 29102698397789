
import react, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Typography } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";
import * as masterAction from "../../actions/masterAction";
import * as reportActions from "../../actions/reportActions";


const LogsVersioning = (props) => {
  const { vitem, actions } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [allEMIsArr, setAllEMIsArr] = useState([]);
  const [allModifiedEMIsArr, setAllModifiedEMIsArr] = useState([]);

  const [activeTabName, setActiveTabName] = useState('schedule');
  const [loanDetailsArr, setLoanDetailsArr] = useState([]);
  const [loanDetailsModifiedArr, setLoanDetailsModifiedArr] = useState([]);
  const [additionalMissingData, setAdditionalMissingData] = useState([]);
  
  const [ preloaderStatus, setPreloaderStatus] = useState(false);
  const [allLogsArr, setAllLogsArr] = useState([]);

  const [formValues, setFormValues] = useState({
    priority_type: '',
    type_topic: '',
    residence_status: '',
    profile: '',
    msme: '',
    stability: '',
    sector_of_unit: '',
    sub_sector: '',
    business_of_occupation: '',
    turnover: '',
    dl_number: '',
    dl_status: '',
    category: '',
    region: '',
    city_stability: '',
    residence_stability: '',
    business_stability: '',
    driving_experience: '',
    product: '',
    relation_with_applicant: '',
    battery_number: '',
    deployment_of_asset: '',
    warranty_detail: '',
  });

  // Handler for EMIs Schedule tab
  const backToScheduleHandler = () => {
    setActiveTabName('schedule');
  };

  // Handler for Additional Data tab
  const backToAdditionalHandler = () => {
    setActiveTabName('additional');
  };

  useEffect(() => {
    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'RepaymentSchedules')) {
      setAllEMIsArr(vitem.RepaymentSchedules);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'transaction_scheduling_logs')) {
      setAllLogsArr(vitem.transaction_scheduling_logs);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'comparedEmisDetails')) {

      const comparedEmisDetails = vitem.comparedEmisDetails;
      if (comparedEmisDetails && Object.prototype.hasOwnProperty.call(comparedEmisDetails, 'modifiedJSONInfo')) {
        setAllModifiedEMIsArr(comparedEmisDetails.modifiedJSONInfo);

        console.log("comparedEmisDetails.modifiedJSONInfo = ", comparedEmisDetails.modifiedJSONInfo);
      }
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'loanDetails')) {
      setLoanDetailsArr(vitem.loanDetails);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'compareLoanDetails')) {
      const compareLoanDetails = vitem.compareLoanDetails;
      setLoanDetailsModifiedArr(compareLoanDetails);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'additionalMissingData')) {
      setAdditionalMissingData(vitem.additionalMissingData);
    }


  }, [setAllEMIsArr, setAllModifiedEMIsArr, setLoanDetailsArr, setLoanDetailsModifiedArr, setAdditionalMissingData,setAllLogsArr]);

  const formatDate = (date) => {
    if (date === null || date === '' || date === undefined) {
      return '';
    }

    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
  };

  const moneyFormatIndia = (number) => {
    if (number !== undefined && number !== '' && !Number.isNaN(Number(number))) {
      number = parseFloat(number);

      number = number.toFixed(2);

      const [integerPart, decimalPart] = number.split('.');

      const lastThreeDigits = integerPart.slice(-3);
      const otherDigits = integerPart.slice(0, -3);

      let formattedIntegerPart;
      if (otherDigits !== '') {
        formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
      } else {
        formattedIntegerPart = lastThreeDigits;
      }

      return `${formattedIntegerPart}.${decimalPart}`;
    }

    return '0.00';
  };

  const formatNumbersInString = (numberString) => {
    // Split the string into an array of number strings
    const numberArray = numberString.split(',').map(num => num.trim());

    // Format each number and join them back into a string
    const formattedNumbers = numberArray.map(num => moneyFormatIndia(num));

    // Join the formatted numbers into a single string
    return formattedNumbers.join(', ');
  };

  const formatDate2 = (dateTime) => {
    if (!dateTime) {
        return "";
    }
    const dateParts = dateTime.split(' ');
    return dateParts.length > 0 ? dateParts[0] : "";
  };

  const [allProfileMaster, setAllProfileMaster] = useState([]);
  const [allSectorOfUnitMaster, setAllSectorOfUnitMaster] = useState([]);
  const [allDlStatusMaster, setAllDlStatusMaster] = useState([]);
  const [allCategoryMaster, setAllCategoryMaster] = useState([]);
  const [allRegionMaster, setAllRegionMaster] = useState([]);
  const [allProductMaster, setAllProductMaster] = useState([]);
  const [allResidenceStatusMaster, setAllResidenceStatusMaster] = useState([]);

  

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
        ...formValues,
        [name]: value,
    });

  };
  useEffect(() => {

    if(additionalMissingData){

      setValue("residence_status", additionalMissingData.residence_status);
      setValue("priority_type", additionalMissingData.priority_type);
      setValue("type_topic", additionalMissingData.type_topic);
      setValue("profile", additionalMissingData.profile);
      setValue("msme", additionalMissingData.msme);
      setValue("stability", additionalMissingData.stability);
      setValue("sector_of_unit", additionalMissingData.sector_of_unit);
      setValue("sub_sector", additionalMissingData.sub_sector);
      setValue("business_of_occupation", additionalMissingData.business_of_occupation);
      setValue("turnover", additionalMissingData.turnover);
      setValue("dl_number", additionalMissingData.dl_number);
      setValue("dl_status", additionalMissingData.dl_status);
      setValue("category", additionalMissingData.category);
      setValue("region", additionalMissingData.region);
      setValue("city_stability", additionalMissingData.city_stability);
      setValue("business_stability", additionalMissingData.business_stability);
      setValue("driving_experience", additionalMissingData.driving_experience);
      setValue("product", additionalMissingData.product);
      setValue("relation_with_applicant", additionalMissingData.relation_with_applicant);
      setValue("battery_number", additionalMissingData.battery_number);
      setValue("deployment_of_asset", additionalMissingData.deployment_of_asset);
      setValue("warranty_detail", additionalMissingData.warranty_detail);
      setValue("residence_stability", additionalMissingData.residence_stability);

      setValue("insurance_validity_date", formatDate2(additionalMissingData.insurance_validity_date));
      setValue("sanction_date", formatDate2(additionalMissingData.sanction_date));

      const initialFormValues = {
        priority_type: additionalMissingData.priority_type,
        type_topic: additionalMissingData.type_topic,
        residence_status: additionalMissingData.residence_status,
        profile: additionalMissingData.profile,
        msme: additionalMissingData.msme,
        insurance_validity_date: additionalMissingData.insurance_validity_date,
        sanction_date: additionalMissingData.sanction_date,
        stability: additionalMissingData.stability,
        sector_of_unit: additionalMissingData.sector_of_unit,
        sub_sector: additionalMissingData.sub_sector,
        business_of_occupation: additionalMissingData.business_of_occupation,
        turnover: additionalMissingData.turnover,
        dl_number: additionalMissingData.dl_number,
        dl_status: additionalMissingData.dl_status,
        category: additionalMissingData.category,
        region: additionalMissingData.region,
        city_stability: additionalMissingData.city_stability,
        residence_stability: additionalMissingData.residence_stability,
        business_stability: additionalMissingData.business_stability,
        driving_experience: additionalMissingData.driving_experience,
        product: additionalMissingData.product,
        relation_with_applicant: additionalMissingData.relation_with_applicant,
        battery_number: additionalMissingData.battery_number,
        deployment_of_asset: additionalMissingData.deployment_of_asset,
        warranty_detail: additionalMissingData.warranty_detail,
      };
   
    setFormValues(initialFormValues);
    }
  }, [setValue, additionalMissingData]);

  const onSubmit = (data) => {

    const params = {
      'agreementNo': vitem.AgreementNo,
      "priority_type": formValues.priority_type,
      "type_topic": formValues.type_topic,
      "residence_status": formValues.residence_status,
      "profile": formValues.profile,
      "msme": formValues.msme,
      "insurance_validity_date": data.insurance_validity_date,
      "sanction_date": data.sanction_date,
      "stability": formValues.stability,
      "sector_of_unit": formValues.sector_of_unit,
      "sub_sector": formValues.sub_sector,
      "business_of_occupation": formValues.business_of_occupation,
      "turnover": formValues.turnover,
      "dl_number": formValues.dl_number,
      "dl_status": formValues.dl_status,
      "category": formValues.category,
      "region": formValues.region,
      "city_stability": formValues.city_stability,
      "residence_stability": formValues.residence_stability,
      "business_stability": formValues.business_stability,
      "driving_experience": formValues.driving_experience,
      "product": formValues.product,
      "relation_with_applicant": formValues.relation_with_applicant,
      "battery_number": formValues.battery_number,
      "deployment_of_asset": formValues.deployment_of_asset,
      "warranty_detail": formValues.warranty_detail
    }

    const requestData = encryptData(params);
    const response = actions.addEditAdditionalMissingData(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        setPreloaderStatus(false);
        toast.success(result.data.message, {
          position: "top-right",
        });
      } else {
        toast.error(result.data.message, {
          position: "top-right",
        });
      }
    }).catch((error) => {
      setPreloaderStatus(false);
      console.error("Promise rejected -  lead list:", error);
    });
  }

  return (
    <>

      <div className="mt-4 portlet light d-none">
        <h6 className="  portlet-title">Loan Details</h6>
        <div className="row row mx-0">
          <div className="col-md-4">
            <div className="">
              <div><b>FinanceId : </b></div>
              {loanDetailsArr && loanDetailsArr.FinanceId !== undefined ? (
                <>{loanDetailsArr.FinanceId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>CompanyRoleId : </b></div>
              {loanDetailsArr && loanDetailsArr.CompanyRoleId !== undefined ? (
                <>{loanDetailsArr.CompanyRoleId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanType : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanType !== undefined ? (
                <>{loanDetailsArr.LoanType}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>AssetId : </b></div>
              {loanDetailsArr && loanDetailsArr.AssetId !== undefined ? (
                <>{loanDetailsArr.AssetId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>TotalAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalAmount !== undefined ? (
                <>{loanDetailsArr.TotalAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>Duration : </b></div>
              {loanDetailsArr && loanDetailsArr.Duration !== undefined ? (
                <>{loanDetailsArr.Duration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>Interest : </b></div>
              {loanDetailsArr && loanDetailsArr.Interest !== undefined ? (
                <>{loanDetailsArr.Interest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>EMI : </b></div>
              {loanDetailsArr && loanDetailsArr.EMI !== undefined ? (
                <>{loanDetailsArr.EMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>YearlyInterest : </b></div>
              {loanDetailsArr && loanDetailsArr.YearlyInterest !== undefined ? (
                <>{loanDetailsArr.YearlyInterest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>StartDate : </b></div>
              {loanDetailsArr && loanDetailsArr.StartDate !== undefined ? (
                <>{loanDetailsArr.StartDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanClosureDate : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanClosureDate !== undefined ? (
                <>{loanDetailsArr.LoanClosureDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>DPDDays : </b></div>
              {loanDetailsArr && loanDetailsArr.DPDDays !== undefined ? (
                <>{loanDetailsArr.DPDDays}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>AgreementNo : </b></div>
              {loanDetailsArr && loanDetailsArr.AgreementNo !== undefined ? (
                <>{loanDetailsArr.AgreementNo}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>FutureValue : </b></div>
              {loanDetailsArr && loanDetailsArr.FutureValue !== undefined ? (
                <>{loanDetailsArr.FutureValue}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InsuranceAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.InsuranceAmount !== undefined ? (
                <>{loanDetailsArr.InsuranceAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InsuranceDuration : </b></div>
              {loanDetailsArr && loanDetailsArr.InsuranceDuration !== undefined ? (
                <>{loanDetailsArr.InsuranceDuration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>IndicativeROI : </b></div>
              {loanDetailsArr && loanDetailsArr.IndicativeROI !== undefined ? (
                <>{loanDetailsArr.IndicativeROI}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SendSMS : </b></div>
              {loanDetailsArr && loanDetailsArr.SendSMS !== undefined ? (
                <>{loanDetailsArr.SendSMS}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SchemeId : </b></div>
              {loanDetailsArr && loanDetailsArr.SchemeId !== undefined ? (
                <>{loanDetailsArr.SchemeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>EMITypeId : </b></div>
              {loanDetailsArr && loanDetailsArr.EMITypeId !== undefined ? (
                <>{loanDetailsArr.EMITypeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCAmount !== undefined ? (
                <>{loanDetailsArr.LPCAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCType : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCType !== undefined ? (
                <>{loanDetailsArr.LPCType}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCInterest : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCInterest !== undefined ? (
                <>{loanDetailsArr.LPCInterest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InstallmentTypeId : </b></div>
              {loanDetailsArr && loanDetailsArr.InstallmentTypeId !== undefined ? (
                <>{loanDetailsArr.InstallmentTypeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>ROIDuration : </b></div>
              {loanDetailsArr && loanDetailsArr.ROIDuration !== undefined ? (
                <>{loanDetailsArr.ROIDuration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SubLoanTypeForPL : </b></div>
              {loanDetailsArr && loanDetailsArr.SubLoanTypeForPL !== undefined ? (
                <>{loanDetailsArr.SubLoanTypeForPL}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanCategory : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanCategory !== undefined ? (
                <>{loanDetailsArr.LoanCategory}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>DownPayment : </b></div>
              {loanDetailsArr && loanDetailsArr.DownPayment !== undefined ? (
                <>{loanDetailsArr.DownPayment}</>
              ) : (
                <></>
              )}

            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>AdvanceEMI : </b></div>
              {loanDetailsArr && loanDetailsArr.AdvanceEMI !== undefined ? (
                <>{loanDetailsArr.AdvanceEMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>LoanSegmentId : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanSegmentId !== undefined ? (
                <>{loanDetailsArr.LoanSegmentId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>UTRNo : </b></div>
              {loanDetailsArr && loanDetailsArr.UTRNo !== undefined ? (
                <>{loanDetailsArr.UTRNo}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>DisbursementStatus : </b></div>
              {loanDetailsArr && loanDetailsArr.DisbursementStatus !== undefined ? (
                <>{loanDetailsArr.DisbursementStatus}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NachStatusId : </b></div>
              {loanDetailsArr && loanDetailsArr.NachStatusId !== undefined ? (
                <>{loanDetailsArr.NachStatusId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>UMRNNumber : </b></div>
              {loanDetailsArr && loanDetailsArr.UMRNNumber !== undefined ? (
                <>{loanDetailsArr.UMRNNumber}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>StatusId : </b></div>
              {loanDetailsArr && loanDetailsArr.StatusId !== undefined ? (
                <>{loanDetailsArr.StatusId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIStartDate : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIStartDate !== undefined ? (
                <>{loanDetailsArr.EMIStartDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIEndDate : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIEndDate !== undefined ? (
                <>{loanDetailsArr.EMIEndDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NextPaymentDate : </b></div>
              {loanDetailsArr && loanDetailsArr.NextPaymentDate !== undefined ? (
                <>{loanDetailsArr.NextPaymentDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NextEMIDueDate : </b></div>
              {loanDetailsArr && loanDetailsArr.NextEMIDueDate !== undefined ? (
                <>{loanDetailsArr.NextEMIDueDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>ModeOfRePaymentId : </b></div>
              {loanDetailsArr && loanDetailsArr.ModeOfRePaymentId !== undefined ? (
                <>{loanDetailsArr.ModeOfRePaymentId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NoOfPaidEMI : </b></div>
              {loanDetailsArr && loanDetailsArr.NoOfPaidEMI !== undefined ? (
                <>{loanDetailsArr.NoOfPaidEMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalReceivedAmt : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalReceivedAmt !== undefined ? (
                <>{loanDetailsArr.TotalReceivedAmt}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalPrincipleRecvd : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalPrincipleRecvd !== undefined ? (
                <>{loanDetailsArr.TotalPrincipleRecvd}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalInterestPaid : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalInterestPaid !== undefined ? (
                <>{loanDetailsArr.TotalInterestPaid}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIDueCount : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIDueCount !== undefined ? (
                <>{loanDetailsArr.EMIDueCount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalPrincipalDue : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalPrincipalDue !== undefined ? (
                <>{loanDetailsArr.TotalPrincipalDue}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalInterestDue : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalInterestDue !== undefined ? (
                <>{loanDetailsArr.TotalInterestDue}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalVASDues : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalVASDues !== undefined ? (
                <>{loanDetailsArr.TotalVASDues}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalDueAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalDueAmount !== undefined ? (
                <>{loanDetailsArr.TotalDueAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>LPIDues : </b></div>
              {loanDetailsArr && loanDetailsArr.LPIDues !== undefined ? (
                <>{loanDetailsArr.LPIDues}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>DefaultChrages : </b></div>
              {loanDetailsArr && loanDetailsArr.DefaultChrages !== undefined ? (
                <>{loanDetailsArr.DefaultChrages}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalEMIOverdueAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalEMIOverdueAmount !== undefined ? (
                <>{loanDetailsArr.TotalEMIOverdueAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalLoanOverdueAmount : </b></div>

              {/* JavaScript logic */}
              {(() => {
                let totalLoanOverdueAmountUpdatedStatus = false;
                let totalLoanOverdueAmountDeletedStatus = false;
                let totalLoanOverdueAmountObj = {};

                if (loanDetailsModifiedArr && Object.prototype.hasOwnProperty.call(loanDetailsModifiedArr, 'TotalLoanOverdueAmount')) {
                  totalLoanOverdueAmountObj = loanDetailsModifiedArr.TotalLoanOverdueAmount;

                  if (totalLoanOverdueAmountObj && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'old') && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'new')) {
                    totalLoanOverdueAmountUpdatedStatus = true;
                  }

                  if (totalLoanOverdueAmountObj && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'delete')) {
                    totalLoanOverdueAmountDeletedStatus = totalLoanOverdueAmountObj.delete;
                  }
                }

                return (
                  <>
                    {!totalLoanOverdueAmountDeletedStatus && totalLoanOverdueAmountUpdatedStatus ? (
                      <>
                        <div className="text-danger">Old:{(loanDetailsModifiedArr.old !== null && loanDetailsModifiedArr.old !== '') ? moneyFormatIndia(loanDetailsModifiedArr.old) : ''}</div>
                        <div className="text-success">New:{(loanDetailsModifiedArr.new !== null && loanDetailsModifiedArr.new !== '') ? moneyFormatIndia(loanDetailsModifiedArr.new) : ''}</div>
                      </>
                    ) : (loanDetailsArr && loanDetailsArr.TotalLoanOverdueAmount !== undefined ? (
                      <>{loanDetailsArr.TotalLoanOverdueAmount}</>
                    ) : (
                      <><div className='deletedRow'>Deleted</div></>
                    ))}
                  </>
                );
              })()}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>loanTotalDue : </b></div>

              {/* JavaScript logic */}
              {(() => {
                let loanTotalDueUpdatedStatus = false;
                let loanTotalDueDeletedStatus = false;
                let loanTotalDueObj = {};

                // console.log("loanDetailsModifiedArr : ", loanDetailsModifiedArr);

                if (loanDetailsModifiedArr && Object.prototype.hasOwnProperty.call(loanDetailsModifiedArr, 'LoanTotalDue')) {
                  loanTotalDueObj = loanDetailsModifiedArr.LoanTotalDue;

                  if (loanTotalDueObj && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'old') && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'new')) {
                    loanTotalDueUpdatedStatus = true;
                  }

                  if (loanTotalDueObj && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'delete')) {
                    loanTotalDueDeletedStatus = loanTotalDueObj.delete;
                  }
                }

                return (
                  <>
                    {loanTotalDueDeletedStatus ? (
                      <div className='deletedRow'>Deleted</div>
                    ) : loanTotalDueUpdatedStatus ? (
                      <>
                        <div className="text-danger">Old:{(loanDetailsModifiedArr.LoanTotalDue.old !== null && loanDetailsModifiedArr.LoanTotalDue.old !== '') ? moneyFormatIndia(loanDetailsModifiedArr.LoanTotalDue.old) : ''}</div>
                        <div className="text-success">New:{(loanDetailsModifiedArr.LoanTotalDue.new !== null && loanDetailsModifiedArr.LoanTotalDue.new !== '') ? moneyFormatIndia(loanDetailsModifiedArr.LoanTotalDue.new) : ''}</div>
                      </>
                    ) : (
                      <>{(loanDetailsArr.loanTotalDue !== null && loanDetailsArr.loanTotalDue !== '') ? moneyFormatIndia(loanDetailsArr.loanTotalDue) : ''}</>
                    )}
                  </>
                );
              })()}
            </div>
          </div>

        </div>
      </div>
      
      <div className="tab-content" id="pills-tabContent">
        <ul className="nav nav-pills mb-" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={activeTabName === 'schedule' ? "nav-link active" : "nav-link"}
              id="pills-schedule-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-schedule"
              type="button"
              role="tab"
              aria-controls="pills-schedule"
              aria-selected={activeTabName === 'schedule' ? "true" : "false"}
              onClick={backToScheduleHandler}
            >
              Logs
            </button>
          </li>
          
        </ul>

        {/* Tab Content */}
        <div className={activeTabName === 'schedule' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-schedule" role="tabpanel">
          <div className=" portlet light">
            <div className='table-wrap table-responsive'>
              <table className="table table-hover " id="exportLoansReportExcelFile" width="100%">
                <thead>
                  <tr>
                    <th className="text-nowrap">No</th>
                    <th className="text-nowrap">Posting Date</th>
                    <th className="text-nowrap">ACH Type</th>
                    <th className="text-nowrap">Reference Id</th>
                    <th className="text-nowrap align-right">Amount</th>
                    <th className="text-nowrap">Schedling Status</th>
                    <th className="text-right">Scheduling Successful</th>
                    <th className="text-right">Final Status</th>
                    <th className="text-right">Created By</th>

                  </tr>
                </thead>
                <tbody>
                  {allLogsArr.map((item, index) => {
 
                    return (
                      <>
                        
                        <tr key={index}>
                          <td>{(index +1)}</td>
                          <td>{formatDate(item.created_on)}</td>
                          <td>eNACH</td> 
                          <td>{item.transaction_identifier}</td>
                          <td className='align-right'>{moneyFormatIndia( item.amount)}</td>
                          <td>{item.TransactionSchedulingStatus}</td>
                          <td>{item.verificationStatus === 'S' ? 'Success' : (item.verificationStatus === 'I') ? "Intiated" : 'Failed'} </td> 
                          <td>{item.errorMessage}</td>
                          <td>{item.createdByName}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        
      </div> 

    </>
  );
}


function mapStateToProps(state) {
  return {
    getUserModuleData: state.getUserModuleData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default, reportActions.default, masterAction.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogsVersioning);
