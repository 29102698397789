
import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

import AccessDenied from '../common/AccessDenied';

import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import * as manageShipmentActions from "../../actions/manageShipmentActions";
import Loader from "../../components/Loader";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const ManageShipment = (props) => {
    const { leadId, id } = useParams();
    const location = useLocation();
    const { actions, usersData, fetchLeadIdItr, allLeadItrByLeadData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allUsers, setAllUsers] = useState([]);
    const [selectedItrFiles, setSelectedItrFiles] = useState([]);
    const formRef = useRef(null);

    const [allCampaigns, setAllCampaigns] = useState([]);
    const [allUploadLeads, setUploadLeads] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [allLeads, setAllLeads] = useState([]);
    const [lead, setLeadId] = useState('');
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const leadPermissions = permissionsData.find(permission => permission.module_name === 'Manage Shipment');
                const hasAddPermissions = leadPermissions?.add_permission;
                const hasEditPermissions = leadPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }

            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, "user_role_type": userRoleType };
        const requestData = encryptData(obj);
        const response = actions.leadsData(requestData);
        response.then((result) => {
            console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllLeads(resultArr);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });

    }, [setAllLeads]);

    const onSubmit = (data) => {
        // console.log("data : ", data);

        setPreloaderStatus(true);

        const cearetOrderParam = {
            "lead" : lead,
            "billing_customer_name" : data.billing_customer_name,
            "billing_address" : data.billing_address,
            "billing_address_2" : data.billing_address_2,
            "billing_city" : data.billing_city,
            "billing_pincode" : data.billing_pincode,
            "billing_state" : data.billing_state,
            "billing_country" : data.billing_country,
            "billing_email" : data.billing_email,
            "billing_phone" : data.billing_phone,
            "product_name" : data.product_name,
            "sku" : data.sku,
            "units" : data.units,
            "selling_price" : data.selling_price,
            "name" : data.name,
            "payment_method" : data.payment_method,
            "sub_total" : data.sub_total,
            "length" : data.length,
            "breadth" : data.breadth,
            "height" : data.height,
            "weight" : data.weight,

            "pickup_customer_name" : data.pickup_customer_name,
            "pickup_address" : data.pickup_address,
            "pickup_address_2" : data.pickup_address_2,
            "pickup_city" : data.pickup_city,
            "pickup_pincode" : data.pickup_pincode,
            "pickup_state" : data.pickup_state,
            "pickup_country" : data.pickup_country,
            "pickup_email" : data.pickup_email,
            "pickup_phone" : data.pickup_phone,

            "shipping_customer_name" : data.shipping_customer_name,
            "shipping_address" : data.shipping_address,
            "shipping_address_2" : data.shipping_address_2,
            "shipping_city" : data.shipping_city,
            "shipping_pincode" : data.shipping_pincode,
            "shipping_state" : data.shipping_state,
            "shipping_country" : data.shipping_country,
            "shipping_email" : data.shipping_email,
            "shipping_phone" : data.shipping_phone,
        };

        const requestData = encryptData(cearetOrderParam);
        const response = actions.createShipmentOrder(requestData, navigate);

        response.then((result) => {
            // console.log("Promise resolved - uploadLeads form:", result); 
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                setPreloaderStatus(false);
                reset();
            } else {
                setPreloaderStatus(false);
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            toast.error(error, {
                position: "top-right",
            });
        });
    };

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(()=> {
        if (leadId !== undefined) {
            const decodedId = atob(leadId);
            setLeadId(decodedId);
        }
    },[setLeadId, leadId]);

    const handleLeadId = (event, actionName = '') => {
        const selectedLeadId = event.target.value;
        setLeadId(selectedLeadId);
    }

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }


    return (
        <>
            <Helmet>
                <title> Create Shipment | Ckers </title>
            </Helmet>

            <Container>

                <div id='main-wrap' className='mt-2'>
                    <Typography variant="h4" sx={{ mb: 5 }}>
                        Create Shipment
                    </Typography>
                </div>

                <div id='main-wrap' className='mt-2'>
                    <form onSubmit={handleSubmit(onSubmit)} className="row" encType="multipart/form-data" ref={formRef}>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Shipments Information</h5>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label htmlFor="lead">
                                        <span>Lead</span>
                                        <select
                                            id='lead'
                                            name='lead'
                                            className="form-select"
                                            {...register('lead')}
                                            value={lead}
                                            onChange={handleLeadId}
                                        >
                                            <option value=''>Select Lead</option>
                                            {allLeads.map((option) => (
                                                <option value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Customer Name<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_customer_name' className='form-control'
                                            {...register('shipping_customer_name', { required: true })} />
                                    </label>
                                    {errors.shipping_customer_name && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Address 1<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_address' className='form-control'
                                            {...register('shipping_address', { required: true })} />
                                    </label>
                                    {errors.shipping_address && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Address 2<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_address_2' className='form-control'
                                            {...register('shipping_address_2', { required: true })} />
                                    </label>
                                    {errors.shipping_address_2 && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>City<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_city' className='form-control'
                                            {...register('shipping_city', { required: true })} />
                                    </label>
                                    {errors.shipping_city && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Pincode<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_pincode' className='form-control'
                                            {...register('shipping_pincode', { required: true })} />
                                    </label>
                                    {errors.shipping_pincode && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>State<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_state' className='form-control'
                                            {...register('shipping_state', { required: true })} />
                                    </label>
                                    {errors.shipping_state && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Country<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_country' className='form-control'
                                            {...register('shipping_country', { required: true })} />
                                    </label>
                                    {errors.shipping_country && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Email<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_email' className='form-control'
                                            {...register('shipping_email', { required: true })} />
                                    </label>
                                    {errors.shipping_email && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Phone<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='shipping_phone' className='form-control'
                                            {...register('shipping_phone', { required: true })} />
                                    </label>
                                    {errors.shipping_phone && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                        </div>

                        <div className="row d-none">
                            <div className="col-md-12">
                                <h5>Billing Information</h5>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Customer Name<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_customer_name' className='form-control'
                                            {...register('billing_customer_name')} value="cKers Finance"/>
                                    </label>
                                    {errors.billing_customer_name && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Address 1<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_address' className='form-control'
                                            {...register('billing_address')} value="Building No. 24-30, First Floor"/>
                                    </label>
                                    {errors.billing_address && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>City<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_city' className='form-control'
                                            {...register('billing_city')} value="South Delhi"/>
                                    </label>
                                    {errors.billing_city && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Pincode<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_pincode' className='form-control'
                                            {...register('billing_pincode')} value="110020"/>
                                    </label>
                                    {errors.billing_pincode && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>State<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_state' className='form-control'
                                            {...register('billing_state')} value="Delhi"/>
                                    </label>
                                    {errors.billing_state && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Country<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_country' className='form-control'
                                            {...register('billing_country')} value="India"/>
                                    </label>
                                    {errors.billing_country && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Email<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_email' className='form-control'
                                            {...register('billing_email')} value="rkapur@cKersfinance.in"/>
                                    </label>
                                    {errors.billing_email && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Phone<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='billing_phone' className='form-control'
                                            {...register('billing_phone')} value="9910457575"/>
                                    </label>
                                    {errors.billing_phone && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="row">
                            <div className="col-md-12">
                                <hr/>
                                <h5>Pickup Address Information</h5>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Name<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_name' className='form-control'
                                            {...register('pickup_name', { required: true })} />
                                    </label>
                                    {errors.pickup_name && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Email<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_email' className='form-control'
                                            {...register('pickup_email', { required: true })} />
                                    </label>
                                    {errors.pickup_email && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Phone<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_phone' className='form-control'
                                            {...register('pickup_phone', { required: true })} />
                                    </label>
                                    {errors.pickup_phone && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Address<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_address' className='form-control'
                                            {...register('pickup_address', { required: true })} />
                                    </label>
                                    {errors.pickup_address && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Address 2<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_address_2' className='form-control'
                                            {...register('pickup_address_2', { required: true })} />
                                    </label>
                                    {errors.pickup_address_2 && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>City<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_city' className='form-control'
                                            {...register('pickup_city', { required: true })} />
                                    </label>
                                    {errors.pickup_city && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>State<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_state' className='form-control'
                                            {...register('pickup_state', { required: true })} />
                                    </label>
                                    {errors.pickup_state && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Country<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_country' className='form-control'
                                            {...register('pickup_country', { required: true })} />
                                    </label>
                                    {errors.pickup_country && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Pincode<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='pickup_pincode' className='form-control'
                                            {...register('pickup_pincode', { required: true })} />
                                    </label>
                                    {errors.pickup_pincode && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                        </div> */}

                        <div className="row d-none">
                            <div className="col-md-12">
                                <h5>Product Information</h5>
                                <hr />
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Units<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='units' className='form-control'
                                            {...register('units')} value="1"/>
                                    </label>
                                    {errors.units && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Selling Price<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='selling_price' className='form-control'
                                            {...register('selling_price')} value="0"/>
                                    </label>
                                    {errors.selling_price && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Sub Total<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='sub_total' className='form-control'
                                            {...register('sub_total')} value="0"/>
                                    </label>
                                    {errors.sub_total && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Length<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='length' className='form-control'
                                            {...register('length')} value="10"/>
                                    </label>
                                    {errors.length && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Breadth<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='breadth' className='form-control'
                                            {...register('breadth')} value="10"/>
                                    </label>
                                    {errors.breadth && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Height<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='height' className='form-control'
                                            {...register('height')} value="5"/>
                                    </label>
                                    {errors.height && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="name">
                                        <span>Weight<a style={{ color: "red" }}>*</a></span>
                                        <input type='text' name='weight' className='form-control'
                                            {...register('weight')} value="1"/>
                                    </label>
                                    {errors.weight && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-0">
                                    <button className="btn btn-primary">Create Order</button>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                    </form>
                </div>
                <br/><br/>
            </Container>

            {preloaderStatus && <Loader />}
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        getUserModuleData: state.getUserModuleData,
        uploadLeadData: state.uploadLeadData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, manageShipmentActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageShipment);
