import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { Link as RouterLink , useNavigate,useParams ,useLocation} from 'react-router-dom';
import DataTable from 'react-data-table-component'; 
import Chart from 'react-apexcharts';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { PieChart, Pie, Tooltip, BarChart, XAxis, YAxis, Legend, CartesianGrid, Bar, ResponsiveContainer, Cell, Label, LabelList} from "recharts";
import { scaleOrdinal } from 'd3-scale'; // Import 'scaleOrdinal' from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as userActions from "../../actions/userActions";
import * as dashboardActions from "../../actions/dashboardActions";
import * as moduleAction from "../../actions/moduleAction";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const DashboardAppPage = (props) => {

  const theme = useTheme();
  const { actions, userInfo, loggedinUserActivityData, loggedinUserFollowupLeadsData, loggedinUserNewLeadsData, requestData} = props;
  const navigate = useNavigate();
  const [showListDetails, setShowListDetails] = useState(false);
  const [newLeadData, setNewLeadData] = useState([]);
  const [followUpsLeadData, setFollowUpsLeadData] = useState([]);
  const [leadByStatusData, setLeadByStatusData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [getAllChildsData, setAllChildsData] = useState([]);

  const [getLeadStatus, setGetLeadStatus]= useState([]);
  const [getLeadCount, setGetLeadCount]= useState([]);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: registerHabile, handleSubmit: handleSubmitHabile, formState: { errors: errorsHabile }, setValue: setValueHabile } = useForm();
  const { register: registerDailyLeads, handleSubmit: handleSubmitDailyLeads, formState: { errors: errorsDailyLeads }, setValue: setValueDailyLeads } = useForm();

  const [selectedNewLeadAssigned, setSelectedNewLeadAssigned] = useState(userInfo.id);
  const [selectedFollowupLeadAssigned, setSelectedFollowupLeadAssigned] = useState(userInfo.id);

  const [totalRowsNewLead, setTotalRowsNewLead] = useState(0);
  const [currentPageNewLead, setCurrentPageNewLead] = useState(1);
  const [pageSizeNewLead, setPageSizeNewLead] = useState(10);

  const [totalRowsFollowupLead, setTotalRowsFollowupLead] = useState(0);
  const [currentPageFollowupLead, setCurrentPageFollowupLead] = useState(1);
  const [pageSizeFollowupLead, setPageSizeFollowupLead] = useState(config.ITEMS_PER_PAGE);

  const [allIncidentReports, setAllIncidentReports] = useState([]);
  const [selectedIncidentReportsAssigned, setSelectedIncidentReportsAssigned] = useState(userInfo.id);
  const [totalRowsIncidentReports, setTotalRowsIncidentReports] = useState(0);
  const [currentPageIncidentReports, setCurrentPageIncidentReports] = useState(1);
  const [pageSizeIncidentReports, setPageSizeIncidentReports] = useState(10);
  const [leadSorceData, setLeadSorceData] = useState([]);

  const [allIncidentTask, setAllIncidentTask] = useState([]);
  const [selectedIncidentTaskAssigned, setSelectedIncidentTaskAssigned] = useState(userInfo.id);
  const [totalRowsIncidentTask, setTotalRowsIncidentTask] = useState(0);
  const [currentPageIncidentTask, setCurrentPageIncidentTask] = useState(1);
  const [pageSizeIncidentTask, setPageSizeIncidentTask] = useState(10);

  const [allModule, setAllModule] = useState([]);
  const [selectedModuleAssigned, setSelectedModuleAssigned] = useState(userInfo.id);
  const [totalRowsModule, setTotalRowsModule] = useState(0);
  const [currentPageModule, setCurrentPageModule] = useState(1);
  const [pageSizeModule, setPageSizeModule] = useState(10);

  const [allModuleTask, setAllModuleTask] = useState([]);
  const [selectedModuleTaskAssigned, setSelectedModuleTaskAssigned] = useState(userInfo.id);
  const [totalRowsModuleTask, setTotalRowsModuleTask] = useState(0);
  const [currentPageModuleTask, setCurrentPageModuleTask] = useState(1);
  const [pageSizeModuleTask, setPageSizeModuleTask] = useState(10);

  const [viewLeadModulePermissionStatus, setViewLeadModulePermissionStatus] = useState(false);
  const [viewHabileUsageModulePermissionStatus, setViewHabileUsageModulePermissionStatus] = useState(false);
  const [viewScorecardsModulePermissionStatus, setViewScorecardsModulePermissionStatus] = useState(false);
  const [viewIncidentReportPermissionStatus, setIncidentReportPermissionStatus] = useState(false);
  const [requestModulePermissionStatus, setRequestModulePermissionStatus] = useState(false);
  const [isExternalUser, setIsExternalUser] = useState(false);
  
  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if(authToken === null || authToken === ""){
        navigate('/login');
    }
  }, []);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;

        // Leads module permission
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Leads');
        const hasViewPermissions = leadPermissions?.view_permission;
        setViewLeadModulePermissionStatus(hasViewPermissions);

        const hasHabileUsageViewPermissions = permissionsData.find(permission => permission.module_name === 'Habile Usage')?.view_permission;
        setViewHabileUsageModulePermissionStatus(hasHabileUsageViewPermissions);

        const hasScorecardsViewPermissions = permissionsData.find(permission => permission.module_name === 'Scorecards')?.view_permission;
        setViewScorecardsModulePermissionStatus(hasScorecardsViewPermissions);

        const incidentReportPermissions = permissionsData.find(permission => permission.module_name === 'Incident Report');
        const hasPermissionsIncidentReportPermissions = incidentReportPermissions?.view_permission;
        setIncidentReportPermissionStatus(hasPermissionsIncidentReportPermissions);

        const hasRequestViewPermissions = permissionsData.find(permission => permission.module_name === 'Request')?.view_permission;
        setRequestModulePermissionStatus(hasRequestViewPermissions);

        if(Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'is_external_user')  && result.data.user_info[0].is_external_user !== '' && result.data.user_info[0].is_external_user === "1"){
          setIsExternalUser(true);
        }
      }
    }).catch((error) => {
      // console.error("Promise rejected -  lead list:", error);
    });
  }, [setViewLeadModulePermissionStatus, setViewHabileUsageModulePermissionStatus, setViewScorecardsModulePermissionStatus, setIncidentReportPermissionStatus, setRequestModulePermissionStatus, setIsExternalUser]);

  useEffect(() => {

    setShowListDetails(false);
    if (!showListDetails) {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': userInfo.id};
        const requestData = encryptData(obj);

        const response = actions.loggedinUserActivityData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  master profile data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                
                setShowListDetails(true);
                if('lead_res' in results){
                  setLeadByStatusData(results.lead_res);

                  // console.log("setLeadByStatusData", results.lead_res);
            
                  const leadStatusName=[];
                  const leadStatusValue=[];
            
                  const leadStatusData = results.lead_res;
                    if (Array.isArray(leadStatusData) && leadStatusData.length > 0) {
            
                      for(let i = 0; i < leadStatusData.length; i += 1)
                      {
                        leadStatusName.push(leadStatusData[i].lead_status);
                        leadStatusValue.push(leadStatusData[i].leads);
                      }
                      setGetLeadStatus(leadStatusName);
                      setGetLeadCount(leadStatusValue);
                    }
                }
            }
        }).catch((error) => {
             console.error("Promise rejected -  loggedinUserActivityData :", error); 
        });
    }
  }, [setGetLeadStatus, setGetLeadCount, setLeadByStatusData]);

  const handlePageChangeFollowupLead = (page) => {
    setCurrentPageFollowupLead(page);
  };
  const handlePerRowsChangeFollowupLead = async (newPageSizeFollowupLead, currentPageFollowupLead) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeFollowupLead(newPageSizeFollowupLead);
    setCurrentPageFollowupLead(currentPageFollowupLead);
  };

  useEffect(() => {
    let obj = "";
    if(selectedFollowupLeadAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': userInfo.id, "page": currentPageFollowupLead, "size": pageSizeFollowupLead};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': userInfo.id, "page": currentPageFollowupLead, "size": pageSizeFollowupLead, "lead_assigned": selectedFollowupLeadAssigned };
    }
    // console.log("selectedFollowupLeadAssigned requestData = ", obj);
    const requestData = encryptData(obj);
    fetchFollowupLeadsData(requestData);
  }, [selectedFollowupLeadAssigned, currentPageFollowupLead, pageSizeFollowupLead]);

  const fetchFollowupLeadsData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.getLoggedinUserExternalLeads(requestData, false);
      if (result.data.success === 1) {
        const results = result.data.data.data;
        setShowListDetails(true);
        if('external_lead_res' in results){
          setFollowUpsLeadData(results.external_lead_res);
          setTotalRowsFollowupLead(results.count_result);
        }
      }
    } catch (error) {
      console.error("Promise rejected - getLoggedinUserExternalLeads:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNewLead(page);
  };
  
  const handlePerRowsChange = async (newPageSizeNewLead, currentPageNewLead) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeNewLead(newPageSizeNewLead);
    setCurrentPageNewLead(currentPageNewLead);
  };

  useEffect(() => {
    let obj = "";
    if(selectedNewLeadAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': userInfo.id, "page": currentPageNewLead, "size": pageSizeNewLead};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, 'user_id': userInfo.id, "page": currentPageNewLead, "size": pageSizeNewLead, "lead_assigned": selectedNewLeadAssigned };
    }
    const requestData = encryptData(obj);
    fetchNewLeadsData(requestData);
  }, [selectedNewLeadAssigned, currentPageNewLead, pageSizeNewLead]);

  const fetchNewLeadsData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.loggedinUserNewLeadsData(requestData, false);
      if (result.data.success === 1) {
        const results = result.data.data.data;
        setShowListDetails(true);
        if ('new_lead_res' in results) {
          setNewLeadData(results.new_lead_res);
          setTotalRowsNewLead(results.count_result);
        }
      }
    } catch (error) {
      console.error("Promise rejected - loggedinUserNewLeadsData:", error);
    }
  };

  const newLeadColumns = [
    {
      name: 'Name',
      // selector: (row) => row.lead_name,
      selector: (row) => (
        <a href={`/dashboard/edit-lead/${btoa(String(row.lead_id))}`} style={{ color: 'black' }}>
          {row.lead_name}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Mobile',
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => row.created_date,
      sortable: true,
    }
  ];

  const followUpsLeadLeadColumns = [
    {
      name: 'Name',
      // selector: (row) => row.lead_name,
      selector: (row) => (
        <a href={`/dashboard/edit-lead/${btoa(String(row.lead_id))}`} style={{ color: 'black' }}>
          {row.lead_name}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Mobile',
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => row.created_date,
      sortable: true,
    }
  ];

  useEffect(() => {
    let obj = "";
    if(selectedIncidentReportsAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageIncidentReports, "size": pageSizeIncidentReports};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageIncidentReports, "size": pageSizeIncidentReports, "lead_assigned": selectedIncidentReportsAssigned };
    }
    const requestData = encryptData(obj);
    fetchIncidentReportsData(requestData);
  }, [selectedIncidentReportsAssigned, currentPageIncidentReports, pageSizeIncidentReports]);

  const fetchIncidentReportsData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.incidentReportAssignedMe(requestData, false);

      if (result.data.success === 1) {
        const results = result.data.data;
        setShowListDetails(true);
        if ('incident_reporte' in results) {
          setAllIncidentReports(results.incident_reporte);
          setTotalRowsIncidentReports(results.count_result);
        }
      }
    } catch (error) {
      console.error("Promise rejected - incidentReportAssignedMe:", error);
    }
  };

  // const incidentReportAssignedMeColumn = [
  //   {
  //     name: 'Name',
  //     // selector: (row) => row.lead_name,
  //     selector: (row) => (
  //       <a href={`/dashboard/view-ticket/${btoa(String(row.id))}`} style={{ color: 'black', display: 'block', maxHeight: '3.6em', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.8em' }}>
  //         {(row.leadName && row.leadName.trim() ? row.leadName : row.name || '')}
  //       </a>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: 'Category',
  //     selector: (row) => row.incidentCategor,
  //     sortable: true,
  //   },
  //   // {
  //   //   name: 'Topic',
  //   //   selector: (row) => row.type_topic,
  //   //   sortable: true,
  //   //   cell: (row) => (
  //   //     <div style={{ overflow: 'hidden' }}>
  //   //       {row.type_topic}
  //   //     </div>
  //   //   ),
  //   // },
  //   {
  //     name: 'Created Date',
  //     selector: (row) => row.created_date,
  //     sortable: true,
  //   },
  // ];

  const incidentReportAssignedMeColumn = [
    {
      name: 'Name',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <a href={`/dashboard/view-ticket/${btoa(String(row.id))}`} style={{ color: textColor, display: 'block', maxHeight: '3.6em', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.8em' }}>
            {(row.leadName && row.leadName.trim() ? row.leadName : row.name || '')}
          </a>
        );
      },
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <span1 className="nav_name" style={{ color: textColor}}>{(row.incidentCategor)}</span1>
        );
      },
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <span1 className="nav_name" style={{ color: textColor}}>{(row.created_date)}</span1>
        );
      },
      sortable: true,
    }
  ];

  const handlePageChangeIncidentReports = (page) => {
    setCurrentPageIncidentReports(page);
  };
  const handlePerRowsChangeIncidentReports = async (newPageSizeIncidentReports, currentPageIncidentReports) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeIncidentReports(newPageSizeIncidentReports);
    setCurrentPageIncidentReports(currentPageIncidentReports);
  };

  // useEffect(() => {
  //   const params = { 'user_id': userInfo.id };
  //   const requestData = encryptData(params);
  //   const response = actions.getLoggedinUserSourceLeads(requestData, false);
  //   response.then((result) => {
  //     // console.log("Promise resolved - leadBreakupCountData", result); 
  //     if (result.data.success === 1) {
  //       const results = result.data.data.per_source_res;
  //       // console.log("results :", results);
  //       setLeadSorceData(results);
  //     }
  //   }).catch((error) => {
  //     // console.error("Promise rejected - leadBreakupCountData", error);
  //   });
  // }, [setLeadSorceData]);

  const sourceChartDataFormatted = leadSorceData.map((entry, index) => ({
    name: entry.sourceName,
    sourceId: entry.sourceId,
    leadCount: parseInt(entry.leadCount, 10), // Convert lead_count to an integer
    color: theme.palette[index % theme.palette.length]?.main || '#000', // Use a fallback color if 'main' is not available
  }));

  const colorScale = scaleOrdinal(schemeCategory10);

  const xValues = getLeadStatus.map((_, index) => index + 1);
  
  useEffect(() => {
    // setAllLeadStatusData(false);
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getLoggedinUserChild(requestData, false);

    response.then((result) => {
      // console.log("Promise resolved -  lead Status data:", result); 
      if (result.data.success === 1) {
        const results = result.data.data.data;
        setAllChildsData(results);
      }
    }).catch((error) => {
      //  console.error("Promise rejected -  source data :", error); 
    });
  }, [setAllChildsData]);

  const checkLogout = () => {
    const authToken = localStorage.getItem('authToken');
    const logInTimestamp = localStorage.getItem('logInTimestamp');

    if (logInTimestamp !== null) {
      const loginTimestamp = parseInt(logInTimestamp, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - loginTimestamp;
      const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

      if (timeElapsed >= autoLogoutTimeInSecod) {
        const params = { 'login_token': authToken };
        console.log("params : ", params);
        const requestData = encryptData(params);
        const response = actions.userLogout(requestData);
        response.then((result) => {
          if (result.data.success === 1) {
            const data = {};
            actions.loadUserAuth(false);
            actions.loginDataSuccess(data);
            localStorage.setItem("authToken", '');
            localStorage.setItem("logInTimestamp", '');
            navigate("/login");
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }

  useEffect(() => {
    checkLogout();
  });

  const [allSendHabileCount, setAllSendHabileCount] = useState("");
  const [allGenerateScoreCount, setAllGenerateScoreCount] = useState("");
  const [allTotalLeadsCount, setAllTotalLeadsCount] = useState("");
  const [allSentToCreditCount, setAllSentToCreditCount] = useState("");
  const [allUserName, setAllUserName] = useState("");
  const [allSentToBureau, setAllSentToBureau] = useState("");

  const [startDateHabileScorecard, setStartDateHabileScorecard] = useState('');
  const [endDateHabileScorecard, setEndDateHabileScorecard] = useState('');

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = new Date();

    const previousDay = new Date(today);
    previousDay.setDate(today.getDate() - 6);

    const previousDayFormatted = formatDate(previousDay);
    const todayFormatted = formatDate(today);

    setValueHabile('habile_and_scorecard_start_date', previousDayFormatted);
    setValueHabile('habile_and_scorecard_end_date', todayFormatted);

    setStartDateHabileScorecard(previousDayFormatted);
    setEndDateHabileScorecard(todayFormatted);

    const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': previousDayFormatted, 'end_date': todayFormatted };
    const requestData = encryptData(params);
    const response = actions.getLoggedinUserScorecardActivity(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved - getLoggedinUserScorecardActivity", result);
      if (result.data.success === 1) {
        const results = result.data.data.data;

        if (results) {
          setAllUserName(results.userName);
          setAllSendHabileCount(results.sendHabileCount);
          setAllGenerateScoreCount(results.generateScoreCount);
          setAllTotalLeadsCount(results.totalLeads);
          setAllSentToCreditCount(results.totalSentToCredit);
          setAllSentToBureau(results.totalSentToBureau);
        }
      }
    }).catch((error) => {
      console.error("Promise rejected - getLoggedinUserScorecardActivity", error);
      toast.error(error, {
        position: "top-right",
      });
    });

  }, [setAllUserName, setAllSendHabileCount, setAllGenerateScoreCount, setAllTotalLeadsCount, setAllSentToCreditCount, setAllSentToBureau]);

  const handleHabileScorecard = (data) =>{

    setStartDateHabileScorecard(data.habile_and_scorecard_start_date);
    setEndDateHabileScorecard(data.habile_and_scorecard_end_date);

    const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': data.habile_and_scorecard_start_date, 'end_date': data.habile_and_scorecard_end_date };
    const requestData = encryptData(params);
    const response = actions.getLoggedinUserScorecardActivity(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved - getLoggedinUserScorecardActivity", result);
      if (result.data.success === 1) {
        const results = result.data.data.data;

        if (results) {
          setAllUserName(results.userName);
          setAllSendHabileCount(results.sendHabileCount);
          setAllGenerateScoreCount(results.generateScoreCount);
          setAllTotalLeadsCount(results.totalLeads);
          setAllSentToCreditCount(results.totalSentToCredit);
          setAllSentToBureau(results.totalSentToBureau);
        }
      }
    }).catch((error) => {
      console.error("Promise rejected - getLoggedinUserScorecardActivity", error);
      toast.error(error, {
        position: "top-right",
      });
    });
  };

  let allUserNameArray = '';
  let sendHabileCount = '';
  let generateScoreCount = '';
  let totalLeadsCount = '';
  let sentToCreditCount = '';
  let totalSentToBureau = '';

  if(allUserName && allUserName.length > 0){
    allUserNameArray = allUserName.split(',');
  }

  if (allSendHabileCount) {
    if (Array.isArray(allSendHabileCount)) {
      sendHabileCount = allSendHabileCount.map(value => parseInt(value.trim(), 10));
    } else {
      sendHabileCount = allSendHabileCount.split(',').map(value => parseInt(value.trim(), 10));
    }
  }

  if (allGenerateScoreCount) {
    if (Array.isArray(allGenerateScoreCount)) {
      generateScoreCount = allGenerateScoreCount.map(value => parseInt(value.trim(), 10));
    } else {
      generateScoreCount = allGenerateScoreCount.split(',').map(value => parseInt(value.trim(), 10));
    }
  }

  if (allTotalLeadsCount) {
    if (Array.isArray(allTotalLeadsCount)) {
      totalLeadsCount = allTotalLeadsCount.map(value => parseInt(value.trim(), 10));
    } else {
      totalLeadsCount = allTotalLeadsCount.split(',').map(value => parseInt(value.trim(), 10));
    }
  }

  if (allSentToCreditCount) {
    if (Array.isArray(allSentToCreditCount)) {
      sentToCreditCount = allSentToCreditCount.map(value => parseInt(value.trim(), 10));
    } else {
      sentToCreditCount = allSentToCreditCount.split(',').map(value => parseInt(value.trim(), 10));
    }
  }

  if (allSentToBureau) {
    if (Array.isArray(allSentToBureau)) {
      totalSentToBureau = allSentToBureau.map(value => parseInt(value.trim(), 10));
    } else {
      totalSentToBureau = allSentToBureau.split(',').map(value => parseInt(value.trim(), 10));
    }
  }
  

  let habileAndScoreState = '';
  if(allUserNameArray !== "" && sendHabileCount !== "" && generateScoreCount  !== "" && sentToCreditCount !=='' && totalSentToBureau !==''){

    habileAndScoreState =
    {
        series: [{
            name: 'No. of Sent to Habile',
            type: 'column',
            data: sendHabileCount
        }, {
            name: 'No. of Generated Scorecards',
            type: 'column',
            data: generateScoreCount
        }, {
          name: 'No. of leads added/assigned',
          type: 'column',
          data: totalLeadsCount
        }, {
          name: 'No. of cases sent to credit',
          type: 'column',
          data: sentToCreditCount
        }, {
          name: 'No. of Bureau Call',
          type: 'column',
          data: totalSentToBureau
        }
      ],

        
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 4]
            },
            xaxis: {
                categories: allUserNameArray,
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            ],
            tooltip: {
                fixed: {
                    enabled: false,
                    position: 'topLeft',
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            }
        },
    };
    
  } else {
    habileAndScoreState = {
      series: [],
      options: {},
    };
  }

  const [startDateDailyLeads, setStartDateDailyLeads] = useState('');
  const [endDateDailyLeads, setEndDateDailyLeads] = useState('');

  const [allLeadCreatedDate, setAllLeadCreatedDate] = useState('');
  const [allTotalCreatedLeads, setAllTotalCreatedLeads] = useState('');

  useEffect(() => {
    const today = new Date();

    const previousDayDailyLeads = new Date(today);
    previousDayDailyLeads.setDate(today.getDate() - 15);

    const previousDayFormattedDailyLeads = formatDate(previousDayDailyLeads);
    const todayFormattedDailyLeads = formatDate(today);

    setValueDailyLeads('daily_leads_start_date', previousDayFormattedDailyLeads);
    setValueDailyLeads('daily_leads_end_date', todayFormattedDailyLeads);

    setStartDateHabileScorecard(previousDayFormattedDailyLeads);
    setEndDateHabileScorecard(todayFormattedDailyLeads);

    const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': previousDayFormattedDailyLeads, 'end_date': todayFormattedDailyLeads };
    const requestData = encryptData(params);
    const response = actions.getDailyLeadsActivity(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved - getDailyLeadsActivity", result);
      if (result.data.success === 1) {
        const results = result.data.data;

        if ('added_leads' in results) {
          const addedLeads = results.added_leads
          setAllLeadCreatedDate(addedLeads.leadCreatedDate);
          setAllTotalCreatedLeads(addedLeads.totalCreatedLeads);
        }

        if ('per_source_res' in results) {
          const perSourceRes = results.per_source_res;
          setLeadSorceData(perSourceRes);
        }
      }
    }).catch((error) => {
      console.error("Promise rejected - getDailyLeadsActivity", error);
      toast.error(error, {
        position: "top-right",
      });
    });

  }, [setStartDateHabileScorecard, setEndDateHabileScorecard, setValueDailyLeads, setAllLeadCreatedDate, setAllTotalCreatedLeads, setLeadSorceData]);

  const handleDailyLeads = (data) =>{

    setStartDateDailyLeads(data.daily_leads_start_date);
    setEndDateDailyLeads(data.daily_leads_end_date);

    const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': data.daily_leads_start_date, 'end_date': data.daily_leads_end_date };
    const requestData = encryptData(params);
    const response = actions.getDailyLeadsActivity(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved - getDailyLeadsActivity", result);
      if (result.data.success === 1) {
        const results = result.data.data;

        if ('added_leads' in results) {
          const addedLeads = results.added_leads
          setAllLeadCreatedDate(addedLeads.leadCreatedDate);
          setAllTotalCreatedLeads(addedLeads.totalCreatedLeads);
        }

        if ('per_source_res' in results) {
          const perSourceRes = results.per_source_res;
          setLeadSorceData(perSourceRes);
        }
      }
    }).catch((error) => {
      console.error("Promise rejected - getDailyLeadsActivity", error);
      toast.error(error, {
        position: "top-right",
      });
    });
  };

  let allLeadCreatedDateArray = '';
  let allTotalCreatedLeadsCount = '';

  if(allLeadCreatedDate && allLeadCreatedDate.length > 0){
    allLeadCreatedDateArray = allLeadCreatedDate.split(',');
  }

  if (allTotalCreatedLeads) {
    if (Array.isArray(allTotalCreatedLeads)) {
      allTotalCreatedLeadsCount = allTotalCreatedLeads.map(value => parseInt(value.trim(), 10));
    } else {
      allTotalCreatedLeadsCount = allTotalCreatedLeads.split(',').map(value => parseInt(value.trim(), 10));
    }
  }

  let handleDailyState = '';
  if(allLeadCreatedDateArray !== "" && allTotalCreatedLeadsCount !== ""){

    handleDailyState =
    {
        series: [{
            name: 'No. of Leads Added',
            type: 'column',
            data: allTotalCreatedLeadsCount
        }
      ],
      options: {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        xaxis: {
            categories: allLeadCreatedDateArray,
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            }
        ],
        tooltip: {
            fixed: {
                enabled: false,
                position: 'topLeft',
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
      },
    };
    
  } else {
    handleDailyState = {
      series: [],
      options: {},
    };
  }

  const handleSelectedIncidentTaskAssignedChange = (e) => {
    setSelectedIncidentTaskAssigned(e.target.value);
  };

  useEffect(() => {
    let obj = "";
    if(selectedIncidentTaskAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageIncidentTask, "size": pageSizeIncidentTask};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageIncidentTask, "size": pageSizeIncidentTask, "lead_assigned": selectedIncidentTaskAssigned };
    }
    const requestData = encryptData(obj);
    fetchIncidentTaskData(requestData);
  }, [selectedIncidentTaskAssigned, currentPageIncidentTask, pageSizeIncidentTask]);

  const fetchIncidentTaskData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.incidentTaskAssignedMe(requestData, false);

      if (result.data.success === 1) {
        const results = result.data.data;
        setShowListDetails(true);
        if ('incident_task' in results) {
          setAllIncidentTask(results.incident_task);
          setTotalRowsIncidentTask(results.count_incident_task);
        }
      }
    } catch (error) {
      console.error("Promise rejected - incidentTaskAssignedMe:", error);
    }
  };

  const incidentTaskAssignedMeColumn = [
    {
      name: 'Task Name',
      selector: (row) => row.task_name || row.approvalMasterTaskName,
      sortable: true,
      cell: (row) => (
        <a href={`/dashboard/view-ticket/${btoa(String(row.incident_id))}`} style={{ color: 'black', display: 'block', maxHeight: '3.6em', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.8em' }}>
          {(row.task_name && row.task_name.trim() ? row.task_name : row.task_name || row.approvalMasterTaskName && row.approvalMasterTaskName.trim() ? row.approvalMasterTaskName : row.approvalMasterTaskName)}
        </a>
      ),
    },
    {
      name: 'Due Date',
      selector: (row) => row.dueDate,
      sortable: true,
    },
    // {
    //   name: 'Assigned To',
    //   selector: (row) => row.assignedTo,
    //   sortable: true,
    // },
    {
      name: 'Created Date',
      selector: (row) => row.created_date,
      sortable: true,
    },
  ];

  const handlePageChangeIncidentTask = (page) => {
    setCurrentPageIncidentTask(page);
  };
  const handlePerRowsChangeIncidentTask = async (newPageSizeIncidentTask, currentPageIncidentTask) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeIncidentTask(newPageSizeIncidentTask);
    setCurrentPageIncidentTask(currentPageIncidentTask);
  };

  useEffect(() => {
    let obj = "";
    if(selectedModuleAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageModule, "size": pageSizeModule};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageModule, "size": pageSizeModule, "lead_assigned": selectedModuleAssigned };
    }
    const requestData = encryptData(obj);
    fetchModuleData(requestData);
  }, [selectedModuleAssigned, currentPageModule, pageSizeModule]);

  const fetchModuleData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.moduleAssignedMe(requestData, false);

      if (result.data.success === 1) {
        const results = result.data.data;
        setShowListDetails(true);
        if ('incident_reporte' in results) {
          setAllModule(results.incident_reporte);
          setTotalRowsModule(results.count_result);
        }
      }
    } catch (error) {
      console.error("Promise rejected - moduleAssignedMe:", error);
    }
  };

  const moduleAssignedMeColumn = [
    {
      name: 'Name',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <a href={`/dashboard/module-view-ticket-page/${btoa(String(row.id))}`} style={{ color: textColor, display: 'block', maxHeight: '3.6em', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.8em' }}>
            {(row.leadName && row.leadName.trim() ? row.leadName : row.name || '')}
          </a>
        );
      },
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <span1 className="nav_name" style={{ color: textColor}}>{(row.incidentCategor)}</span1>
        );
      },
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => {
        const isDueDateOver = row.dueDateOver; // Assuming row.dueDateOver returns true/false
        const textColor = isDueDateOver ? 'red' : 'black';

        return (
          <span1 className="nav_name" style={{ color: textColor}}>{(row.created_date)}</span1>
        );
      },
      sortable: true,
    }
  ];

  const handlePageChangeModule = (page) => {
    setCurrentPageModule(page);
  };
  const handlePerRowsChangeModule = async (newPageSizeModule, currentPageModule) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeModule(newPageSizeModule);
    setCurrentPageModule(currentPageModule);
  };

  // ==============================================

  const handleSelectedModuleTaskAssignedChange = (e) => {
    setSelectedModuleTaskAssigned(e.target.value);
  };
  
  useEffect(() => {
    let obj = "";
    if(selectedModuleTaskAssigned === ""){
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageModuleTask, "size": pageSizeModuleTask};
    } else {
      obj = { 'start': '0', 'length': config.MAX_RECORDS, "page": currentPageModuleTask, "size": pageSizeModuleTask, "lead_assigned": selectedModuleTaskAssigned };
    }
    const requestData = encryptData(obj);
    fetchModuleTaskData(requestData);
  }, [selectedModuleTaskAssigned, currentPageModuleTask, pageSizeModuleTask]);
  
  const fetchModuleTaskData = async (requestData) => {
    setShowListDetails(false);
    try {
      const result = await actions.moduleTaskAssignedMe(requestData, false);
  
      if (result.data.success === 1) {
        const results = result.data.data;
        setShowListDetails(true);
        if ('incident_task' in results) {
          setAllModuleTask(results.incident_task);
          setTotalRowsModuleTask(results.count_incident_task);
        }
      }
    } catch (error) {
      console.error("Promise rejected - moduleTaskAssignedMe:", error);
    }
  };
  
  const moduleTaskAssignedMeColumn = [
    {
      name: 'Name',
      selector: (row) => row.task_name || row.approvalMasterTaskName,
      sortable: true,
      cell: (row) => (
        <a href={`/dashboard/module-view-ticket-page/${btoa(String(row.incident_id))}`} style={{ color: 'black', display: 'block', maxHeight: '3.6em', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.8em' }}>
          {(row.task_name && row.task_name.trim() ? row.task_name : row.task_name || row.approvalMasterTaskName && row.approvalMasterTaskName.trim() ? row.approvalMasterTaskName : row.approvalMasterTaskName)}
        </a>
      ),
    },
    {
      name: 'Due Date',
      selector: (row) => row.dueDate,
      sortable: true,
    },
    // {
    //   name: 'Assigned To',
    //   selector: (row) => row.assignedTo,
    //   sortable: true,
    // },
    {
      name: 'Created Date',
      selector: (row) => row.created_date,
      sortable: true,
    },
  ];
  
  const handlePageChangeModuleTask = (page) => {
    setCurrentPageModuleTask(page);
  };
  const handlePerRowsChangeModuleTask = async (newPageSizeModuleTask, currentPageModuleTask) => {
    // You can update the pageSize state and then re-fetch data based on the new page size
    setPageSizeModuleTask(newPageSizeModuleTask);
    setCurrentPageModuleTask(currentPageModuleTask);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Ckers </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome!
        </Typography>

        
        {(isExternalUser) && (
          <div className='col-md-12 mb-4'>
            <div id='main-wrap' className='search-filter h-100'>

              <div className='row'>
                <div className='col-md-12'>
                  <h5 className="fw-600 mb-0">Leads Pending Action</h5>
                </div>
              </div>

              <DataTable
                columns={followUpsLeadLeadColumns}
                data={followUpsLeadData}
                pagination
                paginationServer
                paginationTotalRows={totalRowsFollowupLead}
                paginationPerPage={pageSizeFollowupLead}
                onChangeRowsPerPage={handlePerRowsChangeFollowupLead}
                paginationDefaultPage={currentPageFollowupLead}
                onChangePage={handlePageChangeFollowupLead}
              />
            </div>
          </div>
        )}

        {(viewLeadModulePermissionStatus) && (
          <div id='main-wrap' className='mb-4'>
              <div className='row'>
                
                <form onSubmit={handleSubmitDailyLeads(handleDailyLeads)}>
                  <div id='main-wrap' className='mb-4 filter'>
                    <div className='row mx-0'>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor="start-date">
                            <span>Start Date</span>
                            <input type='date' name='daily_leads_start_date' className='form-control'{...registerDailyLeads('daily_leads_start_date', { required: true })} />
                          </label>
                          {errorsDailyLeads.daily_leads_start_date && <span className="required">This field is required.</span>}
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor="end-date">
                            <span>End Date</span>
                            <input type='date' name='daily_leads_end_date' className='form-control'{...registerDailyLeads('daily_leads_end_date', { required: true })} />
                          </label>
                          {errorsDailyLeads.daily_leads_end_date && <span className="required">This field is required.</span>}
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group mb-2'>
                          <span className='d-block'>&nbsp;</span>
                          <button className='btn btn-primary'>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                
                <div className= {isExternalUser ? 'col-md-12 mb-4' : 'col-md-8 mb-4' }>
                  <h5 className="fw-600 mb-3">Daily Added Leads</h5>
                  <div id="chart">
                    <Chart options={handleDailyState.options} series={handleDailyState.series} type="line" height={350} />
                  </div>
                </div>

                <div className= {isExternalUser ? 'col-md-4 mb-4 d-none' : 'col-md-4 mb-4' }>
                  <h5>Breakup as Per Source</h5>
                  <ResponsiveContainer width="100%" height={321}>
                    <PieChart>
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="bottom"
                        // align="right"
                        wrapperStyle={{ marginRight: '20px' }}
                      />
                      <Pie dataKey="leadCount" data={sourceChartDataFormatted} cx="50%" cy="50%" outerRadius={80} labelLine={false}>
                        {sourceChartDataFormatted.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colorScale(index)}
                          />
                        ))}
                        <LabelList dataKey="leadCount" position="inside" />
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>

                </div>
              </div>
          </div>
        )}
          

        <div id='main-wrap-'>

          {(viewLeadModulePermissionStatus) && (
            <div className='row'>
              <div className= {isExternalUser ? 'col-md-12 mb-4 d-none' : 'col-md-12 mb-4' }>
                
                <div id='main-wrap'>
                  <h5 className="fw-600 mb-0">My Activity</h5>

                  <Chart type="bar" height={315}
                    series={[
                      {
                        name: "Number Of Lead",
                        data: getLeadCount,
                      },
                    ]}
                    options={{
                      colors: ["#3399FF"],
                      theme: { mode: "light" },
                      chart: {
                        toolbar: {
                          show: false
                        }
                      },
                      xaxis: {
                        tickPlacement: "on",
                        categories: getLeadStatus,
                        // title: {
                        //   text: "Lead Status",
                        //   style: { fontSize: "15" },
                        // },
                        labels: {
                          rotateAlways: true,
                          rotate: -35,
                          style: {
                            fontSize: "10",
                          },
                        },
                      },

                      yaxis: {
                        labels: {
                          formatter: (val) => {
                            return `${val}`;
                          },
                          style: { fontSize: "15" },
                        },
                      },

                      legend: {
                        show: true,
                        position: "right",
                      },

                      dataLabels: {
                        formatter: (val) => {
                          return `${val}`;
                        },
                        style: {
                          fontSize: "15",
                        },
                      },
                      plotOptions: {
                        bar: {
                          columnMaxWidth: "30%", // You can adjust this value to control the column width
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {(viewLeadModulePermissionStatus) && (
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <div id='main-wrap' className='search-filter h-100'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <h5 className="fw-600 mb-0">New Leads</h5>
                    </div>

                    {(!isExternalUser) && (
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label htmlFor="lead-profile">
                            <select
                              id='child_id'
                              name='child_id'
                              className="form-select"
                              value={selectedNewLeadAssigned}
                              onChange={(e) => setSelectedNewLeadAssigned(e.target.value)}
                            >
                              <option value=''>All Users</option>
                              {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                                <option value={option.user_id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <DataTable
                    columns={newLeadColumns}
                    data={newLeadData}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsNewLead}
                    paginationPerPage={pageSizeNewLead}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationDefaultPage={currentPageNewLead}
                    onChangePage={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}

          {(viewHabileUsageModulePermissionStatus && viewIncidentReportPermissionStatus) && (
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <div id='main-wrap'>
                  <h5 className="fw-600 mb-3">Habile and Scorecard</h5>
                  <form onSubmit={handleSubmitHabile(handleHabileScorecard)}>
                    <div id='main-wrap' className='mb-4 filter'>
                      <div className='row mx-0'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor="start-date">
                              <span>Start Date</span>
                              <input type='date' name='habile_and_scorecard_start_date' className='form-control'{...registerHabile('habile_and_scorecard_start_date', { required: true })} />
                            </label>
                            {errorsHabile.habile_and_scorecard_start_date && <span className="required">This field is required.</span>}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor="end-date">
                              <span>End Date</span>
                              <input type='date' name='habile_and_scorecard_end_date' className='form-control'{...registerHabile('habile_and_scorecard_end_date', { required: true })} />
                            </label>
                            {errorsHabile.habile_and_scorecard_end_date && <span className="required">This field is required.</span>}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group mb-2'>
                            <span className='d-block'>&nbsp;</span>
                            <button className='btn btn-primary'>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div id="chart">
                    <Chart options={habileAndScoreState.options} series={habileAndScoreState.series} type="line" height={350} />
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {(viewIncidentReportPermissionStatus) && (
            <div className='row'>
              <div className='col-md-6 mb-4'>
                <div id='main-wrap' className='search-filter h-100'>

                  <div className='row'>
                    <div className='col-md-7'>
                      <h5 className="fw-600 mb-0">Incident Assigned To Me</h5>
                    </div>
                    <div className='col-md-5'>
                      <div className='form-group'>
                        <label htmlFor="users">
                          <select
                            id='child_id'
                            name='child_id'
                            className="form-select"
                            value={selectedIncidentReportsAssigned}
                            onChange={(e) => setSelectedIncidentReportsAssigned(e.target.value)}
                          >
                            <option value=''>All Users</option>
                            {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                              <option value={option.user_id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    columns={incidentReportAssignedMeColumn}
                    data={allIncidentReports}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsIncidentReports}
                    paginationPerPage={pageSizeIncidentReports}
                    onChangeRowsPerPage={handlePerRowsChangeIncidentReports}
                    paginationDefaultPage={currentPageIncidentReports}
                    onChangePage={handlePageChangeIncidentReports}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-4'>
                <div id='main-wrap' className='search-filter h-100'>
                  <div className='row'>
                    <div className='col-md-7'>
                      <h5 className="fw-600 mb-0">Task Assigned To Me</h5>
                    </div>
                    <div className='col-md-5'>
                      <div className='form-group'>
                        <label htmlFor="users">
                          <select
                            id='child_id'
                            name='child_id'
                            className="form-select"
                            value={selectedIncidentTaskAssigned}
                            onChange={handleSelectedIncidentTaskAssignedChange}
                          >
                            <option value=''>All Users</option>
                            {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                              <option value={option.user_id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    columns={incidentTaskAssignedMeColumn}
                    data={allIncidentTask}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsIncidentTask}
                    paginationPerPage={pageSizeIncidentTask}
                    onChangeRowsPerPage={handlePerRowsChangeIncidentTask}
                    paginationDefaultPage={currentPageIncidentTask}
                    onChangePage={handlePageChangeIncidentTask}
                  />
                </div>
              </div>
            </div>
          )}

          {(requestModulePermissionStatus) && (
            <div className='row'>
              <div className='col-md-6 mb-4'>
                <div id='main-wrap' className='search-filter h-100'>

                  <div className='row'>
                    <div className='col-md-7'>
                      <h5 className="fw-600 mb-0">{config.MODULE_TYPE_NAME} Assigned To Me</h5>
                    </div>
                    <div className='col-md-5'>
                      <div className='form-group'>
                        <label htmlFor="users">
                          <select
                            id='child_id'
                            name='child_id'
                            className="form-select"
                            value={selectedModuleAssigned}
                            onChange={(e) => setSelectedModuleAssigned(e.target.value)}
                          >
                            <option value=''>All Users</option>
                            {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                              <option value={option.user_id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    columns={moduleAssignedMeColumn}
                    data={allModule}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsModule}
                    paginationPerPage={pageSizeModule}
                    onChangeRowsPerPage={handlePerRowsChangeModule}
                    paginationDefaultPage={currentPageModule}
                    onChangePage={handlePageChangeModule}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-4'>
                <div id='main-wrap' className='search-filter h-100'>
                  <div className='row'>
                    <div className='col-md-7'>
                      <h5 className="fw-600 mb-0">{config.MODULE_SUB_TYPE_NAME} Assigned To Me</h5>
                    </div>
                    <div className='col-md-5'>
                      <div className='form-group'>
                        <label htmlFor="users">
                          <select
                            id='child_id'
                            name='child_id'
                            className="form-select"
                            value={selectedModuleTaskAssigned}
                            onChange={handleSelectedModuleTaskAssignedChange}
                          >
                            <option value=''>All Users</option>
                            {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                              <option value={option.user_id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    columns={moduleTaskAssignedMeColumn}
                    data={allModuleTask}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsModuleTask}
                    paginationPerPage={pageSizeModuleTask}
                    onChangeRowsPerPage={handlePerRowsChangeModuleTask}
                    paginationDefaultPage={currentPageModuleTask}
                    onChangePage={handlePageChangeModuleTask}
                  />
                </div>
              </div>
            </div>
          )}
          
        </div>

      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    loggedinUserActivityData: state.loggedinUserActivityData,
    loggedinUserFollowupLeadsData: state.loggedinUserFollowupLeadsData,
    loggedinUserNewLeadsData: state.loggedinUserNewLeadsData,
    incidentReportAssignedMe: state.incidentReportAssignedMe,
    getLoggedinUserSourceLeads: state.getLoggedinUserSourceLeads,
   }
}
 
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, dashboardActions.default, moduleAction.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardAppPage);
