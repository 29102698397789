import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as campaignsActions from "../../actions/campaignsActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AuditReport = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);
    const [productType, setProductType] = useState('');
    const [allAuditReportData, setAllAuditReportData] = useState([]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Audit Report')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    useEffect(() => {
        const today = new Date();
        const todayFormatted = formatDate(today);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

    }, [setStartDate, setEndDate, setValue]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const onSubmit = (data) => {
        const oneDay = 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds

        // Convert start_date and end_date to Date objects
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        // Check if end date is greater than or equal to start date
        if (endDate < startDate) {
            toast.error("End date must be greater than or equal to start date. Please select a valid date range.", {
                position: "top-right",
            });
            return;
        }

        const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));


        setPreloaderStatus(true);
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'ProductType': data.product_type,
            'AuditColumnId': data.transaction_type,
            'start_date': data.start_date,
            'end_date': data.end_date,
        };
        // console.log("params : ", params);

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        const requestData = encryptData(params);
        const response = actions.getAuditReportData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getAuditReportData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
            } else {
                setPreloaderStatus(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getAuditReportData:", error);
        });
    };
    

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allBeatPlans.filter(item => {
        const companyName = item.companyName?.toLowerCase() || '';
        const assignedUser = item.assignedUser?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        return (
            companyName.includes(searchQuery.toLowerCase()) ||
            assignedUser.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const exportExcelFile = async () => {

        const table = document.getElementById('exportExcelFile');
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');
        
        if (actionColumnIndex !== -1) {

            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }
    
        TableToExcel.convert(table, {
            name: 'Audit-Report.xlsx',
            sheet: {
                name: 'Audit-Report',
            },
        });
    
        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };

    const moneyFormatIndia = (number) => {
        if (number !== undefined && number !== null && !Number.isNaN(Number(number))) {
            number = parseFloat(number).toFixed(2);  // Ensure two decimal places
    
            const [integerPart, decimalPart] = number.split('.');
    
            const lastThreeDigits = integerPart.slice(-3);
            const otherDigits = integerPart.slice(0, -3);
    
            let formattedIntegerPart;
            if (otherDigits !== '') {
                formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
            } else {
                formattedIntegerPart = lastThreeDigits;
            }
    
            return `${formattedIntegerPart}.${decimalPart}`;
        }
    
        return '0.00';  // Default return value for invalid inputs
    };

    const formatDateForRow = (dateStr) => {
        if (!dateStr) {
            return '';
        }

        const date = new Date(dateStr);
        if (Number.isNaN(date.getTime())) {
            return '';
        }

        const dateOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // for AM/PM format
        };

        // Format the date and time separately
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        // Combine the formatted date and time
        return `${formattedDate} ${formattedTime}`;
    };

    const handleExport = () => {

        const paramStartDate = new Date(startDate);
        const paramEndDate = new Date(endDate);
    
        // Check if end date is greater than or equal to start date
        if (paramEndDate < paramStartDate) {
            toast.error("End date must be greater than or equal to start date. Please select a valid date range.", {
                position: "top-right",
            });
        } else {

            setPreloaderStatus(true);
            const params = {
                'start_date': startDate,
                'end_date': endDate,
                'product_type':productType,
            };
            const requestData = encryptData(params);
            const response = actions.exportAuditData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  exportAuditData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data;
                    const resultArr = Object.keys(results).map((key) => results[key]);
                    setAllAuditReportData(resultArr);
                } else {
                    setPreloaderStatus(false);
                    setAllAuditReportData([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  exportAuditData:", error);
            });
        }
    };

    const exportToExcel = () => {
        const table = document.getElementById('exportAllDataExcelFile');
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');

        if (actionColumnIndex !== -1) {
            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }

        TableToExcel.convert(table, {
            name: 'Audit-Report.xlsx',
            sheet: {
                name: 'Audit-Report',
            },
        });

        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };

    useEffect(() => {
        if (allAuditReportData && allAuditReportData.length > 0) {
            exportToExcel();
        }
    }, [allAuditReportData]);

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Audit Report | Ckers</title>
            </Helmet>

            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="priority-type">
                                        <span>Product Type <a style={{ color: "red" }}>*</a></span>
                                        <select name='product' className="form-select" {...register('product_type', { required: true })} onChange={(e) => setProductType(e.target.value)}>
                                            <option value="">Select Product Type</option>
                                            <option value="vehicleloan">Vehicle Loan</option>
                                            <option value="businessloan">Business Loan</option>
                                        </select>
                                    </label>
                                    {errors.product_type && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="priority-type">
                                        <span>Transaction Type <a style={{ color: "red" }}>*</a></span>
                                        <select name='priority_type' className="form-select" {...register('transaction_type', { required: true })}>
                                            <option value="">Select Transaction Type</option>
                                            <option value="financecreation">Finance Creation</option>
                                            <option value="financedeletion">Finance Deletion</option>
                                            <option value="financesettlement">Finance Settlement</option>
                                            <option value="emideletion">EMI Deletion</option>
                                            <option value="emireceipt">EMI Receipt</option>
                                            <option value="contra">Contra</option>
                                            <option value="journal">Journal</option>
                                            <option value="payment">Payment</option>
                                            <option value="receipt">Receipt</option>
                                            <option value="usercreation">User Creation</option>
                                            <option value="userdeletion">User Deletion</option>
                                            <option value="financefreeze">Finance Freeze</option>
                                            <option value="latepayment">Late Payment</option>
                                            <option value="ledgerdelete">Ledger Delete</option>
                                            <option value="vasdelete">VAS Delete</option>
                                            <option value="borrowerchange">Borrower Change</option>
                                            <option value="guarantorchange">Guarantor Change</option>
                                            <option value="borrowerdelete">Borrower Delete</option>
                                            <option value="vasamountUpdate">VAS Amount Update</option>
                                            <option value="unsettledfinance">Unsettled Finance</option>
                                            <option value="paymentdelete">Payment Delete</option>
                                            <option value="receiptdelete">Receipt Delete</option>
                                            <option value="journaldelete">Journal Delete</option>
                                            <option value="contradelete">Contra Delete</option>
                                            <option value="mfigroupdelete">MFI Group Delete</option>
                                            <option value="discountupdate">Discount Update</option>
                                            <option value="emireversal">Emi Reversal</option>
                                            <option value="nocdownload">NOC Download</option>
                                            <option value="colendingsettle">Co-Lending Settle</option>
                                            <option value="colendingunsettle">Co-Lending UnSettle</option>
                                            <option value="rspreset">RSP Reset</option>
                                            <option value="workflowdelete">Workflow Delete</option>
                                        </select>
                                    </label>
                                    {errors.transaction_type && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date </span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button type='submit' className='btn btn-primary'>Submit</button>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button type='button' className='btn btn-info' onClick={handleExport}>Export</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                Audit Report
                            </Typography>
                        </div>
                        <div className='col-md-5 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <button className="btn btn-info text-white ms-3" onClick={() => exportExcelFile()}>
                                    Export
                                </button>
                            </div>
                            <div className="float-left mb-2"><b>Total Records : {allBeatPlans.length}</b></div>
                        </div>
                    </div>
                    <div className="table-wrap table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Loan ID</th>
                                    <th className="text-nowrap">Agreement Number</th>
                                    <th className="text-nowrap">Branch</th>
                                    <th className="text-nowrap">User Name</th>
                                    <th className="text-nowrap">User ID</th>
                                    <th className="text-nowrap">Transaction Type</th>
                                    <th className="text-nowrap">Voucher No.</th>
                                    <th className="text-nowrap">Amount</th>
                                    <th className="text-nowrap">Transaction Date</th>
                                    <th className="text-nowrap">Created Date</th>
                                    
                                    {/* <th className="text-nowrap">Additional Info</th> */}
                                    {/* <th className="text-nowrap">Borrowername</th> */}
                                    {/* <th className="text-nowrap">History</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>

                                        <td className="text-nowrap">{item.LoanID}</td>
                                        <td className="text-nowrap">{item.AgreementNumber}</td>
                                        <td className="text-nowrap">{item.Branch}</td>
                                        <td className="text-nowrap">{item.UserName}</td>
                                        <td className="text-nowrap">{item.UserId}</td>
                                        <td className="text-nowrap">{item.TransactionType}</td>
                                        <td className="text-nowrap">{item.VoucherNumber}</td>
                                        <td className="text-nowrap">{moneyFormatIndia(item.Amount)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.TransactionDate)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.CreatedDate)}</td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={13} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="table-wrap table-responsive d-none">
                        <table className="table table-hover" id="exportExcelFile">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Loan ID</th>
                                    <th className="text-nowrap">Agreement Number</th>
                                    <th className="text-nowrap">Branch</th>
                                    <th className="text-nowrap">User Name</th>
                                    <th className="text-nowrap">User ID</th>
                                    <th className="text-nowrap">Transaction Type</th>
                                    <th className="text-nowrap">Voucher No.</th>
                                    <th className="text-nowrap">Amount</th>
                                    <th className="text-nowrap">Transaction Date</th>
                                    <th className="text-nowrap">Created Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {allBeatPlans.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.LoanID}</td>
                                        <td className="text-nowrap">{item.AgreementNumber}</td>
                                        <td className="text-nowrap">{item.Branch}</td>
                                        <td className="text-nowrap">{item.UserName}</td>
                                        <td className="text-nowrap">{item.UserId}</td>
                                        <td className="text-nowrap">{item.TransactionType}</td>
                                        <td className="text-nowrap">{item.VoucherNumber}</td>
                                        <td className="text-nowrap">{moneyFormatIndia(item.Amount)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.TransactionDate)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.CreatedDate)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="table-wrap table-responsive d-none">
                        <table className="table table-hover" id="exportAllDataExcelFile">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Loan ID</th>
                                    <th className="text-nowrap">Agreement Number</th>
                                    <th className="text-nowrap">Branch</th>
                                    <th className="text-nowrap">User Name</th>
                                    <th className="text-nowrap">User ID</th>
                                    <th className="text-nowrap">Transaction Type</th>
                                    <th className="text-nowrap">Voucher No.</th>
                                    <th className="text-nowrap">Amount</th>
                                    <th className="text-nowrap">Transaction Date</th>
                                    <th className="text-nowrap">Created Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {allAuditReportData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">{item.LoanID}</td>
                                        <td className="text-nowrap">{item.AgreementNumber}</td>
                                        <td className="text-nowrap">{item.Branch}</td>
                                        <td className="text-nowrap">{item.UserName}</td>
                                        <td className="text-nowrap">{item.UserId}</td>
                                        <td className="text-nowrap">{item.TransactionType}</td>
                                        <td className="text-nowrap">{item.VoucherNumber}</td>
                                        <td className="text-nowrap">{moneyFormatIndia(item.Amount)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.TransactionDate)}</td>
                                        <td className="text-nowrap">{formatDateForRow(item.CreatedDate)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBeatPlanData: state.getBeatPlanData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuditReport);