import React from "react";
import BounceLoader from "react-spinners/MoonLoader";
// import { css } from "@emotion/core";
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
  top: 50%;
  left: 50%;
  position: fixed;
  color: darkred;
  margin-top: -32px;
  margin-left: -32px;
`;

const Loader = () => {
  return (
    <div className="loader">
      <BounceLoader css={override} size={50} color={"#0000ff"} loading />
    </div>
  );
}; 

export default Loader;
