import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus"; 

export function departmentsDataSuccess(departmentsData) {
  return { type: types.LOAD_DEPARTMENTS_DATA_SUCCESS, departmentsData };
}
export function departmentInfoSuccess(departmentInfo) {
  return { type: types.LOAD_DEPARTMENT_INFO_SUCCESS, departmentInfo };
}

const createDepartment = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CU_DEPARTMENT, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const departmentsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_DEPARTMENTS, params);
      } else {
        response = await agent.post(API.GET_DEPARTMENTS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};


const departmentInfo = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_DEPARTMENTS, params);
      } else {
        response = await agent.post(API.GET_DEPARTMENTS, params);
        
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(departmentInfoSuccess(response.data.data));
          }
        }
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

export default {
  createDepartment,
  departmentsData,
  departmentsDataSuccess,
  departmentInfo,
  departmentInfoSuccess 
}